import React, { useRef, useMemo, memo, useEffect } from 'react'

import classNames from 'classnames'
import { VariableSizeList as List } from 'react-window'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

import Avatar from '../Avatar'
import { getAbsenceDates, makeAbsenceMap } from './Absences/helpers'
import ShiftDayList from './ShiftDay'
import { useAbsenceContext } from './contexts/AbsenceContext'
import { useMonthContext } from './contexts/MonthContext'
import useEmployees from './hooks/useEmployees'

const EMPLOYEE_ROW_COUNT = 12
const EMPLOYEE_ROW_HEIGHT = 41
const LAST_EMPLOYEE_ROW_HEIGHT = 48

const MAX_EMPLOYEE_LIST_HEIGHT = EMPLOYEE_ROW_COUNT * EMPLOYEE_ROW_HEIGHT + EMPLOYEE_ROW_HEIGHT / 2

const OnlineBadge = ({ className = '' }) => <span className={classNames('online-badge', className)} />

const EmployeeItem = memo(
  ({ last, style, employee, schedule, listRef, index, datesMetadata, absenceMap, absenceDates }) => {
    useEffect(() => {
      listRef.current.resetAfterIndex(index)
    }, [index, listRef])

    const {
      id,
      first_name_last_name,
      position,
      picture_url,
      online,
      planned_shift_work_time_duration,
      consumed_shift_work_time_duration
    } = employee

    return (
      <div className='employee-container' key={id} style={style} data-employee-id={id}>
        <span
          className={classNames('shift-schedule__color', { 'round-bottom': last })}
          style={{ backgroundColor: schedule.color }}
        />
        <div className='employee-content'>
          <div className='employee-info'>
            <div className='employee-full-name text-truncate'>{first_name_last_name}</div>
            <div className='d-flex justify-content-end'>
              <div className='employee-hours'>
                {consumed_shift_work_time_duration} / {planned_shift_work_time_duration} h
              </div>
              {position && <div className='employee-position ml-2 text-truncate'>{position}</div>}
            </div>
          </div>
          <div className='avatar-container'>
            <Avatar src={picture_url} />
            {online && <OnlineBadge />}
          </div>
        </div>
        <ShiftDayList
          employee={employee}
          datesMetadata={datesMetadata}
          shifts={employee.shifts}
          color={schedule.color}
          schedule={schedule}
          absenceMap={absenceMap}
          absenceDates={absenceDates}
        />
      </div>
    )
  }
)

const EmployeesList = ({ schedule, setLoading, setHasEmployees, datesMetadata, searchTerm }) => {
  const { month } = useMonthContext()
  const { employeeAbsences } = useAbsenceContext()
  const listRef = useRef({})
  const { employees } = useEmployees({ schedule, month, setLoading, setHasEmployees, searchTerm })

  const renderEmployeeItem = useMemo(
    () =>
      ({ index, style, data }) => {
        const employee = data[index]

        const employeeId = `${employee.first_name_last_name}-${employee.id}`

        const absences = employeeAbsences[employeeId]
        const absenceMap = absences ? makeAbsenceMap(employeeAbsences[employeeId], month) : {}
        const absenceDates = getAbsenceDates(absences)

        return (
          <EmployeeItem
            employee={employee}
            last={index + 1 === data.length}
            index={index}
            schedule={schedule}
            style={style}
            listRef={listRef}
            datesMetadata={datesMetadata}
            absenceMap={absenceMap}
            absenceDates={absenceDates}
          />
        )
      },
    [month, schedule, listRef, datesMetadata, employeeAbsences]
  )

  const getEmployeeItemSize = index => (index + 1 === employees.length ? LAST_EMPLOYEE_ROW_HEIGHT : EMPLOYEE_ROW_HEIGHT)

  if (!employees.length) return null

  const height =
    employees.length < EMPLOYEE_ROW_COUNT
      ? (employees.length - 1) * EMPLOYEE_ROW_HEIGHT + LAST_EMPLOYEE_ROW_HEIGHT
      : MAX_EMPLOYEE_LIST_HEIGHT

  return (
    <div className='employees'>
      <SimpleBar style={{ height: '100%', maxHeight: height }}>
        {({ scrollableNodeRef, contentNodeRef }) => (
          <List
            width='100%'
            ref={listRef}
            height={height}
            itemCount={employees.length}
            itemData={employees}
            itemSize={getEmployeeItemSize}
            className='list-container'
            outerRef={scrollableNodeRef}
            innerRef={contentNodeRef}
            overscanCount={EMPLOYEE_ROW_COUNT}
          >
            {renderEmployeeItem}
          </List>
        )}
      </SimpleBar>
    </div>
  )
}

export default EmployeesList
