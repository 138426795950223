import React, { useState, useEffect, useMemo, useCallback } from 'react'

import classNames from 'classnames'
import { lv, ru, enUS } from 'date-fns/locale'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import I18n from 'i18n-js'
import DatePicker, { registerLocale } from 'react-datepicker'

import Icon from '../components/Icon'

registerLocale('lv', lv)
registerLocale('ru', ru)
registerLocale('en', enUS)

dayjs.extend(customParseFormat)

const TimeInput = ({ timeValue, onTimeChange, onBlur }) => (
  <input value={timeValue} onChange={onTimeChange} onBlur={onBlur} className='form-control time-input' />
)

const usePickerType = type =>
  useMemo(
    () => ({
      isTimePicker: type === 'time',
      isDateTimePicker: type === 'date_time',
      isMonthPicker: type === 'month',
      isDatePicker: type === 'date'
    }),
    [type]
  )

const DateTimePicker = props =>
  props.onChange ? <ControlledDateTimePicker {...props} /> : <UncontrolledDateTimePicker {...props} />

const ControlledDateTimePicker = ({ onChange, value, ...props }) => {
  const [timeValue, setTimeValue] = useState('')

  const timeFormat = I18n.t('time.formats.time_human_js')

  const { isTimePicker, isDateTimePicker, isMonthPicker, isDatePicker } = usePickerType(props.type)

  const {
    name,
    id,
    maxDate,
    minDate,
    disabled,
    className,
    popperPlacement = 'bottom-start',
    containerClassName = '',
    popperModifiers,
    readOnly,
    inline,
    required,
    withIcon,
    placeholder
  } = props

  useEffect(() => {
    setTimeValue(dayjs(value || undefined).format(timeFormat))
  }, [value, timeFormat])

  const handleChange = (dateValue, event) => {
    if (dateValue && isTimePicker) {
      // HC date to 1st january 2000 if value is time without date
      dateValue.setDate(1)
      dateValue.setMonth(0)
      dateValue.setYear(2000)
    }
    const parsedTime = dayjs(timeValue, timeFormat, true)
    if (parsedTime.isValid() && event?.nativeEvent instanceof MouseEvent && dateValue) {
      dateValue.setHours(parsedTime.hour(), parsedTime.minute(), parsedTime.second())
      event?.nativeEvent.stopImmediatePropagation()
      onChange(dateValue)
    } else {
      event?.nativeEvent.stopImmediatePropagation()
      onChange(dateValue)
      setTimeValue(dateValue ? dayjs(dateValue).format(timeFormat) : '')
    }
  }

  const handleTimeChange = ({ target: { value: inputValue } }) => {
    if (inputValue.match(/[^0-9]:/) || inputValue.match(/[^0-9,:]/) || inputValue.match(/\d{2}:\d{3,}/)) {
      return false
    }
    const isColonAbsent = inputValue.match(/^\d{2}$/)
    const wasColonPresent = timeValue.match(/^\d{2}:$/)
    if (isColonAbsent && !wasColonPresent) {
      inputValue = `${inputValue}:`
    } else if (isColonAbsent && wasColonPresent) {
      inputValue = inputValue[0]
    }
    setTimeValue(inputValue)
    const parsedTime = dayjs(inputValue, timeFormat, true)
    if (parsedTime.isValid()) {
      const date = value ? new Date(value) : new Date()
      date.setHours(parsedTime.hour(), parsedTime.minute(), parsedTime.second())
      handleChange(date)
    }
  }

  const resetTimeValue = () => {
    setTimeValue(value ? dayjs(value).format(timeFormat) : '')
  }

  const classes = classNames(
    'datepicker-field',
    { 'month-datepicker': isMonthPicker, 'with-icon': withIcon },
    containerClassName
  )

  let format = 'date.formats.date_human_js'

  if (isTimePicker) {
    format = 'time.formats.time_human_js'
  } else if (isDateTimePicker) {
    format = 'time.formats.date_human_js'
  } else if (isMonthPicker) {
    format = 'date.formats.without_date_human_js'
  } else if (isDatePicker) {
    format = 'date.formats.date_human_js'
  }

  const handleKeyDown = e => {
    if (e.key === 'Escape') {
      // Prevent modal to catch this event
      e.nativeEvent.stopImmediatePropagation()
    }
  }

  return (
    <div className={classes} onKeyDown={handleKeyDown}>
      <DatePicker
        locale={I18n.locale}
        timeInputLabel={`${I18n.t('components.date_picker.time_label')}:`}
        selected={value}
        onChange={handleChange}
        dateFormat={I18n.t(format)}
        highlightDates={[new Date()]}
        showMonthYearPicker={isMonthPicker}
        showTimeInput={isDateTimePicker}
        customTimeInput={<TimeInput timeValue={timeValue} onTimeChange={handleTimeChange} onBlur={resetTimeValue} />}
        name={name}
        showPopperArrow={false}
        className={classNames('form-control', className)}
        shouldCloseOnSelect={!isDateTimePicker}
        showTimeSelect={isTimePicker}
        showTimeSelectOnly={isTimePicker}
        timeIntervals={10}
        timeCaption={I18n.t('components.date_picker.time_label')}
        autoComplete='off'
        id={id}
        maxDate={maxDate}
        minDate={minDate}
        disabled={disabled}
        popperPlacement={popperPlacement}
        popperModifiers={popperModifiers}
        readOnly={readOnly}
        inline={inline}
        isClearable={!required}
        placeholderText={placeholder ?? ''}
      />
      {withIcon && <Icon icon='filterCalendar' className='calendar-icon' />}
    </div>
  )
}

const UncontrolledDateTimePicker = ({ input_options, afterChange }) => {
  const [dateValue, setDateValue] = useState(null)
  const { isMonthPicker } = usePickerType(input_options.type)

  const { value, class: className, hidden, notClearable, ...pickerOptions } = input_options

  const setInitialValue = useCallback(() => {
    if (value) {
      const parsedValue = isMonthPicker
        ? dayjs(value, I18n.t('date.formats.without_date_human')).toDate()
        : dayjs(value).toDate()

      setDateValue(parsedValue)
    } else {
      setDateValue(null)
    }
  }, [isMonthPicker, value])

  const handleChange = newValue => {
    if (!newValue && notClearable) {
      setInitialValue()
    } else {
      setDateValue(newValue)
    }
    if (afterChange) {
      afterChange(newValue)
    }
  }

  useEffect(() => {
    setInitialValue()
  }, [setInitialValue, input_options.value])

  useEffect(
    function setHiddenValue() {
      if (!hidden) return

      const element = document.getElementById(hidden.id)
      element.value = dateValue && dateValue.toISOString()
      element.dispatchEvent(new Event('change'))
    },
    [dateValue, hidden]
  )

  return <ControlledDateTimePicker value={dateValue} onChange={handleChange} className={className} {...pickerOptions} />
}

export default DateTimePicker
