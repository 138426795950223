import React from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import classNames from 'classnames'
import { useFormContext } from 'react-hook-form'

import useTranslate from '../../../../hooks/useTranslate'
import { showConfirmation } from '../../../../utils/confirmations'
import { url } from '../../../../utils/urlGenerator'
import Icon from '../../../Icon'
import styles from '../Buttons.module.scss'

const ContractButtons = ({ onClose, explanation }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.ContractForm')
  const { formDisabled, isEditForm, ligumsId, contract, setDestroyRequested, closeForm } = useFormContext()

  const { mutate: mutateDestroy, isLoading: isDestroying } = useMutation({
    mutationFn: () =>
      axios.delete(url('v2/vedludb/contracts', { id: ligumsId, ligumaId: contract.ligumsId, pamatojums: explanation })),
    onSettled: () => {
      closeForm()
    }
  })

  const handleDestroy = () => {
    if (explanation) {
      showConfirmation({
        isOpen: true,
        closeOnConfirm: true,
        onConfirm: mutateDestroy,
        text: t('.titles.confirmDelete'),
        confirmButton: { title: t('.buttons.confirmDelete'), type: 'danger' }
      })
    } else {
      setDestroyRequested(true)
    }
  }

  const buttonsDisabled = formDisabled || isDestroying

  return (
    <div className='mt-4 d-flex justify-content-between'>
      <button
        className={classNames(styles.vedludbButton, styles.primary)}
        type='submit'
        disabled={buttonsDisabled || (isEditForm && !explanation)}
      >
        {t('.buttons.save')}
      </button>
      <div className='d-flex align-items-center'>
        <button
          className={classNames(styles.vedludbButton, styles.secondary)}
          type='button'
          onClick={onClose}
          disabled={buttonsDisabled}
        >
          {t('.buttons.cancel')}
        </button>
        {isEditForm && (
          <button
            className={classNames(styles.vedludbButton, styles.danger, styles.destroyButton, 'destroy-button')}
            type='button'
            disabled={buttonsDisabled}
            onClick={handleDestroy}
            title={t('destroy')}
          >
            <Icon icon='iconTrash' />
          </button>
        )}
      </div>
    </div>
  )
}

export default ContractButtons
