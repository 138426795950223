import React from 'react'

import ActiveTable from '../../ActiveTable'
import navigateToFilteredTasks from './navigateToFilteredTasks'

class UsersTable extends React.Component {
  render() {
    return (
      <ActiveTable
        {...this.props}
        getTdProps={navigateToFilteredTasks(row => ({ responsible: [`user_${row.original.id}`] }))}
      />
    )
  }
}

export default UsersTable
