import React from 'react'

import Icon from '../Icon'

const DayErrors = ({ errors }) => (
  <div className='day-errors p-3 mt-3 mb-1 text-danger rounded font-weight-bold'>
    <Icon icon='warningFilled' className='warning-icon' />
    {errors.map((error, idx) => (
      <div key={idx}>{error}</div>
    ))}
  </div>
)

export default DayErrors
