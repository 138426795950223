import React from 'react'

import classNames from 'classnames'

import { t } from '../../../utils/i18n'
import Store from '../../Store'
import TableContainer from '../../TableContainer'
import ExternalDevicesFilter from './ExternalDevicesFilter'
import ExternalDevicesTable from './ExternalDevicesTable'

class ExternalDevicesTableContainer extends TableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'created_at', desc: true }]
  })

  columns = [
    {
      header: t('activerecord.attributes.external_device.kind'),
      accessorKey: 'translated_kind',
      size: 140
    },
    {
      header: t('activerecord.attributes.external_device.device_model'),
      accessorKey: 'translated_model',
      size: 170
    },
    {
      header: t('activerecord.attributes.external_device.uid'),
      accessorKey: 'uid',
      size: 140
    },
    {
      header: t('activerecord.attributes.external_device.is_active'),
      accessorKey: 'is_active',
      sortable: false,
      cell: ({ getValue }) => {
        const value = getValue()
        return <i className={classNames('fa', { 'fa-check success': value, 'fa-times danger': !value })} />
      },
      size: 90
    },
    {
      header: t('activerecord.attributes.external_device.construction_object'),
      accessorKey: 'construction_object'
    },
    {
      header: t('activerecord.attributes.external_device.direction'),
      accessorKey: 'translated_direction',
      sortable: false,
      size: 130
    },
    {
      header: t('activerecord.attributes.external_device.comment'),
      accessorKey: 'comment',
      sortable: false
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <ExternalDevicesFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <ExternalDevicesTable {...this.props} store={this.store} columns={this.columns} columnOptions={columnOptions} />
      </div>
    )
  }
}

export default ExternalDevicesTableContainer
