import React from 'react'

import classNames from 'classnames'
import { useController } from 'react-hook-form'

const RegularityOptions = ({ control, options, disabled }) => {
  const { field } = useController({ name: 'regularity', control })

  const { value } = field

  const onChange = event => {
    const radioValue = event.target.value

    if (event.target.checked) {
      field.onChange([...value, radioValue])
    } else {
      field.onChange(value.filter(v => v !== radioValue))
    }
  }

  return (
    <div className='regularity-options'>
      {options?.map((option, i) => {
        const checked = value.includes(option.value)

        return (
          <label key={i} className={classNames('day', { selected: checked, disabled })}>
            <input
              {...field}
              onChange={onChange}
              type='checkbox'
              checked={checked}
              disabled={disabled}
              value={option.value}
            />
            {option.label}
          </label>
        )
      })}
    </div>
  )
}

export default RegularityOptions
