import React from 'react'

import classNames from 'classnames'

import { t } from '../../../utils/i18n'
import Avatar from '../../Avatar'
import Store from '../../Store'
import TableContainer from '../../TableContainer'
import UserGroupsFilter from './UserGroupsFilter'
import UserGroupsTable from './UserGroupsTable'

class UserGroupsTableContainer extends TableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'name', desc: false }],
    baseScope: this.props.base_scope
  })

  columns = [
    {
      header: t('activerecord.attributes.tasker/user_group.name'),
      accessorKey: 'name',
      cell: ({
        getValue,
        row: {
          original: { is_parent, children_count, image_url }
        }
      }) => {
        let value = getValue()

        if (is_parent) {
          value = (
            <>
              <span>{value}</span>
              <span className='children-count'>({children_count})</span>
            </>
          )
        }

        return <Avatar src={image_url} text={value} />
      }
    },
    {
      header: t('activerecord.attributes.tasker/user_group.parent_title'),
      accessorKey: 'parent_title',
      cell: ({
        row: {
          original: { parent }
        }
      }) => {
        return parent ? <Avatar src={parent.image_url} text={parent.name} /> : null
      }
    },
    {
      header: t('activerecord.attributes.tasker/user_group.users_count'),
      accessorKey: 'users_count'
    },
    {
      header: t('activerecord.attributes.tasker/user_group.draft_tasks_count'),
      accessorKey: 'draft_tasks_count',
      size: 50,
      minSize: 50
    },
    {
      header: t('activerecord.attributes.tasker/user_group.in_progress_tasks_count'),
      accessorKey: 'in_progress_tasks_count',
      size: 50,
      minSize: 50
    },
    {
      header: t('activerecord.attributes.tasker/user_group.delayed_tasks_count'),
      accessorKey: 'delayed_tasks_count',
      size: 50,
      minSize: 50
    }
  ]

  getTrClassIdentifier = ({ original: { id } }) => `user_group_row_${id}`

  getTrClassName = row =>
    classNames({
      [this.getTrClassIdentifier(row)]: true,
      'font-weight-bold': row?.original.is_parent
    })

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <UserGroupsFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <UserGroupsTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
          getTrClassName={this.getTrClassName}
        />
      </div>
    )
  }
}

export default UserGroupsTableContainer
