import React, { useCallback, useRef } from 'react'

import classNames from 'classnames'
import { useField } from 'formik'
import { MentionsInput, Mention } from 'react-mentions'

const renderSuggestion = (entry, search, highlightedDisplay, index, focused) => (
  <div className={classNames('mention-suggestion', { 'mention-suggestion--focused': focused })}>
    <img className='avatar' src={entry.author_picture} />
    <div className='mention-suggestion__name text-truncate'>{entry.display}</div>
    <div className='mention-suggestion__email text-truncate'>{entry.email}</div>
  </div>
)

const MentionsField = ({ taggable_users, name, id, ...others }) => {
  const [{ value }, , { setValue }] = useField(name)

  const suggestionsPortalRef = useRef()

  const dataProvider = useCallback(
    query => {
      const normalizedQuery = query.toLowerCase()

      return taggable_users.filter(
        suggestion => !query || suggestion.keywords.toLowerCase().indexOf(normalizedQuery) > -1
      )
    },
    [taggable_users]
  )

  return (
    <div className='mentions-field' ref={suggestionsPortalRef} id={id} {...others}>
      <MentionsInput
        value={value}
        onChange={(event, newValue) => setValue(newValue)}
        suggestionsPortalHost={suggestionsPortalRef?.current}
        className='mentions-input'
        singleLine
        ignoreAccents
        {...others}
      >
        <Mention
          trigger='@'
          data={dataProvider}
          renderSuggestion={renderSuggestion}
          markup='<user-mention sgid="__id__">__display__</user-mention>'
          displayTransform={(id, display) => `@${display}`}
          appendSpaceOnAdd
          className='mention-tag'
        />
      </MentionsInput>
    </div>
  )
}

export default MentionsField
