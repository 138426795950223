import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Avatar from '../Avatar'
import { Identifiers } from '../Employees/EmployeeIdentifiers'
import EmployeeFilter from '../Employees/EmployeesFilter'
import { SimpleSelect } from '../FilterComponents'
import Icon from '../Icon'
import IconCell from '../IconCell'
import Store from '../Store'
import TableContainer from '../TableContainer'
import CompanyEmployeesTable from './CompanyEmployeesTable'

class CompanyEmployeesTableContainer extends TableContainer {
  pathPrefix = 'v2/employees'

  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'currently_checked_in_duration', desc: false }]
  })

  customFilters = () => {
    const { construction_objects_options, state_options, identifiers_options, is_foreigner_options } = this.props

    return (
      <React.Fragment>
        <SimpleSelect
          store={this.store}
          selectable='construction_object_id'
          options={construction_objects_options}
          isMulti={true}
          dataContains='select-construction-object-filter'
        />
        <SimpleSelect
          store={this.store}
          selectable='state'
          options={state_options}
          dataContains='select-state-filter'
        />
        <SimpleSelect
          store={this.store}
          selectable='is_foreigner'
          options={is_foreigner_options}
          dataContains='select-is-foreigner-filter'
        />
        <SimpleSelect store={this.store} selectable='checkable_identifier' options={identifiers_options} />
      </React.Fragment>
    )
  }

  columns = [
    {
      header: t('activerecord.attributes.employee.first_name_last_name'),
      accessorKey: 'first_name_last_name',
      cell: ({ getValue, row: { original } }) => (
        <Avatar src={original.picture_url} text={getValue()} resourcePath={url(this.pathPrefix, original.id)} />
      )
    },
    {
      header: t('activerecord.attributes.employee.identifiers'),
      accessorKey: 'identifiers_presence',
      sortable: false,
      cell: ({ getValue, row: { original } }) => <Identifiers id={original.id} {...getValue()} />,
      size: 90
    },
    {
      header: t('activerecord.attributes.employee.currently_in_object'),
      accessorKey: 'currently_checked_in_duration',
      minSize: 90,
      size: 90,
      cell: ({ getValue }) => <span className='font-weight-bold'>{getValue() || '-'}</span>
    },
    {
      header: t('activerecord.attributes.employee.currently_checked_in_construction_object_name'),
      accessorKey: 'currently_checked_in_construction_object_name'
    },
    {
      header: t('activerecord.attributes.employee.personal_code'),
      accessorKey: 'personal_code',
      cell: ({
        getValue,
        row: {
          original: { id, is_foreigner }
        }
      }) => (
        <IconCell
          id={id}
          showIcon={is_foreigner}
          tooltipText={t('activerecord.attributes.employee.is_foreigner')}
          iconComponent={tooltipId => <Icon icon='globe' className='ml-2 center-icon' data-tip data-for={tooltipId} />}
        >
          {getValue()}
        </IconCell>
      )
    },
    {
      header: t('activerecord.attributes.employee.code'),
      accessorKey: 'code'
    },
    {
      header: t('activerecord.attributes.employee.consumed_time_in_period'),
      accessorKey: 'consumed_time_in_period'
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <EmployeeFilter
          {...this.props}
          store={this.store}
          customFilters={this.customFilters}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <CompanyEmployeesTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
          pathPrefix={this.pathPrefix}
        />
      </div>
    )
  }
}

export default CompanyEmployeesTableContainer
