import React from 'react'

import classNames from 'classnames'

import { t } from '../../utils/i18n'
import Icon from '../Icon'
import Tooltip from '../Tooltip'
import { HistoricalTimeEntryIcon } from '../elements/TableColumnsElements'

const CheckLogDates = ({ dateTimes, checkLog }) => {
  const invalid = checkLog.leave_time_exceeded
  const checkedIn = checkLog.checked_in
  const dateClassName = classNames('check-log-date', { 'text-danger': invalid })
  const timeClassName = classNames('check-log-time', { 'text-danger': invalid })
  const clockClassName = classNames('check-log-clock ml-2 d-flex', { 'text-danger': invalid })

  return (
    <React.Fragment>
      <div className='check-log-date-item'>
        <Icon icon='checkIn' className='check-log-direction' />
        <div className={dateClassName}>{dateTimes.checked_in_date}</div>
        <Icon icon='clock' className={clockClassName} />
        <div className={timeClassName}>{dateTimes.checked_in_time}</div>
        <HistoricalTimeEntryIcon edited={dateTimes.checked_in_at_changed} id={`${checkLog.id}-in`} />
      </div>
      <div className={classNames('check-log-date-item', { 'pending-check-out': checkedIn })}>
        <Icon icon='checkOut' className='check-log-direction' />
        <div className={dateClassName}>{dateTimes.checked_out_date || '–'}</div>
        <Icon icon='clock' className={clockClassName} />
        <div className={timeClassName}>{dateTimes.checked_out_time || '–'}</div>
        <HistoricalTimeEntryIcon edited={dateTimes.checked_out_at_changed} id={`${checkLog.id}-out`} />
      </div>
    </React.Fragment>
  )
}

const CheckLogCheckedWith = ({ checkedWith, checkLog: { id, checked_in } }) => {
  return (
    <React.Fragment>
      <div className='d-flex'>
        <CheckedWithIcon
          checkedWith={checkedWith.checked_in_with}
          title={checkedWith.checked_in_with_title}
          offline={checkedWith.checked_in_offline}
          id={id}
          direction='in'
        />
        <CheckedPermillesIcon checkedWith={checkedWith} id={id} direction='in' />
      </div>
      <div
        className={classNames('d-flex  check_log_checked_with_properties--out', { 'pending-check-out': checked_in })}
      >
        <CheckedWithIcon
          checkedWith={checkedWith.checked_out_with}
          title={checkedWith.checked_out_with_title}
          offline={checkedWith.checked_out_offline}
          id={id}
          direction='out'
        />
        <CheckedPermillesIcon checkedWith={checkedWith} id={id} direction='out' />
      </div>
    </React.Fragment>
  )
}

const CheckedWithIcon = ({ checkedWith, title, offline, id, direction }) => {
  if (checkedWith) {
    const tooltipId = `check-log-kind-${id}-${direction}`
    const icon = {
      nfc: 'checkWithNfc',
      fingerprint: 'checkWithFingerprint',
      remote: 'checkWithRemote',
      auto_check_out: 'checkWithAuto',
      manual: 'checkWithManual',
      forced_checkout: 'checkWithAuto'
    }[checkedWith]

    return (
      <div className={`nfc-check-log-icon-${direction} d-flex`}>
        <IconWithTooltip icon={icon} tooltipId={tooltipId} tooltipText={title} />
        {offline && (
          <IconWithTooltip
            icon='checkKindOffline'
            tooltipId={`check-log-offline-${id}-${direction}`}
            tooltipText={t('checked_offline')}
            className='ml-1'
          />
        )}
      </div>
    )
  } else {
    return <span>–</span>
  }
}

const CheckedPermillesIcon = ({ direction, id, checkedWith }) => {
  const { [`checked_${direction}_permilles`]: permilles, [`checked_${direction}_permilles_over_limit`]: overLimit } =
    checkedWith

  if (permilles === null || permilles === undefined) {
    return null
  }

  const tooltipId = `check-log-permilles-${id}-${direction}`

  const classes = classNames('ml-1', `check-log-icon-permilles-${direction}`, {
    'text-primary': permilles > 0 && !overLimit,
    'text-white bg-danger': overLimit
  })

  return (
    <IconWithTooltip
      icon='permilles'
      tooltipId={tooltipId}
      tooltipText={t('alcohol_content', { permilles: permilles.toFixed(2) })}
      className={classes}
    />
  )
}

const CheckLogLocation = props => (
  <React.Fragment>
    <div>
      <CheckLogLocationIcons {...props} direction='in' />
    </div>
    <div className={classNames({ 'pending-check-out': props.checkLog.checked_in })}>
      <CheckLogLocationIcons {...props} direction='out' />
    </div>
  </React.Fragment>
)

const CheckLogLocationIcons = ({ checkLog, direction, onClick, locationData }) => {
  if (direction === 'out' && checkLog.checked_in) return '–'

  const checkedWithinArea = locationData[`checked_${direction}_within_area`]
  const distance = locationData[`checked_${direction}_distance`]
  const gpsDisabled = locationData[`checked_${direction}_gps_disabled`]
  const markerLat = locationData[`checked_${direction}_lat`]
  const markerLong = locationData[`checked_${direction}_long`]
  const polygons = locationData['polygons']
  const apiKey = locationData['api_key']
  const tooltipId = `check-log-location-${checkLog.id}-${direction}`
  const timeTrackableName = checkLog.is_child_time_entry
    ? checkLog.checked_in_name
    : checkLog.construction_object_short_name
  const mapModalTitle = t('checked_modal_title', {
    full_name: checkLog.checkable_full_name,
    time_trackable_name: timeTrackableName
  })

  const clickHandler = event => {
    event.stopPropagation()
    event.preventDefault()

    onClick(mapModalTitle, markerLat, markerLong, polygons, apiKey)
  }

  if (checkedWithinArea) {
    return <CheckedWithinAreaIcons onClick={clickHandler} tooltipId={tooltipId} />
  } else if (gpsDisabled) {
    return <GpsDisabledIcons tooltipId={tooltipId} />
  } else if (checkedWithinArea === null) {
    return <CheckLogLocationMarker onClick={clickHandler} tooltipId={tooltipId} />
  } else {
    return <CheckedOutsideAreaIcons distance={distance} onClick={clickHandler} tooltipId={tooltipId} />
  }
}

const CheckedWithinAreaIcons = ({ onClick, tooltipId }) => (
  <IconWithTooltip
    icon='mapMarker'
    tooltipText={t('checked_within_area')}
    tooltipId={tooltipId}
    onClick={onClick}
    className='text-success'
  />
)

const CheckLogLocationMarker = ({ onClick, tooltipId }) => (
  <IconWithTooltip
    icon='mapMarker'
    tooltipText={t('nfc_check_log_location')}
    tooltipId={tooltipId}
    onClick={onClick}
    className='text-primary'
  />
)

const CheckedOutsideAreaIcons = ({ distance, onClick, tooltipId }) => (
  <div onClick={onClick} className='text-danger d-flex align-items-center'>
    <IconWithTooltip
      icon='mapMarker'
      tooltipText={t('checked_outside_area')}
      tooltipId={tooltipId}
      className='d-flex bg-danger text-white mr-1'
    />
    {distance}
  </div>
)

const GpsDisabledIcons = ({ tooltipId }) => (
  <IconWithTooltip icon='gpsDisabled' tooltipId={tooltipId} tooltipText={t('checked_with_gps_disabled')} />
)

const IconWithTooltip = ({ icon, tooltipId, tooltipText, className, onClick }) => (
  <React.Fragment>
    <Icon
      icon={icon}
      className={classNames('check-log-icon', className)}
      data-tip
      data-for={tooltipId}
      onClick={onClick}
    />
    <Tooltip id={tooltipId} className='accented' place='bottom' effect='solid'>
      {tooltipText}
    </Tooltip>
  </React.Fragment>
)

const CheckLogDuration = ({ duration, invalid }) => (
  <span className={classNames({ 'text-danger': invalid })}>{duration}</span>
)

export { CheckLogLocation, CheckLogCheckedWith, CheckLogDates, CheckLogDuration, IconWithTooltip }
