import React, { useState, useCallback } from 'react'

import classNames from 'classnames'

import { t } from '../../utils/i18n'
import Icon from '../Icon'
import EmployeesList from './EmployeesList'
import ScheduleForm from './Forms/ScheduleForm'
import ShiftDayList from './ShiftDay'

const ShiftScheduleInfo = ({ schedule, handleOpen, open, loading }) => {
  return (
    <div
      className='shift-schedule__info-container'
      onClick={e => {
        e.nativeEvent.stopImmediatePropagation()
        handleOpen(!open)
      }}
    >
      <div className='shift-schedule__info'>
        <div className='shift-schedule__name'>{schedule.name}</div>
        <div className='shift-schedule__objects-info'>
          <Icon icon='mapMarker' className='mr-1' />
          <div className='text-truncate'>{schedule.construction_object_short_name}</div>
        </div>
      </div>
      <div className='expander'>
        <i className={loading ? 'fa fa-spinner fa-spin' : `fa fa-sort-${open ? 'up' : 'down'}`} />
      </div>
    </div>
  )
}

const ShiftSchedule = ({ schedule, datesMetadata, searchTerm, openedItems, toggleOpenedItems }) => {
  const [loading, setLoading] = useState(false)
  const [hasEmployees, setHasEmployees] = useState(false)
  const [formOpen, setFormOpen] = useState(false)

  const { id, color, shifts } = schedule

  const handleOpen = useCallback(
    opened => {
      opened && setLoading(true)

      setHasEmployees(false)
      toggleOpenedItems(id)
    },
    [setLoading, toggleOpenedItems, id]
  )

  const open = openedItems.includes(id)

  const formProps = {
    action: 'edit',
    title: t('v2.shifts.index.form.title_edit'),
    scheduleId: schedule.id
  }

  return (
    <>
      <div className={classNames('schedule', { open })} data-schedule-id={id}>
        <div className='shift-schedule'>
          <span
            className={classNames('shift-schedule__color round-top', { 'round-bottom': !hasEmployees })}
            style={{ backgroundColor: color }}
          />
          <div className='shift-schedule__main'>
            <ShiftScheduleInfo schedule={schedule} handleOpen={handleOpen} open={open} loading={loading} />
          </div>

          <ShiftDayList datesMetadata={datesMetadata} shifts={shifts} schedule={schedule} />
        </div>
        {open && (
          <EmployeesList
            schedule={schedule}
            setLoading={setLoading}
            setHasEmployees={setHasEmployees}
            datesMetadata={datesMetadata}
            searchTerm={searchTerm}
          />
        )}
        {open && schedule.can_manage && (
          <div className='shift-schedule__actions d-flex align-items-center'>
            <button type='button' className='shift-schedule__action d-flex pl-3' onClick={() => setFormOpen(true)}>
              <i className='icon fa fa-pencil mr-2' />
              <span>Labot maiņu</span>
            </button>
          </div>
        )}
      </div>
      {formOpen && <ScheduleForm {...formProps} setFormOpen={setFormOpen} />}
    </>
  )
}

export default ShiftSchedule
