import React, { useState, useEffect } from 'react'

import axios from 'axios'
import { FormProvider } from 'react-hook-form'

import { showConfirmation } from '../../../../utils/confirmations'
import { t } from '../../../../utils/i18n'
import { url } from '../../../../utils/urlGenerator'
import { TextField, SelectField } from '../../../Forms/HookFields'
import ModalFormButtons from '../../common/ModalFormButtons'
import TimeFields from '../TimeFields'
import useShiftForm from './useShiftForm'

const ShiftForm = ({ shift, closeModal, schedule, metadata, employee }) => {
  const [switachbleSchedules, setSwitacbleSchedules] = useState([])

  const { future } = metadata
  const showSwitchableScheduleInput = future && shift

  const { form, sendRequest, formData } = useShiftForm({
    shift,
    employee,
    schedule,
    metadata,
    closeModal
  })

  useEffect(() => {
    if (showSwitchableScheduleInput) {
      const fetchSchedules = async () => {
        const { data } = await axios.get(
          url('v2/schedules/autocomplete', {
            schedule_id: schedule.id,
            employee_id: employee.id
          })
        )

        setSwitacbleSchedules(data)
      }

      fetchSchedules()
    }
  }, [employee.id, schedule.id, showSwitchableScheduleInput])

  const confirmShiftDelete = () => {
    showConfirmation({
      isOpen: true,
      closeOnConfirm: true,
      onConfirm: () => sendRequest(formData, 'deleted'),
      text: t('confirm_destroy'),
      confirmButton: { title: t('destroy'), type: 'danger' }
    })
  }

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    getFieldState,
    register
  } = form

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(values => sendRequest(values, 'updated'))} className='react-form shift-form'>
        <div className='modal-form-container'>
          {showSwitchableScheduleInput && (
            <SelectField
              name='switchable_schedule_id'
              label={t('v2.shift_employee_days.form.fields.shift')}
              control={control}
              options={switachbleSchedules}
              withImages
            />
          )}
          <TimeFields control={control} setValue={setValue} initialValues={formData} />
          <TextField
            control={control}
            name='comment'
            label={t('v2.shifts.index.form.fields.comment')}
            getFieldState={getFieldState}
            register={register}
            textArea
          />
        </div>
        <ModalFormButtons
          isSubmitting={isSubmitting}
          closeModal={closeModal}
          onDestroyClick={shift && confirmShiftDelete}
        />
      </form>
    </FormProvider>
  )
}

export default ShiftForm
