import React from 'react'

import classNames from 'classnames'

import { t } from '../../utils/i18n'
import Avatar from '../Avatar'
import Icon from '../Icon'
import ModalForm from '../ModalForm'
import Tooltip from '../Tooltip'

class UserProfile extends React.Component {
  state = { isOpen: false }

  toggleProfileOptions(isOpen) {
    this.setState({ isOpen })
  }

  renderProfileOptions() {
    const { profile_settings_url, sign_out_url } = this.props

    return (
      <div className='profile-options'>
        <ModalForm
          url={profile_settings_url}
          title={t('v2.settings.users.form.title')}
          button={this.renderProfileSettingsContent}
        />

        <a data-method='delete' href={sign_out_url} className='profile-option'>
          <Icon icon='logOut' />
          {t('sign_out')}
        </a>
      </div>
    )
  }

  renderProfileSettingsContent = onClick => (
    <div className='profile-option' onClick={onClick}>
      <Icon icon='cog' />
      {t('tabs.user_settings')}
    </div>
  )

  render() {
    const {
      current_user: { first_name, last_name },
      avatar_src
    } = this.props

    return (
      <div className='current-user-info-wrapper'>
        <div
          className={classNames('current-user-info', { 'profile-options-open': this.state.isOpen })}
          data-tip
          data-for='profile-options-tooltip'
        >
          <div className='current-user-credentials text-right'>
            <div className='current-user-name'>{first_name}</div>
            <div className='current-user-name'>{last_name}</div>
          </div>
          <div className='current-user-profile'>
            <Avatar src={avatar_src} />
          </div>
        </div>
        <Tooltip
          id='profile-options-tooltip'
          place='bottom'
          className='profile-options-toggler white'
          effect='solid'
          offset={{ right: -41 }}
          globalEventOff='click'
          afterHide={() => this.toggleProfileOptions(false)}
          afterShow={() => this.toggleProfileOptions(true)}
          event='click'
          clickable
        >
          {this.renderProfileOptions()}
        </Tooltip>
      </div>
    )
  }
}

export default UserProfile
