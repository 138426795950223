import React, { useState, useCallback } from 'react'

import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'

import { t } from '../../utils/i18n'
import { NewButton } from '../ModalForm'
import ToggleButton from '../ToggleButton'
import ScheduleForm from './Forms/ScheduleForm'
import { SelectedShiftItemsProvider } from './SelectedShiftItemsContext'
import ShiftSchedule from './ShiftSchedule'
import UnitModal from './UnitModal'
import { useSchedulesContext } from './contexts/SchedulesContext'

dayjs.extend(isToday)

const ShiftSchedulesList = ({ canCreateSchedule, searchTerm, ...others }) => {
  const [openedItems, setOpenedItems] = useState([])
  const { loading, schedules } = useSchedulesContext()

  const translationName = loading ? 'loading_text' : 'no_data_text'

  const formProps = {
    action: 'new',
    title: t('v2.shifts.index.form.title_new')
  }

  const toggleOpenedItems = useCallback(
    id => {
      setOpenedItems(prevState => {
        const indexInPrevState = prevState.indexOf(id)

        if (indexInPrevState > -1) {
          return [...prevState.slice(0, indexInPrevState), ...prevState.slice(indexInPrevState + 1)]
        } else {
          return [...prevState, id]
        }
      })
    },
    [setOpenedItems]
  )

  return (
    <SelectedShiftItemsProvider>
      <div className='shift-schedules mb-2'>
        {schedules.length ? (
          schedules.map(schedule => (
            <ShiftSchedule
              key={schedule.id}
              schedule={schedule}
              searchTerm={searchTerm}
              openedItems={openedItems}
              toggleOpenedItems={toggleOpenedItems}
              {...others}
            />
          ))
        ) : (
          <div className='scheduler-empty-item'>{t(`components.shift_list.${translationName}`)}</div>
        )}
      </div>
      {canCreateSchedule && (
        <ToggleButton buttonText={t('v2.shifts.index.add_subject')} as={NewButton}>
          {({ toggleOpen }) => <ScheduleForm {...formProps} setFormOpen={toggleOpen} />}
        </ToggleButton>
      )}
      <UnitModal />
    </SelectedShiftItemsProvider>
  )
}

export default ShiftSchedulesList
