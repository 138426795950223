import React from 'react'

import ActiveTable from '../ActiveTable'
import NfcCheckLogForm, { useNfcCheckLogFormik } from '../Forms/NfcCheckLogForm'
import MapModal from '../MapModal'
import {
  checkOut,
  checkOutSelected,
  editFormik,
  closeTimeEntry,
  discard,
  discardConsructionObjectTimeEntry,
  discardSelectedTimeRecords
} from '../elements/actionDefinitions'

export const formikForm = { Form: NfcCheckLogForm, useForm: useNfcCheckLogFormik }

class NfcCheckLogsTable extends React.Component {
  state = { modalMapOpen: false, mapModalTitle: '', markerLat: '', markerLong: '', polygons: '', apiKey: '' }
  pathPrefix = 'v2/nfc_check_logs'

  actions = {
    row: [
      editFormik({ pathPrefix: this.pathPrefix, title: 'v2.nfc_check_logs.edit.title', formikForm }),
      checkOut('id'),
      closeTimeEntry(),
      discard(this.pathPrefix),
      discardConsructionObjectTimeEntry()
    ],
    header: [checkOutSelected(this.pathPrefix), ...(this.props.business_industry ? [discardSelectedTimeRecords()] : [])]
  }

  toggleMapModal = () => {
    this.setState(ps => ({ modalMapOpen: !ps.modalMapOpen }))
  }

  openMapModal = (mapModalTitle, markerLat, markerLong, polygons, apiKey) => {
    this.setState({ modalMapOpen: true, mapModalTitle, markerLat, markerLong, polygons, apiKey })
  }

  renderMapModal() {
    const { modalMapOpen, mapModalTitle, ...mapProps } = this.state

    return <MapModal {...mapProps} modalOpen={modalMapOpen} toggleModal={this.toggleMapModal} title={mapModalTitle} />
  }

  renderTable() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        pathPrefix={this.pathPrefix}
        translationPath='nfc_check_logs'
        highlightedBy='checked_in'
        dangerBy='invalid_attendance'
        formParams={this.formParams}
        formikForm={formikForm}
      />
    )
  }

  render() {
    return (
      <>
        {this.renderTable()}
        {this.renderMapModal()}
      </>
    )
  }
}

export default NfcCheckLogsTable
