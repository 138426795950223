import React, { useState } from 'react'

import ReactDOM from 'react-dom'

import { t } from '../../../utils/i18n'
import Modal from '../../Modal'
import DayForm from '../Forms/DayForm'
import FormToggler from '../common/FormToggler'
import ShiftHeader from '../common/ShiftHeader'
import ShiftModalHeader from '../common/ShiftModalHeader'
import useDaySummary from '../hooks/useDaySummary'
import Shifts from './Shifts'

const DayModal = ({ shift, closeModal, metadata, schedule }) => {
  const [showForm, setShowForm] = useState(false)
  const { dateIso, weekDay, date } = metadata

  const [summary, loading] = useDaySummary({ schedule, dateIso })

  const { shifts } = summary

  // TODO: EDLUS-1417 No idea about why allowed to update day but not see registrations
  const forceFormOpen = (!loading && !shifts?.length) || !schedule.can_manage

  const showShifts = schedule.can_manage && shifts?.length > 0

  return ReactDOM.createPortal(
    <Modal
      isOpen
      className='modal-lg modal-dialog-centered modal--new'
      onClose={closeModal}
      header={<ShiftModalHeader schedule={schedule} />}
    >
      <>
        <ShiftHeader weekDay={weekDay} date={date} />
        <hr className='delimiter w-auto' />
        {showShifts && <Shifts shifts={shifts} loading={loading} />}
        {!forceFormOpen && (
          <>
            <hr className='delimiter w-auto mt-4' />
            <FormToggler
              open={showForm}
              onClick={() => setShowForm(opn => !opn)}
              shift={shift}
              schedule={schedule}
              metadata={metadata}
            >
              {t(`v2.shift_days.form.title_edit`)}
            </FormToggler>
          </>
        )}
        {(showForm || forceFormOpen) && <DayForm schedule={schedule} closeModal={closeModal} metadata={metadata} />}
      </>
    </Modal>,
    document.querySelector('.modal-container')
  )
}

export default DayModal
