import React from 'react'

import classNames from 'classnames'
import { useFormContext } from 'react-hook-form'

import { t } from '../../../../../utils/i18n'
import SwitchField from '../../../../Forms/HookFields/SwitchField'
import TextField from '../../../../Forms/HookFields/TextField'
import MapCircle from '../../../../Maps/MapCircle'
import MapContainer from '../../../../Maps/MapContainer'
import drawingController from '../../../../Maps/drawingController'
import polygonToText from '../../../../Maps/polygonToText'
import styles from './LocationModule.module.scss'

export const LocationModule = () => {
  const { getValues, register, setValue, getFieldState } = useFormContext()
  const { invalid: invalidPolygon } = getFieldState('location_polygon')

  const onAutocompleteChanged = place => setValue('location_address', place.formatted_address)
  const onPolygonChanged = polygonPath => {
    setValue('location_drawing_type', 'polygon')
    setValue('location_polygon', polygonToText(polygonPath.getArray()))
  }

  const onCircleChanged = circle => {
    setValue('location_drawing_type', 'circle')
    setValue('location_circle_center', circle.center)
    setValue('location_circle_radius', circle.radius)
    setValue('location_polygon', polygonToText(MapCircle.polygonFromCircle(circle)))
  }

  const onDestroy = () => {
    setValue('location_drawing_type', null)
    setValue('location_circle_center', null)
    setValue('location_circle_radius', null)
    setValue('location_polygon', null)
  }

  const mapProps = {
    apiKey: getValues('google_maps_api_key'),
    style: { width: '565px', height: '423px' },
    autocompleteProps: {
      targetId: 'location_address',
      onChanged: onAutocompleteChanged
    },
    polygon: getValues('location_polygon_points'),
    circleCenter: getValues('location_circle_center'),
    circleRadius: getValues('location_circle_radius'),
    drawingType: getValues('location_drawing_type'),
    drawable: getValues('location_polygon_points') && getValues('location_polygon_points').length === 0,
    drawingController: {
      ...drawingController,
      drawingModes: ['polygon', 'circle'],
      onPolygonChanged,
      onCircleChanged,
      onDestroy
    }
  }

  return (
    <div className='module-wrapper'>
      <hr className='mt-4 mb-2' />
      <div className={classNames('mb-2', styles.moduleTitle)}>{t('v2.tasker.tasks.form.modules.location')}</div>
      <div className='fields'>
        <div className='location-module'>
          <TextField name='location_address' placeholder={t('v2.tasker.tasks.form.location_address')} />

          <SwitchField
            name='check_within_location'
            label={t('v2.tasker.tasks.form.check_within_location')}
            wrapperClassName='mt-3'
          />

          <input className='d-none' {...register('location_drawing_type')} />
          <input className='d-none' {...register('location_polygon')} />
          <input className='d-none' {...register('location_circle_radius')} />
          <input className='d-none' {...register('location_circle_center')} />

          <div className={classNames('mt-3', styles.mapWrapper)}>
            <MapContainer {...mapProps} />
          </div>

          {invalidPolygon && (
            <p className={classNames('mt-1', styles.mapError)}>{t('v2.tasker.tasks.form.location_polygon_warning')}</p>
          )}
        </div>
      </div>
    </div>
  )
}
