import React from 'react'

import Tooltip from './Tooltip'

const IconCell = ({ children, id, showIcon, ...others }) => (
  <div className='cell-with-icon'>
    <div className='text-truncate'>{children}</div>
    {showIcon && <IconWithTooltip id={id} {...others} />}
  </div>
)

const IconWithTooltip = ({ id, tooltipText, iconComponent }) => {
  const tooltipId = `cell-icon-${id}`

  return (
    <React.Fragment>
      {iconComponent(tooltipId)}
      <Tooltip id={tooltipId} className='accented' place='bottom' effect='solid'>
        {tooltipText}
      </Tooltip>
    </React.Fragment>
  )
}

export default IconCell
