import React from 'react'

import useTranslate from '../../../hooks/useTranslate'
import { TextField, DateField, CheckField, NumberField } from '../../Forms/HookFields'

const RegistrationForm = ({ formContext, onSubmit, isSubmitting, editing, extraButtons }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.Registration')

  const {
    watch,
    reset,
    formState: { isDirty, errors: _errors }
  } = formContext

  const watchCitsPasutitajs = watch('citsPasutitajs')

  return (
    <div className='row mt-3'>
      <div className='col-xl-6'>
        <form onSubmit={onSubmit} className='react-form'>
          <div className='row'>
            <div className='col-4'>
              <DateField
                name='buvniecibasUzsaksanasDatums'
                label={t('.buvniecibasUzsaksanasDatums')}
                withIcon
                disabled={editing}
                required
              />
            </div>
            <div className='col-4'>
              <DateField name='ligumaDatums' label={t('.buvdarbuLigumaDatums')} withIcon required />
            </div>
            <div className='col-4'>
              <NumberField name='ligumaSumma' label={t('.buvdarbuLigumaSumma')} required />
            </div>
          </div>
          <div className='row'>
            <div className='col-9'>
              <CheckField name='citsPasutitajs' label={t('.citsPasutitajs')} />
            </div>
          </div>
          {watchCitsPasutitajs && (
            <div className='row'>
              <div className='col-6'>
                <TextField name='pasutitajaNosaukums' label={t('.pasutitajaNosaukums')} required />
              </div>
              <div className='col-6'>
                <TextField name='pasutitajaRegNr' label={t('.pasutitajaRegistracijasNr')} required />
              </div>
            </div>
          )}
          {isDirty && editing && (
            <div className='row'>
              <div className='col-12'>
                <TextField name='pamatojums' label={t('.pamatojums')} required textArea />
              </div>
            </div>
          )}
          <div className='mt-3 d-flex gap-3'>
            <button className='btn btn-primary' type='submit' disabled={!isDirty || isSubmitting}>
              {t('save')}
            </button>
            {isDirty && (
              <button className='btn btn-secondary' type='button' onClick={() => reset()}>
                {t('cancel')}
              </button>
            )}
            {extraButtons}
          </div>
        </form>
      </div>
    </div>
  )
}

export default RegistrationForm
