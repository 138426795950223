import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../../utils/i18n'
import { FilterContainer, SearchField, SimpleSelect } from '../../FilterComponents'

class ExternalDevicesFilter extends React.Component {
  hiddenFilters = () => {
    const { store } = this.props

    return (
      <React.Fragment>
        <SimpleSelect
          store={store}
          selectable='account_id'
          options={this.props.accounts_options}
          dataContains='select-account-filter'
          isMulti
        />
        <SimpleSelect
          store={store}
          selectable='kind'
          options={this.props.kinds_options}
          dataContains='select-kind-filter'
          isMulti
        />
        <SimpleSelect
          store={store}
          selectable='latest_healthcheck_host'
          options={this.props.latest_healthcheck_hosts_options}
          isMulti
          dataContains='select-latest-healthcheck-host-filter'
        />
        <SimpleSelect
          store={store}
          selectable='status'
          options={this.props.statuses_options}
          dataContains='select-status-filter'
        />
      </React.Fragment>
    )
  }

  render() {
    const { store, filters } = this.props

    return (
      <FilterContainer store={store} hiddenFilters={this.hiddenFilters} filters={filters}>
        <SearchField store={store} placeholder={t('filters.search_external_devices')} />
      </FilterContainer>
    )
  }
}

export default observer(ExternalDevicesFilter)
