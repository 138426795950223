import { showDefaultErrorModal } from '../../../utils/confirmations'

const errorString = errors => errors.map(({ message }) => message).join('; ')

const getFormErrorMap = errors =>
  Object.entries(errors).reduce(
    (accumulator, [currentKey, keyErrors]) => accumulator.set(currentKey, errorString(keyErrors)),
    new Map()
  )

export const handleHookFormError = ({ error, setError }) => {
  if (error.response?.status === 422) {
    const responseData = error.response.data

    getFormErrorMap(responseData).forEach((value, key) => {
      setError(key, { type: 'custom', message: value })
    })
  } else {
    showDefaultErrorModal()
  }
}
