import React from 'react'

import classNames from 'classnames'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { t } from '../../../utils/i18n'
import Icon from '../../Icon'

dayjs.extend(customParseFormat)

const DateTimeCell = ({ dateTime, isDelayed, hideDateTime = false }) => {
  const dayjsObject = dayjs(dateTime, t('time.formats.default_human_js'))
  const formattedDate = dateTime ? dayjsObject.format(t('date.formats.default_human_js')) : '-'
  const formattedTime = dateTime ? dayjsObject.format(t('time.formats.time_human_js')) : '-'

  return (
    <div className='date-time-cell'>
      <span className={classNames('date-item', { 'text-danger': isDelayed })}>{hideDateTime ? '' : formattedDate}</span>
      <div className='time-item-container'>
        <div className={classNames('time-item', { 'text-danger': isDelayed })}>{hideDateTime ? '' : formattedTime}</div>
        <div className='date-time-cell-warning'>
          {isDelayed && <Icon icon='warningFilled' className='text-danger' />}
        </div>
      </div>
    </div>
  )
}

export default DateTimeCell
