import React from 'react'

import ActiveTable from '../ActiveTable'
import { edit, remove, removeSelected } from '../elements/actionDefinitions'

class UserConstructionObjectsTable extends React.Component {
  pathPrefix = 'v2/construction_object_users'

  formParams = {
    context: 'user',
    construction_object_user: { user_id: this.props.user_id }
  }

  actions = {
    row: [
      edit(this.pathPrefix, 'v2.construction_object_users.contexts.user.edit.title', this.formParams),
      remove(this.pathPrefix)
    ],
    header: [removeSelected(this.pathPrefix)]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        pathPrefix={this.pathPrefix}
        actions={this.actions}
        pathResourceId='construction_object_id'
        inactiveBy='archived'
        translationPath={['construction_object_users', 'contexts', 'user']}
        formParams={this.formParams}
        reloadPageOnSuccess={true}
        onRowClick='openEditForm'
      />
    )
  }
}

export default UserConstructionObjectsTable
