import React from 'react'

import classNames from 'classnames'
import { t } from 'i18n-js'
import { useController, useFormContext } from 'react-hook-form'

import Switch from '../../../Forms/Fields/Switch'
import Icon from '../../../Icon'
import styles from './styles/Sidebar.module.scss'

const SettingToggle = ({ icon, name, disabled = false }) => {
  const translatedTitle = t(`activerecord.attributes.tasker/task.${name}`)
  const { control, submitting } = useFormContext()
  const { field } = useController({ control, name })
  const isDisabled = disabled || submitting

  return (
    <div className={classNames(styles.moduleToggle, 'module-toggle', field.value ? styles.enabled : 'untoggled')}>
      {isDisabled && <div className={styles.disabledOverlay}></div>}
      <div className='d-flex align-items-center'>
        {icon && (
          <div className={styles.moduleIcon}>
            <Icon icon={icon} className={styles.moduleSvg} />
          </div>
        )}
        <div>{translatedTitle}</div>
      </div>
      <Switch {...field} checked={field.value} disabled={isDisabled} />
    </div>
  )
}

export default SettingToggle
