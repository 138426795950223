import { showConfirmation } from '../utils/confirmations'
import { t } from '../utils/i18n'

class TaskForm {
  static selector = '.edit_tasker_task, .new_tasker_task'

  constructor(form) {
    this.form = form

    this.bindElements()
    this.bindEvents()
    this.toggleTaskTimesFields()
    this.toggleForbiddenTimePeriodsInputs()
    this.toggleRegularityContainer()
  }

  bindElements() {
    this.selectors = {
      timeTrackingSwitch: this.form.querySelector('#tasker_task_time_tracking_enabled'),
      timeTrackerOptionsContainer: this.form.querySelector('.time-tracking-options'),
      regularitySwitch: this.form.querySelector('#tasker_task_regularity_enabled'),
      regularityContainer: this.form.querySelector('.regularity-container'),
      deadlineField: this.form.querySelector('div.tasker_task_deadline'),
      startingTimeField: this.form.querySelector('div.tasker_task_starting_time'),
      filesInput: this.form.querySelector('#tasker_task_files'),
      filesUploadContainer: this.form.querySelector('.files-upload-container'),
      purgeAttachmentIcons: this.form.querySelectorAll('.purge-attachment'),
      updateWithAllRelatedTasksInput: this.form.querySelector('#tasker_task_update_all_related_tasks'),
      responsibleField: this.form.querySelector('#tasker_task_responsible'),
      responsibleFields: {
        user: this.form.querySelector('#tasker_task_responsible_user_id'),
        usergroup: this.form.querySelector('#tasker_task_responsible_user_group_id')
      }
    }
  }

  bindEvents() {
    const {
      timeTrackingSwitch,
      regularitySwitch,
      filesInput,
      purgeAttachmentIcons,
      updateWithAllRelatedTasksInput,
      responsibleField
    } = this.selectors

    if (responsibleField) {
      responsibleField.addEventListener('change', this.setResponsibleIds)
    }

    if (timeTrackingSwitch) {
      timeTrackingSwitch.addEventListener('change', this.toggleForbiddenTimePeriodsInputs)
    }

    if (regularitySwitch) {
      regularitySwitch.addEventListener('change', this.toggleRegularityContainer)
      regularitySwitch.addEventListener('change', this.toggleTaskTimesFields)
    }

    if (filesInput) {
      filesInput.addEventListener('change', this.onFileChange)
    }

    if (purgeAttachmentIcons) {
      purgeAttachmentIcons.forEach(item => item.addEventListener('click', this.onAttachmentPurge))
    }

    if (updateWithAllRelatedTasksInput?.value === 'false') {
      document.querySelector('.modal .btn-container .btn-success')?.addEventListener('click', this.showConfirmation)
    }

    // TODO: remove timeout
    setTimeout(() => {
      this.form.querySelectorAll('input, select, textarea').forEach(input => {
        if (input === responsibleField || input === updateWithAllRelatedTasksInput) return

        input.addEventListener('change', _e => (this.form.changed = true))
      })
    }, 100)
  }

  setResponsibleIds = () => {
    const { responsibleField, responsibleFields } = this.selectors
    const [responsibleType, responsibleId] = responsibleField.value.split('_')

    Object.entries(responsibleFields).forEach(([type, field]) => {
      if (type === responsibleType) {
        field.value = responsibleId
      } else {
        field.value = ''
      }
    })
  }

  onFileChange = () => {
    const { filesInput, filesUploadContainer } = this.selectors
    const { maxFileCount, allowedFileCount, maxFileSize } = filesInput.dataset

    const files = filesInput.files
    const totalSize = Array.from(files).reduce((acc, file) => acc + file.size, 0)

    const exceedsSizeLimit = totalSize > maxFileSize
    const exceedsCountLimit = files.length > allowedFileCount

    if (exceedsSizeLimit || exceedsCountLimit) {
      filesInput.value = null
      filesUploadContainer.innerHTML = ''

      const translationPath = 'v2.tasker.tasks.file_upload.'
      const messages = []

      if (exceedsCountLimit) {
        messages.push(t(`${translationPath}max_file_count_exceeded`, { max_file_count: maxFileCount }))
      }

      if (exceedsSizeLimit) {
        messages.push(t(`${translationPath}max_file_size_exceeded`, { max_file_size: `${maxFileSize / 1000000} MB` }))
      }

      alert(messages.join('\n'))
    } else {
      const html = Array.from(files).map(
        f => `<span class='tag'><i data-type='${f.type}' class='fa fa-lg'></i> ${f.name}</span>`
      )

      filesUploadContainer.innerHTML = html.join(' ')
    }
  }

  onAttachmentPurge = event => {
    const input = document.createElement('input')

    input.type = 'hidden'
    input.name = 'tasker_task[purgeable_file_ids][]'
    input.value = event.target.parentNode.dataset.attachmentId

    this.form.append(input)
    this.form.changed = true

    event.target.parentNode.remove()
  }

  toggleVisibility(element, isVisible) {
    if (!element) return

    element.classList[isVisible ? 'add' : 'remove']('d-none')
  }

  toggleForbiddenTimePeriodsInputs = () => {
    const { timeTrackingSwitch, timeTrackerOptionsContainer } = this.selectors

    if (timeTrackingSwitch) {
      this.toggleSwitchableContainer(timeTrackingSwitch, timeTrackerOptionsContainer)
    }
  }

  toggleRegularityContainer = () => {
    const { regularitySwitch, regularityContainer } = this.selectors

    if (regularitySwitch) {
      this.toggleSwitchableContainer(regularitySwitch, regularityContainer)
    }
  }

  toggleTaskTimesFields = () => {
    const { deadlineField, startingTimeField, regularitySwitch } = this.selectors

    if (regularitySwitch && regularitySwitch.checked) {
      this.toggleVisibility(deadlineField, true)
      this.toggleVisibility(startingTimeField, true)
    } else if (deadlineField && startingTimeField) {
      this.toggleVisibility(deadlineField, false)
      this.toggleVisibility(startingTimeField, false)
    }
  }

  toggleSwitchableContainer = (switchInput, container) => {
    if (switchInput.checked) {
      container.classList.add('animating-in')
      container.classList.remove('hidden')
      container.classList.remove('animating-out')
    } else {
      container.classList.add('animating-out')
      container.classList.add('hidden')
      container.classList.remove('animating-in')
    }
  }

  showConfirmation = event => {
    if (this.selectors.updateWithAllRelatedTasksInput.checked || !this.form.changed) return

    showConfirmation({
      event: event,
      isOpen: true,
      closeOnConfirm: true,
      text: t('confirm_task_changes'),
      confirmButton: { title: t('save'), type: 'danger' }
    })
  }
}

export default TaskForm
