import React from 'react'

import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'

import MapAutocomplete from './MapAutocomplete'
import MapCircle from './MapCircle'
import MapDrawingManager from './MapDrawingManager'
import MapPolygon from './MapPolygon'
import fitFigureInMap from './fitFigureInMap'

class MapContainer extends React.Component {
  initialCoords = {
    lat: 56.946285,
    lng: 24.105078
  }

  initializeServices = (_mapProps, map) => {
    const { polygon, autocompleteProps, drawingController, drawingType, readOnly } = this.props

    new MapAutocomplete(map, autocompleteProps)
    const mapDrawingManager = new MapDrawingManager(map, drawingController)

    mapDrawingManager.setupListeners()

    if (polygon && polygon.length > 0 && polygon.flat().length > 0) {
      let { figure, points } = drawingType === 'circle' ? this.loadCircle(map) : this.loadPolygon(map)

      mapDrawingManager.disableNewDrawings()
      this.fitFigureInMap(map, points)

      if (!readOnly) {
        figure.setEditable(true)
        figure.setDraggable(true)
        mapDrawingManager.figure = figure
      }
    }
  }

  loadCircle = map => {
    const { drawingController, circleCenter, circleRadius } = this.props

    return new MapCircle(map, circleCenter, circleRadius, drawingController).draw()
  }

  loadPolygon = map => {
    const { drawingController, polygon } = this.props

    return new MapPolygon(map, polygon, drawingController).draw()
  }

  fitFigureInMap(map, points) {
    const { markerLat, markerLong } = this.props

    if (markerLat && markerLong) {
      points = [...points, new google.maps.LatLng(markerLat, markerLong)]
    }

    fitFigureInMap(map, points)
  }

  render() {
    const { google, markerLat, markerLong, style } = this.props

    return (
      <Map
        google={google}
        zoom={11}
        initialCenter={this.initialCoords}
        mapTypeControl
        style={style}
        onReady={this.initializeServices}
      >
        <Marker position={{ lat: markerLat, lng: markerLong }} />
      </Map>
    )
  }
}

export default GoogleApiWrapper(props => ({
  apiKey: props.apiKey,
  libraries: ['drawing', 'places', 'geometry']
}))(MapContainer)
