import { useRef, useEffect } from 'react'

export default ({ ref, onOutsideClick, onEsc = onOutsideClick }) => {
  const initialClick = useRef(true)

  useEffect(() => {
    const handleClickOutside = event => {
      if (initialClick.current) {
        initialClick.current = false
        return
      }

      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick(event)
      }
    }

    const handleEsc = ({ key }) => {
      if (key === 'Escape') onEsc()
    }

    window.addEventListener('click', handleClickOutside)
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('click', handleClickOutside)
      window.removeEventListener('keydown', handleEsc)
    }
  }, [ref, onOutsideClick, onEsc])
}
