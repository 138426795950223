import React, { useState } from 'react'

import Axios from 'axios'
import ReactDOM from 'react-dom'

import initialize from '../initializers'
import { t } from '../utils/i18n'
import Icon from './Icon'
import Modal from './Modal'

export const NewButton = ({ children, ...props }) => (
  <div className='new-resource-button'>
    <button className='btn btn-sm btn-primary py-2' {...props}>
      <Icon icon='iconPlusCircle' className='mr-2' />
      {children}
    </button>
  </div>
)

const ModalForm = props => (props.setIsOpen ? <ControlledModalForm {...props} /> : <UncontrolledModalForm {...props} />)

const UncontrolledModalForm = props => {
  const [isOpen, setIsOpen] = useState(false)

  return <ControlledModalForm {...props} isOpen={isOpen} setIsOpen={setIsOpen} />
}

class ControlledModalForm extends React.Component {
  state = {}
  activeTab = 'existing'

  componentDidMount() {
    if (this.props.isOpen) {
      this.loadModalContents()
    }
  }

  loadModalContents = async () => {
    const { url } = this.props
    const { data } = await Axios.get(url, { headers: { Accept: 'text/html' } })

    this.injectForm(data)
  }

  toggleModal = async () => {
    const { isOpen, setIsOpen } = this.props

    setIsOpen(!isOpen)

    if (!isOpen) {
      this.loadModalContents()
    }
  }

  submitForm = async () => {
    this.setState({ submitting: true })
    this.toggleInputDisabledState()

    const { method, action } = this.form
    const { reloadPageOnSuccess, onSuccessfulSubmit } = this.props
    const data = new FormData(this.form)
    const headers = { 'Content-Type': 'multipart/form-data' }

    try {
      const response = await Axios({ url: action, method, data, headers })
      const location = reloadPageOnSuccess || !response.headers.location ? window.location : response.headers.location

      if (onSuccessfulSubmit) {
        onSuccessfulSubmit(response)
      }

      window.location = location
    } catch ({ response }) {
      this.injectForm(response.data)
    }
  }

  injectForm(html) {
    this.formContainer().innerHTML = html
    this.form = this.formContainer().querySelector('form')

    this.initializeForm()
    this.toggleTabs()
    this.setState({ submitting: false })
  }

  initializeForm() {
    this.form.addEventListener('submit', e => e.preventDefault())

    this.formInputs().forEach(input => {
      input.addEventListener('keyup', e => (e.key === 'Enter' && input.type !== 'textarea' ? this.submitForm() : true))
    })

    this.tabs().forEach(tab => {
      tab.addEventListener('click', this.toggleTabs)
    })

    initialize(this.formContainer())
  }

  toggleTabs = event => {
    if (!this.form.dataset.tabs) return

    if (event) this.activeTab = event.currentTarget.dataset.tab

    this.tabs().forEach(({ dataset, classList }) => {
      dataset.tab === this.activeTab ? classList.add('active') : classList.remove('active')
    })

    this.inactiveForm.classList.remove('d-block')
    this.activeForm.classList.add('d-block')
  }

  toggleInputDisabledState() {
    if (!this.form.dataset.tabs) return

    this.formInputs().forEach(input => {
      if (!Object.prototype.hasOwnProperty.call(input, 'originalDisabled')) {
        input.originalDisabled = input.disabled
      }
    })

    this.formInputs(this.inactiveForm).forEach(input => {
      input.setAttribute('disabled', 'disabled')
    })

    this.formInputs(this.activeForm).forEach(input => {
      if (!input.originalDisabled) {
        input.removeAttribute('disabled')
      }
    })
  }

  tabs() {
    return this.formContainer().querySelectorAll('[data-tab]')
  }

  formContainer() {
    return document.querySelector('.modal-form-container')
  }

  get activeForm() {
    return this.form.querySelector(`#${this.activeTab}-resource-fields`)
  }

  get inactiveForm() {
    return this.form.querySelector(`#${this.activeTab === 'new' ? 'existing' : 'new'}-resource-fields`)
  }

  formInputs(container = this.form) {
    return container.querySelectorAll('input, select, textarea')
  }

  renderModalToggleButton() {
    const { action, button, image, skipToggleButton, type = 'button' } = this.props

    if (skipToggleButton) {
      return
    } else if (button) {
      return this.renderCustomButton()
    } else if (image) {
      return this.renderButtonImage()
    } else if (action === 'new') {
      if (type === 'link') {
        return this.renderNewResourceLink()
      } else {
        return this.renderNewButton()
      }
    } else if (action === 'edit') {
      return this.renderEditButton()
    }
  }

  renderCustomButton() {
    return this.props.button(this.toggleModal)
  }

  renderButtonImage() {
    return (
      <div className={this.props.imageWrapperClass} onClick={this.toggleModal}>
        <img src={this.props.image} />
      </div>
    )
  }

  renderNewResourceLink() {
    return (
      <div className='new-resource-link'>
        <span onClick={this.toggleModal} className='d-flex align-items-center link'>
          <Icon icon='iconPlusCircle' className='mr-2' />
          <span className='icon-link-text'>{this.props.buttonText}</span>
        </span>
      </div>
    )
  }

  renderNewButton() {
    return <NewButton onClick={this.toggleModal}>{this.props.buttonText}</NewButton>
  }

  renderEditButton() {
    return (
      <button className={`btn btn-primary ${this.props.buttonClass}`} onClick={this.toggleModal}>
        {this.props.toggleButtonText || t('edit')}
      </button>
    )
  }

  renderModal() {
    const { isOpen } = this.props

    return ReactDOM.createPortal(
      <Modal
        isOpen={isOpen}
        className='modal-xl modal-dialog-centered'
        onClose={this.toggleModal}
        title={this.props.title}
        closeOnBackdropClick={false}
      >
        <div className='modal-form-container' />

        <div className='btn-container'>
          <button className='btn btn-success' onClick={this.submitForm} disabled={this.state.submitting}>
            {t('save')}
          </button>

          <button className='btn btn-secondary' onClick={this.toggleModal}>
            {t('cancel')}
          </button>
        </div>
      </Modal>,
      document.querySelector('.modal-container')
    )
  }

  render() {
    if (this.props.toggleModal) {
      this.props.toggleModal(this.toggleModal)
    }

    return (
      <React.Fragment>
        {this.renderModalToggleButton()}
        {this.renderModal()}
      </React.Fragment>
    )
  }
}

export default ModalForm
