import React from 'react'

import { t } from '../../../utils/i18n'
import ActiveTable from '../../ActiveTable'
import IconCell from '../../IconCell'
import { edit, discard, unlock, invite } from '../../elements/actionDefinitions'

class AdminUsersTable extends React.Component {
  store = this.props.store
  pathPrefix = 'admin/admin_users'

  columns = [
    {
      header: t('activerecord.attributes.user.full_name'),
      accessorKey: 'full_name',
      cell: ({
        getValue,
        row: {
          original: { locked, inactive, id }
        }
      }) => {
        let icon, type, text

        if (locked) {
          ;[icon, type, text] = ['ban', 'danger', '.locked_tooltip_text']
        } else if (inactive) {
          ;[icon, type, text] = ['envelope', 'primary', '.inactive_tooltip_text']
        }

        return (
          <IconCell
            id={id}
            showIcon={locked || inactive}
            tooltipText={t('v2.users.index' + text)}
            iconComponent={tooltipId => (
              <i className={`fa fa-${icon} ${type} ml-2 center-icon`} data-tip data-for={tooltipId} />
            )}
          >
            {getValue()}
          </IconCell>
        )
      }
    },
    {
      header: t('activerecord.attributes.user.email'),
      accessorKey: 'email'
    },
    {
      header: t('activerecord.attributes.user.role'),
      accessorKey: 'role'
    },
    {
      header: t('activerecord.attributes.user.last_activity_at'),
      accessorKey: 'last_activity_at'
    }
  ]

  actions = {
    row: [
      edit(this.pathPrefix, 'admin.admin_users.edit.title'),
      unlock(this.pathPrefix),
      invite(this.pathPrefix),
      discard(this.pathPrefix)
    ]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        columns={this.columns}
        actions={this.actions}
        pathPrefix={this.pathPrefix}
        highlightedBy='online'
        dangerBy='locked'
        warningBy='inactive'
        translationPath='admin_users'
      />
    )
  }
}

export default AdminUsersTable
