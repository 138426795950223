import dayjs from 'dayjs'

import { generateId } from '../../../../../utils/generateId'

export const generateSubtask = () => ({
  id: generateId(),
  title: '',
  autogen: true
})

const selectValueFromString = str => (str ? { value: str, label: str } : null)
const selectValue = (val, options) => {
  if (!val) return null

  if (Array.isArray(val)) {
    return options?.filter(({ value }) => val.includes(value))
  } else {
    return options?.find(({ value }) => val === value)
  }
}

export const formData = data => ({
  title: data?.title || null,
  description: data?.description || null,
  starting_time: data?.starting_time ? dayjs(data.starting_time).toDate() : undefined,
  deadline: data?.deadline ? dayjs(data.deadline).toDate() : undefined,
  sub_tasks_attributes: data?.sub_tasks_attributes?.length ? data?.sub_tasks_attributes : [generateSubtask()],
  sub_task_completion_required: data?.sub_task_completion_required || false,
  time_tracking_enabled: data?.time_tracking_enabled || false,
  allow_time_tracking_in_forbidden_periods: data?.allow_time_tracking_in_forbidden_periods || false,
  resume_time_tracking_after_forbidden_period: data?.resume_time_tracking_after_forbidden_period || false,
  concurrent_time_tracking_forbidden: data?.concurrent_time_tracking_forbidden || false,
  time_tracking_forbidden_time_periods: formatForbiddenPeriods(data?.time_tracking_forbidden_time_periods || []),
  images_required: data?.images_required || false,
  receive_task_completion_notification: data?.receive_task_completion_notification || false,
  is_template: data?.is_template || false,
  attached_files: data?.attached_files || [],
  regularity: initializeRegularity(data),
  task_group_id: selectValue(data?.task_group_id, data?.select_options?.task_group_id),
  client_label: selectValueFromString(data?.client_label),
  device_label: selectValueFromString(data?.device_label),
  responsible_users_ids: selectValue(data?.responsible_users_ids, data?.select_options?.responsible_user_ids),
  responsible_user_groups_ids: selectValue(
    data?.responsible_user_groups_ids,
    data?.select_options?.responsible_user_group_ids
  ),
  responsible_user: selectValue(data?.responsible_user_id, data?.select_options?.responsible_user_ids),
  responsible_user_group: selectValue(
    data?.responsible_user_group_id,
    data?.select_options?.responsible_user_group_ids
  ),
  google_maps_api_key: data?.google_maps_api_key || false,
  has_location: data?.has_location || false,
  location_address: data?.location_address || null,
  location_drawing_type: data?.location_drawing_type || 'polygon',
  location_polygon_points: data?.location_polygon_points || null,
  location_circle_center: data?.location_circle_center || null,
  location_circle_radius: data?.location_circle_radius || null,
  check_within_location: data?.check_within_location || false
})

const formatTimeInput = rawTime => {
  if (!rawTime) return undefined
  const hours = parseInt(rawTime.split(':')[0])
  const minutes = parseInt(rawTime.split(':')[1])
  return new Date(0, 0, 0, hours, minutes)
}

const initializeRegularity = data => {
  const inputSettings = data?.regularity

  return {
    interval: inputSettings?.interval || data?.select_options?.regularity?.day_interval_options?.[0] || null,
    ...inputSettings,
    starting_date: dayjs(inputSettings?.starting_date).toDate(),
    end_date: inputSettings?.end_date
      ? dayjs(inputSettings.end_date).toDate()
      : dayjs(undefined).endOf('month').toDate(),
    starting_time: formatTimeInput(inputSettings?.starting_time) || new Date('0'),
    duration_days: inputSettings?.duration_days || data?.select_options?.regularity?.duration_days_options[0],
    duration_time: formatTimeInput(inputSettings?.duration_time)
  }
}

const formatForbiddenPeriods = periods =>
  periods.map(period => ({ start: dayjs(period[0]).toDate(), finish: dayjs(period[1]).toDate() }))
