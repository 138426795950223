import React from 'react'

import classNames from 'classnames'
import { useController, useFormContext } from 'react-hook-form'

const MonthDate = props => {
  const { day, disabled, selected } = props
  const { control } = useFormContext()
  const {
    field: { onChange, value },
    field
  } = useController({
    control,
    name: `[regularity][month][month_dates][date_${day}]`,
    defaultValue: selected ? 'true' : 'false'
  })

  const handleSelectedDate = () => {
    const newValue = value === 'true' ? 'false' : 'true'
    onChange(newValue)
  }

  return (
    <div
      className={classNames('task-regularity-clickable-day task-regularity-month-date', {
        active: value === 'true',
        disabled: disabled
      })}
      onClick={() => !disabled && handleSelectedDate()}
    >
      {day + '.'}
      <input type='hidden' {...field} />
    </div>
  )
}

export default MonthDate
