import React from 'react'

import classNames from 'classnames'

import { t } from '../../utils/i18n'
import Tooltip from '../Tooltip'

export default ({ constructionObjectUser }) => (
  <React.Fragment>
    {notificationIcon(constructionObjectUser, 'receive_email_notifications', 'fa-at')}
    {notificationIcon(constructionObjectUser, 'receive_push_notifications', 'fa-mobile')}
  </React.Fragment>
)

const notificationIcon = (constructionObjectUser, type, iconClass) => {
  const tooltipId = `${type}-${constructionObjectUser.id}`
  const className = classNames('fa', iconClass, { active: constructionObjectUser[type] })

  return (
    <React.Fragment>
      <i data-for={tooltipId} className={className} data-tip />

      <Tooltip id={tooltipId} className='accented' place='bottom' effect='solid'>
        {t(`activerecord.attributes.construction_object_user.${type}`)}
      </Tooltip>
    </React.Fragment>
  )
}
