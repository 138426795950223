import React from 'react'

import classNames from 'classnames'
import { t } from 'i18n-js'
import { useController, useFormContext } from 'react-hook-form'

import { DateField } from '../../../../Forms/HookFields'
import Icon from '../../../../Icon'
import RegularityConfigurationButtons from '../Fields/RegularityModule/ConfigurationButtons'
import RegularityDynamicSelect from '../Fields/RegularityModule/DynamicSelect'
import RegularityPeriodSelection from '../Fields/RegularityModule/PeriodSelection'
import RegularityDeadline from '../Fields/RegularityModule/RegularityDeadline'
import RegularityRepeatMonthdaysSelect from '../Fields/RegularityModule/RepeatMonthdaysSelect'
import RegularityRepeatWeekdaysSelect from '../Fields/RegularityModule/RepeatWeekdaysSelect'
import RegularityFieldRow from '../RegularityFieldRow'
import SectionTitle from '../SectionTitle'
import styles from '../styles/ModuleWrapper.module.scss'

export const RegularityModule = () => {
  const title = t('v2.tasker.tasks.form.modules.regularity')
  const { control, submitting, disableRegularity } = useFormContext()
  const {
    field: { onChange, value: intervalKind }
  } = useController({ control, name: '[regularity][interval_kind]', defaultValue: 'day' })

  return (
    <div className={classNames('regularity-container', styles.moduleWrapper, styles.regularityModule)}>
      <hr className='mt-4 mb-2' />
      <div className={classNames('mb-2', styles.moduleTitle)}>{title}</div>
      <div className={classNames(styles.fields, styles.regularityFields)}>
        <RegularityConfigurationButtons onChange={onChange} value={intervalKind} />

        <RegularityFieldRow>
          <RegularityDynamicSelect name='regularity.interval' intervalKind={intervalKind} />
          <RegularityPeriodSelection />
        </RegularityFieldRow>

        <RegularityFieldRow>
          <div>
            <SectionTitle>{t('v2.tasker.tasks.regularity_common_fields.starting_time')}</SectionTitle>
            <DateField
              name='regularity.starting_time'
              placeholder={t('v2.tasker.tasks.regularity_common_fields.starting_time')}
              disabled={submitting || disableRegularity}
              type='time'
            />
          </div>
          <RegularityDeadline />
        </RegularityFieldRow>

        <div className='d-flex mt-2'>
          {intervalKind === 'week' && <RegularityRepeatWeekdaysSelect />}
          {intervalKind === 'month' && <RegularityRepeatMonthdaysSelect />}
        </div>

        <div className='regularity-info'>
          <Icon icon='infoOutlined' className='info-icon' />
          <div className='info-text'>{t('v2.tasker.tasks.regularity_common_fields.info_text')}</div>
        </div>
      </div>
    </div>
  )
}
