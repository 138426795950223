import { formatDuration } from '../../../../utils/schedulerDuration'
import { stringFormatter, timeIntervalFormatter } from '../../../Forms/Formatters'
import { formatValuesForSubmit } from '../../../Forms/Formatters/submit'
import Yup from '../../../Forms/common/yup'
import { breakTimeSchema } from '../../utils/yupSchemas'

export const validationSchema = Yup.object().shape({
  start_time: Yup.string().required(),
  end_time: Yup.string().required(),
  break_time: breakTimeSchema
})

export const scheduleToForm = schedule => ({
  start_time: formatDuration(schedule.start_time),
  end_time: formatDuration(schedule.end_time),
  break_time: formatDuration(schedule.break_time)
})

export const formToData = values =>
  formatValuesForSubmit(values, {
    start_time: timeIntervalFormatter,
    end_time: timeIntervalFormatter,
    break_time: timeIntervalFormatter,
    comment: stringFormatter
  })
