import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../utils/i18n'
import { FilterContainer, SearchField, SimpleSelect } from '../FilterComponents'
import MonthDatePicker from '../MonthDatepicker'

class ReportsFilter extends React.Component {
  hiddenFilters = () => {
    const { store, construction_objects_options, state_options, token_options } = this.props

    return (
      <React.Fragment>
        <SimpleSelect
          store={store}
          selectable='construction_object_id'
          options={construction_objects_options}
          isMulti={true}
          dataContains='select-construction-object-filter'
        />
        <SimpleSelect store={store} selectable='state' options={state_options} dataContains='select-state-filter' />
        <SimpleSelect
          store={store}
          selectable='construction_object_vedludb_token'
          options={token_options}
          dataContains='select-vedludb-token-filter'
        />
      </React.Fragment>
    )
  }

  render() {
    const { store, ...others } = this.props
    return (
      <FilterContainer store={store} hiddenFilters={this.hiddenFilters} {...others}>
        <SearchField store={store} placeholder={t('filters.search_reports')} />
        <div className='month-datepicker filter-month-datepicker'>
          <MonthDatePicker store={store} />
        </div>
      </FilterContainer>
    )
  }
}

export default observer(ReportsFilter)
