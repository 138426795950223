import { t } from '../utils/i18n'

class FormWithRoles {
  constructor({ form, checkBoxes, namespace }) {
    this.form = form
    this.checkBoxes = checkBoxes
    this.namespace = namespace

    this.bindElements()

    if (!this.selectors.roleInput) return

    this.bindEvents()
    this.toggleFields()
    this.toggleRoleDescription()
  }

  bindEvents() {
    this.selectors.roleInput.addEventListener('change', this.toggleFields)
    this.selectors.roleInput.addEventListener('change', this.toggleRoleDescription)
  }

  toggleRoleDescription = () => {
    const roleDescriptions = Array.from(this.form.querySelectorAll('.role-description')).filter(
      el => el.dataset.visible !== 'false'
    )
    roleDescriptions.forEach((el, i) => {
      const { i18nScope, prefix } = el.dataset
      const roleDescription = t(
        `v2.${this.namespace}.form.${i18nScope}role_descriptions.${this.selectors.roleInput.value}`
      )
      const rolePrefix = roleDescriptions.length > 1 ? `${prefix}: ` : ''
      const margin = i + 1 === roleDescriptions.length ? 'mb-4' : 'mb-2'
      el.innerText = `${rolePrefix}${roleDescription}`
      el.classList.add(margin)
    })
  }

  toggleFields = _event => {
    if (!this.checkBoxes) return

    const role = this.selectors.roleInput.value

    this.checkBoxes.forEach(({ name, visibleFor }) => {
      const container = this.selectors[name]

      if (!container) return

      const isVisible = typeof visibleFor === 'function' ? visibleFor(role) : visibleFor === role

      if (isVisible) {
        container.classList.remove('d-none')
      } else {
        container.classList.add('d-none')
      }
    })
  }
}

export default FormWithRoles
