import { getDuration } from '../utils/duration'

class TimeEntryInputsContainer {
  static selector = '[data-contains=time-entry-inputs-container]'

  constructor(container) {
    this.container = container

    this.bindElements()
    this.bindEvents()
  }

  bindElements() {
    this.selectors = {
      startedAtInput: this.container.querySelector('[data-contains=time-entry-started-at]'),
      finishedAtInput: this.container.querySelector('[data-contains=time-entry-finished-at]'),
      durationContainer: this.container.querySelector('[data-contains=time-entry-duration]')
    }
  }

  bindEvents() {
    const { startedAtInput, finishedAtInput } = this.selectors

    startedAtInput.addEventListener('change', this.onDurationChange)
    finishedAtInput.addEventListener('change', this.onDurationChange)
  }

  onDurationChange = () => {
    const { startedAtInput, finishedAtInput, durationContainer } = this.selectors

    let startedAt = startedAtInput.value || null
    let finishedAt = finishedAtInput.value || null

    durationContainer.innerText = getDuration(startedAt, finishedAt)
  }
}

export default TimeEntryInputsContainer
