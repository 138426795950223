import { makeObservable, action } from 'mobx'

const defaultScrollPage = {
  pageNum: 1,
  rowsLeftCount: 0
}

export const StoreTableScrollPages = storeState =>
  class extends storeState {
    constructor(args) {
      super(args)
      makeObservable(this, {
        resetScrollPage: action,
        touchScrollPage: action
      })
    }

    get pages() {
      return this.state.tableScrollPages
    }

    resetScrollPage = rowId => this.pages.set(rowId, { ...defaultScrollPage })

    touchScrollPage = rowId => {
      if (!this.pages.has(rowId)) {
        this.resetScrollPage(rowId)
      }

      return this.pages.get(rowId)
    }
  }
