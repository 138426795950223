import React, { useContext, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import dayjs from 'dayjs'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import yup from 'utils/yup'

import useTranslate from '../../../hooks/useTranslate'
import useUnsavedChangesConfirmation from '../../../hooks/useUnsavedChangesConfirmation'
import { url } from '../../../utils/urlGenerator'
import { DateField, TextField } from '../../Forms/HookFields'
import Modal from '../../Modal'
import { VedludbContext } from './ConstructionObjectVedludb'
import ConstructionSiteData from './ContractForm/ConstructionSiteData'
import ContractButtons from './ContractForm/ContractButtons'
import ContractPaymentData from './ContractForm/ContractPaymentData'
import SubcontractorSelects from './ContractForm/SubcontractorSelects'
import { useContractQuery } from './queries'

const formattedData = (data, isEdit, isGeneral) => {
  const formattedPaymentMonths = paymentMonths =>
    paymentMonths.map(row => {
      const result = {
        summa: row?.summa?.replaceAll(' ', ''),
        menesis: row.menesis ? dayjs(row.menesis).format('YYYY-MM') : null
      }

      if (row.vedludbId) {
        result.id = row.vedludbId
        result.aktuala = row.aktuala
      }

      return result
    })

  const result = {
    ligumsId: data?.ligumsId,
    arvalstuDarbaDevejs: data?.arvalstuDarbaDevejs,
    isFiziskaPersona: data?.isFiziskaPersona,
    apaksuznemejaNosaukums: data?.apaksuznemejaNosaukums?.label,
    apaksuznemejaRegistracijasNr: data?.apaksuznemejaRegistracijasNr,
    ligumaDatums: data?.ligumaDatums ? dayjs(data.ligumaDatums).format('YYYY-MM-DD') : null,
    ligumaNumurs: data?.ligumaNumurs,
    ligumaSummaNavNoteikta: data?.ligumaSummaNavNoteikta || false,
    ligumaSumma: data?.ligumaSummaNavNoteikta === false ? data?.ligumaSumma?.replaceAll(' ', '') : null,
    ligumaSummasMenesi: data?.ligumaSummaNavNoteikta === true ? formattedPaymentMonths(data?.ligumaSummasMenesi) : null
  }

  if (!isGeneral) {
    result.buvatlaujasNr = data?.buvatlaujasNr
    result.bisBuvniecibasLietasNr = data?.bisBuvniecibasLietasNr
    result.buvniecibasLietaId = data?.buvniecibasLietaId.value
  }

  if (isEdit) result.pamatojums = data?.pamatojums

  return result
}

const formData = (raw, ligumsId) => {
  const buildSubcontractor = contract => {
    if (!contract) return null

    return {
      label: contract.apaksuznemejaNosaukums,
      value: contract.apaksuznemejaRegistracijasNr
    }
  }

  const processMonthlyPayments = contract => {
    if (!contract) return []

    return contract?.ligumaSummasMenesi?.map(rawMonth => ({
      ...rawMonth.table,
      menesis: dayjs(rawMonth.table.menesis).toDate(),
      vedludbId: rawMonth.table.id, // Need to store duplicate, since useFieldArray overrides this attribute with its own id
      aktuala: true
    }))
  }

  const buildConstructionSide = contract => {
    return contract?.buvniecibasLietas?.length > 0
      ? {
          buvatlaujasNr: contract.buvniecibasLietas[0].table.buvatlaujasNr,
          bisBuvniecibasLietasNr: contract.buvniecibasLietas[0].table.bisBuvniecibasLietasNr,
          buvniecibasLietaId: {
            label: contract.buvniecibasLietas[0].table.buvatlaujasNr,
            value: contract.buvniecibasLietas[0].table.id
          }
        }
      : {
          buvatlaujasNr: null,
          bisBuvniecibasLietasNr: null,
          buvniecibasLietaId: null
        }
  }

  return {
    ligumsId: raw?.ligumsId || ligumsId,
    apaksuznemejaNosaukums: buildSubcontractor(raw),
    apaksuznemejaRegistracijasNr: raw?.apaksuznemejaRegistracijasNr || null,
    isFiziskaPersona: raw?.isFiziskaPersona || false,
    arvalstuDarbaDevejs: raw?.arvalstuDarbaDevejs || false,
    ligumaDatums: raw?.ligumaDatums ? dayjs(raw.ligumaDatums).toDate() : null,
    ligumaNumurs: raw?.ligumaNumurs || null,
    ligumaSumma: raw?.ligumaSumma || null,
    ligumaSummaNavNoteikta: raw?.ligumaSummaNavNoteikta || false,
    ligumaSummasMenesi: processMonthlyPayments(raw),
    ...buildConstructionSide(raw)
  }
}

const setRequiredFields = isGeneral => ({
  apaksuznemejaNosaukums: true,
  ligumaDatums: true,
  ligumaNumurs: false,
  ligumaSumma: isGeneral ? false : true,
  ligumaSummaNavNoteikta: isGeneral ? false : true,
  ligumaSummasMenesi: isGeneral ? false : true,
  buvniecibasLietaId: true,
  buvatlaujasNr: false,
  bisBuvniecibasLietasNr: false
})

const validationSchema = isGeneral => {
  return isGeneral
    ? yup.object({
        ligumsId: yup.string().required(),
        apaksuznemejaNosaukums: yup.object().nullable().required(),
        apaksuznemejaRegistracijasNr: yup.string().nullable().required(),
        isFiziskaPersona: yup.boolean().nullable().required(),
        arvalstuDarbaDevejs: yup.boolean().nullable().required(),
        ligumaDatums: yup.date().nullable().required(),
        ligumaNumurs: yup.string().nullable().nullable(),
        ligumaSumma: yup.string().nullable(),
        ligumaSummaNavNoteikta: yup.boolean(),
        ligumaSummasMenesi: yup.array().nullable(),
        buvatlaujasNr: yup.string().nullable(),
        bisBuvniecibasLietasNr: yup.string().nullable(),
        buvniecibasLietaId: yup.object().nullable()
      })
    : yup.object({
        ligumsId: yup.string().required(),
        apaksuznemejaNosaukums: yup.object().nullable().required(),
        apaksuznemejaRegistracijasNr: yup.string().nullable().required(),
        isFiziskaPersona: yup.boolean().required(),
        arvalstuDarbaDevejs: yup.boolean().required(),
        ligumaDatums: yup.date().nullable().required(),
        ligumaNumurs: yup.string().nullable().nullable(),
        ligumaSumma: yup
          .string()
          .nullable()
          .when('ligumaSummaNavNoteikta', {
            is: false,
            then: schema => schema.required()
          }),
        ligumaSummaNavNoteikta: yup.boolean(),
        ligumaSummasMenesi: yup
          .array(
            yup.object({
              menesis: yup.string().nullable().required(),
              summa: yup.string().nullable().required()
            })
          )
          .nullable()
          .when('ligumaSummaNavNoteikta', {
            is: true,
            then: schema => schema.min(1).required()
          }),
        buvatlaujasNr: yup.string().nullable(),
        bisBuvniecibasLietasNr: yup.string().nullable(),
        buvniecibasLietaId: yup.object().nullable().required()
      })
}

// Form Component Definition
const ContractForm = ({ closeForm, contract }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.ContractForm')
  const [destroyRequested, setDestroyRequested] = useState(false)
  const { ligumsId, permissions } = useContext(VedludbContext)
  const queryClient = useQueryClient()
  const { data, isLoading, isPending } = useContractQuery(ligumsId, contract)

  const subcontractors = data?.subcontractors || []
  const constructionSites = data?.constructionSites || []

  const fetching = isLoading || isPending

  const isEditForm = !!contract

  const form = useForm({
    defaultValues: formData(contract, ligumsId),
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema(permissions.edit_general)),
    values: formData(contract, ligumsId)
  })

  const {
    handleSubmit,
    formState: { isDirty, errors: _errors }
  } = form
  const handleClose = useUnsavedChangesConfirmation({ dirty: isDirty, onClose: closeForm })

  const { mutate, isLoading: isSubmitting } = useMutation({
    mutationFn: data => {
      const URL = url('v2/vedludb/contracts')
      const processedData = { contract: formattedData(data, isEditForm, permissions.edit_general), id: ligumsId }

      if (isEditForm) {
        return axios.put(URL, processedData)
      } else {
        return axios.post(URL, processedData)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vedludb', 'contracts', ligumsId] })
      closeForm()
    },
    onError: () => closeForm()
  })

  const explanation = useWatch({ name: 'pamatojums', control: form.control })
  const displayExplanation = (isEditForm && isDirty) || destroyRequested
  const formDisabled = fetching || isSubmitting
  const requiredFields = setRequiredFields(permissions.edit_general)

  const contextProps = {
    ...form,
    contract,
    subcontractors,
    constructionSites,
    formDisabled,
    ligumsId,
    isEditForm,
    requiredFields,
    closeForm,
    setDestroyRequested
  }

  const modalProps = {
    onClose: handleClose,
    closeOnBackdropClick: false,
    className: 'new-modal contract-form',
    title: isEditForm ? t('.titles.edit') : t('.titles.new')
  }

  return (
    <Modal isOpen {...modalProps}>
      <FormProvider {...contextProps}>
        <form className='react-form' onSubmit={handleSubmit(mutate)}>
          <SubcontractorSelects />
          <hr />
          <DateField
            name='ligumaDatums'
            placeholder={t('.fields.placeholders.contractDate')}
            label={t('.fields.labels.contractDate')}
            required={requiredFields.ligumaDatums}
            disabled={formDisabled}
            dateMax={dayjs().toDate()}
          />
          <TextField
            name='ligumaNumurs'
            placeholder={t('.fields.placeholders.contractNumber')}
            label={t('.fields.labels.contractNumber')}
            required={requiredFields.ligumaNumurs}
            disabled={formDisabled}
          />
          <hr />
          {!permissions.edit_general && (
            <>
              <ConstructionSiteData />
              <hr />
            </>
          )}
          <ContractPaymentData />
          {displayExplanation && (
            <TextField
              name='pamatojums'
              label={t('.fields.labels.explanation')}
              placeholder={t('.fields.placeholders.explanation')}
              required={isDirty}
              disabled={formDisabled}
              textArea
            />
          )}
          <ContractButtons explanation={explanation} onClose={handleClose} />
        </form>
      </FormProvider>
    </Modal>
  )
}

export default ContractForm
