import React from 'react'

import MonthByDates from './MonthByDates'
import MonthByDays from './MonthByDays'
import MonthConfiguration from './MonthConfiguration'

class MonthFields extends React.Component {
  state = { monthIntervalKind: this.props.monthIntervalKind || 'dates' }

  onMonthIntervalKindChange = monthIntervalKind => {
    this.setState({ monthIntervalKind })
  }

  renderMonthBy(monthIntervalKind) {
    const { monthSpecificDayOptions, monthWeeks, weekDaysNames, days, specificDays, disabled } = this.props

    if (monthIntervalKind == 'days') {
      return (
        <div className='form-group'>
          <label className='col-sm-4 col-form-label'></label>
          <div className='fields-right-container'>
            <MonthByDays monthWeeks={monthWeeks} weekDaysNames={weekDaysNames} days={days} disabled={disabled} />
          </div>
        </div>
      )
    } else if (monthIntervalKind == 'dates') {
      return (
        <div className='form-group'>
          <label className='col-sm-4 col-form-label'></label>
          <div className='fields-right-container'>
            <MonthByDates
              monthSpecificDayOptions={monthSpecificDayOptions}
              days={days}
              specificDays={specificDays}
              disabled={disabled}
            />
          </div>
        </div>
      )
    }
  }

  render() {
    const { monthIntervalKind } = this.state

    return (
      <div>
        <MonthConfiguration
          monthIntervalKind={monthIntervalKind}
          onMonthIntervalKindChange={this.onMonthIntervalKindChange}
          disabled={this.props.disabled}
        />
        {this.renderMonthBy(monthIntervalKind)}
      </div>
    )
  }
}

export default MonthFields
