import React from 'react'

import axios from 'axios'
import { Formik, Form } from 'formik'
import Yup from 'utils/yup'

import { getApiErrorMessage, showErrorModal } from '../../utils/confirmations'
import { t } from '../../utils/i18n'
import MentionsField from '../Forms/Fields/MentionsField'
import Icon from '../Icon'

const initialValues = {
  text: '',
  file: null
}

const tView = key => t(['v2.tasker.tasks.task_activities', key])

const validationSchema = Yup.object().shape({
  text: Yup.string().when('file', {
    is: null,
    then: schema => schema.required()
  })
})

const NoteForm = ({ taggable_users, accepted_image_formats = '', submit_url, afterCreate }) => {
  const handleSubmit = (values, { setSubmitting, setValues }) => {
    setSubmitting(true)

    const { text, file } = values

    const formData = new FormData()
    formData.append('note[text]', text)

    if (file) {
      formData.append('note[file]', file)
    }

    axios
      .post(submit_url, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
      .then(({ data }) => {
        setSubmitting(false)
        afterCreate(data)
        setValues(initialValues) // Bug: https://github.com/jaredpalmer/formik/issues/3512
      })
      .catch(_e => {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnMount>
      {({ setFieldValue, isValid, values: { file }, isSubmitting }) => (
        <>
          {file && <div className='file-placeholder'>{file.name}</div>}
          <div className='form-holder'>
            <Form id='new_task_note'>
              <div className='input-group input-group-lg underbox'>
                <MentionsField
                  name='text'
                  placeholder={tView('add_message_placeholder')}
                  id='note_text'
                  taggable_users={taggable_users}
                />
                <label htmlFor='note_file'>
                  <Icon icon='addAttachment' />
                  <input
                    type='file'
                    accept={accepted_image_formats}
                    name='note_file'
                    id='note_file'
                    onChange={e => setFieldValue('file', e.currentTarget.files[0])}
                  />
                </label>
                <div className='input-group-append'>
                  <button type='submit' className='btn btn-primary' disabled={isSubmitting || !isValid}>
                    <Icon icon='chatButtonIcon' />
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </>
      )}
    </Formik>
  )
}

export default NoteForm
