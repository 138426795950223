import React, { useCallback, useEffect, useState } from 'react'

import isEqual from 'lodash/isEqual'

import { formatTime } from '../../utils/formatTime'
import { calculateMiniModalPosition } from './MiniModal'
import { useSelectedShiftItems } from './SelectedShiftItemsContext'
import ShiftDayExtendedModal from './ShiftDayExtendedModal'
import ShiftDayMiniModal from './ShiftDayMiniModal'

const MINI_MODAL_MAX_WIDTH = 280

export default () => {
  const [showModal, setShowModal] = useState(false)
  const [miniModalProps, setMiniModalProps] = useState(null)
  const { selectedShiftItems, modalParams, setModalParams, clearShiftItems } = useSelectedShiftItems()

  useEffect(() => {
    if (selectedShiftItems.length < 1) {
      setMiniModalProps(null)
    }
  }, [selectedShiftItems])

  useEffect(() => {
    if (modalParams && selectedShiftItems.length === 1) {
      const { shift, employee, dayParams, dateIso, dayRef } = modalParams

      const currentMiniModalParams = {
        date: dateIso,
        shift_id: shift.id,
        employee_id: employee?.id
      }

      if (isEqual(selectedShiftItems[0], currentMiniModalParams)) {
        const { subject } = dayParams.shiftUnits

        const startTime = subject?.start_time || shift.start_time
        const endTime = subject?.end_time || shift.end_time

        const start_time = startTime ? formatTime(startTime) : ''
        const end_time = endTime ? formatTime(endTime) : ''

        setMiniModalProps({
          position: calculateMiniModalPosition(dayRef, MINI_MODAL_MAX_WIDTH),
          initialValues: { start_time, end_time }
        })
      }
    }

    if (!showModal) {
      setShowModal(true)
    }
  }, [selectedShiftItems, modalParams, setMiniModalProps, showModal, setShowModal])

  const closeShiftDayModal = useCallback(() => {
    setModalParams(null)
    setMiniModalProps(null)
    setShowModal(false)
    clearShiftItems()
  }, [setModalParams, clearShiftItems])

  const inShifts = window.location.pathname.split('/').at(-1) === 'shifts'

  if (!modalParams || !showModal || !inShifts) return null

  return miniModalProps ? (
    <ShiftDayMiniModal
      {...miniModalProps}
      showExpand={selectedShiftItems.length === 1}
      onExpand={() => setMiniModalProps(null)}
      onClose={closeShiftDayModal}
    />
  ) : (
    <ShiftDayExtendedModal closeModal={closeShiftDayModal} {...modalParams} />
  )
}
