import React from 'react'

import axios from 'axios'
import { useFormik, FormikProvider, Form } from 'formik'
import ReactDOM from 'react-dom'

import useUnsavedChangesConfirmation from '../../../hooks/useUnsavedChangesConfirmation'
import { getApiErrorMessage, showErrorModal } from '../../../utils/confirmations'
import { t } from '../../../utils/i18n'
import { url } from '../../../utils/urlGenerator'
import yup from '../../../utils/yup'
import { SwitchField, MultiselectField, SelectField, DateTimeField } from '../../Forms/Fields'
import { dateFormatter, selectFormatter, multiselectFormatter } from '../../Forms/Formatters'
import { formatValuesForSubmit } from '../../Forms/Formatters/submit'
import Icon from '../../Icon'
import Modal from '../../Modal'

const initialValues = {
  keep_open: false,
  employee_ids: [],
  absence_type_id: null,
  date_from: new Date(),
  date_to: null
}
const validationSchema = yup.object({
  employee_ids: yup.array().min(1),
  absence_type_id: yup.object().nullable().required(),
  date_from: yup.date().nullable().required(),
  date_to: yup.date().nullable().required()
})

const valueFormats = {
  date_from: dateFormatter,
  date_to: dateFormatter,
  absence_type_id: selectFormatter,
  employee_ids: multiselectFormatter
}

const absenceTypesAdminUrl = url('v2/settings/classifiers/absence_types')

const CreateModal = ({ policies, onClose, addAbsences }) => {
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    axios({
      method: 'POST',
      url: url('v2/absences/bulk_create'),
      data: {
        absence: formatValuesForSubmit(values, valueFormats)
      }
    })
      .then(({ data: { absences } }) => {
        addAbsences(absences)
      })
      .catch(() => {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      })
      .finally(() => {
        setSubmitting(false)
        if (values.keep_open) {
          resetForm({
            values: { ...initialValues, keep_open: true }
          })
        } else {
          onClose()
        }
      })
  }

  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    initialValues: initialValues
  })

  const { dirty, isSubmitting, values } = formik

  const handleModalClose = useUnsavedChangesConfirmation({ dirty, onClose })

  return ReactDOM.createPortal(
    <Modal
      isOpen
      className='modal-dialog-centered modal-lg shift-modal'
      onClose={handleModalClose}
      title={t('v2.absences.create_modal.title')}
    >
      <FormikProvider value={formik}>
        <Form className='react-form'>
          <MultiselectField
            name='employee_ids'
            label={t('v2.shifts.index.form.fields.employees')}
            optionsURL={url('v2/absences/employees_autocomplete')}
            withImages
            fieldIcon='iconEmployee'
          />

          <div className='row'>
            <div className='col-6'>
              <SelectField
                label={t('activerecord.attributes.absence.absence_type')}
                optionsURL={url('v2/absences/absence_types_autocomplete')}
                name='absence_type_id'
                withoutBlank
                renderOptionLabel={({ abbreviation, title }) => (
                  <>
                    <span className='text-truncate'>{title}</span>
                    <span className='text-secondary text-nowrap ml-2'>( {abbreviation} )</span>
                  </>
                )}
                formatSelectedItem={({ abbreviation, title }) => `${title} ( ${abbreviation} )`}
                optionsFooter={
                  policies?.absenceTypes?.create && (
                    <a className='options-link' href={absenceTypesAdminUrl} target='_blank' rel='noreferrer'>
                      <Icon icon='cog' className='mr-2' />
                      <span>{t('nav.settings')}</span>
                    </a>
                  )
                }
              />
            </div>
            <div className='col-6'>
              <div className='daterange-wrapper'>
                <DateTimeField
                  name='date_from'
                  label={t('v2.absences.create_modal.period')}
                  type='date'
                  maxDate={values.date_to}
                  withIcon
                  required
                />
                <DateTimeField name='date_to' label={true} type='date' minDate={values.date_from} withIcon required />
              </div>
            </div>
          </div>

          <hr className='delimiter my-4' />

          <div className='row'>
            <div className='col-6 mt-1 d-flex'>
              <button type='submit' className='btn btn-success flex-grow-1 px-0' disabled={isSubmitting}>
                {t('save')}
              </button>

              <button className='btn btn-secondary ml-3 flex-grow-1 px-0' onClick={onClose}>
                {t('cancel')}
              </button>
            </div>
            <div className='col-6'>
              <SwitchField
                name='keep_open'
                label={t('v2.absences.create_modal.add_another')}
                labelClassName='text-mute'
              />
            </div>
          </div>
        </Form>
      </FormikProvider>
    </Modal>,
    document.querySelector('.modal-container')
  )
}

export default CreateModal
