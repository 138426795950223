import React, { useEffect, useState } from 'react'

import axios from 'axios'

import { t } from '../../../utils/i18n'
import ShiftLabelForm, { useShiftLabelFormik, initialValues } from '../../Forms/ShiftLabelForm'
import ModalFormikForm from '../../ModalFormikForm'
import Table, { AddSubjectButton } from '../../Table'
import { editFormik, destroyShiftLabel } from '../../elements/actionDefinitions'

const formikForm = { Form: ShiftLabelForm, useForm: useShiftLabelFormik }
const pathPrefix = 'v2/settings/classifiers/shift_labels'

const columns = [
  {
    Header: t('activerecord.attributes.shift_label.color'),
    accessor: 'color',
    className: 'text-center',
    width: 75,
    Cell: ({ resource }) => (
      <div className='w-100 d-flex justify-content-center'>
        <div style={{ width: 15, height: 15, backgroundColor: resource.color, borderRadius: 4 }}></div>
      </div>
    )
  },
  {
    Header: t('activerecord.attributes.shift_label.name'),
    accessor: 'name'
  }
]

const ShiftLabels = ({ url }) => {
  const [data, setData] = useState([])
  const [creationModalOpen, setCreationModalOpen] = useState(false)

  useEffect(() => {
    const loadData = async () => {
      const { data } = await axios.get(url)

      setData(data.rows)
    }

    loadData()
  }, [url])

  const actions = [
    editFormik({
      title: 'v2.settings.classifiers.shift_labels.form.title',
      formikForm,
      overrides: {
        classes: 'new-modal modal-lg'
      }
    }),
    destroyShiftLabel({ pathPrefix, options: data.map(label => ({ value: label.id, label: label.name, ...label })) })
  ]

  const buttonProps = {
    className: 'ml-4',
    translationPath: 'v2.settings.classifiers.shift_labels.index',
    onClick: () => setCreationModalOpen(true)
  }

  return (
    <div className='w-100'>
      <Table columns={columns} actions={actions} data={data} className='shift-labels-table' />
      <AddSubjectButton {...buttonProps} />
      <ModalFormikForm
        isOpen={creationModalOpen}
        setIsOpen={setCreationModalOpen}
        onClose={() => setCreationModalOpen(false)}
        formData={initialValues}
        classes='new-modal modal-lg'
        action='new'
        title={t('v2.settings.classifiers.shift_labels.form.new_title')}
        formikForm={formikForm}
        reloadPageOnSuccess
        skipToggleButton
      />
    </div>
  )
}

export default ShiftLabels
