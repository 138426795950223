import React, { useCallback, useRef, useMemo } from 'react'

import classNames from 'classnames'

import { AbsenceBar } from './Absences'
import { useSelectedShiftItems } from './SelectedShiftItemsContext'
import ShiftDayBlock from './ShiftDayBlock'
import ShiftDayWeekendOverlay from './ShiftDayWeekendOverlay'

const ShiftVersionDay = ({ shift, employee, dayParams, yesterdayParams, tomorrowParams }) => {
  const { addSelectedShiftItem, clearShiftItems, setModalParams } = useSelectedShiftItems()
  const dayRef = useRef()

  const {
    day,
    dayBlockParams: { creatable, showBlock },
    shiftStarted
  } = dayParams

  const { color } = shift
  const { dateIso, today, past } = day

  const isPastOrHasRegistrations = useMemo(() => {
    const registrationCounts = employee?.registration_counts || shift.registration_counts
    return past || (registrationCounts && !!registrationCounts[dateIso]) || shiftStarted()
  }, [shift, employee, dateIso, shiftStarted, past])

  const shiftItemParams = useMemo(
    () => ({
      date: dateIso,
      shift_id: shift.id,
      employee_id: employee?.id
    }),
    [dateIso, shift.id, employee?.id]
  )

  const modalParams = useMemo(
    () => ({
      shift,
      employee,
      dayParams,
      dateIso
    }),
    [shift, employee, dayParams, dateIso]
  )

  const handleClick = useCallback(
    event => {
      event.stopPropagation()

      if (!creatable) return

      const ctrlPressed = event.getModifierState('Control') || event.getModifierState('Meta') // Ctrl || Cmd

      if (isPastOrHasRegistrations) {
        if (!ctrlPressed) {
          clearShiftItems()
          setModalParams(modalParams)
        }
      } else {
        addSelectedShiftItem(shiftItemParams, !ctrlPressed)
        setModalParams({ ...modalParams, dayRef })
      }
    },
    [
      creatable,
      isPastOrHasRegistrations,
      addSelectedShiftItem,
      clearShiftItems,
      modalParams,
      setModalParams,
      shiftItemParams
    ]
  )

  return (
    <>
      <div
        ref={dayRef}
        className={classNames('shift-day-block', {
          'with-day-block': showBlock || !creatable
        })}
        data-contains={dateIso}
        onClick={handleClick}
      >
        <ShiftDayBlock
          shiftId={shift.id}
          employeeId={employee?.id}
          color={color}
          dayParams={dayParams}
          yesterdayParams={yesterdayParams}
          tomorrowParams={tomorrowParams}
        />
      </div>
      {today && <div className='today-overlay' />}
    </>
  )
}

const ShiftDay = ({ day: calendarDay, days, width, absenceMap = [], ...others }) => {
  const dayParams = days[calendarDay.format('DD.MM.YYYY')]
  const yesterdayParams = days[calendarDay.subtract(1, 'day').format('DD.MM.YYYY')]
  const tomorrowParams = days[calendarDay.add(1, 'day').format('DD.MM.YYYY')]

  if (!dayParams) return <div className='shift-day' style={{ width }} />

  const {
    shiftUnits: { version },
    day: { dateIso, past, dayjsDay },
    dayBlockParams: { creatable, hasAbsence }
  } = dayParams

  const absence = absenceMap[calendarDay.format('YYYY-MM-DD')]

  return (
    <div className={classNames('shift-day', { disabled: !creatable })} style={{ width }}>
      {absence && <AbsenceBar day={calendarDay} absence={absence} dayWidth={width} />}

      {version && !hasAbsence ? (
        <ShiftVersionDay
          width={width}
          dayParams={dayParams}
          yesterdayParams={yesterdayParams}
          tomorrowParams={tomorrowParams}
          {...others}
        />
      ) : (
        <div
          className={classNames('shift-day-block', { 'with-day-block': past, 'has-absence': hasAbsence })}
          data-contains={dateIso}
        />
      )}
      <ShiftDayWeekendOverlay day={dayjsDay} width={width} />
    </div>
  )
}

export default ShiftDay
