import { useEffect, useState } from 'react'

export default (value, timeout) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const debounceHandler = setTimeout(() => setDebouncedValue(value), timeout)

    return () => clearTimeout(debounceHandler)
  }, [value, timeout])

  return debouncedValue
}
