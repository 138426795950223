import React from 'react'

import { t } from '../../utils/i18n'
import Store from '../Store'
import TableContainer from '../TableContainer'
import Tooltip from '../Tooltip'
import ConstructionObjectTasksFilter from './ConstructionObjectTasksFilter'
import ConstructionObjectTasksTable from './ConstructionObjectTasksTable'

class ConstructionObjectTasksTableContainer extends TableContainer {
  state = {
    secondLevelRows: {}
  }

  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'id', desc: false }],
    baseScope: this.props.base_scope
  })

  columns = [
    {
      accessorKey: 'row-expander',
      id: 'row-expander',
      header: '',
      size: 40,
      maxSize: 40,
      sortable: false,
      enableResizing: false,
      minSize: 40,
      getClassName: this.getExpandableCellClassName,
      hideable: false,
      cell: ({ row, row: { original } }) => {
        return (
          <div style={{ cursor: 'pointer' }}>{this.renderExpander(row, original.is_group, row.getIsExpanded())}</div>
        )
      }
    },
    {
      header: t('activerecord.attributes.task.title'),
      accessorKey: 'title',
      cell: ({
        getValue,
        row: {
          original: { id, construction_object_sub_tasks_count }
        }
      }) => (
        <div className='text-truncate'>
          {getValue()}
          <span className='sub-resource-count' data-tip data-for={`sub-tasks-count-${id}`}>
            {construction_object_sub_tasks_count > 0 && (
              <span className='task-count'>({construction_object_sub_tasks_count})</span>
            )}
          </span>

          <Tooltip id={`sub-tasks-count-${id}`} className='accented' place='bottom' effect='solid'>
            {t(`v2.construction_object_tasks.index.sub_tasks_count`)}
          </Tooltip>
        </div>
      )
    },
    {
      header: t('activerecord.attributes.construction_object_task.active_time_entries_count'),
      accessorKey: 'active_time_entries_count'
    },
    {
      header: t('activerecord.attributes.construction_object_task.consumed_time_in_period'),
      accessorKey: 'consumed_time_in_period'
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <ConstructionObjectTasksFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />

        <ConstructionObjectTasksTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
          onExpand={row =>
            this.onExpandedChange({
              rowId: row.id,
              parentId: row.original.parent_id,
              path: 'v2/construction_object_tasks/sub_tasks',
              subRowName: 'secondLevelRows',
              parentIdPrefix: row.original.parent_id_prefix,
              open: !row.getIsExpanded()
            })
          }
          expanded={this.store.expanded}
        />
      </div>
    )
  }
}

export default ConstructionObjectTasksTableContainer
