import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { isEqual, uniq } from 'lodash'

import { formatTime } from '../../utils/formatTime'

dayjs.extend(isBetween)

export const getDayParams = day => {
  const dayjsDay = dayjs(day)
  const weekDay = dayjsDay.day()
  const today = dayjsDay.isToday()
  const past = dayjsDay.isBefore(dayjs(), 'date')
  const startOfWeek = weekDay === 1
  const endOfWeek = weekDay === 0
  const date = dayjsDay.format('DD.MM.YYYY')
  const dateIso = dayjsDay.format('YYYY-MM-DD')

  return {
    date,
    dateIso,
    dayjsDay,
    today,
    past,
    weekDay,
    startOfWeek,
    endOfWeek
  }
}

const getShiftDayParams = ({
  version: { version_employees, actual_to, start_time, end_time, regularity, actual_from },
  employee,
  day: { dayjsDay, weekDay, past, today },
  subject,
  datesWithInvalidRegistrationsCount,
  employeeDays,
  hasAbsence,
  canManage
}) => {
  const isdayInvalid = invalidDates => invalidDates?.find(date => dayjs(date).isSame(dayjsDay, 'day'))
  const isSameDayOrAfter = day => dayjsDay.isSame(day, 'day') || dayjsDay.isAfter(day, 'day')

  const shiftVersionEmployee =
    employee && version_employees?.find(versionEmployee => versionEmployee.employee_id === employee.id)

  const isVersionEmployee =
    shiftVersionEmployee && dayjsDay.isBetween(shiftVersionEmployee.created_at, actual_to, 'day', '[]')

  const regularityDay = start_time && end_time && regularity.includes(weekDay)
  const drawDayBlock = ((regularityDay && isSameDayOrAfter(actual_from)) || subject) && subject?.status !== 'deleted'
  const showEmployeeDayBlock = isVersionEmployee || (employee && subject?.employee_id === employee.id)
  const showDayBlock = employee ? showEmployeeDayBlock && drawDayBlock : drawDayBlock
  const dayInvalid = (past || today) && isdayInvalid(Object.keys(datesWithInvalidRegistrationsCount))
  const employeeDayInvalid = employee && dayInvalid && isdayInvalid(employee.invalid_dates)
  const invalidRegistrationsCount =
    dayInvalid && datesWithInvalidRegistrationsCount[dayjs(dayjsDay).format('YYYY-MM-DD')]
  const creatable = canManage && (employee ? showEmployeeDayBlock : true)
  const hasEmployeeDays = !employee && !showDayBlock && !!employeeDays?.filter(day => day.status == 'updated').length
  const hasPlannedDay = employee ? !hasAbsence : true
  const showBlock = (showDayBlock && hasPlannedDay) || hasEmployeeDays

  return {
    creatable,
    dayInvalid,
    employeeDayInvalid,
    showDayBlock,
    showBlock,
    hasAbsence,
    invalidRegistrationsCount
  }
}

export const versionForCurrentDay = (version, dayjsDay) => {
  if (version.actual_to) {
    return dayjsDay.isBetween(version.actual_from, version.actual_to, 'day', '[]')
  } else {
    return dayjsDay.isSame(version.actual_from) || dayjsDay.isAfter(version.actual_from)
  }
}

const findShiftUnits = ({ shift: { shift_days, shift_employee_days, versions }, dayjsDay, employee }) => {
  const version = versions.find(version => versionForCurrentDay(version, dayjsDay))

  const currentBlockDay = date => dayjs(date).isSame(dayjsDay, 'day')

  const shiftDay = shift_days?.find(day => currentBlockDay(day.date))
  const employeeDays = shift_employee_days?.filter(day => currentBlockDay(day.date)) || []
  const employeeDay = employee && employeeDays.find(day => day.employee_id === employee.id)
  const subject = employeeDay || shiftDay

  const startTime = subject?.start_time || version?.start_time
  const endTime = subject?.end_time || version?.end_time

  const breakTimes = subject?.break_time_periods || version?.break_time_periods

  const singleDayShift = startTime && endTime ? dayjs(startTime).isBefore(dayjs(endTime)) : true
  const versionWithTimes = version?.start_time && version.end_time

  return {
    shiftDay,
    employeeDays,
    employeeDay,
    subject,
    startTime,
    endTime,
    singleDayShift,
    version,
    versionWithTimes,
    breakTimes
  }
}

const getAbsenceDates = absences => {
  let dateList = []

  absences.map(a => {
    let currDate = dayjs(a.date_from)
    const dateTo = dayjs(a.date_to)

    while (currDate.diff(dateTo) <= 0) {
      dateList.push(currDate.format('YYYY-MM-DD'))
      currDate = currDate.add(1, 'day')
    }
  })

  return uniq(dateList)
}

export const processRow = ({ employee, shift, days, absences = [] }) => {
  if (!shift) return

  const item = employee ? employee : shift
  const { dates_with_invalid_registrations_count } = shift

  const absenceDates = getAbsenceDates(absences)

  const dayInfo = days.map(day => {
    const { dayjsDay, date } = day

    const shiftUnits = findShiftUnits({
      dayjsDay,
      shift,
      employee
    })

    const { subject, singleDayShift, version, versionWithTimes, employeeDays, startTime, endTime } = shiftUnits

    const checkShiftTime = (time, addDay = false) => {
      if (!version || (!versionWithTimes && !subject)) return false

      const currentTime = dayjs()
      let comparableTime = dayjs(`${date} ${formatTime(time)}`, 'DD.MM.YYYY HH:mm')

      if (addDay) {
        comparableTime = comparableTime.add(1, 'days')
      }

      return currentTime.isAfter(comparableTime)
    }

    const shiftStarted = () => checkShiftTime(startTime)
    const shiftEnded = () => checkShiftTime(endTime, !singleDayShift)

    const hasAbsence = absenceDates.includes(dayjsDay.format('YYYY-MM-DD'))
    const canManage = shift.can_manage

    const dayBlockParams = getShiftDayParams({
      version: version || {},
      employee,
      day,
      subject,
      datesWithInvalidRegistrationsCount: dates_with_invalid_registrations_count || {},
      singleDayShift,
      employeeDays,
      hasAbsence,
      canManage
    })

    const isShiftEdited =
      version &&
      !employee &&
      !dayjsDay.isSame(dayjs(shift.created_at), 'day') &&
      dayjsDay.isSame(dayjs(version.actual_from))

    const dayUpdated = subject?.status === 'updated'

    return {
      [dayjsDay.format('DD.MM.YYYY')]: {
        day,
        shiftUnits,
        shiftStarted,
        shiftEnded,
        dayBlockParams,
        editParams: { isShiftEdited, dayUpdated }
      }
    }
  })

  return { ...item, days: Object.assign({}, ...dayInfo) }
}

export const unitSelectIndex = (units, attributes) => units.findIndex(item => isEqual(item, attributes))
