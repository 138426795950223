import React from 'react'

import { t } from '../../../utils/i18n'
import Icon from '../../Icon'
import CommonFields from './TaskRegularityComponents/CommonFields'
import IntervalKindButtons from './TaskRegularityComponents/IntervalKindButtons'
import MonthFields from './TaskRegularityComponents/MonthFields'
import WeekFields from './TaskRegularityComponents/WeekFields'

class TaskRegularityContainer extends React.Component {
  state = { intervalKind: 'day' }

  componentDidMount() {
    if (this.props.values?.intervalKind) {
      this.setState({ intervalKind: this.props.values.intervalKind })
    }
  }

  onIntervalKindChange = intervalKind => {
    this.setState({ intervalKind })
  }

  renderSpecificFields() {
    const { weekDaysNames, monthSpecificDayOptions, monthWeeks, values, disabled } = this.props
    const { intervalKind } = this.state

    if (intervalKind == 'week') {
      return <WeekFields weekDaysNames={weekDaysNames} days={values?.days} disabled={disabled} />
    } else if (intervalKind == 'month') {
      return (
        <MonthFields
          monthSpecificDayOptions={monthSpecificDayOptions}
          monthWeeks={monthWeeks}
          weekDaysNames={weekDaysNames}
          monthIntervalKind={values?.monthIntervalKind}
          days={values?.days}
          specificDays={values?.specificDays}
          disabled={disabled}
        />
      )
    }
  }

  render() {
    const { intervalKind } = this.state
    const { disabled } = this.props

    return (
      <div className='task-regularity-container'>
        <IntervalKindButtons
          currentIntervalKind={intervalKind}
          onIntervalKindChange={this.onIntervalKindChange}
          disabled={disabled}
        />
        <CommonFields currentIntervalKind={intervalKind} {...this.props} />
        {this.renderSpecificFields()}
        <hr className='delimiter my-3' />
        <div className='regularity-info'>
          <Icon icon='infoOutlined' className='info-icon' />
          <div className='info-text'>{t('v2.tasker.tasks.regularity_common_fields.info_text')}</div>
        </div>
        <input type='hidden' value={intervalKind} name='tasker_task[regularity][interval_kind]' />
      </div>
    )
  }
}

export default TaskRegularityContainer
