import React from 'react'

import ActiveTable from '../ActiveTable'
import {
  activate,
  checkOut,
  deactivate,
  discard,
  edit,
  activateSelected,
  checkOutSelected,
  deactivateSelected,
  discardSelected
} from '../elements/actionDefinitions'

class CompanyEmployeesTable extends React.Component {
  store = this.props.store

  formParams = {
    context: 'company',
    employee: { company_id: this.props.company_id }
  }

  actions = {
    row: [
      edit(this.props.pathPrefix, 'v2.employees.edit.title'),
      checkOut('latest_checked_in_check_log_id'),
      activate(this.props.pathPrefix),
      deactivate(this.props.pathPrefix),
      discard(this.props.pathPrefix)
    ],
    header: [
      checkOutSelected(this.props.pathPrefix),
      activateSelected(this.props.pathPrefix),
      deactivateSelected(this.props.pathPrefix),
      discardSelected(this.props.pathPrefix)
    ]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        translationPath='employees'
        highlightedBy='currently_checked_in_duration'
        inactiveBy='deactivated'
        formParams={this.formParams}
        onRowClick='openEditForm'
      />
    )
  }
}

export default CompanyEmployeesTable
