import React from 'react'

import classNames from 'classnames'
import { t } from 'i18n-js'
import { useFormContext } from 'react-hook-form'

import styles from '../../styles/ModuleWrapper.module.scss'

const RegularityButton = ({ periodName, value, disabled, onClick }) => (
  <button
    className={classNames(styles.configButton, 'config-button', disabled, {
      [styles.selected]: value === periodName
    })}
    onClick={onClick}
  >
    {t(periodName)}
  </button>
)

const RegularityConfigurationButtons = ({ value, onChange }) => {
  const { disableRegularity, submitting, selectOptions, setValue } = useFormContext()
  const selectInterval = type => {
    if (submitting || disableRegularity) return
    setValue('regularity.interval', selectOptions.regularity[`${type}_interval_options`][0])
    onChange(type)
  }

  const disabled = disableRegularity || submitting

  return (
    <div className={classNames(styles.configurationButtons, 'configuration-buttons')}>
      {['day', 'week', 'month'].map((periodName, key) => (
        <RegularityButton
          value={value}
          periodName={periodName}
          disabled={disabled}
          key={key}
          onClick={() => selectInterval(periodName)}
        />
      ))}
    </div>
  )
}

export default RegularityConfigurationButtons
