import React, { useState } from 'react'

import classNames from 'classnames'
import I18n from 'i18n-js'

import useDebounce from '../hooks/useDebounce'
import Icon from './Icon'
import OptionsDropdown from './OptionsDropdown'
import RangeDatepicker from './RangeDatepicker'

export const ExportRange = props => {
  const [open, setOpen] = useState(false)
  const debouncedOpen = useDebounce(open, 200)

  return (
    <>
      <div className={classNames('options-container range-datepicker-wrapper', { open })}>
        {debouncedOpen && <RangeDatepicker {...props} withIcon={false} />}
      </div>
      <button
        className={classNames('btn btn-shadowed options-container more-options', { open })}
        title={I18n.t('export_interval')}
        onClick={() => setOpen(!open)}
      >
        <Icon icon='calendar' />
      </button>
    </>
  )
}

export const ExportButton = ({ children = null, optionsContent, ...restProps }) => {
  const containerClasses = classNames('export-container', { 'btn-group': children })

  return (
    <div className={containerClasses}>
      <>
        <button
          className='btn btn-sm btn-shadowed export-button'
          data-tip
          data-for='options-tooltip'
          title={I18n.t('export')}
          {...restProps}
        >
          <Icon icon='download' />
        </button>
        {optionsContent && <OptionsDropdown>{optionsContent}</OptionsDropdown>}
        {children}
      </>
    </div>
  )
}
