import FormWithRoles from './FormWithRoles'

const MANAGER = 'company_manager'
const REGULAR = 'regular'

class CompanyUserForm extends FormWithRoles {
  static selector = '.edit_company_user, .new_company_user'

  constructor(form) {
    const checkBoxes = [
      { name: 'canEditIdentifiers', visibleFor: REGULAR },
      { name: 'canManageEmployees', visibleFor: REGULAR },
      { name: 'canEditNfcCheckLogs', visibleFor: MANAGER },
      { name: 'canManageVedludb', visibleFor: MANAGER }
    ]

    super({ form, checkBoxes, namespace: 'company_users' })

    this.selectors.canManageEmployees.addEventListener('change', this.toggleIdentifierInput)
    this.selectors.roleInput.addEventListener('change', this.toggleIdentifierInput)
    this.toggleIdentifierInput()
  }

  bindElements() {
    this.selectors = {
      roleInput: this.form.querySelector('#company_user_role'),
      canEditIdentifiersInput: this.form.querySelector('#company_user_can_edit_identifiers'),
      canEditIdentifiers: this.form.querySelector('.company_user_can_edit_identifiers'),
      canManageEmployeesInput: this.form.querySelector('#company_user_can_manage_employees'),
      canManageEmployees: this.form.querySelector('.company_user_can_manage_employees'),
      canEditNfcCheckLogsInput: this.form.querySelector('#company_user_can_edit_nfc_check_logs'),
      canEditNfcCheckLogs: this.form.querySelector('.company_user_can_edit_nfc_check_logs'),
      canManageVedludbInput: this.form.querySelector('#company_user_can_manage_vedludb'),
      canManageVedludb: this.form.querySelector('.company_user_can_manage_vedludb')
    }
  }

  toggleIdentifierInput = () => {
    const { canManageEmployeesInput, canEditIdentifiersInput } = this.selectors

    if (canManageEmployeesInput.checked) {
      canEditIdentifiersInput.setAttribute('disabled', true)
      canEditIdentifiersInput.checked = true
    } else {
      canEditIdentifiersInput.removeAttribute('disabled')
    }
  }
}

export default CompanyUserForm
