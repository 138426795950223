import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import GuestsFilter from '../Guests/GuestsFilter'
import Store from '../Store'
import TableContainer from '../TableContainer'
import ConstructionObjectGuestsTable from './ConstructionObjectGuestsTable'

class ConstructionObjectGuestsTableContainer extends TableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'currently_checked_in_duration', desc: false }]
  })

  columns = [
    {
      accessorKey: 'guest_id',
      visible: false
    },
    {
      header: t('activerecord.attributes.guest.full_name'),
      accessorKey: 'guest_full_name',
      cell: ({ getValue, row: { original } }) => <a href={url('v2/guests', original.guest_id)}>{getValue()}</a>
    },
    {
      header: t('activerecord.attributes.guest.personal_code'),
      accessorKey: 'guest_personal_code'
    },
    {
      header: t('activerecord.attributes.guest.description'),
      accessorKey: 'guest_description'
    },
    {
      header: t('activerecord.attributes.guest.currently_in_object'),
      accessorKey: 'currently_checked_in_duration',
      cell: ({ getValue }) => <span className='font-weight-bold'>{getValue() || '-'}</span>
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <GuestsFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <ConstructionObjectGuestsTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
        />
      </div>
    )
  }
}

export default ConstructionObjectGuestsTableContainer
