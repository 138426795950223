import React from 'react'

import MapContainer from '../Maps/MapContainer'
import drawingController from '../Maps/drawingController'
import polygonToText from '../Maps/polygonToText'

const ConstructionObjectAreaMapContainer = props => {
  const onPolygonChanged = polygonPath =>
    (document.getElementById('construction_object_area_check_in_polygon').value = polygonToText(polygonPath.getArray()))

  const mapProps = {
    ...props,
    drawingController: {
      ...drawingController,
      onPolygonChanged
    }
  }

  return <MapContainer {...mapProps} />
}

export default ConstructionObjectAreaMapContainer
