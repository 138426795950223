import React, { useState } from 'react'

import classNames from 'classnames'
import { t } from 'i18n-js'
import { useFormContext } from 'react-hook-form'

import Switch from '../../../../../Forms/Fields/Switch'
import { DateField } from '../../../../../Forms/HookFields'
import SectionTitle from '../../SectionTitle'
import styles from '../../styles/ModuleWrapper.module.scss'

const RegularityPeriodSelection = () => {
  const { submitting, disableRegularity, setValue } = useFormContext()
  const isDisabled = submitting || disableRegularity
  const [isEndDateUndefined, setEndDateUndefined] = useState(false)

  const toggleUndefined = () => {
    if (!isEndDateUndefined) {
      setValue('regularity.end_date', null)
    }
    setEndDateUndefined(!isEndDateUndefined)
  }

  const switchProps = {
    onClick: toggleUndefined,
    onChange: () => {},
    disabled: isDisabled,
    checked: isEndDateUndefined
  }

  return (
    <div>
      <SectionTitle>{t('v2.tasker.tasks.regularity_common_fields.period')}</SectionTitle>
      <div className={classNames('d-flex align-items-center', styles.withGaps)}>
        <DateField type='date' placeholder={t('from')} name='regularity.starting_date' disabled={isDisabled} withIcon />
        <DateField
          type='date'
          placeholder={t('up_to')}
          name='regularity.end_date'
          disabled={isDisabled || isEndDateUndefined}
          withIcon
        />
        <div className='d-flex align-items-center'>
          <Switch {...switchProps} />
          <div className='text-lighter ml-1'>{t('v2.tasker.tasks.regularity_common_fields.infinite_end_date')}</div>
        </div>
      </div>
    </div>
  )
}

export default RegularityPeriodSelection
