import React, { useRef, useMemo } from 'react'

import cx from 'classnames'

import { AbsenceBar } from '../Absences'
import SelectIndicator from '../SelectIndicator'
import { useSelectedShiftItems } from '../SelectedShiftItemsContext'
import BlockTime from './BlockTime'
import styles from './ShiftBlock.module.scss'
import { getContainerStyle, getShiftVisibilityParams } from './helpers'

const ShiftBlock = ({ schedule, metadata, shifts, employee, absenceMap = [], absenceDates = [] }) => {
  const dayRef = useRef()
  const { addSelectedShiftItem, clearShiftItems, setModalParams, selectShiftDay } = useSelectedShiftItems()
  const { today, past, dateIso, date } = metadata
  const absence = absenceMap[dateIso]

  const visibilityParams = getShiftVisibilityParams(shifts, date, dateIso, absenceDates)

  const { shift, showBlock, hasAbsence } = visibilityParams

  const creatable = employee ? showBlock || !hasAbsence : true
  const isEmployeeDay = !!employee

  const shiftItemParams = useMemo(
    () => ({
      date: dateIso,
      schedule_id: schedule.id,
      employee_id: employee?.id
    }),
    [dateIso, schedule.id, employee?.id]
  )

  const modalParams = useMemo(
    () => ({
      shift,
      employee,
      metadata,
      schedule,
      hasAbsence,
      isShiftDay: !isEmployeeDay
    }),
    [shift, employee, metadata, schedule, hasAbsence, isEmployeeDay]
  )

  const handleClick = event => {
    event.stopPropagation()
    if (!creatable || !schedule.can_manage) return

    const ctrlPressed = event.getModifierState('Control') || event.getModifierState('Meta') // Ctrl || Cmd

    if (past || today) {
      if (!ctrlPressed) {
        clearShiftItems()
        setModalParams(modalParams)
      }
    } else {
      isEmployeeDay
        ? addSelectedShiftItem(shiftItemParams, !ctrlPressed)
        : selectShiftDay(schedule, dateIso, !ctrlPressed)
      setModalParams({ ...modalParams, dayRef })
    }
  }

  const invalidCount = shift?.invalid_count
  const updated = !invalidCount && employee && shift?.updated
  const unscheduled = shift?.unscheduled

  const { containerClasses, halfSizeBlock } = getContainerStyle(visibilityParams, updated)

  return (
    <div ref={dayRef} onClick={handleClick} className={cx(containerClasses)}>
      {absence && <AbsenceBar date={date} absence={absence} />}
      {showBlock && (
        <div
          className={cx(styles.block, {
            [styles.invalid]: invalidCount && !!employee && !unscheduled,
            [styles.past]: past,
            [styles.unscheduled]: unscheduled
          })}
          data-color={schedule.color}
        >
          {!!invalidCount && !employee && (
            <div className='text-danger'>
              <i className='fa fa-warning' />
              <span className={styles.errorsCount}>{invalidCount}</span>
            </div>
          )}
          {employee && (
            <BlockTime
              halfSizeBlock={halfSizeBlock}
              startTime={shift.start_datetime}
              endTime={shift.end_datetime}
              workTime={shift.work_time_duration}
            />
          )}
          <div className={styles.hoverIndicator} />
        </div>
      )}

      <SelectIndicator
        scheduleId={schedule.id}
        employeeId={employee?.id}
        dateIso={dateIso}
        visibilityParams={visibilityParams}
      />
    </div>
  )
}

export default ShiftBlock
