import { useState, useEffect, useMemo, useCallback } from 'react'

import axios from 'axios'
import { getApiErrorMessage, showErrorModal } from 'utils/confirmations'
import { url } from 'utils/urlGenerator'

const useShiftSchedules = ({ month, searchTerm, chosenCompaniesIds }) => {
  const [schedules, setSchedules] = useState([])
  const [loading, setLoading] = useState(true)
  const [shiftFactualTime, setShiftFactualTime] = useState(false)

  const updateSchedules = (oldSchedules, schedules) =>
    oldSchedules.map(os => schedules.find(shift => shift.id === os.id) || os)

  const manageMultipleSchedules = useCallback(
    newSchedules => {
      const updatedSchedules = updateSchedules(schedules, newSchedules)

      setSchedules(updatedSchedules)
    },
    [schedules, setSchedules]
  )

  const updateSchedule = (oldShifts, shift) => {
    const shiftIdx = oldShifts.findIndex(os => os.id === shift.id)
    oldShifts[shiftIdx] = shift
  }

  const addSchedule = useCallback(shift => setSchedules([...schedules, shift]), [schedules])

  const refreshSchedule = useCallback(
    shift => {
      const oldSchedules = [...schedules]

      updateSchedule(oldSchedules, shift)

      if (shift.switched_shift) {
        updateSchedule(oldSchedules, shift.switched_shift)
      }

      setSchedules(oldSchedules)
    },
    [schedules]
  )

  const removeSchedule = useCallback(
    shift => {
      const newShifts = schedules.filter(s => s.id !== shift.id)

      setSchedules(newShifts)
    },
    [schedules]
  )

  const loadSchedules = useCallback(() => {
    axios
      .get(url('v2/schedules', { month, search: searchTerm, chosen_companies_ids: chosenCompaniesIds }))
      .then(({ data: { rows } }) => {
        setSchedules(rows)
      })
      .catch(() => {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [month, searchTerm, chosenCompaniesIds])

  useEffect(() => {
    loadSchedules()
  }, [month, searchTerm, loadSchedules])

  return useMemo(
    () => ({
      shiftFactualTime,
      schedules,
      loading,
      setShiftFactualTime,
      manageMultipleSchedules,
      addSchedule,
      refreshSchedule,
      removeSchedule,
      loadSchedules
    }),
    [
      shiftFactualTime,
      schedules,
      loading,
      setShiftFactualTime,
      addSchedule,
      refreshSchedule,
      removeSchedule,
      manageMultipleSchedules,
      loadSchedules
    ]
  )
}

export default useShiftSchedules
