import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../../utils/i18n'
import {
  FilterContainer,
  SearchField,
  SimpleSelect,
  DateRangeFilter,
  DurationFilterInput
} from '../../FilterComponents'

class TaskTimeEntriesFilter extends React.Component {
  hiddenFilters = () => {
    const { store, user_options, state_options, closing_type_options } = this.props

    return (
      <React.Fragment>
        <SimpleSelect
          store={store}
          selectable='owner_id'
          options={user_options}
          isMulti
          dataContains='select-user-filter'
        />
        <SimpleSelect store={store} selectable='state' options={state_options} dataContains='select-state-filter' />
        <SimpleSelect
          store={store}
          selectable='closing_type'
          options={closing_type_options}
          dataContains='select-closing-type-filter'
        />
        <DurationFilterInput store={store} />
      </React.Fragment>
    )
  }

  render() {
    const { store, ...others } = this.props
    return (
      <FilterContainer store={store} hiddenFilters={this.hiddenFilters} {...others}>
        <SearchField store={store} placeholder={t('filters.search_time_entries')} />
        <DateRangeFilter store={store} />
      </FilterContainer>
    )
  }
}

export default observer(TaskTimeEntriesFilter)
