import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../../utils/i18n'
import { FilterContainer, SearchField, SimpleSelect } from '../../FilterComponents'

class ExternalDevicesFilter extends React.Component {
  hiddenFilters = () => {
    const { store, construction_objects_options, statuses_options, directions_options } = this.props

    return (
      <React.Fragment>
        <SimpleSelect
          store={store}
          selectable='construction_object_id'
          options={construction_objects_options}
          dataContains='select-construction-object-filter'
        />
        <SimpleSelect
          store={store}
          selectable='status'
          options={statuses_options}
          dataContains='select-status-filter'
        />
        <SimpleSelect
          store={store}
          selectable='direction'
          options={directions_options}
          dataContains='select-direction-filter'
        />
      </React.Fragment>
    )
  }

  render() {
    const { store, ...others } = this.props

    return (
      <FilterContainer store={store} hiddenFilters={this.hiddenFilters} {...others}>
        <SearchField store={store} placeholder={t('filters.search_external_devices')} />
      </FilterContainer>
    )
  }
}

export default observer(ExternalDevicesFilter)
