import React from 'react'

import { t } from '../../utils/i18n'
import IconCell from '../IconCell'
import Store from '../Store'
import TableContainer from '../TableContainer'
import { BooleanIcon } from '../elements/TableColumnsElements'
import ReportsFilter from './ReportsFilter'
import ReportsTable from './ReportsTable'

class ReportsTableContainer extends TableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'period', desc: true }],
    baseScope: this.props.base_scope
  })

  columns = [
    {
      header: t('activerecord.attributes.report.period'),
      accessorKey: 'period',
      size: 90
    },
    {
      header: t('activerecord.attributes.report.construction_object_short_name'),
      accessorKey: 'construction_object_short_name'
    },
    {
      header: t('activerecord.attributes.report.construction_object_name'),
      accessorKey: 'construction_object_name'
    },
    {
      header: t('activerecord.attributes.report.posted_at'),
      accessorKey: 'posted_at'
    },
    {
      header: t('activerecord.attributes.report_version.state'),
      accessorKey: 'latest_version_state',
      size: 170,
      cell: ({
        getValue,
        row: {
          original: { id }
        }
      }) => {
        const state = getValue()

        const className = this.props.report_icon_mapping[state].join(' ')
        const description = this.props.report_state_description_mapping[state]

        return (
          <IconCell
            id={id}
            showIcon
            tooltipText={description}
            iconComponent={tooltipId => (
              <i className={`fa fa-${className} ml-2 state-icon center-icon`} data-tip data-for={tooltipId} />
            )}
          >
            {t(`activerecord.attributes.report_version.state/${state}`)}
          </IconCell>
        )
      }
    },
    {
      header: t('activerecord.attributes.report.version_count'),
      accessorKey: 'version_count',
      size: 90
    },
    {
      header: t('activerecord.attributes.report.construction_object_vedludb_token'),
      accessorKey: 'construction_object_vedludb_token',
      minSize: 120,
      size: 120,
      cell: ({ getValue }) => <BooleanIcon checked={getValue()} />
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div>
        <ReportsFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <ReportsTable {...this.props} store={this.store} columns={this.columns} columnOptions={columnOptions} />
      </div>
    )
  }
}

export default ReportsTableContainer
