import axios from 'axios'

import { url } from '../utils/urlGenerator'
import TaskActivitiesChangelogToggle from './TaskActivitiesChangelogToggle'

// Infinite loader handler
const BOTTOM_OFFSET_PX = 5

class TaskActivitiesLoader {
  static selector = '.task-activities-table'
  static fetching = false

  constructor() {
    window.addEventListener('scroll', this.autloadTrigger)
  }

  autloadTrigger = () => {
    // Should remove scroll event listener if activities table isn't present
    if (document.querySelectorAll(TaskActivitiesLoader.selector).length === 0) {
      window.removeEventListener('scroll', this.autloadTrigger)
      return
    }

    if (window.scrollY + window.innerHeight >= document.body.offsetHeight - BOTTOM_OFFSET_PX) {
      this.loadMoreActivities()
    }
  }

  loadMoreActivities() {
    const Handler = TaskActivitiesChangelogToggle
    const anchor = document.querySelector('.activities-loader-anchor')

    if (anchor && !TaskActivitiesLoader.fetching) {
      TaskActivitiesLoader.fetching = true
      const { taskId, offset } = anchor.dataset
      const fetchURL = url(`/v2/tasker/tasks/${taskId}/task_activities`)

      axios
        .get(fetchURL, {
          params: { activities_offset: offset },
          headers: { 'Content-Type': 'text/html' }
        })
        .then(response => {
          const temp = document.createElement('div')
          temp.innerHTML = response.data

          temp.querySelectorAll(Handler.selector).forEach(element => new Handler(element))
          anchor.replaceWith(...temp.childNodes)
        })
        .finally(() => (TaskActivitiesLoader.fetching = false))
    }
  }
}

export default TaskActivitiesLoader
