import React from 'react'

import axios from 'axios'
import { FormikProvider, useFormik, Form, setIn } from 'formik'
import { t } from 'utils/i18n'
import yup from 'utils/yup'

import { getApiErrorMessage, showErrorModal } from '../../utils/confirmations'
import { url } from '../../utils/urlGenerator'
import { HexColorPicker, TextField } from './Fields'
import BaseError from './Fields/BaseError'
import { hexFormatter, stringFormatter } from './Formatters'
import { formatValuesForSubmit } from './Formatters/submit'

const tAbsenceType = key => t(['activerecord.attributes.absence_type', key])

const randomHexColor = () => '#' + Math.floor(Math.random() * 16777215).toString(16)

const valueFormats = {
  abbreviation: stringFormatter,
  title: stringFormatter,
  color: hexFormatter
}

export const initialValues = {
  abbreviation: '',
  title: '',
  color: randomHexColor()
}

const validationSchema = yup.object({
  abbreviation: yup.string().required(),
  title: yup.string().required()
})

const errorString = errors => errors.map(({ message }) => message).join('; ')

export const getFormikErrors = errors =>
  Object.keys(errors).reduce(
    (accumulator, currentKey) => setIn(accumulator, currentKey, errorString(errors[currentKey])),
    {}
  )

export const useAbsenceTypeFormik = ({ data, afterSuccessfulSubmit }) => {
  const httpMethod = data.id ? 'put' : 'post'
  const subjectUrl = url([`v2/settings/classifiers/absence_types`, data.id].join('/'))

  const sendRequest = async (values, { setErrors, setSubmitting }) => {
    axios({
      method: httpMethod,
      url: subjectUrl,
      data: {
        absence_type: formatValuesForSubmit(values, valueFormats)
      }
    })
      .then(response => {
        setSubmitting(false)
        afterSuccessfulSubmit && afterSuccessfulSubmit(response)
      })
      .catch(error => {
        setSubmitting(false)
        if (error.response?.status === 422) {
          const responseData = error.response.data
          setErrors(getFormikErrors(responseData))
        } else {
          showErrorModal({
            title: getApiErrorMessage(null),
            cancelAction: Turbolinks.visit
          })
        }
      })
  }

  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: sendRequest,
    validationSchema
  })

  return { formik }
}

const AbsenceTypeForm = ({ formik }) => (
  <FormikProvider value={formik}>
    <Form className='react-form'>
      <BaseError name='base' />
      <div className='row' data-contains='time-entry-inputs-container'>
        <div className='col-6'>
          <HexColorPicker name='color' label={tAbsenceType('color')} required />
        </div>
        <div className='col-6'>
          <TextField
            name='abbreviation'
            label={tAbsenceType('abbreviation')}
            inputProps={{ className: 'form-control text-uppercase', maxLength: '3' }}
            required
          />
        </div>
        <div className='col'>
          <TextField name='title' label={tAbsenceType('title')} required />
        </div>
      </div>
    </Form>
  </FormikProvider>
)

export default AbsenceTypeForm
