import dayjs from 'dayjs'
import { uniq } from 'lodash'

const groupOverlappingAbsences = absences =>
  absences.reduce((acc, { date_from, date_to }) => {
    const currentAbsence = acc[acc.length - 1]

    // expects absences to be already sorted by date_from
    if (!currentAbsence || date_from > currentAbsence.date_to) {
      acc.push({ date_from, date_to })
    } else if (date_to > currentAbsence.date_to) {
      currentAbsence.date_to = date_to
    }

    return acc
  }, [])

export const makeAbsenceMap = (absences, month) =>
  absences.reduce((acc, absence) => {
    const startDate = !absence.date_from.startsWith(month) ? `${month}-01` : absence.date_from
    acc[startDate] = absence

    return acc
  }, {})

export const mergeAbsences = (absences, month) => {
  if (!absences.length) {
    return {}
  }

  const mergedAbsences = groupOverlappingAbsences(absences)

  return makeAbsenceMap(mergedAbsences, month)
}

export const getAbsenceDates = (absences = []) => {
  const dateList = []

  absences.map(a => {
    let currDate = dayjs(a.date_from)
    const dateTo = dayjs(a.date_to)

    while (currDate.diff(dateTo) <= 0) {
      dateList.push(currDate.format('YYYY-MM-DD'))
      currDate = currDate.add(1, 'day')
    }
  })

  return uniq(dateList)
}
