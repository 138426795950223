import React from 'react'

import { t } from '../../utils/i18n'
import Avatar from '../Avatar'
import Store from '../Store'
import TableContainer from '../TableContainer'
import DevicesFilter from './DevicesFilter'
import DevicesTable from './DevicesTable'

class DevicesTableContainer extends TableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'actualized_at', desc: true }]
  })

  columns = [
    {
      header: t('activerecord.attributes.device.user_full_name'),
      accessorKey: 'user_full_name',
      cell: ({ getValue, row: { original } }) => <Avatar src={original.user_picture_url} text={getValue()} />
    },
    {
      header: t('activerecord.attributes.user.email'),
      accessorKey: 'user_email'
    },
    {
      header: t('activerecord.attributes.user.description'),
      accessorKey: 'user_description',
      sortable: false
    },
    {
      header: t('activerecord.attributes.device.name'),
      accessorKey: 'name'
    },
    {
      header: t('activerecord.attributes.device.version'),
      accessorKey: 'version'
    },
    {
      header: t('activerecord.attributes.device.actualized_at'),
      accessorKey: 'actualized_at'
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <DevicesFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <DevicesTable {...this.props} store={this.store} columns={this.columns} columnOptions={columnOptions} />
      </div>
    )
  }
}

export default DevicesTableContainer
