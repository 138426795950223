import React, { useEffect } from 'react'

import { useFormContext, useWatch } from 'react-hook-form'

import useTranslate from '../../../../hooks/useTranslate'
import { SelectField, SwitchField, TextField } from '../../../Forms/HookFields'

const SubcontractorSelects = () => {
  const t = useTranslate('ConstructionObjects.Vedludb.ContractForm')
  const { formDisabled, subcontractors, requiredFields, setValue } = useFormContext()
  const options = subcontractors.map(sub => ({ label: sub.name, value: sub.registration_number }))
  const selectedSubcontractor = useWatch({ name: 'apaksuznemejaNosaukums' })

  useEffect(() => {
    if (selectedSubcontractor) {
      const company = subcontractors.find(sub => sub.registration_number === selectedSubcontractor.value)
      setValue('apaksuznemejaRegistracijasNr', company?.registration_number || selectedSubcontractor.value)
      // In case we dont have a referenced company, leave the values gotten from VEDLUDB
      if (company?.is_person) setValue('isFiziskaPersona', company.is_person)
      if (company?.is_foreign) setValue('arvalstuDarbaDevejs', company.is_foreign)
    } else {
      setValue('apaksuznemejaRegistracijasNr', null)
      setValue('isFiziskaPersona', false)
      setValue('arvalstuDarbaDevejs', false)
    }
  }, [selectedSubcontractor, setValue, subcontractors])

  return (
    <>
      <SelectField
        name='apaksuznemejaNosaukums'
        label={t('.fields.labels.subcontractor')}
        placeholder={t('.fields.placeholders.subcontractorName')}
        options={options}
        required={requiredFields.apaksuznemejaNosaukums}
        disabled={formDisabled}
      />
      <TextField
        name='apaksuznemejaRegistracijasNr'
        placeholder={t('.fields.placeholders.subcontractorNr')}
        disabled={true}
      />
      <SwitchField name='isFiziskaPersona' label={t('.fields.labels.isPerson')} disabled={true} />
      <SwitchField name='arvalstuDarbaDevejs' label={t('.fields.labels.isForeign')} disabled={true} />
    </>
  )
}

export default SubcontractorSelects
