class MapPolygon {
  constructor(map, polygon, drawingController) {
    this.map = map
    this.polygon = polygon
    this.drawingController = drawingController
  }

  draw() {
    const figure = this.load()

    return { figure, points: figure.getPath().getArray() }
  }

  load() {
    const polygon = new google.maps.Polygon({
      ...this.drawingController.style,
      paths: this.polygon
    })

    this.drawingController.setPolygonEvents(polygon.getPath())

    polygon.setMap(this.map)

    return polygon
  }
}

export default MapPolygon
