import React from 'react'

import { useController, useFormContext } from 'react-hook-form'

import DateTimePicker from '../../DateTimePicker'
import HookFieldWrapper from './HookFieldWrapper'

const DateField = ({
  name,
  required,
  fieldIcon,
  label,
  className = '',
  wrapperClassName = '',
  dateMin,
  dateMax,
  popperModifiers,
  disabled,
  type = 'date',
  placeholder,
  ...others
}) => {
  const { control, submitting } = useFormContext()
  const {
    field: { ref: _ref, ...field },
    fieldState
  } = useController({ name, control })

  return (
    <HookFieldWrapper
      name={name}
      required={required}
      className={wrapperClassName}
      icon={fieldIcon}
      label={label}
      fieldState={fieldState}
    >
      <DateTimePicker
        id={name}
        type={type}
        className={`form-control ${className}`}
        minDate={dateMin}
        maxDate={dateMax}
        popperModifiers={popperModifiers}
        disabled={submitting || disabled}
        placeholder={placeholder}
        required={required}
        {...field}
        {...others}
      />
    </HookFieldWrapper>
  )
}

export default DateField
