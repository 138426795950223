export const getColumnOptions = (columns, hiddenColumns = []) =>
  columns.map(column => {
    const accessor = column.accessorKey || column.id
    const hasVisibleParam = column.visible !== undefined

    return {
      title: column.customHeader ? column.title : column.header,
      accessorKey: accessor,
      visible: hasVisibleParam ? column.visible : !hiddenColumns.includes(accessor),
      hideable: hasVisibleParam ? column.visible : column.hideable !== false
    }
  })
