import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../utils/i18n'
import { FilterContainer, SearchField, SimpleSelect } from '../FilterComponents'

class UsersFilter extends React.Component {
  hiddenFilters = () => {
    const {
      store,
      construction_objects_options,
      companies_options,
      roles_options,
      statuses_options,
      employees_options,
      user_groups_options,
      show_edlus_components
    } = this.props

    return (
      <>
        {show_edlus_components && (
          <SimpleSelect
            store={store}
            selectable='construction_object_id'
            options={construction_objects_options}
            isMulti
            dataContains='select-construction-object-filter'
          />
        )}
        <SimpleSelect
          store={store}
          selectable='user_group_id'
          options={user_groups_options}
          isMulti
          dataContains='select-user-group-filter'
        />
        {show_edlus_components && (
          <SimpleSelect
            store={store}
            selectable='company_id'
            options={companies_options}
            isMulti
            dataContains='select-company-filter'
          />
        )}
        <SimpleSelect store={store} selectable='role' options={roles_options} dataContains='select-role-filter' />
        <SimpleSelect
          store={store}
          selectable='status'
          options={statuses_options}
          dataContains='select-status-filter'
        />
        <SimpleSelect
          store={store}
          selectable='employee'
          options={employees_options}
          dataContains='select-employee-filter'
          isMulti
        />
      </>
    )
  }
  render() {
    const { store, customFilters, ...others } = this.props

    return (
      <FilterContainer store={store} hiddenFilters={customFilters || this.hiddenFilters} {...others}>
        <SearchField store={store} placeholder={t('filters.search_users')} />
      </FilterContainer>
    )
  }
}

export default observer(UsersFilter)
