import React, { useEffect, forwardRef, useImperativeHandle } from 'react'

import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender
} from '@tanstack/react-table'
import classNames from 'classnames'

import sortArrowAsc from '../images/sort-arrow-asc.png'
import sortArrowDesc from '../images/sort-arrow-desc.png'
import { t } from '../utils/i18n'
import TableFooter from './elements/TableFooter'

const ColumnHeader = ({ header }) => {
  const columnDefinition = header.column.columnDef
  const Header = columnDefinition.header ? flexRender(columnDefinition.header, header.getContext()) : ''

  if (columnDefinition.sortable === false) return Header

  if (columnDefinition.customHeader) return Header()

  let showArrowDesc = true
  let showArrowAsc = true

  const sortedBy = header.column.getIsSorted()

  if (sortedBy) {
    if (sortedBy === 'desc') showArrowDesc = false
    else showArrowAsc = false
  }

  return (
    <>
      {Header}
      <div className='sort-arrows'>
        {showArrowDesc && <img className='sort-arrow-desc' src={sortArrowDesc} />}
        {showArrowAsc && <img className='sort-arrow-asc' src={sortArrowAsc} />}
      </div>
    </>
  )
}

const TableHeader = ({ headerGroups, getTheadThProps }) => (
  <div className='rt-thead'>
    {headerGroups.map(headerGroup => (
      <div key={headerGroup.id} className='rt-tr'>
        {headerGroup.headers.map(header => {
          const columnId = header.id || header.accessorKey

          const headerClass = `${columnId}-header`

          const resizable = header.column.getCanResize()
          return (
            <div
              key={header.id}
              style={{
                display: 'flex',
                width: header.getSize(),
                minWidth: header.column.columnDef.minSize,
                maxWidth: header.column.columnDef.maxSize,
                flex: `${header.getSize()} ${resizable ? '1' : '0'} auto`
              }}
              data-column-name={header.id}
              {...getTheadThProps(header)}
              className={classNames(
                'rt-th',
                { 'rt-resizable-header': resizable },
                header.column.columnDef.headerClassName || '',
                headerClass
              )}
            >
              <div
                onClick={header.column.getCanSort() ? header.column.getToggleSortingHandler() : () => {}}
                className={classNames({ 'rt-header-content': true, 'rt-resizable-header-content': resizable })}
              >
                <div className='header-content'>
                  <ColumnHeader header={header} />
                </div>
              </div>
              {resizable && (
                <div
                  {...{
                    onMouseDown: header.getResizeHandler(),
                    onTouchStart: header.getResizeHandler(),
                    onClick: e => e.stopPropagation()
                  }}
                  className='resizer'
                />
              )}
            </div>
          )
        })}
      </div>
    ))}
  </div>
)

const TableBody = ({ rows, ...others }) => (
  <div className='rt-tbody'>
    {!rows.length ? (
      <div className='no-data rt-tr'>
        <div className='rt-td'>
          <span>{t('components.active_table.no_data_text')}</span>
        </div>
      </div>
    ) : (
      rows.map((row, i) => <TableRow key={i} row={row} {...others} />)
    )}
  </div>
)

const ExpandedRows = ({ row, getTrProps, getTrClassName, getTdProps, subRowDepth, ...others }) => (
  <>
    <div className={`expanded-rows depth-${subRowDepth}`}>
      {row.subRows?.map((row, i) => (
        <TableRow
          key={i}
          row={row}
          getTrProps={getTrProps}
          getTrClassName={getTrClassName}
          getTdProps={getTdProps}
          subRowDepth={subRowDepth}
          {...others}
        />
      ))}
    </div>
    <ExpandedRowsLoadMoreLink row={row} {...others} />
  </>
)

const ExpandedRowsLoadMoreLink = ({ row, onLoadMore, store }) => {
  const scrollPage = store.touchScrollPage(row.id)

  return (
    scrollPage.rowsLeftCount > 0 && (
      <div className='no-data rt-tr load-more-row'>
        <div className='rt-td'>
          <button className='btn btn-link load-more-link' onClick={() => onLoadMore(row)}>
            {t('components.active_table.load_more_link_title', { count: scrollPage.rowsLeftCount })}
          </button>
        </div>
      </div>
    )
  )
}

const TableCell = ({ cell, getTdProps, row }) => {
  const { className, getClassName } = cell.column.columnDef

  const cellClassName = getClassName ? getClassName(cell) : ''
  const cellProps = getTdProps({ row, column: cell.column.columnDef })

  const size = cell.column.getSize()

  const { maxSize, minSize } = cell.column.columnDef

  const resizable = cell.column.getCanResize()
  return (
    <div
      style={{
        width: size,
        minWidth: minSize,
        maxWidth: maxSize,
        flex: `${size} ${resizable ? '1' : '0'} auto`
      }}
      onClick={cellProps.onClick}
      className={classNames('rt-td text-truncate', className, cellClassName, cellProps.className)}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext()) || ''}
    </div>
  )
}

const TableRow = ({ row, getTrProps, getTrClassName, getTdProps, subRowDepth = 0, ...others }) =>
  (subRowDepth || !row.depth) && (
    <>
      <div
        key={row.id}
        {...getTrProps(row)}
        className={classNames('rt-tr rt-tbody-row', getTrClassName(row))}
        data-object-id={row.original?.id}
        data-object-starting-time={row.original?.starting_time?.slice(0, 10)}
        data-row={`row_${row.id.replace(/\./g, '_')}`}
      >
        {row.getVisibleCells().map(cell => (
          <TableCell key={cell.id} cell={cell} getTdProps={getTdProps} row={row} />
        ))}
      </div>
      {row.getIsExpanded() && (
        <ExpandedRows
          row={row}
          getTrProps={getTrProps}
          getTrClassName={getTrClassName}
          getTdProps={getTdProps}
          subRowDepth={subRowDepth + 1}
          {...others}
        />
      )}
    </>
  )

const ReactTable = forwardRef((props, ref) => {
  const {
    className = '',
    data,
    columns,
    loading = false,
    getTdProps = () => {},
    getTrClassName = () => '',
    loadingText,
    onFetchData,
    page,
    pages,
    dataSize,
    sorted,
    onSortedChange,
    getTrProps = () => {},
    getTheadThProps = () => {},
    columnVisibility,
    onLoadMore = () => {},
    store
  } = props

  const table = useReactTable({
    columns,
    data,
    getRowCanExpand: row => row.original.is_group,
    state: {
      sorting: sorted,
      pageIndex: page,
      pageSize: dataSize,
      columnVisibility,
      expanded: store.state.expanded
    },
    manualPagination: true,
    manualExpanding: true,
    pageCount: pages,
    manualSorting: true,
    enableSorting: true,
    defaultColumn: { size: 150, maxSize: undefined },
    onSortingChange: onSortedChange,
    autoResetSortBy: false,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getSubRows: row => row.subRows || row.subrows,
    sortDescFirst: false
  })

  const { pageIndex, pageSize, sorting, filters } = table.getState()

  const {
    getCanNextPage,
    getCanPreviousPage,
    nextPage,
    previousPage,
    setPageSize,
    getPageCount,
    setPageIndex,
    getRowModel,
    getHeaderGroups
  } = table

  const rows = getRowModel().rows

  // todo - backendā
  useImperativeHandle(
    ref,
    () => ({
      ...ref.current
      // expandAll
    }),
    [ref]
  )

  useEffect(() => {
    onFetchData()
  }, [onFetchData, pageIndex, pageSize, filters, sorting])

  //   const expandAll = () => {
  // rows.map()
  //   }

  return (
    <div className={classNames(className, 'react-table_new')}>
      <div className='rt-table'>
        <TableHeader headerGroups={getHeaderGroups()} getTheadThProps={getTheadThProps} />

        <TableBody
          columnCount={columns.length}
          rows={rows}
          getTrProps={getTrProps}
          getTrClassName={getTrClassName}
          getTdProps={getTdProps}
          onLoadMore={onLoadMore}
          store={store}
        />
      </div>

      <div className='pagination-bottom'>
        <TableFooter
          {...props}
          page={pageIndex}
          pageCount={getPageCount()}
          pageSize={dataSize}
          canNextPage={getCanNextPage()}
          canPreviousPage={getCanPreviousPage()}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          gotoPage={setPageIndex}
        />
      </div>

      {loading && (
        <div className='loading-overlay'>
          <div className='loading-inner'>{loadingText}</div>
        </div>
      )}
    </div>
  )
})

export default ReactTable
