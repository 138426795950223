import dayjs from 'dayjs'

function getUnit(duration, calc) {
  return Math.floor(calc(Math.abs(duration)))
    .toString()
    .padStart(2, '0')
}

/**
 * Returns from formatted duration (seconds) in hh:mm format
 * @param  {Number}   duration
 * @return {String}
 */
export const formatDuration = duration => {
  const hours = getUnit(duration, d => d / 3600)
  const minutes = getUnit(duration, d => (d / 60) % 60)
  let formatted = `${hours}:${minutes}`

  if (duration < 0) formatted = `- ${formatted}`

  return formatted
}

/**
 * Returns formatted duration in hh:mm format
 * @param  {?(Date|String)}   startedAt
 * @param  {?(Date|String)}   finishedAt
 * @return {String}
 */
export const getDuration = (startedAt, finishedAt) => {
  if (!startedAt) {
    return '--:--'
  }

  const startAt = dayjs(startedAt)
  let endAt = dayjs(finishedAt)

  if (!endAt.isValid()) {
    endAt = dayjs()
  }

  return formatDuration(endAt.diff(startAt, 'seconds'))
}

export const durationFromHHMM = str => {
  const iso = `PT${str.replace(':', 'H')}M`

  return dayjs.duration(iso)
}
