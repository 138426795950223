import React from 'react'

import classNames from 'classnames'
import Turbolinks from 'turbolinks'

import { getColumnOptions } from '../../utils/columnOptions'
import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Store from '../Store'
import TableContainer from '../TableContainer'
import { IconWithTooltip } from '../elements/TableColumnsElements'
import ConstructionObjectsFilter from './ConstructionObjectsFilter'
import ConstructionObjectsTable, { CheckedInAttendantCount } from './ConstructionObjectsTable'

class ConstructionObjectsTableContainer extends TableContainer {
  pathPrefix = 'v2/construction_objects'

  store = new Store({
    url: this.props.url,
    defaultFilter: this.props.default_filter,
    defaultSorted: [{ id: 'checked_in_attendant_count', desc: true }],
    baseScope: this.props.base_scope
  })

  componentDidMount() {
    if (this.props.construction_industry) {
      this.columns.push(this.sendToSrsColumn)
    }

    this.setState({
      columnOptions: getColumnOptions(this.columns, this.props.hidden_columns)
    })

    const archived = document.getElementById('archived')
    archived.addEventListener('click', this.onArchivedClick, false)
  }

  onArchivedClick = event => {
    event.preventDefault()
    if (this.props.active_selection === 'archived') Turbolinks.visit(url(window.location.pathname))
    else
      Turbolinks.visit(
        url(window.location.pathname, {
          active_selection: 'archived',
          base_scope: 'archived'
        })
      )
  }

  columns = [
    {
      header: t('activerecord.attributes.construction_object.checked_in_attendant_count'),
      id: 'checked_in_attendant_count',
      accessorKey: 'checked_in_attendant_count',
      size: 120,
      cell: ({
        row: {
          original: { id, checked_in_employees_count, checked_in_guests_count }
        }
      }) => (
        <>
          <CheckedInAttendantCount
            type='employees'
            id={id}
            count={checked_in_employees_count}
            prefix={this.pathPrefix}
          />
          <span className='separator' />
          <CheckedInAttendantCount type='guests' id={id} count={checked_in_guests_count} prefix={this.pathPrefix} />
        </>
      )
    },
    {
      header: t('activerecord.attributes.construction_object.short_name'),
      accessorKey: 'short_name',
      cell: ({ getValue, row: { original } }) => <a href={url(this.pathPrefix, original.id)}>{getValue()}</a>
    },
    {
      header: t('activerecord.attributes.construction_object.name'),
      accessorKey: 'name',
      cell: ({ getValue, row: { original } }) => <a href={url(this.pathPrefix, original.id)}>{getValue()}</a>
    },
    {
      header: t('activerecord.attributes.construction_object.address'),
      accessorKey: 'address'
    },
    {
      header: t('activerecord.attributes.construction_object.construction_object_events_created_recently_count'),
      accessorKey: 'construction_object_events_recently_count',
      size: 120,
      cell: ({ getValue, row: { original } }) => (
        <a href={url(this.pathPrefix, original.id, 'construction_object_events')}>{getValue()}</a>
      )
    },
    {
      header: t('activerecord.attributes.construction_object.unread_notes_count'),
      accessorKey: 'unread_notes_count',
      size: 120,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue()
        return (
          <a
            href={url(this.pathPrefix, original.id)}
            className={classNames({ 'text-primary': Number.parseInt(value) > 0 })}
          >
            {value}
          </a>
        )
      }
    }
  ]

  sendToSrsColumn = {
    header: t('activerecord.attributes.construction_object.send_to_srs'),
    accessorKey: 'send_to_srs',
    size: 120,
    cell: ({
      getValue,
      row: {
        original: { id, vedludb_construction_site }
      }
    }) => {
      let type, text

      if (vedludb_construction_site) {
        type = 'success'
        text = `${t('v2.construction_objects.index.linked_with')} ${vedludb_construction_site}`
      } else {
        type = 'danger'
        text = t('v2.construction_objects.index.object_isnt_linked_with_bis')
      }

      return <IconWithTooltip id={id} checked={getValue()} text={text} type={type} icon='check' />
    }
  }

  render() {
    const { columnOptions } = this.state

    return (
      <div>
        <ConstructionObjectsFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <ConstructionObjectsTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
          pathPrefix={this.pathPrefix}
        />
      </div>
    )
  }
}

export default ConstructionObjectsTableContainer
