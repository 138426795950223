import dayjs from 'dayjs'

import { t } from '../../../utils/i18n'
import Yup from '../../../utils/yup'

Yup.addMethod(Yup.string, 'bothTimes', function (comparableField) {
  return this.test('both_times', t('v2.shifts.index.form.errors.required'), function (value) {
    const required = !!this.parent[comparableField]

    return !!value || !required
  })
})

Yup.addMethod(Yup.object, 'overlaps', function () {
  return this.test('overlaps', t('v2.shifts.index.form.errors.overlaps'), function (value) {
    const [_parent1, parent2, ..._others] = this.from
    const { break_time_periods } = parent2.value

    const formattedStart = dayjs(value.start, 'HH:mm')
    let formattedFinish = dayjs(value.finish, 'HH:mm')

    if (formattedFinish.isBefore(formattedStart)) {
      formattedFinish = formattedFinish.add(1, 'day')
    }

    const breakTimePeriods = [...break_time_periods]
    const index = breakTimePeriods.indexOf(value)

    if (index > -1) {
      breakTimePeriods.splice(index, 1)
    }

    const overlaps = breakTimePeriods.find(breakTime => {
      const formattedPeriodStart = dayjs(breakTime.start, 'HH:mm')
      let formattedPeriodFinish = dayjs(breakTime.finish, 'HH:mm')

      if (formattedPeriodFinish.isBefore(formattedPeriodStart)) {
        formattedPeriodFinish = formattedPeriodFinish.add(1, 'day')
      }

      if (!(formattedPeriodStart.isValid() && formattedPeriodFinish.isValid())) return false

      const timeOverlaps = time => formattedStart.isSameOrBefore(time) && time.isSameOrBefore(formattedFinish)

      if (timeOverlaps(formattedPeriodStart) || timeOverlaps(formattedPeriodFinish)) return true

      if (formattedPeriodStart.isBefore(formattedStart) && formattedFinish.isBefore(formattedPeriodFinish)) {
        return true
      }
    })

    return !overlaps
  })
})

Yup.addMethod(Yup.object, 'bothInShiftTime', function () {
  return this.test('bothInShiftTime', t('v2.shifts.index.form.errors.outside_shift'), function (value) {
    const [_parent1, parent2, ..._others] = this.from
    const { start_time, end_time } = parent2.value

    if (!(start_time && end_time && value && value.start && value.finish)) return true

    const shiftStart = dayjs(start_time, 'HH:mm')
    let shiftEnd = dayjs(end_time, 'HH:mm')

    const breakTimeStart = dayjs(value.start, 'HH:mm')
    let breakTimeFinish = dayjs(value.finish, 'HH:mm')

    if (breakTimeFinish.isBefore(breakTimeStart)) {
      breakTimeFinish = breakTimeFinish.add(1, 'day')
    }

    if (shiftEnd.isBefore(shiftStart)) {
      shiftEnd = shiftEnd.add(1, 'day')
    }

    const breakTimeStartInShift = breakTimeStart.isAfter(shiftStart) && breakTimeStart.isBefore(shiftEnd)

    const breakTimeFinishInShift = breakTimeFinish.isAfter(shiftStart) && breakTimeFinish.isBefore(shiftEnd)

    return breakTimeStartInShift && breakTimeFinishInShift
  })
})

export default Yup
