import React from 'react'

import Tooltip from './Tooltip'

const OptionsDropdown = ({ children, ...props }) => (
  <Tooltip
    id='options-tooltip'
    place='bottom'
    className='white options-dropdown'
    effect='solid'
    globalEventOff='click'
    event='click'
    clickable
    {...props}
  >
    {children}
  </Tooltip>
)

export default OptionsDropdown
