import { t } from 'i18n-js'

class CompanyForm {
  static selector = '#new_company, .edit_company'

  translations = {
    name: {
      person: t('v2.companies.form.name.person'),
      company: t('v2.companies.form.name.company')
    },
    registration_number: {
      person: t('v2.companies.form.registration_number.person'),
      company: t('v2.companies.form.registration_number.company')
    }
  }

  constructor(form) {
    this.form = form

    this.bindElements()

    if (this.selectors.isPerson) {
      this.bindEvents()
      this.formatForm(this.selectors.isPerson.checked)
    }
  }

  bindElements() {
    this.selectors = {
      nameLabel: document.querySelector('.form-group.company_name label'),
      regNumberLabel: document.querySelector('.form-group.company_registration_number label'),
      isPerson: document.querySelector('#company_is_person'),
      isForeignInput: document.querySelector('#company_is_foreign'),
      isForeignField: document.querySelector('.form-group.company_is_foreign')
    }
  }

  bindEvents() {
    this.selectors.isPerson.addEventListener('change', event => {
      this.formatForm(event.target.checked)
    })
  }

  formatForm(isPersonChecked) {
    if (isPersonChecked) {
      this.selectors.isForeignField.classList.add('d-none')
      this.selectors.nameLabel.innerHTML = this.translations['name']['person']
      this.selectors.regNumberLabel.innerHTML = this.translations['registration_number']['person']
    } else {
      this.selectors.isForeignField.classList.remove('d-none')
      this.selectors.nameLabel.innerHTML = this.translations['name']['company']
      this.selectors.regNumberLabel.innerHTML = this.translations['registration_number']['company']
    }
  }
}

export default CompanyForm
