import React from 'react'

import ActiveTable from '../ActiveTable'
import { edit } from '../elements/actionDefinitions'

class ReportCompaniesTable extends React.Component {
  actions = {
    row: [edit(this.props.pathPrefix, 'v2.companies.contexts.report.edit.title')]
  }
  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        translationPath='companies'
        onRowClick='openEditForm'
        dangerBy='invalid'
      />
    )
  }
}

export default ReportCompaniesTable
