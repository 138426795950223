import React from 'react'

import { t } from '../../../utils/i18n'
import ActiveTable from '../../ActiveTable'
import { remove, removeSelected } from '../../elements/actionDefinitions'

class SystemAccessTokensTable extends React.Component {
  store = this.props.store
  pathPrefix = 'v2/settings/system_access_tokens'

  columns = [
    {
      header: t('activerecord.attributes.system_access_token.name'),
      accessorKey: 'name'
    },
    {
      header: t('activerecord.attributes.system_access_token.token'),
      accessorKey: 'token',
      minSize: 300
    },
    {
      header: t('activerecord.attributes.system_access_token.created_at'),
      accessorKey: 'created_at'
    }
  ]

  actions = {
    row: [remove(this.pathPrefix)],
    header: [removeSelected(this.pathPrefix)]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        columns={this.columns}
        actions={this.actions}
        pathPrefix={this.pathPrefix}
        className='mb-0'
        translationPath={['settings', 'system_access_tokens']}
      />
    )
  }
}

export default SystemAccessTokensTable
