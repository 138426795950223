class Collapse {
  static selector = '[data-toggle=collapse]'

  constructor(collapseToggle) {
    this.collapseToggle = collapseToggle
    this.bindEvents()
  }

  bindEvents() {
    this.collapseToggle.addEventListener('click', this.toggleElements)
  }

  toggleElements = event => {
    event.preventDefault()
    event.stopPropagation()

    document.querySelectorAll(this.collapseToggle.dataset.target).forEach(element => {
      if (element.classList.contains('collapse')) {
        element.classList.remove('collapse')
      } else {
        element.classList.add('collapse')
      }
    })
  }
}

export default Collapse
