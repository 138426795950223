import { useState, useEffect, useMemo } from 'react'

import axios from 'axios'
import { getApiErrorMessage, showErrorModal } from 'utils/confirmations'
import { url } from 'utils/urlGenerator'

const REQUEST_URL = 'v2/schedules/shift_registrations/summary'

const useDaySummary = ({ schedule, dateIso }) => {
  const [summary, setSummary] = useState({ shifts: null, dayData: false })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const params = {
      date: dateIso,
      schedule_id: schedule.id
    }

    setLoading(true)

    axios
      .get(url(REQUEST_URL, params))
      .then(({ data: { shifts, day_data } }) => {
        setSummary({ shifts, dayData: day_data })
        setLoading(false)
      })
      .catch(() => {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      })
  }, [schedule, dateIso])

  return useMemo(() => [summary, loading], [summary, loading])
}

export default useDaySummary
