import React from 'react'

import ActiveTable from '../ActiveTable'
import {
  edit,
  checkOut,
  checkIn,
  remove,
  removeSelected,
  remoteCheckInTimeTrackable
} from '../elements/actionDefinitions'

class EmployeeConstructionObjectsTable extends React.Component {
  pathPrefix = 'v2/construction_object_employees'

  formParams = {
    context: 'employee',
    construction_object_employee: { employee_id: this.props.employee_id }
  }

  actions = {
    row: [
      edit(this.pathPrefix, 'v2.construction_object_employees.contexts.employee.edit.title', this.formParams),
      remoteCheckInTimeTrackable(this.pathPrefix, 'check_in_time_trackable'),
      checkIn(this.pathPrefix),
      checkOut('latest_checked_in_check_log_id'),
      remove(this.pathPrefix)
    ],
    header: [removeSelected(this.pathPrefix)]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        pathPrefix={this.pathPrefix}
        pathResourceId='construction_object_id'
        highlightedBy='employee_checked_in'
        inactiveBy='archived'
        translationPath={['construction_object_employees', 'contexts', 'employee']}
        formParams={this.formParams}
        reloadPageOnSuccess={true}
        onRowClick='openEditForm'
      />
    )
  }
}

export default EmployeeConstructionObjectsTable
