import React from 'react'

import { useField } from 'formik'

import FormikFieldWrapper from './FormikFieldWrapper'
import Switch from './Switch'

export default props => {
  const { label, disabled, className, fieldIcon, ...otherProps } = props
  const [field] = useField(props)
  const { name } = field

  return (
    <FormikFieldWrapper name={name} label={label} className={className} icon={fieldIcon} {...otherProps}>
      <Switch id={name} disabled={disabled} checked={field.value} {...field} />
    </FormikFieldWrapper>
  )
}
