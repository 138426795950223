import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import CompaniesFilter from '../Companies/CompaniesFilter'
import { SimpleSelect } from '../FilterComponents'
import Icon from '../Icon'
import IconCell from '../IconCell'
import Store from '../Store'
import TableContainer from '../TableContainer'
import { BooleanIcon } from '../elements/TableColumnsElements'
import ConstructionObjectCompaniesTable from './ConstructionObjectCompaniesTable'

class ConstructionObjectCompaniesTableContainer extends TableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'checked_in_employees_count', desc: true }]
  })

  customFilters = () => {
    return <SimpleSelect store={this.store} selectable='contract_kind' options={this.props.contract_kind_options} />
  }

  columns = [
    {
      accessorKey: 'company_id',
      visible: false
    },
    {
      header: t('activerecord.attributes.company.name'),
      accessorKey: 'company_name',
      cell: ({ getValue, row: { original } }) => {
        const value = getValue()
        return original.permissions.show ? <a href={url('v2/companies', original.company_id)}>{value}</a> : value
      }
    },
    {
      header: t('activerecord.attributes.company.registration_number'),
      accessorKey: 'company_registration_number',
      cell: ({
        getValue,
        row: {
          original: { id, is_foreign }
        }
      }) => (
        <IconCell
          id={id}
          showIcon={is_foreign}
          tooltipText={t('activerecord.attributes.company.is_foreign')}
          iconComponent={tooltipId => <Icon icon='globe' className='ml-2 center-icon' data-tip data-for={tooltipId} />}
        >
          {getValue()}
        </IconCell>
      )
    },
    this.props.construction_industry && {
      header: t('activerecord.attributes.construction_object_company.contract_kind'),
      accessorKey: 'contract_kind',
      size: 180,
      sortable: false
    },
    this.props.construction_industry && {
      header: t('activerecord.attributes.construction_object_company.contract_sum'),
      accessorKey: 'contract_sum',
      cell: ({
        row: {
          original: { remaining_contract_sum_count, contract_sum }
        }
      }) => {
        if (remaining_contract_sum_count) {
          return (
            <>
              {contract_sum} <span className='badge badge-secondary'>+{remaining_contract_sum_count}</span>
            </>
          )
        } else {
          return contract_sum
        }
      }
    },
    this.props.construction_industry && {
      header: t('activerecord.attributes.construction_object_company.parent_id'),
      accessorKey: 'parent_name'
    },
    {
      header: t('activerecord.attributes.company.employees'),
      accessorKey: 'checked_in_employees_count',
      cell: ({ row: { original } }) => (
        <>
          <span className='text-success'>{original.checked_in_employees_count}</span> / {original.employees_count}
        </>
      )
    },
    {
      header: t('activerecord.attributes.construction_object_company.sync_all_employees_short'),
      accessorKey: 'sync_all_employees',
      minSize: 120,
      size: 120,
      cell: ({ getValue }) => <BooleanIcon checked={getValue()} />
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <CompaniesFilter
          {...this.props}
          store={this.store}
          customFilters={this.customFilters}
          filters={this.props.construction_industry}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <ConstructionObjectCompaniesTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
        />
      </div>
    )
  }
}

export default ConstructionObjectCompaniesTableContainer
