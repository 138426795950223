import React, { useState } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

import useTranslate from '../../../hooks/useTranslate'
import { url } from '../../../utils/urlGenerator'
import BisConstructionCasesModal from './BisConstructionCasesModal'
import ManualConstructionCaseFormModal from './ManualConstructionCaseFormModal'

const AdditionalConstructionCaseModal = ({ disabled, ligumsId, constructionObjectId, bis }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.Registration')
  const [openMode, setOpenMode] = useState(false)
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation({
    mutationFn: data => {
      return axios.put(url('v2/vedludb/registrations', ligumsId, 'add_construction_case'), {
        construction_case: { ...data }
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['vedludb', 'registration', ligumsId] })
      setOpenMode(false)
    }
  })

  return (
    <div className='mt-3'>
      <button className='btn btn-secondary' onClick={() => setOpenMode(bis ? 'bis' : 'manual')} disabled={disabled}>
        {t('.addAdditionalConstructionCase')}
      </button>
      {openMode === 'bis' && (
        <BisConstructionCasesModal
          constructionObjectId={constructionObjectId}
          onCaseClick={data => mutate({ ...data, pecKadastra: false })}
          onClose={() => setOpenMode(null)}
        />
      )}
      {openMode === 'manual' && (
        <ManualConstructionCaseFormModal
          title={t('.addAdditionalConstructionCase')}
          onSubmit={mutate}
          onCancel={() => setOpenMode(null)}
          onClose={() => setOpenMode(null)}
          isSubmitting={isLoading}
        />
      )}
    </div>
  )
}

export default AdditionalConstructionCaseModal
