import React from 'react'

import { useField } from 'formik'

const BaseError = ({ className = 'invalid-feedback alert alert-danger alert-form', ...props }) => {
  const [_, meta] = useField(props)

  const { error } = meta

  if (!error || typeof error !== 'string') {
    return null
  }

  return <div className={className}>{error}</div>
}

export default BaseError
