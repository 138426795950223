import React, { useState, useMemo } from 'react'

import dayjs from 'dayjs'
import I18n from 'i18n-js'

import useDebounce from '../../hooks/useDebounce'
import { Notificator } from '../Notificator'
import Absences from './Absences'
import SchedulerHeader from './SchedulerHeader'
import SchedulerToolbar from './SchedulerToolbar'
import ShiftSchedulesList from './ShiftSchedulesList'
import { AbsenceContext } from './contexts/AbsenceContext'
import { ExceptionDaysContext } from './contexts/ExceptionDaysContext'
import { MonthContext } from './contexts/MonthContext'
import { SchedulesContext } from './contexts/SchedulesContext'
import useAbsences from './hooks/useAbsences'
import useExceptionDays from './hooks/useExceptionDays'
import useShiftSchedules from './hooks/useShiftSchedules'

dayjs.locale(I18n.locale)

export const monthDatesMetadata = month => {
  const monthDayjs = dayjs(month, 'YYYY-MM')

  const dateFrom = monthDayjs.startOf('month')
  const dateTo = monthDayjs.endOf('month')

  const datesMetadata = []

  let currDate = dateFrom

  while (currDate.diff(dateTo) <= 0) {
    let metadata = Object.create({})

    const weekDay = currDate.day()

    metadata.date = currDate
    metadata.dateIso = currDate.format('YYYY-MM-DD')
    metadata.past = currDate.isBefore(dayjs(), 'date')
    metadata.today = currDate.isToday()
    metadata.future = currDate.isAfter(dayjs(), 'date')
    metadata.saturday = weekDay === 6
    metadata.sunday = weekDay === 0
    metadata.weekDay = weekDay
    metadata.month = month

    datesMetadata.push(metadata)
    currDate = currDate.add(1, 'day')
  }

  return datesMetadata
}

const SchedulerContainer = ({ month: initialMonth, policies, ...others }) => {
  const [month, setMonth] = useState(initialMonth)
  const [searchTerm, setSearchTerm] = useState('')
  const [chosenCompaniesIds, setChosenCompaniesIds] = useState([])
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const exceptionDaysContextValue = useExceptionDays()
  const schedulesContextValue = useShiftSchedules({
    month,
    searchTerm,
    chosenCompaniesIds
  })
  const absenceContextValue = useAbsences({ month, search: searchTerm })

  const datesMetadata = useMemo(() => monthDatesMetadata(month), [month])

  const goToPrevMonth = () => {
    setMonth(month => dayjs(month, 'YYYY-MM').subtract(1, 'month').format('YYYY-MM'))
  }

  const goToNextMonth = () => {
    setMonth(month => dayjs(month, 'YYYY-MM').add(1, 'month').format('YYYY-MM'))
  }

  const goToMonth = month => {
    setMonth(dayjs(month).format('YYYY-MM'))
  }

  const monthContextValue = useMemo(
    () => ({
      month,
      setMonth
    }),
    [month, setMonth]
  )

  return (
    <MonthContext.Provider value={monthContextValue}>
      <ExceptionDaysContext.Provider value={exceptionDaysContextValue}>
        <SchedulesContext.Provider value={schedulesContextValue}>
          <Notificator />
          <div className='scheduler-container'>
            <SchedulerToolbar
              goToPrevMonth={goToPrevMonth}
              goToNextMonth={goToNextMonth}
              goToMonth={goToMonth}
              searchTerm={debouncedSearchTerm}
            />
            <div className='scheduler-content'>
              <SchedulerHeader
                datesMetadata={datesMetadata}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                chosenCompaniesIds={chosenCompaniesIds}
                setChosenCompaniesIds={setChosenCompaniesIds}
              />
              <AbsenceContext.Provider value={absenceContextValue}>
                <Absences datesMetadata={datesMetadata} search={debouncedSearchTerm} policies={policies} />
                <ShiftSchedulesList
                  datesMetadata={datesMetadata}
                  searchTerm={debouncedSearchTerm}
                  chosenCompaniesIds={chosenCompaniesIds}
                  {...others}
                />
              </AbsenceContext.Provider>
            </div>
          </div>
        </SchedulesContext.Provider>
      </ExceptionDaysContext.Provider>
    </MonthContext.Provider>
  )
}

export default SchedulerContainer
