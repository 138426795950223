import React from 'react'

import { t } from '../../../utils/i18n'
import ActiveTable from '../../ActiveTable'
import { edit, remove, removeSelected } from '../../elements/actionDefinitions'
import navigateToFilteredTasks from './navigateToFilteredTasks'

class TaskGroupsTable extends React.Component {
  pathPrefix = 'v2/tasker/task_groups'

  actions = {
    row: [
      edit(this.pathPrefix, 'v2.tasker.user_groups.edit.title'),
      remove(this.pathPrefix, {
        text: t('destroy'),
        confirmationProps: {
          text: t('confirm_destroy'),
          confirmButton: { title: t('destroy'), type: 'danger' }
        }
      })
    ],
    header: [
      removeSelected(this.pathPrefix, {
        text: t('destroy_selected'),
        confirmationProps: {
          text: t('confirm_destroy_selected'),
          confirmButton: { title: t('destroy'), type: 'danger' }
        }
      })
    ]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        pathPrefix={this.pathPrefix}
        translationPath={['tasker', 'task_groups']}
        getTdProps={navigateToFilteredTasks(row => ({ task_group_id: [row.original.id] }))}
      />
    )
  }
}

export default TaskGroupsTable
