import React from 'react'

import { t } from '../../../utils/i18n'
import Avatar from '../../Avatar'
import Store from '../../Store'
import TableContainer from '../../TableContainer'
import UsersFilter from './UsersFilter'
import UsersTable from './UsersTable'

class UsersTableContainer extends TableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'full_name', desc: false }],
    baseScope: this.props.base_scope
  })

  columns = [
    {
      header: t('activerecord.attributes.user.full_name'),
      accessorKey: 'full_name',
      size: 200,
      cell: ({ getValue, row: { original } }) => <Avatar src={original.picture_url} text={getValue()} />
    },
    {
      header: t('activerecord.attributes.user.description'),
      accessorKey: 'description'
    },
    {
      header: t('activerecord.attributes.user.time_entries_duration'),
      accessorKey: 'time_entries_duration',
      size: 120
    },
    {
      header: t('activerecord.attributes.tasker/user_group.draft_tasks_count'),
      accessorKey: 'draft_tasks_count',
      size: 60,
      minSize: 60
    },
    {
      header: t('activerecord.attributes.tasker/user_group.in_progress_tasks_count'),
      accessorKey: 'in_progress_tasks_count',
      size: 60,
      minSize: 60
    },
    {
      header: t('activerecord.attributes.tasker/user_group.delayed_tasks_count'),
      accessorKey: 'delayed_tasks_count',
      size: 60,
      minSize: 60
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <UsersFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <UsersTable {...this.props} store={this.store} columns={this.columns} columnOptions={columnOptions} />
      </div>
    )
  }
}

export default UsersTableContainer
