import React from 'react'

import Axios from 'axios'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import { ConfirmationWithDataTransfer } from '../Confirmation'
import ModalForm from '../ModalForm'
import ModalFormikForm from '../ModalFormikForm'
import TasksFormModal from '../Tasker/Tasks/TasksFormModal'
import { action } from './actions'

const groupTimeRecords = ids => {
  let nfc_check_log_ids = []
  let time_entry_ids = []

  ids.forEach(id => {
    typeof id === 'string' && id.includes('TimeEntry_')
      ? time_entry_ids.push(id.replace('TimeEntry_', ''))
      : nfc_check_log_ids.push(id)
  })

  return { nfc_check_log_ids, time_entry_ids }
}

const checkIn = pathPrefix =>
  action({
    permission: 'check_in',
    text: t('check_in'),
    icon: 'sign-in',
    onClick: ({ id }) => Axios.put(url(pathPrefix, id, 'manual_check_in')),
    confirmationProps: {
      text: t('confirm_check_in'),
      confirmButton: { title: t('check_in'), type: 'warning' }
    }
  })

const remoteCheckInTimeTrackable = (pathPrefix, permission) => ({
  permission: permission,
  buttonProps: { icon: 'sign-in', text: t('check_in') },
  action: (object, props) => (
    <ModalForm
      {...props}
      url={url(pathPrefix, object.id, 'remote_employee_check_in_form')}
      title={t('check_in')}
      skipToggleButton
      reloadPageOnSuccess
    />
  )
})

const checkInSelected = (pathPrefix, construction_object_id) =>
  action({
    text: t('check_in_selected'),
    icon: 'sign-in',
    onClick: ids => Axios.put(url(pathPrefix, 'manual_check_in_selected'), { ids, construction_object_id }),
    confirmationProps: {
      text: t('confirm_check_in_selected'),
      confirmButton: { title: t('check_in'), type: 'warning' }
    }
  })

const checkOut = (nfcCheckLogIdPropertyName, overrides = {}) =>
  action({
    permission: 'check_out',
    text: t('check_out'),
    icon: 'sign-out',
    onClick: object => Axios.put(url('v2/nfc_check_logs', object[nfcCheckLogIdPropertyName], 'manual_check_out')),
    confirmationProps: {
      text: t('confirm_check_out'),
      confirmButton: { title: t('check_out'), type: 'warning' }
    },
    ...overrides
  })

const closeTimeEntry = () =>
  action({
    permission: 'close_time_entry',
    text: t('check_out'),
    icon: 'sign-out',
    onClick: object =>
      Axios.put(url('v2/construction_object_time_entries', object.id.replace('TimeEntry_', ''), 'manual_close')),
    confirmationProps: {
      text: t('confirm_check_out'),
      confirmButton: { title: t('check_out'), type: 'warning' }
    }
  })

const stopTimeTracking = (pathPrefix, taskId) =>
  action({
    permission: 'stop_time_tracking',
    text: t('stop'),
    icon: 'pause',
    onClick: ({ id }) => Axios.put(url(pathPrefix, id, 'manual_close', { task_id: taskId })),
    confirmationProps: {
      text: t('confirm_stop_time_entry'),
      confirmButton: { title: t('stop'), type: 'warning' }
    }
  })

const checkOutEventEmployees = () =>
  action({
    permission: 'check_out',
    customText: ({ employees }) => (employees.length === 1 ? t('check_out') : t('check_out_many')),
    icon: 'sign-out',
    onClick: ({ employees }) =>
      Axios.put(url('v2/nfc_check_logs/manual_check_out_selected'), {
        nfc_check_log_ids: employees.map(e => e.nfc_check_log_id)
      }),
    confirmationProps: {
      text: t('confirm_check_out'),
      confirmButton: { title: t('check_out'), type: 'warning' }
    }
  })

const checkOutSelected = pathPrefix =>
  action({
    text: t('check_out_selected'),
    icon: 'sign-out',
    onClick: ids => Axios.put(url(pathPrefix, 'manual_check_out_selected'), groupTimeRecords(ids)),
    confirmationProps: {
      text: t('confirm_check_out_selected'),
      confirmButton: { title: t('check_out'), type: 'warning' }
    }
  })

const activate = pathPrefix =>
  action({
    permission: 'activate',
    text: t('activate'),
    icon: 'check-circle',
    onClick: ({ id }) => Axios.put(url(pathPrefix, id, 'activate')),
    confirmationProps: {
      text: t('confirm_activate'),
      confirmButton: { title: t('activate'), type: 'success' }
    }
  })

const activateSelected = pathPrefix =>
  action({
    text: t('activate_selected'),
    icon: 'check-circle',
    onClick: ids => Axios.put(url(pathPrefix, 'activate_selected'), { ids }),
    confirmationProps: {
      text: t('confirm_activate_selected'),
      confirmButton: { title: t('activate'), type: 'success' }
    }
  })

const archive = pathPrefix =>
  action({
    permission: 'archive',
    text: t('archive'),
    icon: 'archive',
    onClick: ({ id }) => Axios.put(url(pathPrefix, id, 'archive')),
    confirmationProps: {
      text: t('confirm_archive'),
      confirmButton: { title: t('archive'), type: 'danger' }
    }
  })

const archiveSelected = pathPrefix =>
  action({
    text: t('archive_selected'),
    icon: 'archive',
    onClick: ids => Axios.put(url(pathPrefix, 'archive_selected'), { ids }),
    confirmationProps: {
      text: t('confirm_archive_selected'),
      confirmButton: { title: t('archive'), type: 'danger' }
    }
  })

const deactivate = pathPrefix =>
  action({
    permission: 'deactivate',
    text: t('deactivate'),
    icon: 'times-circle',
    onClick: ({ id }) => Axios.put(url(pathPrefix, id, 'deactivate')),
    confirmationProps: {
      text: t('confirm_deactivate'),
      confirmButton: { title: t('deactivate'), type: 'warning' }
    }
  })

const deactivateSelected = pathPrefix =>
  action({
    text: t('deactivate_selected'),
    icon: 'times-circle',
    onClick: ids => Axios.put(url(pathPrefix, 'deactivate_selected'), { ids }),
    confirmationProps: {
      text: t('confirm_deactivate_selected'),
      confirmButton: { title: t('deactivate'), type: 'warning' }
    }
  })

/**
 * Soft delete main object, e.g. company
 */
const discard = (pathPrefix, overrides = {}) =>
  action({
    permission: 'discard',
    text: t('discard'),
    icon: 'trash-o',
    onClick: ({ id }) => Axios.put(url(pathPrefix, id, 'discard')),
    confirmationProps: {
      text: t('confirm_discard'),
      confirmButton: { title: t('discard'), type: 'danger' }
    },
    ...overrides
  })

const discardSelected = pathPrefix =>
  action({
    text: t('discard_selected'),
    icon: 'trash-o',
    onClick: ids => Axios.put(url(pathPrefix, 'discard_selected'), { ids }),
    confirmationProps: {
      text: t('confirm_discard_selected'),
      confirmButton: { title: t('discard'), type: 'danger' }
    }
  })

const discardConsructionObjectTimeEntry = () =>
  action({
    permission: 'discard_time_entry',
    text: t('discard'),
    icon: 'trash-o',
    onClick: ({ id }) => Axios.put(url('v2/construction_object_time_entries', id.replace('TimeEntry_', ''), 'discard')),
    confirmationProps: {
      text: t('confirm_discard'),
      confirmButton: { title: t('discard'), type: 'danger' }
    }
  })

const discardSelectedTimeRecords = () =>
  action({
    text: t('discard_selected'),
    icon: 'trash-o',
    onClick: ids => Axios.put(url('v2/nfc_check_logs', 'discard_selected'), groupTimeRecords(ids)),
    confirmationProps: {
      text: t('confirm_discard_selected'),
      confirmButton: { title: t('discard'), type: 'danger' }
    }
  })

const edit = (pathPrefix, title, formParams = {}) => ({
  permission: 'edit',
  buttonProps: { icon: 'pencil', text: t('edit') },
  action: (object, props) => (
    <ModalForm
      {...props}
      action='edit'
      url={url(pathPrefix, object.id, 'edit', formParams)}
      title={t(title)}
      skipToggleButton
      reloadPageOnSuccess
    />
  )
})

const editFormik = ({ pathPrefix, title, formikForm, formParams = {}, overrides = {} }) => ({
  permission: 'edit',
  buttonProps: { icon: 'pencil', text: t('edit') },
  action: (object, props) => (
    <ModalFormikForm
      {...props}
      action='edit'
      url={pathPrefix && url(pathPrefix, object.id, 'edit', formParams)}
      title={t(title)}
      skipToggleButton
      reloadPageOnSuccess
      formikForm={formikForm}
      formData={object}
      {...overrides}
    />
  )
})

const editTask = () => ({
  permission: 'edit',
  buttonProps: { icon: 'pencil', text: t('edit') },
  action: (object, props) => (
    <TasksFormModal {...props} objectId={object.id} title={t('v2.tasker.tasks.edit.title')} skipToggle />
  )
})

const editRelatedGroup = () => ({
  permission: 'edit_related_task_group',
  buttonProps: { icon: 'pencil', text: t('edit_group') },
  action: (object, props) => (
    <TasksFormModal
      {...props}
      parentId={object.parent_id}
      title={t('v2.tasker.tasks.edit_related.title')}
      isGroup
      skipToggle
    />
  )
})

const editConsructionObjectTaskSubTask = pathPrefix => ({
  permission: 'edit_sub_task',
  buttonProps: { icon: 'pencil', text: t('edit') },
  action: (object, props) => (
    <ModalForm
      {...props}
      action='edit'
      url={url(pathPrefix, object.parent_id, 'edit', { sub_task_id: object.id })}
      title={t('v2.tasker.tasks.edit_related.title')}
      skipToggleButton
      reloadPageOnSuccess
    />
  )
})

const discardConsructionObjectTaskSubTask = pathPrefix =>
  action({
    permission: 'discard_sub_task',
    text: t('discard'),
    icon: 'trash-o',
    onClick: ({ id }) => Axios.delete(url(pathPrefix, 'discard_sub_task', { id })),
    confirmationProps: {
      text: t('v2.construction_object_tasks.index.confirm_discard_sub_task'),
      confirmButton: { title: t('destroy'), type: 'danger' }
    }
  })

const invite = pathPrefix =>
  action({
    permission: 'invite',
    text: t('invite'),
    icon: 'envelope-o',
    onClick: ({ id }) => Axios.put(url(pathPrefix, id, 'invite')),
    confirmationProps: {
      text: t('confirm_invite'),
      confirmButton: { title: t('invite'), type: 'success' }
    }
  })

const inviteSelected = pathPrefix =>
  action({
    text: t('invite_selected'),
    icon: 'envelope-o',
    onClick: ids => Axios.put(url(pathPrefix, 'invite_selected'), { ids }),
    confirmationProps: {
      text: t('confirm_invite_selected'),
      confirmButton: { title: t('invite'), type: 'success' }
    }
  })

/**
 * Remove linked object, e.g. costruction object from company
 */
const remove = (pathPrefix, overrides = {}) =>
  action({
    permission: 'destroy',
    text: t('remove'),
    icon: 'trash-o',
    onClick: ({ id }) => Axios.delete(url(pathPrefix, id)),
    confirmationProps: {
      text: t('confirm_remove'),
      confirmButton: { title: t('remove'), type: 'danger' }
    },
    ...overrides
  })

const destroyRelatedTaskGroup = pathPrefix =>
  action({
    permission: 'destroy_related_task_group',
    text: t('v2.tasker.tasks.index.destroy_related_task_group'),
    icon: 'trash-o',
    onClick: ({ parent_id }) => Axios.delete(url(pathPrefix, 'destroy_related_task_group', { parent_id })),
    confirmationProps: {
      text: t('v2.tasker.tasks.index.confirm_destroy_related_task_group'),
      confirmButton: { title: t('destroy'), type: 'danger' }
    }
  })

const destroy = pathPrefix =>
  action({
    permission: 'destroy',
    text: t('destroy'),
    icon: 'trash-o',
    onClick: ({ id }) => Axios.delete(url(pathPrefix, id)),
    confirmationProps: {
      text: t('confirm_destroy'),
      confirmButton: { title: t('destroy'), type: 'danger' }
    }
  })

const destroyAbsenceType = ({ pathPrefix, options }) => ({
  permission: 'destroy',
  buttonProps: { icon: 'trash-o', text: t('destroy') },
  action: ({ abbreviation, id, absence_count }, props) => (
    <ConfirmationWithDataTransfer
      {...props}
      closeOnConfirm
      confirmButton={{ title: t('transfer_and_destroy'), type: 'danger' }}
      title={t('v2.absence_types.destroy.title')}
      description={t('v2.absence_types.destroy.description', { abbreviation })}
      options={options.filter(o => o.value !== id)}
      transferTitle={t('v2.absence_types.destroy.transfer_title', { abbreviation, absence_count })}
      onConfirm={typeId => Axios.delete(url(pathPrefix, id, { transfer_to_id: typeId }))}
      renderOptionLabel={({ abbreviation, title }) => (
        <>
          <span className='text-truncate'>{title}</span>
          <span className='text-secondary text-nowrap ml-2'>( {abbreviation} )</span>
        </>
      )}
      formatSelectedItem={({ abbreviation, title }) => `${title} ( ${abbreviation} )`}
    />
  )
})

const destroyShiftLabel = ({ pathPrefix, options }) => ({
  permission: 'destroy',
  buttonProps: { icon: 'trash-o', text: t('destroy') },
  action: ({ name, id }, props) => (
    <ConfirmationWithDataTransfer
      {...props}
      closeOnConfirm
      confirmButton={{ title: t('transfer_and_destroy'), type: 'danger' }}
      title={t('v2.shift_labels.destroy.title')}
      description={t('v2.shift_labels.destroy.description', { name })}
      options={options.filter(o => o.value !== id)}
      transferTitle={t('v2.shift_labels.destroy.transfer_title', { name, shift_day_count: 0 })} // ADD SHIFT DAY COUNT WHEN ASSOCIATION IMPELEMNTED
      onConfirm={typeId => Axios.delete(url(pathPrefix, id, { transfer_to_id: typeId }))}
      renderOptionLabel={({ name, color }) => (
        <>
          <span className='text-truncate'>{name}</span>
          <span
            className='text-secondary text-nowrap ml-2'
            style={{ backgroundColor: color, width: '10px', height: '10px', borderRadius: '50%' }}
          ></span>
        </>
      )}
      formatSelectedItem={({ name }) => `${name}`}
    />
  )
})

const removeSelected = (pathPrefix, overrides = {}) =>
  action({
    text: t('remove_selected'),
    icon: 'trash-o',
    onClick: ids => Axios.put(url(pathPrefix, 'destroy_selected'), { ids }),
    confirmationProps: {
      text: t('confirm_remove_selected'),
      confirmButton: { title: t('remove'), type: 'danger' }
    },
    ...overrides
  })

const unlock = pathPrefix =>
  action({
    permission: 'unlock_access',
    text: t('unlock'),
    icon: 'unlock',
    onClick: ({ id }) => Axios.put(url(pathPrefix, id, 'unlock_access')),
    confirmationProps: {
      text: t('confirm_unlock'),
      confirmButton: { title: t('unlock'), type: 'success' }
    }
  })

const unlockSelected = pathPrefix =>
  action({
    text: t('unlock_selected'),
    icon: 'unlock',
    onClick: ids => Axios.put(url(pathPrefix, 'unlock_access_selected'), { ids }),
    confirmationProps: {
      text: t('confirm_unlock_selected'),
      confirmButton: { title: t('unlock'), type: 'success' }
    }
  })

const unlinkTimeTrackable = pathPrefix =>
  action({
    permission: 'unlink_time_trackable',
    text: t('remove'),
    icon: 'unlink',
    onClick: ({ id }) => Axios.put(url(pathPrefix, id, 'unlink_time_trackable')),
    confirmationProps: {
      text: t('confirm_unlink_time_trackable'),
      confirmButton: { title: t('remove'), type: 'danger' }
    }
  })

const unlinkTimeTrackableSelected = pathPrefix =>
  action({
    text: t('remove_selected'),
    icon: 'unlink',
    onClick: ids => Axios.put(url(pathPrefix, 'unlink_time_trackable_selected'), { ids }),
    confirmationProps: {
      text: t('confirm_unlink_time_trackable_selected'),
      confirmButton: { title: t('remove'), type: 'danger' }
    }
  })

export {
  activate,
  archive,
  checkOut,
  checkIn,
  remoteCheckInTimeTrackable,
  deactivate,
  discard,
  edit,
  editTask,
  editFormik,
  invite,
  remove,
  destroy,
  unlock,
  activateSelected,
  archiveSelected,
  checkOutSelected,
  checkInSelected,
  deactivateSelected,
  discardSelected,
  inviteSelected,
  removeSelected,
  unlockSelected,
  destroyRelatedTaskGroup,
  checkOutEventEmployees,
  stopTimeTracking,
  unlinkTimeTrackable,
  unlinkTimeTrackableSelected,
  editRelatedGroup,
  editConsructionObjectTaskSubTask,
  discardConsructionObjectTaskSubTask,
  closeTimeEntry,
  discardConsructionObjectTimeEntry,
  discardSelectedTimeRecords,
  destroyAbsenceType,
  destroyShiftLabel
}
