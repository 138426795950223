import React from 'react'

import { t } from '../../utils/i18n'
import Icon from '../Icon'
import Tooltip from '../Tooltip'

const Identifiers = ({ nfc, fingerprint, id }) => {
  return (
    <React.Fragment>
      {nfc && <IdentifierIcon icon='checkWithNfc' type='nfc' id={id} />}
      {fingerprint && <IdentifierIcon icon='checkWithFingerprint' type='fingerprint' id={id} />}
    </React.Fragment>
  )
}

const IdentifierIcon = ({ icon, type, id }) => {
  const tooltipId = `employee-${type}-identifier-${id}`

  return (
    <React.Fragment>
      <Icon icon={icon} className='mr-2 pb-1' data-tip data-for={tooltipId} />

      <Tooltip id={tooltipId} className='accented' place='bottom' effect='solid'>
        {t(`activerecord.attributes.employee.${type}_identifier`)}
      </Tooltip>
    </React.Fragment>
  )
}

export { Identifiers }
