import React, { useEffect, useCallback, useRef } from 'react'

import classNames from 'classnames'

import Icon from './Icon'

const ModalButtons = ({ buttons, buttonPlacement }) => {
  if (!buttons) return null

  return (
    <div className={classNames('btn-container', buttonPlacement)}>
      {buttons.map(({ type, title, onClick, disabled }, i) => (
        <button key={i} className={classNames('btn', { [`btn-${type}`]: type })} onClick={onClick} disabled={disabled}>
          {title}
        </button>
      ))}
    </div>
  )
}

const Modal = ({
  children,
  className,
  isOpen,
  onClose,
  dataContains,
  header,
  title,
  buttons,
  buttonPlacement,
  closeOnBackdropClick = true,
  withDefaultModalContent = true
}) => {
  const modalRef = useRef()

  const close = useCallback(
    e => {
      if (e.key === 'Escape' && isOpen) {
        onClose()
      }
    },
    [isOpen, onClose]
  )

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', close)
      return () => {
        document.removeEventListener('keydown', close)
      }
    }
  }, [isOpen, close])

  const onBackdropClick = event => {
    if (event.target === modalRef.current) onClose(event)
  }

  if (!isOpen) return null

  return (
    <React.Fragment>
      <div className='modal-backdrop show' />
      <div
        className='modal d-block'
        onClick={closeOnBackdropClick ? onBackdropClick : null}
        ref={modalRef}
        data-contains={dataContains}
      >
        <div className={classNames('modal-dialog', className)}>
          {withDefaultModalContent && (
            <div className='modal-content'>
              <div className='modal-header align-items-center border-bottom-0'>
                {header || (title && <h2 className='modal-title'>{title}</h2>)}

                <span className='close' onClick={onClose}>
                  <Icon icon='cross' className='d-flex' />
                </span>
              </div>

              <div className='modal-body'>
                {children}
                <ModalButtons buttons={buttons} buttonPlacement={buttonPlacement} />
              </div>
            </div>
          )}
          {!withDefaultModalContent && <div className='modal-content'>{children}</div>}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Modal
