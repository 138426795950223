import React, { useContext, useMemo } from 'react'

import classNames from 'classnames'

import { Option, OptionList } from '../../../Options'
import { SelectContext } from './index'

const InputOption = ({ index, data, style }) => {
  const { renderOptionLabel, getItemProps, highlightedIndex, selectedItems, withImages, isMulti, inputItems } =
    useContext(SelectContext)

  const highlighted = !isMulti && highlightedIndex === index
  const item = data.items[index]
  const trueIndex = inputItems.findIndex(({ value }) => value === item.value)

  return (
    <Option
      index={index}
      data={data}
      style={style}
      item={item}
      renderOptionLabel={renderOptionLabel}
      className={classNames('', { highlighted, simple: !isMulti && !withImages })}
      selectedItems={selectedItems}
      containerProps={getItemProps({ item, index: trueIndex })}
      isMulti={isMulti}
      withImages={withImages}
    />
  )
}

const getSelectMap = selectedItems =>
  selectedItems.reduce((res, item) => {
    res[item.value] = true
    return res
  }, {})

const sortItemsSelectedFirst = (inputItems, selectMap) =>
  inputItems.sort((a, b) => !!selectMap[b.value] - !!selectMap[a.value])

const SelectOptions = ({ listRef, onMouseLeave }) => {
  const { inputItems, isMulti, selectItem, selectedItems, optionsFooter } = useContext(SelectContext)

  const sortedItems = useMemo(
    () => (isMulti ? sortItemsSelectedFirst([...inputItems], getSelectMap(selectedItems)) : inputItems),
    [inputItems, isMulti, selectedItems]
  )

  return (
    <OptionList
      isMulti={isMulti}
      options={sortedItems}
      selectedItems={selectedItems}
      onSelect={selectItem}
      listRef={listRef}
      onMouseLeave={onMouseLeave}
      optionsFooter={optionsFooter}
      OptionComponent={InputOption}
    />
  )
}

export default SelectOptions
