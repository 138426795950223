import React, { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import dayjs from 'dayjs'
import { FormProvider, useForm } from 'react-hook-form'
import yup from 'utils/yup'

import useTranslate from '../../../hooks/useTranslate'
import { formatCurrency } from '../../../utils/formatters'
import { url } from '../../../utils/urlGenerator'
import { dateFormatter } from '../../Forms/Formatters'
import { formatValuesForSubmit } from '../../Forms/Formatters/submit'
import AdditionalConstructionCaseModal from './AdditionalConstructionCaseModal'
import ConstructionCasesTable from './ConstructionCasesTable'
import History from './History'
import RegistrationForm from './RegistrationForm'
import RegistrationStatus from './RegistrationStatus'
import { commonRegistrationFieldSchemas } from './common'

const validationSchema = yup.object({
  ...commonRegistrationFieldSchemas,
  pamatojums: yup.string().required()
})

const getRegistration = async ligumsId => {
  return await axios.get(url('v2/vedludb/registrations', ligumsId)).then(res => res.data)
}

const dataToForm = data => ({
  buvniecibasUzsaksanasDatums: data && dayjs(data.buvniecibasUzsaksanasDatums).toDate(),
  ligumaDatums: data && dayjs(data.buvdarbuLigumaDatums).toDate(),
  ligumaSumma: formatCurrency(data?.buvdarbuLigumaSumma || ''),
  citsPasutitajs: !!data?.pasutitajaNosaukums,
  pasutitajaNosaukums: data?.pasutitajaNosaukums,
  pasutitajaRegNr: data?.pasutitajaRegistracijasNr,
  pamatojums: ''
})

const valueFormats = {
  ligumaDatums: dateFormatter
}

const Registration = ({ ligumsId, constructionObjectId, setRegistration }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.Registration')
  const queryClient = useQueryClient()
  const [historyIsOpen, setHistoryIsOpen] = useState(false)

  const { isPending, isLoading, isError, data } = useQuery({
    queryKey: ['vedludb', 'registration', ligumsId],
    queryFn: () => getRegistration(ligumsId),
    retry: false
  })

  useEffect(() => {
    setRegistration(data)
  }, [data, setRegistration])

  const formContext = useForm({
    values: dataToForm(data),
    mode: 'onSubmit',
    resetOptions: {
      keepDirtyValues: true // keep dirty fields unchanged, but update defaultValues
    },
    resolver: yupResolver(validationSchema)
  })

  const {
    handleSubmit,
    reset,
    formState: { isDirty, errors: _errors }
  } = formContext

  const { mutate, isLoading: isSubmitting } = useMutation({
    mutationFn: formData => {
      const { citsPasutitajs: _a, buvniecibasUzsaksanasDatums: _b, ...otherValues } = formData
      return axios.put(url('v2/vedludb/registrations', ligumsId), {
        registration: {
          ...otherValues,
          pasutitajaNosaukums: formData.citsPasutitajs ? formData.pasutitajaNosaukums : '',
          pasutitajaRegNr: formData.citsPasutitajs ? formData.pasutitajaRegNr : '',
          ...formatValuesForSubmit(otherValues, valueFormats)
        }
      })
    },
    onSuccess: () => {
      reset()
      queryClient.invalidateQueries({ queryKey: ['vedludb', 'registration', ligumsId] })
    }
  })

  if (isPending || isLoading) {
    return <div>{t('loading_data')}</div>
  }

  if (isError) {
    return null
  }

  const bis = !!data.buvniecibasLietas.find(item => item.buvatlaujasNr)

  const cancelButton = (
    <button type='button' className='btn btn-secondary' onClick={() => setHistoryIsOpen(true)}>
      {t('.history')}
    </button>
  )

  return (
    <FormProvider {...formContext}>
      {data.buvniecibasLietas.length ? <ConstructionCasesTable cases={data.buvniecibasLietas} /> : null}
      <AdditionalConstructionCaseModal
        constructionObjectId={constructionObjectId}
        ligumsId={ligumsId}
        disabled={isDirty}
        bis={bis}
      />
      <RegistrationStatus data={data} ligumsId={ligumsId} />
      <RegistrationForm
        onSubmit={handleSubmit(mutate)}
        isSubmitting={isSubmitting}
        formContext={formContext}
        extraButtons={cancelButton}
        editing
      />

      {historyIsOpen && <History ligumsId={ligumsId} onClose={() => setHistoryIsOpen(false)} />}
    </FormProvider>
  )
}

export default Registration
