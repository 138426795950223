import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../../utils/i18n'
import { FilterContainer, SearchField, SimpleSelect } from '../../FilterComponents'

class AccountsFilter extends React.Component {
  hiddenFilters = () => {
    const { store, default_filter } = this.props

    return (
      <React.Fragment>
        <SimpleSelect
          store={store}
          selectable='industry'
          options={this.props.industry_options}
          dataContains='select-industry-filter'
          isMulti
        />
        <SimpleSelect
          store={store}
          selectable='state'
          options={this.props.state_options}
          dataContains='select-state-filter'
          defaultFilter={default_filter}
          isMulti
        />
        <SimpleSelect
          store={store}
          selectable='locale'
          options={this.props.locale_options}
          isMulti
          dataContains='select-locale-filter'
        />
      </React.Fragment>
    )
  }

  render() {
    const { store, filters, export_url } = this.props

    return (
      <FilterContainer store={store} hiddenFilters={this.hiddenFilters} filters={filters} export_url={export_url}>
        <SearchField store={store} placeholder={t('filters.search_accounts')} />
      </FilterContainer>
    )
  }
}

export default observer(AccountsFilter)
