import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import ConstructionObjectsFilter from '../ConstructionObjects/ConstructionObjectsFilter'
import { SimpleSelect } from '../FilterComponents'
import Permissions from '../Permissions'
import Store from '../Store'
import TableContainer from '../TableContainer'
import ConstructionObjectUserNotificationSettings from '../elements/ConstructionObjectUserNotificationSettings'
import UserConstructionObjectsTable from './UserConstructionObjectsTable'

class UserConstructionObjectsTableContainer extends TableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'construction_object_name', desc: false }]
  })

  customFilters = () => {
    return (
      <React.Fragment>
        <SimpleSelect store={this.store} selectable='role' options={this.props.roles_options} />
        <SimpleSelect store={this.store} selectable='permission' options={this.props.permissions_options} />
      </React.Fragment>
    )
  }

  columns = [
    {
      accessorKey: 'construction_object_id',
      visible: false
    },
    {
      header: t('activerecord.attributes.construction_object.name'),
      accessorKey: 'construction_object_name',
      cell: ({ getValue, row: { original } }) => (
        <a href={url('v2/construction_objects', original.construction_object_id)}>{getValue()}</a>
      )
    },
    {
      header: t('activerecord.attributes.construction_object.short_name'),
      accessorKey: 'construction_object_short_name',
      cell: ({ getValue, row: { original } }) => (
        <a href={url('v2/construction_objects', original.construction_object_id)}>{getValue()}</a>
      )
    },
    {
      header: t('activerecord.attributes.user.role'),
      accessorKey: 'role',
      sortable: false
    },
    {
      header: t('activerecord.attributes.construction_object_user.permissions'),
      accessorKey: 'permissions',
      sortable: false,
      cell: ({ row: { original } }) => (
        <Permissions object={original} type='construction_object_user' permissions={this.props.permissions} />
      )
    },
    {
      header: t('activerecord.attributes.construction_object_user.notification_settings'),
      accessorKey: 'notification_settings',
      sortable: false,
      cell: ({ row: { original } }) => <ConstructionObjectUserNotificationSettings constructionObjectUser={original} />
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <ConstructionObjectsFilter
          {...this.props}
          store={this.store}
          customFilters={this.customFilters}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <UserConstructionObjectsTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
        />
      </div>
    )
  }
}

export default UserConstructionObjectsTableContainer
