import React from 'react'

import { useField } from 'formik'

import FormikFieldWrapper from './FormikFieldWrapper'
import Select from './Select'

export default props => {
  const { options, name, onSelect, label, required, className, fieldIcon, ...others } = props
  const [{ value }, , { setValue }] = useField(props)

  return (
    <FormikFieldWrapper name={name} required={required} className={className} icon={fieldIcon} label={label}>
      <Select {...others} name={name} items={options} onSelect={onSelect ? onSelect : setValue} value={value} />
    </FormikFieldWrapper>
  )
}
