import React from 'react'

import MonthDay from './MonthDay'

const MonthDaysRow = ({ weekName, weekDaysNames, id, selected, disabled }) => {
  const days = weekDaysNames.map((dayName, i) => (
    <MonthDay
      day={dayName}
      key={i}
      id={i}
      rowId={id}
      selected={selected && selected[`day_${i + 1}`] === 'true'}
      disabled={disabled}
    />
  ))

  return (
    <div className='task-regularity-month-days-row'>
      {days}
      {weekName}
    </div>
  )
}

export default MonthDaysRow
