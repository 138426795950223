import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../utils/i18n'
import { FilterContainer, SearchField, SimpleSelect } from '../FilterComponents'

class DevicesFilter extends React.Component {
  hiddenFilters = () => {
    const { store, user_options } = this.props

    return <SimpleSelect store={store} selectable='user_id' options={user_options} isMulti />
  }

  render() {
    const { store, ...others } = this.props
    return (
      <FilterContainer store={store} hiddenFilters={this.hiddenFilters} {...others}>
        <SearchField store={store} placeholder={t('filters.search_devices')} />
      </FilterContainer>
    )
  }
}

export default observer(DevicesFilter)
