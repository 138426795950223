import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../utils/i18n'
import { FilterContainer, SearchField, SimpleSelect, DateRangeFilter } from '../FilterComponents'

class ConstructionObjectTasksFilter extends React.Component {
  hiddenFilters = () => {
    const { store, activities_options } = this.props

    return (
      <SimpleSelect
        store={store}
        selectable='task_activities'
        options={activities_options}
        dataContains='select-task-activities-filter'
      />
    )
  }

  render() {
    const { store, ...others } = this.props

    return (
      <FilterContainer store={store} hiddenFilters={this.hiddenFilters} {...others}>
        <SearchField store={store} placeholder={t('filters.search_tasks')} />
        <DateRangeFilter store={store} />
      </FilterContainer>
    )
  }
}

export default observer(ConstructionObjectTasksFilter)
