import React, { useCallback, useEffect, useState, useMemo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import classNames from 'classnames'
import ReactDOM from 'react-dom'
import { FormProvider, useForm } from 'react-hook-form'

import useTranslate from '../../../../hooks/useTranslate'
import useUnsavedChangesConfirmation from '../../../../hooks/useUnsavedChangesConfirmation'
import { showConfirmation, getApiErrorMessage, showErrorModal } from '../../../../utils/confirmations'
import { url } from '../../../../utils/urlGenerator'
import { formatValuesForSubmit } from '../../../Forms/Formatters/submit'
import {
  ColorPickerField,
  MultiselectField,
  RegularityOptions,
  SelectField,
  TextField,
  TimeField,
  SwitchField
} from '../../../Forms/HookFields'
import { handleHookFormError } from '../../../Forms/utils/formErrors'
import Modal from '../../../Modal'
import { IconWithTooltip } from '../../../Tooltip'
import ModalFormButtons from '../../common/ModalFormButtons'
import { useSchedulesContext } from '../../contexts/SchedulesContext'
import useSchedule from '../../hooks/useSchedule'
import TimeFields from '../TimeFields'
import { validationSchema, dataToForm, colors, valueFormats, roundMinsToFive } from './helpers'

const ModalFormHeader = ({ title, children }) => (
  <div className='modal-header-content'>
    <div className='modal-header-title'>{title}</div>
    {children}
  </div>
)

const ScheduleForm = ({ title, scheduleId, setFormOpen, classes = 'modal-lg' }) => {
  const { schedule, isLoading } = useSchedule(scheduleId)
  const [formOptions, setFormOptions] = useState({})
  const { addSchedule, refreshSchedule, removeSchedule } = useSchedulesContext()
  const t = useTranslate('Scheduler.Forms.ScheduleForm')

  useEffect(() => {
    async function fetchFormOptions() {
      const { data } = await axios.get(url('v2/schedules/form_options'))
      setFormOptions(data)
    }
    fetchFormOptions()
  }, [])

  const formData = dataToForm(schedule, formOptions)

  const formContext = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formData,
    mode: 'onSubmit'
  })

  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitting },
    setValue,
    setError,
    getFieldState,
    reset,
    register
  } = formContext

  useEffect(() => {
    reset(dataToForm(schedule, formOptions))
  }, [schedule, reset, formOptions])

  const scheduleUrl = url('v2/schedules', scheduleId, 'discard')

  const discardShift = () => {
    axios
      .put(scheduleUrl)
      .then(() => {
        setFormOpen(false)
        removeSchedule(schedule)
      })
      .catch(() => {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      })
  }

  const confirmShiftDiscard = () => {
    showConfirmation({
      isOpen: true,
      closeOnConfirm: true,
      onConfirm: discardShift,
      text: t('.discard_confirmation', { shift_name: schedule.name }),
      confirmButton: { title: t('destroy'), type: 'danger' }
    })
  }

  const onSubmit = useCallback(
    async values => {
      const httpMethod = schedule.id ? 'put' : 'post'
      const scheduleUrl = url('v2/schedules', schedule.id)

      return axios({
        method: httpMethod,
        url: scheduleUrl,
        data: {
          schedule: formatValuesForSubmit(values, valueFormats)
        }
      })
        .then(response => {
          setFormOpen(false)
          schedule.id ? refreshSchedule(response.data) : addSchedule(response.data)
        })
        .catch(error => {
          handleHookFormError({ error, setError })
        })
    },
    [addSchedule, refreshSchedule, schedule.id, setFormOpen, setError]
  )

  const closeForm = () => setFormOpen(false)

  const modalClasses = classNames(classes, 'modal-dialog-centered modal--new')

  const handleClose = useUnsavedChangesConfirmation({ dirty: isDirty, onClose: closeForm })

  const startTime = watch('start_time')
  const endTime = watch('end_time')
  const construction_object_id = watch('construction_object_id')

  const constructionObjectId = useMemo(() => construction_object_id?.value, [construction_object_id])

  return ReactDOM.createPortal(
    <Modal isOpen className={modalClasses} onClose={handleClose} header={<ModalFormHeader title={title} />}>
      <FormProvider {...formContext}>
        <form onSubmit={handleSubmit(onSubmit)} className='react-form shift-form'>
          <div className='modal-form-container'>
            <div className='shift-info'>
              <ColorPickerField name='color' colors={colors} control={control} />
              <TextField
                name='name'
                register={register}
                placeholder={t('.name')}
                getFieldState={getFieldState}
                required
              />
            </div>

            <TimeFields control={control} setValue={setValue} initialValues={formData} />

            {schedule.id && (
              <SwitchField
                control={control}
                getFieldState={getFieldState}
                register={register}
                name='force_update'
                label={t('v2.shifts.index.form.fields.force_update')}
                fieldIcon='iconEmployee'
              />
            )}

            <RegularityOptions
              options={formOptions.regularity_options}
              control={control}
              getFieldState={getFieldState}
              watch={watch}
              register={register}
              name='force_update'
              label={t('.force_update')}
              fieldIcon='iconEmployee'
            />

            <hr className='delimiter' />

            <SelectField
              control={control}
              name='construction_object_id'
              label={t('.object')}
              options={formOptions.object_options}
              fieldIcon='iconLocation'
              required
            />

            <hr className='delimiter' />

            <MultiselectField
              control={control}
              name='employee_ids'
              label={t('.employees')}
              optionsURL={url('v2/schedules/employees_autocomplete', { construction_object_id: constructionObjectId })}
              withImages
              fieldIcon='iconEmployee'
            />
            <SwitchField
              control={control}
              register={register}
              getFieldState={getFieldState}
              name='sync_new_employees'
              label={t('.sync_new_employees')}
              fieldIcon='iconLocation'
            />

            <MultiselectField
              control={control}
              name='company_ids'
              label={t('.departments')}
              optionsURL={url('v2/schedules/companies_autocomplete', { construction_object_id: constructionObjectId })}
              fieldIcon='iconCompany'
            />

            <hr className='delimiter' />

            <MultiselectField
              control={control}
              name='responsible_user_ids'
              label={t('.responsibles')}
              withImages
              options={formOptions.responsible_options}
              fieldIcon='iconResponsible'
            />

            <hr className='delimiter' />

            <div className='d-flex'>
              <div className='shift-tolerances'>
                <label className='form-label'>
                  {t('.start_time_tolerances')}{' '}
                  <IconWithTooltip
                    icon='info'
                    tooltipText={t('v2.shifts.index.form.fields.time_tolerances_info')}
                    tooltipId='start-tolerances-tooltip'
                  />
                </label>
                <div className='form-row'>
                  <TimeField
                    name='start_time_tolerance_before'
                    control={control}
                    disabled={!startTime}
                    bonusFormatter={roundMinsToFive}
                  />
                  <TimeField
                    name='start_time_tolerance_after'
                    control={control}
                    disabled={!startTime}
                    bonusFormatter={roundMinsToFive}
                  />
                </div>
              </div>
              <div className='shift-tolerances'>
                <label className='form-label'>
                  {t('.end_time_tolerances')}{' '}
                  <IconWithTooltip
                    icon='info'
                    tooltipText={t('.time_tolerances_info')}
                    tooltipId='end-tolerances-tooltip'
                  />
                </label>
                <div className='form-row'>
                  <TimeField
                    name='end_time_tolerance_before'
                    control={control}
                    disabled={!endTime}
                    bonusFormatter={roundMinsToFive}
                  />
                  <TimeField
                    name='end_time_tolerance_after'
                    control={control}
                    disabled={!endTime}
                    bonusFormatter={roundMinsToFive}
                  />
                </div>
              </div>
            </div>
          </div>
          <ModalFormButtons
            isSubmitting={isSubmitting || isLoading}
            closeModal={closeForm}
            onDestroyClick={schedule.id ? confirmShiftDiscard : null}
          />
        </form>
      </FormProvider>
    </Modal>,
    document.querySelector('.modal-container')
  )
}

export default ScheduleForm
