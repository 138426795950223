import React from 'react'

import axios from 'axios'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { FormikProvider, useFormik, Form, setIn, getIn } from 'formik'
import { t } from 'utils/i18n'

import { getApiErrorMessage, showErrorModal } from '../../utils/confirmations'
import { getDuration } from '../../utils/duration'
import { url } from '../../utils/urlGenerator'
import Icon from '../Icon'
import ToolTip from '../Tooltip'
import { TextField, DateTimeField, SelectField } from './Fields'
import BaseError from './Fields/BaseError'
import { arrayFormatter, stringFormatter, dateTimeFormatter, selectFormatter } from './Formatters'
import { formatValuesForSubmit } from './Formatters/submit'

const tNfcCheckLog = key => t(['activerecord.attributes.nfc_check_log', key])

const childValueFormats = {
  id: null,
  started_at: dateTimeFormatter,
  finished_at: dateTimeFormatter
}

const valueFormats = {
  checked_in_at: dateTimeFormatter,
  checked_out_at: dateTimeFormatter,
  comment: stringFormatter,
  construction_object_id: null,
  checkable_id: selectFormatter,
  task_id: selectFormatter,
  sub_task_id: selectFormatter,
  children_attributes: {
    function: arrayFormatter,
    valueFormatter: {
      function: formatValuesForSubmit,
      valueFormatter: {
        ...childValueFormats,
        children_attributes: {
          function: arrayFormatter,
          valueFormatter: {
            function: formatValuesForSubmit,
            valueFormatter: childValueFormats
          }
        }
      }
    }
  }
}

const valuesForSubmit = (values, data) => {
  const { checkable_id: _checkableId, ...otherFormats } = valueFormats

  const formatsToUse = data.id ? otherFormats : valueFormats

  return formatValuesForSubmit(values, formatsToUse)
}

const errorMap = {
  checkable: 'checkable_id'
}
const errorString = errors => errors.map(({ message }) => message).join('; ')

const directErrors = errors => Object.entries(errors).filter(([key]) => !key.includes('.'))

export const getFormikErrors = errors =>
  Object.keys(errors).reduce((accumulator, currentValue) => setIn(accumulator, currentValue, errors[currentValue]), {})

const errorsFromData = ({ children = [], errors }) => {
  let returnErrors = {}

  directErrors(errors).forEach(([key, val]) => {
    returnErrors[errorMap[key] || key] = errorString(val)
  })

  children?.forEach(({ errors = {}, ...others }, idx) => {
    directErrors(errors).forEach(([key, val]) => {
      const errorBase = `children_attributes.${idx}`
      returnErrors[`${errorBase}.${key}`] = errorString(val)
    })

    others?.children?.forEach(({ errors = {} }, sidx) => {
      directErrors(errors).forEach(([key, val]) => {
        const errorBase = `children_attributes.${idx}.children_attributes.${sidx}`
        returnErrors[`${errorBase}.${key}`] = errorString(val)
      })
    })
  })

  return getFormikErrors(returnErrors)
}

const formikDate = value => value && dayjs(value).toDate()

const formikChildren = children =>
  children &&
  children.map(({ started_at, finished_at, children, ...others }) => ({
    ...others,
    started_at: formikDate(started_at),
    finished_at: formikDate(finished_at),
    children_attributes: formikChildren(children)
  }))

const dataToFormik = data => {
  const { checked_in_at, checked_out_at, construction_object_id, time_zone, children } = data

  return {
    construction_object_id,
    checked_in_at: formikDate(checked_in_at),
    checked_out_at: formikDate(checked_out_at),
    comment: '',
    checkable_id: '',
    task_id: '',
    sub_task_id: '',
    time_zone,
    children_attributes: formikChildren(children)
  }
}

export const useNfcCheckLogFormik = ({ data, afterSuccessfulSubmit }) => {
  const httpMethod = data.id ? 'put' : 'post'
  const subjectUrl = url([`v2/nfc_check_logs`, data.id].join('/'))

  const sendRequest = async (values, { setErrors, setSubmitting }) => {
    try {
      const response = await axios({
        method: httpMethod,
        url: subjectUrl,
        data: {
          nfc_check_log: valuesForSubmit(values, data)
        }
      })

      setSubmitting(false)
      afterSuccessfulSubmit && afterSuccessfulSubmit(response)
    } catch (error) {
      setSubmitting(false)
      if (error.response?.status === 422) {
        const responseData = error.response.data
        setErrors(errorsFromData(responseData))
      } else {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      }
    }
  }

  const formik = useFormik({
    initialValues: dataToFormik(data),
    enableReinitialize: true,
    onSubmit: sendRequest
  })

  return { formik }
}

const ChildRow = ({ child, isSubchild, namePrefix, errors }) => {
  const rowClasses = classNames('row align-items-center', {
    'mt-1': isSubchild,
    'mt-3': !isSubchild
  })

  const baseKey = `${namePrefix}.base`
  const baseError = getIn(errors, baseKey)
  const nameClasses = classNames('text-truncate', { 'font-weight-bold': !isSubchild, 'text-danger': !!baseError })

  return (
    <>
      <div className={rowClasses} data-id={child.id}>
        <div className='col-sm-4'>
          <div className={nameClasses} data-tip data-for={baseKey}>
            {child.name} {baseError && <Icon icon='info' />}
            {baseError && <ToolTip id={baseKey}>{baseError}</ToolTip>}
          </div>
        </div>
        <div className='col-sm-8'>
          <div className='d-flex align-items-center ml-1' data-contains='time-entry-inputs-container'>
            <div className='form-group mr-3'>
              <DateTimeField name={`${namePrefix}.started_at`} errorInTooltip withIcon />
            </div>
            <span>—</span>
            <div className='form-group ml-3 mr-3'>
              <DateTimeField name={`${namePrefix}.finished_at`} errorInTooltip withIcon />
            </div>
            <strong data-contains='time_entry_duration'>{getDuration(child.started_at, child.finished_at)}</strong>
          </div>
        </div>
      </div>
      {child.children?.map((subchild, idx) => (
        <ChildRow
          key={idx}
          child={subchild}
          namePrefix={`${namePrefix}.children_attributes.${idx}`}
          isSubchild
          errors={errors}
        />
      ))}
    </>
  )
}

const Children = ({ childs, errors }) => {
  if (!childs) return null

  return (
    <>
      <h4 className='mt-5'>{t('v2.nfc_check_logs.time_entry_form_fields.task_time_entries')}</h4>
      <hr className='mt-0 mb-4' />
      <div className='construction-object-time-entries'>
        {childs.map((child, idx) => (
          <ChildRow key={idx} child={child} namePrefix={`children_attributes.${idx}`} errors={errors} />
        ))}
      </div>
    </>
  )
}

const NfcCheckLogForm = ({ formik, data, showHistoricalTimeEntries = true }) => {
  const { values, errors } = formik
  const { checked_in_at, checked_out_at, time_zone } = values

  return (
    <FormikProvider value={formik}>
      <Form className='react-form edit_nfc_check_log'>
        <BaseError name='base' />
        {!data.id && (
          <SelectField
            name='checkable_id'
            label={tNfcCheckLog('checkable')}
            options={data.employee_options || []}
            withImages
            fieldIcon='iconEmployee'
            className='nfc_check_log_checkable'
            required
          />
        )}
        {data.construction_object_task_options && (
          <>
            <SelectField
              name='task_id'
              label={tNfcCheckLog('task_id')}
              options={data.construction_object_task_options}
            />
            <SelectField
              name='sub_task_id'
              label={tNfcCheckLog('sub_task_id')}
              optionsURL={url('v2/construction_object_sub_tasks/autocomplete', { task_id: values.task_id?.value })}
              disabled={!values.task_id}
            />
          </>
        )}
        <div className='row' data-contains='time-entry-inputs-container'>
          <div className='col'>
            <DateTimeField name='checked_in_at' label={tNfcCheckLog('checked_in_at')} required withIcon />
          </div>
          <div className='col'>
            <DateTimeField name='checked_out_at' label={tNfcCheckLog('checked_out_at')} withIcon />
          </div>
        </div>
        <div className='form-field'>
          <label className='form-label'>{tNfcCheckLog('checked_in_duration')}</label>
          <input
            disabled
            className='form-control'
            id='checked_in_duration'
            value={getDuration(checked_in_at, checked_out_at)}
          />
        </div>
        {time_zone && (
          <TextField
            name='time_zone'
            disabled
            label={t(['activerecord.attributes.construction_object', 'time_zone_name'])}
          />
        )}
        <TextField name='comment' label={tNfcCheckLog('comment')} textArea required className='nfc_check_log_comment' />
        <Children childs={data.children} errors={errors} />

        {data.historical_time_entries_html && showHistoricalTimeEntries && (
          <div dangerouslySetInnerHTML={{ __html: data.historical_time_entries_html }} />
        )}
      </Form>
    </FormikProvider>
  )
}

export default NfcCheckLogForm
