import React from 'react'

import { t } from '../../../../utils/i18n'
import WeekDay from './WeekDay'

const WeekFields = ({ weekDaysNames, days, disabled, withLabel = true }) => {
  const weekDays = weekDaysNames.map((dayName, i) => (
    <WeekDay dayName={dayName} key={i} id={i} selected={days && days[`day_${i + 1}`] === 'true'} disabled={disabled} />
  ))

  return (
    <div className='task-regularity-week-container'>
      <div className='form-group'>
        {withLabel && <label className='col-sm-4 col-form-label'>{t('v2.tasker.tasks.week_days_text')}</label>}
        <div className='fields-right-container' style={{ paddingLeft: 0 }}>
          {weekDays}
        </div>
      </div>
    </div>
  )
}

export default WeekFields
