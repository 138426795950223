import React, { useState } from 'react'

const ToggleButton = ({ as = 'button', children, buttonText, ...props }) => {
  const As = as

  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => setIsOpen(open => !open)

  return (
    <>
      <As onClick={toggleOpen} {...props}>
        {buttonText}
      </As>
      {isOpen && children({ toggleOpen })}
    </>
  )
}

export default ToggleButton
