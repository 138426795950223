import React from 'react'

import cx from 'classnames'

const FormToggler = ({ open, onClick, children, disabled, ...props }) => (
  <button
    type='button'
    className={cx('scheduler-action edit d-flex align-items-center', { open: open })}
    onClick={onClick}
    disabled={disabled}
    {...props}
  >
    <i className='icon fa fa-pencil mr-2' />
    <span>{children}</span>
    <i className={`arrow fa fa-caret-${open ? 'up' : 'down'} ml-2`} />
  </button>
)

export default FormToggler
