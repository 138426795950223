import React from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import classNames from 'classnames'
import { t } from 'i18n-js'
import { useFormContext, useWatch } from 'react-hook-form'

import useTranslate from '../../../../hooks/useTranslate'
import { url } from '../../../../utils/urlGenerator'
import styles from '../Buttons.module.scss'

const ButtonsContainer = ({ justify = 'start', children }) => {
  return <div className={`form-buttons d-flex justify-content-${justify} mt-4`}>{children}</div>
}

const PaymentButtons = ({ closeForm }) => {
  const tr = useTranslate('ConstructionObjects.Vedludb.PaymentForm.buttons')
  const {
    payment,
    isDirty,
    isEditForm,
    isPaymentOwner,
    isPaymentReceiver,
    isStandalone,
    editMode,
    ligumsId,
    tableReload,
    onClose
  } = useFormContext()

  const explanation = useWatch({ name: 'pamatojums' })

  const { mutate } = useMutation({
    mutationFn: statuss => {
      return axios.post(url('/v2/vedludb/payments/change_status'), {
        id: ligumsId,
        payment: {
          id: payment.norekinuMaksajumsId,
          statuss,
          pamatojums: explanation
        }
      })
    },
    onSuccess: () => {
      tableReload()
      onClose()
    },
    onError: () => {
      onClose()
    }
  })

  const displaySaveControls = !payment || (isPaymentOwner && !isStandalone) || (isStandalone && editMode)
  const displayApprovalControls =
    payment?.statuss === 0 && ((isPaymentReceiver && !isStandalone) || (isStandalone && !editMode))
  const saveDisabled = !isDirty || (isEditForm && !explanation)

  return (
    <>
      <ButtonsContainer justify={!displayApprovalControls && !displaySaveControls ? 'end' : 'between'}>
        {displayApprovalControls && (
          <div className='d-flex align-items-center gap-2'>
            <button
              className={classNames(styles.vedludbButton, styles.success)}
              onClick={() => mutate(1)}
              type='button'
            >
              {tr('.approve')}
            </button>
            <button
              className={classNames(styles.vedludbButton, styles.danger)}
              onClick={() => mutate(2)}
              disabled={!explanation}
              type='button'
            >
              {tr('.deny')}
            </button>
          </div>
        )}
        {displaySaveControls && (
          <button className={classNames(styles.vedludbButton, styles.primary)} disabled={saveDisabled} type='submit'>
            {payment ? tr('.send_edited') : tr('.send_payment')}
          </button>
        )}
        <button
          className={classNames(styles.vedludbButton, styles.secondary)}
          onClick={() => closeForm()}
          type='button'
        >
          {t('cancel')}
        </button>
      </ButtonsContainer>
    </>
  )
}

export default PaymentButtons
