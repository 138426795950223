import Axios from 'axios'
import dayjs from 'dayjs'
import pick from 'lodash/pick'
import reject from 'lodash/reject'
import { makeObservable, action } from 'mobx'

import { t } from '../utils/i18n'
import { url } from '../utils/urlGenerator'

export const StoreData = storeState =>
  class extends storeState {
    constructor(args) {
      super(args)
      makeObservable(this, {
        reload: action
      })
    }

    currentPage = 0

    reload = async () => {
      this.setState({ loading: true })

      // assume that filters (or something else) have changed if page has not changed
      if (this.currentPage === this.state.page) {
        const perservedPage = sessionStorage.tableStorePage
        sessionStorage.removeItem('tableStorePage')
        this.setState({ page: Number(perservedPage) || 0 })
      } else {
        this.currentPage = this.state.page
      }

      try {
        const { data } = await Axios.get(this.state.url, {
          params: { ...this.buildParams(false), ...this.additionalParams }
        })

        this.setState({
          ...this.state,
          data: data.rows,
          pages: data.pages,
          dataSize: data.count,
          loading: false
        })

        this.onDataLoad && this.onDataLoad()

        this.saveSettings()
        this.clearCheckboxes()
        this.loadCheckboxes()
      } catch (error) {
        this.setState({ loading: false })
      }
    }

    dateFiltersAsHuman() {
      const {
        starting_time_start_date,
        starting_time_end_date,
        deadline_start_date,
        deadline_end_date,
        created_start_date,
        created_end_date
      } = this.state.filters

      const intervals = {}
      const format = t('date.formats.default_human_js')

      const groups = {
        starting_time_interval: [starting_time_start_date, starting_time_end_date],
        deadline_interval: [deadline_start_date, deadline_end_date],
        created_at_interval: [created_start_date, created_end_date]
      }

      for (let key in groups) {
        const [start, end] = groups[key]
        if (start || end) {
          intervals[key] = groups[key].map(d => (d ? dayjs(d).format(format) : '...'))
        }
      }

      return intervals
      // appending [undefined, undefined] to human_filters is valid. Since undefined values are parsed out
    }

    getHumanFilterValues(filterValue, humanField) {
      const { filtersHuman } = this.state

      return reject(filtersHuman[humanField], f => {
        if (Array.isArray(filterValue)) {
          return !filterValue.includes(f.value)
        } else {
          return filterValue !== f.value
        }
      }).map(f => f.label)
    }

    buildFilter(include_human_filters = false) {
      const stateFilters = this.state.filters

      let filter = {}
      let human_filters = {}

      Object.keys(stateFilters).forEach(field => {
        let filterValue = undefined
        if (stateFilters[field] === undefined && this.state.defaultFilter) {
          filter[field] = filterValue = this.state.defaultFilter[field]
        } else if (stateFilters[field] !== undefined && stateFilters[field] !== null) {
          filter[field] = filterValue = stateFilters[field]
        }

        if (filterValue && include_human_filters) {
          if (['startDate', 'endDate'].includes(field)) return // Not used in human_filters, proceed to next field

          const adjustedField = field.replace('_id', '')
          human_filters[adjustedField] = this.getHumanFilterValues(filterValue, adjustedField)
        }
      })

      human_filters = include_human_filters ? { ...human_filters, ...this.dateFiltersAsHuman() } : undefined

      return { filter, human_filters }
    }

    buildParams(include_human_filters = false) {
      const {
        pageSize,
        sorted,
        page,
        filters: { startDate, endDate, keyword },
        baseScope,
        timeZone
      } = this.state

      let sort_by = ''
      let sort_order = 'asc'
      if (sorted.length > 0) {
        sort_by = sorted[0].id
        sort_order = sorted[0].desc === false ? 'asc' : 'desc'
      }

      return {
        page: page + 1,
        paginate_per: pageSize,
        sort_by,
        sort_order,
        base_scope: baseScope,
        time_zone: timeZone,
        start_date: startDate || dayjs().startOf('month').format(t('date.formats.default_raw_sql_human')),
        end_date: endDate || dayjs().endOf('month').format(t('date.formats.default_raw_sql_human')),
        q: keyword,
        ...this.buildFilter(include_human_filters)
      }
    }

    exportData(exportUrl, params = {}) {
      const chosenParams = pick(
        this.buildParams(true),
        'filter',
        'human_filters',
        'q',
        'sort_by',
        'sort_order',
        'start_date',
        'end_date',
        'base_scope',
        'time_zone'
      )

      window.location = url(exportUrl, { ...chosenParams, ...params })
    }
  }
