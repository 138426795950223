import React, { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { FormProvider, useForm, useFieldArray } from 'react-hook-form'
import yup from 'utils/yup'

import useTranslate from '../../../hooks/useTranslate'
import { url } from '../../../utils/urlGenerator'
import { dateFormatter } from '../../Forms/Formatters'
import { formatValuesForSubmit } from '../../Forms/Formatters/submit'
import Icon from '../../Icon'
import BisConstructionCasesModal from './BisConstructionCasesModal'
import ConstructionCasesTable from './ConstructionCasesTable'
import ManualConstructionCaseFormModal from './ManualConstructionCaseFormModal'
import RegistrationForm from './RegistrationForm'
import { commonRegistrationFieldSchemas } from './common'

const validationSchema = yup.object({
  ...commonRegistrationFieldSchemas,
  buvniecibasUzsaksanasDatums: yup.date().nullable().required(),
  buvniecibasLietas: yup.array().of(yup.object()).min(1)
})

const initialData = {
  buvniecibasUzsaksanasDatums: null,
  ligumaDatums: null,
  ligumaSumma: '',
  citsPasutitajs: '',
  pasutitajaNosaukums: '',
  pasutitajaRegNr: '',
  buvniecibasLietas: []
}

const valueFormats = {
  buvniecibasUzsaksanasDatums: dateFormatter,
  ligumaDatums: dateFormatter
}

const NewRegistrationForm = ({ constructionObjectId }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.NewRegistrationForm')
  const [openMode, setOpenMode] = useState(null)
  const [editCaseData, setEditCaseData] = useState(null)

  const formContext = useForm({
    values: initialData,
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema)
  })

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors: errors }
  } = formContext

  const { append, remove, update } = useFieldArray({
    control,
    name: 'buvniecibasLietas'
  })

  const buvniecibasLietas = watch('buvniecibasLietas')

  const { mutate, isLoading: isSubmitting } = useMutation({
    mutationFn: formData => {
      const { citsPasutitajs: _a, ...otherValues } = formData
      return axios.post(url('v2/vedludb/registrations'), {
        registration: {
          ...otherValues,
          pasutitajaNosaukums: formData.citsPasutitajs ? formData.pasutitajaNosaukums : '',
          pasutitajaRegNr: formData.citsPasutitajs ? formData.pasutitajaRegNr : '',
          ...formatValuesForSubmit(otherValues, valueFormats)
        },
        construction_object_id: constructionObjectId
      })
    },
    onSuccess: () => {
      window.location.reload()
    }
  })

  const bis = !!buvniecibasLietas.find(item => item.buvatlaujasNr)

  const itemActions = (item, idx) => (
    <div className='d-flex gap-3'>
      <button className='btn btn-clear' type='button' onClick={() => remove(idx)}>
        <Icon className='action text-danger' icon='iconTrash' />
      </button>
      {!bis && (
        <button className='btn btn-clear' type='button' onClick={() => setEditCaseData({ idx, item })}>
          <Icon className='action' icon='iconPencil' />
        </button>
      )}
    </div>
  )

  return (
    <FormProvider {...formContext}>
      <div className='d-flex gap-2 mb-4 align-items-center'>
        {(!buvniecibasLietas.length || bis) && (
          <button type='button' className='btn btn-secondary' onClick={() => setOpenMode('bis')}>
            {t('.choose_construction_case_from_list')}
          </button>
        )}
        {(!buvniecibasLietas.length || !bis) && (
          <button type='button' className='btn btn-secondary' onClick={() => setOpenMode('manual')}>
            {t('.enter_construction_case_data_manually')}
          </button>
        )}
        {errors.buvniecibasLietas?.message && (
          <div className='form-field-error text-danger'>{errors.buvniecibasLietas.message}</div>
        )}
      </div>
      {buvniecibasLietas.length > 0 && <ConstructionCasesTable cases={buvniecibasLietas} itemActions={itemActions} />}
      <RegistrationForm onSubmit={handleSubmit(mutate)} isSubmitting={isSubmitting} formContext={formContext} />

      {openMode === 'bis' && (
        <BisConstructionCasesModal
          constructionObjectId={constructionObjectId}
          onCaseClick={data => {
            append(data)
            setOpenMode(null)
          }}
          onClose={() => setOpenMode(null)}
        />
      )}
      {openMode === 'manual' && (
        <ManualConstructionCaseFormModal
          title={t('.construction_case_data')}
          onSubmit={data => {
            append(data)
            setOpenMode(null)
          }}
          onCancel={() => setOpenMode(null)}
          onClose={() => setOpenMode(null)}
        />
      )}
      {editCaseData && (
        <ManualConstructionCaseFormModal
          data={editCaseData.item}
          title={t('.construction_case_data')}
          onSubmit={data => {
            update(editCaseData.idx, data)
            setEditCaseData(null)
          }}
          onCancel={() => setEditCaseData(null)}
          onClose={() => setEditCaseData(null)}
        />
      )}
    </FormProvider>
  )
}

export default NewRegistrationForm
