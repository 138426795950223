import React, { useMemo } from 'react'

import classNames from 'classnames'

import { useSelectedShiftItems } from './SelectedShiftItemsContext'
import { unitSelectIndex } from './shiftDayHelper'

const isUnitSelected = (units, shiftId, dateIso, employeeId) =>
  unitSelectIndex(units, { shift_id: shiftId, date: dateIso, employee_id: employeeId }) > -1

const SelectIndicator = ({ shiftId, employeeId, dayParams, yesterdayParams, tomorrowParams, halfSizeBlock }) => {
  const { selectedShiftItems } = useSelectedShiftItems()

  const showBlock = dayParams.dayBlockParams.showBlock
  const showTomorrow = tomorrowParams?.dayBlockParams?.showBlock
  const showYesterday = yesterdayParams?.dayBlockParams?.showBlock

  const singleDayShift = dayParams.shiftUnits.singleDayShift
  const singleDayTomorrow = tomorrowParams?.shiftUnits?.singleDayShift
  const singleDayYesterday = yesterdayParams?.shiftUnits?.singleDayShift

  const dateIso = dayParams.day.dateIso
  const tomorrowDateIso = tomorrowParams?.day?.dateIso
  const yesterdayDateIso = yesterdayParams?.day?.dateIso

  const tomorrowShifted = !singleDayTomorrow && showTomorrow
  const yesterdayShifted = !singleDayYesterday && showYesterday
  const todayShifted = !singleDayShift && showBlock

  const selected = useMemo(
    () => isUnitSelected(selectedShiftItems, shiftId, dateIso, employeeId),
    [selectedShiftItems, shiftId, dateIso, employeeId]
  )

  const tomorrowSelected = useMemo(
    () => isUnitSelected(selectedShiftItems, shiftId, tomorrowDateIso, employeeId),
    [selectedShiftItems, shiftId, tomorrowDateIso, employeeId]
  )

  const yesterdaySelected = useMemo(
    () => isUnitSelected(selectedShiftItems, shiftId, yesterdayDateIso, employeeId),
    [selectedShiftItems, shiftId, yesterdayDateIso, employeeId]
  )

  if (!selected) {
    return null
  }

  const roundRight = !tomorrowSelected || (!todayShifted && tomorrowShifted)
  const roundLeft = !yesterdaySelected || (!yesterdayShifted && todayShifted)

  const selectIndicatorClasses = {
    'rounded-right': roundRight,
    'border-right-0': !roundRight,
    'rounded-left': roundLeft,
    'border-left-0': !roundLeft,
    'two-day': todayShifted,
    'half-block': halfSizeBlock || yesterdayShifted
  }

  return <div className={classNames('select-indicator', selectIndicatorClasses)} />
}

export default SelectIndicator
