import SimpleMDE from 'simplemde'

export default container => {
  const markdownInputs = container.querySelectorAll('[data-markdown-input]')

  markdownInputs.forEach(input => initSimpleMDE(input))
}

const initSimpleMDE = input => {
  const simplemde = new SimpleMDE({
    element: input,
    autofocus: true,
    spellChecker: false,
    hideIcons: ['fullscreen', 'side-by-side'],
    showIcons: ['horizontal-rule']
  })

  if (input.dataset.preview) {
    simplemde.togglePreview()
  }

  return simplemde
}
