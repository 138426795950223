import Turbolinks from 'turbolinks'

import { url } from '../../../utils/urlGenerator'

export default getFilter => {
  return ({ row, column }) => ({
    onClick: () => {
      if (['id', 'row_actions'].includes(column.accessorKey)) return

      Turbolinks.visit(url('v2/tasker/tasks', { filters: getFilter(row) }))
    }
  })
}
