import React, { useEffect } from 'react'

import { useFormContext, useWatch } from 'react-hook-form'

import useTranslate from '../../../../hooks/useTranslate'
import { NumberField, SwitchField } from '../../../Forms/HookFields'
import PaymentPerMonthField from './PaymentPerMonthField'

const ContractPaymentData = () => {
  const t = useTranslate('ConstructionObjects.Vedludb.ContractForm')
  const { setValue, formDisabled, requiredFields } = useFormContext()
  const sumUndefined = useWatch({ name: 'ligumaSummaNavNoteikta' })

  useEffect(() => {
    if (sumUndefined) {
      setValue('ligumaSumma', null)
    }
  }, [setValue, sumUndefined])

  return (
    <>
      <NumberField
        name='ligumaSumma'
        placeholder={t('.fields.placeholders.contractSum')}
        label={t('.fields.labels.contractSum')}
        disabled={sumUndefined || formDisabled}
        required={requiredFields.ligumaSumma}
      />
      <SwitchField
        name='ligumaSummaNavNoteikta'
        label={t('.fields.labels.contractSumUndefined')}
        disabled={formDisabled}
      />
      {sumUndefined && <PaymentPerMonthField disabled={formDisabled} required={requiredFields.ligumaSummasMenesi} />}
    </>
  )
}

export default ContractPaymentData
