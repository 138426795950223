import React, { useCallback } from 'react'

import { useFormikContext } from 'formik'

import { t } from '../../../utils/i18n'
import { calculateTimeDiff } from '../utils/handleTotalTime'
import MultiTimePeriodField from './MultiTimePeriodField'
import Regularity from './Regularity'
import TimeField from './TimeField'

const resetTolerances = (setFieldValue, name) =>
  setFieldValue(`${name}_tolerance_before`, '') && setFieldValue(`${name}_tolerance_after`, '')

const Field = React.memo(({ name, disabled, options, onChange }) => {
  const { setFieldValue } = useFormikContext()

  const onTimeInputChange = useCallback(
    value => {
      !value && resetTolerances(setFieldValue, name)
    },
    [name, setFieldValue]
  )

  return (
    <TimeField
      name={name}
      className='align-items-start'
      id={`shift_${name}`}
      disabled={disabled}
      label={t(`v2.shifts.index.form.fields.${name}`)}
      onChange={({ value }) => (onChange ? onChange(value) : onTimeInputChange(value))}
      options={options}
    />
  )
})

export default ({ disabled, regularityOptions }) => {
  const { values } = useFormikContext()

  const totalTime = values.start_time && values.end_time ? calculateTimeDiff(values.start_time, values.end_time) : ''

  return (
    <div className='form-row align-items-start'>
      <div className='time-fields'>
        <div className='d-flex align-items-start'>
          <Field name='start_time' />
          <Field name='end_time' />
          <div className='form-field align-items-start'>
            <label className='form-label'>{t('v2.shifts.index.form.fields.total_time')}</label>
            <div className='form-input-wrapper'>
              <div id='shift_total_time' className='form-control-plaintext ml-3 font-weight-bold'>
                {totalTime}
              </div>
            </div>
          </div>
        </div>

        {regularityOptions && <Regularity options={regularityOptions} disabled={disabled} />}
      </div>

      <MultiTimePeriodField
        disabled={disabled}
        name='break_time_periods'
        label={t('v2.shifts.index.form.fields.break_time_periods')}
        className='ml-3'
      />
    </div>
  )
}
