import FormWithRoles from './FormWithRoles'

const MANAGER = 'object_manager'
const REGULAR = 'regular'

class ConstructionObjectUserForm extends FormWithRoles {
  static selector = '.edit_construction_object_user, .new_construction_object_user'

  constructor(form) {
    const checkBoxes = [
      { name: 'canReadAttendants', visibleFor: REGULAR },
      { name: 'canCheckIn', visibleFor: REGULAR },
      { name: 'canManualCheckIn', visibleFor: REGULAR },
      { name: 'canCheckInGuest', visibleFor: REGULAR },
      { name: 'canManageEmployees', visibleFor: REGULAR },
      { name: 'canEditNfcCheckLogs', visibleFor: MANAGER }
    ]

    super({ form, checkBoxes, namespace: 'construction_object_users' })
  }

  bindElements() {
    this.selectors = {
      roleInput: this.form.querySelector('#construction_object_user_role'),
      canReadAttendantsInput: this.form.querySelector('#construction_object_user_can_read_attendants'),
      canReadAttendants: this.form.querySelector('.construction_object_user_can_read_attendants'),
      canCheckInInput: this.form.querySelector('#construction_object_user_can_check_in'),
      canCheckIn: this.form.querySelector('.construction_object_user_can_check_in'),
      canManualCheckInInput: this.form.querySelector('#construction_object_user_can_manual_check_in'),
      canManualCheckIn: this.form.querySelector('.construction_object_user_can_manual_check_in'),
      canCheckInGuestInput: this.form.querySelector('#construction_object_user_can_check_in_guest'),
      canCheckInGuest: this.form.querySelector('.construction_object_user_can_check_in_guest'),
      canManageEmployeesInput: this.form.querySelector('#construction_object_user_can_manage_employees'),
      canManageEmployees: this.form.querySelector('.construction_object_user_can_manage_employees'),
      canEditNfcCheckLogsInput: this.form.querySelector('#construction_object_user_can_edit_nfc_check_logs'),
      canEditNfcCheckLogs: this.form.querySelector('.construction_object_user_can_edit_nfc_check_logs')
    }
  }
}

export default ConstructionObjectUserForm
