import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Icon from '../Icon'
import IconCell from '../IconCell'
import Store from '../Store'
import TableContainer from '../TableContainer'
import CompaniesFilter from './CompaniesFilter'
import ReportCompaniesTable from './ReportCompaniesTable'
import StateCell from './StateCell'

class ReportCompaniesTableContainer extends TableContainer {
  pathPrefix = 'v2/companies'

  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'name', desc: false }]
  })

  columns = [
    {
      header: t('activerecord.attributes.company.name'),
      accessorKey: 'name',
      size: 320,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue()

        if (original.discarded) {
          return value
        } else {
          return <a href={url(this.pathPrefix, original.id)}>{value}</a>
        }
      }
    },
    {
      header: t('activerecord.attributes.company.registration_number'),
      accessorKey: 'registration_number',
      cell: ({
        getValue,
        row: {
          original: { id, is_foreign }
        }
      }) => (
        <IconCell
          id={id}
          showIcon={is_foreign}
          tooltipText={t('activerecord.attributes.company.is_foreign')}
          iconComponent={tooltipId => <Icon icon='globe' className='ml-2 center-icon' data-tip data-for={tooltipId} />}
        >
          {getValue()}
        </IconCell>
      )
    },
    {
      header: t('activerecord.attributes.company.address'),
      accessorKey: 'address',
      size: 320
    },
    this.props.show_state_column && {
      header: t('activerecord.attributes.report_version.state'),
      accessorKey: 'state',
      cell: ({ getValue, row: { original } }) => (
        <StateCell state={getValue()} edited={original.edited} id={original.id} type='company' />
      )
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <CompaniesFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <ReportCompaniesTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
          pathPrefix={this.pathPrefix}
        />
      </div>
    )
  }
}

export default ReportCompaniesTableContainer
