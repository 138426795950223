import React, { useCallback, useEffect, useRef, forwardRef } from 'react'

import axios from 'axios'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'

import { getApiErrorMessage, showConfirmation, showErrorModal } from '../../utils/confirmations'
import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import TimeField from '../Forms/Fields/TimeField'
import { timeFormatter } from '../Forms/Formatters'
import { formatValuesForSubmit } from '../Forms/Formatters/submit'
import Icon from '../Icon'
import MiniModal from './MiniModal'
import { useSelectedShiftItems } from './SelectedShiftItemsContext'
import { useMonthContext } from './contexts/MonthContext'
import { useSchedulesContext } from './contexts/SchedulesContext'

const TIME_REGEX = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

const valueFormats = {
  start_time: timeFormatter,
  end_time: timeFormatter
}

const validationSchema = Yup.object().shape({
  start_time: Yup.string()
    .required(t('v2.shifts.index.form.errors.required'))
    .matches(TIME_REGEX, t('v2.shifts.index.form.errors.incorrect_format')),
  end_time: Yup.string()
    .required(t('v2.shifts.index.form.errors.required'))
    .matches(TIME_REGEX, t('v2.shifts.index.form.errors.incorrect_format'))
})

const Field = forwardRef(({ name, ...others }, ref) => (
  <TimeField
    name={name}
    inputClass='not-draggable'
    id={`shift_${name}`}
    label={t(`v2.shifts.index.form.fields.${name}`)}
    errorInTooltip
    ref={ref}
    {...others}
  />
))

const ShiftDayMiniModal = ({ position, onExpand, showExpand, onClose, initialValues }) => {
  const { selectedShiftItems } = useSelectedShiftItems()
  const { month } = useMonthContext()
  const { manageMultipleSchedules } = useSchedulesContext()
  const startTimeInputRef = useRef()

  const sendRequest = useCallback(
    (params, status) => {
      axios({
        method: 'PUT',
        url: url('v2/schedules/shifts/bulk_upsert'),
        data: {
          month,
          shift_units_attributes: { ...params, status, units: selectedShiftItems }
        }
      })
        .then(({ data }) => {
          onClose()
          manageMultipleSchedules(data.rows)
        })
        .catch(() => {
          showErrorModal({
            title: getApiErrorMessage(null),
            cancelAction: Turbolinks.visit
          })
        })
    },
    [manageMultipleSchedules, month, onClose, selectedShiftItems]
  )

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => sendRequest(formatValuesForSubmit(values, valueFormats), 'updated')
  })

  const { values, handleSubmit, setValues, dirty } = formik

  useEffect(() => {
    setValues(initialValues)
  }, [setValues, initialValues])

  useEffect(() => {
    startTimeInputRef?.current.focus()
    startTimeInputRef?.current.select()
  }, [selectedShiftItems, startTimeInputRef])

  const deleteShiftDays = useCallback(() => {
    showConfirmation({
      isOpen: true,
      closeOnConfirm: true,
      onConfirm: () => sendRequest(formatValuesForSubmit(values, valueFormats), 'deleted'),
      text: t('v2.shift_units.form.confirmations.delete'),
      confirmButton: { title: t('destroy'), type: 'danger' }
    })
  }, [sendRequest, values])

  const buttons = showExpand && (
    <button className='btn btn-sm not-draggable expand mx-2' onClick={onExpand} title={t('expand')}>
      <Icon icon='expand' className='d-flex' />
    </button>
  )

  return (
    <MiniModal
      position={position}
      onClose={onClose}
      dirty={dirty}
      handleSubmit={handleSubmit}
      onDeleteClick={deleteShiftDays}
      buttons={buttons}
      multiple
    >
      <FormikProvider value={formik}>
        <form className='react-form shift-form'>
          <div className='form-row align-items-start'>
            <div className='time-fields d-flex'>
              <Field name='start_time' ref={startTimeInputRef} className='m-0 mr-2' />
              <Field name='end_time' className='m-0' />
            </div>
          </div>
        </form>
      </FormikProvider>
    </MiniModal>
  )
}

export default ShiftDayMiniModal
