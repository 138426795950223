class AccountForm {
  static selector = '.edit_account, .new_account'

  constructor(form) {
    this.form = form
    this.bindElements()
    this.bindEvents()
    this.toggleSchedulerCheckbox()
  }

  bindElements() {
    this.selectors = {
      hasEdlus: this.form.querySelector('#account_has_edlus'),
      hasSchedulerInput: this.form.querySelector('.account_has_scheduler')
    }
  }

  bindEvents() {
    this.form.addEventListener('change', this.toggleSchedulerCheckbox)
  }

  toggleSchedulerCheckbox = () => {
    const { hasEdlus, hasSchedulerInput } = this.selectors

    if (!hasEdlus && !hasSchedulerInput) return

    if (hasEdlus.checked) {
      hasSchedulerInput.classList.remove('d-none')
    } else {
      hasSchedulerInput.classList.add('d-none')
    }
  }
}

export default AccountForm
