import React from 'react'

import { DebounceInput } from 'react-debounce-input'

import { t } from '../utils/i18n'
import Icon from './Icon'

const Search = ({ value, onChange, className }) => (
  <div className={`search ${className}`}>
    <div className='search-icon'>
      <i className='fa fa-search' />
    </div>
    <DebounceInput
      type='text'
      placeholder={t('filters.search')}
      value={value}
      onChange={({ target: { value } }) => onChange(value)}
      debounceTimeout={300}
    />
    {value && <Icon icon='cross' className='clear-icon' onClick={() => onChange('')} />}
  </div>
)

export default Search
