import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../../utils/i18n'
import { FilterContainer, SearchField } from '../../FilterComponents'

class AdminUsersFilter extends React.Component {
  render() {
    const { store } = this.props

    return (
      <FilterContainer store={store}>
        <SearchField store={store} placeholder={t('filters.search_users')} />
      </FilterContainer>
    )
  }
}

export default observer(AdminUsersFilter)
