import React from 'react'

import { useMultipleSelection } from 'downshift'
import { useController } from 'react-hook-form'

import { addOrRemoveSelectedItem } from '../../Options'
import { Select } from '../Fields'
import HookFieldWrapper from './HookFieldWrapper'

const MultiselectField = ({
  control,
  options,
  name,
  required,
  label,
  fieldIcon,
  className = '',
  wrapperClassName = '',
  ...others
}) => {
  const { getSelectedItemProps, getDropdownProps } = useMultipleSelection()

  const {
    field: { value, onChange },
    fieldState
  } = useController({ name, control })

  const safeValue = value || []

  return (
    <HookFieldWrapper
      name={name}
      required={required}
      className={wrapperClassName}
      icon={fieldIcon}
      label={label}
      fieldState={fieldState}
    >
      <Select
        {...others}
        isMulti
        name={name}
        items={options}
        onSelect={selectedItem => addOrRemoveSelectedItem({ selectedItem, selectedItems: safeValue, onChange })}
        onSelectAll={onChange}
        getSelectedItemProps={getSelectedItemProps}
        getDropdownProps={getDropdownProps}
        value={safeValue}
        label={label}
        required={required}
        className={className}
      />
    </HookFieldWrapper>
  )
}

export default MultiselectField
