class MapCircle {
  constructor(map, circleCenter, circleRadius, drawingController) {
    this.map = map
    this.circle = undefined
    this.circleRadius = circleRadius
    this.circleCenter = circleCenter
    this.drawingController = drawingController
  }

  draw() {
    const figure = this.load()
    if (!figure) return

    return { figure, points: MapCircle.polygonFromCircle(figure) }
  }

  load() {
    if (typeof this.circleCenter.lat !== 'number') return undefined

    const center = new google.maps.LatLng(this.circleCenter.lat, this.circleCenter.lng)
    const circle = new google.maps.Circle({
      ...this.drawingController.style,
      map: this.map,
      radius: this.circleRadius,
      center
    })
    this.drawingController.setCircleEvents(circle)
    return circle
  }

  static polygonFromCircle = circle => {
    const center = circle.getCenter()
    const radius = circle.getRadius()
    const numberOfVertices = 36
    const angleIncrement = 360 / numberOfVertices
    const spherical = google.maps.geometry.spherical

    let polygon = []

    for (let i = 0; i < numberOfVertices; i++) {
      const angle = angleIncrement * i

      polygon.push(spherical.computeOffset(center, radius, angle))
    }

    return polygon
  }
}

export default MapCircle
