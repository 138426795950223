import React from 'react'

import { t } from '../../../utils/i18n'
import ActiveTable from '../../ActiveTable'
import { IconWithTooltip } from '../../NfcCheckLogs/CheckLogsData'
import { HistoricalTimeEntryIcon } from '../../elements/TableColumnsElements'
import { edit, stopTimeTracking } from '../../elements/actionDefinitions'

export const ClosingType = ({ closing_type, id }) => {
  if (!closing_type) return '-'

  const icon = closing_type === 'manual' ? 'checkWithManual' : 'checkWithAuto'
  const tooltipId = `time_entry_state_icon_${id}`
  const text = t(`activerecord.attributes.time_entry.closing_type_descriptions.${closing_type}`)

  return (
    <div className={`text-primary ${closing_type}`}>
      <IconWithTooltip icon={icon} tooltipId={tooltipId} tooltipText={text} />
    </div>
  )
}

export const TimeColumn = ({ entry, property }) => (
  <>
    {entry[`translated_${property}`]}
    <HistoricalTimeEntryIcon edited={entry[`${property}_changed`]} id={`${entry.id}-${property}`} />
  </>
)

class TaskTimeEntriesTable extends React.Component {
  store = this.props.store
  pathPrefix = 'v2/tasker/time_entries'

  formParams = {
    context: 'task',
    time_entry: { task_id: this.props.task_id }
  }

  actions = {
    row: [
      edit(this.pathPrefix, 'v2.tasker.time_entries.edit.title', this.formParams),
      stopTimeTracking(this.pathPrefix, this.formParams.task_id)
    ]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        pathPrefix={this.pathPrefix}
        actions={this.actions}
        translationPath={['tasker', 'time_entries']}
        highlightedBy='state'
        onRowClick='openEditForm'
      />
    )
  }
}

export default TaskTimeEntriesTable
