import React, { useEffect, useState, useCallback } from 'react'

import classNames from 'classnames'

import { generateId } from '../utils/generateId'
import Icon from './Icon'

const DestroyedSubItem = ({ id, inputName }) => (
  <div className='sub-item destroyed' key={id}>
    <input type='hidden' value={true} name={inputName(id, '_destroy')} />
    <input type='hidden' value={id} name={inputName(id, 'id')} />
  </div>
)

const SubItemRow = ({
  subItem,
  idx,
  inputClass,
  inputNameBase,
  canRemove,
  handleSubItemAdd,
  handleItemChange,
  subItemIdTarget,
  itemAttribute
}) => {
  const { completed, id, persisted, destroyed, [itemAttribute]: value } = subItem

  const inputName = (id, attribute) => `${inputNameBase}[${id}][${attribute}]`

  if (destroyed) {
    return persisted ? <DestroyedSubItem id={id} inputName={inputName} /> : null
  }

  const errors = subItem.errors[itemAttribute]

  return (
    <div className={classNames('sub-item', { completed })} key={id}>
      <input
        className={classNames(inputClass, { invalid: !!errors })}
        name={inputName(id, itemAttribute)}
        value={value}
        onChange={e => handleItemChange(id, { [itemAttribute]: e.target.value })}
        autoFocus={id === subItemIdTarget}
      />

      {!value && <input type='hidden' value={true} name={inputName(id, '_destroy')} />}
      {persisted && <input type='hidden' value={id} name={inputName(id, 'id')} />}
      <input type='hidden' value={idx} name={inputName(id, 'position')} />

      {completed && <Icon icon='checkMark' className='completed-marker' />}

      <Icon className='action' icon='iconPlusCircle' onClick={() => handleSubItemAdd(idx)} />
      {canRemove && (
        <Icon className='action' icon='iconTrash' onClick={() => handleItemChange(id, { destroyed: true })} />
      )}
      {errors && <div className='invalid-feedback'>{errors.join('; ')}</div>}
    </div>
  )
}

const SubItemsInput = ({ sub_items, input_classes, input_name_base, sub_item_id_target, item_attribute }) => {
  const [subItems, setSubItems] = useState([])

  const SubItem = useCallback(
    (obj = { [item_attribute]: '' }) => ({
      ...obj,
      id: obj.id || generateId(),
      persisted: !!obj.id,
      destroyed: false,
      errors: obj.errors || {}
    }),
    [item_attribute]
  )

  useEffect(() => {
    if (sub_items.length === 0) {
      setSubItems([SubItem()])
    } else {
      setSubItems(sub_items.map(SubItem))
    }
  }, [sub_items, SubItem])

  const handleSubItemAdd = idx => {
    const dirty = [...subItems]
    dirty.splice(idx + 1, 0, SubItem())
    setSubItems(dirty)
  }

  const handleItemChange = (id, changes) => {
    setSubItems(subItems.map(subItem => (id === subItem.id ? { ...subItem, ...changes } : subItem)))
  }

  const canRemove = subItems.filter(subItem => !subItem.destroyed).length > 1

  const inputClass = input_classes.join(' ')

  return (
    <div className='sub-items-input-container'>
      {subItems.map((subItem, idx) => (
        <SubItemRow
          key={idx}
          subItem={subItem}
          idx={idx}
          inputNameBase={input_name_base}
          canRemove={canRemove}
          inputClass={inputClass}
          handleItemChange={handleItemChange}
          handleSubItemAdd={handleSubItemAdd}
          subItemIdTarget={sub_item_id_target}
          itemAttribute={item_attribute}
        />
      ))}
    </div>
  )
}

export default SubItemsInput
