import React, { useContext, useEffect, useState } from 'react'

import axios from 'axios'

import useTranslate from '../../../hooks/useTranslate'
import { formatCurrency, formatDate } from '../../../utils/formatters'
import { url } from '../../../utils/urlGenerator'
import Modal from '../../Modal'
import Table from '../../Table'
import { VedludbContext } from './ConstructionObjectVedludb'

const ContractHistory = ({ isOpen, contract, onClose }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.ContractHistory')
  const vedludbCtx = useContext(VedludbContext)
  const [contractHistory, setContractHistory] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true)
      const fetchParams = { id: vedludbCtx.ligumsId, ligumsId: contract?.ligumsId }

      axios
        .get(url('v2/vedludb/contracts/history'), { params: { ...fetchParams } })
        .then(res => setContractHistory(res.data.history))
        .finally(() => setIsLoading(false))
    } else {
      setContractHistory([])
    }
  }, [isOpen, contract, vedludbCtx.ligumsId])

  const modalProps = {
    closeOnBackdropClick: false,
    title: t('.title'),
    className: 'contract-history-modal',
    onClose,
    isOpen
  }

  const columns = [
    { Header: t('.headers.version'), accessor: 'versija', className: 'text-right' },
    {
      Header: t('.headers.subcontractor'),
      Cell: ({ resource }) => `${resource.nosaukums} (${resource.registracijasNr})`
    },
    { Header: t('.headers.contract_number'), accessor: 'ligumaNumurs' },
    { Header: t('.headers.date'), accessor: 'datums' },
    {
      Header: t('.headers.sum'),
      className: 'text-right',
      Cell: ({ resource }) => (
        <div>
          <i className='fa fa-eur mx-1' aria-hidden='true'></i>
          {formatCurrency(resource.summa)}
        </div>
      )
    },
    { Header: t('.headers.bis_case_nr'), accessor: 'bisLietasNr' },
    { Header: t('.headers.who_changed'), accessor: 'izmainuVeicejs' },
    { Header: t('.headers.change_date'), Cell: ({ resource }) => formatDate(resource.izmainuDatums) },
    { Header: t('.headers.explanation'), accessor: 'pamatojums' }
  ]

  return (
    <Modal {...modalProps}>
      <Table columns={columns} data={contractHistory} isLoading={isLoading} className='contract-history-table' />
    </Modal>
  )
}

export default ContractHistory
