import React from 'react'

import classNames from 'classnames'
import { t } from 'i18n-js'
import { useFormContext } from 'react-hook-form'

import Switch from '../../../Forms/Fields/Switch'
import Icon from '../../../Icon'
import styles from './styles/Sidebar.module.scss'

export const ModuleToggle = ({ title, icon, moduleName = null, disabled = false }) => {
  const translatedTitle = t(`v2.tasker.tasks.form.modules.${title}`)
  const { toggleModule, modules, submitting } = useFormContext()
  const isDisabled = disabled || submitting

  const handleToggle = () => {
    if (isDisabled) return
    toggleModule(moduleName)
  }

  return (
    <div
      className={classNames(
        styles.moduleToggle,
        moduleName,
        'module-toggle',
        modules[moduleName] ? styles.enabled : 'untoggled'
      )}
    >
      {isDisabled && <div className={classNames(styles.disabledOverlay, 'is-disabled')}></div>}
      <div className='d-flex align-items-center'>
        {icon && (
          <div className={styles.moduleIcon}>
            <Icon icon={icon} className={styles.moduleSvg} />
          </div>
        )}
        <div>{translatedTitle}</div>
      </div>
      <Switch onClick={() => handleToggle()} onChange={() => {}} checked={modules[moduleName]} disabled={disabled} />
    </div>
  )
}
