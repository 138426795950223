import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../../utils/i18n'
import { FilterContainer, SearchField } from '../../FilterComponents'

class TaskGroupsFilter extends React.Component {
  render() {
    const { store, ...others } = this.props

    return (
      <FilterContainer store={store} {...others}>
        <SearchField store={store} placeholder={t('filters.search_task_groups')} />
      </FilterContainer>
    )
  }
}

export default observer(TaskGroupsFilter)
