import React from 'react'

import { useFormContext } from 'react-hook-form'

import Switch from '../Fields/Switch'
import HookFieldWrapper from './HookFieldWrapper'

const SwitchField = ({ name, required, fieldIcon, label, wrapperClassName = '', inputProps, disabled }) => {
  const { getFieldState, register } = useFormContext()
  const fieldState = getFieldState(name)

  const propsForInput = {
    ...register(name),
    id: name,
    className: 'form-control',
    ...inputProps,
    disabled
  }

  return (
    <HookFieldWrapper
      name={name}
      required={required}
      className={wrapperClassName}
      icon={fieldIcon}
      label={label}
      fieldState={fieldState}
    >
      <Switch {...propsForInput} />
    </HookFieldWrapper>
  )
}

export default SwitchField
