import { createContext, useContext } from 'react'

import dayjs from 'dayjs'

export const MonthContext = createContext({
  month: dayjs().startOf('month').format('YYYY-MM'),
  setMonth: () => {}
})

export const useMonthContext = () => useContext(MonthContext)
