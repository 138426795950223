import React from 'react'

import Axios from 'axios'
import classNames from 'classnames'
import ReactDOM from 'react-dom'
import ReactMarkdown from 'react-markdown'

import { t } from '../utils/i18n'
import Modal from './Modal'

class ChangeLog extends React.Component {
  state = { isOpen: false, content: null }

  toggleModal = async () => {
    const isOpen = this.state.isOpen

    if (isOpen) {
      this.setState({ isOpen: false })
    } else {
      const { data } = await Axios.get(this.props.url)

      this.setState({ isOpen: true, content: data.content })
    }
  }

  renderToggle() {
    const { linkText, hasUnreadChangeLogVersion } = this.props

    return (
      <div className='clickable ml-1' onClick={this.toggleModal}>
        {linkText}
        <i className={classNames('fa fa-info-circle ml-1', { 'text-primary': hasUnreadChangeLogVersion })} />
      </div>
    )
  }

  renderModal() {
    const { isOpen, content } = this.state

    if (!isOpen && content) return

    return ReactDOM.createPortal(
      <Modal
        className='modal-xl modal-dialog-centered'
        isOpen={isOpen}
        onClose={this.toggleModal}
        title={t('admin.change_logs.show.title')}
      >
        <ReactMarkdown className='change-log-container'>{content}</ReactMarkdown>

        <div className='btn-container'>
          <button className='btn btn-secondary' onClick={this.toggleModal}>
            {t('close')}
          </button>
        </div>
      </Modal>,
      document.querySelector('.modal-container')
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderToggle()}
        {this.renderModal()}
      </React.Fragment>
    )
  }
}

export default ChangeLog
