import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react'

import axios from 'axios'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import ReactDOM from 'react-dom'

import useUnsavedChangesConfirmation from '../../hooks/useUnsavedChangesConfirmation'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { getApiErrorMessage, showErrorModal } from '../../utils/confirmations'
import { formatDuration } from '../../utils/duration'
import { formatTime } from '../../utils/formatTime'
import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Avatar from '../Avatar'
import NfcCheckLogForm, { useNfcCheckLogFormik } from '../Forms/NfcCheckLogForm'
import ShiftDayForm, { useShiftDayFormik } from '../Forms/ShiftDayForm'
import Icon from '../Icon'
import Modal from '../Modal'
import ModalFormikForm from '../ModalFormikForm'
import { discard, checkOut } from '../elements/actionDefinitions'
import { ActionDropdown } from '../elements/actions'
import { ShiftContext } from './Shifts'
import useShiftDayDurationCalculator from './hooks/useShiftDayDurationCalculator'

const formikForm = { Form: NfcCheckLogForm, useForm: useNfcCheckLogFormik }

const ShiftTimePeriod = ({ subject, version }) => {
  const startTime = subject?.start_time || version.start_time
  const endTime = subject?.end_time || version.end_time

  if (!startTime || !endTime) return null

  return <div className='shift-info-bottom text-nowrap mr-3'>{`${formatTime(startTime)} - ${formatTime(endTime)}`}</div>
}

const HeaderContent = ({ shift, subject, version, employee, objectNames = [], shift: { color, name } }) => (
  <div className='modal-header-content'>
    <div className={classNames('d-flex align-items-center shift-modal-content', { 'employee-modal': employee })}>
      <span className='shift-color' style={{ backgroundColor: color }} />
      <div className='shift-info'>
        <div className='shift-name'>{name}</div>
        <div className='d-flex'>
          <ShiftTimePeriod shift={shift} subject={subject} version={version} />
          <div className='shift-info-bottom text-truncate'>
            <Icon icon='mapMarker' className='mr-1' />
            {objectNames.join(', ')}
          </div>
        </div>
      </div>
    </div>
    {employee && (
      <div className='employee-info d-flex align-items-center'>
        <div className='employee-name'>{employee.first_name_last_name}</div>
        <Avatar src={employee.picture_url} />
      </div>
    )}
  </div>
)

const RegistrationRow = ({
  registration,
  registration: {
    id,
    checked_in_at,
    checked_out_at,
    work_time_duration,
    check_log_duration,
    invalid_start_time,
    invalid_end_time,
    employee_name,
    picture_url,
    construction_object_name,
    permissions
  },
  multipleObjects,
  shiftView,
  registrationActions
}) => (
  <div className={classNames('registration-row registration', { invalid: invalid_start_time || invalid_end_time })}>
    {shiftView && (
      <div className='registration-cell employee'>
        <Avatar src={picture_url} />
        <div>{employee_name}</div>
      </div>
    )}
    {multipleObjects && <div className='registration-cell'>{construction_object_name}</div>}
    <div
      className={classNames('registration-cell', {
        invalid: invalid_start_time,
        'justify-content-center': multipleObjects
      })}
    >
      {invalid_start_time && <Icon icon='warningFilled' className='warning-icon' />}
      {formatTime(checked_in_at)}
    </div>
    <div className={classNames('registration-cell justify-content-center', { invalid: invalid_end_time })}>
      {invalid_end_time && <Icon icon='warningFilled' className='warning-icon' />}
      {checked_out_at ? formatTime(checked_out_at) : '--:--'}
    </div>
    <div className='registration-cell justify-content-end align-items-end flex-column'>
      <div>{formatDuration(work_time_duration)}</div>
      <div
        className={classNames('check-log-time', { invalid: Number(work_time_duration) < Number(check_log_duration) })}
      >
        ({formatDuration(check_log_duration)})
      </div>
    </div>
    <div className='registration-cell d-flex align-items-center justify-content-end'>
      <ActionDropdown
        object={registration}
        actions={registrationActions}
        permissions={permissions}
        tooltipId={id.toString()}
      />
    </div>
  </div>
)

const ShiftDayRegistrations = ({
  registrations = [],
  shiftView,
  loading,
  dayInvalid,
  multipleObjects,
  setRegistrations,
  loadRegistrations,
  loadShifts,
  setOverModalData
}) => {
  const registrationActions = [
    {
      permission: 'edit',
      text: t('edit'),
      icon: 'pencil',
      onClick: object => {
        setOverModalData({
          props: {
            url: url('v2/nfc_check_logs', object.id, 'edit'),
            reloadPageOnSuccess: false,
            onSuccessfulSubmit: () => {
              setOverModalData(null)
              loadRegistrations()
              loadShifts()
            },
            title: t('v2.nfc_check_logs.edit.title'),
            skipToggleButton: true,
            formikForm: formikForm,
            isOpen: true,
            onClose: () => setOverModalData(null)
          },
          Component: ModalFormikForm
        })
      }
    },
    checkOut('id', {
      confirmationProps: {
        text: t('confirm_check_out'),
        confirmButton: { title: t('check_out'), type: 'warning' },
        closeOnConfirm: true
      },
      handler: ({ id }) => {
        axios.put(url('v2/shift_registrations', id, 'manual_check_out')).then(() => {
          loadRegistrations()
          loadShifts()
        })
      }
    }),
    discard('v2/nfc_check_logs', {
      confirmationProps: {
        text: t('confirm_discard'),
        confirmButton: { title: t('discard'), type: 'danger' },
        closeOnConfirm: true
      },
      handler: ({ id }) => {
        axios.put(url('v2/nfc_check_logs', id, 'discard')).then(() => {
          loadShifts()
          setRegistrations(registrations.filter(reg => reg.id !== id))
        })
      }
    })
  ]

  return (
    <div className='registrations'>
      <div className='registration-row header'>
        {shiftView && (
          <div className='registration-cell employee'>{t('activerecord.attributes.shift.registrations.employee')}</div>
        )}
        {multipleObjects && (
          <div className='registration-cell'>{t('activerecord.attributes.shift.registrations.object')}</div>
        )}
        <div className={classNames('registration-cell', { 'justify-content-center': multipleObjects })}>
          {t('activerecord.attributes.shift.registrations.start')}
        </div>
        <div className='registration-cell justify-content-center'>
          {t('activerecord.attributes.shift.registrations.end')}
        </div>
        <div className='registration-cell justify-content-end'>
          {t('activerecord.attributes.shift.registrations.hours')}
        </div>
        <div className='registration-cell'></div>
      </div>
      {registrations.length ? (
        registrations.map((registration, i) => (
          <RegistrationRow
            registration={registration}
            key={i}
            shiftView={shiftView}
            multipleObjects={multipleObjects}
            registrationActions={registrationActions}
          />
        ))
      ) : (
        <div className='registration-row no-data'>
          {loading ? (
            t('components.active_table.loading_text')
          ) : (
            <>
              {dayInvalid && <Icon icon='warningFilled' className='warning-icon' />}
              <span>{t('activities_none')}</span>
            </>
          )}
        </div>
      )}
    </div>
  )
}

const ShiftDayFormToggler = ({ open, ...others }) => {
  const [showForm, setShowForm] = useState(open)
  const translationPath = others.employee ? 'shift_employee_days' : 'shift_days'

  return (
    <div className='past-form-wrapper'>
      <div>
        <hr className='delimiter' />
        <button
          className={classNames('shift-action edit d-flex ml-auto align-items-center', { open: showForm })}
          onClick={() => setShowForm(!showForm)}
        >
          <i className='icon fa fa-pencil mr-2' />
          <span>{t(`v2.${translationPath}.form.title_edit`)}</span>
          <i className={`arrow fa fa-caret-${showForm ? 'up' : 'down'} ml-2`} />
        </button>
      </div>
      <AnimatePresence initial={false}>
        {showForm && (
          <motion.div
            initial={{ opacity: 0, y: '-10%' }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.5 }
            }}
            exit={{
              opacity: 0,
              y: '-10%',
              transition: { duration: 0.5 }
            }}
          >
            <ShiftDayForm {...others} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

const ShiftDayExtendedModal = ({ closeModal, shift, employee, dayParams }) => {
  const [loading, setLoading] = useState(false)
  const [registrationData, setRegistrationData] = useState({})
  const { loadShifts } = useContext(ShiftContext)
  const [overModalData, setOverModalData] = useState(null)

  const {
    day,
    shiftUnits: {
      shiftDay,
      employeeDays,
      employeeDay,
      subject,
      startTime,
      endTime,
      singleDayShift,
      version,
      breakTimes
    },
    shiftStarted,
    shiftEnded,
    dayBlockParams: { showDayBlock, dayInvalid }
  } = dayParams

  const { dayjsDay, past, today, weekDay } = day

  const { dayTotalDuration } = useShiftDayDurationCalculator({
    employeeCount: registrationData?.employee_count,
    breakTimes,
    startTime,
    endTime,
    employeeDays,
    employeeDay: !!employee
  })

  const subjectId = employee ? employeeDay?.id : shiftDay?.id
  const { formik, sendRequest } = useShiftDayFormik({ shift, employee, subject, subjectId, closeModal, day })
  const handleClose = useUnsavedChangesConfirmation({ dirty: formik.dirty, onClose: closeModal })

  const setCalculatedRegistrationData = useCallback(data => {
    const sumDurations = type =>
      data.registrations.map(reg => Number(reg[type])).reduce((duration1, duration2) => duration1 + duration2, 0)

    setRegistrationData({
      ...data,
      totalDuration: sumDurations('check_log_duration'),
      totalWorkTimeDuration: sumDurations('work_time_duration')
    })
  }, [])

  const loadRegistrations = () => {
    setLoading(true)

    const params = {
      date: dayjsDay.format('YYYY-MM-DD'),
      employee_id: employee ? employee.id : null,
      shift_id: shift.id
    }

    axios
      .get(url('v2/shift_registrations', params))
      .then(({ data }) => {
        setCalculatedRegistrationData(data)
        setLoading(false)
      })
      .catch(() => {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      })
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (past || today) {
      loadRegistrations()
    }
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  const { registrations, totalWorkTimeDuration, totalDuration, multiple_objects, object_names } = registrationData || {}

  let dayName = capitalizeFirstLetter(t('date.day_names')[weekDay])
  let dayDate = dayjsDay.format('DD.MM.YYYY')

  if (!singleDayShift) {
    dayName += ` - ${capitalizeFirstLetter(t('date.day_names')[(weekDay + 1) % 7])}`
    dayDate += ` - ${dayjsDay.add(1, 'day').format('DD.MM.YYYY')}`
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  const showRegistrations = useMemo(() => {
    if (shift.can_manage) {
      if (today) {
        return registrations?.length > 0 || shiftStarted()
      } else {
        return past
      }
    }
  }, [shift, registrations, past, today])
  /* eslint-enable react-hooks/exhaustive-deps */

  if (overModalData) {
    const { props, Component } = overModalData
    return <Component {...props} />
  }

  return ReactDOM.createPortal(
    <Modal
      isOpen
      className='modal-lg modal-dialog-centered shift-modal'
      onClose={handleClose}
      header={
        <HeaderContent
          shift={shift}
          subject={subject}
          employee={employee}
          version={version}
          objectNames={past || today ? object_names : version.construction_object_short_names}
        />
      }
    >
      <>
        <div className='modal-body-header'>
          <div className='shift-day'>
            <div className='shift-day-name'>{dayName}</div>
            <div className='shift-day-date'>{dayDate}</div>
          </div>

          {totalDuration >= 0 && (
            <div className='shift-total'>
              <div className='shift-total-text'>{t('activerecord.attributes.shift.registrations.total')}</div>
              <div className='shift-total-data'>
                <span className='shift-total-duration'>({formatDuration(totalDuration)})</span>
                <span className='shift-total-work-duration'>{formatDuration(totalWorkTimeDuration)}</span>
                <span className='shift-planned-work-duration'>
                  {t('components.active_table.of_text')} {dayTotalDuration}
                </span>
              </div>
            </div>
          )}
        </div>
        <hr className='delimiter' />
        {showRegistrations ? (
          <>
            <ShiftDayRegistrations
              registrations={registrations}
              shiftView={!employee}
              loading={loading}
              dayInvalid={dayInvalid}
              multipleObjects={multiple_objects}
              setRegistrations={registrations => setCalculatedRegistrationData({ ...registrationData, registrations })}
              loadRegistrations={loadRegistrations}
              loadShifts={loadShifts}
              setOverModalData={setOverModalData}
            />
            <ShiftDayFormToggler
              shift={shift}
              employee={employee}
              closeModal={closeModal}
              day={day}
              showDayBlock={showDayBlock}
              open={today && !shiftEnded()}
              formik={formik}
              sendRequest={sendRequest}
            />
          </>
        ) : (
          <ShiftDayForm
            shift={shift}
            employee={employee}
            closeModal={closeModal}
            day={day}
            showDayBlock={showDayBlock}
            formik={formik}
            sendRequest={sendRequest}
          />
        )}
      </>
    </Modal>,
    document.querySelector('.modal-container')
  )
}

export default ShiftDayExtendedModal
