import React, { useCallback } from 'react'

import { useMultipleSelection } from 'downshift'
import { useField } from 'formik'

import { addOrRemoveSelectedItem } from '../../Options'
import FormikFieldWrapper from './FormikFieldWrapper'
import Select from './Select'

export default props => {
  const { options, name, required, label, className, fieldIcon, ...others } = props
  const [{ value: selectedItems }, , { setValue }] = useField(props)

  const { getSelectedItemProps, getDropdownProps } = useMultipleSelection()

  const onSelect = useCallback(
    selectedItem => addOrRemoveSelectedItem({ selectedItem, selectedItems, onChange: setValue }),
    [setValue, selectedItems]
  )

  return (
    <FormikFieldWrapper name={name} required={required} className={className} label={label} icon={fieldIcon}>
      <Select
        {...others}
        isMulti
        name={name}
        items={options}
        onSelect={onSelect}
        onSelectAll={setValue}
        getSelectedItemProps={getSelectedItemProps}
        getDropdownProps={getDropdownProps}
        value={selectedItems}
        label={label}
        required={required}
      />
    </FormikFieldWrapper>
  )
}
