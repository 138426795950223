import React, { useState, useCallback } from 'react'

import { useAbsenceContext } from './contexts/AbsenceContext'
import { unitSelectIndex, filterSelectableEmployeeDays, deselectByObject } from './shiftDayHelper'

const SelectedShiftItemsContext = React.createContext()

const SelectedShiftItemsProvider = ({ children }) => {
  const [selectedShiftItems, setSelectedShiftItems] = useState([])
  const [modalParams, setModalParams] = useState(null)
  const { absenceDatesWithEmployeeIds } = useAbsenceContext()

  const addSelectedShiftItem = useCallback(
    (item, reset) => {
      if (reset) {
        setSelectedShiftItems([item])
      } else {
        const unitIndex = unitSelectIndex(selectedShiftItems, item)

        if (unitIndex > -1) {
          setSelectedShiftItems(selectedShiftItems.filter((_, idx) => idx !== unitIndex))
        } else {
          setSelectedShiftItems([...selectedShiftItems, item])
        }
      }
    },
    [selectedShiftItems]
  )

  const selectShiftDay = useCallback(
    (schedule, date, reset) => {
      const employeesWithAbsence = absenceDatesWithEmployeeIds[date]
      const items = schedule.employee_ids.reduce((filtered, employee_id) => {
        if (!employeesWithAbsence || !employeesWithAbsence.includes(employee_id)) {
          filtered.push({ employee_id, date, schedule_id: schedule.id })
        }
        return filtered
      }, [])

      if (reset) {
        setSelectedShiftItems([...items])
      } else {
        const selectable = filterSelectableEmployeeDays(selectedShiftItems, items)
        if (selectable.length > 0) {
          setSelectedShiftItems([...selectedShiftItems, ...selectable])
        } else {
          setSelectedShiftItems(deselectByObject(selectedShiftItems, items))
        }
      }
    },
    [selectedShiftItems, absenceDatesWithEmployeeIds]
  )

  const clearShiftItems = useCallback(() => {
    setSelectedShiftItems([])
  }, [])

  const value = {
    selectedShiftItems,
    addSelectedShiftItem,
    clearShiftItems,
    selectShiftDay,
    setModalParams,
    modalParams
  }
  return <SelectedShiftItemsContext.Provider value={value}>{children}</SelectedShiftItemsContext.Provider>
}

const useSelectedShiftItems = () => {
  const context = React.useContext(SelectedShiftItemsContext)
  if (context === undefined) {
    throw new Error('useSelectedShiftItems must be used within a SelectedShiftItemsProvider')
  }
  return context
}

export { SelectedShiftItemsProvider, useSelectedShiftItems }
