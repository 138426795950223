import { makeObservable, action } from 'mobx'

/**
 * Table checkbox related functions
 * @param storeState
 */
export const StoreCheckbox = storeState =>
  class extends storeState {
    constructor(args) {
      super(args)
      makeObservable(this, {
        toggleHeaderCheckbox: action,
        toggleRowCheckbox: action,
        loadCheckboxes: action
      })
    }

    get checkboxes() {
      return this.state.tableRowCheckboxes
    }

    clearCheckboxes = () => this.checkboxes.clear()

    loadCheckboxes = newData => {
      const data = newData || this.state.data

      data.forEach(row => {
        if (!this.checkboxes.has(row.id)) {
          this.checkboxes.set(row.id, false)
        }
      })
    }

    getHeaderCheckboxState = () => this.isAllChecked()

    toggleHeaderCheckbox = () => this.setAllRowCheckboxes(!this.isAllChecked() && !this.isAnyChecked())

    getRowCheckboxState = id => this.checkboxes.get(id)

    toggleRowCheckbox = id => this.checkboxes.set(id, !this.getRowCheckboxState(id))

    setAllRowCheckboxes = val => this.checkboxes.forEach((_, key, map) => map.set(key, val))

    checkedRows = () => [...this.checkboxes].filter(([_, val]) => val)

    isAnyChecked = () => [...this.checkboxes].some(([_, val]) => val)

    isAllChecked = () => this.checkboxes.length > 0 && [...this.checkboxes.values()].every(val => val)

    getHeaderCheckboxIconClassName = () => (this.isAnyChecked() && !this.isAllChecked() ? 'checkminus' : 'checkmark')
  }
