import { useCallback } from 'react'

import { showConfirmation } from '../utils/confirmations'
import { t } from '../utils/i18n'

const useUnsavedChangesConfirmation = ({ dirty, onClose }) => {
  const handler = useCallback(
    event => {
      if (!dirty) {
        onClose()
        return
      }

      showConfirmation({
        isOpen: true,
        closeOnConfirm: true,
        onConfirm: onClose,
        text: t('confirm_leave_unsaved_changes'),
        confirmButton: { title: t('boolean_true'), type: 'warning' },
        event: event?.nativeEvent
      })
    },
    [dirty, onClose]
  )

  return handler
}

export default useUnsavedChangesConfirmation
