import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Avatar from '../Avatar'
import EmployeeFilter from '../Employees/EmployeesFilter'
import Icon from '../Icon'
import IconCell from '../IconCell'
import Store from '../Store'
import TableContainer from '../TableContainer'
import ConstructionObjectEmployeesTable from './ConstructionObjectEmployeesTable'

class ConstructionObjectEmployeesTableContainer extends TableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'currently_checked_in_duration', desc: false }]
  })

  columns = [
    {
      accessorKey: 'employee_id',
      visible: false
    },
    {
      header: t('activerecord.attributes.employee.first_name_last_name'),
      accessorKey: 'employee_first_name_last_name',
      cell: ({ getValue, row: { original } }) => (
        <Avatar src={original.picture_url} text={getValue()} resourcePath={url('v2/employees', original.employee_id)} />
      )
    },
    {
      header: t('activerecord.attributes.employee.personal_code'),
      accessorKey: 'employee_personal_code',
      cell: ({
        getValue,
        row: {
          original: { id, is_foreigner }
        }
      }) => (
        <IconCell
          id={id}
          showIcon={is_foreigner}
          tooltipText={t('activerecord.attributes.employee.is_foreigner')}
          iconComponent={tooltipId => <Icon icon='globe' className='ml-2 center-icon' data-tip data-for={tooltipId} />}
        >
          {getValue()}
        </IconCell>
      )
    },
    {
      header: t('activerecord.attributes.employee.company'),
      accessorKey: 'employee_company_name'
    },
    {
      header: t('activerecord.attributes.employee.code'),
      accessorKey: 'employee_code'
    },
    {
      header: t('activerecord.attributes.construction_object_employee.permitted_to_enter_until'),
      accessorKey: 'permitted_to_enter_until'
    },
    {
      header: t('activerecord.attributes.employee.currently_in_object'),
      accessorKey: 'currently_checked_in_duration',
      minSize: 90,
      size: 90,
      cell: ({ getValue }) => <span className='font-weight-bold'>{getValue() || '-'}</span>
    },
    {
      header: t('activerecord.attributes.employee.consumed_time_in_period'),
      accessorKey: 'consumed_time_in_period',
      size: 100
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <EmployeeFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <ConstructionObjectEmployeesTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
        />
      </div>
    )
  }
}

export default ConstructionObjectEmployeesTableContainer
