import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import ConstructionObjectsFilter from '../ConstructionObjects/ConstructionObjectsFilter'
import { SimpleSelect } from '../FilterComponents'
import Store from '../Store'
import TableContainer from '../TableContainer'
import { BooleanIcon } from '../elements/TableColumnsElements'
import CompanyConstructionObjectsTable from './CompanyConstructionObjectsTable'

class CompanyConstructionObjectsTableContainer extends TableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'checked_in_employees_count', desc: true }]
  })

  customFilters = () => {
    return <SimpleSelect store={this.store} selectable='contract_kind' options={this.props.contract_kind_options} />
  }

  columns = [
    {
      accessorKey: 'construction_object_id',
      visible: false
    },
    {
      header: t('activerecord.attributes.construction_object.short_name'),
      accessorKey: 'construction_object_short_name',
      cell: ({ getValue, row: { original } }) => (
        <a href={url('v2/construction_objects', original.construction_object_id)}>{getValue()}</a>
      )
    },
    {
      header: t('activerecord.attributes.construction_object.name'),
      accessorKey: 'construction_object_name',
      cell: ({ getValue, row: { original } }) => (
        <a href={url('v2/construction_objects', original.construction_object_id)}>{getValue()}</a>
      )
    },
    this.props.construction_industry && {
      header: t('activerecord.attributes.construction_object_company.contract_kind'),
      accessorKey: 'contract_kind',
      sortable: false
    },
    {
      header: t('activerecord.attributes.company.employees'),
      accessorKey: 'checked_in_employees_count',
      cell: ({ row: { original } }) => (
        <>
          <span className='text-success'>{original.checked_in_employees_count}</span> / {original.employees_count}
        </>
      )
    },
    {
      header: t('activerecord.attributes.construction_object_company.sync_all_employees_short'),
      accessorKey: 'sync_all_employees',
      minSize: 60,
      size: 60,
      cell: ({ getValue }) => <BooleanIcon checked={getValue()} />
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <ConstructionObjectsFilter
          {...this.props}
          store={this.store}
          customFilters={this.customFilters}
          filters={this.props.construction_industry}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <CompanyConstructionObjectsTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
        />
      </div>
    )
  }
}

export default CompanyConstructionObjectsTableContainer
