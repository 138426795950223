import React from 'react'

import dayjs from 'dayjs'

import { t } from '../../../../utils/i18n'
import DateTimePicker from '../../../DateTimePicker'
import Icon from '../../../Icon'
import Tooltip from '../../../Tooltip'
import DurationFields from './DurationFields'
import RepetitionIntervalSelect from './RepetitionIntervalSelect'

class RegularityPeriodFields extends React.Component {
  state = {
    startingDateChanged: false,
    endDateReduced: false,
    infiniteEndDate: this.props.disabled && !this.props.endDate
  }

  onStartingDateChange = newDate => {
    const formattedNewDate = dayjs(newDate).format(t('date.formats.default_raw_sql_human'))

    this.setState({ startingDateChanged: formattedNewDate !== this.props.startingDate })
  }

  onEndDateChange = newDate => {
    this.setState({ endDateReduced: dayjs(newDate).isBefore(dayjs(this.props.endDate), 'date') })
  }

  initialEndDateValue = () => {
    const { endDate, disabled } = this.props

    if (endDate) {
      return dayjs(endDate, t('date.formats.default_raw_sql_human'))
    } else if (disabled) {
      return null
    } else {
      return dayjs().endOf('month')
    }
  }

  endDateValue = this.initialEndDateValue()

  onCheckBoxChange = event => {
    const { checked } = event.target

    if (this.state.endDateReduced && checked) {
      this.setState({ endDateReduced: false })
    }

    this.setState({ infiniteEndDate: event.target.checked })
  }

  render() {
    const { startingDate, endDate, startingDateDisabled, endDateDisabled } = this.props

    const { startingDateChanged, endDateReduced, infiniteEndDate } = this.state

    const startInputOptions = {
      type: 'date',
      name: 'tasker_task[regularity][starting_date]',
      value: startingDate ?? dayjs().startOf('day').format(),
      minDate: startingDate && new Date(dayjs()),
      popperPlacement: 'right-start',
      readOnly: startingDateDisabled
    }

    const endInputOptions = {
      type: 'date',
      name: 'tasker_task[regularity][end_date]',
      value: infiniteEndDate ? null : this.endDateValue?.format(),
      maxDate: new Date(dayjs().add(1, 'year')),
      minDate: endDate && new Date(dayjs()),
      popperPlacement: 'right-start',
      readOnly: infiniteEndDate || endDateDisabled
    }

    const showWarning = startingDateChanged || endDateReduced

    const warningMessage = t(
      `v2.tasker.tasks.regularity_common_fields.${startingDateChanged ? 'starting_date_warning' : 'end_date_warning'}`
    )

    return (
      <>
        <div className='form-group'>
          <label className='col-sm-4 col-form-label'>{t('v2.tasker.tasks.regularity_common_fields.period')}</label>
          <div className='task-regularity-period-fields-container fields-right-container'>
            <div className='d-flex'>
              <DateTimePicker
                input_options={startInputOptions}
                afterChange={value => startingDate && this.onStartingDateChange(value)}
              />
              <span className='col-form-label ml-2 mr-2'>{t('up_to')}</span>
              <DateTimePicker
                input_options={endInputOptions}
                afterChange={value => endDate && this.onEndDateChange(value)}
              />
            </div>
            {showWarning && (
              <>
                <span className='warning-container' data-tip data-for='period-warning'>
                  <Icon icon='warning' className='warning-icon' />
                </span>
                <Tooltip id='period-warning' place='bottom' effect='solid'>
                  {warningMessage}
                </Tooltip>
              </>
            )}
          </div>
        </div>
        <div className='form-group tasker-infinite-end-date'>
          <label className='col-sm-4' />
          <div className='col-sm-6'>
            <div className='form-check'>
              <label className='checkbox-container'>
                <input
                  type='checkbox'
                  id='tasker_task_infinite_end_date'
                  className='form-check-input'
                  checked={this.state.infiniteEndDate}
                  disabled={endDateDisabled}
                  onChange={this.onCheckBoxChange}
                />
                <span className='checkmark rounded'></span>
              </label>
              <span className='d-flex align-items-center'>
                <label className='form-check-label' htmlFor='tasker_task_infinite_end_date'>
                  {t('v2.tasker.tasks.regularity_common_fields.infinite_end_date')}
                </label>
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const StartingTimeField = ({ value, disabled }) => (
  <div className='form-group'>
    <label className='col-sm-4 col-form-label'>{t('v2.tasker.tasks.regularity_common_fields.starting_time')}</label>
    <div className='task-time-input-container fields-right-container'>
      <DateTimePicker
        input_options={{
          type: 'time',
          name: 'tasker_task[regularity][starting_time]',
          value: value ? dayjs(value, t('time.formats.time_human_js')) : dayjs().startOf('day'),
          disabled,
          notClearable: true
        }}
      />
    </div>
  </div>
)

const CommonFields = props => {
  const {
    dayIntervalOptions,
    weekIntervalOptions,
    monthIntervalOptions,
    durationDaysOptions,
    currentIntervalKind,
    values,
    disabled,
    startingDateDisabled,
    endDateDisabled
  } = props

  const getOptions = () => {
    switch (currentIntervalKind) {
      case 'day':
        return dayIntervalOptions
      case 'week':
        return weekIntervalOptions
      case 'month':
        return monthIntervalOptions
    }
  }

  return (
    <div className='task-regularity-common-fields-container'>
      <RepetitionIntervalSelect
        options={getOptions()}
        selectedValue={values?.interval}
        currentIntervalKind={currentIntervalKind}
        intervalKind={values?.intervalKind}
        disabled={disabled}
      />
      <RegularityPeriodFields
        startingDate={values?.startingDate}
        endDate={values?.endDate}
        startingDateDisabled={startingDateDisabled}
        endDateDisabled={endDateDisabled}
        disabled={disabled}
      />
      <StartingTimeField value={values?.startingTime} disabled={disabled} />
      <DurationFields
        options={durationDaysOptions}
        durationDays={values?.durationDays}
        durationTime={values?.durationTime}
        disabled={disabled}
      />
    </div>
  )
}

export default CommonFields
