import { useState, useEffect, useCallback, useMemo } from 'react'

import axios from 'axios'
import dayjs from 'dayjs'
import { groupBy, sortBy } from 'lodash'
import { getApiErrorMessage, showErrorModal } from 'utils/confirmations'
import { url } from 'utils/urlGenerator'

const absenceIsInMonth = (absence, month) => {
  const { date_from, date_to } = absence

  const monthDay = dayjs(month)
  const monthStart = monthDay.format('YYYY-MM-DD')
  const monthEnd = monthDay.endOf('month').format('YYYY-MM-DD')

  return date_from <= monthEnd && date_to >= monthStart
}

const sortFilterAbsences = (absences, month) =>
  sortBy(absences, ['date_from']).filter(absence => absenceIsInMonth(absence, month))

const useAbsences = ({ month, search }) => {
  const [absences, setAbsences] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    axios
      .get(url('v2/absences', { month, search }))
      .then(({ data: { absences: absencesData } }) => {
        setAbsences(absencesData)
      })
      .catch(() => {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [month, search])

  const employeeAbsences = useMemo(
    () => groupBy(absences, ({ employee: { id, first_and_last_name } }) => `${first_and_last_name}-${id}`),
    [absences]
  )

  const addAbsences = useCallback(
    newAbsences => {
      setAbsences(old => sortFilterAbsences([...old, ...newAbsences], month))
    },
    [month]
  )

  const updateAbsence = useCallback(
    absence => {
      setAbsences(old => {
        const withUpdated = old.map(abs => (abs.id === absence.id ? absence : abs))
        return sortFilterAbsences(withUpdated, month)
      })
    },
    [month]
  )

  const deleteAbsence = useCallback(absence => {
    setAbsences(old => old.filter(abs => abs.id !== absence.id))
  }, [])

  return { absences, loading, addAbsences, updateAbsence, deleteAbsence, employeeAbsences }
}

export default useAbsences
