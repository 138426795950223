import React from 'react'

import axios from 'axios'
import classNames from 'classnames'
import { omitBy } from 'lodash'
import { observe } from 'mobx'

import { getColumnOptions } from '../utils/columnOptions'
import { getApiErrorMessage, showErrorModal } from '../utils/confirmations'
import { cleanTasksTableData } from '../utils/sessionStorage'
import { url } from '../utils/urlGenerator'

const getRows = (newRows, addrArr, rows) => {
  const idx = addrArr.shift()
  const isEnd = addrArr.length === 0

  return rows.map((row, pos) => {
    if (idx != pos) {
      return row
    } else if (isEnd) {
      return { ...row, subRows: newRows }
    } else {
      return { ...row, subRows: getRows(newRows, addrArr, row.subRows || row.subrows) }
    }
  })
}

const calculateExpandedMap = (expandedMap, rowId, open) =>
  open
    ? { ...expandedMap, [rowId]: open }
    : omitBy(expandedMap, (val, addr) => addr === rowId || addr.startsWith(`${rowId}.`))

class TableContainer extends React.Component {
  state = { columnOptions: [] }

  componentDidMount() {
    this.setState({ columnOptions: getColumnOptions(this.columns, this.props.hidden_columns) })
    this.store.setFilterLabels(this.props)
    observe(this.store.state.filters, () => this.resetSubRows())
  }

  onColumnVisibilityChange = accessorKey => {
    this.setState(ps => ({
      columnOptions: ps.columnOptions.map(column =>
        column.accessorKey === accessorKey ? { ...column, visible: !column.visible } : column
      )
    }))
  }

  getExpandableCellClassName = ({ row }) =>
    classNames({
      'third-level-row': row?.original.subRow && row.original.subRowName === 'thirdLevelRows',
      subrow: row?.original.subRow,
      last: row?.original.lastSubRow
    })

  renderExpander = (row, isParent, isExpanded) => {
    const { loadingRows } = this.store.state

    if (!isParent)
      return (
        <div className='expanded-row-line'>
          <div className='start'></div>
          <div className='pointer-line'></div>
          <div className='end'></div>
        </div>
      )

    const iconClassNames = loadingRows[row.id] ? 'fa-spinner fa-spin' : `fa-chevron-${isExpanded ? 'down' : 'right'}`

    if (row.depth > 0)
      return (
        <div className='expanded-row-line'>
          <div className='start'></div>
          <div className='subgroup-expander'>
            <div className='pointer-block'>
              <div className='pointer-line'></div>
            </div>
            <div>
              <span className='expander'>
                <i className={`fa ${iconClassNames}`} />
              </span>
            </div>
          </div>
          <div style={{ height: '100%' }}></div>
        </div>
      )

    return (
      <span className='expander'>
        <i className={`fa ${iconClassNames}`} />
      </span>
    )
  }

  onExpandedChange = async ({
    rowId,
    parentId,
    path,
    open,
    startingTime = null,
    parentType = null,
    contextModel = null,
    contextObjectId = null
  }) => {
    this.store.toggleLoadingRow(rowId)

    const expanded = calculateExpandedMap(this.store.state.expanded, rowId, open)
    const scrollPage = this.store.touchScrollPage(rowId)
    scrollPage.pageNum = 1

    const { rows, rowsLeftCount } = await this.loadSubRows({
      rowId,
      parentId,
      path,
      open,
      startingTime,
      parentType,
      contextModel,
      contextObjectId
    })

    scrollPage.rowsLeftCount = rowsLeftCount

    this.store.toggleLoadingRow(rowId)

    const data = this.getTableRows(rowId, rows)
    this.store.updateState({ data, expanded })
    this.store.loadCheckboxes(rows)
  }

  loadSubRows = async ({ rowId, parentId, path, open, startingTime, parentType, contextModel, contextObjectId }) => {
    const scrollPage = this.store.touchScrollPage(rowId)

    let newRows = []
    let newRowsLeftCount = 0

    if (open) {
      try {
        const {
          data: { rows, rowsLeftCount }
        } = await axios.get(
          url(path, {
            parent_type: parentType,
            parent_id: parentId,
            context_model: contextModel,
            context_object_id: contextObjectId,
            starting_time: startingTime,
            scroll_page: scrollPage.pageNum,
            ...this.store.buildParams()
          })
        )

        newRows = rows
        newRowsLeftCount = rowsLeftCount
      } catch (err) {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      }
    }

    return { rows: newRows, rowsLeftCount: newRowsLeftCount }
  }

  onLoadMoreChange = async ({ rowId, parentId, path, startingTime, parentType, contextModel, contextObjectId }) => {
    const scrollPage = this.store.touchScrollPage(rowId)
    scrollPage.pageNum += 1

    const { rows, rowsLeftCount } = await this.loadSubRows({
      open: true,
      rowId,
      parentId,
      path,
      startingTime,
      parentType,
      contextModel,
      contextObjectId
    })

    const parentRow = this.store.state.data[rowId]
    scrollPage.rowsLeftCount = rowsLeftCount
    const data = this.getTableRows(rowId, parentRow.subRows.concat(rows))
    this.store.updateState({ data })
    this.store.loadCheckboxes(rows)
  }

  getTableRows(parentRowId, newRows = []) {
    const addrArr = `${parentRowId}`.split('.')

    return getRows(newRows, addrArr, this.store.state.data)
  }

  resetSubRows() {
    cleanTasksTableData()
    this.store.updateState({ loadingRows: {}, expanded: {} })
  }
}

export default TableContainer
