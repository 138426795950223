import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../utils/i18n'
import { FilterContainer, SearchField, SimpleSelect, DateRangeFilter } from '../FilterComponents'

class ConstructionObjectEventsFilter extends React.Component {
  hiddenFilters = () => {
    const { store, company_options, kind_options } = this.props

    return (
      <React.Fragment>
        <SimpleSelect store={store} selectable='company_id' options={company_options} isMulti={true} />
        <SimpleSelect store={store} selectable='kind' options={kind_options} />
      </React.Fragment>
    )
  }

  render() {
    const { store, ...others } = this.props

    return (
      <FilterContainer store={store} hiddenFilters={this.hiddenFilters} {...others}>
        <SearchField store={store} placeholder={t('filters.search_construction_object_events')} />
        <DateRangeFilter store={store} />
      </FilterContainer>
    )
  }
}

export default observer(ConstructionObjectEventsFilter)
