import React from 'react'

import { t } from '../../../utils/i18n'
import Store from '../../Store'
import TableContainer from '../../TableContainer'
import TaskGroupsFilter from './TaskGroupsFilter'
import TaskGroupsTable from './TaskGroupsTable'

class TaskGroupsTableContainer extends TableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'name', desc: false }],
    baseScope: this.props.base_scope
  })

  columns = [
    {
      header: t('activerecord.attributes.tasker/user_group.name'),
      accessorKey: 'name'
    },
    {
      header: t('activerecord.attributes.tasker/user_group.draft_tasks_count'),
      accessorKey: 'draft_tasks_count',
      size: 20,
      minSize: 20
    },
    {
      header: t('activerecord.attributes.tasker/user_group.in_progress_tasks_count'),
      accessorKey: 'in_progress_tasks_count',
      size: 20,
      minSize: 20
    },
    {
      header: t('activerecord.attributes.tasker/user_group.delayed_tasks_count'),
      accessorKey: 'delayed_tasks_count',
      size: 20,
      minSize: 20
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <TaskGroupsFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <TaskGroupsTable {...this.props} store={this.store} columns={this.columns} columnOptions={columnOptions} />
      </div>
    )
  }
}

export default TaskGroupsTableContainer
