import React from 'react'

import classNames from 'classnames'

import { t } from '../../../utils/i18n'
import Icon from '../../Icon'
import Tooltip from '../../Tooltip'

class ImageUploadButton extends React.Component {
  state = { isUploading: false }

  componentDidMount() {
    this.fileInput.addEventListener('change', this.onFilesAdded)
  }

  get form() {
    return document.querySelector('.edit_tasker_task')
  }

  get fileInput() {
    return this.form.querySelector('#tasker_task_images')
  }

  onFilesAdded = () => {
    const { allowed_image_count, max_image_count } = this.props

    if (this.fileInput.files.length > allowed_image_count) {
      alert(t('v2.tasker.tasks.image_upload.max_image_count_exceeded', { max_image_count }))
    } else {
      this.setState({ isUploading: true })
      this.form.submit()
    }
  }

  renderProgressIndicator() {
    return (
      <div className='upload-progress-indicator'>
        <i className='i fa fa-spinner fa-pulse mr-2' />
        {t('v2.tasker.tasks.image_upload.upload_in_progress')}
      </div>
    )
  }

  renderButtonContent(link) {
    return (
      <span className={classNames('d-flex align-items-center', { link })}>
        <Icon icon='iconPlusCircle' className='mr-2' />
        <div className='icon-link-text'>{t('v2.tasker.tasks.image_upload.add_image')}</div>
      </span>
    )
  }

  renderUploadButton() {
    if (this.props.disabled) {
      return this.renderDisabledUploadButton()
    } else {
      return this.renderActiveUploadButton()
    }
  }

  renderDisabledUploadButton() {
    return (
      <div className='new-resource-link' data-tip data-for='image-upload-button'>
        {this.renderButtonContent()}

        <Tooltip id='image-upload-button' place='top' effect='solid' className='accented'>
          {this.props.disabled_message}
        </Tooltip>
      </div>
    )
  }

  renderActiveUploadButton() {
    return (
      <label htmlFor='tasker_task_images'>
        <div className='new-resource-link'>{this.renderButtonContent(true)}</div>
      </label>
    )
  }

  render() {
    if (this.state.isUploading) {
      return this.renderProgressIndicator()
    } else {
      return this.renderUploadButton()
    }
  }
}

export default ImageUploadButton
