import { createContext, useContext } from 'react'

export const SchedulesContext = createContext({
  addSchedule: () => {},
  refreshSchedule: () => {},
  removeSchedule: () => {},
  manageMultipleSchedules: () => {},
  loadSchedules: () => {},
  setShiftFactualTime: () => {},
  schedules: [],
  loading: false,
  shiftFactualTime: false
})

export const useSchedulesContext = () => useContext(SchedulesContext)
