import React from 'react'

import ActiveTable from '../ActiveTable'
import { edit, remove, removeSelected } from '../elements/actionDefinitions'

class CompanyConstructionObjectsTable extends React.Component {
  pathPrefix = 'v2/construction_object_companies'

  formParams = {
    context: 'company',
    construction_object_company: { company_id: this.props.company_id }
  }

  actions = {
    row: [
      edit(this.pathPrefix, 'v2.construction_object_companies.contexts.company.edit.title', this.formParams),
      remove(this.pathPrefix)
    ],
    header: [removeSelected(this.pathPrefix)]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        pathPrefix={this.pathPrefix}
        pathResourceId='construction_object_id'
        translationPath={['construction_object_companies', 'contexts', 'company']}
        highlightedBy='checked_in_employees_count'
        inactiveBy='archived'
        formParams={this.formParams}
        reloadPageOnSuccess={true}
        onRowClick='openEditForm'
      />
    )
  }
}

export default CompanyConstructionObjectsTable
