import { useEffect, useState } from 'react'

import dayjs from 'dayjs'

import { formatMinutes } from '../../Forms/utils/handleTotalTime'

const timesAsDayjs = (startTime, endTime, format) => {
  const startTimeDayjs = startTime && dayjs(startTime, format)
  let endTimeDayjs

  if (endTime) {
    endTimeDayjs = dayjs(endTime, format)
    if (endTimeDayjs.isBefore(startTimeDayjs)) endTimeDayjs = endTimeDayjs.add(1, 'day')
  }

  return { startTimeDayjs, endTimeDayjs }
}

export default ({ employeeCount, startTime, endTime, breakTimes, employeeDays, employeeDay }) => {
  const [dayTotalDuration, setDayTotalDuration] = useState('00:00')

  const { startTimeDayjs, endTimeDayjs } = timesAsDayjs(startTime, endTime)

  const calculateBreakTimesDuration = breakTimePeriods =>
    breakTimePeriods
      .map(({ start, finish }) => {
        const { startTimeDayjs: startTimeDurationDayjs, endTimeDayjs: endTimeDurationDayjs } = timesAsDayjs(
          start,
          finish,
          'HH:mm'
        )

        return endTimeDurationDayjs.diff(startTimeDurationDayjs, 'minutes')
      })
      .reduce((duration1, duration2) => duration1 + duration2, 0)

  const calculateShiftDayDuration = () => {
    const employeeDayCount = employeeDays?.length || 0

    let employeeDayTotalDuration

    if (employeeDayCount) {
      employeeDayTotalDuration = employeeDays
        .map(({ start_time, end_time, break_time_periods, status }) => {
          if (status === 'deleted') return 0

          const { startTimeDayjs: startTimeEmployeeDayjs, endTimeDayjs: endTimeEmployeeDayjs } = timesAsDayjs(
            start_time,
            end_time
          )

          const dayLength = endTimeEmployeeDayjs.diff(startTimeEmployeeDayjs, 'minutes')

          return dayLength - calculateBreakTimesDuration(break_time_periods)
        })
        .reduce((duration1, duration2) => duration1 + duration2, 0)
    } else {
      employeeDayTotalDuration = 0
    }

    const defaultDuration = endTimeDayjs
      ? endTimeDayjs.diff(startTimeDayjs, 'minutes') - calculateBreakTimesDuration(breakTimes)
      : 0

    const totalDuration = (employeeCount - employeeDayCount) * defaultDuration + employeeDayTotalDuration

    return formatMinutes(totalDuration)
  }

  const calculateEmployeeDayDuration = () => {
    if (!startTime) return '00:00'

    const minutes = endTimeDayjs.diff(startTimeDayjs, 'minutes') - calculateBreakTimesDuration(breakTimes)
    return formatMinutes(minutes)
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (employeeCount) {
      const duration = employeeDay ? calculateEmployeeDayDuration() : calculateShiftDayDuration()

      setDayTotalDuration(duration)
    }
  }, [employeeCount, employeeDays, startTime, endTime])
  /* eslint-enable react-hooks/exhaustive-deps */

  return { dayTotalDuration }
}
