import dayjs from 'dayjs'

/**
 * Returns from formatted duration (seconds) in hh:mm format. Duration CAN exceed 24h
 * @param  {Number}   duration
 * @return {String}
 */
export const formatDuration = (duration = 0) => {
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration % 3600) / 60)

  return `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}`
}

/**
 * Returns formatted duration in hh:mm format
 * @param  {?(Date|String)}   startedAt
 * @param  {?(Date|String)}   finishedAt
 * @return {String}
 */
export const getDuration = (startedAt, finishedAt) => {
  if (!startedAt) {
    return '--:--'
  }

  const startAt = dayjs(startedAt)
  let endAt = dayjs(finishedAt)

  if (!endAt.isValid()) {
    endAt = dayjs()
  }

  return formatDuration(endAt.diff(startAt, 'seconds'))
}

export const durationFromHHMM = str => {
  const iso = `PT${str.replace(':', 'H')}M`

  return dayjs.duration(iso)
}
