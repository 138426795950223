import React, { useState } from 'react'

import classNames from 'classnames'

import useTranslate from '../../../hooks/useTranslate'
import { formatCurrency, formatDate } from '../../../utils/formatters'
import Table from '../../Table'
import styles from './Buttons.module.scss'
import ContractForm from './ContractForm'
import ContractHistory from './ContractHistory'

const NewContractButton = ({ openForm }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.ContractForm.buttons')

  return (
    <button className={classNames('btn btn-primary', styles.vedludbNewButton)} onClick={() => openForm(null)}>
      {t('.newContract')}
    </button>
  )
}

const contractsWithPayments = (contracts = [], payments) => {
  if (!contracts) {
    return []
  }

  return contracts.map(contract => ({
    ...contract,
    maksajumuSkaits: payments.filter(payment => payment.ligumsId === contract.ligumsId).length
  }))
}

const ContractsTable = ({ isLoading, contracts, payments, title, interactable = false, style = {} }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.ContractsTable')
  const [selectedContract, setSelectedContract] = useState(undefined)
  const [historyContract, setHistoryContract] = useState(null)
  const [isHistoryOpen, setHistoryOpen] = useState(false)
  const [isFormOpen, setIsFormOpen] = useState(false)

  const openForm = resource => {
    setSelectedContract(resource)
    setIsFormOpen(true)
  }

  const openHistory = contract => {
    setHistoryContract(contract)
    setHistoryOpen(true)
  }

  const closeForm = () => {
    setIsFormOpen(false)
    setSelectedContract(undefined)
  }

  const closeHistory = () => setHistoryOpen(false)

  const columns = [
    {
      Header: t('.contract_number'),
      accessor: 'ligumaNumurs'
    },
    {
      Header: t('.contract_date'),
      accessor: 'ligumaDatums',
      Cell: ({ resource }) => formatDate(resource.ligumaDatums)
    },
    {
      Header: t('.contract_sum'),
      accessor: 'ligumaSumma',
      className: 'text-right',
      Cell: ({ resource }) => (
        <div>
          <i className='fa fa-eur mx-1' aria-hidden='true'></i>
          {formatCurrency(resource.ligumaSumma)}
        </div>
      )
    },
    {
      Header: t('.customer'),
      accessor: 'pasutitajs'
    },
    {
      Header: t('.subcontractor'),
      accessor: 'apaksuznemejaNosaukums'
    },
    {
      Header: t('.contract_payment_amount'),
      accessor: 'maksajumuSkaits'
    }
  ]

  const tableActions = [
    {
      onClick: openHistory,
      text: t('.showHistory'),
      icon: 'history'
    }
  ]

  if (interactable)
    tableActions.unshift({
      onClick: openForm,
      text: t('.showContract'),
      icon: 'eye'
    })

  return (
    <div style={style}>
      {interactable ? (
        <>
          {isFormOpen && <ContractForm closeForm={closeForm} contract={selectedContract} />}
          <div className='d-flex justify-content-between my-2'>
            <h3>{title}</h3>
            <NewContractButton openForm={openForm} />
          </div>
        </>
      ) : (
        <h3>{title}</h3>
      )}
      <ContractHistory contract={historyContract} onClose={closeHistory} isOpen={isHistoryOpen} />
      <Table
        isLoading={isLoading}
        data={contractsWithPayments(contracts, payments)}
        columns={columns}
        actions={tableActions}
        className={classNames('contracts-table', { interactable })}
        withBorder
        withHover
      />
    </div>
  )
}

export default ContractsTable
