import React, { useCallback, useEffect, useState, useMemo } from 'react'

import axios from 'axios'
import { FormProvider, useForm } from 'react-hook-form'

import useUnsavedChangesConfirmation from '../../../hooks/useUnsavedChangesConfirmation'
import FileUploader from '../../../utils/FileUploader'
import { url } from '../../../utils/urlGenerator'
import TasksFormFields from './Form/FormFields'
import TasksFormSidebar from './Form/FormSidebar'
import { setupModules, preprocessForSubmit } from './Form/helpers'
import styles from './Form/styles/TasksFormModal.module.scss'
import { formData } from './Form/utils/dataInitializer'
import { getValidationSchema } from './Form/validations'

const TasksForm = props => {
  const { onClose, objectId, title, parentId } = props
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [selectOptions, setSelectOptions] = useState({})
  const [labels, setLabels] = useState({ device: false, client: false })
  const [modules, setModules] = useState(setupModules())
  const [relatedTaskId, setRelatedTaskId] = useState(null)
  const [timeEntry, setTimeEntry] = useState(false)
  const [locationAllowed, setLocationAllowed] = useState(false)
  const formType = objectId || parentId ? 'edit' : 'new'
  const disableRegularity = formType === 'edit' || parentId

  const form = useForm({
    mode: 'onSubmit',
    defaultValues: formData(null),
    resolver: getValidationSchema(modules)
  })

  const closeHandler = useUnsavedChangesConfirmation({ dirty: form.formState.isDirty, onClose })
  const {
    reset,
    formState: { errors: _errors },
    setError
  } = form

  const assignData = data => {
    setSelectOptions(data.select_options)
    setLabels(data.labels)
    setTimeEntry(data.active_time_entry)
    setModules(setupModules(data))
    setLocationAllowed(data.location_allowed)
  }

  const fetchUrl = useMemo(() => {
    if (parentId) {
      return url('v2/tasker/tasks/edit_related_task_group', { parent_id: parentId })
    } else {
      return objectId ? url('v2/tasker/tasks', objectId, 'edit') : url('v2/tasker/tasks/new')
    }
  }, [parentId, objectId])

  const submitUrl = useMemo(() => {
    if (parentId) {
      return url('v2/tasker/tasks', relatedTaskId, 'update_related_task_group')
    } else {
      return url('v2/tasker/tasks', objectId)
    }
  }, [parentId, relatedTaskId, objectId])

  useEffect(() => {
    setLoading(true)

    async function fetchTask() {
      const { data } = await axios.get(url(fetchUrl))
      assignData(data)
      reset(formData(data))
      if (parentId) setRelatedTaskId(data.id)
      setLoading(false)
    }
    fetchTask()
  }, [fetchUrl, setRelatedTaskId, parentId, reset])

  const fetchByTemplate = async template => {
    setLoading(true)
    let requestData = {}

    if (template.value > 0) {
      requestData.template_id = template.value
    }

    const { data } = await axios.get(url('v2/tasker/tasks/new'), { params: requestData })

    assignData(data)
    reset(formData(data))

    setLoading(false)
  }

  const onSubmit = useCallback(
    async values => {
      const setFieldError = (name, errorText) =>
        setError(name, {
          type: 'manual',
          message: errorText
        })

      const loadServerErrors = errorsJson => {
        const errors = JSON.parse(errorsJson)
        const fieldsNames = Object.keys(errors)

        fieldsNames.forEach(name => errors[name].forEach(errorText => setFieldError(name, errorText)))
      }

      setSubmitting(true)

      const fileUploader = new FileUploader(values.files)
      const result = await fileUploader.perform() // File keys

      if (!result) return

      axios({
        method: objectId || parentId ? 'PUT' : 'POST',
        url: url(submitUrl),
        data: preprocessForSubmit(values, modules, result, formType)
      })
        .then(() => {
          location.reload()
        })
        .catch(err => {
          if (err.response.status == 422 && err.response.data?.errors) {
            loadServerErrors(err.response.data?.errors)
          }

          console.error(err)
          setSubmitting(false)
        })
    },
    [formType, modules, objectId, parentId, submitUrl, setError]
  )

  const toggleModule = moduleName => {
    const currentValue = modules[moduleName]
    if (currentValue === undefined) console.error(`Trying to toggle unknown module '${moduleName}'`)
    setModules({ ...modules, [moduleName]: !currentValue })
  }

  const contextProps = {
    selectOptions,
    modules,
    submitting,
    formType,
    disableRegularity,
    timeEntry,
    locationAllowed,
    onSubmit,
    toggleModule,
    fetchByTemplate
  }

  return (
    <div className='d-flex tasks-form-container'>
      {loading && <div className={styles.loadingOverlay}></div>}
      <FormProvider {...form} {...contextProps}>
        <TasksFormFields closeForm={closeHandler} title={title} isGroup={!!parentId} labels={labels} />
        <TasksFormSidebar onClose={closeHandler} />
      </FormProvider>
    </div>
  )
}

export default TasksForm
