import React from 'react'

import ActiveTable from '../ActiveTable'
import {
  edit,
  discard,
  unlock,
  invite,
  discardSelected,
  unlockSelected,
  inviteSelected
} from '../elements/actionDefinitions'

class UsersTable extends React.Component {
  actions = {
    row: [
      edit(this.props.pathPrefix, 'v2.users.edit.title'),
      unlock(this.props.pathPrefix),
      invite(this.props.pathPrefix),
      discard(this.props.pathPrefix)
    ],
    header: [
      unlockSelected(this.props.pathPrefix),
      inviteSelected(this.props.pathPrefix),
      discardSelected(this.props.pathPrefix)
    ]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        highlightedBy='online'
        dangerBy='locked'
        warningBy='inactive'
        translationPath='users'
        onRowClick='navigateToResource'
      />
    )
  }
}

export default UsersTable
