import React from 'react'

import Store from '../../Store'
import AccountsFilter from './AccountsFilter'
import AccountsTable from './AccountsTable'

class AccountsTableContainer extends React.Component {
  store = new Store({
    url: this.props.url,
    defaultFilter: this.props.default_filter,
    defaultSorted: [{ id: 'created_at', desc: true }],
    baseScope: this.props.base_scope
  })

  render() {
    return (
      <div>
        <AccountsFilter {...this.props} store={this.store} />
        <AccountsTable {...this.props} store={this.store} />
      </div>
    )
  }
}

export default AccountsTableContainer
