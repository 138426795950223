import React from 'react'

import classNames from 'classnames'

import { formatTime } from '../../../utils/formatTime'
import Avatar from '../../Avatar'
import Icon from '../../Icon'

const ShiftTimePeriod = ({ shift }) => {
  if (!shift) return null

  const startTime = shift.start_datetime
  const endTime = shift.end_datetime

  return <div className='shift-info-bottom text-nowrap mr-3'>{`${formatTime(startTime)} - ${formatTime(endTime)}`}</div>
}

const ShiftModalHeader = ({ shift, schedule, schedule: { color, name }, employee }) => (
  <div className='modal-header-content'>
    <div className={classNames('d-flex align-items-center shift-modal-content')}>
      <span className='shift-color' style={{ backgroundColor: color }} />
      <div className='shift-info'>
        <div className='shift-name'>{name}</div>
        <div className='d-flex'>
          <ShiftTimePeriod shift={shift} />
          <div className='shift-info-bottom text-truncate'>
            <Icon icon='mapMarker' className='mr-1' />
            {schedule.construction_object_short_name}
          </div>
        </div>
      </div>
    </div>
    {employee && (
      <div className='employee-info d-flex align-items-center'>
        <div className='employee-name'>{employee.first_name_last_name}</div>
        <Avatar src={employee.picture_url} />
      </div>
    )}
  </div>
)

export default ShiftModalHeader
