import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Avatar from '../Avatar'
import ConstructionObjectEventsFilter from '../ConstructionObjectEvents/ConstructionObjectEventsFilter'
import Store from '../Store'
import TableContainer from '../TableContainer'
import ConstructionObjectEventsTable from './ConstructionObjectEventsTable'

class ConstructionObjectEventsTableContainer extends TableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'updated_at', desc: true }]
  })

  columns = [
    {
      header: t('activerecord.attributes.construction_object_event.employees'),
      accessorKey: 'employees',
      cell: ({
        row: {
          original: { employees }
        }
      }) => {
        const remainingEmployeeCount = employees.length - 1
        const firstEmployee = employees[0]

        return (
          <div className='d-flex align-items-center'>
            <span onClick={e => e.stopPropagation()}>
              <Avatar
                src={firstEmployee.picture_url}
                text={firstEmployee.first_name_last_name}
                resourcePath={url('v2/employees', firstEmployee.id)}
              />
            </span>

            {remainingEmployeeCount ? <span className='badge badge-secondary'>+{remainingEmployeeCount}</span> : null}
          </div>
        )
      },
      sortable: false
    },
    {
      header: t('activerecord.attributes.construction_object_event.kind'),
      accessorKey: 'kind',
      sortable: false
    },
    {
      header: t('activerecord.attributes.construction_object_event.event_time'),
      accessorKey: 'updated_at',
      sortable: false
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <ConstructionObjectEventsFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <ConstructionObjectEventsTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
        />
      </div>
    )
  }
}

export default ConstructionObjectEventsTableContainer
