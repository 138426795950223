import dayjs from 'dayjs'
import * as yup from 'yup'

import { t } from './i18n'

yup.setLocale({
  mixed: {
    required: t('activerecord.errors.messages.required')
  },
  array: {
    min: t('validations.array.min')
  }
})

yup.addMethod(yup.string, 'humanDate', function () {
  return this.test('humanDate', t('validations.date.valid'), function (value) {
    return dayjs(value, 'DD.MM.YYYY').isValid()
  })
})

export default yup
