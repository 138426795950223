import React from 'react'

import { useField } from 'formik'

import FormikFieldWrapper from './FormikFieldWrapper'

const TextField = props => {
  const { label, textArea, placeholder, required, disabled, className, inputProps = {} } = props
  const [field] = useField(props)
  const { name } = field

  const formatRequired = text => (required ? `${text}*` : text)

  const propsForInput = {
    ...field,
    id: name,
    placeholder: placeholder && formatRequired(placeholder),
    className: 'form-control',
    ...inputProps,
    disabled
  }

  return (
    <FormikFieldWrapper name={name} label={label} required={required} className={className}>
      <div className='form-input-wrapper'>
        {textArea ? <textarea rows='3' {...propsForInput} /> : <input type='text' {...propsForInput} />}
      </div>
    </FormikFieldWrapper>
  )
}

export default TextField
