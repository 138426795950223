import React, { useRef, useEffect } from 'react'

import { formatNumeral, registerCursorTracker } from 'cleave-zen'
import { useController } from 'react-hook-form'

import styles from './FieldStyles.module.scss'
import HookFieldWrapper from './HookFieldWrapper'

const formatNumber = val =>
  formatNumeral(val || '', {
    delimiter: ' ',
    numeralPositiveOnly: true
  })

const NumberField = ({
  name,
  required,
  fieldIcon,
  label,
  className = '',
  wrapperClassName = '',
  placeholder,
  inputProps,
  disabled,
  ...others
}) => {
  const formatRequired = text => (required ? `${text}*` : text)

  const inputRef = useRef(null)
  const { field } = useController({
    name
  })

  useEffect(() => registerCursorTracker({ input: inputRef.current, delimiter: '*' }), [inputRef])

  const propsForInput = {
    id: name,
    placeholder: placeholder && formatRequired(placeholder),
    ...inputProps,
    className: `${styles.formControl} form-control ${className}`,
    disabled
  }

  return (
    <HookFieldWrapper name={name} required={required} className={wrapperClassName} icon={fieldIcon} label={label}>
      <div className={styles.formInputWrapper}>
        <input
          {...others}
          {...propsForInput}
          {...field}
          ref={e => {
            field.ref(e)
            inputRef.current = e
          }}
          value={formatNumber(field.value)}
          onChange={e => {
            field.onChange(formatNumber(e.target.value))
          }}
          type='text'
        />
      </div>
    </HookFieldWrapper>
  )
}

export default NumberField
