import { formatValuesForSubmit } from './submit'

export default (value, valueFormatter) => {
  const formattedObject = Object.fromEntries(
    Object.entries(value).filter(([, val]) => {
      if (valueFormatter) {
        val = formatValuesForSubmit({ value: val }, { value: valueFormatter }).value
      }

      return val !== null
    })
  )
  return Object.keys(formattedObject).length ? formattedObject : null
}
