import yup from 'utils/yup'

export const commonRegistrationFieldSchemas = {
  ligumaDatums: yup.date().nullable().required(),
  ligumaSumma: yup
    .string()
    .nullable()
    .transform(currentValue => currentValue.replace(/\s/g, ''))
    .matches(/^[0-9]+(\.[0-9]+)?$/, {
      message: 'test',
      excludeEmptyString: true
    })
    .required(),
  pasutitajaNosaukums: yup.string().when('citsPasutitajs', {
    is: true,
    then: schema => schema.required()
  }),
  pasutitajaRegNr: yup.string().when('citsPasutitajs', {
    is: true,
    then: schema => schema.required()
  })
}
