import React from 'react'

import ActiveTable from '../ActiveTable'
import { edit, remove, removeSelected } from '../elements/actionDefinitions'

class CompanyUsersTable extends React.Component {
  pathPrefix = 'v2/company_users'

  formParams = {
    context: 'company',
    company_user: { company_id: this.props.company_id }
  }

  actions = {
    row: [
      edit(this.pathPrefix, 'v2.company_users.contexts.company.edit.title', this.formParams),
      remove(this.pathPrefix)
    ],
    header: [removeSelected(this.pathPrefix)]
  }

  canNavigateToResource({ permissions }) {
    return permissions.show
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        pathPrefix={this.pathPrefix}
        translationPath={['company_users', 'contexts', 'company']}
        highlightedBy='user_online'
        pathResourceId='user_id'
        canNavigateToResource={this.canNavigateToResource}
        reloadPageOnSuccess={true}
        formParams={this.formParams}
        onRowClick='openEditForm'
      />
    )
  }
}

export default CompanyUsersTable
