import React from 'react'

import { t } from '../../../utils/i18n'
import ShiftRow from './ShiftRow'

const Shifts = ({ shifts }) => (
  <div className='registrations'>
    <div className='registration-row header'>
      <div className='registration-cell employee'>{t('activerecord.attributes.shift.registrations.employee')}</div>
      <div className='registration-cell'>Darba laiks</div>
      <div className='registration-cell'>Plānotais laiks</div>
      <div className='registration-cell justify-content-end'>
        {t('activerecord.attributes.shift.registrations.hours')}
      </div>
    </div>
    {shifts.map((shift, i) => (
      <ShiftRow shift={shift} key={i} />
    ))}
  </div>
)

export default Shifts
