import React from 'react'

import ReactTooltip from 'react-tooltip'

import Icon from './Icon'

const Tooltip = ({ text, children, place = 'bottom', effect = 'solid', className = 'accented', ...restProps }) => (
  <ReactTooltip {...restProps} place={place} effect={effect} className={className}>
    {text || children}
  </ReactTooltip>
)

export const IconWithTooltip = ({ icon, tooltipId, tooltipText, className }) => (
  <React.Fragment>
    <Icon icon={icon} className={className} data-tip data-for={tooltipId} />
    <Tooltip id={tooltipId} className='accented' place='bottom' effect='solid'>
      {tooltipText}
    </Tooltip>
  </React.Fragment>
)

export default Tooltip
