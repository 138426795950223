import React from 'react'

import classNames from 'classnames'
import { some, pick } from 'lodash'
import ReactDOM from 'react-dom'
import ReactTable from 'react-table-6'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import ActiveTable from '../ActiveTable'
import Avatar from '../Avatar'
import Modal from '../Modal'
import { checkOut, checkOutEventEmployees } from '../elements/actionDefinitions'
import { ActionsColumn } from '../elements/actions'

// TODO: use `react-table` v7
const EmployeesTable = ({ employees, store }) => {
  const columns = [
    {
      Header: t('activerecord.attributes.employee.first_name_last_name'),
      accessor: 'first_name_last_name',
      Cell: ({ original: { picture_url, first_name_last_name, id } }) => (
        <Avatar src={picture_url} text={first_name_last_name} resourcePath={url('v2/employees', id)} />
      )
    },
    {
      Header: t('activerecord.attributes.employee.company'),
      accessor: 'company_name'
    },
    {
      Header: t('activerecord.attributes.construction_object_event.kind'),
      accessor: 'kind'
    },
    {
      Header: t('activerecord.attributes.construction_object_event.event_time'),
      accessor: 'created_at'
    }
  ]

  const getTrProps = (_, rowInfo) =>
    rowInfo
      ? {
          className: classNames({
            'highlighted-danger': some(pick(rowInfo.original, 'checked_in'))
          })
        }
      : {}

  const getColumns = [
    ...columns,
    ActionsColumn(
      store,
      {
        row: [checkOut('nfc_check_log_id')]
      },
      true
    )
  ]

  return (
    <div className='nested-table border-bottom'>
      <ReactTable
        columns={getColumns}
        className='mb-0'
        data={employees}
        showPagination={false}
        defaultPageSize={employees.length}
        getTrProps={getTrProps}
      />
    </div>
  )
}

class ConstructionObjectEventsTable extends React.Component {
  state = { modalOpen: false }
  pathPrefix = 'v2/construction_object_events'

  toggleModal = () => {
    this.setState(ps => ({ modalOpen: !ps.modalOpen }))
  }

  tdProps = ({ row: { original } }) => ({
    onClick: () => this.setState({ modalOpen: true, selectedRow: original })
  })

  renderModal() {
    const { modalOpen, selectedRow } = this.state

    if (!modalOpen) return

    return ReactDOM.createPortal(
      <Modal
        className='modal-xl modal-dialog-centered'
        isOpen={modalOpen}
        onClose={this.toggleModal}
        title={selectedRow.kind}
      >
        <EmployeesTable employees={selectedRow.employees} store={this.props.store} />

        <div className='btn-container'>
          <button className='btn btn-secondary' onClick={this.toggleModal}>
            {t('close')}
          </button>
        </div>
      </Modal>,
      document.querySelector('.modal-container')
    )
  }

  actions = {
    row: [checkOutEventEmployees()]
  }

  render() {
    return (
      <React.Fragment>
        <ActiveTable
          {...this.props}
          actions={this.actions}
          pathPrefix={this.pathPrefix}
          getTdProps={this.tdProps}
          highlightedDangerBy='checked_in'
        />

        {this.renderModal()}
      </React.Fragment>
    )
  }
}

export default ConstructionObjectEventsTable
