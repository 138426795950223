import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

import { durationFromHHMM } from '../../../utils/duration'

dayjs.extend(isSameOrBefore)

export const handleTotalTime = () => {
  console.log('Deprecated! Total time will not be a an input!')
}

export const totalTimeDiffInMinutes = (startTime, endTime, breakTime) => {
  const startDateTime = dayjs(`2000-01-01 ${startTime}`)
  const breakMinutes = breakTime && durationFromHHMM(breakTime).asMinutes()
  let endDateTime = dayjs(`2000-01-01 ${endTime}`)

  if (endDateTime.isSameOrBefore(startDateTime, 'minute')) {
    endDateTime = endDateTime.add(1, 'day')
  }

  let minutes = endDateTime.diff(startDateTime, 'minutes')

  if (breakMinutes) {
    minutes -= breakMinutes
  }

  return minutes
}

export const formattedTotalTime = (startTime, endTime, breakTime) => {
  if (!startTime || !endTime) return '--:--'

  const minutes = totalTimeDiffInMinutes(startTime, endTime, breakTime)

  return formatMinutes(minutes)
}

const zeroPad = num => num.toString().padStart(2, '0')

export const formatMinutes = minutes => {
  const hours = Math.floor(minutes / 60)

  return `${zeroPad(hours)}:${zeroPad(minutes % 60)}`
}

// Deprecated
export const calculateTimeDiffDayjs = (startTime, endTime) => {
  const minutes = endTime.diff(startTime, 'minutes')

  return formatMinutes(minutes)
}

// Deprecated
export const formatTotalTime = value => {
  if (value.slice(0, 2) === '24') {
    return '24:00'
  } else if (parseInt(value.slice(0, 1)) > 2) {
    return `0${value.slice(0, 1)}:`
  } else if (parseInt(value.slice(0, 2)) > 24) {
    return '23:'
  } else if (parseInt(value.slice(3, 4)) > 5) {
    return value.slice(0, 2) + `:0${value[3]}`
  } else {
    return value
  }
}

// Deprecated
export const calculateTimeDiff = (startTime, endTime, totalTime) => {
  const startDateTime = dayjs(`2000-01-01 ${startTime}`)

  if (endTime) {
    if (endTime === startTime) return '24:00'

    let endDateTime = dayjs(`2000-01-01 ${endTime}`)

    if (dayjs(endDateTime).isBefore(dayjs(startDateTime), 'minute')) {
      endDateTime = endDateTime.add(1, 'day')
    }

    return calculateTimeDiffDayjs(startDateTime, endDateTime)
  } else {
    const midnight = dayjs('2000-01-01')
    const totalDateTime = dayjs(`2000-01-01 ${totalTime}`)

    let minutes = totalDateTime.diff(midnight, 'minutes') + startDateTime.diff(midnight, 'minutes')
    // 1440 minutes == 24 hours
    if (minutes >= 1440) {
      minutes -= 1440
    }

    return formatMinutes(minutes)
  }
}
