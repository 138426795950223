import React from 'react'

import { useController } from 'react-hook-form'

import { Select } from '../Fields'
import HookFieldWrapper from './HookFieldWrapper'

const SelectField = ({
  control,
  name,
  options,
  required,
  fieldIcon,
  label,
  className = '',
  wrapperClassName = '',
  onSelect = null,
  ...others
}) => {
  const {
    field: { value, onChange },
    fieldState
  } = useController({
    name,
    control
  })

  const handleSelect = event => {
    if (onSelect) onSelect(event)
    onChange(event)
  }

  return (
    <HookFieldWrapper
      name={name}
      required={required}
      className={wrapperClassName}
      icon={fieldIcon}
      label={label}
      fieldState={fieldState}
    >
      <Select {...others} name={name} items={options} onSelect={handleSelect} value={value} className={className} />
    </HookFieldWrapper>
  )
}

export default SelectField
