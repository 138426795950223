import React from 'react'

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import useTranslate from '../../../hooks/useTranslate'
import { url } from '../../../utils/urlGenerator'
import FormModal from '../../FormModal'
import ConstructionCasesTable from './ConstructionCasesTable'

const getConstructionCasesFromBis = async constructionObjectId => {
  return await axios
    .get(url('v2/vedludb/construction_cases', { construction_object_id: constructionObjectId }))
    .then(res => res.data)
}

const BisConstructionCasesModal = ({ onCaseClick, onClose, constructionObjectId }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.BisConstructionCasesModal')
  const { isPending, isLoading, data } = useQuery({
    queryKey: ['vedludb', 'construction_cases', constructionObjectId],
    queryFn: () => getConstructionCasesFromBis(constructionObjectId)
  })

  const loading = isPending || isLoading

  return (
    <FormModal title={t('.title')} className='modal-xl' onClose={onClose}>
      {loading ? (
        <div>{t('loading_data')}</div>
      ) : (
        <ConstructionCasesTable cases={data?.construction_cases} onCaseClick={onCaseClick} />
      )}
    </FormModal>
  )
}

export default BisConstructionCasesModal
