import React from 'react'

import { t } from '../../utils/i18n'
import Avatar from '../Avatar'
import Store from '../Store'
import TableContainer from '../TableContainer'
import ActivitiesFilter from './ActivitiesFilter'
import ActivitiesTable from './ActivitiesTable'

class ActivitiesTableContainer extends TableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'created_at', desc: true }],
    baseScope: this.props.base_scope
  })

  columns = [
    {
      header: t('activerecord.attributes.activity.user_id'),
      accessorKey: 'user',
      cell: ({ getValue, row: { original } }) => {
        const value = getValue()
        return value && <Avatar src={original.user_picture_url} text={value} />
      }
    },
    {
      header: t('activerecord.attributes.activity.action_type'),
      accessorKey: 'action_type',
      size: 120
    },
    {
      header: t('activerecord.attributes.activity.changed_item_with_additional_info'),
      accessorKey: 'changed_item_with_additional_info',
      size: 320
    },
    {
      header: t('activerecord.attributes.activity.created_at'),
      accessorKey: 'created_at'
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div>
        <ActivitiesFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <ActivitiesTable {...this.props} store={this.store} columns={this.columns} columnOptions={columnOptions} />
      </div>
    )
  }
}

export default ActivitiesTableContainer
