import React from 'react'

import MonthDaysRow from './MonthDaysRow'

const MonthByDays = ({ monthWeeks, weekDaysNames, days, disabled }) => {
  const rows = monthWeeks.map((weekName, i) => (
    <MonthDaysRow
      weekName={weekName}
      weekDaysNames={weekDaysNames}
      key={i}
      id={i}
      selected={days && days[i]}
      disabled={disabled}
    />
  ))

  return <div className='task-regularity-month-days-container'>{rows}</div>
}

export default MonthByDays
