import React from 'react'

import classNames from 'classnames'
import ReactDOM from 'react-dom'
import { useFormState } from 'react-hook-form'

import useUnsavedChangesConfirmation from '../../hooks/useUnsavedChangesConfirmation'
import Icon from '../Icon'
import Modal from '../Modal'
import styles from './styles.module.scss'

const FormModal = ({ children, sideBarContent, title = '', onClose, className }) => {
  const { isDirty } = useFormState()

  const handleClose = useUnsavedChangesConfirmation({ dirty: isDirty, onClose })

  return ReactDOM.createPortal(
    <Modal isOpen onClose={handleClose} withDefaultModalContent={false} className={classNames(styles.modal, className)}>
      <div className={styles.main}>
        {title && <div className={styles.formTitle}>{title}</div>}
        {children}
      </div>

      <button className={styles.closeButton}>
        <Icon icon='cross' onClick={handleClose} />
      </button>

      {sideBarContent && (
        <div className={styles.sidebar}>
          <div className={styles.stickyBlock}>{sideBarContent}</div>
        </div>
      )}
    </Modal>,
    document.querySelector('.modal-container')
  )
}

export default FormModal
