import React from 'react'

import ActiveTable from '../ActiveTable'
import { edit, remove, removeSelected } from '../elements/actionDefinitions'

class UserCompaniesTable extends React.Component {
  pathPrefix = 'v2/company_users'

  formParams = {
    context: 'user',
    company_user: { user_id: this.props.user_id }
  }

  actions = {
    row: [edit(this.pathPrefix, 'v2.company_users.contexts.user.edit.title', this.formParams), remove(this.pathPrefix)],
    header: [removeSelected(this.pathPrefix)]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        pathPrefix={this.pathPrefix}
        actions={this.actions}
        pathResourceId='company_id'
        translationPath={['company_users', 'contexts', 'user']}
        formParams={this.formParams}
        reloadPageOnSuccess={true}
        onRowClick='openEditForm'
      />
    )
  }
}

export default UserCompaniesTable
