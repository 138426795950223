import React from 'react'

import { useField, FieldArray } from 'formik'

import { t } from '../../../utils/i18n'
import Icon from '../../Icon'
import FormikFieldWrapper from './FormikFieldWrapper'
import TimeField from './TimeField'

export default props => {
  const { label, disabled, id, className } = props
  const [field] = useField(props)
  const { value, name } = field

  return (
    <div className={`form-field ${className}`}>
      {label && (
        <label className='form-label' htmlFor={id}>
          {label}
        </label>
      )}

      <FieldArray id={id} name={name}>
        {({ insert, remove }) => {
          return (
            <div className='array-field'>
              {value?.map((_timePeriod, index) => {
                const startFieldName = `${name}[${index}].start`
                const finishFieldName = `${name}[${index}].finish`

                return (
                  <FormikFieldWrapper key={index} name={`${name}[${index}]`} className='field-group-item'>
                    <div className='time-period d-flex'>
                      <TimeField name={startFieldName} disabled={disabled} className='p-0 m-0' />

                      <span className='time-period-seperator'>{t('up_to')}</span>

                      <TimeField name={finishFieldName} disabled={disabled} className='p-0 m-0' />

                      {!disabled && (
                        <div className='d-flex align-items-center'>
                          <Icon
                            className='ml-2 action'
                            icon='iconPlusCircle'
                            onClick={() => insert(index + 1, { start: '', finish: '' })}
                          />
                          {value.length > 1 ? (
                            <Icon className='ml-2 action' icon='iconTrash' onClick={() => remove(index)} />
                          ) : null}
                        </div>
                      )}
                    </div>
                  </FormikFieldWrapper>
                )
              })}
            </div>
          )
        }}
      </FieldArray>
    </div>
  )
}
