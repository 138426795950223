import React from 'react'

import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { t } from '../../../utils/i18n'
import { formatDuration } from '../../../utils/schedulerDuration'

const dayName = (weekDay, overnight) => {
  const name = capitalizeFirstLetter(t('date.day_names')[weekDay])

  if (overnight) {
    return `${name} - ${capitalizeFirstLetter(t('date.day_names')[(weekDay + 1) % 7])}`
  }

  return name
}

const dayDate = (date, overnight) => {
  const str = date.format('DD.MM.YYYY')

  if (overnight) {
    return `${str} - ${date.add(1, 'day').format('DD.MM.YYYY')}`
  }

  return str
}

const ShiftHeader = ({ date, weekDay, overnight, plannedDuration }) => (
  <div className='modal-body-header'>
    <div className='shift-day'>
      <div className='shift-day-name'>{dayName(weekDay, overnight)}</div>
      <div className='shift-day-date'>{dayDate(date, overnight)}</div>
    </div>
    {plannedDuration && (
      <div className='shift-total'>
        <div className='shift-total-text'>{t('activerecord.attributes.shift.registrations.total')}</div>
        <div className='shift-total-data'>
          <span className='shift-total-work-duration'>{formatDuration(plannedDuration)}</span>
        </div>
      </div>
    )}
  </div>
)

export default ShiftHeader
