import React, { createContext, useState } from 'react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import useTranslate from '../../../hooks/useTranslate'
import { Notificator } from '../../Notificator'
import ContractSelect from './ContractSelect'
import Contracts from './Contracts'
import NewRegistrationForm from './NewRegistrationForm'
import Payments from './Payments'
import Registration from './Registration'

const queryClient = new QueryClient()
export const VedludbContext = createContext()

const ConstructionObjectVedludb = ({ vedludb_ligums_id, construction_object_id, permissions }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.ConstructionObjectVedludb')
  const [ligumsId, setLigumsId] = useState(vedludb_ligums_id)
  const [registration, setRegistration] = useState(null)
  const [newForm, setNewForm] = useState(false)

  const vedludbContextValues = {
    ligumsId,
    registration,
    permissions,
    constructionObjectId: construction_object_id
  }

  return (
    <QueryClientProvider client={queryClient}>
      <VedludbContext.Provider value={vedludbContextValues}>
        <Notificator />
        <div className='mt-4 mx-4'>
          <div className='h2'>{t('.construction_site')}</div>
        </div>
        {permissions.edit_general && (
          <>
            <ContractSelect
              ligumsId={ligumsId}
              constructionObjectId={construction_object_id}
              setLigumsId={setLigumsId}
              setNewForm={setNewForm}
            />
            <hr className='mx-4 mb-4' />
            <div className='m-4 mb-5 pb-5'>
              {ligumsId && !newForm && (
                <Registration
                  ligumsId={ligumsId}
                  setRegistration={setRegistration}
                  constructionObjectId={construction_object_id}
                />
              )}
              {newForm && <NewRegistrationForm constructionObjectId={construction_object_id} />}
            </div>
          </>
        )}
        {/* use XNOR to safely load tables after registration has loaded */}
        {!!registration === permissions.edit_general && ligumsId && !newForm && (
          <div className='p-4'>
            <Contracts ligumsId={ligumsId} permissions={permissions} />
            <Payments title={t('.subtitles.payments')} />
          </div>
        )}
      </VedludbContext.Provider>
    </QueryClientProvider>
  )
}

export default ConstructionObjectVedludb
