import React, { memo, useState, useMemo, useRef, useContext } from 'react'

import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import { VariableSizeList as List, areEqual } from 'react-window'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

import { t } from '../../../utils/i18n'
import Avatar from '../../Avatar'
import Icon from '../../Icon'
import ShiftDayWeekendOverlay from '../ShiftDayWeekendOverlay'
import { AbsenceContext, MonthContext } from '../Shifts'
import CreateModal from './CreateModal'
import Edit from './Edit'
import { mergeAbsences, makeAbsenceMap } from './helpers'
import useAbsencePositionStyle from './useAbsencePositionStyle'

const EMPLOYEE_ROW_HEIGHT = 41
const EMPLOYEE_ROW_COUNT = 12
const MAX_EMPLOYEE_LIST_HEIGHT = EMPLOYEE_ROW_COUNT * EMPLOYEE_ROW_HEIGHT + EMPLOYEE_ROW_HEIGHT / 2

dayjs.extend(isToday)

export const AbsenceBar = ({ dayWidth, absence, day, policies }) => {
  const absenceRef = useRef()
  const { absence_type, date_from, date_to, id } = absence

  const style = useAbsencePositionStyle({ day, dayWidth, dateFrom: date_from, dateTo: date_to })

  return (
    <div className='absences__absence' ref={absenceRef} style={style} data-from={date_from} data-to={date_to}>
      {absence_type?.abbreviation}
      {id && policies?.absences.update && (
        <Edit absenceRef={absenceRef} absence={absence} policies={policies} dayWidth={dayWidth} day={day} />
      )}
    </div>
  )
}

const AbsenceDay = ({ day, dayWidth, absenceMap, policies, ...others }) => {
  const absence = absenceMap[day.format('YYYY-MM-DD')]

  return (
    <div className='absences__day' data-date={day.format('YYYY-MM-DD')} style={{ width: dayWidth }} {...others}>
      <ShiftDayWeekendOverlay day={day} width={dayWidth} />
      {absence && <AbsenceBar day={day} absence={absence} dayWidth={dayWidth} policies={policies} />}
      {day.isToday() && <div className='today-overlay' />}
    </div>
  )
}

const AbsenceWeek = ({ week, ...others }) => (
  <div className='absences__week'>
    {week.map((day, i) => (
      <AbsenceDay key={i} day={day} {...others} />
    ))}
  </div>
)

const EmployeeRow = memo(({ style, absences, weeks, dayWidth, policies }) => {
  const { month } = useContext(MonthContext)

  const { employee } = absences[0]

  const { id, first_and_last_name, position, picture_url } = employee

  const absenceMap = makeAbsenceMap(absences, month)

  return (
    <div className='employee-container d-flex' key={id} style={style} data-employee-id={id}>
      <div className='shift-employee-content'>
        <div className='employee-info'>
          <div className='employee-full-name text-truncate'>{first_and_last_name}</div>
          <div className='d-flex justify-content-end'>
            {position && <div className='employee-position ml-2 text-truncate'>{position}</div>}
          </div>
        </div>
        <div className='avatar-container'>
          <Avatar src={picture_url} />
        </div>
      </div>
      <div className='absences__days absences__days--for-employee'>
        {weeks.map((week, weekIndex) => (
          <AbsenceWeek key={weekIndex} week={week} dayWidth={dayWidth} absenceMap={absenceMap} policies={policies} />
        ))}
      </div>
    </div>
  )
}, areEqual)

const EmployeeAbsences = ({ weeks, dayWidth, policies }) => {
  const listRef = useRef({})

  const { employeeAbsences } = useContext(AbsenceContext)
  const orderedEmployeeKeys = Object.keys(employeeAbsences).sort()

  const renderEmployeeRow = useMemo(
    () =>
      ({ index, style, data }) =>
        (
          <EmployeeRow
            absences={data[orderedEmployeeKeys[index]]}
            index={index}
            weeks={weeks}
            dayWidth={dayWidth}
            style={style}
            listRef={listRef}
            policies={policies}
          />
        ),
    [weeks, dayWidth, orderedEmployeeKeys, policies]
  )

  const employeeCount = Object.keys(employeeAbsences).length

  const height = employeeCount < EMPLOYEE_ROW_COUNT ? employeeCount * EMPLOYEE_ROW_HEIGHT : MAX_EMPLOYEE_LIST_HEIGHT

  return (
    <SimpleBar className='absences__employees' style={{ height: height }}>
      {({ scrollableNodeRef, contentNodeRef }) => (
        <List
          width='100%'
          ref={listRef}
          height={height}
          itemCount={employeeCount}
          itemData={employeeAbsences}
          itemSize={() => EMPLOYEE_ROW_HEIGHT}
          className='list-container'
          outerRef={scrollableNodeRef}
          innerRef={contentNodeRef}
          overscanCount={EMPLOYEE_ROW_COUNT}
        >
          {renderEmployeeRow}
        </List>
      )}
    </SimpleBar>
  )
}

const Absences = ({ weeks, policies, width: dayWidth }) => {
  const [open, setOpen] = useState(false)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const { month } = useContext(MonthContext)
  const { absences, loading, addAbsences } = useContext(AbsenceContext)

  const absenceMap = mergeAbsences(absences, month)

  return (
    <div className='absences'>
      <div className='absences__summary'>
        <div className='absences__info' onClick={() => setOpen(!open)}>
          <div className='absences__heading'>{t('v2.absences.title')}</div>
          <div className='expander'>
            <i className={loading ? 'fa fa-spinner fa-spin' : `fa fa-sort-${open ? 'up' : 'down'}`} />
          </div>
        </div>
        <div className='absences__days'>
          {weeks.map((week, weekIndex) => (
            <AbsenceWeek key={weekIndex} week={week} dayWidth={dayWidth} absenceMap={absenceMap} />
          ))}
        </div>
      </div>
      {open && (
        <>
          <EmployeeAbsences weeks={weeks} dayWidth={dayWidth} policies={policies} />
          {policies.absences.create && (
            <div className='absences__footer'>
              <button
                className='p-0 d-flex align-items-center'
                onClick={() => setCreateModalOpen(true)}
                title={t('v2.absences.add_new')}
              >
                <Icon icon='iconPlusCircle' className='text-primary mr-2' />
                {t('v2.absences.add_new')}
              </button>
            </div>
          )}
        </>
      )}
      {createModalOpen && (
        <CreateModal
          isOpen={createModalOpen}
          addAbsences={addAbsences}
          policies={policies}
          onClose={() => setCreateModalOpen(false)}
        />
      )}
    </div>
  )
}

export default Absences
