import { useMemo } from 'react'

import { useCombobox } from 'downshift'

const multiselectReducer = (state, actionAndChanges) => {
  const { changes, type } = actionAndChanges
  switch (type) {
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
    case useCombobox.stateChangeTypes.ItemClick:
      return {
        ...changes,
        isOpen: true,
        inputValue: state.inputValue
      }
    case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
      return {
        ...changes,
        inputValue: state.inputValue
      }
    case useCombobox.stateChangeTypes.InputBlur:
      return {
        ...changes,
        inputValue: ''
      }
    default:
      return changes
  }
}

const singleReducer = (_state, actionAndChanges) => {
  const { changes, type } = actionAndChanges
  switch (type) {
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
    case useCombobox.stateChangeTypes.ItemClick:
    case useCombobox.stateChangeTypes.InputBlur:
    case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
      return {
        ...changes,
        inputValue: ''
      }
    default:
      return changes
  }
}

export default function useSelectReducer(isMulti) {
  return useMemo(() => (isMulti ? multiselectReducer : singleReducer), [isMulti])
}
