import React from 'react'

import { t } from '../../utils/i18n'
import ActiveTable from '../ActiveTable'
import Icon from '../Icon'
import Tooltip from '../Tooltip'
import { edit, checkOut, checkOutSelected, discard, discardSelected } from '../elements/actionDefinitions'

export const Identifiers = ({ identifierPresent, id }) => {
  if (!identifierPresent) return null

  const tooltipId = `guest-nfc-identifier-${id}`

  return (
    <React.Fragment>
      <Icon icon='checkWithNfc' className='mr-2 pb-1' data-tip data-for={tooltipId} />

      <Tooltip id={tooltipId} className='accented' place='bottom' effect='solid'>
        {t(`activerecord.attributes.guest.nfc_identifier`)}
      </Tooltip>
    </React.Fragment>
  )
}

class GuestsTable extends React.Component {
  actions = {
    row: [
      edit(this.props.pathPrefix, 'v2.guests.edit.title'),
      checkOut('latest_checked_in_check_log_id'),
      discard(this.props.pathPrefix)
    ],
    header: [checkOutSelected(this.props.pathPrefix), discardSelected(this.props.pathPrefix)]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        highlightedBy='currently_checked_in_duration'
        translationPath='guests'
        onRowClick='navigateToResource'
      />
    )
  }
}

export default GuestsTable
