import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Avatar from '../Avatar'
import { SimpleSelect } from '../FilterComponents'
import Permissions from '../Permissions'
import Store from '../Store'
import TableContainer from '../TableContainer'
import UsersFilter from '../Users/UsersFilter'
import ConstructionObjectUserNotificationSettings from '../elements/ConstructionObjectUserNotificationSettings'
import ConstructionObjectUsersTable from './ConstructionObjectUsersTable'

class ConstructionObjectUsersTableContainer extends TableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'user_last_activity_at', desc: true }]
  })

  columns = [
    {
      accessorKey: 'user_id',
      visible: false
    },
    {
      header: t('activerecord.attributes.user.full_name'),
      accessorKey: 'user_full_name',
      cell: ({ getValue, row: { original } }) => {
        const resourcePath = original.permissions.show && url('v2/users', original.user_id)

        return <Avatar src={original.picture_url} text={getValue()} resourcePath={resourcePath} />
      }
    },
    {
      header: t('activerecord.attributes.user.email'),
      accessorKey: 'user_email'
    },
    {
      header: t('activerecord.attributes.user.description'),
      accessorKey: 'user_description'
    },
    {
      header: t('activerecord.attributes.user.role'),
      accessorKey: 'role',
      sortable: false
    },
    {
      header: t('activerecord.attributes.construction_object_user.permissions'),
      accessorKey: 'permissions',
      sortable: false,
      cell: ({ row: { original } }) => (
        <Permissions object={original} type='construction_object_user' permissions={this.props.permissions} />
      )
    },
    {
      header: t('activerecord.attributes.construction_object_user.notification_settings'),
      accessorKey: 'notification_settings',
      sortable: false,
      cell: ({ row: { original } }) => <ConstructionObjectUserNotificationSettings constructionObjectUser={original} />
    },
    {
      header: t('activerecord.attributes.user.last_activity_at'),
      accessorKey: 'user_last_activity_at'
    }
  ]

  customFilters = () => {
    return (
      <>
        <SimpleSelect store={this.store} selectable='role' options={this.props.roles_options} />
        <SimpleSelect store={this.store} selectable='permission' options={this.props.permissions_options} />
      </>
    )
  }

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <UsersFilter
          {...this.props}
          store={this.store}
          customFilters={this.customFilters}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <ConstructionObjectUsersTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
        />
      </div>
    )
  }
}

export default ConstructionObjectUsersTableContainer
