import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../utils/i18n'
import { FilterContainer, SearchField, SimpleSelect, ImportButton } from '../FilterComponents'

class GuestsFilter extends React.Component {
  hiddenFilters = () => {
    const { store, construction_objects_options, state_options, identifiers_options } = this.props

    return (
      <React.Fragment>
        <SimpleSelect
          store={store}
          selectable='construction_object_id'
          options={construction_objects_options}
          isMulti={true}
        />
        <SimpleSelect store={store} selectable='state' dataContains='select-state-filter' options={state_options} />
        <SimpleSelect store={store} selectable='checkable_identifier' options={identifiers_options} />
      </React.Fragment>
    )
  }

  render() {
    const { store, import_url, importable, ...others } = this.props

    return (
      <FilterContainer store={store} hiddenFilters={this.hiddenFilters} {...others}>
        <SearchField store={store} placeholder={t('filters.search_guests')} />
        <ImportButton url={import_url} permitted={importable} />
      </FilterContainer>
    )
  }
}

export default observer(GuestsFilter)
