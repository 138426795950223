import React, { useState } from 'react'

import classNames from 'classnames'
import uniqBy from 'lodash/uniqBy'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'

import useTranslate from '../../../../hooks/useTranslate'
import { DateField, SelectField } from '../../../Forms/HookFields'
import Icon from '../../../Icon'
import ColumnToggler from './ColumnToggler'
import styles from './Filters.module.scss'

const ClearFiltersButton = () => {
  const { setFilters, reset, defaultValues } = useFormContext()

  const clearHandler = () => {
    reset({ values: { ...defaultValues } })
    setFilters({ ...defaultValues })
  }

  return (
    <div className={classNames(styles.filterButton, 'clear-filters-button')} onClick={clearHandler}>
      <i className='fa fa-times'></i>
    </div>
  )
}

const ToggleFiltersButton = ({ toggle }) => {
  const { t } = useFormContext()

  return (
    <div className={classNames(styles.filterButton, 'extend-filters-button')} onClick={toggle}>
      <div className='d-flex align-items-center'>
        <i className='fa fa-sliders mr-2'></i>
        {t('.toggler')}
      </div>
    </div>
  )
}

const SearchField = () => {
  const { setFilters, filters, t } = useFormContext()

  const handleChange = event => {
    setFilters({ ...filters, searchTerm: event.target.value })
  }

  return (
    <div className={classNames(styles.searchField, 'flex-grow-1')}>
      <i className='fa fa-search'></i>
      <input name='searchTerm' type='text' placeholder={t('.search')} onChange={handleChange} />
    </div>
  )
}

const ContractFilter = () => {
  const { payments, filters, setFilters, t } = useFormContext()
  const options = uniqBy(payments, 'ligumsId').map(payment => ({ label: payment.ligumsId, value: payment.ligumsId }))

  const handleSelect = v => {
    setFilters({ ...filters, ligumsId: v })
  }

  return (
    <SelectField
      className='w-100'
      name='ligumsId'
      placeholder={t('.contract_id')}
      options={options}
      onSelect={handleSelect}
      isClearable
    />
  )
}

const StatusFilter = () => {
  const { filters, setFilters } = useFormContext()
  const t = useTranslate('ConstructionObjects.Vedludb.PaymentsTable')
  const options = [
    { label: t('.statuses.waiting'), value: 0 },
    { label: t('.statuses.approved'), value: 1 },
    { label: t('.statuses.denied'), value: 2 }
  ]

  const handleSelect = v => {
    setFilters({ ...filters, maksajumaStatuss: v })
  }

  return (
    <SelectField
      className='w-100'
      name='maksajumaStatuss'
      placeholder={t('.filters.status')}
      options={options}
      onSelect={handleSelect}
      isClearable
    />
  )
}

// Sides in this context is meant by payer, receiver (maksatajs, sanemejs)
const PaymentSideFilter = ({ sideName }) => {
  const { filters, setFilters, payments, t } = useFormContext()

  // Change 'maksatajs' -> 'maksataja'
  let accessor = sideName.slice(0, -1) + 'a'

  const options = uniqBy(payments, `${accessor}RegistracijasNr`).map(p => ({
    label: p[`${accessor}Nosaukums`],
    value: p[`${accessor}RegistracijasNr`]
  }))

  const handleSelect = v => {
    const updatedFilters = { ...filters }
    updatedFilters[sideName] = v
    setFilters(updatedFilters)
  }

  return (
    <SelectField
      className='w-100'
      name={sideName}
      placeholder={t(`.${sideName}`)}
      options={options}
      onSelect={handleSelect}
      isClearable
    />
  )
}

const PaymentDateFilter = () => {
  const { setFilters, filters, t } = useFormContext()

  const handleChange = (attr, value) => {
    const updatedFilters = { ...filters }
    updatedFilters[`date${attr}`] = value
    setFilters(updatedFilters)
  }

  return (
    <div className={styles.dateIntervalWrapper}>
      <Icon icon='filterCalendar' />
      <DateField
        wrapperClassName='w-100'
        containerClassName='w-100'
        name={'dateFrom'}
        onChange={v => handleChange('From', v)}
        value={filters.dateFrom}
        placeholder={t('.date_start')}
      />
      <div>-</div>
      <DateField
        wrapperClassName='w-100'
        containerClassName='w-100'
        name={'dateTo'}
        onChange={v => handleChange('To', v)}
        value={filters.dateTo}
        placeholder={t('.date_end')}
      />
    </div>
  )
}

const PaymentTableFilters = props => {
  const t = useTranslate('ConstructionObjects.Vedludb.PaymentsTable.filters')
  const { defaultValues } = props
  const [showExtendedFilters, setShowExtendedFilters] = useState(false)
  const form = useForm({ defaultValues: { ...defaultValues } }) // Necessary to use Hook form components

  return (
    <FormProvider {...form} {...props} t={t}>
      <div className={classNames(styles.filtersContainer, 'payment-table-filters-wrapper')}>
        <div className={styles.filterRow}>
          <ColumnToggler {...props} />
          <ClearFiltersButton />
          <ToggleFiltersButton toggle={() => setShowExtendedFilters(!showExtendedFilters)} />
          <SearchField />
        </div>
        {showExtendedFilters && (
          <div className={classNames(styles.filterRow, 'extended-payment-filters')}>
            <PaymentDateFilter />
            <ContractFilter />
            <StatusFilter />
            <PaymentSideFilter sideName='maksatajs' />
            <PaymentSideFilter sideName='sanemejs' />
          </div>
        )}
      </div>
    </FormProvider>
  )
}

export default PaymentTableFilters
