import React, { useEffect, useCallback, useRef } from 'react'

import { formatTime as formatCleaveTime, registerCursorTracker } from 'cleave-zen'
import { useController } from 'react-hook-form'

import HookFieldWrapper from './HookFieldWrapper'

const formatTime = value => formatCleaveTime(value, { timePattern: ['h', 'm'] })

const TimeField = ({
  control,
  name,
  required,
  wrapperClassName = '',
  className = '',
  fieldIcon,
  label,
  bonusFormatter,
  ...others
}) => {
  const inputRef = useRef(null)
  const {
    field: { value, onChange, onBlur, ref },
    fieldState
  } = useController({
    name,
    control
  })

  useEffect(() => registerCursorTracker({ input: inputRef.current, delimiter: '*' }), [inputRef])

  const formatFinal = useCallback(value => (bonusFormatter ? bonusFormatter(value) : value), [bonusFormatter])

  const onFieldBlur = fieldValue => {
    let formattedValue = formatTime(fieldValue)

    if (fieldValue.length) {
      formattedValue = fieldValue.includes(':') ? fieldValue.padEnd(5, '0') : `${fieldValue}0:00`
    }

    formattedValue = formatFinal(formattedValue)
    inputRef.current.value = formattedValue

    onBlur(formattedValue)
  }

  const onFieldChange = fieldValue => {
    const formattedValue = formatTime(formatFinal(fieldValue))

    if (onChange) onChange(formattedValue)
  }

  return (
    <HookFieldWrapper
      name={name}
      required={required}
      className={wrapperClassName}
      icon={fieldIcon}
      label={label}
      fieldState={fieldState}
    >
      <div className='form-input-wrapper'>
        <input
          ref={e => {
            ref(e)
            inputRef.current = e
          }}
          {...others}
          id={name}
          name={name}
          value={value}
          className={`form-control ${className}`}
          placeholder='hh:mm'
          onBlur={({ target: { value } }) => onFieldBlur(value)}
          onChange={({ target: { value } }) => onFieldChange(value)}
          onFocus={({ target }) => target.select()}
        />
      </div>
    </HookFieldWrapper>
  )
}

export default TimeField
