import React from 'react'

import classNames from 'classnames'

import { t } from '../../utils/i18n'
import Icon from '../Icon'
import Tooltip from '../Tooltip'

const Error = ({ children, name, isTooltip }) =>
  isTooltip ? (
    <Tooltip id={name} className='accented' place='bottom' effect='solid'>
      {children}
    </Tooltip>
  ) : (
    <div className='form-field-error'>{children}</div>
  )

const FieldWrapper = ({
  name,
  fieldId,
  label,
  icon,
  required,
  className = '',
  children,
  errorInTooltip,
  labelClassName = '',
  error,
  hasError
}) => {
  const nameClass = name.replace(/\./g, '_')

  return (
    <div
      className={classNames('form-field', className, nameClass, {
        'form-field-invalid': hasError
      })}
      data-tip={errorInTooltip}
      data-for={name}
    >
      {label && (
        <label className={`form-label ${labelClassName}`} htmlFor={fieldId || name}>
          {icon && <Icon icon={icon} />}
          {label}&nbsp;
          {required && typeof label === 'string' && (
            <abbr data-required title={t('activerecord.errors.messages.required')}>
              *
            </abbr>
          )}
        </label>
      )}
      {children}
      {hasError && (
        <Error name={name} isTooltip={errorInTooltip}>
          {error}
        </Error>
      )}
    </div>
  )
}

export default FieldWrapper
