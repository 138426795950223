import React from 'react'

import useTranslate from '../../../hooks/useTranslate'
import Table from '../../Table'

const ConstructionCasesTable = ({ cases, onCaseClick, itemActions }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.ConstructionCasesTable')

  if (!cases) {
    return null
  }

  const columns = [
    {
      Header: t('.buvatlaujasNr'),
      accessor: 'buvatlaujasNr'
    },
    {
      Header: t('.objektaKadastraApzimejums'),
      accessor: 'objektaKadastraApzimejums'
    },
    {
      Header: t('.bisBuvniecibasLietasNr'),
      accessor: 'bisBuvniecibasLietasNr'
    },
    {
      Header: t('.nosaukums'),
      accessor: 'nosaukums'
    },
    {
      Header: t('.adrese'),
      accessor: 'adrese'
    },
    {
      Header: t('.ierosinatajs'),
      Cell: ({ resource }) => resource.ierosinatajs || resource.ierosinatajaNosaukums
    },
    {
      Header: t('.ierosinatajaRegistracijasNr'),
      accessor: 'ierosinatajaRegistracijasNr'
    }
  ]

  if (itemActions) {
    columns.push({ Header: '', Cell: ({ resource, id }) => itemActions(resource, id) })
  }

  return (
    <div data-contains='construction_cases'>
      <Table data={cases} columns={columns} withBorder withHover onRowClick={onCaseClick} />
    </div>
  )
}

export default ConstructionCasesTable
