import React from 'react'

import ActiveTable from '../ActiveTable'
import {
  edit,
  discard,
  editConsructionObjectTaskSubTask,
  discardConsructionObjectTaskSubTask,
  remoteCheckInTimeTrackable
} from '../elements/actionDefinitions'

class ConstructionObjectTasksTable extends React.Component {
  pathPrefix = 'v2/construction_object_tasks'

  formParams = {
    construction_object_task: { construction_object_id: this.props.construction_object_id }
  }

  actions = {
    row: [
      remoteCheckInTimeTrackable(this.pathPrefix, 'check_in'),
      edit(this.pathPrefix, 'v2.construction_object_tasks.edit.title', this.formParams),
      editConsructionObjectTaskSubTask(this.pathPrefix),
      discard(this.pathPrefix),
      discardConsructionObjectTaskSubTask(this.pathPrefix),
      remoteCheckInTimeTrackable('v2/construction_object_sub_tasks', 'check_in_sub_task')
    ]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        pathPrefix={this.pathPrefix}
        translationPath='construction_object_tasks'
        formParams={this.formParams}
        reloadPageOnSuccess={true}
        highlightedBy='has_active_time_entry'
      />
    )
  }
}

export default ConstructionObjectTasksTable
