import I18n from 'i18n-js'

export const t = (...args) => {
  const industry = document.querySelector('meta[name=industry]').content

  const [path, variables] = args

  const translationPathWithIndustry = `${industry}_industry.${path}`

  return I18n.t(translationPathWithIndustry, { defaults: [{ scope: path }], ...variables })
}
