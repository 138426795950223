import React from 'react'

import { useFormContext, useWatch } from 'react-hook-form'

import useTranslate from '../../../../hooks/useTranslate'
import { SelectField, TextField } from '../../../Forms/HookFields'

const PaymentContractWithSides = () => {
  const t = useTranslate('ConstructionObjects.Vedludb.PaymentForm.fields')
  const { allContracts, payment, formDisabled, permissions } = useFormContext()

  const selectedLigumsId = useWatch({
    name: 'ligumsId'
  })

  const selectedContract = allContracts.find(contract => contract.ligumsId === selectedLigumsId?.value)

  const payerProps = {
    value: selectedContract?.pasutitajs || payment?.maksatajaNosaukums || selectedContract?.pasutitajaNosaukums || ''
  }
  const receiverProps = {
    value:
      selectedContract?.apaksuznemejaNosaukums ||
      payment?.sanemejaNosaukums ||
      (selectedContract?.ligumsId && permissions.general_name) ||
      ''
  }

  const ligumsIdOptions = allContracts.map(contract => ({
    label: `${contract.ligumsId} (${contract.apaksuznemejaNosaukums || contract.pasutitajaNosaukums})`,
    value: contract.ligumsId
  }))

  return (
    <>
      <SelectField
        name='ligumsId'
        label={t('.ligumsId')}
        placeholder={t('.ligumsId')}
        options={ligumsIdOptions}
        disabled={formDisabled}
        required
      />
      <TextField name='payer' label={t('.payer')} placeholder={t('.payer')} disabled={true} inputProps={payerProps} />
      <TextField
        name='receiver'
        label={t('.receiver')}
        placeholder={t('.receiver')}
        disabled
        inputProps={receiverProps}
      />
    </>
  )
}

export default PaymentContractWithSides
