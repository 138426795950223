import React from 'react'

import { observer } from 'mobx-react'

import { Notifier } from '../utils/notifier'
import Icon from './Icon'

const Notification = ({ item }) => {
  const icon = { alert: 'circleCross', notice: 'checkMark' }[item.type]

  const handleOnClick = () => {
    Notifier.closeFlash(item.id)
  }

  return (
    <div className={`flash ${item.type}`}>
      <Icon icon={icon} className='icon' />
      <div className='flex-grow-1'>{item.message}</div>
      <Icon icon='cross' className='close' onClick={() => handleOnClick()} />
    </div>
  )
}

export const Notificator = observer(() => {
  const flashes = Notifier.flashes

  return (
    <div className='notificator-container'>
      {flashes.map((item, idx) => (
        <Notification item={item} key={idx} />
      ))}
    </div>
  )
})
