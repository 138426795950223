import React from 'react'

import classNames from 'classnames'

import { t } from '../utils/i18n'
import Tooltip from './Tooltip'

export default ({ object, type, permissions }) => {
  const tooltipId = `permissions-${type}-${object.id}`

  const roleSpans = permissions.map(permission => (
    <span className={classNames('permission', { active: object[permission] })} key={permission}>
      {tr(type, permission, 'short')}
    </span>
  ))

  return (
    <span className='permissions' data-tip data-for={tooltipId}>
      {roleSpans}

      <Tooltip id={tooltipId} className='accented' place='bottom' effect='solid'>
        {permissions.map(r => (
          <div key={r}>
            {tr(type, r, 'short')} - {tr(type, r)}
          </div>
        ))}
      </Tooltip>
    </span>
  )
}

const tr = (namespace, key, suffix = '') => {
  return t(`activerecord.attributes.${namespace}.${key}${suffix && `_${suffix}`}`)
}
