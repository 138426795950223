import React from 'react'

import ActiveTable from '../ActiveTable'
import { checkOut, checkIn, remove, removeSelected } from '../elements/actionDefinitions'

class GuestConstructionObjectsTable extends React.Component {
  pathPrefix = 'v2/construction_object_guests'

  formParams = {
    context: 'guest',
    construction_object_guest: { guest_id: this.props.guest_id }
  }

  actions = {
    row: [checkOut('latest_checked_in_check_log_id'), checkIn(this.pathPrefix), remove(this.pathPrefix)],
    header: [removeSelected(this.pathPrefix)]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        pathPrefix={this.pathPrefix}
        pathResourceId='construction_object_id'
        highlightedBy='guest_checked_in'
        inactiveBy='archived'
        translationPath={['construction_object_guests', 'contexts', 'guest']}
        formParams={this.formParams}
        reloadPageOnSuccess={true}
      />
    )
  }
}

export default GuestConstructionObjectsTable
