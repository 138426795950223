import React from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm, useFieldArray } from 'react-hook-form'
import yup from 'utils/yup'

import useTranslate from '../../../hooks/useTranslate'
import { t as tGlobal } from '../../../utils/i18n'
import FormModal from '../../FormModal'
import { TextField, SwitchField } from '../../Forms/HookFields'
import SubItem from '../../SubItem'

const validationSchema = yup.object({
  ierosinatajaRegistracijasNr: yup.string().nullable().required(),
  ierosinatajaNosaukums: yup.string().nullable().required(),
  nosaukums: yup.string().nullable().required(),
  adrese: yup.string().nullable().required(),
  objektaKadastraApzimejums: yup
    .array()
    .of(
      yup.object({
        value: yup
          .string()
          .nullable()
          .matches(/^\d{11}(\d{3})?$/, {
            message: tGlobal('components.ConstructionObjects.Vedludb.ManualConstructionCaseForm.kadastraFormats')
          })
          .required()
      })
    )
    .min(1)
})

const initialFormData = (data = null) => ({
  ierosinatajaRegistracijasNr: data?.ierosinatajaRegistracijasNr || '',
  ierosinatajaNosaukums: data?.ierosinatajaNosaukums || '',
  nosaukums: data?.nosaukums || '',
  adrese: data?.adrese || '',
  objektaKadastraApzimejums: data?.objektaKadastraApzimejums?.split(' | ')?.map(k => ({
    value: k
  })) || [{ value: '' }]
})

const prepareData = data => {
  const {
    fiziskaPersona: _fiziskaPersona,
    arvalstuUznemums: _arvalstuUznemums,
    objektaKadastraApzimejums,
    ...others
  } = data

  const objektaKadastraApzimejumsValues = objektaKadastraApzimejums.map(({ value }) => value).join(' | ')

  return {
    ...others,
    objektaKadastraApzimejums: objektaKadastraApzimejumsValues,
    pecKadastra: true
  }
}

const ManualConstructionCaseFormModal = ({ onSubmit, onClose, title, isSubmitting, data }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.ManualConstructionCaseForm')

  const formContext = useForm({
    values: initialFormData(data),
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema)
  })

  const {
    fields: cadastreFields,
    append,
    remove
  } = useFieldArray({
    control: formContext.control,
    name: 'objektaKadastraApzimejums'
  })

  const {
    handleSubmit,
    watch,
    formState: { errors: _errors }
  } = formContext

  const [arvalstuUznemums, fiziskaPersona] = watch(['arvalstuUznemums', 'fiziskaPersona'])

  let regNrLabelKey

  if (fiziskaPersona) {
    regNrLabelKey = '.ierosinatajaPersonasKods'
  } else if (arvalstuUznemums) {
    regNrLabelKey = '.ierosinatajaRegistracijasNrMitnesValsti'
  } else {
    regNrLabelKey = '.ierosinatajaRegistracijasNr'
  }

  return (
    <FormProvider {...formContext}>
      <FormModal title={title} onClose={onClose}>
        <form onSubmit={handleSubmit(formData => onSubmit(prepareData(formData)))} className='react-form'>
          <SwitchField name='fiziskaPersona' label={t('.fiziskaPersona')} wrapperClassName='mb-3' />
          {!fiziskaPersona && (
            <SwitchField name='arvalstuUznemums' label={t('.arvalstuUznemums')} wrapperClassName='mb-3' />
          )}
          <TextField name='ierosinatajaRegistracijasNr' label={t(regNrLabelKey)} wrapperClassName='mb-3' required />
          <TextField
            name='ierosinatajaNosaukums'
            label={t(fiziskaPersona ? '.ierosinatajaVardsUzvards' : '.ierosinatajaNosaukums')}
            wrapperClassName='mb-3'
            required
          />
          <TextField name='nosaukums' label={t('.nosaukums')} wrapperClassName='mb-3' required />
          <TextField name='adrese' label={t('.adrese')} wrapperClassName='mb-3' required />
          <div>
            <h5 className='form-label'>{t('.objektaKadastraApzimejums')}</h5>
            {cadastreFields.map((item, index) => (
              <SubItem
                onAdd={() => append('')}
                onRemove={cadastreFields.length > 1 ? () => remove(index) : undefined}
                key={item.id}
                className='mb-2'
              >
                <TextField name={`objektaKadastraApzimejums.${index}.value`} />
              </SubItem>
            ))}
          </div>
          <div className='mt-4 d-flex gap-3'>
            <button className='btn btn-primary' type='submit' disabled={isSubmitting}>
              {t('save')}
            </button>
            <button className='btn btn-secondary' onClick={onClose}>
              {t('cancel')}
            </button>
          </div>
        </form>
      </FormModal>
    </FormProvider>
  )
}

export default ManualConstructionCaseFormModal
