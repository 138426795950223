import React from 'react'

import Select from 'react-select'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import { REACT_SELECT_PROPS } from '../Select'

class ReportVersionSwitch extends React.Component {
  onVersionChange = option => window.location.assign(url(window.location.pathname, { report_version_id: option.value }))

  render() {
    const { version_options, default_option } = this.props

    return (
      <div>
        <Select
          {...REACT_SELECT_PROPS}
          placeholder={t('filters.select_report_version')}
          onChange={this.onVersionChange}
          options={version_options}
          defaultValue={default_option}
          isClearable={false}
        />
      </div>
    )
  }
}

export default ReportVersionSwitch
