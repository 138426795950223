import browserUpdate from 'browser-update'

import { t } from '../utils/i18n'

export default () => {
  // @see https://browser-update.org/customize.html
  // @see https://github.com/browser-update/browser-update/wiki/Details-on-configuration
  const params = {
    required: {
      c: -5,
      e: -2,
      f: -5,
      o: -3,
      s: 10.1,
      samsung: 7.0,
      vivaldi: 1.2
    },
    reminder: 0, // enabling this sets a cookie which hides the bar for one day!
    reminderClosed: 1,
    noclose: false,
    insecure: true,
    mobile: false,
    l: 'lv',
    text: t('old_browser_warning'),
    test: false
  }

  browserUpdate(params)
}
