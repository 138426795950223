import React from 'react'

import { t } from '../../../utils/i18n'
import ActiveTable from '../../ActiveTable'
import { BooleanIcon } from '../../elements/TableColumnsElements'
import { edit } from '../../elements/actionDefinitions'

class ExternalDevicesTable extends React.Component {
  store = this.props.stores
  pathPrefix = 'admin/external_devices'

  columns = [
    {
      header: t('activerecord.attributes.external_device.kind'),
      accessorKey: 'translated_kind'
    },
    {
      header: t('activerecord.attributes.external_device.device_model'),
      accessorKey: 'device_model'
    },
    {
      header: t('activerecord.attributes.external_device.account'),
      accessorKey: 'account_name'
    },
    {
      header: t('activerecord.attributes.external_device.uid'),
      accessorKey: 'uid'
    },
    {
      header: t('activerecord.attributes.external_device.latest_healthcheck_at'),
      accessorKey: 'latest_healthcheck_at'
    },
    {
      header: t('activerecord.attributes.external_device.latest_healthcheck_host'),
      accessorKey: 'latest_healthcheck_host'
    },
    {
      header: t('activerecord.attributes.external_device.construction_object'),
      accessorKey: 'construction_object',
      sortable: false
    },
    {
      header: t('activerecord.attributes.external_device.is_active'),
      accessorKey: 'is_active',
      cell: ({ getValue }) => <BooleanIcon checked={getValue()} />
    },
    {
      header: t('activerecord.attributes.external_device.direction'),
      accessorKey: 'translated_direction'
    },
    {
      header: t('activerecord.attributes.external_device.supports_fingerprints'),
      accessorKey: 'supports_fingerprints',
      cell: ({ getValue }) => <BooleanIcon checked={getValue()} />
    }
  ]

  actions = {
    row: [edit(this.pathPrefix, 'admin.external_devices.edit.title')]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        columns={this.columns}
        actions={this.actions}
        pathPrefix={this.pathPrefix}
        translationPath='external_devices'
        onRowClick='openEditForm'
      />
    )
  }
}

export default ExternalDevicesTable
