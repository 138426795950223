import React from 'react'

import { t } from 'i18n-js'

import Switch from '../../Forms/Fields/Switch'
import { useSchedulesContext } from '../contexts/SchedulesContext'

const ShiftTimeToggler = () => {
  const { setShiftFactualTime, shiftFactualTime } = useSchedulesContext()

  const handleOnClick = () => {
    setShiftFactualTime(!shiftFactualTime)
  }

  return (
    <div className='shift-time-toggler d-flex align-items-center mr-2'>
      <span className='mr-2'>{t('components.Scheduler.toggle_shift_hours')}</span>
      <Switch onClick={handleOnClick} />
    </div>
  )
}

export default ShiftTimeToggler
