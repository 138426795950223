import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../utils/i18n'
import { FilterContainer, SearchField, SimpleSelect } from '../FilterComponents'

class ConstructionObjectsFilter extends React.Component {
  hiddenFilters = () => {
    const { store, creatable, companies_options, state_options, other_filter_options, default_filter } = this.props

    return (
      <React.Fragment>
        <SimpleSelect store={store} selectable='company_id' options={companies_options} isMulti={true} />
        <SimpleSelect store={store} selectable='other' options={other_filter_options} />
        {creatable && (
          <SimpleSelect store={store} selectable='state' options={state_options} defaultFilter={default_filter} />
        )}
      </React.Fragment>
    )
  }

  render() {
    const { store, customFilters, ...others } = this.props

    return (
      <FilterContainer store={store} hiddenFilters={customFilters || this.hiddenFilters} {...others}>
        <SearchField store={store} placeholder={t('filters.search_construction_objects')} />
      </FilterContainer>
    )
  }
}

export default observer(ConstructionObjectsFilter)
