import React from 'react'

import Tooltip from '../Tooltip'

export default ({ id, items, className = '' }) => (
  <span className={`badge-container ${className}`}>
    {items.length > 0 ? (
      <>
        <span className='badge badge-secondary' data-tip data-for={id}>
          +{items.length}
        </span>
        <Tooltip id={id} place='right' effect='solid'>
          <div className='d-flex flex-column'>
            {items.map((item, index) => (
              <span key={index}>{item}</span>
            ))}
          </div>
        </Tooltip>
      </>
    ) : null}
  </span>
)
