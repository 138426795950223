import React, { useState, useContext } from 'react'

import classNames from 'classnames'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import localeData from 'dayjs/plugin/localeData'

import Search from '../Search'
import CompaniesSelector from './ListHeader/CompaniesSelector'
import ToggleCompaniesListButton from './ListHeader/ToggleCompaniesListButton'
import { ExceptionDaysContext } from './Shifts'

dayjs.extend(localeData)
dayjs.extend(isToday)

const Week = ({ week, width }) => {
  const weekDayNames = dayjs.localeData().weekdaysMin()
  const { loading, isHoliday } = useContext(ExceptionDaysContext)

  return (
    <div className='week'>
      <div className='dates'>
        {week.map((day, i) => {
          const today = day.isToday()
          const weekDay = day.day()
          const sunday = weekDay === 0
          const date = day.format('YYYY-MM-DD')
          const weekend = sunday || weekDay === 6

          return (
            <div key={i} style={{ width }} className={classNames('week-date', { sunday })}>
              <div
                style={{ width }}
                className={classNames('date', { today, sunday, weekend: weekend || (!loading && isHoliday(date)) })}
                data-date={date}
              >
                <div className='week-name'>{weekDayNames[weekDay]}</div>
                {day.get('date')}
              </div>
              <span className={classNames('day-border', { today })} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const ShiftListHeader = ({ weeks, width, search, setSearch, chosenCompaniesIds, setChosenCompaniesIds }) => {
  const [isListVisible, setIsListVisible] = useState(false)
  const toggleList = () => setIsListVisible(!isListVisible)

  return (
    <div className={classNames('shift-list-header')}>
      <div className='shift-search first-column d-flex align-items-center'>
        <Search value={search} onChange={setSearch} className='flex-grow-1 mr-1' />
        <ToggleCompaniesListButton onClick={toggleList} isActive={chosenCompaniesIds.length > 0} />
      </div>
      <div className='date-columns'>
        {weeks.map((week, index) => (
          <Week width={width} week={week} key={index} />
        ))}
      </div>
      {isListVisible && (
        <CompaniesSelector
          className='position-absolute filter-companies-list'
          chosenCompaniesIds={chosenCompaniesIds}
          setChosenCompaniesIds={setChosenCompaniesIds}
        />
      )}
    </div>
  )
}

export default ShiftListHeader
