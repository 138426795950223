import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../utils/i18n'
import { FilterContainer, SearchField, SimpleSelect } from '../FilterComponents'

class CompaniesFilter extends React.Component {
  hiddenFilters = () => {
    const { store, state_options } = this.props
    return <SimpleSelect store={store} selectable='state' options={state_options} dataContains='select-state-filter' />
  }

  render() {
    const { store, show_state_column, ...others } = this.props

    return (
      <FilterContainer store={store} hiddenFilters={show_state_column && this.hiddenFilters} {...others}>
        <SearchField store={store} placeholder={t('filters.search_companies')} />
      </FilterContainer>
    )
  }
}

export default observer(CompaniesFilter)
