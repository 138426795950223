import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../../utils/i18n'
import { DateIntervalFilter, FilterContainer, SearchField, SimpleSelect } from '../../FilterComponents'

class TasksFilter extends React.Component {
  hiddenFilters = () => {
    const {
      store,
      additional_columns,
      state_options,
      responsible_options,
      parent_user_group_options,
      author_options,
      task_group_options,
      client_label_options,
      device_label_options,
      default_filter,
      task_property_options
    } = this.props

    return (
      <React.Fragment>
        <SimpleSelect
          store={store}
          selectable='state'
          options={state_options}
          dataContains='select-state-filter'
          isMulti
          defaultFilter={default_filter}
        />
        <SimpleSelect
          store={store}
          selectable='responsible'
          options={responsible_options}
          isMulti
          dataContains='select-responsible-filter'
        />
        <SimpleSelect
          store={store}
          selectable='author_id'
          options={author_options}
          isMulti
          dataContains='select-author-filter'
        />
        <SimpleSelect
          store={store}
          selectable='task_group_id'
          options={task_group_options}
          isMulti
          dataContains='select-responsible-task-group-filter'
        />
        <SimpleSelect
          store={store}
          selectable='task_property'
          options={task_property_options}
          isMulti
          dataContains='select-task-property-filter'
        />
        <SimpleSelect
          store={store}
          selectable='parent_user_group_id'
          options={parent_user_group_options}
          isMulti
          dataContains='select-parent-user-group-filter'
        />
        {additional_columns.client && (
          <SimpleSelect
            store={store}
            selectable='client_label'
            options={client_label_options}
            isMulti
            dataContains='select-client-label-filter'
          />
        )}
        {additional_columns.device && (
          <SimpleSelect
            store={store}
            selectable='device_label'
            options={device_label_options}
            isMulti
            dataContains='select-device-label-filter'
          />
        )}
        <DateIntervalFilter
          store={store}
          label={t('filters.search_created_at')}
          accessKeys={{ start: 'created_start_date', end: 'created_end_date' }}
        />
        <DateIntervalFilter
          store={store}
          label={t('filters.search_starting_time')}
          accessKeys={{ start: 'starting_time_start_date', end: 'starting_time_end_date' }}
        />
        <DateIntervalFilter
          store={store}
          label={t('filters.search_deadline')}
          accessKeys={{ start: 'deadline_start_date', end: 'deadline_end_date' }}
        />
      </React.Fragment>
    )
  }

  render() {
    const { store, ...others } = this.props

    return (
      <FilterContainer store={store} hiddenFilters={this.hiddenFilters} {...others}>
        <SearchField store={store} placeholder={t('filters.search_tasks')} />
      </FilterContainer>
    )
  }
}

export default observer(TasksFilter)
