import React from 'react'

import Turbolinks from 'turbolinks'

import { getColumnOptions } from '../../utils/columnOptions'
import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Avatar from '../Avatar'
import Icon from '../Icon'
import IconCell from '../IconCell'
import Store from '../Store'
import TableContainer from '../TableContainer'
import { Identifiers } from './EmployeeIdentifiers'
import EmployeesFilter from './EmployeesFilter'
import EmployeesTable from './EmployeesTable'

class EmployeesTableContainer extends TableContainer {
  pathPrefix = 'v2/employees'

  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'currently_checked_in_duration', desc: false }],
    baseScope: this.props.base_scope
  })

  componentDidMount() {
    this.setState({
      columnOptions: getColumnOptions(this.columns, this.props.hidden_columns)
    })

    const deactivated = document.getElementById('deactivated')
    deactivated.addEventListener('click', this.onDeactivatedClick, false)
  }

  onDeactivatedClick = event => {
    event.preventDefault()
    if (this.props.active_selection === 'deactivated') Turbolinks.visit(url(window.location.pathname))
    else
      Turbolinks.visit(
        url(window.location.pathname, {
          active_selection: 'deactivated',
          base_scope: 'deactivated'
        })
      )
  }

  columns = [
    {
      header: t('activerecord.attributes.employee.first_name_last_name'),
      accessorKey: 'first_name_last_name',
      cell: ({
        getValue,
        row: {
          original: { id, picture_url, user }
        }
      }) => (
        <IconCell
          id={user}
          showIcon={user}
          tooltipText={user}
          iconComponent={tooltipId => <i className='fa fa-link ml-2 center-icon' data-tip data-for={tooltipId} />}
        >
          <Avatar src={picture_url} text={getValue()} resourcePath={url(this.pathPrefix, id)} />
        </IconCell>
      )
    },
    {
      header: t('activerecord.attributes.employee.identifiers'),
      accessorKey: 'identifiers_presence',
      sortable: false,
      cell: ({ getValue, row: { original } }) => <Identifiers id={original.id} {...getValue()} />,
      size: 90
    },
    {
      header: t('activerecord.attributes.employee.currently_in_object'),
      accessorKey: 'currently_checked_in_duration',
      cell: ({ getValue }) => <span className='font-weight-bold'>{getValue() || '-'}</span>,
      size: 90
    },
    {
      header: t('activerecord.attributes.employee.currently_checked_in_construction_object_name'),
      accessorKey: 'currently_checked_in_construction_object_name'
    },
    {
      header: t('activerecord.attributes.employee.personal_code'),
      accessorKey: 'personal_code',
      size: 160,
      cell: ({
        getValue,
        row: {
          original: { id, is_foreigner }
        }
      }) => (
        <IconCell
          id={id}
          showIcon={is_foreigner}
          tooltipText={t('activerecord.attributes.employee.is_foreigner')}
          iconComponent={tooltipId => <Icon icon='globe' className='ml-2 center-icon' data-tip data-for={tooltipId} />}
        >
          {getValue()}
        </IconCell>
      )
    },
    {
      header: t('activerecord.attributes.employee.code'),
      accessorKey: 'code',
      size: 100
    },
    {
      header: t('activerecord.attributes.employee.company'),
      accessorKey: 'company_name'
    },
    {
      header: t('activerecord.attributes.employee.consumed_time_in_period'),
      accessorKey: 'consumed_time_in_period',
      size: 90
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div>
        <EmployeesFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <EmployeesTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
          pathPrefix={this.pathPrefix}
        />
      </div>
    )
  }
}

export default EmployeesTableContainer
