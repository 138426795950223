import { t } from '../../../utils/i18n'
import Yup from '../../Forms/common/yup'
import { totalTimeDiffInMinutes } from './handleTotalTime'

export const breakTimeSchema = Yup.string()
  .nullable()
  .test('fits-in-shift', t('v2.shifts.index.form.errors.too_long_break_time'), (value, context) => {
    const break_time = value
    const { start_time, end_time } = context.parent
    if (!start_time || !end_time || !break_time) return true

    return totalTimeDiffInMinutes(start_time, end_time, break_time) > 0
  })
