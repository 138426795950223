class PasswordShowToggle {
  static selector = 'input[type=password]'

  constructor(passwordInput) {
    const inputParent = passwordInput.parentElement
    const inputWrapper = document.createElement('div')
    const iconElement = document.createElement('i')

    passwordInput.classList.add('switchable_password')
    iconElement.classList.add('fa', 'fa-eye', 'fa-lg', 'mr-2', 'password-show-toggle')
    inputWrapper.classList.add('password-input')
    iconElement.addEventListener('click', this.passwordToggleClick)

    inputParent.prepend(inputWrapper)
    inputWrapper.append(passwordInput, iconElement)
  }

  passwordToggleClick($event) {
    const iconElement = $event.target
    const inputElement = iconElement.parentElement.querySelector('input')

    inputElement.type = inputElement.type === 'password' ? 'text' : 'password'
    iconElement.classList.toggle('fa-eye-slash')
    iconElement.classList.toggle('fa-eye')
  }
}

export default PasswordShowToggle
