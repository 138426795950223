import { useState, useEffect, useMemo } from 'react'

import axios from 'axios'
import { getApiErrorMessage, showErrorModal } from 'utils/confirmations'
import { url } from 'utils/urlGenerator'

import { columnDates } from '../Shifts'
import { getDayParams, processRow } from '../shiftDayHelper'

export default ({ month, shift, setLoading, setHasEmployees, search, employeeAbsences }) => {
  const [employees, setEmployees] = useState([])
  const dates = useMemo(() => columnDates(month), [month])
  const days = dates.map(getDayParams)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    axios
      .get(url('v2/shifts', shift.id, 'employees', { month, search }))
      .then(({ data: { employees: employeeData } }) => {
        const employees = employeeData.map(employee => {
          const employeeId = `${employee.first_name_last_name}-${employee.id}`
          const absences = employeeAbsences[employeeId]

          return processRow({ shift, days, employee, absences })
        })
        setEmployees(employees)
        setHasEmployees(!!employees.length)
      })
      .catch(() => {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [shift.shift_employee_days, employeeAbsences])
  /* eslint-enable react-hooks/exhaustive-deps */

  return useMemo(() => ({ employees }), [employees])
}
