import React, { useState } from 'react'

import classNames from 'classnames'
import { t } from 'i18n-js'
import { useFormContext } from 'react-hook-form'

import { Select } from '../../../../Forms/Fields'
import styles from '../styles/TemplateSelector.module.scss'

const TasksTemplateSelector = () => {
  const { selectOptions, fetchByTemplate, submitting } = useFormContext()
  const items = selectOptions.templates
  const [template, setTemplate] = useState(null)

  const loadTemplate = () => {
    fetchByTemplate(template)
  }

  const selectProps = {
    value: template,
    onSelect: setTemplate,
    placeholder: t('v2.tasker.tasks.form.create_from_template'),
    className: 'w-75 pr-2 template-select',
    disabled: submitting,
    items
  }

  return (
    <div className='mb-3 mt-1'>
      <div className='d-flex w-100 align-items-center'>
        <Select {...selectProps} />
        <button
          className={classNames('btn btn-primary w-25', styles.templateLoadButton)}
          onClick={loadTemplate}
          disabled={submitting || !template}
          title={t('load')}
        >
          {t('load')}
        </button>
      </div>
    </div>
  )
}

export default TasksTemplateSelector
