import React from 'react'

import { useWatch } from 'react-hook-form'

import useTranslate from '../../../hooks/useTranslate'
import { TimeField } from '../../Forms/HookFields'
import { formattedTotalTime } from '../utils/handleTotalTime'

const TimeFields = ({ control, initialValues }) => {
  const t = useTranslate('Scheduler.Forms.TimeFields')

  const startTime = useWatch({
    control,
    name: 'start_time',
    defaultValue: initialValues.start_time
  })
  const endTime = useWatch({
    control,
    name: 'end_time',
    defaultValue: initialValues.end_time
  })
  const breakTime = useWatch({
    control,
    name: 'break_time',
    defaultValue: initialValues.break_time
  })

  const totalTime = formattedTotalTime(startTime, endTime, breakTime)

  return (
    <div className='form-row align-items-start'>
      <div className='time-fields'>
        <div className='d-flex align-items-baseline'>
          <TimeField name='start_time' control={control} label={t('.start_time')} wrapperClassName='mb-0' />

          <TimeField name='end_time' control={control} label={t('.end_time')} wrapperClassName='mb-0' />
          <TimeField name='break_time' control={control} label={t('.break_time')} wrapperClassName='mb-0' />
          <div className='form-field mb-0'>
            <label className='form-label'>{t('.total_time')}</label>
            <div className='form-input-wrapper'>
              <div className='form-control-plaintext ml-3 font-weight-bold' data-contains='total_time'>
                {totalTime}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TimeFields
