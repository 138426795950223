import { useMemo, useCallback } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import Turbolinks from 'turbolinks'

import { getApiErrorMessage, showErrorModal } from '../../../../utils/confirmations'
import { url } from '../../../../utils/urlGenerator'
import { formatValuesForSubmit } from '../../../Forms/Formatters/submit'
import { useSchedulesContext } from '../../contexts/SchedulesContext'
import { valueFormats, validationSchema, dataToForm } from './helpers'

const useShiftForm = ({ shift, schedule, metadata, closeModal, employee }) => {
  const { manageMultipleSchedules } = useSchedulesContext()

  const { dateIso: date, month } = metadata

  const sendRequest = useCallback(
    (values, status) => {
      const subjectUrl = url(`v2/schedules/shifts`, shift?.id)

      axios({
        method: shift ? 'put' : 'post',
        url: subjectUrl,
        data: {
          month,
          shift: {
            ...formatValuesForSubmit(values, valueFormats),
            status,
            date,
            employee_id: employee.id,
            schedule_id: schedule.id
          }
        }
      })
        .then(({ data }) => {
          closeModal()
          manageMultipleSchedules(data.rows)
        })
        .catch(() => {
          showErrorModal({
            title: getApiErrorMessage(null),
            cancelAction: Turbolinks.visit
          })
        })
    },
    [employee.id, schedule.id, closeModal, shift, manageMultipleSchedules, month, date]
  )

  const formData = useMemo(() => dataToForm({ shift, schedule, date }), [shift, schedule, date])

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formData,
    mode: 'onSubmit'
  })

  return { form, sendRequest, formData }
}

export default useShiftForm
