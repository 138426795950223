import React from 'react'

import ActiveTable from '../ActiveTable'
import { edit, destroy } from '../elements/actionDefinitions'

class ReportsTable extends React.Component {
  pathPrefix = 'v2/reports'

  actions = {
    row: [edit(this.pathPrefix, 'v2.reports.edit.title'), destroy(this.pathPrefix)]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        pathPrefix={this.pathPrefix}
        translationPath='reports'
        onRowClick='navigateToResource'
        dangerBy='invalid'
      />
    )
  }
}

export default ReportsTable
