import React from 'react'

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import useTranslate from '../../../hooks/useTranslate'
import { formatDate } from '../../../utils/formatters'
import { formatCurrency } from '../../../utils/formatters'
import { url } from '../../../utils/urlGenerator'
import Modal from '../../Modal'
import Table from '../../Table'

const getHistoryFromBis = async ligumsId => {
  return await axios.get(url('v2/vedludb/registrations', ligumsId, 'history')).then(res => res.data?.history)
}

const History = ({ onClose, ligumsId }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.History')
  const { isPending, isLoading, data } = useQuery({
    queryKey: ['vedludb', 'registration', 'history', ligumsId],
    queryFn: () => getHistoryFromBis(ligumsId)
  })

  const loading = isPending || isLoading

  const versions = [].concat(data)

  const columns = [
    {
      Header: t('.versija'),
      accessor: 'versija',
      className: 'text-right'
    },
    {
      Header: t('.izmainuDatums'),
      Cell: ({ resource }) => formatDate(resource.izmainuDatums)
    },
    {
      Header: t('.izmainuVeicejs'),
      accessor: 'izmainuVeicejs'
    },
    {
      Header: t('.statuss'),
      accessor: 'statuss'
    },
    {
      Header: t('.buvdarbuLigumaDatums'),
      Cell: ({ resource }) => formatDate(resource.buvdarbuLigumaDatums)
    },
    {
      Header: t('.buvdarbuLigumaSumma'),
      Cell: ({ resource }) => formatCurrency(resource.buvdarbuLigumaSumma),
      className: 'text-right text-nowrap'
    },
    {
      Header: t('.pasutitajaNosaukums'),
      accessor: 'pasutitajaNosaukums'
    },
    {
      Header: t('.pasutitajaRegistracijasNr'),
      accessor: 'pasutitajaRegNr'
    },
    {
      Header: t('.edlusNosaukums'),
      accessor: 'edlusNosaukums'
    },
    {
      Header: t('.edlusParzinis'),
      accessor: 'edlusParzinis'
    },
    {
      Header: t('.pamatojums'),
      accessor: 'pamatojums'
    }
  ]

  return (
    <Modal isOpen title={t('.title')} className='modal-xl' onClose={onClose}>
      <div className='table-responsive'>
        <Table columns={columns} data={versions} isLoading={loading} withBorder withHover />
      </div>
    </Modal>
  )
}

export default History
