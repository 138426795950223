import React, { useState, useRef, useEffect, useCallback } from 'react'

import axios from 'axios'
import classNames from 'classnames'
import { useCombobox } from 'downshift'

import Avatar from '../../../Avatar'
import { OptionColorAvatar } from '../../../Options'
import SelectOptions from './SelectOptions'
import SelectedItems from './SelectedItems'
import useSelectReducer from './reducers'

export const SelectContext = React.createContext({
  inputItems: [],
  selectedItems: [],
  withImages: false,
  isMulti: false,
  highlightedIndex: 0,
  getItemProps: () => {}
})

const SelectAvatar = ({ selectedItems }) =>
  selectedItems.color ? (
    <OptionColorAvatar color={selectedItems.color} />
  ) : (
    <Avatar src={selectedItems.image_src || selectedItems.imageSrc} />
  )

const Select = props => {
  const {
    withImages,
    onSelect,
    getSelectedItemProps,
    getDropdownProps,
    isMulti,
    isClearable,
    disabled,
    name,
    optionsURL,
    value: selectedItems,
    onSelectAll,
    label,
    renderOptionLabel,
    formatSelectedItem,
    optionsFooter,
    withoutBlank,
    placeholder,
    className,
    creatable,
    showCountInfo = true
  } = props

  const [items, setItems] = useState(props.items || [])
  const [inputItems, setInputItems] = useState(props.items || [])
  const listRef = useRef()
  const inputRef = useRef()

  const loadItems = useCallback(async () => {
    const { data } = await axios.get(optionsURL)

    setItems(data)
    setInputItems(data)
  }, [optionsURL])

  useEffect(
    function updateItemsFromProps() {
      if (!optionsURL) {
        setItems(props.items || [])
        setInputItems(props.items || [])
      }
    },
    [props.items, optionsURL]
  )

  useEffect(() => {
    if (optionsURL) {
      loadItems()
    }
  }, [optionsURL, loadItems])

  const stateReducer = useSelectReducer(isMulti)

  useEffect(() => {
    if (withoutBlank && !selectedItems) {
      onSelect(items[0])
    }
  }, [withoutBlank, selectedItems, onSelect, items])

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    openMenu,
    closeMenu,
    inputValue,
    highlightedIndex,
    selectItem
  } = useCombobox({
    itemToString: item => (item ? item.value : ''),
    selectedItem: selectedItems,
    items: inputItems,
    onSelectedItemChange: ({ selectedItem }) => {
      if (Array.isArray(selectedItem)) {
        onSelectAll(selectedItem)
      } else {
        onSelect(selectedItem)
      }
      if (!isMulti) {
        inputRef.current.blur()
      }
    },
    onInputValueChange: ({ inputValue: newValue }) => {
      const newInputItems = items.filter(({ label, search_keywords }) => {
        const lowerCaseInput = newValue.toLowerCase()
        const matchable = `${label} ${search_keywords || ''}`

        return matchable.toLowerCase().includes(lowerCaseInput)
      })

      if (creatable && !newInputItems.length) {
        newInputItems.push({ value: newValue, label: newValue, creatable: true })
      }

      setInputItems(newInputItems)
    },
    stateReducer
  })

  const contextValue = {
    inputItems,
    selectedItems,
    withImages,
    isMulti,
    highlightedIndex,
    getItemProps,
    getSelectedItemProps,
    onSelect,
    selectItem,
    name,
    inputValue,
    renderOptionLabel,
    optionsFooter
  }

  const getSimpleSelectInputProps = () => ({
    placeholder: selectedItems
      ? selectedItems.label && (formatSelectedItem ? formatSelectedItem(selectedItems) : selectedItems.label)
      : placeholder || undefined,
    ref: inputRef
  })

  const getMultiselectInputProps = () => ({
    ...getDropdownProps({ preventKeyAction: isOpen, ref: inputRef }),
    placeholder: ((!selectedItems || !selectedItems.length) && placeholder) || undefined
  })

  const inputProps = {
    ...(isMulti ? getMultiselectInputProps() : getSimpleSelectInputProps()),
    onClick: isOpen ? closeMenu : openMenu,
    value: inputValue || '',
    disabled,
    name,
    id: name,
    className: 'text-truncate'
  }

  const displayAvatar = !isMulti && selectedItems && withImages

  const countInfo =
    isMulti && showCountInfo ? (
      <div className='form-label-right'>
        {selectedItems.length} / {items.length}
      </div>
    ) : null

  const completeInputProps = getInputProps(inputProps)

  const handleInputWrapperClick = () => {
    if (isOpen) {
      inputRef?.current.blur()
      closeMenu()
    } else if (!disabled) {
      openMenu()
      inputRef?.current.focus()
    }
  }

  const menuProps = getMenuProps()

  return (
    <SelectContext.Provider value={contextValue}>
      {countInfo}
      <div className={`form-select-wrapper ${className}`} onKeyDown={e => e.nativeEvent.stopImmediatePropagation()}>
        <div className={classNames('select-field', { open: isOpen, disabled })}>
          <div
            {...getComboboxProps()}
            className={classNames('input-wrapper', { 'with-selected-item': !isMulti && selectedItems })}
            onClick={handleInputWrapperClick}
          >
            {displayAvatar ? <SelectAvatar selectedItems={selectedItems} /> : null}
            <SelectedItems getSelectedItemProps={getSelectedItemProps} label={label || placeholder} />
            <input {...completeInputProps} />
            {!isMulti && isClearable && selectedItems && (
              <div className='clear-icon' onClick={() => onSelect()}>
                <i className='fa fa-times' />
              </div>
            )}
            <div {...getToggleButtonProps({ disabled })} className='toggle-arrow'>
              <i className={`fa fa-caret-${isOpen ? 'up' : 'down'}`} />
            </div>
          </div>
          <div {...menuProps} className={classNames('options-menu', { open: isOpen })}>
            {isOpen && <SelectOptions listRef={listRef} onMouseLeave={menuProps.onMouseLeave} />}
          </div>
        </div>
      </div>
    </SelectContext.Provider>
  )
}

export default Select
