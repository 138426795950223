import React from 'react'

import axios from 'axios'
import classNames from 'classnames'
import Turbolinks from 'turbolinks'

import { showConfirmation, getApiErrorMessage } from '../../../utils/confirmations'
import { t } from '../../../utils/i18n'
import { url } from '../../../utils/urlGenerator'

class TimeTracker extends React.Component {
  constructor(props) {
    super(props)

    const { id, started_at } = props.time_entry || {}

    this.startedAt = started_at && new Date(started_at)

    this.state = {
      inForbiddenPeriod: false,
      seconds: started_at ? this.consumedTimeInSeconds() : 0,
      started: !!started_at,
      timeEntryId: id,
      errorMessage: null
    }

    if (started_at) this.startTimer()
  }

  componentDidMount() {
    // Automatically enable `play` button when the `forbidden_time_period` has started/ended
    this.trackingInterval = setInterval(() => this.setState({ inForbiddenPeriod: this.inForbiddenPeriod }), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.trackingInterval)
  }

  startTimer = () => {
    this.interval = setInterval(() => {
      if (this.inForbiddenPeriod) {
        this.setState({ started: false, seconds: 0 })

        clearInterval(this.interval)
      } else {
        this.setState({ seconds: this.consumedTimeInSeconds() })
      }
    }, 1000)
  }

  get inForbiddenPeriod() {
    const { forbidden_periods } = this.props

    if (!forbidden_periods?.length) return false

    const currentTime = new Date()
    const secSinceMidnight = (currentTime.getTime() - currentTime.setHours(0, 0, 0, 0)) / 1000

    const isInRange = (secs, range) => {
      let [start, finish] = range

      if (start > finish) {
        return start > secs && secs < finish
      }

      return start < secs && secs < finish
    }

    return forbidden_periods.some(period => isInRange(secSinceMidnight, period))
  }

  get isButtonDisabled() {
    return this.inForbiddenPeriod || !!this.state.errorMessage || this.props.disabled
  }

  consumedTimeInSeconds() {
    return Math.floor((new Date() - this.startedAt) / 1000)
  }

  toggleButton = () => {
    if (this.isButtonDisabled) return

    this.state.started ? this.pause() : this.start()
  }

  confirmNewTimeEntry = (id, title) => {
    const link = (
      <a href={url('v2/tasker/tasks', id)} target='_blank' rel='noreferrer'>
        {title}
      </a>
    )

    showConfirmation({
      isOpen: true,
      closeOnConfirm: true,
      onConfirm: () => this.start(true),
      text: (
        <>
          {t('confirm_start_time_entry_beginning')} {link}, {t('confirm_start_time_entry_end')}
        </>
      ),
      confirmButton: { title: t('start'), type: 'success' }
    })
  }

  start = async (confirmed = false) => {
    try {
      const params = { task_id: this.props.task_id, confirmed }
      const { data, status } = await axios.post(this.props.path, params)

      if (data.active_time_entry) {
        const { task_id, title } = data

        this.confirmNewTimeEntry(task_id, title)
      } else {
        const { id, started_at } = data
        this.startedAt = new Date(started_at)

        this.setState({ started: true, timeEntryId: id, seconds: 0, errorMessage: null })

        status === 255 ? Turbolinks.visit() : this.startTimer()
      }
    } catch ({ response }) {
      this.setState({ errorMessage: getApiErrorMessage(response) })
    }
  }

  pause = async () => {
    try {
      await axios.put(`${this.props.path}/${this.state.timeEntryId}/manual_close`, { task_id: this.props.task_id })

      clearInterval(this.interval)
      this.setState({ started: false, errorMessage: null })
    } catch ({ response }) {
      this.setState({ errorMessage: getApiErrorMessage(response) })
    }
  }

  formatedUnit(unit) {
    return `0${unit}`.slice(-2)
  }

  formatedDuration() {
    let initSeconds = this.state.seconds

    let hours = Math.floor(initSeconds / 3600)
    let minutes = Math.floor((initSeconds / 60) % 60)
    let seconds = initSeconds - hours * 3600 - minutes * 60

    return `${this.formatedUnit(hours)} : ${this.formatedUnit(minutes)} : ${this.formatedUnit(seconds)}`
  }

  renderTimer() {
    return <div className='timer'>{this.formatedDuration()}</div>
  }

  renderTrackerInfo() {
    if (this.state.errorMessage) {
      return <div className='timer-error-msg'>{this.state.errorMessage}</div>
    } else if (this.props.disabled_message) {
      return <div className='timer-error-msg'>{this.props.disabled_message}</div>
    } else if (this.inForbiddenPeriod) {
      return <div className='timer-error-msg'>{t('v2.tasker.tasks.time_tracker.unavailable')}</div>
    } else {
      return this.renderTimer()
    }
  }

  render() {
    const { started } = this.state

    return (
      <>
        <div
          className={classNames('btn btn-primary', { pause: started, disabled: this.isButtonDisabled })}
          onClick={this.toggleButton}
        >
          <span className='icon' />
          <span className='icon-text'>{t(`v2.tasker.tasks.show.${started ? 'pause' : 'start'}`)}</span>
        </div>
        {this.renderTrackerInfo()}
      </>
    )
  }
}

export default TimeTracker
