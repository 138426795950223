import React, { useState, useEffect, useCallback } from 'react'

import axios from 'axios'
import ReactDOM from 'react-dom'

import useTranslate from '../../../hooks/useTranslate'
import { getApiErrorMessage, showErrorModal } from '../../../utils/confirmations'
import { url } from '../../../utils/urlGenerator'
import Modal from '../../Modal'
import Tooltip from '../../Tooltip'
import ShiftForm from '../Forms/ShiftForm'
import FormToggler from '../common/FormToggler'
import ShiftHeader from '../common/ShiftHeader'
import ShiftModalHeader from '../common/ShiftModalHeader'
import Registrations from './Registrations'
import ShiftErrors from './ShiftErrors'
import ShiftStats from './ShiftStats'

const ShiftModal = ({ shift, closeModal, employee, metadata, schedule, hasAbsence }) => {
  const [loading, setLoading] = useState(false)
  const [overModalData, setOverModalData] = useState(null)
  const [registrations, setRegistrations] = useState([])
  const [dayData, setDayData] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const t = useTranslate('Scheduler.ShiftModal')

  const { dateIso, weekDay, date, future } = metadata

  const loadRegistrations = useCallback(() => {
    setLoading(true)

    const params = {
      date: dateIso,
      shift_id: shift?.id,
      schedule_id: schedule.id
    }

    const requestUrl = 'v2/schedules/shift_registrations'

    axios
      .get(url(requestUrl, params))
      .then(({ data: { registrations, day_data } }) => {
        setRegistrations(registrations)
        setDayData(day_data)
        setLoading(false)
      })
      .catch(() => {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      })
  }, [dateIso, schedule.id, shift])

  useEffect(() => {
    if (!future && shift) {
      loadRegistrations()
    }
  }, [future, shift, loadRegistrations])

  if (overModalData) {
    const { props, Component } = overModalData
    return <Component {...props} />
  }

  const forceShowForm = future || !shift

  const togglerDisabled = shift?.unscheduled && hasAbsence

  return ReactDOM.createPortal(
    <Modal
      isOpen
      className='modal-lg modal-dialog-centered modal--new'
      onClose={closeModal}
      header={<ShiftModalHeader shift={shift} schedule={schedule} employee={employee} />}
    >
      <>
        <ShiftHeader
          date={date}
          weekDay={weekDay}
          overnight={shift && !shift.single_day_shift}
          plannedDuration={dayData.planned_duration}
        />
        <div className='primary-delimiter'></div>
        {!!dayData.errors?.length && <ShiftErrors errors={dayData.errors} />}
        <Registrations
          registrations={registrations}
          loading={loading}
          dayData={dayData}
          setRegistrations={setRegistrations}
          loadRegistrations={loadRegistrations}
          setOverModalData={setOverModalData}
        />
        {!forceShowForm && (
          <>
            <hr className='delimiter w-auto mt-4' />
            <div className='d-flex justify-content-between mt-2'>
              <FormToggler
                open={showForm}
                onClick={() => setShowForm(opn => !opn)}
                shift={shift}
                schedule={schedule}
                metadata={metadata}
                disabled={togglerDisabled}
                data-tip
                data-for='shift-form-toggler'
              >
                {t(`.edit_shift`)}
              </FormToggler>
              {togglerDisabled && (
                <Tooltip id='shift-form-toggler' place='top'>
                  {t('.absence_present')}
                </Tooltip>
              )}
              <ShiftStats dayData={dayData} />
            </div>
          </>
        )}
        {(showForm || forceShowForm) && (
          <ShiftForm
            shift={shift}
            schedule={schedule}
            employee={employee}
            closeModal={closeModal}
            metadata={metadata}
          />
        )}
      </>
    </Modal>,
    document.querySelector('.modal-container')
  )
}

export default ShiftModal
