const fitFigureInMap = (map, points) => {
  const bounds = getBoundsFromPolygon(points)

  if (!bounds.isEmpty()) {
    if (bounds.size === 1) {
      const { lat, lng } = bounds.getCenter()

      bounds.extend(new google.maps.LatLng(lat() + 0.01, lng() + 0.01))
      bounds.extend(new google.maps.LatLng(lat() - 0.01, lng() - 0.01))
    }

    map.fitBounds(bounds)
  }
}

const getBoundsFromPolygon = points => {
  const bounds = createBounds()

  points.forEach(point => bounds.extend(point))

  return bounds
}

const createBounds = () => {
  return new Proxy(new google.maps.LatLngBounds(), {
    get(target, method) {
      if (method === 'extend') {
        !target.size ? (target.size = 1) : (target.size += 1)
      }

      return Reflect.get(...arguments)
    }
  })
}

export default fitFigureInMap
