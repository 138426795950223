import React from 'react'

import { t } from 'i18n-js'
import { useFormContext } from 'react-hook-form'

import WeekFields from '../../../TaskRegularityComponents/WeekFields'
import SectionTitle from '../../SectionTitle'

const RegularityRepeatWeekdaysSelect = () => {
  const { selectOptions, submitting, disableRegularity, getValues } = useFormContext()
  const { week_days_names } = selectOptions.regularity
  const days = getValues().regularity.days

  return (
    <div>
      <SectionTitle>{t('v2.tasker.tasks.form.regularity.week_days')}</SectionTitle>
      <WeekFields
        weekDaysNames={week_days_names}
        days={days}
        disabled={submitting || disableRegularity}
        withLabel={false}
      />
    </div>
  )
}

export default RegularityRepeatWeekdaysSelect
