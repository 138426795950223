import React from 'react'

import Icon from './Icon'
import Tooltip from './Tooltip'

class SimpleFormTooltip extends React.Component {
  render() {
    const { id, tooltip } = this.props

    const tooltipId = `tooltip-${id}`

    return (
      <>
        <span className='tooltip-icon' data-tip data-for={tooltipId}>
          <Icon icon='warningFilled' className='warning-icon' />
        </span>

        <Tooltip id={tooltipId} effect='solid'>
          {tooltip}
        </Tooltip>
      </>
    )
  }
}

export default SimpleFormTooltip
