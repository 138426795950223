import React from 'react'

import ReactDOM from 'react-dom'

import { t } from '../utils/i18n'
import MapContainer from './Maps/MapContainer'
import drawingController from './Maps/drawingController'
import Modal from './Modal'

class MapModal extends React.Component {
  render() {
    const { modalOpen, toggleModal, title, polygons, ...mapProps } = this.props
    if (!modalOpen) return null

    const drawingProps = {
      drawingController: {
        ...drawingController,
        drawingModes: []
      },
      polygon: polygons,
      ...mapProps
    }

    return ReactDOM.createPortal(
      <Modal className='map-modal' isOpen={modalOpen} onClose={toggleModal} title={title}>
        <div id='google-maps-container' data-read-only>
          <div id='map'>
            <MapContainer {...drawingProps} />
          </div>
        </div>

        <div className='btn-container'>
          <button className='btn btn-secondary' onClick={toggleModal}>
            {t('close')}
          </button>
        </div>
      </Modal>,
      document.querySelector('.modal-container')
    )
  }
}

export default MapModal
