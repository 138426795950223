import dayjs from 'dayjs'

export const calculateTimeDiff = (startTime, endTime) => {
  const startDateTime = dayjs(`2000-01-01 ${startTime}`)

  let endDateTime = dayjs(`2000-01-01 ${endTime}`)

  if (dayjs(endDateTime).isSameOrBefore(dayjs(startDateTime), 'minute')) {
    endDateTime = endDateTime.add(1, 'day')
  }

  return calculateTimeDiffDayjs(startDateTime, endDateTime)
}

export const calculateTimeDiffDayjs = (startTime, endTime) => {
  const minutes = endTime.diff(startTime, 'minutes')

  return formatMinutes(minutes)
}

export const formatMinutes = minutes => {
  let hours = Math.floor(minutes / 60)
  minutes -= hours * 60

  if (hours < 10) hours = `0${hours}`
  if (minutes < 10) minutes = `0${minutes}`

  return `${hours}:${minutes}`
}
