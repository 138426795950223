class MapAutocomplete {
  constructor(map, props = {}) {
    this.map = map
    this.targetId = props.targetId
    this.onChanged = props.onChanged

    if (this.targetId) {
      this.initAutocomplete(this.targetId)
      return
    }

    this.emPacContainer = document.getElementById('pac-container')

    if (this.emPacContainer) {
      this.preparePacContainer()

      this.initAutocomplete('pac-input')
    }
  }

  initAutocomplete(targetId) {
    this.targetInput = document.getElementById(targetId)

    if (this.targetInput) {
      this.bindAutocomplete()
      this.addAutocompleteListener()
    }
  }

  preparePacContainer() {
    this.emPacContainer.classList.remove('d-none')
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('pac-card'))
  }

  bindAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.targetInput, {
      types: ['geocode', 'establishment']
    })
    this.autocomplete.bindTo('bounds', this.map)
  }

  addAutocompleteListener() {
    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace()
      if (!place.geometry) return
      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport)
      } else {
        this.map.setZoom(17)
        this.map.setCenter(place.geometry.location)
      }

      this.onChanged && this.onChanged(place)
    })
  }
}

export default MapAutocomplete
