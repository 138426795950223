import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../../utils/i18n'
import { FilterContainer, SearchField, DateRangeFilter } from '../../FilterComponents'

class UsersFilter extends React.Component {
  render() {
    const { store, ...others } = this.props

    return (
      <FilterContainer store={store} {...others}>
        <SearchField store={store} placeholder={t('filters.search_task_users')} />
        <DateRangeFilter store={store} />
      </FilterContainer>
    )
  }
}

export default observer(UsersFilter)
