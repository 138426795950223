class UserGroupForm {
  static selector = '#new_tasker_user_group'

  constructor(form) {
    this.form = form

    this.bindElements()
    this.bindEvents()
    this.toggleAssociationInputs()
  }

  bindElements() {
    this.selectors = {
      usersContainer: this.form.querySelector('.tasker_user_group_users'),
      childrenContainer: this.form.querySelector('.tasker_user_group_children'),
      isParentCheckbox: this.form.querySelector('#tasker_user_group_is_parent')
    }
  }

  bindEvents() {
    this.selectors.isParentCheckbox.addEventListener('change', this.toggleAssociationInputs)
  }

  toggleAssociationInputs = () => {
    const { usersContainer, childrenContainer, isParentCheckbox } = this.selectors

    usersContainer.classList[isParentCheckbox.checked ? 'add' : 'remove']('d-none')
    childrenContainer.classList[isParentCheckbox.checked ? 'remove' : 'add']('d-none')
  }
}

export default UserGroupForm
