import React from 'react'

import Store from '../../Store'
import AdminUsersFilter from './AdminUsersFilter'
import AdminUsersTable from './AdminUsersTable'

class AdminUsersTableContainer extends React.Component {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'full_name', desc: false }],
    baseScope: this.props.base_scope
  })

  render() {
    return (
      <div>
        <AdminUsersFilter {...this.props} store={this.store} />
        <AdminUsersTable {...this.props} store={this.store} />
      </div>
    )
  }
}

export default AdminUsersTableContainer
