import React, { useState, useEffect, useContext, useCallback, useMemo, memo, useRef } from 'react'

import classNames from 'classnames'
import { motion } from 'framer-motion'
import { areEqual, VariableSizeList as List } from 'react-window'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

import { t } from '../../utils/i18n'
import Avatar from '../Avatar'
import Icon from '../Icon'
import Tooltip from '../Tooltip'
import { makeAbsenceMap } from './Absences/helpers'
import Badge from './Badge'
import ShiftDay from './ShiftDay'
import { OpenedItemsContext } from './ShiftList'
import { AbsenceContext, MonthContext, ShiftContext } from './Shifts'
import useEmployees from './hooks/useEmployees'

const EMPLOYEE_ROW_COUNT = 12
const EMPLOYEE_ROW_HEIGHT = 41
const LAST_EMPLOYEE_ROW_HEIGHT = 48
const MAX_EMPLOYEE_LIST_HEIGHT = EMPLOYEE_ROW_COUNT * EMPLOYEE_ROW_HEIGHT + EMPLOYEE_ROW_HEIGHT / 2

const SyncIcon = ({ id }) => (
  <>
    <Icon icon='sync' className='ml-2 sync-icon' data-tip data-for={id} />
    <Tooltip id={id} place='right' effect='solid'>
      {t('v2.shifts.index.sync_all_construction_objects')}
    </Tooltip>
  </>
)

const OnlineBadge = ({ className = '' }) => <span className={classNames('online-badge', className)} />

const ShiftInfo = ({
  shift: {
    id,
    name,
    construction_object_short_names,
    sync_all_construction_objects,
    online_employee_count,
    planned_online_employee_count
  },
  open,
  handleOpen,
  loading
}) => {
  const [firstObjectName, ...otherObjectNames] = construction_object_short_names

  return (
    <div
      className='shift-info-container first-column'
      onClick={e => {
        e.nativeEvent.stopImmediatePropagation()
        handleOpen(!open)
      }}
    >
      <div className='shift-info'>
        <div className='shift-name'>{name}</div>
        <div className='d-flex shift-info-item justify-content-between pr-2'>
          <div className='d-flex shift-object-info'>
            <Icon icon='mapMarker' className='mr-1' />
            <div className='text-truncate'>{firstObjectName}</div>
            <Badge id={`shift-item-${id}`} className='ml-1' items={otherObjectNames} />
            {sync_all_construction_objects && <SyncIcon id={`sync-${id}`} />}
          </div>
          <div className='online-count'>
            <OnlineBadge className='mr-2' />
            {online_employee_count} / {planned_online_employee_count}
          </div>
        </div>
      </div>
      <div className='expander'>
        <i className={loading ? 'fa fa-spinner fa-spin' : `fa fa-sort-${open ? 'up' : 'down'}`} />
      </div>
    </div>
  )
}

const ShiftWeek = ({ week, ...others }) => (
  <div className='shift-week'>
    {week.map((day, i) => (
      <ShiftDay key={i} day={day} {...others} />
    ))}
  </div>
)

const EmployeeList = ({ weeks, width, shift, setLoading, setHasEmployees, search }) => {
  const { month } = useContext(MonthContext)
  const { employeeAbsences } = useContext(AbsenceContext)
  const listRef = useRef({})
  const { employees } = useEmployees({ shift, month, setLoading, setHasEmployees, search, employeeAbsences })

  /* eslint-disable react-hooks/exhaustive-deps */
  const renderEmployeeItem = useMemo(
    () =>
      ({ index, style, data }) => {
        const employee = data[index]

        const employeeId = `${employee.first_name_last_name}-${employee.id}`

        const absences = employeeAbsences[employeeId]
        const absenceMap = absences ? makeAbsenceMap(employeeAbsences[employeeId], month) : {}

        return (
          <EmployeeItem
            employee={employee}
            last={index + 1 === data.length}
            index={index}
            weeks={weeks}
            width={width}
            shift={shift}
            style={style}
            listRef={listRef}
            absenceMap={absenceMap}
          />
        )
      },
    [width, employees.length, month, employeeAbsences]
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  const getEmployeeItemSize = index => (index + 1 === employees.length ? LAST_EMPLOYEE_ROW_HEIGHT : EMPLOYEE_ROW_HEIGHT)

  if (!employees.length) return null

  const height =
    employees.length < EMPLOYEE_ROW_COUNT
      ? (employees.length - 1) * EMPLOYEE_ROW_HEIGHT + LAST_EMPLOYEE_ROW_HEIGHT
      : MAX_EMPLOYEE_LIST_HEIGHT

  return (
    <div className='employees'>
      <SimpleBar style={{ height: '100%', maxHeight: height }}>
        {({ scrollableNodeRef, contentNodeRef }) => (
          <List
            width='100%'
            ref={listRef}
            height={height}
            itemCount={employees.length}
            itemData={employees}
            itemSize={getEmployeeItemSize}
            className='list-container'
            outerRef={scrollableNodeRef}
            innerRef={contentNodeRef}
            overscanCount={EMPLOYEE_ROW_COUNT}
          >
            {renderEmployeeItem}
          </List>
        )}
      </SimpleBar>
    </div>
  )
}

const EmployeeItem = memo(({ last, style, employee, weeks, width, shift, listRef, index, absenceMap }) => {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    listRef.current.resetAfterIndex(index)
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  const {
    id,
    first_name_last_name,
    position,
    picture_url,
    online,
    days,
    planned_shift_work_time_duration,
    consumed_shift_work_time_duration
  } = employee

  return (
    <div className='employee-container' key={id} style={style} data-employee-id={id}>
      <span className={classNames('shift-color', { 'round-bottom': last })} style={{ backgroundColor: shift.color }} />
      <div className='employee-content first-column'>
        <div className='employee-info'>
          <div className='employee-full-name text-truncate'>{first_name_last_name}</div>
          <div className='d-flex justify-content-end'>
            <div className='employee-hours'>
              {consumed_shift_work_time_duration} / {planned_shift_work_time_duration} h
            </div>
            {position && <div className='employee-position ml-2 text-truncate'>{position}</div>}
          </div>
        </div>
        <div className='avatar-container'>
          <Avatar src={picture_url} />
          {online && <OnlineBadge />}
        </div>
      </div>
      <div className='shift-days'>
        {weeks.map((week, weekIndex) => (
          <ShiftWeek
            key={weekIndex}
            week={week}
            width={width}
            shift={shift}
            employee={employee}
            days={days}
            absenceMap={absenceMap}
          />
        ))}
      </div>
    </div>
  )
}, areEqual)

const ShiftItem = memo(props => {
  const { shift, weeks, width, openEditForm, search } = props
  const { month } = useContext(MonthContext)
  const { managedShiftInfo, setManagedShiftInfo } = useContext(ShiftContext)
  const { openedItems, toggleOpenedItems } = useContext(OpenedItemsContext)
  const [loading, setLoading] = useState(false)
  const [hasEmployees, setHasEmployees] = useState(false)

  const animations =
    !managedShiftInfo.date && managedShiftInfo.shiftId === shift.id
      ? {
          animate: { scale: [1, 1.01, 1] },
          onAnimationComplete: () => {
            setManagedShiftInfo({})
          }
        }
      : {}
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    open && setLoading(true)
  }, [month])

  useEffect(() => {
    if (!open && hasEmployees) {
      setHasEmployees(false)
    }
  }, [openedItems])

  const { color } = shift

  const open = openedItems.includes(shift.id)
  const handleOpen = useCallback(
    opened => {
      opened && setLoading(true)
      !opened && setHasEmployees(false)

      toggleOpenedItems(shift.id)
    },
    [setLoading, toggleOpenedItems]
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <motion.div
      key={shift.latest_updated_at}
      className={classNames('shift-item', { open })}
      data-shift-id={shift.id}
      {...animations}
    >
      <div className='d-flex'>
        <div className='shift-item-container'>
          <div className='shift-container'>
            <span
              className={classNames('shift-color round-top', { 'round-bottom': !hasEmployees })}
              style={{ backgroundColor: color }}
            />
            <ShiftInfo shift={shift} open={open} handleOpen={handleOpen} loading={loading} />
            <div className='shift-days'>
              {weeks.map((week, weekIndex) => (
                <ShiftWeek key={weekIndex} week={week} width={width} shift={shift} days={shift.days} />
              ))}
            </div>
          </div>
          {open && (
            <EmployeeList
              weeks={weeks}
              width={width}
              shift={shift}
              setLoading={setLoading}
              setHasEmployees={setHasEmployees}
              search={search}
            />
          )}
        </div>
      </div>
      {open && shift.can_manage && (
        <div className='shift-actions d-flex align-items-center'>
          <div className='shift-action edit d-flex pl-3' onClick={() => openEditForm(shift)}>
            <i className='icon fa fa-pencil mr-2' />
            <span>{t('v2.shifts.index.edit_subject')}</span>
          </div>
        </div>
      )}
    </motion.div>
  )
})

export default ShiftItem
