import { DataProcessor } from './utils/dataProcesser'

export const setupModules = (data = null) => {
  if (data === null) return { regularity: false, timeTracking: false, subtasks: false, location: false }

  return {
    regularity: !!data.regularity,
    timeTracking: data.time_tracking_enabled,
    subtasks: data.sub_tasks_attributes?.length > 0,
    location: data.has_location
  }
}

export const preprocessForSubmit = (unformattedData, modules, fileKeys, formType) => {
  let processer = new DataProcessor(modules, unformattedData, fileKeys, formType)
  return processer.perform()
}
