import React, { useEffect } from 'react'

import classNames from 'classnames'
import { t } from 'i18n-js'
import { useFormContext, useWatch } from 'react-hook-form'

import SwitchField from '../../../../Forms/HookFields/SwitchField'
import SubtasksInput from '../Fields/SubtasksInput'
import styles from '../styles/ModuleWrapper.module.scss'

export const SubtaskModule = () => {
  const { submitting, setValue } = useFormContext()
  const title = t('v2.tasker.tasks.form.modules.subtasks')

  const subTasks = useWatch({ name: 'sub_tasks_attributes' })

  const isCompletionSwitchDisabled = subTasks.filter(sub => sub.title && !sub._destroy).length === 0

  useEffect(() => {
    if (isCompletionSwitchDisabled) {
      setValue('sub_task_completion_required', false)
    }
  }, [isCompletionSwitchDisabled, setValue])

  return (
    <div className={styles.moduleWrapper}>
      <hr className='mt-4 mb-2' />
      <div className={classNames(styles.moduleTitle, 'mb-2')}>{title}</div>
      <div className={styles.fields}>
        <SubtasksInput />
        <SwitchField
          name='sub_task_completion_required'
          label={t('activerecord.attributes.tasker/task.sub_task_completion_required')}
          disabled={isCompletionSwitchDisabled || submitting}
        />
      </div>
    </div>
  )
}
