import { t } from '../utils/i18n'

class EmployeeForm {
  static selector = '.edit_employee, .new_employee, .new_construction_object_employee'

  constructor(form) {
    this.form = form

    this.bindElements()
    this.bindEvents()
  }

  bindElements() {
    this.selectors = {
      isForeignerInput: this.form.querySelector(
        '#employee_is_foreigner, #construction_object_employee_employee_attributes_is_foreigner'
      ),
      personalCodeInputLabel: this.form.querySelector(
        '.employee_personal_code label, .construction_object_employee_employee_personal_code label'
      )
    }
  }

  bindEvents() {
    const input = this.selectors.isForeignerInput
    if (input) {
      input.addEventListener('change', this.onIsForeignerChange)
    }
  }

  onIsForeignerChange = () => {
    const { personalCodeInputLabel, isForeignerInput } = this.selectors
    if (isForeignerInput.checked) {
      personalCodeInputLabel.innerText = t('v2.employees.form.foreigner_personal_code_label')
    } else {
      personalCodeInputLabel.innerText = t('activerecord.attributes.employee.personal_code')
    }
  }
}

export default EmployeeForm
