import React from 'react'

import { useField } from 'formik'

import DateTimePicker from '../../DateTimePicker'
import FormikFieldWrapper from './FormikFieldWrapper'

const DateTimeField = ({ type = 'date_time', ...props }) => {
  const { label, disabled, className, required, errorInTooltip } = props
  const [field, , helpers] = useField(props)
  const { value, name } = field

  const handleChange = val => {
    helpers.setValue(val)
  }

  return (
    <FormikFieldWrapper
      name={name}
      label={label}
      required={required}
      className={className}
      errorInTooltip={errorInTooltip}
    >
      <DateTimePicker
        onChange={handleChange}
        id={name}
        value={value}
        type={type}
        disabled={disabled}
        name={name}
        {...props}
      />
    </FormikFieldWrapper>
  )
}

export default DateTimeField
