import { useMemo } from 'react'

import dayjs from 'dayjs'

const useAbsencePositionStyle = ({ day, dateFrom, dateTo, dayWidth }) => {
  const dayFrom = useMemo(() => dayjs(dateFrom), [dateFrom])
  const dayTo = useMemo(() => dayjs(dateTo), [dateTo])

  const style = useMemo(() => {
    if (!dayFrom.isValid() || !dayTo.isValid()) {
      return null
    }

    const notInCurrentMonth = day.month() < dayFrom.month() || day.month() > dayTo.month()
    if (notInCurrentMonth) {
      return null
    }

    const startBeforeMonth = day.month() > dayFrom.month()
    const startAfterMonth = day.month() < dayTo.month()

    const calcDayFrom = startBeforeMonth ? day.startOf('month') : dayFrom
    const calcDayTo = startAfterMonth ? day.endOf('month') : dayTo

    const startDelta = calcDayFrom.diff(day, 'day')
    const endDelta = calcDayTo.diff(day, 'day')

    const dayCount = endDelta - startDelta + 1

    const data = { width: dayWidth * dayCount, left: startDelta * dayWidth - 1 }

    if (startBeforeMonth) {
      data.borderLeft = 0
      data.borderTopLeftRadius = 0
      data.borderBottomLeftRadius = 0
    }
    if (startAfterMonth) {
      data.borderRight = 0
      data.borderTopRightRadius = 0
      data.borderBottomRightRadius = 0
    }

    return data
  }, [day, dayFrom, dayTo, dayWidth])

  return style
}

export default useAbsencePositionStyle
