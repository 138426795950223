import React from 'react'

import ReactDOM from 'react-dom'
import ReactTable from 'react-table-6'

import { t } from '../../utils/i18n'
import Modal from '../Modal'

const getValueFromLocalStorage = localStorageKey => {
  const value = JSON.parse(localStorage.getItem(localStorageKey))
  localStorage.removeItem(localStorageKey)

  return value
}

// TODO: use `react-table` v7
const GuestsTable = ({ guests, getTrProps }) => (
  <ReactTable
    columns={[
      { Header: t('activerecord.attributes.guest.full_name'), accessor: 'full_name' },
      { Header: t('activerecord.attributes.guest.personal_code'), accessor: 'personal_code' },
      { Header: t('activerecord.attributes.guest.error_message'), accessor: 'errors', className: 'pre-wrap' }
    ]}
    className='mb-5 mt-2'
    data={guests}
    showPagination={false}
    getTrProps={getTrProps}
    defaultPageSize={guests.length}
  />
)

class GuestsImportedResultContainer extends React.Component {
  state = { modalOpen: true }
  importResult = getValueFromLocalStorage('importResult')

  toggleModal = () => {
    this.setState(ps => ({ modalOpen: !ps.modalOpen }))
  }

  succeededRowProps(_state, rowInfo, _column) {
    return rowInfo.original.errors ? { className: 'warning' } : { className: 'success' }
  }

  failedRowProps() {
    return { className: 'danger height-auto' }
  }

  renderTable(collection, type) {
    if (collection.length == 0) return

    return (
      <div className={type}>
        <h4>
          {t(`import_${type}_results_title`)} ({collection.length})
        </h4>
        <GuestsTable guests={collection} getTrProps={this[`${type}RowProps`]} />
      </div>
    )
  }

  renderModal() {
    const { modalOpen } = this.state
    if (!modalOpen) return

    return ReactDOM.createPortal(
      <Modal
        className='modal-xl modal-dialog-centered'
        title={t('import_results_title')}
        isOpen={modalOpen}
        onClose={this.toggleModal}
        dataContains='guests-imported-result-modal'
      >
        {this.renderTable(this.importResult.invalids, 'failed')}
        {this.renderTable(this.importResult.guests_imported, 'succeeded')}

        <div className='btn-container'>
          <button className='btn btn-secondary' onClick={this.toggleModal}>
            {t('close')}
          </button>
        </div>
      </Modal>,
      document.querySelector('.modal-container')
    )
  }

  render() {
    return <React.Fragment>{this.importResult && this.renderModal()}</React.Fragment>
  }
}

export default GuestsImportedResultContainer
