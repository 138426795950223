import React from 'react'

import dayjs from 'dayjs'

import { formatTime } from '../../../utils/formatTime'
import { useSchedulesContext } from '../contexts/SchedulesContext'
import styles from './ShiftBlock.module.scss'

const formatHours = time => dayjs(time).format('HH')

const BlockTime = ({ halfSizeBlock, startTime, endTime, workTime }) => {
  const { shiftFactualTime } = useSchedulesContext()

  if (!startTime || !endTime) return null

  const formatShiftTime = halfSizeBlock ? formatHours : formatTime

  if (shiftFactualTime) {
    return <div className={styles.time}>{workTime}</div>
  } else {
    return <div className={styles.time}>{`${formatShiftTime(startTime)} - ${formatShiftTime(endTime)}`}</div>
  }
}

export default BlockTime
