import styles from './ShiftBlock.module.scss'

export const getContainerStyle = (visibilityParams, updated) => {
  const {
    showBlock,
    yesterdayShift,
    tomorrowShift,
    showTomorrow,
    showYesterday,
    singleDay,
    singleDayTomorrow,
    singleDayYesterday,
    hasAbsenceTomorrow,
    hasAbsence
  } = visibilityParams

  const halfSizeBlock =
    (yesterdayShift && !singleDayYesterday && singleDay && !hasAbsence) || (!singleDay && hasAbsenceTomorrow)

  const roundedLeft =
    !yesterdayShift || (yesterdayShift && (!showYesterday || (showYesterday && singleDayYesterday && !singleDay)))
  const roundedRight =
    !tomorrowShift || (tomorrowShift && (!showTomorrow || (showTomorrow && !singleDayTomorrow && singleDay)))

  const containerClasses = {
    [styles.container]: true,
    [styles.hasShiftBlock]: showBlock,
    'has-shift-block': showBlock,
    [styles.nightShift]: showBlock && !singleDay,
    [styles.roundedLeft]: roundedLeft,
    'rounded-left': roundedLeft,
    [styles.roundedRight]: roundedRight,
    'rounded-right': roundedRight,
    [styles.halfBlock]: halfSizeBlock,
    [styles.updated]: updated,
    [styles.hasAbsence]: hasAbsence
  }
  return { containerClasses, halfSizeBlock }
}

export const getShiftVisibilityParams = (shifts, date, dateIso, absenceDates) => {
  const yesterdayDateIso = date.subtract(1, 'day').format('YYYY-MM-DD')
  const tomorrowDateIso = date.add(1, 'day').format('YYYY-MM-DD')

  const shift = shifts.find(shift => shift.date === dateIso)
  const yesterdayShift = shifts.find(shift => shift.date === yesterdayDateIso)
  const tomorrowShift = shifts.find(shift => shift.date === tomorrowDateIso)

  const hasAbsence = absenceDates.includes(dateIso)
  const hasAbsenceTomorrow = absenceDates.includes(tomorrowDateIso)
  const hasAbsenceYesterday = absenceDates.includes(yesterdayDateIso)

  const showBlock = shift?.show_block && (!hasAbsence || shift?.unscheduled)
  const showTomorrow = tomorrowShift?.show_block && !hasAbsenceTomorrow
  const showYesterday = yesterdayShift?.show_block && !hasAbsenceYesterday

  const singleDay = shift?.single_day_shift ?? true
  const singleDayTomorrow = tomorrowShift?.single_day_shift
  const singleDayYesterday = yesterdayShift?.single_day_shift

  return {
    shift,
    yesterdayDateIso,
    tomorrowDateIso,
    yesterdayShift,
    tomorrowShift,
    showBlock,
    showTomorrow,
    showYesterday,
    singleDay,
    singleDayTomorrow,
    singleDayYesterday,
    hasAbsenceTomorrow,
    hasAbsence
  }
}
