import React from 'react'

import classNames from 'classnames'
import { useController } from 'react-hook-form'
import ReactTooltip from 'react-tooltip'

const ColorPicker = ({ name, control, colors }) => {
  const {
    field: { value, onChange }
  } = useController({ name, control })

  return (
    <div className='color'>
      <div
        name='color'
        className='color-picker'
        data-for='color-picker-tooltip'
        data-tip
        style={{ backgroundColor: value }}
        type='button'
      />
      <ReactTooltip id='color-picker-tooltip' place='bottom' className='white' effect='solid' event='click' clickable>
        <div className='color-picker-container'>
          {colors.map(color => (
            <button
              type='button'
              key={color}
              className={classNames('color-picker-option', { 'selected-color': value === color })}
              style={value === color ? { background: color, outline: `2px solid ${color}` } : { background: color }}
              onClick={() => onChange(color)}
            />
          ))}
        </div>
      </ReactTooltip>
    </div>
  )
}
export default ColorPicker
