class MapDrawingManager {
  constructor(map, drawingController) {
    this.map = map
    this.drawingController = drawingController
    this.drawingManager = this.setDrawingManager()
    this.figure = undefined
    this.setupListeners()
  }

  setDrawingManager() {
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null, // do not preselect polygon tool
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [...this.drawingController.drawingModes]
      },
      polygonOptions: {
        ...this.drawingController.style,
        editable: true,
        draggable: true
      },
      circleOptions: {
        ...this.drawingController.style,
        editable: true,
        draggable: true
      }
    })

    drawingManager.setMap(this.map)
    return drawingManager
  }

  disableNewDrawings() {
    this.drawingManager.setOptions({ drawingMode: null })
    this.drawingManager.setOptions({ drawingControl: false })
  }

  setupListeners() {
    google.maps.event.addListener(this.drawingManager, 'polygoncomplete', polygon => {
      this.figure = polygon

      this.drawingController.setPolygonEvents(polygon.getPath())
      this.disableNewDrawings()
    })

    google.maps.event.addListener(this.drawingManager, 'circlecomplete', circle => {
      this.figure = circle

      this.drawingController.setCircleEvents(circle)
      this.disableNewDrawings()
    })

    google.maps.event.addDomListener(document, 'keyup', e => {
      const backChr = 8
      const delChr = 46
      const code = e.keyCode ? e.keyCode : e.which

      if (e.target.getAttribute('aria-label') == 'Map' && [backChr, delChr].includes(code) && this.figure) {
        this.figure.setMap(null)
        this.drawingManager.setOptions({ drawingControl: true })
        this.drawingController.onDestroy()
      }
    })
  }
}

export default MapDrawingManager
