import React, { useEffect, useState } from 'react'

import classNames from 'classnames'
import { lv, ru, enUS } from 'date-fns/locale'
import dayjs from 'dayjs'
import I18n, { t } from 'i18n-js'
import { observer } from 'mobx-react'
import DatePicker, { registerLocale } from 'react-datepicker'

import Icon from './Icon'

registerLocale('lv', lv)
registerLocale('ru', ru)
registerLocale('en', enUS)

const DateInterval = props => {
  const format = I18n.t('date.formats.date_human_js')
  const { store, accessKeys, label } = props

  const startCalendarRef = React.createRef()
  const endCalendarRef = React.createRef()

  const rawStartDate = store.state.filters[accessKeys.start]
  const rawEndDate = store.state.filters[accessKeys.end]
  const startDate = rawStartDate ? dayjs(rawStartDate).toDate() : null
  const endDate = rawEndDate ? dayjs(rawEndDate).toDate() : null

  const [datesVisible, setDatesVisible] = useState(startDate || endDate)

  const handleStartDateChange = date => {
    if (endDate && endDate < date) {
      store.setMultipleFilters({
        [accessKeys.start]: dayjs(date).format(t('date.formats.default_raw_sql_human')),
        [accessKeys.end]: undefined
      })
    } else {
      store.onRangeDateChange(date, accessKeys.start)
    }
  }

  const handleEndDateChange = date => {
    store.onRangeDateChange(date, accessKeys.end)
  }

  const showDatepicker = () => {
    setDatesVisible(true)
  }

  const hideDatepicker = (clearData = true) => {
    setDatesVisible(false)
    if (clearData) {
      store.setMultipleFilters({
        [accessKeys.start]: undefined,
        [accessKeys.end]: undefined
      })
    }
  }

  const handleBlur = () => {
    // Reacts equivalent of Vues $nextTick method.
    setTimeout(() => {
      const focusedEl = document.activeElement
      const notFocused = focusedEl !== startCalendarRef.current?.input && focusedEl !== endCalendarRef.current?.input
      if (notFocused && !rawEndDate && !rawStartDate) {
        setDatesVisible(false)
      }
    })
  }

  useEffect(() => {
    if (startCalendarRef.current?.input && !startDate && !endDate) startCalendarRef.current.input.focus()
  }, [startCalendarRef, startDate, endDate])

  return (
    <div className='date-interval'>
      <Icon icon='filterCalendar' className='calendar-icon' onClick={() => showDatepicker()} />
      <div className='datepicker-with-label-wrapper'>
        <div className={classNames('label-placeholder')} onClick={() => showDatepicker()}>
          {label}
        </div>
        {datesVisible && (
          <div className='datepickers-container'>
            <DatePicker
              locale={I18n.locale}
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              ref={startCalendarRef}
              onChange={date => handleStartDateChange(date)}
              onBlur={() => handleBlur()}
              popperPlacement='bottom-start'
              showPopperArrow={false}
              dateFormat={format}
              autoComplete='off'
              className='start-date'
              popperClassName='start-date-popper'
              selectsStart
            />
            <div className='range-seperator'>-</div>
            <DatePicker
              locale={I18n.locale}
              selected={endDate}
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              ref={endCalendarRef}
              onChange={date => handleEndDateChange(date)}
              onBlur={() => handleBlur()}
              popperPlacement='bottom-start'
              showPopperArrow={false}
              dateFormat={format}
              autoComplete='off'
              className='end-date'
              popperClassName='end-date-popper'
              selectsEnd
            />
          </div>
        )}
      </div>
      <Icon icon='cross' className='clear-icon' onClick={() => hideDatepicker()} />
    </div>
  )
}

export default observer(DateInterval)
