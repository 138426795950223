import React, { useCallback, useEffect, useState } from 'react'

import isEqual from 'lodash/isEqual'

import { formatTime } from '../../utils/formatTime'
import { formatDuration } from '../../utils/schedulerDuration'
import DayModal from './DayModal'
import { calculateMiniModalPosition } from './MiniModal'
import { useSelectedShiftItems } from './SelectedShiftItemsContext'
import ShiftMiniModal from './ShiftMiniModal'
import ShiftModal from './ShiftModal'

const MINI_MODAL_MAX_WIDTH = 280

const UnitModal = () => {
  const [showModal, setShowModal] = useState(false)
  const [miniModalProps, setMiniModalProps] = useState(null)
  const { selectedShiftItems, modalParams, setModalParams, clearShiftItems } = useSelectedShiftItems()

  useEffect(() => {
    if (selectedShiftItems.length < 1) {
      setMiniModalProps(null)
    }
  }, [selectedShiftItems])

  useEffect(() => {
    if (modalParams && selectedShiftItems.length >= 1) {
      const {
        employee,
        schedule,
        shift,
        metadata: { dateIso },
        dayRef
      } = modalParams

      const currentMiniModalParams = {
        date: dateIso,
        schedule_id: schedule.id,
        employee_id: employee?.id
      }

      if (isEqual(selectedShiftItems[0], currentMiniModalParams) || modalParams.isShiftDay) {
        const start_time = shift?.start_datetime
          ? formatTime(shift.start_datetime)
          : formatDuration(schedule.start_time)
        const end_time = shift?.end_datetime ? formatTime(shift.end_datetime) : formatDuration(schedule.end_time)

        setMiniModalProps({
          position: calculateMiniModalPosition(dayRef, MINI_MODAL_MAX_WIDTH),
          initialValues: {
            start_time,
            end_time
          }
        })
      }
    }

    if (!showModal && modalParams) {
      setShowModal(true)
    }
  }, [selectedShiftItems, modalParams, setMiniModalProps, showModal, setShowModal])

  const closeShiftModal = useCallback(() => {
    setModalParams(null)
    setMiniModalProps(null)
    setShowModal(false)
    clearShiftItems()
  }, [setModalParams, clearShiftItems])

  if (!modalParams || !showModal) return null

  if (miniModalProps) {
    return (
      <ShiftMiniModal
        {...miniModalProps}
        showExpand={selectedShiftItems.length === 1}
        onExpand={() => setMiniModalProps(null)}
        onClose={closeShiftModal}
      />
    )
  }

  return modalParams.employee ? (
    <ShiftModal closeModal={closeShiftModal} {...modalParams} />
  ) : (
    <DayModal closeModal={closeShiftModal} {...modalParams} />
  )
}

export default UnitModal
