import React from 'react'

import { t } from '../../../utils/i18n'
import { url } from '../../../utils/urlGenerator'
import ActiveTable from '../../ActiveTable'
import { edit, remove, removeSelected } from '../../elements/actionDefinitions'

const getFilters = row => {
  const { is_parent, id } = row.original

  if (is_parent) {
    return { parent_user_group_id: [id] }
  } else {
    return { responsible: [`usergroup_${id}`] }
  }
}

class UserGroupsTable extends React.Component {
  pathPrefix = 'v2/tasker/user_groups'

  actions = {
    row: [
      edit(this.pathPrefix, 'v2.tasker.user_groups.edit.title'),
      remove(this.pathPrefix, {
        text: t('destroy'),
        confirmationProps: {
          text: t('confirm_destroy'),
          confirmButton: { title: t('destroy'), type: 'danger' }
        }
      })
    ],
    header: [
      removeSelected(this.pathPrefix, {
        text: t('destroy_selected'),
        confirmationProps: {
          text: t('confirm_destroy_selected'),
          confirmButton: { title: t('destroy'), type: 'danger' }
        }
      })
    ]
  }

  _getTdProps = ({ row, column }) => ({
    onClick: () => {
      if (row.depth === 0 && this.props.parentsOnly) return
      if (['id', 'row_actions'].includes(column.accessorKey)) return

      Turbolinks.visit(url('v2/tasker/tasks', { filters: getFilters(row) }))
    }
  })

  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        pathPrefix={this.pathPrefix}
        getTdProps={this._getTdProps}
        translationPath={['tasker', 'user_groups']}
      />
    )
  }
}

export default UserGroupsTable
