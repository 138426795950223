import React, { useEffect, useState, useRef, useMemo, createContext } from 'react'

import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import I18n from 'i18n-js'
import useResizeObserver from 'use-resize-observer'

import Absences from './Absences'
import ShiftHeader from './ShiftHeader'
import ShiftList from './ShiftList'
import ShiftListHeader from './ShiftListHeader'
import useAbsences from './hooks/useAbsences'
import useExceptionDays from './hooks/useExceptionDays'
import useShifts from './hooks/useShifts'

dayjs.locale(I18n.locale)
dayjs.extend(weekOfYear)

export const columnDates = month => {
  const monthDayjs = dayjs(month, 'YYYY-MM')

  const dateFrom = monthDayjs.startOf('month')
  const dateTo = monthDayjs.endOf('month')

  const dates = []

  let currDate = dateFrom

  while (currDate.diff(dateTo) <= 0) {
    dates.push(currDate)
    currDate = currDate.add(1, 'day')
  }

  return dates
}

const datesByWeeks = dates => {
  const group = dates.reduce((result, date) => {
    const week = date.week()

    result[`w_${week}`] = result[`w_${week}`] || []
    result[`w_${week}`].push(date)

    return result
  }, {})

  return Object.values(group)
}

export const MonthContext = createContext({
  month: dayjs().startOf('month').format('YYYY-MM'),
  setMonth: () => {}
})

export const ShiftContext = createContext({
  manageShift: () => {},
  managedShiftInfo: [],
  setManagedShiftInfo: () => {},
  loadShifts: () => {},
  manageMultipleShifts: () => {},
  shifts: [],
  loading: false
})

export const ExceptionDaysContext = createContext({
  loading: false,
  isHoliday: () => {}
})

export const AbsenceContext = createContext({
  absences: [],
  loading: false,
  addAbsences: () => {},
  updateAbsence: () => {},
  deleteAbsence: () => {},
  employeeAbsences: {}
})

const Shifts = ({ month: initialMonth, policies, shiftProps }) => {
  const containerRef = useRef()
  const [month, setMonth] = useState(initialMonth)
  const [search, setSearch] = useState('')
  const [chosenCompaniesIds, setChosenCompaniesIds] = useState([])
  const [width, setWidth] = useState(null)
  const { width: containerWidth } = useResizeObserver({ ref: containerRef })
  const { loading: loadingExceptionDays, isHoliday } = useExceptionDays()
  const { managedShiftInfo, setManagedShiftInfo, manageShift, shifts, loading, loadShifts, manageMultipleShifts } =
    useShifts({
      month,
      search,
      chosenCompaniesIds
    })
  const {
    absences,
    loading: loadingAbsences,
    addAbsences,
    updateAbsence,
    deleteAbsence,
    employeeAbsences
  } = useAbsences({
    month,
    search
  })

  const dates = useMemo(() => columnDates(month), [month])
  const weeks = useMemo(() => datesByWeeks(dates), [dates])

  const firstColumn = document.querySelectorAll('.first-column')

  useEffect(() => {
    if (containerWidth && firstColumn.length) {
      const firstColumnWidth = Number.parseInt(getComputedStyle(firstColumn[0]).width)

      const dayWidth = (containerWidth - firstColumnWidth) / dates.length

      // (dayWidth || 1) because sticky row causes a one-frame issue on width that returns NaN and throws a warning but doesnt break visibly
      setWidth(dayWidth || 1)
    }
  }, [containerWidth, month, weeks, dates.length, firstColumn])

  const goToPrevMonth = () => {
    setMonth(month => dayjs(month, 'YYYY-MM').subtract(1, 'month').format('YYYY-MM'))
  }

  const goToNextMonth = () => {
    setMonth(month => dayjs(month, 'YYYY-MM').add(1, 'month').format('YYYY-MM'))
  }

  const goToMonth = month => {
    setMonth(dayjs(month).format('YYYY-MM'))
  }

  const monthContextValue = useMemo(
    () => ({
      month,
      setMonth
    }),
    [month, setMonth]
  )

  const shiftContextValue = useMemo(
    () => ({
      manageShift,
      managedShiftInfo,
      setManagedShiftInfo,
      loadShifts,
      manageMultipleShifts,
      shifts,
      loading
    }),
    [manageShift, managedShiftInfo, setManagedShiftInfo, loadShifts, manageMultipleShifts, shifts, loading]
  )

  /* eslint-disable react-hooks/exhaustive-deps */
  const absenceContextValue = useMemo(
    () => ({
      absences,
      loading: loadingAbsences,
      addAbsences,
      updateAbsence,
      deleteAbsence,
      employeeAbsences
    }),
    [absences, loadingAbsences, addAbsences]
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  const exceptionDaysContextValue = useMemo(
    () => ({
      loading: loadingExceptionDays,
      isHoliday
    }),
    [loadingExceptionDays, isHoliday]
  )

  return (
    <MonthContext.Provider value={monthContextValue}>
      <AbsenceContext.Provider value={absenceContextValue}>
        <ShiftContext.Provider value={shiftContextValue}>
          <ExceptionDaysContext.Provider value={exceptionDaysContextValue}>
            <div className='shifts-container'>
              <ShiftHeader
                goToPrevMonth={goToPrevMonth}
                goToNextMonth={goToNextMonth}
                goToMonth={goToMonth}
                search={search}
              />
              <div className='shift-list-container shift-page-content' ref={containerRef}>
                <ShiftListHeader
                  weeks={weeks}
                  width={width}
                  search={search}
                  setSearch={setSearch}
                  chosenCompaniesIds={chosenCompaniesIds}
                  setChosenCompaniesIds={setChosenCompaniesIds}
                />

                <Absences weeks={weeks} width={width} search={search} month={month} policies={policies} />
                <ShiftList weeks={weeks} width={width} search={search} {...shiftProps} />
              </div>
            </div>
          </ExceptionDaysContext.Provider>
        </ShiftContext.Provider>
      </AbsenceContext.Provider>
    </MonthContext.Provider>
  )
}

export default Shifts
