import React, { useState, useCallback } from 'react'

import { unitSelectIndex } from './shiftDayHelper'

const SelectedShiftItemsContext = React.createContext()

function SelectedShiftItemsProvider({ children }) {
  const [selectedShiftItems, setSelectedShiftItems] = useState([])
  const [modalParams, setModalParams] = useState(null)

  const addSelectedShiftItem = useCallback(
    (item, reset) => {
      if (reset) {
        setSelectedShiftItems([item])
      } else {
        const unitIndex = unitSelectIndex(selectedShiftItems, item)
        if (unitIndex > -1) {
          setSelectedShiftItems(selectedShiftItems.filter((_, idx) => idx !== unitIndex))
        } else {
          setSelectedShiftItems([...selectedShiftItems, item])
        }
      }
    },
    [selectedShiftItems]
  )

  const clearShiftItems = useCallback(() => {
    setSelectedShiftItems([])
  }, [])

  const value = { selectedShiftItems, addSelectedShiftItem, clearShiftItems, setModalParams, modalParams }

  return <SelectedShiftItemsContext.Provider value={value}>{children}</SelectedShiftItemsContext.Provider>
}

const useSelectedShiftItems = () => {
  const context = React.useContext(SelectedShiftItemsContext)
  if (context === undefined) {
    throw new Error('useSelectedShiftItems must be used within a SelectedShiftItemsProvider')
  }
  return context
}

export { SelectedShiftItemsProvider, useSelectedShiftItems }
