import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Avatar from '../Avatar'
import IconCell from '../IconCell'
import Store from '../Store'
import TableContainer from '../TableContainer'
import UsersFilter from './UsersFilter'
import UsersTable from './UsersTable'

class UsersTableContainer extends TableContainer {
  pathPrefix = 'v2/users'

  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'last_activity_at', desc: true }],
    baseScope: this.props.base_scope
  })

  columns = [
    {
      header: t('activerecord.attributes.user.full_name'),
      accessorKey: 'full_name',
      size: 250,
      cell: ({
        getValue,
        row: {
          original: { locked, inactive, id, picture_url, employee }
        }
      }) => {
        let icon, type, text, showIcon
        if (locked) {
          icon = 'ban'
          type = 'danger'
          text = t('v2.users.index.locked_tooltip_text')
          showIcon = true
        } else if (inactive) {
          icon = 'envelope'
          type = 'primary'
          text = t('v2.users.index.inactive_tooltip_text')
          showIcon = true
        } else if (employee) {
          icon = 'link'
          type = ''
          text = employee
          showIcon = true
        }

        return (
          <IconCell
            id={id}
            showIcon={showIcon}
            tooltipText={text}
            iconComponent={tooltipId => (
              <i className={`fa fa-${icon} ${type} ml-2 center-icon`} data-tip data-for={tooltipId} />
            )}
          >
            <Avatar src={picture_url} text={getValue()} resourcePath={url(this.pathPrefix, id)} />
          </IconCell>
        )
      }
    },
    {
      header: t('activerecord.attributes.user.email'),
      accessorKey: 'email'
    },
    {
      header: t('activerecord.attributes.user.description'),
      accessorKey: 'description'
    },
    {
      header: t('activerecord.attributes.user.role'),
      accessorKey: 'role'
    },
    this.props.show_edlus_components && {
      header: t('activerecord.attributes.user.construction_objects'),
      accessorKey: 'construction_objects_count',
      cell: ({ getValue, row: { original } }) => (
        <a href={url(this.pathPrefix, original.id, 'construction_objects')}>{getValue()}</a>
      )
    },
    {
      header: t('activerecord.attributes.user.user_groups'),
      accessorKey: 'tasker_user_groups_count'
    },
    {
      header: t('activerecord.attributes.user.last_activity_at'),
      accessorKey: 'last_activity_at'
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div>
        <UsersFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />

        <UsersTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
          pathPrefix={this.pathPrefix}
        />
      </div>
    )
  }
}

export default UsersTableContainer
