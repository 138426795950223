import React from 'react'

import { t } from '../../../utils/i18n'
import Avatar from '../../Avatar'
import Store from '../../Store'
import TableContainer from '../../TableContainer'
import TaskUsersFilter from '../Overview/UsersFilter'
import TaskTimeEntryUsersTable from './TaskTimeEntryUsersTable'

class TaskTimeEntryUsersTableContainer extends TableContainer {
  constructor(props) {
    super(props)

    this.store = new Store({
      url: this.props.url,
      defaultSorted: [{ id: 'full_name', desc: false }]
    })
  }

  columns = [
    {
      header: t('activerecord.attributes.user.full_name'),
      accessorKey: 'full_name',
      cell: ({ getValue, row: { original } }) => <Avatar src={original.picture_url} text={getValue()} />
    },
    {
      header: t('activerecord.attributes.user.description'),
      accessorKey: 'description'
    },
    {
      header: t('activerecord.attributes.user.time_entries_duration'),
      accessorKey: 'time_entries_duration'
    },
    {
      header: t('activerecord.attributes.user.time_entries'),
      accessorKey: 'time_entries_count'
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <TaskUsersFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <TaskTimeEntryUsersTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
        />
      </div>
    )
  }
}

export default TaskTimeEntryUsersTableContainer
