import React from 'react'

import ActiveTable from '../ActiveTable'
import { edit } from '../elements/actionDefinitions'

class ReportEmployeesTable extends React.Component {
  actions = {
    row: [edit(this.props.pathPrefix, 'v2.employees.edit.title')]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        translationPath='employees'
        onRowClick='openEditForm'
        dangerBy='invalid'
      />
    )
  }
}

export default ReportEmployeesTable
