import React, { useState } from 'react'

import axios from 'axios'

import initialize from '../../../initializers'
import { t } from '../../../utils/i18n'
import { url } from '../../../utils/urlGenerator'
import Select from '../../Forms/Fields/Select'

const TaskTemplateSelect = () => {
  const [value, setValue] = useState(null)
  const form = document.querySelector('.form-content')

  const injectForm = () => {
    axios.get(url(`v2/tasker/tasks/${value.value}/edit`, { template: true })).then(({ data }) => {
      form.innerHTML = data
      initialize(document.querySelector('.modal-form-container'))
    })
  }

  return (
    <div className='row'>
      <div className='col-sm-4 p-0' />
      <div className='col-sm-6 d-flex template-select-container'>
        <Select
          className='flex-grow-1'
          value={value}
          optionsURL={url('/v2/tasker/tasks/task_templates')}
          name='absence_type_id'
          onSelect={setValue}
          placeholder={t('v2.tasker.tasks.form.select_template_placeholder')}
          isClearable
        />
        <button className='btn btn-sm btn-secondary ml-2' onClick={injectForm} disabled={!value}>
          {t('load')}
        </button>
      </div>
    </div>
  )
}

export default TaskTemplateSelect
