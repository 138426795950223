import React from 'react'

import classNames from 'classnames'
import { observer } from 'mobx-react'

const CheckboxColumn = ({ store }) => {
  const props = { store, visible: store.isAnyChecked() }

  return {
    header: <HeaderCheckbox {...props} />,
    accessorKey: 'id',
    minSize: 60,
    size: 60,
    maxSize: 60,
    sortable: false,
    enableResizing: false,
    headerClassName: 'checkbox-column',
    className: 'checkbox-column',
    cell: ({ getValue }) => <CellCheckbox {...props} value={getValue()} />
  }
}

class HeaderCheckboxRaw extends React.Component {
  store = this.props.store

  getChecked = () => {
    return this.store.getHeaderCheckboxState()
  }

  handleClick = () => {
    this.store.toggleHeaderCheckbox()
  }

  render() {
    return TableCheckbox(
      this.getChecked,
      this.handleClick,
      this.props.visible,
      this.store.getHeaderCheckboxIconClassName()
    )
  }
}

const HeaderCheckbox = observer(HeaderCheckboxRaw)

class CellCheckboxRaw extends React.Component {
  store = this.props.store

  getChecked = () => {
    return this.store.getRowCheckboxState(this.props.value)
  }

  handleClick = () => {
    this.store.toggleRowCheckbox(this.props.value)
  }

  render() {
    return TableCheckbox(this.getChecked, this.handleClick, this.props.visible)
  }
}

const CellCheckbox = observer(CellCheckboxRaw)

const TableCheckbox = (getChecked, onChange, visible, iconClassName = 'checkmark') => {
  const checked = getChecked()

  return (
    <label className={classNames('table-checkbox-container', { checked, visible })}>
      <input type='checkbox' checked={checked} onChange={onChange} />
      <span className={iconClassName} />
    </label>
  )
}

export { CheckboxColumn }
