import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import ActiveTable from '../ActiveTable'
import Tooltip from '../Tooltip'
import { edit, archive, discard, discardSelected, archiveSelected } from '../elements/actionDefinitions'

export const CheckedInAttendantCount = ({ type, id, count, prefix }) => (
  <React.Fragment>
    <a href={url(prefix, id, type)} className={`checked-in-${type}`} data-tip data-for={`${type}-count-${id}`}>
      {count}
    </a>

    <Tooltip id={`${type}-count-${id}`} className='accented' place='bottom' effect='solid'>
      {t(`v2.construction_objects.index.checked_in_${type}_count`)}
    </Tooltip>
  </React.Fragment>
)

class ConstructionObjectsTable extends React.Component {
  actions = {
    row: [
      edit(this.props.pathPrefix, 'v2.construction_objects.edit.title'),
      archive(this.props.pathPrefix),
      discard(this.props.pathPrefix)
    ],
    header: [archiveSelected(this.props.pathPrefix), discardSelected(this.props.pathPrefix)]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        translationPath='construction_objects'
        highlightedBy={['checked_in_employees_count', 'checked_in_guests_count']}
        inactiveBy='archived'
        onRowClick='navigateToResource'
      />
    )
  }
}

export default ConstructionObjectsTable
