import React, { useEffect } from 'react'

import { useFormContext, useWatch } from 'react-hook-form'

import useTranslate from '../../../../hooks/useTranslate'
import { SelectField, TextField } from '../../../Forms/HookFields'

const ConstructionSiteData = () => {
  const t = useTranslate('ConstructionObjects.Vedludb.ContractForm')
  const { constructionSites, formDisabled, requiredFields, setValue } = useFormContext()
  const options = constructionSites.map(site => ({ label: site.buvatlaujasNr, value: site.buvniecibasLietaId }))
  const selectedSite = useWatch({ name: 'buvniecibasLietaId' })

  useEffect(() => {
    const constructionSite = constructionSites.find(site => site.buvniecibasLietaId === selectedSite?.value)
    setValue('bisBuvniecibasLietasNr', constructionSite?.bisBuvniecibasLietasNr)
    setValue('buvatlaujasNr', constructionSite?.buvatlaujasNr)
  }, [setValue, constructionSites, selectedSite])

  return (
    <>
      <SelectField
        name='buvniecibasLietaId'
        label={t('.fields.labels.constructionSite')}
        placeholder={t('.fields.placeholders.constructionAllowanceNr')}
        options={options}
        disabled={formDisabled}
        required={requiredFields.buvniecibasLietaId}
      />
      <TextField
        name='bisBuvniecibasLietasNr'
        placeholder={t('.fields.placeholders.constructionSiteNr')}
        disabled={true}
      />
    </>
  )
}

export default ConstructionSiteData
