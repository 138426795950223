import React from 'react'

import classNames from 'classnames'

import Icon from '../Icon'
import styles from './SubItem.module.scss'

const SubItem = ({ children, onAdd, onRemove, className = undefined }) => {
  return (
    <div className={classNames(styles.subItem, className)}>
      {children}
      <Icon icon='iconPlusCircle' className={onAdd ? undefined : styles.disabled} onClick={onAdd} />
      <Icon icon='iconTrash' className={onRemove ? undefined : styles.disabled} onClick={onRemove} />
    </div>
  )
}

export default SubItem
