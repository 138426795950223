import FormWithRoles from './FormWithRoles'

const ADMIN = 'admin'
const MANAGER = 'manager'
const REGULAR = 'regular'
const SUPERVISOR = 'supervisor'

class UserForm extends FormWithRoles {
  static selector = '.edit_user, .new_user'

  constructor(form) {
    const checkBoxes = [
      { name: 'hasEdlus', visibleFor: role => [REGULAR, SUPERVISOR].includes(role) },
      { name: 'hasTasker', visibleFor: role => [REGULAR, SUPERVISOR].includes(role) }
    ]

    super({ form, checkBoxes, namespace: 'users' })

    const { syncAllUserGroupsCheck, hasEdlusInput, hasTaskerInput, roleInput } = this.selectors

    if (syncAllUserGroupsCheck) {
      syncAllUserGroupsCheck.addEventListener('change', this.toggleUserGroupsVisibility)
    }

    if (hasEdlusInput && roleInput) {
      hasEdlusInput.addEventListener('change', this.toggleCompaniesManagerInput)
      roleInput.addEventListener('change', this.toggleCompaniesManagerInput)

      this.toggleCompaniesManagerInput()
    }

    if (hasTaskerInput && roleInput) {
      hasTaskerInput.addEventListener('change', this.toggleTaskCreatorInput)
      roleInput.addEventListener('change', this.toggleTaskCreatorInput)

      this.toggleTaskCreatorInput()
    }
  }

  bindElements() {
    this.selectors = {
      roleInput: this.form.querySelector('#user_role'),
      taskerUserGroupsContainer: this.form.querySelector('.user_tasker_user_groups'),
      syncAllUserGroupsCheck: this.form.querySelector('#user_sync_all_user_groups'),
      hasEdlusInput: this.form.querySelector('#user_has_edlus'),
      hasEdlus: this.form.querySelector('.user_has_edlus'),
      hasTaskerInput: this.form.querySelector('#user_has_tasker'),
      hasTasker: this.form.querySelector('.user_has_tasker'),
      companiesManager: this.form.querySelector('.user_companies_manager'),
      taskCreator: this.form.querySelector('.user_task_creator')
    }
  }

  toggleUserGroupsVisibility = event => {
    const { taskerUserGroupsContainer } = this.selectors

    this.form.querySelectorAll('.rs__multi-value__remove').forEach(item => item.click())
    taskerUserGroupsContainer.classList[event.target.checked ? 'add' : 'remove']('d-none')
  }

  toggleCompaniesManagerInput = () => {
    const { hasEdlusInput, companiesManager, roleInput } = this.selectors

    if (!hasEdlusInput.checked || [ADMIN, MANAGER].includes(roleInput.value)) {
      companiesManager.classList.add('invisible')
    } else {
      companiesManager.classList.remove('invisible')
    }
  }

  toggleTaskCreatorInput = () => {
    const { hasTaskerInput, taskCreator, roleInput } = this.selectors

    if (!hasTaskerInput.checked || [ADMIN, MANAGER].includes(roleInput.value)) {
      taskCreator.classList.add('invisible')
    } else {
      taskCreator.classList.remove('invisible')
    }
  }
}

export default UserForm
