import React from 'react'

import ActiveTable from '../ActiveTable'

class DevicesTable extends React.Component {
  render() {
    return <ActiveTable {...this.props} />
  }
}

export default DevicesTable
