import dayjs from 'dayjs'
import compose from 'lodash/fp/compose'
import { makeObservable, action, observable } from 'mobx'
import qs from 'qs'
import { tenant } from 'utils/urlGenerator'

import { t } from '../utils/i18n'
import { StoreCheckbox } from './StoreCheckbox'
import { StoreData } from './StoreData'
import { StoreFilter } from './StoreFilter'
import { StoreTableScrollPages } from './StoreTableScrollPages'

class Store {
  defaultFilters = {
    keyword: undefined,
    duration: undefined,
    construction_object_id: undefined,
    company_id: undefined,
    status: undefined,
    role: undefined,
    permission: undefined,
    state: undefined,
    other: undefined,
    checkable_type: undefined,
    checkable_identifier: undefined,
    contract_kind: undefined,
    is_foreign: undefined,
    period: undefined,
    kind: undefined,
    is_foreigner: undefined,
    construction_object_vedludb_token: undefined,
    startDate: dayjs().startOf('month').format(t('date.formats.default_raw_sql_human')),
    endDate: dayjs().endOf('month').format(t('date.formats.default_raw_sql_human')),
    starting_time_start_date: undefined,
    starting_time_end_date: undefined,
    deadline_start_date: undefined,
    deadline_end_date: undefined,
    created_start_date: undefined,
    created_end_date: undefined,
    user_id: undefined,
    owner_id: undefined,
    closing_type: undefined,
    responsible: undefined,
    task_activities: undefined,
    construction_object_task_id: undefined,
    construction_object_sub_task_id: undefined
  }

  state = {
    url: undefined,
    data: [],
    dataSize: 0,
    page: 0,
    pages: 0,
    loading: false,
    tableRowCheckboxes: new Map(),
    tableScrollPages: new Map(),
    showFilter: false,
    sorted: undefined,
    defaultFilter: undefined,
    baseScope: undefined,
    timeZone: undefined,
    expanded: {},
    loadingRows: {},
    filtersHuman: {},
    filters: {
      ...this.defaultFilters
    }
  }

  constructor({
    url,
    defaultFilter,
    defaultSorted,
    baseScope,
    timeZone,
    onStateChange,
    onDataLoad = null,
    additionalParams = {}
  }) {
    makeObservable(this, {
      state: observable,
      clearFilters: action,
      setState: action,
      applySettings: action
    })

    this.defaultSorted = defaultSorted
    this.onStateChange = onStateChange
    this.onDataLoad = onDataLoad
    this.additionalParams = additionalParams

    if (this.storedSettings) {
      this.applySettings(this.storedSettings, { url, defaultFilter, baseScope, timeZone, sorted: defaultSorted })
    }
  }

  toggleLoadingRow(rowId) {
    this.setState({
      loadingRows: { ...this.state.loadingRows, [rowId]: !this.state.loadingRows[rowId] }
    })
  }

  clearFilters = () => {
    this.setState({
      showFilter: false,
      filters: { ...this.defaultFilters },
      sorted: this.defaultSorted
    })

    const { filters: _filters, ...params } = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    window.history.replaceState(null, null, `${window.location.pathname}?${qs.stringify(params)}`)

    this.reload()
  }

  collectSettings = () => {
    const { page, pageSize, showFilter, sorted, filters } = this.state

    return { page, pageSize, showFilter, sorted, filters }
  }

  setState(newState) {
    this.state = { ...this.state, ...newState }

    if (this.onStateChange) this.onStateChange()
  }

  applySettings(settings, props) {
    const queryFilter = qs.parse(window.location.search, { ignoreQueryPrefix: true }).filters || {}
    const showFilter = Object.keys(queryFilter).length > 0 || settings.showFilter

    this.state = {
      ...this.state,
      ...props,
      ...settings,
      showFilter,
      filters: {
        ...this.state.filters,
        ...settings.filters,
        ...queryFilter
      }
    }
  }

  saveSettings() {
    sessionStorage.setItem('currentSection', this.currentSection)
    sessionStorage.setItem(this.storageKey, JSON.stringify(this.collectSettings()))
  }

  get storedSettings() {
    const previousSection = sessionStorage.getItem('currentSection')

    if (!this.currentSection) return {}

    if (previousSection !== this.currentSection) {
      sessionStorage.clear()
    }

    const settingsJSON = sessionStorage.getItem(this.storageKey)

    return settingsJSON ? JSON.parse(settingsJSON) : {}
  }

  get currentSection() {
    const sectionRegex = new RegExp(`/${tenant()}/v2/([^/]*)/?.*`)
    const checkableLocation = location.pathname.replace('tasker/', '').replace('settings/', '')
    const sectionMatch = checkableLocation.match(sectionRegex)

    return sectionMatch && sectionMatch[1]
  }

  get storageKey() {
    return `preferences-${location.pathname}`
  }

  updateState(newState) {
    this.setState({ ...newState })
  }
}

export default compose(StoreFilter, StoreData, StoreCheckbox, StoreTableScrollPages)(Store)
