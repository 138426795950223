import React from 'react'

import ActiveTable from '../ActiveTable'
import { edit, remove, removeSelected } from '../elements/actionDefinitions'

class ConstructionObjectUsersTable extends React.Component {
  pathPrefix = 'v2/construction_object_users'

  formParams = {
    context: 'construction_object',
    construction_object_user: { construction_object_id: this.props.construction_object_id }
  }

  actions = {
    row: [
      edit(this.pathPrefix, 'v2.construction_object_users.contexts.construction_object.edit.title', this.formParams),
      remove(this.pathPrefix)
    ],
    header: [removeSelected(this.pathPrefix)]
  }

  canNavigateToResource({ permissions }) {
    return permissions.show
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        highlightedBy='user_online'
        pathPrefix={this.pathPrefix}
        pathResourceId='user_id'
        canNavigateToResource={this.canNavigateToResource}
        translationPath={['construction_object_users', 'contexts', 'construction_object']}
        formParams={this.formParams}
        reloadPageOnSuccess={true}
        onRowClick='openEditForm'
      />
    )
  }
}

export default ConstructionObjectUsersTable
