import { isEqual } from 'lodash'

export const unitSelectIndex = (units, attributes) => units.findIndex(item => isEqual(item, attributes))

export const filterSelectableEmployeeDays = (selected, forSelection) => {
  return forSelection.filter(item => unitSelectIndex(selected, item) === -1)
}

export const deselectByObject = (selected, deselectable) => {
  const deselectIdxs = deselectable.map(item => unitSelectIndex(selected, item))
  return selected.filter((_, idx) => !deselectIdxs.includes(idx))
}
