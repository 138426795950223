import React from 'react'

import ActiveTable from '../../ActiveTable'
import {
  edit,
  discard,
  unlinkTimeTrackable,
  discardSelected,
  unlinkTimeTrackableSelected
} from '../../elements/actionDefinitions'

class ExternalDevicesTable extends React.Component {
  pathPrefix = 'v2/settings/external_devices'

  actions = {
    row: [
      edit(this.pathPrefix, 'v2.settings.external_devices.edit.title'),
      unlinkTimeTrackable(this.pathPrefix),
      discard(this.pathPrefix)
    ],
    header: [unlinkTimeTrackableSelected(this.pathPrefix), discardSelected(this.pathPrefix)]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        pathPrefix={this.pathPrefix}
        translationPath='settings.external_devices'
        onRowClick='openEditForm'
      />
    )
  }
}

export default ExternalDevicesTable
