import React from 'react'

import classNames from 'classnames'

import Icon from './Icon'

const Checkbox = ({ checked, disabled, ...others }) => (
  <span className={classNames('checkbox', { checked, disabled })}>
    {checked && <Icon icon='checkMark' />}
    <input type='checkbox' checked={checked} disabled={disabled} {...others} />
  </span>
)

export default Checkbox
