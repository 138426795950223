import React from 'react'

import styles from './ToggleCompaniesListButton.module.css'

export default ({ onClick, isActive }) => (
  <button onClick={onClick} className='btn btn-secondary btn-sm btn-filter-companies flex-grow-0 position-relative'>
    <i className='fa fa-sliders my-2 mr-0' />
    {isActive && <div className={styles.activeBubble}></div>}
  </button>
)
