import React from 'react'

import dayjs from 'dayjs'
import Select from 'react-select'

import { t } from '../../../../utils/i18n'
import DateTimePicker from '../../../DateTimePicker'
import { REACT_SELECT_PROPS } from '../../../Select'

class DurationFields extends React.Component {
  state = { showTimeField: !!this.props.durationTime }

  renderDurationTimePickerWithLabel = durationTime => (
    <div className='task-time-input-container'>
      <span className='col-form-label ml-2 mr-2'>{t('v2.tasker.tasks.regularity_common_fields.duration_time')}</span>
      <DateTimePicker
        input_options={{
          type: 'time',
          name: 'tasker_task[regularity][duration_time]',
          value: durationTime ? dayjs(durationTime, t('time.formats.time_human_js')) : dayjs().startOf('day'),
          disabled: this.props.disabled
        }}
      />
    </div>
  )

  onDurationDateChange = e => {
    this.setState({ showTimeField: e.value !== '' })
  }

  render() {
    const selectName = 'tasker_task[regularity][duration_days]'
    const selectId = 'tasker_task_regularity_duration_days'
    const { options, durationDays, durationTime, disabled } = this.props

    return (
      <div className='form-group'>
        <label className='col-sm-4 col-form-label' htmlFor={selectId}>
          {t('v2.tasker.tasks.regularity_common_fields.duration_days')}
        </label>
        <div className='task-duration-inputs-container fields-right-container'>
          <div className='task-duration-days-container'>
            <Select
              {...REACT_SELECT_PROPS}
              options={options}
              defaultValue={durationDays || options[0]}
              isClearable={false}
              name={selectName}
              inputId={selectId}
              onChange={this.onDurationDateChange}
              isDisabled={disabled}
            />
          </div>
          {this.state.showTimeField && this.renderDurationTimePickerWithLabel(durationTime)}
        </div>
      </div>
    )
  }
}

export default DurationFields
