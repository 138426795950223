import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

let cropper

class ImageUploader {
  static selector = `form.edit_employee, form.new_employee, form.new_construction_object_employee, form.settings_edit_account,
                     form.new_tasker_user_group, form.edit_tasker_user_group, form.new_user, form.edit_user, form.new_company_user`

  constructor(form) {
    this.form = form

    this.bindElements()

    if (this.selectors.fileInput) {
      const { avatarColors, src } = this.selectors.fileInput.dataset

      this.avatarColors = avatarColors && JSON.parse(avatarColors)
      form.querySelector('[name$="[colors]"]').value = this.avatarColors
      this.bindEvents()
      this.applyImage(src)
      this.toggleClearButton(src)
    }
  }

  bindElements() {
    this.selectors = {
      fileInput: this.form.querySelector('[data-image-upload-input'),
      destroyInput: this.form.querySelector('[name$="[purge]"'),
      clearButton: this.form.querySelector('[data-action=clear-image]'),
      resetButton: this.form.querySelector('[data-action=reset-image]'),
      zoomInButton: this.form.querySelector('[data-action=zoom-in-image]'),
      zoomOutButton: this.form.querySelector('[data-action=zoom-out-image]'),
      image: this.form.querySelector('#image-placeholder img'),
      imageCropper: this.form.querySelector('#image-cropper'),
      imagePreview: this.form.querySelector('#image-preview'),
      avatarAttributeInputs: this.form.querySelectorAll('[data-avatar-attribute]')
    }
  }

  bindEvents() {
    this.selectors.fileInput.addEventListener('change', this.onImageChange)
    this.selectors.clearButton.addEventListener('click', this.removeImage)
    this.selectors.resetButton.addEventListener('click', this.resetCropper)
    this.selectors.zoomInButton.addEventListener('click', this.zoomInCropper)
    this.selectors.zoomOutButton.addEventListener('click', this.zoomOutCropper)
    this.selectors.avatarAttributeInputs.forEach(input => input.addEventListener('keyup', this.applyInitials))
  }

  initialsSrc() {
    const { avatarApiUrl } = this.selectors.fileInput.dataset
    const initials = Array.from(this.selectors.avatarAttributeInputs, input => input.value.replace(' ', '+'))
      .filter(Boolean)
      .join('+')

    if (this.avatarColors && initials) {
      return `${avatarApiUrl}&background=${this.avatarColors[0]}&color=${this.avatarColors[1]}&name=${initials}`
    }
  }

  applyInitials = () => {
    if (!this.selectors.fileInput.dataset.src) {
      this.applyImage(this.initialsSrc())
    }
  }

  applyImage(src) {
    this.selectors.image.src = src || this.initialsSrc() || this.selectors.fileInput.dataset.placeholder
  }

  onImageChange = () => {
    this.destroyCropper()

    const file = this.selectors.fileInput.files[0]
    const src = file && URL.createObjectURL(file)

    if (file) {
      if (this.selectors.fileInput.dataset.src) {
        this.selectors.destroyInput.value = true
      } else {
        this.selectors.destroyInput.value = null
      }

      this.initCropper(src)
    }

    this.applyImage(src)
    this.toggleClearButton(src)
  }

  initCropper = src => {
    const form = this.form
    const image = this.selectors.imageCropper.querySelector('img')

    image.src = src
    this.toggleImagePreviewContainer()

    cropper = new Cropper(image, {
      aspectRatio: 1,
      preview: '#image-preview',
      crop(_) {
        const { x, y, width, height } = cropper.getData()

        form.querySelector('[name$="[x]"]').value = x
        form.querySelector('[name$="[y]"]').value = y
        form.querySelector('[name$="[width]"]').value = width
        form.querySelector('[name$="[height]"]').value = height
      }
    })
  }

  removeImage = () => {
    this.selectors.fileInput.value = null
    this.selectors.destroyInput.value = true

    this.destroyCropper()
    this.onImageChange()
  }

  toggleClearButton(src) {
    if (src) {
      this.selectors.clearButton.classList.remove('hidden')
    } else {
      this.selectors.clearButton.classList.add('hidden')
    }
  }

  destroyCropper() {
    if (cropper) {
      this.toggleImagePreviewContainer()

      cropper.destroy()
      cropper = null
    }
  }

  resetCropper() {
    cropper.reset()
  }

  zoomInCropper() {
    cropper.zoom(0.1)
  }

  zoomOutCropper() {
    cropper.zoom(-0.1)
  }

  toggleImagePreviewContainer() {
    const { imagePreview, imageCropper } = this.selectors

    if (cropper) {
      imagePreview.classList.add('d-none')
      imageCropper.classList.add('d-none')
      imageCropper.querySelector('img').removeAttribute('src')
    } else {
      imagePreview.classList.remove('d-none')
      imageCropper.classList.remove('d-none')
    }
  }
}

export default ImageUploader
