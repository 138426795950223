import React, { useState } from 'react'

import classNames from 'classnames'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import localeData from 'dayjs/plugin/localeData'

import { t } from '../../utils/i18n'
import Icon from '../Icon'
import CompaniesSelector from './ListHeader/CompaniesSelector'
import ToggleCompaniesListButton from './ListHeader/ToggleCompaniesListButton'
import { useExceptionDaysContext } from './contexts/ExceptionDaysContext'

dayjs.extend(localeData)
dayjs.extend(isToday)

const DateColumn = ({ metadata, weekDayNames }) => {
  const { date, today, saturday, sunday, dateIso } = metadata
  const { isHoliday } = useExceptionDaysContext()

  return (
    <div key={date} className={classNames('scheduler-header__date-column', { sunday })}>
      <div
        className={classNames('scheduler-header__date-column__content', {
          today,
          saturday,
          sunday,
          holiday: isHoliday(dateIso)
        })}
      >
        <div className='week-name'>{weekDayNames[date.day()]}</div>
        {date.get('date')}
      </div>
      {today && <span className='scheduler-header__date-column__today-border' />}
    </div>
  )
}

const SchedulerHeader = ({ datesMetadata, searchTerm, setSearchTerm, chosenCompaniesIds, setChosenCompaniesIds }) => {
  const weekDayNames = dayjs.localeData().weekdaysMin()
  const handleSearch = e => setSearchTerm(e.target.value)
  const [isListVisible, setIsListVisible] = useState(false)
  const toggleList = () => setIsListVisible(!isListVisible)

  return (
    <div className='scheduler-header'>
      <div className='scheduler-filters'>
        <div className='scheduler-header__search-bar flex-grow-1'>
          <i className='fa fa-search' />
          <input type='text' placeholder={t('filters.search')} value={searchTerm} onChange={handleSearch} />
          {searchTerm && <Icon icon='cross' className='clear-icon' onClick={() => setSearchTerm('')} />}
        </div>
        <ToggleCompaniesListButton onClick={toggleList} isActive={chosenCompaniesIds.length > 0} />
      </div>
      <div className='scheduler-header__date-columns'>
        {datesMetadata.map((metadata, index) => (
          <DateColumn metadata={metadata} weekDayNames={weekDayNames} key={index} />
        ))}
      </div>
      {isListVisible && (
        <CompaniesSelector
          className='position-absolute filter-companies-list'
          chosenCompaniesIds={chosenCompaniesIds}
          setChosenCompaniesIds={setChosenCompaniesIds}
          outsideClickHandler={() => toggleList(false)}
        />
      )}
    </div>
  )
}

export default SchedulerHeader
