import React from 'react'

import classNames from 'classnames'

import { t } from '../../../utils/i18n'
import Avatar from '../../Avatar'
import Store from '../../Store'
import TableContainer from '../../TableContainer'
import UserGroupsParentsFilter from './UserGroupsParentsFilter'
import UserGroupsTable from './UserGroupsTable'

class UserGroupsParentTableContainer extends TableContainer {
  state = {
    expanded: {},
    loadingRows: {}
  }

  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'name', desc: false }],
    baseScope: this.props.base_scope
  })

  columns = [
    {
      accessorKey: 'row-expander',
      id: 'row-expander',
      header: '',
      size: 40,
      maxSize: 40,
      sortable: false,
      enableResizing: false,
      minSize: 40,
      cell: ({ row, row: { original } }) => {
        return !original.is_parent || original.children_count > 0 ? (
          <div style={{ cursor: 'pointer' }}>
            {this.renderExpander(row, original.is_parent, row.getIsExpanded(), original.parent_id_prefix)}
          </div>
        ) : null
      },
      getClassName: this.getExpandableCellClassName,
      hideable: false
    },
    {
      header: t('activerecord.attributes.tasker/user_group.name'),
      accessorKey: 'name',
      cell: ({ getValue, row }) => {
        let value = getValue()

        if (row.original.is_parent) {
          value = (
            <>
              <span>{value}</span>
              <span className='children-count'>({row.original.children_count})</span>
            </>
          )
        }

        return <Avatar src={row.original.image_url} text={value} />
      }
    },
    {
      header: t('activerecord.attributes.tasker/user_group.users_count'),
      accessorKey: 'users_count'
    },
    {
      header: t('activerecord.attributes.tasker/user_group.draft_tasks_count'),
      accessorKey: 'draft_tasks_count',
      size: 50,
      minSize: 50
    },
    {
      header: t('activerecord.attributes.tasker/user_group.in_progress_tasks_count'),
      accessorKey: 'in_progress_tasks_count',
      size: 50,
      minSize: 50
    },
    {
      header: t('activerecord.attributes.tasker/user_group.delayed_tasks_count'),
      accessorKey: 'delayed_tasks_count',
      size: 50,
      minSize: 50
    }
  ]

  getTrClassIdentifier = ({ original: { id } }) => `user_group_parent_row_${id}`

  resetExpanded = () => {
    this.setState({ expanded: {} })
  }

  getTrClassName = row =>
    classNames({
      [this.getTrClassIdentifier(row)]: true
    })

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <UserGroupsParentsFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <UserGroupsTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
          getTrClassName={this.getTrClassName}
          parentsOnly={true}
          onSortedChange={this.resetExpanded}
          onExpand={row =>
            row.depth === 0 &&
            this.onExpandedChange({
              rowId: row.id,
              parentId: row.original.id,
              path: 'v2/tasker/user_groups_parents/child_groups',
              subRowName: 'secondLevelRows',
              parentIdPrefix: row.original.parent_id_prefix,
              open: !row.getIsExpanded()
            })
          }
          expanded={this.state.expanded}
        />
      </div>
    )
  }
}

export default UserGroupsParentTableContainer
