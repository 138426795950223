import React, { useContext } from 'react'

import { ExceptionDaysContext } from './Shifts'

const WeekendOverlay = ({ width }) => <div className='weekend-overlay' style={{ width }} />

export default ({ day, width }) => {
  const { isHoliday } = useContext(ExceptionDaysContext)

  const isExceptionDay = isHoliday(day.format('YYYY-MM-DD'))

  if (day.day() == 6) {
    return <WeekendOverlay width={width * 2} />
  }

  return isExceptionDay ? <WeekendOverlay width={width} /> : null
}
