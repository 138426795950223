import React from 'react'

import SVG from 'react-inlinesvg'

import addAttachment from '../images/add-attachment.svg'
import arrow from '../images/arrow.svg'
import attachmentFilled from '../images/attachment-filled.svg'
import attachment from '../images/attachment.svg'
import avatar from '../images/avatar-placeholder.svg'
import calendar from '../images/calendar.svg'
import chatButtonIcon from '../images/chat-button-icon.svg'
import checkKindOffline from '../images/check_kind_offline.svg'
import checkIn from '../images/check_log_direction_in.svg'
import checkOut from '../images/check_log_direction_out.svg'
import logOut from '../images/check_out.svg'
import checkWithAuto from '../images/check_with_auto.svg'
import checkWithFingerprint from '../images/check_with_fingerprint.svg'
import checkWithManual from '../images/check_with_manual.svg'
import checkWithNfc from '../images/check_with_nfc.svg'
import checkWithRemote from '../images/check_with_remote.svg'
import checkMark from '../images/checkmark.svg'
import circleCross from '../images/circle-cross.svg'
import clock from '../images/clock.svg'
import cog from '../images/cog.svg'
import company from '../images/companies.svg'
import cross from '../images/cross.svg'
import download from '../images/download.svg'
import employee from '../images/employees.svg'
import expand from '../images/expand.svg'
import filePdf from '../images/file_pdf.svg'
import fileXlsx from '../images/file_xlsx.svg'
import fileXlsxPlus from '../images/file_xlsx_plus.svg'
import filterCalendar from '../images/filter_calendar.svg'
import finish from '../images/finish.svg'
import globe from '../images/globe.svg'
import gpsDisabled from '../images/gps_disabled.svg'
import gpsError from '../images/gps_error.svg'
import iconChatMention from '../images/icon-chat-mention.svg'
import iconChatUnread from '../images/icon-chat-unread.svg'
import iconChat from '../images/icon-chat.svg'
import iconCompany from '../images/icon-company.svg'
import iconEmployee from '../images/icon-employee.svg'
import iconLocationA from '../images/icon-location-a.svg'
import iconLocation from '../images/icon-location.svg'
import iconPencil from '../images/icon-pencil.svg'
import iconPlusCircle from '../images/icon-plus-circle.svg'
import iconPlus from '../images/icon-plus.svg'
import iconResponsible from '../images/icon-responsible.svg'
import iconTrash from '../images/icon-trash.svg'
import infoOutlined from '../images/info-o.svg'
import info from '../images/info.svg'
import letterT from '../images/letter-t.svg'
import location from '../images/location.svg'
import mapMarker from '../images/map_marker.svg'
import more from '../images/more.svg'
import noMessages from '../images/no-messages.svg'
import permilles from '../images/permilles.svg'
import receiveNotification from '../images/receive-notification.svg'
import regularity from '../images/regularity.svg'
import related from '../images/related.svg'
import report from '../images/report.svg'
import start from '../images/start.svg'
import subtasks from '../images/subtasks.svg'
import sync from '../images/sync.svg'
import timerRunning from '../images/timer-running.svg'
import timer from '../images/timer.svg'
import warningFilled from '../images/warning-filled.svg'
import warning from '../images/warning.svg'

const icons = {
  checkIn,
  checkOut,
  clock,
  checkWithNfc,
  checkWithFingerprint,
  checkWithRemote,
  checkWithManual,
  checkWithAuto,
  checkKindOffline,
  gpsDisabled,
  gpsError,
  mapMarker,
  checkMark,
  avatar,
  cog,
  info,
  iconPencil,
  iconPlus,
  iconPlusCircle,
  iconTrash,
  iconLocation,
  iconLocationA,
  iconEmployee,
  iconCompany,
  iconResponsible,
  iconChat,
  iconChatUnread,
  iconChatMention,
  report,
  globe,
  calendar,
  filterCalendar,
  cross,
  circleCross,
  arrow,
  start,
  finish,
  timer,
  subtasks,
  noMessages,
  related,
  attachment,
  attachmentFilled,
  timerRunning,
  receiveNotification,
  warning,
  warningFilled,
  regularity,
  more,
  logOut,
  location,
  employee,
  company,
  letterT,
  infoOutlined,
  sync,
  filePdf,
  fileXlsx,
  fileXlsxPlus,
  download,
  expand,
  addAttachment,
  chatButtonIcon,
  permilles
}

export default ({ icon, className = '', ...restProps }) => (
  <span className={`svg-icon ${className}`} {...restProps} data-icon={icon}>
    <SVG src={icons[icon]} />
  </span>
)
