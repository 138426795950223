import React from 'react'

import { t } from '../../utils/i18n'
import Tooltip from '../Tooltip'

const BooleanIcon = ({ checked }) =>
  checked && (
    <span className='checkmark selected'>
      <i className='fa fa-check' />
    </span>
  )

const HistoricalTimeEntryIcon = ({ edited, id }) => {
  if (!edited) return null

  const tooltipId = `historical-time-entry-${id}`

  return (
    <>
      <i className='fa fa-history historical-time-entry' data-tip data-for={tooltipId} />

      <Tooltip id={tooltipId} className='accented' place='bottom' effect='solid'>
        {t('registration_time_is_updated')}
      </Tooltip>
    </>
  )
}
const IconWithTooltip = ({ type, text, checked, icon, id }) => {
  if (!checked) return null

  const tooltipId = `icon-tooltip-${id}`

  return (
    <span className='checkmark'>
      <i className={`${type} fa fa-${icon}`} data-tip data-for={tooltipId} />
      <Tooltip id={tooltipId} className='accented' place='bottom' effect='solid'>
        {text}
      </Tooltip>
    </span>
  )
}

export { BooleanIcon, HistoricalTimeEntryIcon, IconWithTooltip }
