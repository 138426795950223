import React, { useState, useEffect, useMemo } from 'react'

import ReactDOM from 'react-dom'

import useTranslate from '../../hooks/useTranslate'
import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Switch from '../Forms/Fields/Switch'
import Modal from '../Modal'
import { addOrRemoveSelectedItem, OptionList } from '../Options'
import { useSchedulesContext } from './contexts/SchedulesContext'

const renderLabel = schedule => (
  <div className='shift-label-container'>
    <div className='shift-label'>
      <span className='indicator' style={{ backgroundColor: schedule.color }} />
      <div className='text-truncate'>{schedule.label}</div>
    </div>
    <span className='employee-count'>
      {t('components.Scheduler.PdfExportModal.employee_count', { count: schedule.employeeCount })}
    </span>
  </div>
)

const PdfExportModal = ({ setOpen, exportParams }) => {
  const [selectedSchedules, setSelectedSchedules] = useState([])
  const [divide, setDivide] = useState(false)
  const { schedules } = useSchedulesContext()
  const t = useTranslate('Scheduler.PdfExportModal')

  const formattedSchedules = useMemo(
    () =>
      schedules.map(schedule => ({
        value: schedule.id,
        label: schedule.name,
        employeeCount: schedule.employee_count,
        color: schedule.color
      })),
    [schedules]
  )

  useEffect(() => {
    if (formattedSchedules.length) {
      setSelectedSchedules(formattedSchedules)
    }
  }, [formattedSchedules])

  const exportPdf = url('v2/schedules/exports.pdf', {
    ...exportParams,
    divide: divide,
    ids: selectedSchedules.map(shift => shift.value).join(',')
  })

  const closeModal = () => {
    setOpen(false)
  }

  return ReactDOM.createPortal(
    <Modal
      isOpen
      className='modal-lg modal-dialog-centered new-modal shift-export-modal'
      title={t('.title')}
      onClose={closeModal}
    >
      <div className='switch-container mb-2'>
        <label htmlFor='divide_schedule'>{t('.divide_schedule')}</label>
        <Switch id='divide_schedule' checked={divide} onChange={() => setDivide(!divide)} />
      </div>

      <OptionList
        isMulti
        options={formattedSchedules}
        selectedItems={selectedSchedules}
        renderLabel={renderLabel}
        onSelectAll={setSelectedSchedules}
        onSelect={selectedItem =>
          addOrRemoveSelectedItem({ selectedItem, selectedItems: selectedSchedules, onChange: setSelectedSchedules })
        }
      />
      <hr className='delimiter' />
      <div className='row'>
        <div className='col-6 mt-1 d-flex'>
          <a
            onClick={closeModal}
            className='btn btn-primary flex-grow-1 px-0'
            href={exportPdf}
            target='_blank'
            rel='noreferrer'
          >
            {t('export')}
          </a>
          <button className='btn btn-secondary ml-3 flex-grow-1 px-0' onClick={closeModal}>
            {t('cancel')}
          </button>
        </div>
      </div>
    </Modal>,
    document.querySelector('.modal-container')
  )
}

export default PdfExportModal
