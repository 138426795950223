import React from 'react'

import classNames from 'classnames'
import { useController, useFormContext } from 'react-hook-form'

const WeekDay = props => {
  const { selected, disabled, id, dayName } = props
  const { control } = useFormContext()
  const {
    field: { onChange, value },
    field
  } = useController({ control, name: `[regularity][week_days][day_${id + 1}]`, defaultValue: selected || false })

  const handleSelectedDay = () => {
    onChange(!value)
  }

  return (
    <div
      className={classNames('task-regularity-clickable-day task-regularity-week-day', {
        active: value,
        disabled: disabled
      })}
      onClick={() => !disabled && handleSelectedDay()}
    >
      {dayName}
      <input type='hidden' {...field} />
    </div>
  )
}

export default WeekDay
