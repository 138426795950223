import React from 'react'

import classNames from 'classnames'
import { useField, useFormikContext } from 'formik'

export default ({ options, disabled }) => {
  const name = 'regularity'
  const [field, _meta, _helpers] = useField({ name })
  const { setFieldValue } = useFormikContext()
  const { value } = field

  const addOrRemoveWeekDay = wday => {
    let newValue

    if (value.includes(wday)) {
      newValue = value.filter(val => val !== wday)
    } else {
      newValue = value.concat(wday)
    }

    setFieldValue(name, newValue)
  }

  return (
    <div className='regularity-options'>
      {options.map((option, i) => {
        const checked = value.includes(option.value)
        return (
          <label key={i} className={classNames('day', { selected: checked, disabled })}>
            <input
              type='checkbox'
              disabled={disabled}
              onChange={() => addOrRemoveWeekDay(option.value)}
              checked={checked}
            />
            {option.label}
          </label>
        )
      })}
    </div>
  )
}
