import React from 'react'

import { t } from '../../../utils/i18n'
import { formatDuration } from '../../../utils/schedulerDuration'

const ShiftStats = ({ dayData }) => {
  const { total_work_time_duration, total_check_log_duration, total_outside_work_time_duration } = dayData

  return (
    <div className='complete-day-stats text-right'>
      <div className='text-lighter'>
        <span className='mr-1'>{t('v2.schedules.shift_day.inside_work_time')}</span>
        <span>{formatDuration(total_work_time_duration)}</span>
      </div>
      <div className='text-danger'>
        <span className='mr-1'>{t('v2.schedules.shift_day.outside_work_time')}</span>
        <span>{formatDuration(total_outside_work_time_duration)}</span>
      </div>
      <div className='text-lighter'>
        <span className='mr-1'>{t('v2.schedules.shift_day.overall')}</span>
        <span>{formatDuration(total_check_log_duration)}</span>
      </div>
    </div>
  )
}

export default ShiftStats
