import React from 'react'

import classNames from 'classnames'
import I18n from 'i18n-js'

import useShiftsCompanies from '../hooks/useShiftsCompanies'
import styles from './CompaniesSelector.module.css'

const CompaniesListItemCheckbox = ({ checked, disabled, onChange, className, dataName }) => (
  <label className={classNames(styles.checkbox, className, checked ? styles.checked : {})} htmlFor={dataName}>
    <input type='checkbox' checked={checked} disabled={disabled} onChange={onChange} id={dataName} />
    <span className={styles.checkmark} />
  </label>
)

const CompaniesListItem = ({ className, title, checked, disabled, onChange }) => (
  <div className={`d-flex flex-row ${className}`}>
    <CompaniesListItemCheckbox
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      className={'filter-companies-checkbox'}
      dataName={title.toLowerCase().replace(/[\s_]+/g, '-')}
    />
    <span className='flex-grow-1 pa-1 px-2'>{title}</span>
  </div>
)

export default ({ className, chosenCompaniesIds, setChosenCompaniesIds }) => {
  const { companies, isLoading } = useShiftsCompanies()

  const toggle = id => {
    let newIds = isChosen(id) ? removeCompany(id) : addCompany(id)

    if (companies.every(company => newIds.includes(company.id))) {
      newIds = []
    }

    setChosenCompaniesIds(newIds)
  }

  const isChosen = id => chosenCompaniesIds.includes(id)

  const addCompany = id => [...chosenCompaniesIds, id]

  const removeCompany = id => chosenCompaniesIds.filter(chosenId => chosenId !== id)

  return (
    <div className={classNames(styles.list, className)}>
      <CompaniesListItem
        checked={chosenCompaniesIds.length === 0}
        disabled={chosenCompaniesIds.length === 0}
        title={I18n.t('filters.toggle_all_companies')}
        onChange={() => setChosenCompaniesIds([])}
      />

      {!isLoading && companies.length > 0 && (
        <>
          <hr />
          {companies.map(company => (
            <CompaniesListItem
              key={company.id}
              className='mt-2'
              checked={isChosen(company.id)}
              title={company.name}
              onChange={() => toggle(company.id)}
            />
          ))}
        </>
      )}
    </div>
  )
}
