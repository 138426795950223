import React, { useState, useRef, useMemo } from 'react'

import classNames from 'classnames'
import dayjs from 'dayjs'

import useHandleOutsideClick from '../../hooks/useHandleOutsideClick'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { t } from '../../utils/i18n'
import { isMacOS } from '../../utils/macOsChecker'
import { url } from '../../utils/urlGenerator'
import DateTimePicker from '../DateTimePicker'
import { ExportButton, ExportRange } from '../ExportButton'
import Icon from '../Icon'
import PdfExportModal from './PdfExportModal'
import ShiftTimeToggler from './common/ShiftTimeToggler'
import { useMonthContext } from './contexts/MonthContext'

const MonthSelect = ({ goToMonth }) => {
  const [open, setOpen] = useState(false)
  const monthSelectRef = useRef(null)
  const { month } = useMonthContext()

  useHandleOutsideClick({ ref: monthSelectRef, onOutsideClick: () => setOpen(false) })

  const monthDayjs = dayjs(month)
  const monthName = capitalizeFirstLetter(monthDayjs.format('MMMM'))
  const year = monthDayjs.format('YYYY')

  return (
    <div ref={monthSelectRef} className='month-navigation__select-container'>
      <button
        className={classNames('btn btn-shadowed month-navigation__select', { open })}
        onClick={e => {
          e.nativeEvent.stopImmediatePropagation()
          setOpen(!open)
        }}
      >
        <div className='month-navigation__select__selected-info'>
          <div className='year'>{year}</div>
          <div className='month'>{monthName}</div>
        </div>
        <div className={classNames('month-navigation__select__expander', { open })}>
          <i className={`fa fa-sort-${open ? 'up' : 'down'}`} />
        </div>
      </button>
      {open && <DateTimePicker onChange={goToMonth} type='month' value={new Date(month)} inline />}
    </div>
  )
}

const MonthButton = ({ onClick, type }) => (
  <button
    className='btn btn-sm btn-shadowed month-navigation__arrow-button'
    onClick={onClick}
    title={t(`v2.shifts.index.switch_months.${type}`)}
  >
    <i className={`fa fa-caret-${type}`} />
  </button>
)

const SchedulerToolbar = ({ goToPrevMonth, goToNextMonth, goToMonth, searchTerm }) => {
  const { month } = useMonthContext()
  const monthDayjs = dayjs(month, 'YYYY-MM')
  const [pdfModalOpen, setPdfModalOpen] = useState(false)

  const [exportIntervalStart, setExportIntervalStart] = useState(monthDayjs.format('YYYY-MM-DD'))
  const [exportIntervalEnd, setExportIntervalEnd] = useState(monthDayjs.endOf('month').format('YYYY-MM-DD'))

  const keyName = useMemo(() => (isMacOS() ? 'Cmd (⌘)' : 'Ctrl'), [])

  const exportParams = {
    date_from: exportIntervalStart,
    date_to: exportIntervalEnd,
    ...(searchTerm && { searchTerm })
  }

  const exportUrl = path => url(`v2/schedules/${path}`, exportParams)

  return (
    <div className='scheduler-toolbar'>
      <div className='month-navigation'>
        <div className='btn-group month-navigation__arrow-container' role='group'>
          <MonthButton
            onClick={e => {
              e.nativeEvent.stopImmediatePropagation()
              goToPrevMonth()
            }}
            type='left'
          />
          <MonthButton
            onClick={e => {
              e.nativeEvent.stopImmediatePropagation()
              goToNextMonth()
            }}
            type='right'
          />
        </div>
        <MonthSelect goToMonth={goToMonth} />
        <div className='usage-info'>
          <i className={classNames('fa fa-info-circle')} />
          {t('v2.shifts.index.ctrl_info', { key: keyName })}
        </div>
      </div>

      <div className='d-flex align-items-center'>
        <ShiftTimeToggler />
        <ExportButton
          optionsContent={
            <>
              <a className='option' href={exportUrl('export_xlsx')} target='_blank' rel='noreferrer'>
                <Icon icon='fileXlsx' />
                {t('v2.shifts.index.export.report')}
              </a>
              <button onClick={() => setPdfModalOpen(true)} className='option'>
                <Icon icon='filePdf' />
                {t('v2.shifts.index.export.shift_schedule')}
              </button>
            </>
          }
        >
          <ExportRange
            startDate={exportIntervalStart}
            endDate={exportIntervalEnd}
            onStartDateChange={date => setExportIntervalStart(dayjs(date).format('YYYY-MM-DD'))}
            onEndDateChange={date => setExportIntervalEnd(dayjs(date).format('YYYY-MM-DD'))}
          />
        </ExportButton>
      </div>
      {pdfModalOpen && <PdfExportModal setOpen={setPdfModalOpen} exportParams={exportParams} />}
    </div>
  )
}

export default SchedulerToolbar
