import React, { useState, useEffect, useContext } from 'react'

import axios from 'axios'
import dayjs from 'dayjs'
import { useFormik, FormikProvider } from 'formik'
import Turbolinks from 'turbolinks'
import * as Yup from 'yup'

import { showConfirmation, getApiErrorMessage, showErrorModal } from '../../utils/confirmations'
import { formatTime } from '../../utils/formatTime'
import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Icon from '../Icon'
import { MonthContext, ShiftContext } from '../Shifts/Shifts'
import { versionForCurrentDay } from '../Shifts/shiftDayHelper'
import { TextField, TimeFieldsBlock, SelectField } from './Fields'
import { arrayFormatter, objectFormatter, selectFormatter, stringFormatter, timeFormatter } from './Formatters'
import { formatValuesForSubmit } from './Formatters/submit'

Yup.addMethod(Yup.object, 'bothInShiftTime', function () {
  return this.test('bothInShiftTime', t('v2.shifts.index.form.errors.outside_shift'), function (value) {
    const [_parent1, parent2, ..._others] = this.from
    const { start_time, end_time } = parent2.value

    if (!(start_time && end_time && value && value.start && value.finish)) return true

    const shiftStart = dayjs(start_time, 'HH:mm')
    let shiftEnd = dayjs(end_time, 'HH:mm')

    const breakTimeStart = dayjs(value.start, 'HH:mm')
    let breakTimeFinish = dayjs(value.finish, 'HH:mm')

    if (breakTimeFinish.isBefore(breakTimeStart)) {
      breakTimeFinish = breakTimeFinish.add(1, 'day')
    }

    if (shiftEnd.isBefore(shiftStart)) {
      shiftEnd = shiftEnd.add(1, 'day')
    }

    const breakTimeStartInShift = breakTimeStart.isAfter(shiftStart) && breakTimeStart.isBefore(shiftEnd)

    const breakTimeFinishInShift = breakTimeFinish.isAfter(shiftStart) && breakTimeFinish.isBefore(shiftEnd)

    return breakTimeStartInShift && breakTimeFinishInShift
  })
})

Yup.addMethod(Yup.string, 'bothPeriodTimes', function (comparableField) {
  return this.test('both_period_times', t('v2.shifts.index.form.errors.required'), function (value) {
    return value ? true : !this.parent[comparableField]
  })
})

Yup.addMethod(Yup.object, 'overlaps', function () {
  return this.test('overlaps', t('v2.shifts.index.form.errors.overlaps'), function (value) {
    const [_parent1, parent2, ..._others] = this.from
    const { break_time_periods } = parent2.value

    const formattedStart = dayjs(value.start, 'HH:mm')
    const formattedFinish = dayjs(value.finish, 'HH:mm')

    const breakTimePeriods = [...break_time_periods]
    const index = breakTimePeriods.indexOf(value)

    if (index > -1) {
      breakTimePeriods.splice(index, 1)
    }

    const overlaps = breakTimePeriods.find(breakTime => {
      const formattedPeriodStart = dayjs(breakTime.start, 'HH:mm')
      const formattedPeriodFinish = dayjs(breakTime.finish, 'HH:mm')

      if (!(formattedPeriodStart.isValid() && formattedPeriodFinish.isValid())) return false

      const timeOverlaps = time => formattedStart.isSameOrBefore(time) && time.isSameOrBefore(formattedFinish)

      if (timeOverlaps(formattedPeriodStart) || timeOverlaps(formattedPeriodFinish)) return true

      if (formattedPeriodStart.isBefore(formattedStart) && formattedFinish.isBefore(formattedPeriodFinish)) {
        return true
      }
    })

    return !overlaps
  })
})

const defaultBreakTimePeriodsValue = [{ start: '', finish: '' }]

const valueFormats = {
  date: stringFormatter,
  start_time: timeFormatter,
  end_time: timeFormatter,
  switchable_shift_id: selectFormatter,
  comment: stringFormatter,
  break_time_periods: {
    function: arrayFormatter,
    valueFormatter: {
      function: objectFormatter,
      valueFormatter: timeFormatter
    }
  }
}

const getContextBreakTimePeriods = ({ subject, shift, day }) => {
  const shiftVersion = shift.versions.find(version => versionForCurrentDay(version, day.dayjsDay))
  if (subject?.break_time_periods.length) {
    return subject.break_time_periods
  } else if (!subject && shiftVersion.break_time_periods?.length) {
    return shiftVersion.break_time_periods
  } else {
    return defaultBreakTimePeriodsValue
  }
}

const validationSchema = Yup.object().shape({
  start_time: Yup.string().required(t('v2.shifts.index.form.errors.required')),
  end_time: Yup.string().required(t('v2.shifts.index.form.errors.required')),
  switchable_shift_id: Yup.object().nullable(),
  break_time_periods: Yup.array(
    Yup.object({
      start: Yup.string().nullable().bothPeriodTimes('finish'),
      finish: Yup.string().nullable().bothPeriodTimes('start')
    })
      .nullable()
      .overlaps()
      .bothInShiftTime()
  )
})

export const useShiftDayFormik = ({ shift, subject, subjectId, day, closeModal, employee }) => {
  const { month } = useContext(MonthContext)
  const { manageShift } = useContext(ShiftContext)
  const { dateIso: date } = day
  const contextStartTime = subject?.start_time || shift.start_time
  const contextEndTime = subject?.end_time || shift.end_time
  const contextBreakTimePeriods = getContextBreakTimePeriods({ subject, shift, day })

  const startTime = contextStartTime ? formatTime(contextStartTime) : ''
  const endTime = contextEndTime ? formatTime(contextEndTime) : ''

  const httpMethod = subjectId ? 'put' : 'post'
  const subjectName = employee ? 'shift_employee_day' : 'shift_day'
  const subjectUrl = url([`v2/${subjectName}s`, subjectId].join('/'))
  const associationParams = employee ? { shift_id: shift.id, employee_id: employee.id } : { shift_id: shift.id }

  const sendRequest = (params, status) => {
    axios({
      method: httpMethod,
      url: subjectUrl,
      data: {
        month,
        [subjectName]: { ...params, status, ...associationParams }
      }
    })
      .then(({ data }) => {
        closeModal()
        manageShift(data, 'update', date)
      })
      .catch(() => {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      })
  }

  const formik = useFormik({
    initialValues: {
      date: date,
      start_time: startTime,
      end_time: endTime,
      switchable_shift_id: { value: shift.id, label: shift.name, color: shift.color },
      comment: '',
      break_time_periods: contextBreakTimePeriods
    },
    validationSchema,
    onSubmit: values => sendRequest(formatValuesForSubmit(values, valueFormats), 'updated')
  })

  return { formik, sendRequest }
}

/* eslint-disable react-hooks/exhaustive-deps */
const ShiftDayForm = ({ shift, closeModal, day, employee, showDayBlock, formik, sendRequest }) => {
  const [shifts, setShifts] = useState([])
  const { handleSubmit, values, isSubmitting, setFieldValue } = formik

  const { dateIso: date } = day
  const showShiftInput = showDayBlock && employee
  const subjectName = employee ? 'shift_employee_day' : 'shift_day'

  useEffect(() => {
    if (showShiftInput) {
      const fetchShifts = async () => {
        const autocompletePath = 'v2/shift_employee_days/shifts_autocomplete'

        const { data } = await axios.get(url(autocompletePath, { shift_id: shift.id, employee_id: employee.id }))

        setShifts(data)
      }

      fetchShifts()
    }
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  const confirmDayDelete = () => {
    showConfirmation({
      isOpen: true,
      closeOnConfirm: true,
      onConfirm: () => sendRequest({ date }, 'deleted'),
      text: t(`v2.${subjectName}s.form.confirmations.delete`),
      confirmButton: { title: t('destroy'), type: 'danger' }
    })
  }

  const onShiftSelect = selectedItem => {
    const shiftStartTime = formatTime(selectedItem.start_time)
    const shiftEndTime = formatTime(selectedItem.end_time)

    setFieldValue('switchable_shift_id', selectedItem)
    setFieldValue('start_time', shiftStartTime)
    setFieldValue('end_time', shiftEndTime)
  }

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit} className='react-form shift-form'>
        <div className='modal-form-container'>
          {showShiftInput && (
            <SelectField
              name='switchable_shift_id'
              label={t('v2.shift_employee_days.form.fields.shift')}
              options={shifts}
              onSelect={onShiftSelect}
              withImages
            />
          )}
          <TimeFieldsBlock setFieldValue={setFieldValue} values={values} />
          <TextField name='comment' label={t('v2.shifts.index.form.fields.comment')} textArea />
        </div>
        <div className='btn-container'>
          <button className='btn btn-success' type='submit' disabled={isSubmitting}>
            {t('save')}
          </button>
          <button className='btn btn-secondary' type='button' onClick={closeModal}>
            {t('cancel')}
          </button>
          <button className='btn btn-sm btn-secondary ml-auto delete' type='button' onClick={confirmDayDelete}>
            <Icon icon='iconTrash' />
          </button>
        </div>
      </form>
    </FormikProvider>
  )
}

export default ShiftDayForm
