import React from 'react'

import { t } from '../../../utils/i18n'
import ActiveTable from '../../ActiveTable'
import { editRelatedGroup, remove, destroyRelatedTaskGroup, editTask } from '../../elements/actionDefinitions'
import TasksFormModal from './TasksFormModal'

class TasksTable extends React.Component {
  store = this.props.store
  pathPrefix = 'v2/tasker/tasks'

  actions = {
    row: [
      editTask(),
      editRelatedGroup(this.pathPrefix),
      remove(this.pathPrefix, {
        text: t('destroy'),
        confirmationProps: {
          text: t('confirm_destroy'),
          confirmButton: { title: t('destroy'), type: 'danger' }
        }
      }),
      destroyRelatedTaskGroup(this.pathPrefix)
    ]
  }

  customModal = props => {
    return <TasksFormModal {...props} isOpen={this.newTaskFormOpen} setIsOpen={this.setTaskFormOpen} />
  }

  render() {
    const { _expanded, _subRows, ...props } = this.props
    return (
      <ActiveTable
        {...props}
        actions={this.actions}
        pathPrefix={this.pathPrefix}
        translationPath={['tasker', 'tasks']}
        onRowClick='navigateToResource'
        inactiveBy='without_responsible'
        highlightedBy='has_active_time_entry'
        dangerBy='is_delayed'
        tableClass='tasks-table'
        customModal={this.customModal}
      />
    )
  }
}

export default TasksTable
