import React, { useRef, useState } from 'react'

import classNames from 'classnames'

import useHandleOutsideClick from '../../../../hooks/useHandleOutsideClick'
import Checkbox from '../../../Checkbox'
import styles from './Filters.module.scss'

const ColumnToggler = props => {
  const { columns, setColumns } = props
  const activeCount = columns.filter(col => col.visible).length

  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  useHandleOutsideClick({ ref, onOutsideClick: () => setIsOpen(false) })

  const handleColumnChange = idx => {
    const copyOfColumns = [...columns]
    copyOfColumns[idx].visible = !copyOfColumns[idx].visible
    setColumns(copyOfColumns)
  }

  return (
    <>
      <div className={classNames(styles.filterButton, 'payments-column-toggle-btn')} onClick={() => setIsOpen(!isOpen)}>
        <i className='fa fa-eye'></i>
      </div>
      <div
        className={classNames(styles.columnToggler, 'payments-column-toggle-panel', { 'd-none': !isOpen })}
        ref={ref}
      >
        {columns.map((column, key) => (
          <span key={key} className={styles.toggleRow}>
            <Checkbox
              checked={column.visible}
              onChange={() => handleColumnChange(key)}
              disabled={column.visible && activeCount === 1}
            />
            {column.Header}
          </span>
        ))}
      </div>
    </>
  )
}

export default ColumnToggler
