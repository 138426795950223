import React, { useState, useRef, useContext, useMemo } from 'react'

import classNames from 'classnames'
import dayjs from 'dayjs'

import useHandleOutsideClick from '../../hooks/useHandleOutsideClick'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { t } from '../../utils/i18n'
import { isMacOS } from '../../utils/macOsChecker'
import { url } from '../../utils/urlGenerator'
import DateTimePicker from '../DateTimePicker'
import { ExportButton, ExportRange } from '../ExportButton'
import Icon from '../Icon'
import ScheduleExportModal from './ScheduleExportModal'
import { MonthContext } from './Shifts'

const MonthSelect = ({ goToMonth }) => {
  const [open, setOpen] = useState(false)
  const monthSelectRef = useRef(null)
  const { month } = useContext(MonthContext)

  useHandleOutsideClick({ ref: monthSelectRef, onOutsideClick: () => setOpen(false) })

  const monthDayjs = dayjs(month)
  const monthName = capitalizeFirstLetter(monthDayjs.format('MMMM'))
  const year = monthDayjs.format('YYYY')

  return (
    <div ref={monthSelectRef} className='month-select-wrapper'>
      <button
        className={classNames('btn btn-shadowed month-select', { open })}
        onClick={e => {
          e.nativeEvent.stopImmediatePropagation()
          setOpen(!open)
        }}
      >
        <div className='month-select-info'>
          <div className='year'>{year}</div>
          <div className='month'>{monthName}</div>
        </div>
        <div className={classNames('expander', { open })}>
          <i className={`fa fa-sort-${open ? 'up' : 'down'}`} />
        </div>
      </button>
      {open && <DateTimePicker onChange={goToMonth} type='month' value={new Date(month)} inline />}
    </div>
  )
}

const MonthButton = ({ onClick, type }) => (
  <button
    className='btn btn-sm btn-shadowed month-arrow-button'
    onClick={onClick}
    title={t(`v2.shifts.index.switch_months.${type}`)}
  >
    <i className={`fa fa-caret-${type}`} />
  </button>
)

const ShiftHeader = ({ goToPrevMonth, goToNextMonth, goToMonth, search }) => {
  const [exportModalOpen, setExportModalOpen] = useState(false)
  const { month } = useContext(MonthContext)
  const monthDayjs = dayjs(month, 'YYYY-MM')

  const [exportIntervalStart, setExportIntervalStart] = useState(monthDayjs.format('YYYY-MM-DD'))
  const [exportIntervalEnd, setExportIntervalEnd] = useState(monthDayjs.endOf('month').format('YYYY-MM-DD'))

  const keyName = useMemo(() => (isMacOS() ? 'Cmd (⌘)' : 'Ctrl'), [])

  const exportParams = {
    date_from: exportIntervalStart,
    date_to: exportIntervalEnd,
    ...(search && { search })
  }

  return (
    <div className='shift-header'>
      <div className='list-options'>
        <div className='navigation'>
          <div className='btn-group month-arrows' role='group'>
            <MonthButton
              onClick={e => {
                e.nativeEvent.stopImmediatePropagation()
                goToPrevMonth()
              }}
              type='left'
            />
            <MonthButton
              onClick={e => {
                e.nativeEvent.stopImmediatePropagation()
                goToNextMonth()
              }}
              type='right'
            />
          </div>
          <MonthSelect goToMonth={goToMonth} />
          <div className='ctrl-info'>
            <i className={classNames('fa fa-info-circle')} />
            {t('v2.shifts.index.ctrl_info', { key: keyName })}
          </div>
        </div>

        <ExportButton
          optionsContent={
            <>
              <a className='option' href={url('v2/shifts/export', exportParams)} target='_blank' rel='noreferrer'>
                <Icon icon='fileXlsx' />
                {t('v2.shifts.index.export.report')}
              </a>
              <button
                className='option'
                onClick={() => setExportModalOpen(true)}
                title={t('v2.shifts.index.export.shift_schedule')}
              >
                <Icon icon='filePdf' />
                {t('v2.shifts.index.export.shift_schedule')}
              </button>
            </>
          }
        >
          <ExportRange
            startDate={exportIntervalStart}
            endDate={exportIntervalEnd}
            onStartDateChange={date => setExportIntervalStart(dayjs(date).format('YYYY-MM-DD'))}
            onEndDateChange={date => setExportIntervalEnd(dayjs(date).format('YYYY-MM-DD'))}
          />
        </ExportButton>
        {exportModalOpen && <ScheduleExportModal setOpen={setExportModalOpen} exportParams={exportParams} />}
      </div>
    </div>
  )
}

export default ShiftHeader
