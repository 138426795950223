class ConstructionObjectCompanyForm {
  static selector = '.edit_construction_object_company, .new_construction_object_company'

  constructor(form) {
    this.form = form

    this.bindElements()

    if (this.selectors.contractKindInput) {
      this.contractKind = this.selectors.contractKindInput.value
      this.bindEvents()
      this.rerender()
    }
  }

  bindElements() {
    this.selectors = {
      contractKindInput: this.form.querySelector('#construction_object_company_contract_kind'),
      parent: this.form.querySelector('.construction_object_company_parent')
    }
  }

  bindEvents() {
    this.selectors.contractKindInput.addEventListener('change', this.toggleFields)
  }

  toggleFields = event => {
    this.contractKind = event.target.value
    this.rerender()
  }

  isParentFieldVisible() {
    return ['is_subcontractor_subcontractor', 'is_outsourcee'].includes(this.contractKind)
  }

  toggleVisibility(element, isVisible) {
    element.classList[isVisible.bind(this)() ? 'remove' : 'add']('d-none')
  }

  rerender = () => {
    this.toggleVisibility(this.selectors.parent, this.isParentFieldVisible)
  }
}

export default ConstructionObjectCompanyForm
