import { useState, useEffect } from 'react'

import axios from 'axios'
import { url } from 'utils/urlGenerator'

export default () => {
  const [loading, setLoading] = useState(false)
  const [exceptionDays, setExceptionDays] = useState([])

  const isHoliday = dateIso => exceptionDays.find(day => day.date === dateIso)?.holiday

  useEffect(() => {
    axios
      .get(url('v2/exception_days'))
      .then(({ data: { exception_days } }) => {
        setExceptionDays(exception_days)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return { loading, isHoliday }
}
