import React from 'react'

import { useController, useFormContext } from 'react-hook-form'

import MonthByDates from '../../../TaskRegularityComponents/MonthByDates'
import MonthByDays from '../../../TaskRegularityComponents/MonthByDays'
import MonthConfiguration from '../../../TaskRegularityComponents/MonthConfiguration'

const RegularityRepeatMonthdaysSelect = () => {
  const { control, submitting, selectOptions, disableRegularity, getValues } = useFormContext()
  const isDisabled = submitting || disableRegularity
  const {
    field: { onChange, value }
  } = useController({ control, name: '[regularity][month_interval_kind]', defaultValue: 'dates' })

  const monthDays = getValues().regularity?.days
  const specificDays = getValues().regularity?.specific_days

  const renderByIntervalType = () => {
    const { month_weeks, week_days_names, month_specific_day_options } = selectOptions.regularity

    if (value === 'dates') {
      return (
        <div>
          <MonthByDates
            monthSpecificDayOptions={month_specific_day_options}
            days={monthDays}
            specificDays={specificDays}
            disabled={isDisabled}
          />
        </div>
      )
    } else {
      return (
        <div className='fields-right-container'>
          <MonthByDays
            monthWeeks={month_weeks}
            weekDaysNames={week_days_names}
            days={monthDays}
            disabled={isDisabled}
          />
        </div>
      )
    }
  }

  return (
    <div className='d-flex align-items-start'>
      <MonthConfiguration
        monthIntervalKind={value}
        onMonthIntervalKindChange={onChange}
        disabled={isDisabled}
        withLabel={false}
      />
      {renderByIntervalType()}
    </div>
  )
}

export default RegularityRepeatMonthdaysSelect
