import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../utils/i18n'
import { FilterContainer, SearchField, SimpleSelect, ImportButton } from '../FilterComponents'

class EmployeesFilter extends React.Component {
  hiddenFilters = () => {
    const {
      store,
      construction_objects_options,
      companies_options,
      users_options,
      state_options,
      identifiers_options,
      is_foreigner_options
    } = this.props

    return (
      <React.Fragment>
        <SimpleSelect
          store={store}
          selectable='construction_object_id'
          options={construction_objects_options}
          isMulti={true}
          dataContains='select-construction-object-filter'
        />
        <SimpleSelect
          store={store}
          selectable='company_id'
          options={companies_options}
          isMulti={true}
          dataContains='select-company-filter'
        />
        <SimpleSelect store={store} selectable='state' options={state_options} dataContains='select-state-filter' />
        <SimpleSelect
          store={store}
          selectable='is_foreigner'
          options={is_foreigner_options}
          dataContains='select-is-foreigner-filter'
        />
        <SimpleSelect store={store} selectable='checkable_identifier' options={identifiers_options} />
        <SimpleSelect
          store={store}
          selectable='user'
          options={users_options}
          dataContains='select-user-filter'
          isMulti
        />
      </React.Fragment>
    )
  }

  render() {
    const { store, import_url, customFilters, importable, ...others } = this.props

    return (
      <FilterContainer store={store} hiddenFilters={customFilters || this.hiddenFilters} {...others}>
        <SearchField store={store} placeholder={t('filters.search_employees')} />
        <ImportButton url={import_url} permitted={importable} />
      </FilterContainer>
    )
  }
}

export default observer(EmployeesFilter)
