import React, { useState, useContext, useMemo, useEffect } from 'react'

import ReactDOM from 'react-dom'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Switch from '../Forms/Fields/Switch'
import Modal from '../Modal'
import { addOrRemoveSelectedItem, OptionList } from '../Options'
import { ShiftContext } from './Shifts'

const renderLabel = shift => (
  <div className='shift-label-container'>
    <div className='shift-label'>
      <span className='indicator' style={{ backgroundColor: shift.color }} />
      <div className='text-truncate'>{shift.label}</div>
    </div>
    <span className='employee-count'>
      {t('v2.shifts.index.export_modal.employee_count', { count: shift.employeeCount })}
    </span>
  </div>
)

const ScheduleExportModal = ({ setOpen, exportParams }) => {
  const [selectedShifts, setSelectedShifts] = useState([])
  const [divideSchedule, setDivideSchedule] = useState(false)
  const { shifts } = useContext(ShiftContext)

  const formattedShifts = useMemo(
    () =>
      shifts.map(shift => ({
        value: shift.id,
        label: shift.name,
        employeeCount: shift.employee_count,
        color: shift.color
      })),
    [shifts]
  )

  useEffect(() => {
    if (formattedShifts.length) {
      setSelectedShifts(formattedShifts)
    }
  }, [formattedShifts])

  const exportPdf = url('v2/shifts/export_schedule.pdf', {
    ...exportParams,
    divide_schedule: divideSchedule,
    shift_ids: selectedShifts.map(shift => shift.value).join(',')
  })

  const closeModal = () => {
    setOpen(false)
  }

  return ReactDOM.createPortal(
    <Modal
      isOpen
      className='modal-lg modal-dialog-centered new-modal shift-export-modal'
      title={t('v2.shifts.index.export_modal.title')}
      onClose={closeModal}
    >
      <div className='switch-container mb-2'>
        <label htmlFor='divide_schedule'>{t('v2.shifts.index.export_modal.divide_schedule')}</label>
        <Switch id='divide_schedule' checked={divideSchedule} onChange={() => setDivideSchedule(!divideSchedule)} />
      </div>

      <OptionList
        isMulti
        options={formattedShifts}
        selectedItems={selectedShifts}
        renderLabel={renderLabel}
        onSelectAll={setSelectedShifts}
        onSelect={selectedItem =>
          addOrRemoveSelectedItem({ selectedItem, selectedItems: selectedShifts, onChange: setSelectedShifts })
        }
      />
      <hr className='delimiter' />
      <div className='row'>
        <div className='col-6 mt-1 d-flex'>
          <a
            onClick={closeModal}
            className='btn btn-primary flex-grow-1 px-0'
            href={exportPdf}
            target='_blank'
            rel='noreferrer'
          >
            {t('export')}
          </a>
          <button className='btn btn-secondary ml-3 flex-grow-1 px-0' onClick={closeModal}>
            {t('cancel')}
          </button>
        </div>
      </div>
    </Modal>,
    document.querySelector('.modal-container')
  )
}

export default ScheduleExportModal
