import React from 'react'

import Axios from 'axios'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import Creatable from 'react-select/creatable'

import { t } from '../utils/i18n'
import { url } from '../utils/urlGenerator'

export const REACT_SELECT_PROPS = {
  className: 'select-container',
  classNamePrefix: 'rs',
  noOptionsMessage: () => t('components.select.no_options'),
  loadingMessage: () => t('components.select.loading_message'),
  formatCreateLabel: value => `${t('components.select.create', { value })}`
}

export const filterOptions = ({ data: { label, search_keywords } }, inputValue) => {
  const lowerCaseInput = inputValue.toLowerCase()
  const matchable = `${label} ${search_keywords || ''}`

  return matchable.toLowerCase().includes(lowerCaseInput)
}

class Select extends React.Component {
  state = { selectedOption: null }

  get hiddenSelect() {
    return document.getElementById(this.props.input_options.id)
  }

  componentDidMount() {
    this.select = React.createRef()
    this.findAndSetSelectedOption(this.props.options)
    this.setUpRemoteSelectParamInputs()
    this.bubbleLabelEvents()
  }

  findAndSetSelectedOption(options) {
    const { value, multiple } = this.props
    let selectedOption

    if (multiple) {
      let multipleValues = value && value.map(v => v.toString())

      selectedOption = options.filter(option => multipleValues.includes(option.value.toString()))
    } else {
      selectedOption = options.find(option => value === option.value)
    }

    this.onChange(selectedOption)
  }

  setUpRemoteSelectParamInputs() {
    this.iterateParams((key, input) => {
      this.setState({ [key]: input.value })

      input.addEventListener('change', () => {
        if (this.state[key] !== input.value) {
          this.setState({ selectedOption: null, [key]: input.value })
        }
      })
    })
  }

  bubbleLabelEvents() {
    const label = document.querySelector(`label[for=${this.props.input_options.id}]`)
    label.addEventListener('click', () => this.select.current.focus())
  }

  iterateParams(callback) {
    const params = this.props.input_options.params

    if (params) {
      Object.entries(params).forEach(([key, selector]) => callback(key, document.querySelector(selector)))
    }
  }

  onChange = selectedOption => {
    if (!selectedOption || (this.props.multiple && !selectedOption.length)) {
      selectedOption = null
    }

    this.setState({ selectedOption }, () => this.setHiddenValue())
  }

  setHiddenValue() {
    this.hiddenSelect.innerHTML = ''

    if (this.state.selectedOption) {
      this.addSelectedOptionsToSelect()
    } else {
      this.hiddenSelect.add(new Option('', '', true, true))
    }

    this.hiddenSelect.dispatchEvent(new Event('change'))
  }

  addSelectedOptionsToSelect() {
    const { selectedOption } = this.state

    if (this.props.multiple) {
      selectedOption.forEach(option => this.hiddenSelect.add(new Option(option.label, option.value, true, true)))
    } else {
      this.hiddenSelect.add(new Option(selectedOption.label, selectedOption.value, true, true))
    }
  }

  loadAsyncOptions = async q => {
    const {
      remote_url,
      value,
      input_options: { _params }
    } = this.props
    const queryParams = { q }

    this.iterateParams(key => (queryParams[key] = this.state[key]))

    const { data } = await Axios.get(url(remote_url, queryParams))

    if (value && !this.state.selectedOption) {
      this.findAndSetSelectedOption(data)
    }
    this.setHiddenValue()

    return data
  }

  get customStyles() {
    return {
      option: (styles, { data }) => {
        return { ...styles, fontWeight: data.emphasize ? 'bold' : 'normal' }
      }
    }
  }

  get selectProps() {
    const { disabled, allow_clear, multiple, placeholder } = this.props

    return {
      ...REACT_SELECT_PROPS,
      onChange: this.onChange,
      value: this.state.selectedOption,
      isDisabled: disabled,
      placeholder: disabled ? '-' : placeholder || t('choose'),
      ref: this.select,
      isClearable: allow_clear == false ? false : true,
      styles: this.customStyles,
      isMulti: multiple
    }
  }

  renderRemoteSelect() {
    const { multiple } = this.props
    let asyncSelectKey = ''
    this.iterateParams(key => (asyncSelectKey += this.state[key]))

    return (
      <AsyncSelect
        {...this.selectProps}
        defaultOptions
        key={asyncSelectKey}
        loadOptions={this.loadAsyncOptions}
        isMulti={this.props.multiple}
        closeMenuOnSelect={!multiple}
      />
    )
  }

  isValidNewOption = value => {
    return value.replace(/\s/g, '').length > 0 && !value.includes(',')
  }

  renderSelect() {
    const { multiple, options, creatable } = this.props
    const Component = creatable ? Creatable : ReactSelect

    return (
      <Component
        {...this.selectProps}
        options={options}
        closeMenuOnSelect={!multiple}
        filterOption={filterOptions}
        isValidNewOption={this.isValidNewOption}
      />
    )
  }

  render() {
    if (this.props.remote_url) {
      return this.renderRemoteSelect()
    } else {
      return this.renderSelect()
    }
  }
}

export default Select
