import React from 'react'

import { url } from '../../../utils/urlGenerator'
import ActiveTable from '../../ActiveTable'

class TaskTimeEntryUsersTable extends React.Component {
  trProps = selectedRow => ({
    onClick: () => {
      const { store } = this.props

      store.setFilter('owner_id', { label: selectedRow.original.name, value: [selectedRow.original.id] })
      store.setState({ sorted: [{ id: 'state', desc: true }] })
      store.setState({ showFilter: true })

      Turbolinks.visit(url('v2/tasker/tasks', this.props.task_id, 'time_entries'))
    }
  })

  render() {
    return <ActiveTable {...this.props} getTrProps={this.trProps} />
  }
}

export default TaskTimeEntryUsersTable
