import React from 'react'

import ActiveTable from '../ActiveTable'

class ActivitiesTable extends React.Component {
  store = this.props.store
  pathPrefix = 'v2/activities'

  render() {
    return (
      <ActiveTable
        {...this.props}
        pathPrefix={this.pathPrefix}
        translationPath='activities'
        onRowClick='navigateToResource'
      />
    )
  }
}

export default ActivitiesTable
