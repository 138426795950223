import { useCallback } from 'react'

import { t } from '../utils/i18n'

const useTranslate = base =>
  useCallback(
    (key, options = null) => {
      if (key.startsWith('.')) {
        return t(`components.${base}${key}`, options)
      } else {
        return t(key, options)
      }
    },
    [base]
  )

export default useTranslate
