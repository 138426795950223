class NoteForm {
  static selector = '#new_note'

  constructor(form) {
    this.form = form

    this.bindElements()
    this.bindEvents()
  }

  bindElements() {
    this.selectors = {
      fileInput: this.form.querySelector('#note_file'),
      filePlaceholder: document.querySelector('.file-placeholder')
    }
  }

  bindEvents() {
    this.selectors.fileInput.addEventListener('change', this.onFileChange)
  }

  onFileChange = () => {
    const { filePlaceholder, fileInput } = this.selectors

    filePlaceholder.classList.remove('d-none')
    filePlaceholder.innerText = fileInput.files[0].name
  }
}

export default NoteForm
