import React from 'react'

import { t } from '../../utils/i18n'
import Tooltip from '../Tooltip'

const StateCell = ({ state, type, ...others }) => {
  if (!state) return null
  const icon = state === 'with_error' ? 'times-circle-o' : 'check-circle-o'
  const color = state === 'with_error' ? 'danger' : 'success'

  return <StateContent {...others} icon={icon} color={color} type={type} state={state} />
}

const StateContent = ({ icon, color, edited, type, state, id }) => {
  const stateTooltipId = `report-${type}-state-${id}`
  const editTooltipId = `report-${type}-edit-${id}`

  return (
    <div className='d-flex align-items-center'>
      <i className={`fa fa-2x fa-${icon} ${color} ml-2`} data-tip data-for={stateTooltipId} />
      <Tooltip id={stateTooltipId} className='accented' place='bottom' effect='solid'>
        {t(`activerecord.attributes.report_version_${type}.${state}`)}
      </Tooltip>

      {edited && (
        <>
          <i className='fa fa-pencil primary ml-3' data-tip data-for={editTooltipId} />
          <Tooltip id={editTooltipId} className='accented' place='bottom' effect='solid'>
            {t(`activerecord.attributes.report_version_${type}.edited`)}
          </Tooltip>
        </>
      )}
    </div>
  )
}

export default StateCell
