import React from 'react'

import Icon from '../../Icon'

const SubTasksProgress = ({ sub_task_count, completed_sub_task_count }) => (
  <div className='d-flex align-items-center'>
    <Icon icon='subtasks' className='mr-1' />
    {completed_sub_task_count} / {sub_task_count}
  </div>
)

export default SubTasksProgress
