import React from 'react'

import axios from 'axios'
import classNames from 'classnames'

import { t } from '../utils/i18n'
import { url } from '../utils/urlGenerator'

class DownloadProgressBars extends React.Component {
  state = { downloads: this.props.downloads }
  intervals = {}

  componentDidMount() {
    this.state.downloads.forEach(download => {
      this.intervals[download.id] = setInterval(() => {
        try {
          this.refreshDownload(download)
        } catch (e) {
          this.removeDownload(download)
        }
      }, 1000)
    })
  }

  componentWillUnmount() {
    Object.entries(this.intervals).forEach(([_, interval]) => clearInterval(interval))
  }

  async refreshDownload({ id }) {
    const { data } = await axios.get(url('v2/downloads', id))

    this.setState(_prevState => ({
      downloads: this.state.downloads.map(download => (download.id === id ? data : download))
    }))

    if (data.completed) {
      clearInterval(this.intervals[id])
    }
  }

  removeDownload({ id }) {
    clearInterval(this.intervals[id])

    this.setState(_prevState => ({ downloads: this.state.downloads.filter(download => download.id !== id) }))
  }

  download(id) {
    this.setState(_prevState => ({ downloads: this.state.downloads.filter(download => download.id !== id) }))

    window.location = url('v2/downloads', id, 'download')
  }

  renderProgressBarContent(completed, id, filename, progress) {
    if (completed) {
      return (
        <span className='clickable progress-bar-text' onClick={() => this.download(id)}>
          {t('download')} {filename}
        </span>
      )
    } else {
      return (
        <span className='progress-bar-text'>
          {filename || t('components.download_progress_bars.default_title')} - {progress}%
        </span>
      )
    }
  }

  renderProgressBars = ({ id, filename, progress, completed }) => {
    return (
      <div className={classNames('progress', { completed })} key={id}>
        {this.renderProgressBarContent(completed, id, filename, progress)}
        <div
          className={classNames('progress-bar progress-bar-striped', { 'bg-success': completed })}
          style={{ width: `${progress}%` }}
        />
      </div>
    )
  }

  render() {
    return this.state.downloads.map(this.renderProgressBars)
  }
}

export default DownloadProgressBars
