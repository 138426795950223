import React from 'react'

import { t } from '../../../utils/i18n'
import Icon from '../../Icon'

const ModalFormButtons = ({ isSubmitting, closeModal, onDestroyClick }) => (
  <div className='btn-container'>
    <button className='btn btn-primary' type='submit' disabled={isSubmitting}>
      {t('save')}
    </button>
    <button className='btn btn-secondary' type='button' onClick={closeModal}>
      {t('cancel')}
    </button>
    {onDestroyClick && (
      <button
        className='btn btn-sm btn-secondary ml-auto delete'
        type='button'
        title={t('destroy')}
        onClick={onDestroyClick}
      >
        <Icon icon='iconTrash' />
      </button>
    )}
  </div>
)

export default ModalFormButtons
