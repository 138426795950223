import React, { forwardRef } from 'react'

import { useField } from 'formik'

import TimeInput from '../Inputs/TimeInput'
import FormikFieldWrapper from './FormikFieldWrapper'

const TimeField = forwardRef((props, ref) => {
  const { label, onChange, onBlur, disabled, id, options, className, errorInTooltip, inputClass, ...others } = props
  const [field, meta, _helpers] = useField(props)
  const { value, name } = field

  return (
    <FormikFieldWrapper name={name} label={label} className={className} fieldId={id} errorInTooltip={errorInTooltip}>
      <TimeInput
        name={name}
        id={id}
        className={inputClass}
        disabled={disabled && value === meta.initialValue}
        onBlur={onBlur}
        onChange={onChange}
        options={options}
        ref={ref}
        {...others}
      />
    </FormikFieldWrapper>
  )
})

export default TimeField
