import React, { useContext } from 'react'

import axios from 'axios'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { useFormik, FormikProvider } from 'formik'
import ReactDOM from 'react-dom'
import Turbolinks from 'turbolinks'
import * as Yup from 'yup'

import variables from '../../../assets/stylesheets/shift-colors'
import useUnsavedChangesConfirmation from '../../hooks/useUnsavedChangesConfirmation'
import { showConfirmation, getApiErrorMessage, showErrorModal } from '../../utils/confirmations'
import { formatTime } from '../../utils/formatTime'
import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Icon from '../Icon'
import Modal from '../Modal'
import { MonthContext, ShiftContext } from '../Shifts/Shifts'
import { IconWithTooltip } from '../Tooltip'
import { TextField, TimeFieldsBlock, ColorPicker, MultiselectField, SwitchField, TimeField } from './Fields'
import {
  multiselectFormatter,
  stringFormatter,
  timeFormatter,
  arrayFormatter,
  objectFormatter,
  booleanFormatter
} from './Formatters'
import { formatValuesForSubmit } from './Formatters/submit'

dayjs.extend(customParseFormat)
dayjs.extend(isSameOrBefore)

Yup.addMethod(Yup.string, 'bothTimes', function (comparableField) {
  return this.test('both_times', t('v2.shifts.index.form.errors.required'), function (value) {
    const required = !!this.parent[comparableField] || this.parent.break_time_periods.some(p => !!p.start || !!p.finish)

    return !!value || !required
  })
})

Yup.addMethod(Yup.string, 'bothPeriodTimes', function (comparableField) {
  return this.test('both_period_times', t('v2.shifts.index.form.errors.required'), function (value) {
    return value ? true : !this.parent[comparableField]
  })
})

Yup.addMethod(Yup.object, 'overlaps', function () {
  return this.test('overlaps', t('v2.shifts.index.form.errors.overlaps'), function (value) {
    const [_parent1, parent2, ..._others] = this.from
    const { break_time_periods } = parent2.value

    const formattedStart = dayjs(value.start, 'HH:mm')
    let formattedFinish = dayjs(value.finish, 'HH:mm')

    if (formattedFinish.isBefore(formattedStart)) {
      formattedFinish = formattedFinish.add(1, 'day')
    }

    const breakTimePeriods = [...break_time_periods]
    const index = breakTimePeriods.indexOf(value)

    if (index > -1) {
      breakTimePeriods.splice(index, 1)
    }

    const overlaps = breakTimePeriods.find(breakTime => {
      const formattedPeriodStart = dayjs(breakTime.start, 'HH:mm')
      let formattedPeriodFinish = dayjs(breakTime.finish, 'HH:mm')

      if (formattedPeriodFinish.isBefore(formattedPeriodStart)) {
        formattedPeriodFinish = formattedPeriodFinish.add(1, 'day')
      }

      if (!(formattedPeriodStart.isValid() && formattedPeriodFinish.isValid())) return false

      const timeOverlaps = time => formattedStart.isSameOrBefore(time) && time.isSameOrBefore(formattedFinish)

      if (timeOverlaps(formattedPeriodStart) || timeOverlaps(formattedPeriodFinish)) return true

      if (formattedPeriodStart.isBefore(formattedStart) && formattedFinish.isBefore(formattedPeriodFinish)) {
        return true
      }
    })

    return !overlaps
  })
})

Yup.addMethod(Yup.object, 'bothInShiftTime', function () {
  return this.test('bothInShiftTime', t('v2.shifts.index.form.errors.outside_shift'), function (value) {
    const [_parent1, parent2, ..._others] = this.from
    const { start_time, end_time } = parent2.value

    if (!(start_time && end_time && value && value.start && value.finish)) return true

    const shiftStart = dayjs(start_time, 'HH:mm')
    let shiftEnd = dayjs(end_time, 'HH:mm')

    const breakTimeStart = dayjs(value.start, 'HH:mm')
    let breakTimeFinish = dayjs(value.finish, 'HH:mm')

    if (breakTimeFinish.isBefore(breakTimeStart)) {
      breakTimeFinish = breakTimeFinish.add(1, 'day')
    }

    if (shiftEnd.isBefore(shiftStart)) {
      shiftEnd = shiftEnd.add(1, 'day')
    }

    const breakTimeStartInShift = breakTimeStart.isAfter(shiftStart) && breakTimeStart.isBefore(shiftEnd)

    const breakTimeFinishInShift = breakTimeFinish.isAfter(shiftStart) && breakTimeFinish.isBefore(shiftEnd)

    return breakTimeStartInShift && breakTimeFinishInShift
  })
})

const defaultBreakTimePeriodsValue = [{ start: '', finish: '' }]

const valueFormats = {
  color: stringFormatter,
  name: stringFormatter,
  start_time: timeFormatter,
  end_time: timeFormatter,
  construction_object_ids: multiselectFormatter,
  employee_ids: multiselectFormatter,
  company_ids: multiselectFormatter,
  start_time_tolerance_before: stringFormatter,
  start_time_tolerance_after: stringFormatter,
  end_time_tolerance_before: stringFormatter,
  end_time_tolerance_after: stringFormatter,
  regularity: arrayFormatter,
  responsible_ids: multiselectFormatter,
  break_time_periods: {
    function: arrayFormatter,
    valueFormatter: {
      function: objectFormatter,
      valueFormatter: timeFormatter
    }
  },
  sync_all_construction_objects: booleanFormatter
}

const HeaderContent = ({ shift }) => (
  <div className='modal-header-content'>
    <div>
      <div className='modal-header-title'>{t(`v2.shifts.index.form.title_${shift ? 'edit' : 'new'}`)}</div>
      {shift && (
        <div className='modal-header-subtitle'>
          <div
            className='modal-header-subtitle-indicator modal-header-subtitle-color'
            style={{ backgroundColor: shift.color }}
          />
          <div className='modal-header-subtitle-text'>{shift.name}</div>
        </div>
      )}
    </div>
  </div>
)

const roundMinsToFive = val => val.replace(/^(\d\d:\d)([1234])$/, '$10').replace(/^(\d\d:\d)([6789])$/, '$15')

const ShiftForm = ({
  formOptions: { objectOptions, regularityOptions, responsibleOptions },
  modalOpen,
  closeModal,
  shift
}) => {
  const httpMethod = shift ? 'put' : 'post'
  const shiftUrl = url(['v2/shifts', shift?.id].join('/'))
  const action = shift ? 'update' : 'create'

  const { month } = useContext(MonthContext)
  const { manageShift } = useContext(ShiftContext)

  const sendRequest = values => {
    axios({
      method: httpMethod,
      url: shiftUrl,
      data: {
        month,
        shift: formatValuesForSubmit(
          {
            ...values,
            regularity: timeRelatedFieldsDisabled ? [] : values.regularity,
            break_time_periods: timeRelatedFieldsDisabled ? [] : values.break_time_periods
          },
          valueFormats
        )
      }
    })
      .then(({ data }) => {
        closeModal()
        manageShift(data, action)
      })
      .catch(() => {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      })
  }

  const discardShift = () => {
    axios
      .put(`${shiftUrl}/discard`)
      .then(() => {
        closeModal()
        manageShift(shift, 'delete')
      })
      .catch(() => {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      })
  }

  const confirmShiftDiscard = () => {
    showConfirmation({
      isOpen: true,
      closeOnConfirm: true,
      onConfirm: discardShift,
      text: t('v2.shifts.index.form.confirmations.discard', { shift_name: shift.name }),
      confirmButton: { title: t('destroy'), type: 'danger' }
    })
  }

  const requiredTranslation = t('v2.shifts.index.form.errors.required')
  const oneTranslation = t('v2.shifts.index.form.errors.one_required_allowed')

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(requiredTranslation),
    start_time: Yup.string().nullable().bothTimes('end_time'),
    end_time: Yup.string().nullable().bothTimes('start_time'),
    construction_object_ids: Yup.array(Yup.object()).length(1, oneTranslation),
    employee_ids: Yup.array(Yup.object()).nullable(),
    company_ids: Yup.array(Yup.object()).nullable(),
    start_time_tolerance_before: Yup.string().nullable(),
    start_time_tolerance_after: Yup.string().nullable(),
    end_time_tolerance_before: Yup.string().nullable(),
    end_time_tolerance_after: Yup.string().nullable(),
    responsible_ids: Yup.array(Yup.object()).nullable(),
    regularity: Yup.array(Yup.number()).nullable(),
    break_time_periods: Yup.array(
      Yup.object({
        start: Yup.string().nullable().bothPeriodTimes('finish'),
        finish: Yup.string().nullable().bothPeriodTimes('start')
      })
        .nullable()
        .overlaps()
        .bothInShiftTime()
    ),
    sync_all_construction_objects: Yup.boolean()
  })

  const colors = variables.colors.split(',')

  const initialValues = {
    color: colors[Math.floor(Math.random() * colors.length)],
    name: '',
    start_time: '',
    end_time: '',
    construction_object_ids: [],
    employee_ids: [],
    company_ids: [],
    start_time_tolerance_before: '',
    start_time_tolerance_after: '',
    end_time_tolerance_before: '',
    end_time_tolerance_after: '',
    regularity: [1, 2, 3, 4, 5],
    responsible_ids: [],
    break_time_periods: defaultBreakTimePeriodsValue,
    sync_all_construction_objects: false
  }

  const getShiftTolerances = () => {
    const {
      start_time_tolerance_before,
      start_time_tolerance_after,
      end_time_tolerance_before,
      end_time_tolerance_after
    } = shift

    return {
      start_time_tolerance_before: start_time_tolerance_before ? formatTime(start_time_tolerance_before) : '',
      start_time_tolerance_after: start_time_tolerance_after ? formatTime(start_time_tolerance_after) : '',
      end_time_tolerance_before: end_time_tolerance_before ? formatTime(end_time_tolerance_before) : '',
      end_time_tolerance_after: end_time_tolerance_after ? formatTime(end_time_tolerance_after) : ''
    }
  }

  const getShiftTimes = () => {
    const { start_time, end_time } = shift

    return {
      start_time: start_time ? formatTime(start_time) : '',
      end_time: end_time ? formatTime(end_time) : ''
    }
  }

  const getShiftValues = () => ({
    ...shift,
    ...getShiftTolerances(),
    ...getShiftTimes(),
    company_ids: [],
    construction_object_ids: objectOptions.filter(option => shift.construction_object_ids.includes(option.value)) || [],
    employee_ids: shift.employee_options,
    responsible_ids: shift.responsible_options,
    break_time_periods: shift.break_time_periods.length ? shift.break_time_periods : defaultBreakTimePeriodsValue
  })

  const formik = useFormik({
    initialValues: shift ? getShiftValues() : initialValues,
    validationSchema,
    onSubmit: sendRequest
  })

  const { handleSubmit, values, isSubmitting, dirty } = formik

  const timeRelatedFieldsDisabled = !(values.start_time && values.end_time)

  const handleClose = useUnsavedChangesConfirmation({ dirty, onClose: closeModal })

  return ReactDOM.createPortal(
    <Modal
      isOpen={modalOpen}
      className='modal-lg modal-dialog-centered shift-modal'
      onClose={handleClose}
      header={<HeaderContent shift={shift} />}
    >
      <FormikProvider value={formik}>
        <form onSubmit={handleSubmit} className='react-form shift-form'>
          <div className='modal-form-container'>
            <div className='shift-info'>
              <ColorPicker colors={colors} />
              <TextField name='name' placeholder={t('v2.shifts.index.form.fields.name')} required />
            </div>

            <TimeFieldsBlock regularityOptions={regularityOptions} disabled={timeRelatedFieldsDisabled} />

            <hr className='delimiter' />

            <MultiselectField
              name='construction_object_ids'
              label={t('v2.shifts.index.form.fields.object')}
              options={objectOptions}
              fieldIcon='iconLocation'
              required
            />
            <SwitchField
              name='sync_all_construction_objects'
              label={t('v2.shifts.index.form.fields.sync_all_construction_objects')}
              fieldIcon='iconLocationA'
            />

            <hr className='delimiter' />

            <MultiselectField
              name='employee_ids'
              label={t('v2.shifts.index.form.fields.employees')}
              optionsURL={url(
                'v2/shifts/employees_autocomplete',
                values.construction_object_ids?.length > 0
                  ? { construction_object_ids: values.construction_object_ids.map(o => o.value).join(',') }
                  : null
              )}
              withImages
              fieldIcon='iconEmployee'
            />
            <MultiselectField
              name='company_ids'
              label={t('v2.shifts.index.form.fields.departments')}
              optionsURL={url(
                'v2/shifts/companies_autocomplete',
                values.construction_object_ids?.length > 0
                  ? { construction_object_ids: values.construction_object_ids.map(o => o.value).join(',') }
                  : null
              )}
              fieldIcon='iconCompany'
            />

            <hr className='delimiter' />

            <MultiselectField
              name='responsible_ids'
              label={t('v2.shifts.index.form.fields.responsibles')}
              withImages
              options={responsibleOptions}
              fieldIcon='iconResponsible'
            />

            <hr className='delimiter' />

            <div className='d-flex'>
              <div className='shift-tolerances'>
                <label className='form-label'>
                  {t('v2.shifts.index.form.fields.start_time_tolerances')}{' '}
                  <IconWithTooltip
                    icon='info'
                    tooltipText={t('v2.shifts.index.form.fields.time_tolerances_info')}
                    tooltipId='start-tolerances-tooltip'
                  />
                </label>
                <div className='form-row'>
                  <TimeField
                    name='start_time_tolerance_before'
                    disabled={!values.start_time}
                    bonusFormatter={roundMinsToFive}
                  />
                  <TimeField
                    name='start_time_tolerance_after'
                    disabled={!values.start_time}
                    bonusFormatter={roundMinsToFive}
                  />
                </div>
              </div>
              <div className='shift-tolerances'>
                <label className='form-label'>
                  {t('v2.shifts.index.form.fields.end_time_tolerances')}{' '}
                  <IconWithTooltip
                    icon='info'
                    tooltipText={t('v2.shifts.index.form.fields.time_tolerances_info')}
                    tooltipId='end-tolerances-tooltip'
                  />
                </label>
                <div className='form-row'>
                  <TimeField
                    name='end_time_tolerance_before'
                    disabled={!values.end_time}
                    bonusFormatter={roundMinsToFive}
                  />
                  <TimeField
                    name='end_time_tolerance_after'
                    disabled={!values.end_time}
                    bonusFormatter={roundMinsToFive}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='btn-container'>
            <button className='btn btn-success' type='submit' disabled={isSubmitting}>
              {t('save')}
            </button>

            <button className='btn btn-secondary' onClick={closeModal}>
              {t('cancel')}
            </button>

            {shift && (
              <button className='btn btn-sm btn-secondary ml-auto delete' type='button' onClick={confirmShiftDiscard}>
                <Icon icon='iconTrash' />
              </button>
            )}
          </div>
        </form>
      </FormikProvider>
    </Modal>,
    document.querySelector('.modal-container')
  )
}

export default ShiftForm
