import React from 'react'

import classNames from 'classnames'
import I18n from 'i18n-js'

import { generateId } from '../../../utils/generateId'
import { url } from '../../../utils/urlGenerator'
import DateTimePicker from '../../DateTimePicker'
import Icon from '../../Icon'

const TimePeriod = ([start = '', finish = ''] = []) => ({
  start,
  finish,
  id: generateId()
})

class ForbiddenTimePeriodsInput extends React.Component {
  state = { timePeriods: [] }

  componentDidMount() {
    if (this.props.time_periods.length === 0) {
      this.setState({ timePeriods: [TimePeriod()] })
    } else {
      this.setState({ timePeriods: this.props.time_periods.map(TimePeriod) })
    }
  }

  addTimePeriod(idx) {
    const _timePeriods = this.state.timePeriods
    const timePeriods = [..._timePeriods.slice(0, idx + 1), TimePeriod(), ..._timePeriods.slice(idx + 1)]

    this.setState({ timePeriods })
  }

  removeTimePeriod(id) {
    const timePeriods = this.state.timePeriods.filter(timePeriod => id !== timePeriod.id)

    this.setState({ timePeriods })
  }

  onTimePeriodChange(value, attribute, id) {
    const timePeriods = this.state.timePeriods.map(timePeriod =>
      id === timePeriod.id ? { ...timePeriod, [attribute]: value } : timePeriod
    )

    this.setState({ timePeriods })
  }

  inputName(id, attribute) {
    return `tasker_task[time_tracking_forbidden_time_periods][][${attribute}]`
  }

  renderAddIcon(idx) {
    return <Icon className='action' icon='iconPlusCircle' onClick={() => this.addTimePeriod(idx)} />
  }

  renderRemoveIcon(id) {
    if (this.state.timePeriods.length > 1) {
      return <Icon className='action' icon='iconTrash' onClick={() => this.removeTimePeriod(id)} />
    }
  }

  renderTimePeriod = ({ start, finish, id }, idx) => {
    const { disabled } = this.props
    const startInputOptions = { type: 'time', name: this.inputName(id, 'start'), value: start, disabled }
    const finishInputOptions = { type: 'time', name: this.inputName(id, 'finish'), value: finish, disabled }
    const invalid = (start && !finish) || (!start && finish) || Date.parse(start) > Date.parse(finish)

    return (
      <div className={classNames('forbidden-time-period', { invalid })} key={id}>
        <DateTimePicker
          input_options={startInputOptions}
          afterChange={value => this.onTimePeriodChange(value, 'start', id)}
        />
        <span className='forbidden-time-period-separator'>{I18n.t('up_to')}</span>
        <DateTimePicker
          input_options={finishInputOptions}
          afterChange={value => this.onTimePeriodChange(value, 'finish', id)}
        />

        {!disabled && this.renderAddIcon(idx)}
        {!disabled && this.renderRemoveIcon(id)}
      </div>
    )
  }

  renderTimeEntriesMessage() {
    const { task_id } = this.props

    return (
      <div className='d-flex flex-column time-entries-message'>
        {I18n.t('v2.tasker.tasks.form.forbidden_periods_message_info')}
        <br />
        <span>
          {I18n.t('v2.tasker.tasks.form.forbidden_periods_message')}
          <a href={url('v2/tasker/tasks', task_id, 'time_entries')}>
            {' '}
            {I18n.t('v2.tasker.tasks.form.forbidden_periods_message_link')}
          </a>
        </span>
      </div>
    )
  }

  render() {
    const { disabled } = this.props

    return (
      <div className='d-flex flex-row'>
        <div className='time-period-inputs'>{this.state.timePeriods.map(this.renderTimePeriod)}</div>
        {disabled && this.renderTimeEntriesMessage()}
      </div>
    )
  }
}

export default ForbiddenTimePeriodsInput
