import React, { useEffect, useState } from 'react'

import ReactDOM, { unmountComponentAtNode } from 'react-dom'
import Turbolinks from 'turbolinks'

import { getApiErrorMessage, showErrorModal } from '../utils/confirmations'
import { t } from '../utils/i18n'
import { Select } from './Forms/Fields'
import Modal from './Modal'

export const ConfirmationWithDataTransfer = ({
  title,
  description,
  options,
  transferTitle,
  onConfirm,
  onClose,
  renderOptionLabel,
  formatSelectedItem,
  ...others
}) => {
  const [value, setValue] = useState(options[0])

  const onConfirmationConfirm = () => {
    onConfirm(value.id)
      .then(() => Turbolinks.visit(window.location))
      .catch(() => {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      })
  }

  return ReactDOM.createPortal(
    <Confirmation onConfirm={onConfirmationConfirm} cancelAction={onClose} className='transfer-modal' {...others}>
      <>
        <div className='transfer-modal__info'>
          <div className='title'>{title}</div>
          <div className='description'>{description}</div>
        </div>
        <div className='transfer-modal__transfer'>
          <div className='description'>{transferTitle}</div>
          <div className='select'>
            <Select
              name='transfer_select'
              items={options}
              onSelect={setValue}
              value={value}
              renderOptionLabel={renderOptionLabel}
              formatSelectedItem={formatSelectedItem}
            />
          </div>
        </div>
      </>
    </Confirmation>,
    document.querySelector('.confirmation-container')
  )
}

const Confirmation = ({
  setIsOpen,
  event,
  cancelAction,
  onConfirm,
  closeOnConfirm,
  confirmButton,
  text,
  children,
  className = '',
  cancelText = t('cancel')
}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }

    setLoading(false)
  }, [event])

  const close = event => {
    if (event) {
      event.preventDefault()
      event.nativeEvent.stopImmediatePropagation()
    }

    if (cancelAction) {
      cancelAction()
    }

    if (setIsOpen) {
      setIsOpen(false)
    } else {
      unmountComponentAtNode(document.querySelector('.confirmation-container'))
    }
  }

  const confirmAction = () => {
    setLoading(true)

    if (onConfirm) {
      onConfirm()
    } else {
      event.target.dispatchEvent(new MouseEvent('click', { bubbles: true, confirmed: true }))
    }

    if (closeOnConfirm) close()
  }

  const buttons = [
    {
      title: cancelText,
      type: 'secondary',
      onClick: close
    }
  ]

  if (confirmButton)
    buttons.unshift({
      ...confirmButton,
      onClick: confirmAction,
      disabled: loading
    })

  return (
    <Modal
      className={`modal-dialog-centered confirmation-modal ${className}`}
      isOpen
      buttons={buttons}
      buttonPlacement='center'
      onClose={close}
    >
      {text}
      {children}
    </Modal>
  )
}

export default Confirmation
