import React, { useState, useEffect, useRef } from 'react'

import classNames from 'classnames'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { t } from '../utils/i18n'
import NoteForm from './Forms/NoteForm'
import Icon from './Icon'
import Tooltip from './Tooltip'

dayjs.extend(customParseFormat)

const renderFormattedDate = dateTime => {
  const dateFormat = t('date.formats.default_human_js')
  const timeFormat = t('time.formats.time_human_js')

  const today = dayjs().format(dateFormat)
  const yesterday = dayjs().subtract(1, 'day').format(dateFormat)

  let formattedDate = dayjs(dateTime).format(dateFormat)
  let formattedTime = dayjs(dateTime).format(timeFormat)

  if (today === formattedDate) {
    formattedDate = `${t('date.days.today')}, `
  } else if (yesterday === formattedDate) {
    formattedDate = `${t('date.days.yesterday')}, `
  }

  return `${formattedDate} ${formattedTime}`
}

const ActivityItem = ({ item }) => (
  <div className='activity-version'>
    <div className='activity-version-content item-with-divider'>
      <div className='divider' />
      <div className='version-datetime'>{renderFormattedDate(item.created_at)}</div>
      <div className='divider' />
    </div>
    <div className='activity-version-content'>{item.text}</div>
  </div>
)

const NoteItem = ({ item, showImage, scrollToBottom }) => (
  <div className='activity-note'>
    <div className='activity-note-image-container'>
      <img src={item.author_picture} className='activity-note-img' />
    </div>
    <div className='activity-note-content'>
      <div className='note-content-info'>
        <div className='note-name'>{item.user.full_name}</div>
        <div className='note-datetime'>{renderFormattedDate(item.created_at)}</div>
      </div>
      <div
        className='note-text'
        // text is sanitized in the backend using AutoUrlLinker
        dangerouslySetInnerHTML={{ __html: item.formatted_text }}
      />
      {showImage && item.file_url && (
        <div className='img-holder'>
          <a href={item.file_url} target='_blank' rel='noreferrer'>
            <img src={item.file_preview_url} onLoad={scrollToBottom} />
          </a>
        </div>
      )}
    </div>
  </div>
)

const itemTypesMap = {
  note: NoteItem,
  activity: ActivityItem
}

const emptyNotification = (
  <div className='empty-notification'>
    <Icon icon='noMessages' className='icon' />
    <div className='text-container item-with-divider'>
      <div className='divider' />
      <div className='info-text'>{t('v2.notes.no_entries')}</div>
      <div className='divider' />
    </div>
  </div>
)

const FilterCheckbox = ({ name, filters, setFilters }) => {
  const handleClick = () => {
    setFilters({ ...filters, [name]: !filters[name] })
  }

  return (
    <div className='filter-option' onClick={handleClick}>
      <span className={classNames('checkbox', { checked: filters[name] })}>
        {filters[name] && <Icon icon='checkMark' />}
      </span>
      {t(`v2.notes.show_${name}`)}
    </div>
  )
}

const ActivityBlock = ({ task_activities, form_props }) => {
  const [filters, setFilters] = useState({ notes: true, activities: true, images: true })
  const [activities, setActivities] = useState(task_activities)
  const messagesEnd = useRef()

  const scrollToBottom = () => {
    messagesEnd.current.scroll({
      top: messagesEnd.current.scrollHeight,
      left: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    scrollToBottom()
  }, [filters, activities])

  const activityItems = activities.filter(
    item =>
      (item.type === 'activity' && filters.activities) ||
      (item.file_url && filters.images) ||
      (item.text && filters.notes)
  )

  const afterCreate = newNote => setActivities([...activities, newNote])

  return (
    <>
      <div className='activity-header'>
        {t('v2.notes.title').toUpperCase()}
        <div className='activity-filter-button'>
          <Icon data-tip data-for='activity-block-tooltip' icon='cog' className='activity-filter-icon' />
          <Tooltip
            id='activity-block-tooltip'
            place='bottom'
            effect='solid'
            globalEventOff='click'
            event='click'
            clickable
          >
            <FilterCheckbox name='activities' filters={filters} setFilters={setFilters} />
            <FilterCheckbox name='notes' filters={filters} setFilters={setFilters} />
            <FilterCheckbox name='images' filters={filters} setFilters={setFilters} />
          </Tooltip>
        </div>
      </div>
      <div className='activity-block-content' ref={messagesEnd}>
        {activityItems.length
          ? activityItems.map((item, key) => {
              const ItemComponent = itemTypesMap[item.type]
              return <ItemComponent key={key} item={item} showImage={filters.images} scrollToBottom={scrollToBottom} />
            })
          : emptyNotification}
      </div>
      <NoteForm {...form_props} afterCreate={afterCreate} />
    </>
  )
}

export default ActivityBlock
