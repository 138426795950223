import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Store from '../Store'
import TableContainer from '../TableContainer'
import GuestsFilter from './GuestsFilter'
import GuestsTable, { Identifiers } from './GuestsTable'

class GuestsTableContainer extends TableContainer {
  pathPrefix = 'v2/guests'

  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'currently_checked_in_duration', desc: false }],
    baseScope: this.props.base_scope
  })

  columns = [
    {
      header: t('activerecord.attributes.guest.full_name'),
      accessorKey: 'full_name',
      cell: ({ getValue, row: { original } }) => <a href={url(this.pathPrefix, original.id)}>{getValue()}</a>
    },
    {
      header: t('activerecord.attributes.employee.identifiers'),
      accessorKey: 'nfc_identifier_presence',
      sortable: false,
      cell: ({ getValue, row: { original } }) => <Identifiers id={original.id} identifierPresent={getValue()} />,
      size: 60
    },
    {
      header: t('activerecord.attributes.guest.currently_in_object'),
      accessorKey: 'currently_checked_in_duration'
    },
    {
      header: t('activerecord.attributes.guest.currently_checked_in_construction_object_name'),
      accessorKey: 'currently_checked_in_construction_object_name'
    },
    {
      header: t('activerecord.attributes.guest.personal_code'),
      accessorKey: 'personal_code'
    },
    {
      header: t('activerecord.attributes.guest.description'),
      accessorKey: 'description'
    },
    {
      header: t('activerecord.attributes.guest.consumed_time_in_period'),
      accessorKey: 'consumed_time_in_period'
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div>
        <GuestsFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <GuestsTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
          pathPrefix={this.pathPrefix}
        />
      </div>
    )
  }
}

export default GuestsTableContainer
