import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import { SimpleSelect, TimeZoneSelect } from '../FilterComponents'
import Icon from '../Icon'
import { CheckLogLocation, CheckLogCheckedWith, CheckLogDates, CheckLogDuration } from '../NfcCheckLogs/CheckLogsData'
import CheckLogsFilter from '../NfcCheckLogs/CheckLogsFilter'
import NfcCheckLogsTableContainer from '../NfcCheckLogsTableContainer'
import Store from '../Store'
import ConstructionObjectNfcCheckLogsTable from './ConstructionObjectNfcCheckLogsTable'

class ConstructionObjectNfcCheckLogsTableContainer extends NfcCheckLogsTableContainer {
  store = new Store({
    url: this.props.url,
    defaultFilter: this.props.default_filter,
    defaultSorted: [{ id: 'check_log_date_properties', desc: true }],
    timeZone: this.props.default_time_zone_filter.time_zone,
    // To handle auto expansion when filtered by task/subtask
    onDataLoad: () => {
      const taskIds = this.store.state.filters.construction_object_task_id
      const subtaskIds = this.store.state.filters.construction_object_sub_task_id

      const task_filter_present = taskIds && taskIds.length > 0
      const subtask_filter_present = subtaskIds && subtaskIds.length > 0

      if (task_filter_present || subtask_filter_present) {
        let expanded = {}

        for (let i = 0; i < this.store.state.data.length; i++) {
          expanded[i] = true
          if (subtask_filter_present) {
            for (let u = 0; u < this.store.state.data[i].subrows.length; u++) {
              expanded[`${i}.${u}`] = true
            }
          }
        }

        this.store.updateState({ expanded })
      }
    }
  })

  checkableTypeFilter = () => {
    return (
      <>
        <SimpleSelect
          store={this.store}
          selectable='checkable_type'
          className='checkable-type-filter'
          options={this.props.checkable_type_options}
          defaultFilter={this.props.default_filter}
          isClearable={false}
          dataContains='select-checkable-type-filter'
        />
        {this.props.time_zone_options.length > 1 && (
          <TimeZoneSelect
            store={this.store}
            selectable='time_zone'
            className='time-zone-filter'
            options={this.props.time_zone_options}
            defaultFilter={this.props.default_time_zone_filter}
            isClearable={false}
          />
        )}
      </>
    )
  }

  columns = [
    this.props.business_industry && {
      accessorKey: 'row-expander',
      id: 'row-expander',
      header: '',
      size: 40,
      maxSize: 40,
      sortable: false,
      enableResizing: false,
      minSize: 40,
      cell: ({ row, row: { original } }) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            {this.renderExpander(row, original.is_group, row.getIsExpanded(), original.parent_id_prefix)}
          </div>
        )
      },
      getClassName: this.getExpandableCellClassName,
      hideable: false
    },
    {
      header: t('activerecord.attributes.nfc_check_log.checkable_full_name'),
      accessorKey: 'checkable_full_name',
      cell: ({ getValue, row: { original } }) =>
        !original.is_child_time_entry && (
          <div className='text-truncate'>
            <Icon icon='avatar' className='avatar' />
            <a href={url('v2', `${original.checkable_type.toLowerCase()}s`, original.checkable_id)}>{getValue()}</a>
          </div>
        )
    },
    {
      header: t('activerecord.attributes.nfc_check_log.checked_in'),
      size: 340,
      accessorKey: 'checked_in_name'
    },
    {
      header: t('activerecord.attributes.nfc_check_log.registration_time'),
      accessorKey: 'check_log_date_properties',
      cell: ({ getValue, row: { original } }) => <CheckLogDates dateTimes={getValue()} checkLog={original} />
    },
    {
      header: t('activerecord.attributes.nfc_check_log.checked_with'),
      accessorKey: 'check_log_checked_with_properties',
      size: 90,
      sortable: false,
      cell: ({ getValue, row: { original } }) => <CheckLogCheckedWith checkedWith={getValue()} checkLog={original} />
    },
    {
      header: t('activerecord.attributes.nfc_check_log.location'),
      accessorKey: 'check_log_location_properties',
      size: 120,
      sortable: false,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue()
        return value ? <CheckLogLocation locationData={value} checkLog={original} onClick={this.openMapModal} /> : null
      }
    },
    {
      header: t('activerecord.attributes.nfc_check_log.checked_in_duration'),
      accessorKey: 'checked_in_duration',
      cell: ({ getValue, row: { original } }) => (
        <CheckLogDuration duration={getValue()} invalid={original.work_duration_exceeded} />
      )
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <CheckLogsFilter
          {...this.props}
          store={this.store}
          checkableTypeFilter={this.checkableTypeFilter}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
          hideObjectFilter
          filters={this.props.business_industry}
        />
        <ConstructionObjectNfcCheckLogsTable
          ref={this.checkLogTableRef}
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
          onExpand={row => this.onNfcExpandedChange(row, 'ConstructionObject', this.props.construction_object_id)}
          expanded={this.store.expanded}
        />
      </div>
    )
  }
}

export default ConstructionObjectNfcCheckLogsTableContainer
