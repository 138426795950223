import { useState, useEffect } from 'react'

import axios from 'axios'
import { getApiErrorMessage, showErrorModal } from 'utils/confirmations'
import { url } from 'utils/urlGenerator'

export default () => {
  const [isLoading, setIsLoading] = useState(false)
  const [companies, setCompanies] = useState([])

  useEffect(() => {
    setIsLoading(true)

    axios
      .get(url('v2/shifts/companies'))
      .then(({ data }) => {
        setCompanies(data.rows)
      })
      .catch(() => {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return { companies, isLoading }
}
