import React from 'react'

import Tooltip from './Tooltip'

class TenantToggle extends React.Component {
  renderLogo = (src, abbr, isOption) => {
    if (src) {
      return <img src={src} className={isOption ? 'tenant-image-sm' : 'tenant-image'} />
    } else {
      return <span className={isOption ? 'empty-logo-sm' : 'empty-logo'}>{abbr}</span>
    }
  }

  tenantOptions() {
    const { tenant_options } = this.props

    const options = tenant_options.map(({ account_img_src, account_name, account_tenant }) => {
      return (
        <a key={account_tenant} className='tenant-option' href={`/${account_tenant}`}>
          <span className='tenant-option-text'>{account_name}</span>
          {this.renderLogo(account_img_src, account_name[0], true)}
        </a>
      )
    })

    return <div className='tenant-options'>{options}</div>
  }

  render() {
    const { tenant_logo_src, tenant_abbr } = this.props

    return (
      <React.Fragment>
        <div data-tip data-for='tenant-options'>
          {this.renderLogo(tenant_logo_src, tenant_abbr, false)}
        </div>
        <Tooltip
          id='tenant-options'
          place='bottom'
          effect='solid'
          offset={{ right: -57 }}
          clickable={true}
          className='tenant-switcher white'
          event='click'
          globalEventOff='click'
        >
          {this.tenantOptions()}
        </Tooltip>
      </React.Fragment>
    )
  }
}

export default TenantToggle
