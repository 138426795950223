import React, { createContext, useState, useEffect, useMemo, useCallback, useContext } from 'react'

import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'

import { t } from '../../utils/i18n'
import ShiftForm from '../Forms/ShiftForm'
import { AddSubjectButton } from '../Table'
import { SelectedShiftItemsProvider } from './SelectedShiftItemsContext'
import ShiftDayModal from './ShiftDayModal'
import ShiftItem from './ShiftItem'
import { ShiftContext } from './Shifts'

dayjs.extend(isToday)

export const OpenedItemsContext = createContext({
  openedItems: [],
  toggleOpenedItems: () => {}
})

const ShiftList = ({ search, creatable, formOptions, ...others }) => {
  const [openedItems, setOpenedItems] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [shift, setShift] = useState(null)
  const { shifts, loading } = useContext(ShiftContext)

  const toggleOpenedItems = useCallback(
    id => {
      setOpenedItems(prevState => {
        const prevShiftItem = document.querySelector(`[data-shift-id='${id}']`).previousSibling
        const indexInPrevState = prevState.indexOf(id)

        if (indexInPrevState > -1) {
          prevShiftItem && prevShiftItem.classList.remove('with-round-border')

          return [...prevState.slice(0, indexInPrevState), ...prevState.slice(indexInPrevState + 1)]
        } else {
          prevShiftItem && prevShiftItem.classList.add('with-round-border')

          return [...prevState, id]
        }
      })
    },
    [setOpenedItems]
  )

  const openedItemsContextValue = useMemo(
    () => ({
      openedItems,
      toggleOpenedItems
    }),
    [openedItems, toggleOpenedItems]
  )

  useEffect(() => {
    if (!search) {
      setOpenedItems([])
    }
  }, [search])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (search.length > 0) {
      const newIds = shifts.map(shift => shift.id)

      if (newIds.sort().join(',') !== openedItems.sort().join(',')) {
        setOpenedItems(newIds)
      }
    }
  }, [shifts])
  /* eslint-enable react-hooks/exhaustive-deps */

  const translationName = loading ? 'loading_text' : 'no_data_text'

  const openEditForm = useCallback(shift => {
    setShift(shift)
    setModalOpen(true)
  }, [])

  const closeShiftModal = () => {
    setShift(null)
    setModalOpen(false)
  }

  return (
    <>
      <OpenedItemsContext.Provider value={openedItemsContextValue}>
        <SelectedShiftItemsProvider>
          <div className='shift-list'>
            {shifts.length && !loading ? (
              shifts.map((shift, index) => (
                <ShiftItem
                  key={shift.id}
                  shift={shift}
                  index={index}
                  search={search}
                  openEditForm={openEditForm}
                  {...others}
                />
              ))
            ) : (
              <div className='shift-empty-item'>{t(`components.shift_list.${translationName}`)}</div>
            )}
          </div>
          <ShiftDayModal />
        </SelectedShiftItemsProvider>
      </OpenedItemsContext.Provider>
      {creatable && <AddSubjectButton onClick={() => setModalOpen(true)} translationPath={'v2.shifts.index'} />}
      {modalOpen && (
        <ShiftForm modalOpen={modalOpen} closeModal={closeShiftModal} shift={shift} formOptions={formOptions} />
      )}
    </>
  )
}

export default ShiftList
