import React from 'react'

import axios from 'axios'
import classNames from 'classnames'
import Turbolinks from 'turbolinks'

import { showErrorModal } from '../../../utils/confirmations'
import { t } from '../../../utils/i18n'
import Icon from '../../Icon'
import Tooltip from '../../Tooltip'

class StateSwitcher extends React.Component {
  state = { isLoading: false }

  async switchState(eventName) {
    this.setState({ isLoading: true })

    try {
      await axios.put(`${this.props.path}?event=${eventName}`)

      Turbolinks.visit()
    } catch ({ response }) {
      this.setState({ isLoading: false })
      this.showErrors(response.data.errors)
    }
  }

  showErrors(responseErrors) {
    const errors = (
      <ul className='error-list'>
        {responseErrors.map(({ message }, index) => (
          <li className='error-item' key={index}>
            {message}
          </li>
        ))}
      </ul>
    )

    showErrorModal({ title: t('v2.tasker.tasks.notifications.state_not_updated'), errors })
  }

  renderStateTransitions() {
    return this.props.transitions.map(({ label, event, colors }) => (
      <div className='state-option' key={event} onClick={() => this.switchState(event)}>
        <Icon icon='arrow' className='arrow' />
        <span className='label' style={{ color: colors.font, background: colors.bg }}>
          {label}
        </span>
      </div>
    ))
  }

  renderButtonIcon() {
    if (this.state.isLoading) {
      return <i className='fa fa-spinner fa-pulse' />
    } else {
      return <i className='fa fa-chevron-down' />
    }
  }

  render() {
    const { current_state_label, can_change_state, colors } = this.props

    return (
      <div className='task-state-switcher-container'>
        <span
          className={classNames('btn btn-sm task-state-switcher', { 'cannot-change': !can_change_state })}
          data-tip
          data-for='state-transitions'
          style={{ color: colors.font, background: colors.bg }}
        >
          {current_state_label}
          {can_change_state && this.renderButtonIcon()}
        </span>

        {can_change_state && (
          <Tooltip
            id='state-transitions'
            place='bottom'
            effect='solid'
            clickable
            className='states-dropdown'
            event='click'
            globalEventOff='click'
          >
            {this.renderStateTransitions()}
          </Tooltip>
        )}
      </div>
    )
  }
}

export default StateSwitcher
