import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import ConstructionObjectsFilter from '../ConstructionObjects/ConstructionObjectsFilter'
import Store from '../Store'
import TableContainer from '../TableContainer'
import GuestConstructionObjectsTable from './GuestConstructionObjectsTable'

class GuestConstructionObjectsTableContainer extends TableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'guest_checked_in', desc: false }]
  })

  columns = [
    {
      accessorKey: 'construction_object_id',
      visible: false
    },
    {
      header: t('activerecord.attributes.construction_object.short_name'),
      accessorKey: 'construction_object_short_name',
      cell: ({ getValue, row: { original } }) => (
        <a href={url('v2/construction_objects', original.construction_object_id)}>{getValue()}</a>
      )
    },
    {
      header: t('activerecord.attributes.construction_object.name'),
      accessorKey: 'construction_object_name',
      cell: ({ getValue, row: { original } }) => (
        <a href={url('v2/construction_objects', original.construction_object_id)}>{getValue()}</a>
      )
    },
    {
      header: t('activerecord.attributes.construction_object.added_to_object'),
      accessorKey: 'created_at'
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <ConstructionObjectsFilter
          {...this.props}
          store={this.store}
          filters={false}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <GuestConstructionObjectsTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
        />
      </div>
    )
  }
}

export default GuestConstructionObjectsTableContainer
