import dayjs from 'dayjs'
import I18n from 'i18n-js'

import { t } from './i18n'

export const formatDate = (date, format = t('date.formats.default_human_js'), fallback = '') =>
  date ? dayjs(date).format(format) : fallback

export const formatCurrency = (value, separator = '.', delimiter = ' ') =>
  I18n.toCurrency(value, { unit: '', separator, delimiter })
