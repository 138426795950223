import React from 'react'

import classNames from 'classnames'
import { useFormContext } from 'react-hook-form'

import { t } from '../../../../utils/i18n'
import FieldsRow from '../../../Forms/FieldsRow'
import { DateField, FilesField, TextField, SelectField, MultiselectField } from '../../../Forms/HookFields'
import TasksTemplateSelector from './Fields/TemplateSelector'
import { LocationModule } from './Modules/LocationModule'
import { RegularityModule } from './Modules/RegularityModule'
import { SubtaskModule } from './Modules/SubtaskModule'
import { TimeTrackingModule } from './Modules/TimeTrackingModule'
import styles from './styles/Fields.module.scss'

const placeholder = name => t(`activerecord.attributes.tasker/task.${name}`)

const TasksFormFields = ({ closeForm, title, isGroup, labels }) => {
  const { handleSubmit, onSubmit, submitting, modules, formType, selectOptions, locationAllowed } = useFormContext()

  const newForm = formType === 'new'

  const userLabel = newForm || isGroup ? 'responsible_users_ids' : 'responsible_user'
  const userGroupLabel = newForm || isGroup ? 'responsible_user_groups_ids' : 'responsible_user_group'

  const ResponsiblesComponent = newForm || isGroup ? MultiselectField : SelectField

  return (
    <div className={classNames(styles.tasksFormFields, 'tasks-form-fields', 'react-form')}>
      <div className={styles.formTitle}>{title}</div>
      {formType === 'new' && <TasksTemplateSelector />}
      <div className='mt-4 mb-3'>
        <TextField
          name='title'
          required
          placeholder={t('activerecord.attributes.tasker/task.title')}
          className={styles.nameInput}
          wrapperClassName='mt-0 mb-2'
        />
        <TextField
          inputProps={{ rows: '5' }}
          name='description'
          textArea
          placeholder={t('activerecord.attributes.tasker/task.description')}
          wrapperClassName='mt-0 mb-2'
        />
        <FilesField buttonText={t('v2.tasker.tasks.form.fields.add_files')} />
      </div>

      <div className={classNames('mb-1', styles.label)}>{t('v2.tasker.tasks.form.subtitles.period')}</div>
      <FieldsRow>
        <DateField
          name='starting_time'
          placeholder={t('from')}
          disabled={modules.regularity}
          type='date_time'
          withIcon
        />
        <DateField name='deadline' placeholder={t('up_to')} disabled={modules.regularity} type='date_time' withIcon />
      </FieldsRow>

      <div className={classNames('mb-1', styles.label)}>{t('v2.tasker.tasks.form.subtitles.responsibles')}</div>
      <FieldsRow>
        <ResponsiblesComponent
          name={userLabel}
          options={selectOptions.responsible_user_ids}
          placeholder={placeholder(userLabel)}
          disabled={isGroup}
          showCountInfo={false}
          isClearable
        />
        <ResponsiblesComponent
          name={userGroupLabel}
          options={selectOptions.responsible_user_group_ids}
          placeholder={placeholder(userGroupLabel)}
          disabled={isGroup}
          showCountInfo={false}
          isClearable
        />
      </FieldsRow>

      <div className={classNames('mb-1', styles.label)}>{t('v2.tasker.tasks.form.subtitles.labels')}</div>
      <FieldsRow>
        <SelectField
          name='task_group_id'
          options={selectOptions.task_group_id}
          isClearable
          placeholder={placeholder('task_group')}
        />
        {labels.client && (
          <SelectField
            name='client_label'
            options={selectOptions.client_label}
            placeholder={placeholder('client_label')}
            creatable
            isClearable
          />
        )}
        {labels.device && (
          <SelectField
            name='device_label'
            options={selectOptions.device_label}
            placeholder={placeholder('device_label')}
            creatable
            isClearable
          />
        )}
      </FieldsRow>

      {modules.subtasks && <SubtaskModule />}
      {modules.timeTracking && <TimeTrackingModule />}
      {modules.location && locationAllowed && <LocationModule />}
      {modules.regularity && <RegularityModule />}

      <div className='btn-container' style={{ margin: '2.5rem 0 0' }}>
        <button type='submit' className='btn btn-primary' onClick={handleSubmit(onSubmit)} disabled={submitting}>
          {t('save')}
        </button>

        <button className='btn btn-secondary' onClick={closeForm} disabled={submitting}>
          {t('cancel')}
        </button>
      </div>
    </div>
  )
}

export default TasksFormFields
