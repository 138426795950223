import React, { useEffect, useState } from 'react'

import axios from 'axios'

import { t } from '../../../utils/i18n'
import AbsenceTypeForm, { useAbsenceTypeFormik, initialValues } from '../../Forms/AbsenceTypeForm'
import ModalFormikForm from '../../ModalFormikForm'
import Table, { AddSubjectButton } from '../../Table'
import { editFormik, destroyAbsenceType } from '../../elements/actionDefinitions'

const formikForm = { Form: AbsenceTypeForm, useForm: useAbsenceTypeFormik }
const pathPrefix = 'v2/settings/classifiers/absence_types'

const columns = [
  {
    Header: t('activerecord.attributes.absence_type.color'),
    accessor: 'color',
    className: 'text-center',
    width: 75,
    Cell: ({ resource }) => (
      <div className='w-100 d-flex justify-content-center'>
        <div style={{ width: 15, height: 15, backgroundColor: resource.color, borderRadius: 4 }}></div>
      </div>
    )
  },
  {
    Header: t('activerecord.attributes.absence_type.title'),
    accessor: 'title'
  },
  {
    Header: t('activerecord.attributes.absence_type.abbreviation'),
    accessor: 'abbreviation'
  }
]

const AbsenceTypes = ({ url }) => {
  const [data, setData] = useState([])
  const [creationModalOpen, setCreationModalOpen] = useState(false)

  useEffect(() => {
    const loadData = async () => {
      const { data } = await axios.get(url)

      setData(data.rows)
    }

    loadData()
  }, [url])

  const actions = [
    editFormik({
      title: 'v2.settings.classifiers.absence_types.form.title',
      formikForm,
      overrides: {
        classes: 'new-modal modal-lg'
      }
    }),
    destroyAbsenceType({ pathPrefix, options: data.map(type => ({ value: type.id, label: type.title, ...type })) })
  ]

  const buttonProps = {
    className: 'ml-4',
    translationPath: 'v2.settings.classifiers.absence_types.index',
    onClick: () => setCreationModalOpen(true)
  }

  return (
    <div className='w-100'>
      <Table columns={columns} actions={actions} data={data} className='absence-types-table' />
      <AddSubjectButton {...buttonProps} />
      <ModalFormikForm
        isOpen={creationModalOpen}
        setIsOpen={setCreationModalOpen}
        onClose={() => setCreationModalOpen(false)}
        formData={initialValues}
        classes='new-modal modal-lg'
        action='new'
        title={t('v2.settings.classifiers.absence_types.form.new_title')}
        formikForm={formikForm}
        reloadPageOnSuccess
        skipToggleButton
      />
    </div>
  )
}

export default AbsenceTypes
