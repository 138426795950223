import React, { useMemo } from 'react'

import classNames from 'classnames'

import { generateId } from '../utils/generateId'
import { t } from '../utils/i18n'
import Icon from './Icon'
import styles from './Styles/SimpleTable.module.scss'
import { ActionDropdown } from './elements/actions'

const actionsColumn = actions => ({
  className: 'row-actions text-center',
  width: 50,
  Cell: ({ resource }) => {
    if (
      (actions.permissions &&
        actions.every(action => !(resource.permissions && resource.permissions[action.permission]))) ||
      !actions ||
      actions?.length === 0
    ) {
      return null
    }

    return (
      <ActionDropdown
        actions={actions}
        object={resource}
        permissions={resource.permissions}
        bottomOffset={0}
        placement='left'
        tooltipId={`row-actions-${generateId()}`}
      />
    )
  }
})

export const AddSubjectButton = ({ translationPath, onClick, className = '' }) => (
  <div className={`btn-wrapper mt-4 ${className}`}>
    <button
      className='btn btn-sm btn-primary new-resource-button py-2'
      onClick={onClick}
      title={t(`${translationPath}.add_subject`)}
    >
      <Icon icon='iconPlusCircle' className='mr-2' />
      {t(`${translationPath}.add_subject`)}
    </button>
  </div>
)

const Table = ({
  columns,
  data = [],
  withBorder = false,
  withHover = false,
  actions = null,
  isLoading = false,
  className = '',
  onRowClick
}) => {
  const tableClasses = [
    styles.simpleTable,
    withBorder ? styles.withBorder : '',
    withHover ? styles.withHover : '',
    className
  ]
  const rowClasses = [styles.row, onRowClick ? 'clickable' : '']
  const finalColumns = useMemo(() => (actions ? columns.concat(actionsColumn(actions)) : columns), [actions, columns])

  const applyColumnProps = column => {
    return {
      className: column.className || '',
      style: {
        width: column.width || 'auto'
      }
    }
  }

  return (
    <table className={classNames(tableClasses)}>
      <thead>
        <tr className={styles.row}>
          {finalColumns.map((col, idx) => (
            <th key={idx} {...applyColumnProps(col)}>
              {col.Header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {isLoading && (
          <tr className={styles.noDataRow}>
            <td colSpan={finalColumns.length} className='text-center'>
              <i className='fa fa-spinner fa-spin' /> {t('loading_data')}
            </td>
          </tr>
        )}
        {!isLoading &&
          data.map((row, idx) => (
            <tr
              key={idx}
              className={classNames(rowClasses)}
              onClick={
                onRowClick
                  ? () => {
                      onRowClick(row)
                    }
                  : null
              }
            >
              {finalColumns.map((col, idx2) => (
                <td key={idx2} {...applyColumnProps(col)}>
                  {col.Cell ? col.Cell({ resource: row, id: idx }) : row[col.accessor]}
                </td>
              ))}
            </tr>
          ))}
        {!isLoading && data.length === 0 && (
          <tr className={styles.noDataRow}>
            <td colSpan={finalColumns.length} className='text-center'>
              {t('no_data')}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default Table
