import React, { useState, useEffect } from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { FormProvider, useForm } from 'react-hook-form'

import useTranslate from '../../../hooks/useTranslate'
import { url } from '../../../utils/urlGenerator'
import { SelectField } from '../../Forms/HookFields'
import { useRegistrationsQuery } from './queries'

const ContractSelect = ({ constructionObjectId, ligumsId, setLigumsId, setNewForm }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.ContractSelect')
  const [formDisabled, setFormDisabled] = useState(!!ligumsId)

  const { data: registrations } = useRegistrationsQuery(constructionObjectId)

  const formContext = useForm({
    defaultValues: { ligumsId: null },
    mode: 'onSubmit'
  })

  useEffect(() => {
    if (registrations) {
      formContext.setValue(
        'ligumsId',
        registrations.find(opt => opt.value === ligumsId)
      )
    }
  }, [registrations, formContext, ligumsId])

  const { mutate } = useMutation({
    mutationFn: ({ ligumsId }) => {
      return axios.post(url('v2/construction_objects', constructionObjectId, 'update_vedludb_ligums_id'), {
        vedludb_ligums_id: ligumsId?.value
      })
    },
    onSuccess: ({ data }) => {
      setLigumsId(data.vedludb_ligums_id)
      setFormDisabled(true)
      setNewForm(false)
    }
  })

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = formContext

  return (
    <div className='row'>
      <div className='m-4 col-8'>
        <FormProvider {...formContext}>
          <form
            onSubmit={handleSubmit(mutate)}
            className='react-form d-flex gap-2 align-items-center'
            disabled={formDisabled}
          >
            <SelectField
              name='ligumsId'
              options={registrations || []}
              disabled={formDisabled}
              wrapperClassName='m-0'
              placeholder={t('.vedludb_ligums_id')}
            />
            {formDisabled ? (
              <button className='btn btn-secondary' type='button' onClick={() => setFormDisabled(false)} key='activate'>
                {t('.change')}
              </button>
            ) : (
              <button className='btn btn-primary' type='submit' disabled={isSubmitting} key='submit'>
                {t('save')}
              </button>
            )}
            <button className='btn btn-secondary' type='button' onClick={() => setNewForm(true)}>
              {t('.register_new')}
            </button>
          </form>
        </FormProvider>
      </div>
    </div>
  )
}

export default ContractSelect
