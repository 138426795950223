// https://react-table.js.org/#/story/readme
import React, { forwardRef } from 'react'

import classNames from 'classnames'
import { some, pick } from 'lodash'
import { observer } from 'mobx-react'
import Turbolinks from 'turbolinks'

import { t } from '../utils/i18n'
import { cleanTasksTableData } from '../utils/sessionStorage'
import { url } from '../utils/urlGenerator'
import ModalForm from './ModalForm'
import ModalFormikForm from './ModalFormikForm'
import ReactTable from './ReactTable'
import { CheckboxColumn } from './TableCheckboxes'
import { ActionsColumn } from './elements/actions'

const DEFAULT_PAGE_SIZE = 20

class ActiveTable extends React.Component {
  constructor(props) {
    super(props)

    const { store, page_size } = this.props
    this.store = store

    if (!store.state.pageSize) {
      store.setState({ pageSize: page_size || DEFAULT_PAGE_SIZE })
    }

    if (this.props.pathPrefix !== 'v2/tasker/tasks') {
      cleanTasksTableData()
    }

    this.state = { editFormOpen: false }
  }

  componentDidUpdate() {
    if (!this.state.widths) {
      this.calculateColumnWidths()
    }
  }

  calculateColumnWidths() {
    const headers = document.querySelectorAll('.rt-th')
    const widths = {}

    if (!headers.length) return

    headers.forEach(header => {
      if (!header.querySelector('.resizer')) return

      const headerContent = header.querySelector('.header-content')
      const resizer = header.querySelector('.resizer')

      let { width, right } = getComputedStyle(resizer)
      let { paddingLeft, paddingRight } = getComputedStyle(headerContent)

      width = Number.parseInt(width)
      right = Number.parseInt(right)
      paddingLeft = Number.parseInt(paddingLeft)
      paddingRight = Number.parseInt(paddingRight)

      widths[header.dataset.columnName] = headerContent.clientWidth + paddingLeft + paddingRight + width - right
    })

    this.setState({ widths })
  }

  _getTdProps = tdProps => {
    const { row, column } = tdProps

    const props = this.props.getTdProps ? this.props.getTdProps(tdProps) : {}
    props.className = classNames(props.className, column.accessorKey)

    if (!props.onClick && row) {
      props.onClick = e => {
        if (e.target instanceof HTMLAnchorElement) return

        if (this.props.onRowClick) {
          this[`_${this.props.onRowClick}`](row, column)
        }
      }
    }

    return props
  }

  _navigateToResource = (row, { accessorKey }) => {
    if (['id', 'row_actions'].includes(accessorKey)) return

    const { pathPrefix, pathResourceId, canNavigateToResource } = this.props
    const { original } = row
    const permitted = canNavigateToResource ? canNavigateToResource(original) : true

    // Store subrow id, so that window can scroll to it on return to tasks table
    if (pathPrefix === 'v2/tasker/tasks') {
      sessionStorage.tableStorePage = this.store.state.page
      if (row.depth > 0) sessionStorage.tableTasksAnchor = `row_${row.id.replaceAll('.', '_')}`
    }

    if (permitted && pathPrefix) {
      Turbolinks.visit(url(pathPrefix, row[pathResourceId] || original.id))
    }
  }

  _openEditForm = (rowInfo, column) => {
    const canEdit = rowInfo?.original.permissions?.edit

    if (!canEdit || ['check_log_location_properties', 'id', 'row_actions'].includes(column.accessorKey)) return

    this.setState({ editFormOpen: true, rowInfo }, () => {
      this.toggleFormModal()
    })
  }

  _getTheadThProps = column => {
    const props = this.props.getTheadThProps ? this.props.getTheadThProps(column) : {}
    props.className = classNames(props.className, `${column.accessorKey}-header`)

    props['data-attribute'] = column.accessorKey

    return props
  }

  emIsCheckbox = em => em.className.match(/checkmark/g) || em.tagName === 'INPUT'

  toggleExpandedRow = row => {
    const { onExpand } = this.props

    if (row.getCanExpand() && onExpand) {
      return {
        onClick: e => {
          if (!this.emIsCheckbox(e.target)) {
            e.preventDefault()
            e.stopPropagation()

            row.toggleExpanded()
            onExpand(row)
          }
        }
      }
    } else {
      return {}
    }
  }

  _getTrProps = row => {
    const { getTrProps } = this.props

    const props = getTrProps ? getTrProps(row) : this.toggleExpandedRow(row)

    return props
  }

  _getTrClassName = row => {
    if (!row) return ''
    const { highlightedBy, highlightedDangerBy, warningBy, dangerBy, inactiveBy, getTrClassName } = this.props

    const classes = getTrClassName ? getTrClassName(row) : ''

    return classNames(classes, {
      highlighted: some(pick(row.original, highlightedBy)),
      inactive: some(pick(row.original, inactiveBy)),
      warning: some(pick(row.original, warningBy)),
      danger: some(pick(row.original, dangerBy)),
      'highlighted-danger': some(pick(row.original, highlightedDangerBy))
    })
  }

  _getCellMinWidth({ accessorKey }) {
    return this.state.widths && this.state.widths[accessorKey]
  }

  _columns() {
    const { columns, actions } = this.props

    const result = columns.filter(Boolean)

    if (actions) {
      if (actions.header) {
        result.unshift(CheckboxColumn(this.props))
      }
      result.push(ActionsColumn(this.store, actions))
    }

    return result.map(column => ({
      ...column,
      enableResizing: column.enableResizing !== false,
      enableSorting: column.sortable === undefined,
      minSize: column.minSize || this._getCellMinWidth(column)
    }))
  }

  updateSorted = newSorted => {
    const prevSorted = this.store.state.sorted
    const { onSortedChange } = this.props
    onSortedChange && onSortedChange()
    this.store.setState({ sorted: [newSorted()[0], ...prevSorted] })
  }

  renderEditModalForm() {
    const { editFormOpen, rowInfo } = this.state

    if (!editFormOpen) return

    const { translationPath, pathPrefix, formParams = {}, formikForm } = this.props
    const translation = Array.isArray(translationPath) ? translationPath.join('.') : translationPath

    const formProps = {
      action: 'edit',
      url: url(pathPrefix, rowInfo.original.id, 'edit', formParams),
      title: t(`v2.${translation}.edit.title`),
      reloadPageOnSuccess: true,
      toggleModal: toggleFormModal => (this.toggleFormModal = toggleFormModal),
      skipToggleButton: true
    }

    if (formikForm) {
      return <ModalFormikForm {...formProps} formikForm={formikForm} />
    } else {
      return <ModalForm {...formProps} />
    }
  }

  getColumnVisibility() {
    const { columnOptions, columns } = this.props
    if (columnOptions) {
      return columnOptions.reduce((arr, curr) => ({ ...arr, [curr.accessorKey]: curr.visible }), {})
    } else {
      return columns.reduce((arr, curr) => ({ ...arr, [curr.accessorKey]: true }), {})
    }
  }

  renderTable() {
    const { ...props } = this.props
    const { data, dataSize, page, pages, sorted, loading } = this.store.state

    return (
      <ReactTable
        {...props}
        ref={this.props.innerRef}
        store={this.store}
        columns={this._columns()}
        data={data}
        dataSize={dataSize}
        page={page}
        pages={pages}
        sorted={sorted}
        loading={loading}
        onFetchData={this.store.reload}
        onSortedChange={this.updateSorted}
        getTdProps={this._getTdProps}
        getTheadThProps={this._getTheadThProps}
        getTrProps={this._getTrProps}
        getTrClassName={this._getTrClassName}
        columnVisibility={this.getColumnVisibility()}
        className={`-highlight ${this.props.tableClass ?? ''}`}
        loadingText={t('components.active_table.loading_text')}
      />
    )
  }

  render() {
    return (
      <>
        {this.renderTable()}
        {this.renderEditModalForm()}
      </>
    )
  }
}

const Component = observer(ActiveTable)

export default forwardRef((props, ref) => <Component innerRef={ref} {...props} />)
