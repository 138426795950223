import React, { useEffect } from 'react'

import classNames from 'classnames'
import { t } from 'i18n-js'
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form'

import { DateField } from '../../../../Forms/HookFields'
import Icon from '../../../../Icon'
import styles from '../styles/ModuleWrapper.module.scss'

const createEmptyPeriod = () => {
  return {
    start: null,
    finish: null
  }
}

const TimePeriodRow = ({ idx, ...props }) => {
  const { insert, disabled, remove } = props

  const prefix = `time_tracking_forbidden_time_periods.${idx}`

  const periods = useWatch({ name: 'time_tracking_forbidden_time_periods' })

  return (
    <div className={classNames(styles.timePeriodRow, 'time-period-row')}>
      <DateField name={`${prefix}.start`} placeholder={t('from')} type='time' disabled={disabled} required />
      <div>-</div>
      <DateField name={`${prefix}.finish`} placeholder={t('up_to')} type='time' disabled={disabled} required />
      <div className={classNames(styles.controls, 'controls')}>
        {!disabled && (
          <>
            <Icon
              icon='iconPlusCircle'
              className={styles.action}
              onClick={() => insert(idx + 1, createEmptyPeriod())}
            />
            {periods.length > 1 && <Icon icon='iconTrash' className={styles.action} onClick={() => remove(idx)} />}
          </>
        )}
      </div>
    </div>
  )
}

const ForbiddenTimeInput = () => {
  const {
    submitting,
    timeEntry,
    register,
    setValue,
    formState: { errors }
  } = useFormContext()

  const { fields, insert, remove } = useFieldArray({
    name: 'time_tracking_forbidden_time_periods'
  })

  useEffect(() => {
    if (fields.length === 0) {
      insert(0, createEmptyPeriod())
    }
  }, [fields, insert])

  const sharedProps = {
    disabled: submitting || timeEntry,
    register,
    insert,
    setValue,
    remove
  }

  return (
    <>
      <div className={classNames('d-flex align-items-start mb-2', styles.withGaps)}>
        <div className={classNames('mt-2', styles.forbiddenPeriodsLabel)}>
          {t('activerecord.attributes.tasker/task.time_tracking_forbidden_time_periods')}:
        </div>
        <div className={classNames(styles.timePeriodInputs, 'time-period-inputs')}>
          {fields.map((item, index) => (
            <TimePeriodRow item={item} key={item.id} {...sharedProps} idx={index} />
          ))}
        </div>
      </div>
      {errors.time_tracking_forbidden_time_periods?.message && (
        <div className='form-field-error text-danger'>{errors.time_tracking_forbidden_time_periods.message}</div>
      )}
    </>
  )
}

export default ForbiddenTimeInput
