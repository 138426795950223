import React from 'react'

import ActiveTable from '../ActiveTable'
import NfcCheckLogsTable, { formikForm } from '../NfcCheckLogs/NfcCheckLogsTable'
import { editFormik } from '../elements/actionDefinitions'

class ReportNfcCheckLogsTable extends NfcCheckLogsTable {
  actions = {
    row: [editFormik({ pathPrefix: this.pathPrefix, title: 'v2.nfc_check_logs.edit.title', formikForm })]
  }

  formParams = {
    nfc_check_log: { construction_object_id: this.props.construction_object_id }
  }

  renderTable() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        pathPrefix={this.pathPrefix}
        translationPath='nfc_check_logs'
        formParams={this.formParams}
        onRowClick='openEditForm'
      />
    )
  }
}

export default ReportNfcCheckLogsTable
