import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../../utils/i18n'
import { FilterContainer, SearchField, SimpleSelect } from '../../FilterComponents'

class UserGroupsFilter extends React.Component {
  hiddenFilters = () => {
    const { store, user_options, parent_options } = this.props

    return (
      <>
        <SimpleSelect
          store={store}
          selectable='user_id'
          options={user_options}
          isMulti
          dataContains='select-user-filter'
        />
        <SimpleSelect
          store={store}
          selectable='parent_id'
          options={parent_options}
          isMulti
          dataContains='select-parent-filter'
        />
      </>
    )
  }

  render() {
    const { store, ...others } = this.props
    return (
      <FilterContainer store={store} hiddenFilters={this.hiddenFilters} {...others}>
        <SearchField store={store} placeholder={t('filters.search_user_groups')} />
      </FilterContainer>
    )
  }
}

export default observer(UserGroupsFilter)
