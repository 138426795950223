class ExternalDeviceForm {
  static selector = '.edit_external_device, .new_external_device'

  constructor(form) {
    this.form = form

    this.bindElements()
    this.bindEvents()
  }

  bindElements() {
    this.selectors = {
      timeTrackableIdInput: this.form.querySelector('#external_device_time_trackable_id'),
      timeTrackableTypeInput: this.form.querySelector('#external_device_time_trackable_type')
    }

    // sequence is important here!
    this.timeTrackableInputs = {
      SubTask: this.form.querySelector('#external_device_construction_object_sub_task'),
      Task: this.form.querySelector('#external_device_construction_object_task'),
      ConstructionObject: this.form.querySelector('#external_device_construction_object')
    }
  }

  bindEvents() {
    Object.values(this.timeTrackableInputs).forEach(input => {
      input?.addEventListener('change', this.setTimeTrackable)
    })
  }

  setTimeTrackable = () => {
    const [type, input] = Object.entries(this.timeTrackableInputs).find(([_type, input]) => input?.value) || ['', '']

    this.selectors.timeTrackableIdInput.value = input.value || input
    this.selectors.timeTrackableTypeInput.value = type
  }
}

export default ExternalDeviceForm
