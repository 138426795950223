import React from 'react'

import { t } from '../../../utils/i18n'
import ActiveTable from '../../ActiveTable'
import Icon from '../../Icon'
import IconCell from '../../IconCell'
import { edit, archive, activate, discard } from '../../elements/actionDefinitions'

class AccountsTable extends React.Component {
  store = this.props.store
  pathPrefix = 'admin/accounts'

  columns = [
    {
      header: t('activerecord.attributes.account.name'),
      accessorKey: 'name',
      cell: ({
        getValue,
        row: {
          original: { is_test, id }
        }
      }) => (
        <IconCell
          id={id}
          showIcon={is_test}
          tooltipText={t('v2.accounts.index.is_test')}
          iconComponent={tooltipId => (
            <Icon icon='letterT' className='ml-2 center-icon primary' data-tip data-for={tooltipId} />
          )}
        >
          {getValue()}
        </IconCell>
      )
    },
    {
      header: t('activerecord.attributes.account.tenant'),
      accessorKey: 'tenant'
    },
    {
      header: t('activerecord.attributes.account.registration_number'),
      accessorKey: 'registration_number'
    },
    {
      header: t('activerecord.attributes.account.ktn_id'),
      accessorKey: 'ktn_id'
    },
    {
      header: t('activerecord.attributes.account.employee_count'),
      accessorKey: 'employee_count'
    },
    {
      header: t('activerecord.attributes.account.active_object_count'),
      accessorKey: 'active_object_count'
    },
    {
      header: t('activerecord.attributes.account.archived_object_count'),
      accessorKey: 'archived_object_count'
    },
    {
      header: t('activerecord.attributes.account.industry'),
      accessorKey: 'translated_industry'
    },
    {
      header: t('activerecord.attributes.account.locale'),
      accessorKey: 'translated_locale'
    },
    {
      header: t('activerecord.attributes.account.created_at'),
      accessorKey: 'created_at'
    }
  ]

  actions = {
    row: [
      edit(this.pathPrefix, 'admin.accounts.edit.title'),
      archive(this.pathPrefix),
      activate(this.pathPrefix),
      discard(this.pathPrefix)
    ]
  }

  trProps = row => ({
    onClick: () => Turbolinks.visit(`/${row.original.tenant}`)
  })

  render() {
    return (
      <ActiveTable
        {...this.props}
        columns={this.columns}
        actions={this.actions}
        pathPrefix={this.pathPrefix}
        getTrProps={this.trProps}
        inactiveBy='archived'
        translationPath='accounts'
      />
    )
  }
}

export default AccountsTable
