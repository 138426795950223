import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Icon from '../Icon'
import IconCell from '../IconCell'
import Store from '../Store'
import TableContainer from '../TableContainer'
import CompaniesFilter from './CompaniesFilter'
import CompaniesTable from './CompaniesTable'

class CompaniesTableContainer extends TableContainer {
  pathPrefix = 'v2/companies'

  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'checked_in_employees_count', desc: true }],
    baseScope: this.props.base_scope
  })

  columns = [
    {
      header: t('activerecord.attributes.company.employees'),
      accessorKey: 'checked_in_employees_count',
      cell: ({ row: { original } }) => (
        <a href={url(this.pathPrefix, original.id, 'employees')}>
          <span className='text-success'>{original.checked_in_employees_count}</span>
          {` / ${original.employees_count}`}
        </a>
      )
    },
    {
      header: t('activerecord.attributes.company.name'),
      accessorKey: 'name',
      cell: ({ getValue, row: { original } }) => <a href={url(this.pathPrefix, original.id)}>{getValue()}</a>
    },
    {
      header: t('activerecord.attributes.company.registration_number'),
      accessorKey: 'registration_number',
      cell: ({
        getValue,
        row: {
          original: { id, is_foreign }
        }
      }) => (
        <IconCell
          id={id}
          showIcon={is_foreign}
          tooltipText={t('activerecord.attributes.company.is_foreign')}
          iconComponent={tooltipId => <Icon icon='globe' className='ml-2 center-icon' data-tip data-for={tooltipId} />}
        >
          {getValue()}
        </IconCell>
      )
    },
    {
      header: t('activerecord.attributes.company.address'),
      accessorKey: 'address'
    },
    {
      header: t('activerecord.attributes.company.construction_objects'),
      accessorKey: 'construction_objects_count',
      cell: ({ getValue, row: { original } }) => (
        <a href={url(this.pathPrefix, original.id, 'construction_objects')}>{getValue()}</a>
      )
    },
    {
      header: t('activerecord.attributes.construction_object.construction_object_events_created_recently_count'),
      accessorKey: 'created_recently_events_count'
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div>
        <CompaniesFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <CompaniesTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
          pathPrefix={this.pathPrefix}
        />
      </div>
    )
  }
}

export default CompaniesTableContainer
