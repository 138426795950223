import React from 'react'

import classNames from 'classnames'

import { t } from '../../../../utils/i18n'

const Button = ({ currentIntervalKind, onIntervalKindChange, type, disabled }) => (
  <div
    className={classNames('regularity-type-button', { active: currentIntervalKind === type, disabled: disabled })}
    onClick={() => !disabled && onIntervalKindChange(type)}
  >
    {t(type)}
  </div>
)

const IntervalKindButtons = props => (
  <div className='task-regularity-configurations-buttons-container form-group'>
    <label className='col-sm-4 col-form-label' />
    <div className='fields-right-container'>
      <Button {...props} type='day' />
      <Button {...props} type='week' />
      <Button {...props} type='month' />
    </div>
  </div>
)

export default IntervalKindButtons
