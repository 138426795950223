import React from 'react'

import Store from '../../Store'
import ExternalDevicesFilter from './ExternalDevicesFilter'
import ExternalDevicesTable from './ExternalDevicesTable'

class ExternalDevicesTableContainer extends React.Component {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'id', desc: true }],
    baseScope: this.props.base_scope
  })

  render() {
    return (
      <div>
        <ExternalDevicesFilter {...this.props} store={this.store} />
        <ExternalDevicesTable {...this.props} store={this.store} />
      </div>
    )
  }
}

export default ExternalDevicesTableContainer
