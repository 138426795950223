import React from 'react'

import { t } from '../../../utils/i18n'
import Store from '../../Store'
import TableContainer from '../../TableContainer'
import DateTimeCell from './DateTimeCell'
import TasksFilter from './TasksFilter'
import TasksTable from './TasksTable'
import { TaskInfoIcons, ResponsibleCell, StateCell } from './TasksTableComponents'

class TasksTableContainer extends TableContainer {
  state = {
    columnOptions: [],
    secondLevelRows: {}
  }

  store = new Store({
    url: this.props.url,
    additionalParams: { include_children_for: this.taskGroupsWithChildren },
    defaultFilter: this.props.default_filter,
    defaultSorted: [{ id: 'created_at', desc: true }],
    baseScope: this.props.base_scope,
    onDataLoad: () => {
      const rows = this.taskGroupsWithChildren.map(groupData => {
        const [uid, time] = groupData.split('@')
        let accessor = `.rt-tr[data-object-id='${uid}']`
        if (time) accessor += `[data-object-starting-time='${time.replaceAll('-', '.')}']`
        return document.querySelector(accessor)
      })
      const row_accesr = rows.map(item => item.dataset.row.slice(4, item.dataset.row.length))
      const expanded = {}
      row_accesr.forEach(id => {
        expanded[id] = true
      })
      this.store.updateState({ expanded })
      this.tryScrollingToAnchor()
    }
  })

  get taskGroupsWithChildren() {
    const groupUids = sessionStorage.tableTasksWithChildren
    return groupUids ? groupUids.split(',') : []
  }

  tryScrollingToAnchor() {
    const anchor = sessionStorage.tableTasksAnchor

    if (anchor) {
      sessionStorage.removeItem('tableTasksAnchor')
      const row = document.querySelector(`.rt-tr[data-row='${anchor}']`)
      row.scrollIntoView({
        block: 'center',
        inline: 'center'
      })
    }
  }

  onStateStatisticsClick = state => {
    this.store.setState({ showFilter: false })
    this.store.setFilter('state', [
      {
        label: t(`activerecord.attributes.task.state/${state}`),
        value: state
      }
    ])

    this.store.setState({ sorted: [{ id: 'created_at', desc: true }] })
  }

  getColumns = () => {
    const columns = [
      {
        accessorKey: 'row-expander',
        id: 'row-expander',
        header: '',
        size: 40,
        maxSize: 40,
        sortable: false,
        enableResizing: false,
        minSize: 40,
        cell: ({ row, row: { original } }) => {
          return (
            <div style={{ cursor: 'pointer' }}>{this.renderExpander(row, original.is_group, row.getIsExpanded())}</div>
          )
        },
        hideable: false
      },
      {
        header: t('activerecord.attributes.task.title'),
        accessorKey: 'title',
        size: 280,
        cell: ({
          row,
          row: {
            original: { title, is_group, task_count }
          }
        }) => {
          return row.depth == 0 ? (
            <div data-contains='task-group-title' className='text-truncate'>
              {is_group && <span className='children-count'>({task_count})</span>}
              {title}
            </div>
          ) : null
        },
        getClassName: this.getExpandableCellClassName,
        hideable: false
      },
      {
        header: t('activerecord.attributes.tasker/task.table.starting_time'),
        accessorKey: 'starting_time',
        size: 80,
        cell: ({ row, getValue }) => (row.depth === 0 ? <DateTimeCell dateTime={getValue()} /> : null)
      },
      {
        header: t('activerecord.attributes.tasker/task.table.deadline'),
        accessorKey: 'deadline',
        size: 80,
        cell: ({
          getValue,
          row,
          row: {
            original: { is_delayed }
          }
        }) => <DateTimeCell dateTime={getValue()} isDelayed={is_delayed} hideDateTime={row.depth !== 0} />
      },
      {
        header: t('activerecord.attributes.tasker/task.responsible'),
        accessorKey: 'responsible_name',
        size: 160,
        sortable: false,
        cell: ({ rowIndex, row: { original } }) => <ResponsibleCell original={original} rowIndex={rowIndex} />
      },
      {
        header: t('activerecord.attributes.tasker/task.state'),
        accessorKey: 'state',
        size: 100,
        sortable: false,
        cell: ({ row: { original } }) => (
          <StateCell original={original} onStateStatisticsClick={this.onStateStatisticsClick} />
        )
      },
      {
        header: t('activerecord.attributes.tasker/task.task_info_icons'),
        accessorKey: 'icons',
        sortable: false,
        cell: ({ row: { original } }) => <TaskInfoIcons original={original} />
      },
      {
        header: t('activerecord.attributes.tasker/task.task_group'),
        accessorKey: 'task_group_name'
      },
      {
        header: t('activerecord.attributes.tasker/task.created_at'),
        accessorKey: 'created_at',
        size: 80,
        cell: ({ getValue }) => <DateTimeCell dateTime={getValue()} />
      }
    ]

    const { device, client } = this.props.additional_columns

    if (device) {
      columns.splice(columns.length - 1, 0, {
        header: t('activerecord.attributes.tasker/task.device_label'),
        accessorKey: 'device_label'
      })
    }

    if (client) {
      columns.splice(columns.length - 1, 0, {
        header: t('activerecord.attributes.tasker/task.client_label'),
        accessorKey: 'client_label'
      })
    }

    return columns
  }

  columns = this.getColumns()

  updateExpandedTasks(row) {
    const { original } = row
    const groupUids = this.taskGroupsWithChildren
    let selectedUid = null

    if (original.regularity_enabled) {
      const formattedTime = original.starting_time.slice(0, 10).replaceAll('.', '-')
      const formattedUid = `${original.id}@${formattedTime}` // make regular task group unique by appending starting_time
      selectedUid = formattedUid
    } else {
      selectedUid = original.id
    }

    if (row.getIsExpanded()) {
      const idx = groupUids.indexOf(selectedUid)
      if (idx > -1) groupUids.splice(idx, 1)
    } else {
      groupUids.push(selectedUid)
    }

    sessionStorage.tableTasksWithChildren = groupUids
  }

  render() {
    const { columnOptions } = this.state

    return (
      <div>
        <TasksFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <TasksTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
          canNavigateToResource={({ is_group }) => !is_group}
          onExpand={row => {
            this.updateExpandedTasks(row)
            this.onExpandedChange({
              rowId: row.id,
              parentId: row.original.parent_id,
              path: 'v2/tasker/tasks/related_tasks',
              subRowName: 'secondLevelRows',
              startingTime: row.original.starting_time,
              parentIdPrefix: row.original.parent_id_prefix,
              open: !row.getIsExpanded()
            })
          }}
          expanded={this.state.expanded}
          onLoadMore={row => {
            this.onLoadMoreChange({
              rowId: row.id,
              parentId: row.original.parent_id,
              path: 'v2/tasker/tasks/related_tasks',
              subRowName: 'secondLevelRows',
              startingTime: row.original.starting_time,
              parentIdPrefix: row.original.parent_id_prefix
            })
          }}
        />
      </div>
    )
  }
}

export default TasksTableContainer
