import React from 'react'

import classNames from 'classnames'
import { useField, useFormikContext } from 'formik'

import Tooltip from '../../Tooltip'

const ColorPicker = ({ colors }) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField('color')
  const { value } = field

  const boxClick = hexColor => {
    setFieldValue('color', hexColor)
  }

  const tooltipContent = () => (
    <div className='color-picker-container'>
      {colors.map(color => (
        <button
          type='button'
          key={color}
          className={classNames('color-picker-option', { 'selected-color': value === color })}
          style={value === color ? { background: color, outline: `2px solid ${color}` } : { background: color }}
          onClick={() => boxClick(color)}
        ></button>
      ))}
    </div>
  )

  return (
    <div className='color'>
      <div
        name='color'
        className='color-picker'
        data-for='color-picker-tooltip'
        data-tip
        style={{ backgroundColor: value }}
        type='button'
      ></div>
      <Tooltip
        id='color-picker-tooltip'
        place='bottom'
        className='white'
        effect='solid'
        globalEventOff='click'
        event='click'
        clickable
      >
        {tooltipContent()}
      </Tooltip>
    </div>
  )
}
export default ColorPicker
