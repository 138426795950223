import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import mapKeys from 'lodash/mapKeys'
import mapValues from 'lodash/mapValues'
import pick from 'lodash/pick'
import { makeObservable, action } from 'mobx'

import { t } from '../utils/i18n'

export const StoreFilter = storeState =>
  class extends storeState {
    constructor(args) {
      super(args)
      makeObservable(this, {
        setFilterKeyword: action,
        setFilterDuration: action,
        setFilter: action,
        toggleFilter: action,
        onDatesChange: action,
        onRangeDateChange: action,
        onMonthDateChange: action,
        onTimeZoneChange: action,
        setFilterLabels: action
      })
    }

    setFilterKeyword = event => {
      this.state.filters.keyword = event.target.value
      this.debouncedReload()
    }

    debouncedReload = debounce(this.reload, 500)

    getFilterKeyword() {
      return this.state.filters.keyword || ''
    }

    setFilterDuration = event => {
      this.state.filters.duration = event.target.value
      this.debouncedReload()
    }

    getFilterDuration() {
      return this.state.filters.duration || ''
    }

    setFilter(field, selection) {
      if (selection) {
        this.state.filters[field] = Array.isArray(selection) ? selection.map(s => s.value) : selection.value
      } else {
        this.state.filters[field] = null
      }

      this.reload()
    }

    toggleFilter = () => {
      this.state.showFilter = !this.state.showFilter
    }

    getFilterState() {
      return this.state.showFilter
    }

    onDatesChange = (startDate, endDate) => {
      if (startDate && endDate) {
        const format = t('date.formats.default_raw_sql_human')

        this.state.filters.startDate = startDate.format(format)
        this.state.filters.endDate = endDate.format(format)
        this.reload()
      }
    }

    onRangeDateChange = (date, type) => {
      if (type) {
        this.state.filters[type] = date && dayjs(date).format(t('date.formats.default_raw_sql_human'))
        this.reload()
      }
    }

    onMonthDateChange = date => {
      this.state.filters.period = date && dayjs(date).format(t('date.formats.without_date_human'))
      this.reload()
    }

    onTimeZoneChange = selection => {
      this.state.timeZone = selection.value
      this.reload()
    }

    setMultipleFilters(newFilters) {
      this.setState({
        filters: { ...this.state.filters, ...newFilters }
      })
      this.reload()
    }

    setFilterLabels(tableProps) {
      const filterKeys = Object.keys(tableProps).filter(key => key.includes('_options'))
      const processedProps = pick(tableProps, filterKeys)
      const formattedKeys = mapKeys(processedProps, (value, key) => key.replace('_options', ''))
      this.state.filtersHuman = mapValues(formattedKeys, obj => {
        if (!obj || obj.length === 0) return obj
        if (Array.isArray(obj[0])) {
          return obj.map(([label, value]) => ({ label, value }))
        } else {
          return obj
        }
      })
    }
  }
