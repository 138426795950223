import React from 'react'

import { useFormContext } from 'react-hook-form'

import styles from './FieldStyles.module.scss'
import HookFieldWrapper from './HookFieldWrapper'

const TextField = ({
  name,
  required,
  fieldIcon,
  label,
  className = '',
  wrapperClassName = '',
  textArea,
  placeholder,
  inputProps,
  disabled
}) => {
  const formatRequired = text => (required ? `${text}*` : text)

  const { register } = useFormContext()

  const propsForInput = {
    ...register(name),
    id: name,
    placeholder: placeholder && formatRequired(placeholder),
    ...inputProps,
    className: `${styles.formControl} form-control ${className}`,
    disabled
  }
  return (
    <HookFieldWrapper name={name} required={required} className={wrapperClassName} icon={fieldIcon} label={label}>
      <div className={styles.formInputWrapper}>
        {textArea ? (
          <textarea rows={propsForInput.rows || 3} {...propsForInput} />
        ) : (
          <input type='text' {...propsForInput} />
        )}
      </div>
    </HookFieldWrapper>
  )
}

export default TextField
