export default {
  drawingModes: ['polygon'],

  style: {
    fillColor: '#FD7035',
    fillOpacity: 0.25,
    strokeColor: '#FD7035',
    strokeWeight: 3
  },

  onPolygonChanged() {},

  setPolygonEvents(polygonPath) {
    this.onPolygonChanged(polygonPath)

    google.maps.event.addListener(polygonPath, 'insert_at', () => this.onPolygonChanged(polygonPath))
    google.maps.event.addListener(polygonPath, 'set_at', () => this.onPolygonChanged(polygonPath))
  },

  onCircleChanged() {},

  onBeforeCircleChanged(circle) {
    let radius = circle.getRadius()

    if (radius > 500) circle.setRadius(500)
    if (radius < 5) circle.setRadius(5)

    this.onCircleChanged(circle)
  },

  setCircleEvents(circle) {
    this.onBeforeCircleChanged(circle)

    google.maps.event.addListener(circle, 'radius_changed', () => this.onBeforeCircleChanged(circle))
    google.maps.event.addListener(circle, 'center_changed', () => this.onBeforeCircleChanged(circle))
  },

  onDestroy() {}
}
