import { useState, useEffect, useMemo } from 'react'

import axios from 'axios'
import { getApiErrorMessage, showErrorModal } from 'utils/confirmations'
import { url } from 'utils/urlGenerator'

const REQUEST_URL = 'v2/schedules'

const useSchedule = scheduleId => {
  const [schedule, setSchedule] = useState({})
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (!scheduleId) {
      return
    }

    setLoading(true)

    axios
      .get(url(REQUEST_URL, scheduleId))
      .then(({ data }) => {
        setSchedule(data)
        setLoading(false)
      })
      .catch(() => {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      })
  }, [scheduleId])

  return useMemo(() => {
    return {
      schedule,
      isLoading
    }
  }, [schedule, isLoading])
}

export default useSchedule
