import { useState, useEffect, useMemo } from 'react'

import axios from 'axios'
import { getApiErrorMessage, showErrorModal } from 'utils/confirmations'
import { url } from 'utils/urlGenerator'

export default ({ month, schedule, setLoading, setHasEmployees, searchTerm, employeeAbsences }) => {
  const [employees, setEmployees] = useState([])

  useEffect(() => {
    axios
      .get(url('v2/schedules', schedule.id, 'shifts', { month, search: searchTerm }))
      .then(({ data: { rows } }) => {
        setEmployees(rows)
        setHasEmployees(!!rows.length)
      })
      .catch(() => {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [schedule, employeeAbsences, searchTerm, month, setHasEmployees, setLoading])

  return useMemo(() => ({ employees }), [employees])
}
