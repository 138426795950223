import React, { useState, useCallback, useEffect } from 'react'

import Axios from 'axios'
import classNames from 'classnames'
import ReactDOM from 'react-dom'

import useUnsavedChangesConfirmation from '../hooks/useUnsavedChangesConfirmation'
import { t } from '../utils/i18n'
import Modal from './Modal'
import { NewButton } from './ModalForm'

const ModalFormikForm = props =>
  props.setIsOpen ? <ControlledModalFormikForm {...props} /> : <UncontrolledModalFormikForm {...props} />

const UncontrolledModalFormikForm = props => {
  const [isOpen, setIsOpen] = useState(props.isOpen)

  return <ControlledModalFormikForm {...props} isOpen={isOpen} setIsOpen={setIsOpen} />
}

const ModalFormHeader = ({ title, children }) => (
  <div className='modal-header-content'>
    <>
      <div className='modal-header-title'>{title}</div>
      {children}
    </>
  </div>
)

const ControlledModalFormikForm = ({
  url,
  reloadPageOnSuccess,
  onSuccessfulSubmit,
  action,
  button,
  buttonText,
  title,
  skipToggleButton,
  type = 'button',
  formikForm,
  onClose,
  formData = {},
  classes = 'modal-lg',
  isOpen,
  setIsOpen,
  toggleModal: toggleModalProp
}) => {
  const [data, setData] = useState(formData)
  const [isLoading, setIsLoading] = useState(!!url)

  const fetchFormData = useCallback(async () => {
    if (!url) {
      return
    }

    setIsLoading(true)

    const { data } = await Axios.get(url, { headers: { Accept: 'application/json' } })

    setData(data)
    setIsLoading(false)
  }, [url])

  const afterSuccessfulSubmit = response => {
    if (onSuccessfulSubmit) {
      onSuccessfulSubmit(response)
    }

    if (reloadPageOnSuccess) {
      window.location.reload()
    }
  }

  const { useForm, Form } = formikForm

  const { formik } = useForm({ data, afterSuccessfulSubmit })
  const { dirty, isSubmitting, handleSubmit, resetForm } = formik

  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  if (toggleModalProp) {
    toggleModalProp(toggleModal)
  }

  useEffect(() => {
    if (isOpen) {
      fetchFormData()
      resetForm()
    }
  }, [isOpen, fetchFormData, resetForm])

  let modalToggleButton

  if (skipToggleButton) {
    modalToggleButton = null
  } else if (action === 'new' && type !== 'link') {
    modalToggleButton = <NewButton onClick={toggleModal}>{buttonText}</NewButton>
  } else if (button) {
    modalToggleButton = button(toggleModal)
  } else if (!skipToggleButton) {
    throw new Error('Not implemented!')
  }

  const handleClose = useUnsavedChangesConfirmation({ dirty, onClose: onClose || toggleModal })

  const modalClasses = classNames(classes, 'modal-dialog-centered modal--new')

  return (
    <>
      {!isLoading &&
        ReactDOM.createPortal(
          <Modal
            isOpen={isOpen}
            className={modalClasses}
            onClose={handleClose}
            header={<ModalFormHeader title={title} />}
          >
            <div className='modal-form-container'>
              <Form formik={formik} data={data} />
            </div>

            <div className='btn-container'>
              <button type='submit' className='btn btn-primary' onClick={handleSubmit} disabled={isSubmitting}>
                {t('save')}
              </button>

              <button className='btn btn-secondary' onClick={onClose || toggleModal}>
                {t('cancel')}
              </button>
            </div>
          </Modal>,
          document.querySelector('.modal-container')
        )}
      {modalToggleButton}
    </>
  )
}

export default ModalFormikForm
