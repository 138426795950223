import React, { useContext, useState } from 'react'

import classNames from 'classnames'
import dayjs from 'dayjs'

import useTranslate from '../../../hooks/useTranslate'
import { formatCurrency, formatDate } from '../../../utils/formatters'
import Table from '../../Table'
import styles from './Buttons.module.scss'
import { VedludbContext } from './ConstructionObjectVedludb'
import PaymentForm from './PaymentForm'
import PaymentTableFilters from './PaymentForm/PaymentTableFilters'
import PaymentHistory from './PaymentHistory'
import { usePaymentsQuery } from './queries'

const NewPayment = ({ openForm }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.PaymentForm.buttons')

  return (
    <button className={classNames('btn btn-primary', styles.vedludbNewButton)} onClick={() => openForm(null)}>
      {t('.new_payment')}
    </button>
  )
}

const defaultPaymentFilters = {
  ligumsId: null,
  dateFrom: null,
  dateTo: null,
  maksajumaStatuss: null,
  maksatajs: null,
  sanemejs: null,
  searchTerm: null
}

const applyFilters = (originalPayments, filters) => {
  if (!filters) return originalPayments

  const { ligumsId, maksajumaStatuss, maksatajs, sanemejs, dateFrom, dateTo, searchTerm } = filters

  let result = [...originalPayments]

  const paymentCorrespondsToSearch = payment => {
    const searchable = Object.values(payment).join(' ').toLowerCase()
    return searchable.includes(searchTerm.toLowerCase())
  }

  // operation: 1 = gte(>=); -1 = lte(<=)
  const paymentInPeriod = (payment, date, operation) => {
    const d1 = dayjs(date).toDate()
    const d2 = dayjs(payment.maksajumaDatums).toDate()

    if (operation > 0) {
      return d2 >= d1
    } else {
      return d2 <= d1
    }
  }

  return result.filter(payment => {
    let isVisible = true

    if (ligumsId) isVisible = isVisible && payment.ligumsId === ligumsId.value
    if (maksajumaStatuss) isVisible = isVisible && payment.statuss === maksajumaStatuss.value
    if (maksatajs) isVisible = isVisible && payment.maksatajaRegistracijasNr === maksatajs.value
    if (sanemejs) isVisible = isVisible && payment.sanemejaRegistracijasNr === sanemejs.value
    if (dateFrom) isVisible = isVisible && paymentInPeriod(payment, dateFrom, 1)
    if (dateTo) isVisible = isVisible && paymentInPeriod(payment, dateTo, -1)
    if (searchTerm) isVisible = isVisible && paymentCorrespondsToSearch(payment)

    return isVisible
  })
}
const Payments = ({ title }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.PaymentsTable')
  const {
    ligumsId,
    permissions: { registration_number }
  } = useContext(VedludbContext)

  const { isPending, isLoading, data: payments } = usePaymentsQuery(ligumsId)

  const formatPaymentStatus = payment => {
    if (payment.statuss === 0) {
      if (payment?.sanemejaRegistracijasNr === registration_number) {
        return t('.statuses.waiting_to_be_approved')
      } else {
        return t('.statuses.waiting')
      }
    } else if (payment.statuss === 1) {
      return t('.statuses.approved')
    } else {
      return t('.statuses.denied')
    }
  }

  const defaultColumns = [
    {
      Header: t('.paymentDate'),
      visible: true,
      accessor: 'maksajumaDatums',
      Cell: ({ resource }) => formatDate(resource.maksajumaDatums)
    },
    {
      Header: t('.payer'),
      visible: true,
      accessor: 'maksatajs',
      Cell: ({ resource }) => `${resource.maksatajaNosaukums} (${resource.maksatajaRegistracijasNr})`
    },
    {
      Header: t('.receiver'),
      visible: true,
      accessor: 'sanemejs',
      Cell: ({ resource }) => `${resource.sanemejaNosaukums} (${resource.sanemejaRegistracijasNr})`
    },
    {
      Header: t('.paymentSum'),
      visible: true,
      accessor: 'maksajumaSumma',
      className: 'text-right',
      Cell: ({ resource }) => (
        <div className='text-right'>
          <i className='fa fa-eur mx-1' aria-hidden='true'></i>
          {formatCurrency(resource.maksajumaSumma)}
        </div>
      )
    },
    { Header: t('.notes'), visible: true, accessor: 'pamatojums' },
    {
      Header: t('.paymentStatus'),
      visible: true,
      accessor: 'statuss',
      Cell: ({ resource }) => formatPaymentStatus(resource)
    }
  ]

  const [selectedPayment, setSelectedPayment] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [paymentForHistory, setPaymentForHistory] = useState(null)
  const [isHistoryOpen, setIsHistoryOpen] = useState(false)
  const [appliedFilters, setAppliedFilters] = useState({ ...defaultPaymentFilters })
  const [columns, setColumns] = useState(defaultColumns)

  const openPaymentForm = payment => {
    setSelectedPayment(payment)
    setIsModalOpen(true)
  }

  const closePaymentForm = () => {
    setIsModalOpen(false)
    setSelectedPayment(undefined) // using `undefined` for form reset, since new payment passes null
  }

  const openHistory = payment => {
    setPaymentForHistory(payment)
    setIsHistoryOpen(true)
  }

  const closeHistory = () => setIsHistoryOpen(false)

  const filterProps = {
    setFilters: setAppliedFilters,
    setColumns,
    filters: appliedFilters,
    defaultValues: { ...defaultPaymentFilters },
    payments,
    columns
  }

  const tableActions = [
    {
      onClick: openPaymentForm,
      text: t('.showPayment'),
      icon: 'eye'
    },
    {
      onClick: openHistory,
      text: t('.showHistory'),
      icon: 'history'
    }
  ]

  const loading = isLoading || isPending

  return (
    <>
      <PaymentHistory isOpen={isHistoryOpen} onClose={closeHistory} payment={paymentForHistory} />
      {isModalOpen && <PaymentForm onClose={closePaymentForm} payment={selectedPayment} />}
      <div className='d-flex justify-content-between my-2'>
        <h3>{title}</h3>
        <NewPayment openForm={openPaymentForm} />
      </div>
      <PaymentTableFilters {...filterProps} />
      <Table
        isLoading={loading}
        columns={columns.filter(c => c.visible)}
        data={payments ? applyFilters(payments, appliedFilters) : null}
        actions={tableActions}
        className='payments-table'
        withHover
        withBorder
      />
    </>
  )
}

export default Payments
