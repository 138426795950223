import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../utils/i18n'
import { FilterContainer, SearchField, SimpleSelect, DateRangeFilter } from '../FilterComponents'

class CheckLogsFilter extends React.Component {
  hiddenFilters = () => {
    const {
      store,
      hideObjectFilter,
      construction_objects_options,
      construction_object_tasks_options,
      construction_object_sub_tasks_options,
      business_industry
    } = this.props

    return (
      <>
        {!hideObjectFilter && (
          <SimpleSelect
            store={store}
            selectable='construction_object_id'
            options={construction_objects_options}
            isMulti
          />
        )}
        {business_industry && (
          <>
            <SimpleSelect
              store={store}
              selectable='construction_object_task_id'
              options={construction_object_tasks_options}
              dataContains='select-construction-object-task-filter'
              isMulti
            />
            <SimpleSelect
              store={store}
              selectable='construction_object_sub_task_id'
              options={construction_object_sub_tasks_options}
              dataContains='select-construction-object-sub-task-filter'
              isMulti
            />
          </>
        )}
      </>
    )
  }

  render() {
    const { store, checkableTypeFilter, ...others } = this.props

    return (
      <FilterContainer store={store} hiddenFilters={this.hiddenFilters} {...others}>
        {checkableTypeFilter && checkableTypeFilter()}
        <SearchField store={store} placeholder={t('filters.search_nfc_check_logs')} />
        <DateRangeFilter store={store} />
      </FilterContainer>
    )
  }
}

export default observer(CheckLogsFilter)
