import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import CompaniesFilter from '../Companies/CompaniesFilter'
import { SimpleSelect } from '../FilterComponents'
import Permissions from '../Permissions'
import Store from '../Store'
import TableContainer from '../TableContainer'
import UserCompaniesTable from './UserCompaniesTable'

class UserCompaniesTableContainer extends TableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'company_name', desc: false }]
  })

  customFilters = () => {
    return <SimpleSelect store={this.store} selectable='role' options={this.props.roles_options} />
  }

  columns = [
    {
      accessorKey: 'company_id',
      visible: false
    },
    {
      header: t('activerecord.attributes.company.name'),
      accessorKey: 'company_name',
      cell: ({ getValue, row: { original } }) => <a href={url('v2/companies', original.company_id)}>{getValue()}</a>
    },
    {
      header: t('activerecord.attributes.company.registration_number'),
      accessorKey: 'company_registration_number'
    },
    {
      header: t('activerecord.attributes.company_user.role'),
      accessorKey: 'role',
      sortable: false
    },
    {
      header: t('activerecord.attributes.company_user.permissions'),
      accessorKey: 'permissions',
      sortable: false,
      cell: ({ row: { original } }) => (
        <Permissions object={original} type='company_user' permissions={this.props.permissions} />
      )
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <CompaniesFilter
          {...this.props}
          store={this.store}
          customFilters={this.customFilters}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <UserCompaniesTable {...this.props} store={this.store} columns={this.columns} columnOptions={columnOptions} />
      </div>
    )
  }
}

export default UserCompaniesTableContainer
