import AccountForm from './AccountForm'
import Collapse from './Collapse'
import CompanyForm from './CompanyForm'
import CompanyUserForm from './CompanyUserForm'
import ConstructionObjectCompanyForm from './ConstructionObjectCompanyForm'
import ConstructionObjectForm from './ConstructionObjectForm'
import ConstructionObjectUserForm from './ConstructionObjectUserForm'
import EmployeeForm from './EmployeeForm'
import ExternalDeviceForm from './ExternalDeviceForm'
import ImageUploader from './ImageUploader'
import NoteForm from './NoteForm'
import PasswordForm from './PasswordForm'
import PasswordShowToggle from './PasswordShowToggle'
import TaskActivitiesChangelogToggle from './TaskActivitiesChangelogToggle'
import TaskActivitiesLoader from './TaskActivitiesLoader'
import TaskForm from './TaskForm'
import TimeEntryInputsContainer from './TimeEntryInputsContainer'
import UserForm from './UserForm'
import UserGroupForm from './UserGroupForm'

export default container => {
  ;[
    CompanyUserForm,
    CompanyForm,
    ConstructionObjectCompanyForm,
    ConstructionObjectUserForm,
    UserForm,
    ImageUploader,
    NoteForm,
    TimeEntryInputsContainer,
    EmployeeForm,
    ConstructionObjectForm,
    Collapse,
    TaskForm,
    PasswordForm,
    PasswordShowToggle,
    UserGroupForm,
    ExternalDeviceForm,
    AccountForm,
    TaskActivitiesChangelogToggle,
    TaskActivitiesLoader
  ].forEach(Handler => {
    container.querySelectorAll(Handler.selector).forEach(element => new Handler(element))
  })
}
