import React from 'react'

import { useField } from 'formik'

import FormikFieldWrapper from './FormikFieldWrapper'

const HexColorPicker = props => {
  const { label, required, className, inputProps = {}, disabled } = props
  const [field] = useField(props)
  const { name } = field

  const inputFieldProps = {
    ...field,
    id: name,
    className: 'form-control',
    ...inputProps,
    disabled
  }

  return (
    <FormikFieldWrapper name={name} label={label} required={required} className={className}>
      <div className='form-input-wrapper'>
        <input type='color' {...inputFieldProps} />
      </div>
    </FormikFieldWrapper>
  )
}

export default HexColorPicker
