import React from 'react'

import classNames from 'classnames'
import { t } from 'i18n-js'
import { useFormContext, useWatch } from 'react-hook-form'

import { DateField, SelectField } from '../../../../../Forms/HookFields'
import SectionTitle from '../../SectionTitle'
import styles from '../../styles/ModuleWrapper.module.scss'

const RegularityDeadline = ({ className = '' }) => {
  const { submitting, selectOptions, disableRegularity } = useFormContext()
  const options = selectOptions.regularity['duration_days_options']
  const isDisabled = submitting || disableRegularity
  const selectedDeadline = useWatch({ name: 'regularity.duration_days' })

  const selectProps = {
    className: `${className} select-container tasks-form-select-field dynamic-select`,
    classNamePrefix: 'rs',
    noOptionsMessage: () => t('components.select.no_options'),
    loadingMessage: () => t('components.select.loading_message'),
    disabled: isDisabled,
    name: 'regularity.duration_days',
    options
  }

  return (
    <div>
      <SectionTitle>{t('v2.tasker.tasks.regularity_common_fields.duration_days')}</SectionTitle>
      <div className={classNames('d-flex align-items-center deadline-controls', styles.withGaps)}>
        <SelectField {...selectProps} />
        {selectedDeadline?.value !== '' && (
          <DateField type={'time'} name='regularity.duration_time' disabled={isDisabled} />
        )}
      </div>
    </div>
  )
}

export default RegularityDeadline
