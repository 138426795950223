import React from 'react'

import ShiftBlock from './ShiftBlock'
import { useExceptionDaysContext } from './contexts/ExceptionDaysContext'

const ShiftDay = ({ metadata, ...others }) => {
  const { today, saturday, dateIso } = metadata
  const { isHoliday } = useExceptionDaysContext()

  return (
    <div className='shift-schedule__day'>
      <ShiftBlock metadata={metadata} {...others} />
      {isHoliday(dateIso) && <div className='shift-schedule__weekend-overlay' style={{ width: '100%' }} />}
      {today && <div className='shift-schedule__today-overlay' />}
      {saturday && <div className='shift-schedule__weekend-overlay' />}
    </div>
  )
}

const ShiftDayList = ({ datesMetadata, ...others }) => (
  <div className='shift-schedule__days'>
    {datesMetadata.map((metadata, i) => (
      <ShiftDay key={i} metadata={metadata} {...others} />
    ))}
  </div>
)

export default ShiftDayList
