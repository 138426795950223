import viewHandlers from '../viewHandlers'
import './axios'
import bindConfirmations from './bindConfirmations'
import browserUpdateCheck from './browserUpdate'
import './dayjs'
import './i18n.js.erb'
import mountComponents from './mountComponents'
import './railsUJS'
import { setSentryContext } from './sentry'
import simpleMDE from './simpleMDE'

const initialize = (container = document) => {
  setSentryContext()
  mountComponents(container)
  bindConfirmations(container)
  viewHandlers(container)
  simpleMDE(container)
}

document.addEventListener('turbolinks:load', () => {
  initialize()
  browserUpdateCheck()
})

export default initialize
