import React from 'react'

import Select from 'react-select'

import { t } from '../../../../utils/i18n'
import { REACT_SELECT_PROPS } from '../../../Select'

class RepetitionIntervalSelect extends React.Component {
  state = { selectedValue: this.props.selectedValue || this.props.options[0], initialValueSet: false }

  componentDidUpdate(prevProps) {
    const { options, selectedValue, currentIntervalKind, intervalKind } = this.props

    if (prevProps.options !== options) {
      if (selectedValue && !this.state.initialValueSet && currentIntervalKind === intervalKind) {
        this.setState({ selectedValue: selectedValue, initialValueSet: true })
      } else {
        this.setState({ selectedValue: options[0] })
      }
    }
  }

  onRepetitionChange = selectedValue => {
    this.setState({ selectedValue })
  }

  render() {
    const selectName = 'tasker_task[regularity][interval]'
    const selectId = 'tasker_task_regularity_interval'

    return (
      <div className='form-group'>
        <label className='col-sm-4 col-form-label' htmlFor={selectId}>
          {t('v2.tasker.tasks.regularity_common_fields.regularity_repetition')}
        </label>
        <div className='task-regularity-repetition-select-container fields-right-container'>
          <Select
            {...REACT_SELECT_PROPS}
            options={this.props.options}
            isClearable={false}
            onChange={this.onRepetitionChange}
            value={this.state.selectedValue}
            name={selectName}
            inputId={selectId}
            isDisabled={this.props.disabled}
          />
        </div>
      </div>
    )
  }
}

export default RepetitionIntervalSelect
