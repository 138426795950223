import React, { useState } from 'react'

import classNames from 'classnames'
import ReactDOM from 'react-dom'

import Modal from '../../Modal'
import { NewButton } from '../../ModalForm'
import styles from './Form/styles/TasksFormModal.module.scss'
import TasksForm from './TasksForm'

const TasksFormModal = ({ skipToggle = false, ...props }) => {
  const { isOpen, setIsOpen, buttonText } = props
  const [selfIsOpen, setSelfIsOpen] = useState(false)

  const openForm = () => {
    setIsOpen ? setIsOpen(true) : setSelfIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen ? setIsOpen(false) : setSelfIsOpen(false)
  }

  return (
    <React.Fragment>
      {!skipToggle && (
        <NewButton onClick={openForm}>
          <div>{buttonText}</div>
        </NewButton>
      )}

      {ReactDOM.createPortal(
        <Modal
          isOpen={isOpen || selfIsOpen}
          onClose={handleClose}
          withDefaultModalContent={false}
          className={classNames(styles.tasksFormModal, 'modal-dialog-centered')}
          closeOnBackdropClick={false}
        >
          <TasksForm onClose={handleClose} {...props} />
        </Modal>,
        document.querySelector('.modal-container')
      )}
    </React.Fragment>
  )
}

export default TasksFormModal
