import React from 'react'

import ActiveTable from '../ActiveTable'
import {
  checkIn,
  checkOut,
  remove,
  removeSelected,
  checkOutSelected,
  checkInSelected
} from '../elements/actionDefinitions'

class ConstructionObjectGuestsTable extends React.Component {
  pathPrefix = 'v2/construction_object_guests'

  formParams = {
    context: 'construction_object',
    construction_object_guest: { construction_object_id: this.props.construction_object_id }
  }

  actions = {
    row: [checkIn(this.pathPrefix), checkOut('latest_checked_in_check_log_id'), remove(this.pathPrefix)],
    header: [
      checkInSelected(this.pathPrefix, this.props.construction_object_id),
      checkOutSelected(this.pathPrefix),
      removeSelected(this.pathPrefix)
    ]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        pathPrefix={this.pathPrefix}
        pathResourceId='guest_id'
        highlightedBy='currently_checked_in_duration'
        translationPath={['construction_object_guests', 'contexts', 'construction_object']}
        formParams={this.formParams}
        reloadPageOnSuccess={true}
      />
    )
  }
}

export default ConstructionObjectGuestsTable
