import React from 'react'

import cx from 'classnames'
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form'

import { TextField } from '../../../../Forms/HookFields'
import Icon from '../../../../Icon'
import styles from '../styles/InputWrapper.module.scss'
import { generateSubtask } from '../utils/dataInitializer'

const SubtaskRow = ({ item, idx, ...props }) => {
  const { insert, disabled, register, setValue } = props

  const prefix = `sub_tasks_attributes.${idx}`

  const destroy = useWatch({ name: `${prefix}._destroy` })
  const subTasks = useWatch({ name: 'sub_tasks_attributes' })

  return (
    <div className={cx(styles.subtaskInputRow, 'subtask-input-row', { 'd-none': destroy, 'd-flex': !destroy })}>
      <TextField name={`${prefix}.title`} disabled={disabled} />
      <div className={cx(styles.subtaskControls, 'subtask-controls')}>
        <div style={{ width: 12 }}>{item.completed && <Icon className={styles.icon} icon='checkMark' />}</div>
        {!disabled && (
          <>
            <Icon icon='iconPlusCircle' className={styles.icon} onClick={() => insert(idx + 1, generateSubtask())} />
            {subTasks.filter(it => !it._destroy).length > 1 && (
              <Icon className={styles.icon} icon='iconTrash' onClick={() => setValue(`${prefix}._destroy`, '1')} />
            )}
          </>
        )}
        <input type='hidden' {...register(`${prefix}._destroy`)} />
      </div>
    </div>
  )
}

const SubtasksInput = () => {
  const { submitting, register, setValue } = useFormContext()
  const { fields, insert } = useFieldArray({
    name: 'sub_tasks_attributes'
  })

  const sharedProps = {
    disabled: submitting,
    register,
    insert,
    setValue
  }

  return (
    <div className={cx(styles.inputWrapper, 'mb-2')}>
      <div className={cx(styles.subitemsInputs, 'subitems-inputs')}>
        {fields.map((item, index) => (
          <SubtaskRow item={item} key={item.id} {...sharedProps} idx={index} />
        ))}
      </div>
    </div>
  )
}

export default SubtasksInput
