import React, { forwardRef } from 'react'

const Switch = forwardRef((props, ref) => (
  <label className='switch-input-wrapper m-0'>
    <input type='checkbox' {...props} ref={ref} />
    <span className='switch' />
  </label>
))

export default Switch
