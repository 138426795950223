import React, { useContext, useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import dayjs from 'dayjs'
import { FormProvider, useForm } from 'react-hook-form'
import yup from 'utils/yup'

import useTranslate from '../../../hooks/useTranslate'
import useUnsavedChangesConfirmation from '../../../hooks/useUnsavedChangesConfirmation'
import { formatCurrency, formatDate } from '../../../utils/formatters'
import { url } from '../../../utils/urlGenerator'
import { DateField, TextField, NumberField } from '../../Forms/HookFields'
import Modal from '../../Modal'
import styles from './Buttons.module.scss'
import { VedludbContext } from './ConstructionObjectVedludb'
import PaymentButtons from './PaymentForm/PaymentButtons'
import PaymentContractWithSides from './PaymentForm/PaymentContractWithSides'
import { useContractsQuery } from './queries'

const formatPayment = payment => {
  return {
    ligumsId: payment?.ligumsId
      ? { value: payment.ligumsId, label: `${payment.ligumsId} (${payment.sanemejaNosaukums})` }
      : null,
    maksajumaDatums: payment?.maksajumaDatums ? dayjs(payment?.maksajumaDatums).toDate() : null,
    maksajumaSumma: formatCurrency(payment?.maksajumaSumma || null)
  }
}

const formatData = data => ({
  ligumsId: data.ligumsId.value,
  maksajumaSumma: data.maksajumaSumma.replaceAll(' ', ''),
  maksajumaDatums: formatDate(data.maksajumaDatums, 'YYYY-MM-DD'),
  pamatojums: data.pamatojums || null
})

const validationSchema = yup.object({
  ligumsId: yup.object().nullable().required(),
  maksajumaSumma: yup.string().nullable().required(),
  maksajumaDatums: yup.date().nullable().required(),
  pamatojums: yup.string().nullable()
})

const contractsForSelect = (permissions, contracts, registration) => {
  if (!contracts) return []

  return permissions.edit_general
    ? [...contracts.subcontractor_contracts, registration]
    : contracts.subcontractor_contracts
}

const PaymentForm = ({ onClose, tableReload, payment }) => {
  const [editMode, setEditMode] = useState(false)
  const t = useTranslate('ConstructionObjects.Vedludb.PaymentForm')
  const isEditForm = !!payment
  const vedludbCtx = useContext(VedludbContext)
  const { permissions, registration, ligumsId } = vedludbCtx
  const queryClient = useQueryClient()

  const { data: contracts } = useContractsQuery(ligumsId)

  const allContracts = contractsForSelect(permissions, contracts, registration)

  const form = useForm({
    defaultValues: formatPayment(payment),
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema)
  })

  const {
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting }
  } = form

  useEffect(() => {
    reset(formatPayment(payment))
  }, [payment, reset])

  const getExplanationPlaceholder = (isOwner, isReceiver, editMode) => {
    if ((isOwner && editMode) || !isReceiver) return t('.ExplanationPlaceholders.only_edit')
    if (isReceiver && !editMode) return t('.ExplanationPlaceholders.only_approve')
  }

  const modalTitle = (isOwner, isReceiver, paymentStatus) => {
    if (paymentStatus === undefined) return t('.titles.new_payment')
    else if (paymentStatus === 0) {
      if (isOwner && isReceiver) return t('.titles.edit_or_approve_payment')
      if (isOwner) return t('.titles.edit_payment')
      if (isReceiver) return t('.titles.approve_payment')
    } else if (paymentStatus === 1) return t('.titles.payment_approved')
    else if (paymentStatus === 2) return t('.titles.payment_denied')

    return t('.titles.default')
  }

  const closeForm = useUnsavedChangesConfirmation({ dirty: isDirty, onClose })

  const { registration_number, edit_general } = permissions

  const isPaymentOwner =
    registration_number === payment?.maksatajaRegistracijasNr ||
    (registration_number === payment?.sanemejaRegistracijasNr &&
      registration_number === payment?.iesniedzejaRegNr &&
      edit_general)

  const isPaymentReceiver = registration_number === payment?.sanemejaRegistracijasNr

  const isStandalone = isPaymentOwner && isPaymentReceiver

  const displayExplanationField = (isEditForm && isDirty) || (isPaymentReceiver && payment?.statuss === 0 && !editMode)

  const explanationPlaceholder = getExplanationPlaceholder(isPaymentOwner, isPaymentReceiver, editMode)
  const formDisabled = (isEditForm && !isPaymentOwner) || isSubmitting || (isStandalone && !editMode)

  const { mutate } = useMutation({
    mutationFn: data => {
      const finalUrl = url('/v2/vedludb/payments')
      const formattedData = { id: ligumsId, payment: formatData(data) }

      if (isEditForm) {
        formattedData.payment.norekinuMaksajumsId = payment.norekinuMaksajumsId
        return axios.put(finalUrl, formattedData)
      } else {
        return axios.post(finalUrl, formattedData)
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vedludb', 'payments', ligumsId] })
      onClose()
    },
    onError: () => {
      onClose()
    }
  })

  const modalProps = {
    isOpen: true,
    onClose: closeForm,
    closeOnBackdropClick: false,
    className: 'new-modal payment-form',
    title: modalTitle(isPaymentOwner, isPaymentReceiver, payment?.statuss)
  }

  const contextProps = {
    ...vedludbCtx,
    allContracts,
    isEditForm,
    editMode,
    isDirty,
    formDisabled,
    isPaymentOwner,
    isPaymentReceiver,
    isStandalone,
    tableReload,
    onClose,
    payment
  }

  return (
    <Modal {...modalProps}>
      {isStandalone && (
        <button
          className={styles.vedludbInteractLink}
          type='button'
          onClick={() => setEditMode(true)}
          disabled={editMode}
        >
          {t('.buttons.edit_payment')}
        </button>
      )}
      <FormProvider {...form} {...contextProps}>
        <form className='react-form' onSubmit={handleSubmit(mutate)}>
          <PaymentContractWithSides />
          <DateField
            name='maksajumaDatums'
            label={t('.fields.paymentDate')}
            placeholder={t('.fields.paymentDate')}
            disabled={formDisabled}
            required
          />
          <NumberField
            name='maksajumaSumma'
            label={t('.fields.paymentSum')}
            placeholder={t('.fields.paymentSum')}
            disabled={formDisabled}
            required
          />
          {displayExplanationField && (
            <TextField
              name='pamatojums'
              label={t('.fields.explanation')}
              placeholder={explanationPlaceholder}
              required={isDirty}
              disabled={isSubmitting}
              inputProps={{ rows: 2 }}
              textArea
            />
          )}
          <PaymentButtons closeForm={closeForm} />
        </form>
      </FormProvider>
    </Modal>
  )
}

export default PaymentForm
