import React from 'react'

import { t } from '../../../utils/i18n'
import Avatar from '../../Avatar'
import Store from '../../Store'
import TableContainer from '../../TableContainer'
import { BooleanIcon } from '../../elements/TableColumnsElements'
import TaskTimeEntriesFilter from './TaskTimeEntriesFilter'
import TaskTimeEntriesTable, { ClosingType, TimeColumn } from './TaskTimeEntriesTable'

class TaskTimeEntriesTableContainer extends TableContainer {
  constructor(props) {
    super(props)

    this.store = new Store({
      url: this.props.url,
      defaultSorted: [{ id: 'state', desc: true }],
      baseScope: this.props.base_scope
    })
  }

  columns = [
    {
      header: t('activerecord.attributes.tasker/time_entry.user'),
      accessorKey: 'user_full_name',
      cell: ({ getValue, row: { original } }) => <Avatar src={original.user_picture_url} text={getValue()} />
    },
    {
      header: t('activerecord.attributes.user.description'),
      accessorKey: 'user_description'
    },
    !this.props.task_id && {
      header: t('activerecord.attributes.time_entry.task'),
      accessorKey: 'task_title'
    },
    {
      header: t('activerecord.attributes.tasker/time_entry.started_at'),
      accessorKey: 'started_at',
      cell: ({ row }) => <TimeColumn entry={row.original} property='started_at' />
    },
    {
      header: t('activerecord.attributes.tasker/time_entry.finished_at'),
      accessorKey: 'finished_at',
      cell: ({ row }) => <TimeColumn entry={row.original} property='finished_at' />
    },
    {
      header: t('activerecord.attributes.tasker/time_entry.closing_type'),
      accessorKey: 'closing_type',
      cell: ({ row: { original } }) => <ClosingType {...original} />,
      size: 90,
      minSize: 90
    },
    {
      header: t('activerecord.attributes.tasker/time_entry.state'),
      accessorKey: 'state',
      cell: ({ getValue }) => <BooleanIcon checked={getValue()} />,
      size: 90
    },
    {
      header: t('activerecord.attributes.time_entry.duration'),
      accessorKey: 'duration',
      size: 80
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <TaskTimeEntriesFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <TaskTimeEntriesTable {...this.props} store={this.store} columns={this.columns} columnOptions={columnOptions} />
      </div>
    )
  }
}

export default TaskTimeEntriesTableContainer
