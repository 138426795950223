import React, { useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react'

import { formatTime as formatCleaveTime, registerCursorTracker } from 'cleave-zen'
import { useField, useFormikContext } from 'formik'

const formatTime = value => formatCleaveTime(value, { timePattern: ['h', 'm'] })

const TimeInput = forwardRef((props, ref) => {
  const inputRef = useRef(null)
  const { onChange, onBlur, className = '', bonusFormatter, ...others } = props

  const { setFieldValue } = useFormikContext()
  const [field, ..._others] = useField(props)
  const { value, name } = field

  useImperativeHandle(
    ref,
    () => {
      return {
        focus() {
          inputRef.current.focus()
        },
        select() {
          inputRef.current.select()
        }
      }
    },
    []
  )

  useEffect(() => {
    registerCursorTracker({ input: inputRef.current, delimiter: '*' })
  }, [])

  const formatFinal = useCallback(value => (bonusFormatter ? bonusFormatter(value) : value), [bonusFormatter])

  const onFieldBlur = fieldValue => {
    let formattedValue = formatTime(fieldValue)

    if (fieldValue.length) {
      formattedValue = fieldValue.includes(':') ? fieldValue.padEnd(5, '0') : `${fieldValue}0:00`
    }

    formattedValue = formatFinal(formattedValue)

    setFieldValue(name, formattedValue)
    if (onBlur) onBlur({ name, value: formattedValue })
  }

  const onFieldChange = fieldValue => {
    const formattedValue = formatTime(formatFinal(fieldValue))

    setFieldValue(name, formattedValue)
    if (onChange) onChange({ name, value: formattedValue })
  }

  return (
    <div className='form-input-wrapper'>
      <input
        ref={inputRef}
        {...others}
        name={name}
        value={value}
        className={`form-control ${className}`}
        placeholder='hh:mm'
        onBlur={({ target: { value } }) => onFieldBlur(value)}
        onChange={({ target: { value } }) => onFieldChange(value)}
      />
    </div>
  )
})

export default TimeInput
