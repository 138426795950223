import React from 'react'

import useTranslate from '../../../hooks/useTranslate'
import ContractsTable from './ContractsTable'
import { useContractsQuery, usePaymentsQuery } from './queries'

const Contracts = ({ ligumsId, permissions }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.Contracts')

  const { isPending, isLoading, data } = useContractsQuery(ligumsId)
  const { isPending: paymentsPending, isLoading: paymentsLoading, data: paymentsData } = usePaymentsQuery(ligumsId)

  const loading = isPending || isLoading || paymentsPending || paymentsLoading

  return (
    <>
      {!permissions.edit_general && (
        <ContractsTable
          isLoading={loading}
          contracts={data?.my_contracts}
          payments={paymentsData || []}
          title={t('.my_contracts')}
        />
      )}
      <ContractsTable
        isLoading={loading}
        contracts={data?.subcontractor_contracts}
        payments={paymentsData || []}
        title={t('.subcontractor_contracts')}
        style={{ margin: '64px 0px' }}
        interactable
      />
    </>
  )
}

export default Contracts
