import React from 'react'

import classNames from 'classnames'

import { formatTime } from '../../../utils/formatTime'
import { formatDuration } from '../../../utils/schedulerDuration'
import Icon from '../../Icon'
import { ActionDropdown } from '../../elements/actions'

const RegistrationRow = ({
  registration,
  registration: { id, checked_in_at, checked_out_at, checked_in_duration, permissions, outside_shift },
  violations,
  multipleObjects,
  registrationActions,
  hasTolerances
}) => (
  <div
    className={classNames('registration-row registration', {
      invalid: (outside_shift && hasTolerances) || violations.start || violations.end,
      'text-muted': outside_shift,
      'outside-shift': outside_shift
    })}
  >
    <div
      className={classNames('registration-cell', {
        'justify-content-center': multipleObjects
      })}
    >
      {violations.start && <Icon icon='warningFilled' className='warning-icon' />}
      {formatTime(checked_in_at)}
    </div>
    <div className='registration-cell justify-content-center'>
      {violations.end && <Icon icon='warningFilled' className='warning-icon' />}
      {checked_out_at ? formatTime(checked_out_at) : '--:--'}
    </div>
    <div className='registration-cell justify-content-center align-items-end flex-column'>
      <div>{formatDuration(checked_in_duration)}</div>
    </div>
    <div className='registration-cell d-flex align-items-center justify-content-end'>
      <ActionDropdown
        object={registration}
        actions={registrationActions}
        permissions={permissions}
        tooltipId={id.toString()}
      />
    </div>
  </div>
)

export default RegistrationRow
