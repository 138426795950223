import { useState, useEffect, useMemo } from 'react'

import axios from 'axios'
import isEqual from 'lodash/isEqual'
import { getApiErrorMessage, showErrorModal } from 'utils/confirmations'
import { url } from 'utils/urlGenerator'

import { columnDates } from '../Shifts'
import { getDayParams, processRow } from '../shiftDayHelper'

export default ({ month, search, chosenCompaniesIds }) => {
  const [shifts, setShifts] = useState([])
  const [loading, setLoading] = useState(true)
  const [managedShiftInfo, setManagedShiftInfo] = useState([])

  const dates = useMemo(() => columnDates(month), [month])
  const days = dates.map(getDayParams)

  const updateShift = (oldShifts, shift) => {
    const shiftIdx = oldShifts.findIndex(os => os.id === shift.id)
    const updatedShift = processRow({ shift, days })

    oldShifts[shiftIdx] = updatedShift
  }

  const updateShifts = (oldShifts, shifts) =>
    oldShifts.map(os => processRow({ shift: shifts.find(shift => shift.id === os.id), days }) || os)

  const handleManagedShiftInfo = shiftParams => {
    const includes = managedShiftInfo.find(param => isEqual(param, shiftParams))

    if (includes) {
      setManagedShiftInfo([...managedShiftInfo].filter(param => !isEqual(param, shiftParams)))
    } else {
      setManagedShiftInfo([...managedShiftInfo, shiftParams])
    }
  }

  const manageShift = (shift, action, date = null) => {
    switch (action) {
      case 'create': {
        const newShift = processRow({ shift, days })

        setShifts([...shifts, newShift])
        handleManagedShiftInfo({ shiftId: shift.id, date })
        break
      }
      case 'update': {
        const oldShifts = [...shifts]

        updateShift(oldShifts, shift)

        if (shift.switched_shift) {
          updateShift(oldShifts, shift.switched_shift)
        }

        setShifts(oldShifts)
        handleManagedShiftInfo({ shiftId: shift.id, date })
        break
      }
      case 'delete': {
        const newShifts = shifts.filter(s => s !== shift)

        setShifts(newShifts)
        break
      }
    }
  }

  const manageMultipleShifts = (newShifts, selectedItems) => {
    const updatedShifts = updateShifts(shifts, newShifts)

    setShifts(updatedShifts)

    const managedShifts = selectedItems.map(item => ({
      shiftId: item.shift_id,
      date: item.date
    }))

    setManagedShiftInfo(managedShifts)
  }

  const loadShifts = () => {
    setLoading(true)

    axios
      .get(url('v2/shifts', { month, search, chosen_companies_ids: chosenCompaniesIds }))
      .then(({ data: { rows } }) => {
        const shifts = rows.map(shift => processRow({ shift, days }))
        setShifts(shifts)
      })
      .catch(() => {
        showErrorModal({
          title: getApiErrorMessage(null),
          cancelAction: Turbolinks.visit
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    loadShifts()
  }, [month, search, chosenCompaniesIds])

  return useMemo(
    () => ({
      loadShifts,
      setManagedShiftInfo,
      managedShiftInfo,
      manageShift,
      shifts,
      loading,
      manageMultipleShifts
    }),
    [managedShiftInfo, shifts, loading, manageMultipleShifts]
  )
  /* eslint-enable react-hooks/exhaustive-deps */
}
