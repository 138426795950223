import React from 'react'

import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import useTranslate from '../../../hooks/useTranslate'
import { formatDate } from '../../../utils/formatters'
import { url } from '../../../utils/urlGenerator'
import Modal from '../../Modal'
import Table from '../../Table'

const getStatusHistoryFromBis = async ligumsId => {
  return await axios
    .get(url('v2/vedludb/registrations', ligumsId, 'status_history'))
    .then(res => res.data?.status_history)
}

const StatusHistory = ({ onClose, ligumsId }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.StatusHistory')
  const { isPending, isLoading, data } = useQuery({
    queryKey: ['vedludb', 'registration', ligumsId, 'status_history'],
    queryFn: () => getStatusHistoryFromBis(ligumsId)
  })

  const columns = [
    {
      Header: t('.versija'),
      accessor: 'versija',
      className: 'text-right'
    },
    {
      Header: t('.izmainuDatums'),
      Cell: ({ resource }) => formatDate(resource.izmainuDatums)
    },
    {
      Header: t('.izmainuVeicejs'),
      accessor: 'izmainuVeicejs'
    },
    {
      Header: t('.statuss'),
      Cell: ({ resource }) => (
        <div>{t(`components.ConstructionObjects.Vedludb.RegistrationStatus.status.${resource.statuss}`)}</div>
      )
    },
    {
      Header: t('.statusaDatums'),
      Cell: ({ resource }) => formatDate(resource.statusaDatums)
    }
  ]

  const loading = isPending || isLoading

  const versions = [].concat(data)

  return (
    <Modal isOpen title={t('.title')} className='modal-xl' onClose={onClose}>
      <div className='table-responsive'>
        <Table columns={columns} data={versions} isLoading={loading} withBorder withHover />
      </div>
    </Modal>
  )
}

export default StatusHistory
