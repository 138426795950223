import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Avatar from '../Avatar'
import { SimpleSelect } from '../FilterComponents'
import Permissions from '../Permissions'
import Store from '../Store'
import TableContainer from '../TableContainer'
import UsersFilter from '../Users/UsersFilter'
import CompanyUsersTable from './CompanyUsersTable'

class CompanyUsersTableContainer extends TableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'user_last_activity_at', desc: true }]
  })

  customFilters = () => {
    const { construction_objects_options, roles_options, statuses_options } = this.props

    return (
      <React.Fragment>
        <SimpleSelect
          store={this.store}
          selectable='construction_object_id'
          options={construction_objects_options}
          isMulti={true}
        />
        <SimpleSelect store={this.store} selectable='role' options={roles_options} />
        <SimpleSelect store={this.store} selectable='status' options={statuses_options} />
      </React.Fragment>
    )
  }

  columns = [
    {
      accessorKey: 'user_id',
      visible: false
    },
    {
      header: t('activerecord.attributes.user.full_name'),
      accessorKey: 'user_full_name',
      cell: ({ getValue, row: { original } }) => {
        const resourcePath = original.permissions.show && url('v2/users', original.user_id)

        return <Avatar src={original.picture_url} text={getValue()} resourcePath={resourcePath} />
      }
    },
    {
      header: t('activerecord.attributes.user.email'),
      accessorKey: 'user_email'
    },
    {
      header: t('activerecord.attributes.company_user.role'),
      accessorKey: 'role',
      sortable: false
    },
    {
      header: t('activerecord.attributes.company_user.permissions'),
      accessorKey: 'permissions',
      sortable: false,
      cell: ({ row: { original } }) => (
        <Permissions object={original} type='company_user' permissions={this.props.permissions} />
      )
    },
    {
      header: t('activerecord.attributes.company_user.created_at'),
      accessorKey: 'created_at'
    },
    {
      header: t('activerecord.attributes.user.last_activity_at'),
      accessorKey: 'user_last_activity_at'
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <UsersFilter
          {...this.props}
          store={this.store}
          customFilters={this.customFilters}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <CompanyUsersTable {...this.props} store={this.store} columns={this.columns} columnOptions={columnOptions} />
      </div>
    )
  }
}

export default CompanyUsersTableContainer
