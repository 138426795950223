import React from 'react'

import classNames from 'classnames'
import dayjs from 'dayjs'
import { useFieldArray } from 'react-hook-form'

import useTranslate from '../../../../hooks/useTranslate'
import { DateField, NumberField } from '../../../Forms/HookFields'
import Icon from '../../../Icon'
import styles from './ContractForm.module.scss'

const PaymentPerMonthField = ({ required, disabled }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.ContractForm')
  const { fields, append, remove, update } = useFieldArray({ name: 'ligumaSummasMenesi' })

  const handleRowRemove = index => {
    const field = fields[index]
    if (field.aktuala) {
      update(index, { ...field, aktuala: false })
    } else {
      remove(index)
    }
  }

  const handleRowAppend = () => {
    append({ menesis: null, summa: null })
  }

  return (
    <div className={styles.monthlyPaymentsField}>
      {fields.map(
        (field, index) =>
          (field.aktuala === undefined || field.aktuala) && (
            <div className={styles.monthPaymentRow} key={field.id}>
              <DateField
                name={`ligumaSummasMenesi.${index}.menesis`}
                type='month'
                placeholder={t('.fields.placeholders.contractMonth')}
                wrapperClassName={styles.paymentMonthSelect}
                dateMin={dayjs().toDate()}
                disabled={disabled}
                required={required}
              />
              <NumberField
                name={`ligumaSummasMenesi.${index}.summa`}
                placeholder={t('.fields.placeholders.contractSum')}
                disabled={disabled}
                required={required}
              />
              <Icon icon='iconTrash' className={styles.rowButton} onClick={() => handleRowRemove(index)} />
            </div>
          )
      )}
      <div className={classNames(styles.rowButton, 'month-append-button')} onClick={handleRowAppend}>
        <Icon icon='iconPlusCircle' />
        <div>{t('.buttons.addPaymentMonth')}</div>
      </div>
    </div>
  )
}

export default PaymentPerMonthField
