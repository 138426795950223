import React from 'react'

import { t } from '../../../../utils/i18n'

const Radio = ({ monthIntervalKind, onMonthIntervalKindChange, type, disabled }) => (
  <label className='month-configurations-radio-label'>
    <input
      disabled={disabled}
      className={type + ' mr-2'}
      type='radio'
      onChange={() => onMonthIntervalKindChange(type)}
      checked={monthIntervalKind === type}
      name='tasker_task[regularity][month_interval_kind]'
      value={type}
    />
    {t(`v2.tasker.tasks.repetitive_month_configurations.${type}`)}
  </label>
)

const MonthConfiguration = ({ withLabel = true, ...props }) => (
  <div className='task-regularity-month-configuration-container form-group'>
    {withLabel && (
      <label className='col-sm-4 col-form-label'>
        {t('v2.tasker.tasks.repetitive_month_configurations.info_label')}
      </label>
    )}
    <div className='fields-right-container' style={{ minWidth: 0 }}>
      <Radio {...props} type='dates' />
      <Radio {...props} type='days' />
    </div>
  </div>
)

export default MonthConfiguration
