/* eslint-disable react-hooks/rules-of-hooks */
import ReactRailsUJS from 'react_ujs'
import Turbolinks from 'turbolinks'

const componentRequireContext = require.context('components', true)

Turbolinks.start()
ReactRailsUJS.detectEvents()
ReactRailsUJS.useContext(componentRequireContext)

export default container => {
  ReactRailsUJS.mountComponents(container)
}
/* eslint-enable react-hooks/rules-of-hooks */
