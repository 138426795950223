import { yupResolver } from '@hookform/resolvers/yup'

import Yup from '../../../Forms/common/yup'

const YUP_SELECT_VALUE = {
  label: Yup.string(),
  value: Yup.number()
}

const YUP_LABEL_OBJECT_VALUE = {
  label: Yup.string(),
  value: Yup.string()
}

export const getValidationSchema = modules => {
  const schema = Yup.object().shape({
    // Always visible fields
    title: Yup.string().required().nullable(),
    description: Yup.string().notRequired().nullable(),
    starting_time: Yup.date().notRequired().nullable(),
    deadline: Yup.date().notRequired().nullable(),
    task_group_id: Yup.object(YUP_SELECT_VALUE).notRequired().nullable(),
    client_label: Yup.object(YUP_LABEL_OBJECT_VALUE).notRequired().nullable(),
    device_label: Yup.object(YUP_LABEL_OBJECT_VALUE).notRequired().nullable(),
    // responsible_user_id: Yup.object(YUP_SELECT_VALUE).notRequired().nullable(),
    responsible_users_ids: Yup.array(Yup.object(YUP_SELECT_VALUE)).notRequired().nullable(),
    // responsible_user_group_id: Yup.object(YUP_SELECT_VALUE).notRequired().nullable(),
    responsible_user_groups_ids: Yup.array(Yup.object(YUP_SELECT_VALUE)).notRequired().nullable(),
    purgeable_file_ids: Yup.array(Yup.number()).notRequired().nullable(),
    files: Yup.array(Yup.object()).notRequired().nullable(),
    images_required: Yup.boolean(),
    is_template: Yup.boolean(),
    receive_task_completion_notification: Yup.boolean(),
    // Conditional schemas & module flags
    has_location: Yup.boolean(),
    regularity_enabled: Yup.boolean(),
    time_tracking_enabled: Yup.boolean(),
    ...(modules.timeTracking ? timeTrackingSchema : {}),
    ...(modules.subtasks ? subtasksSchema : {}),
    ...(modules.location ? locationSchema : {}),
    regularity: modules.regularity ? regularitySchema : null
  })

  return yupResolver(schema)
}

const timeTrackingSchema = {
  time_tracking_forbidden_time_periods: Yup.array(Yup.object()),
  concurrent_time_tracking_forbidden: Yup.boolean(),
  allow_time_tracking_in_forbidden_periods: Yup.boolean(),
  resume_time_tracking_after_forbidden_period: Yup.boolean()
}

const subtasksSchema = {
  sub_tasks_attributes: Yup.array(Yup.object()).notRequired().nullable(),
  sub_task_completion_required: Yup.boolean()
}

const locationSchema = {
  location_address: Yup.string().required().nullable(),
  location_polygon: Yup.string().required().nullable()
}

const regularitySchema = Yup.object({
  duration_days: Yup.object().notRequired().nullable(),
  duration_time: Yup.string().notRequired().nullable(),
  starting_date: Yup.date().required(),
  starting_time: Yup.date().required(),
  interval: Yup.mixed().required(),
  interval_kind: Yup.string().required(),
  week_days: Yup.object().notRequired().nullable(),
  end_date: Yup.date().notRequired().nullable(),
  month_specific_days: Yup.mixed().notRequired().nullable(),
  month_interval_kind: Yup.string().notRequired().nullable(),
  month: Yup.object({
    month_dates: Yup.object().notRequired().nullable(),
    month_days_by_weeks: Yup.array(Yup.object()).notRequired().nullable()
  })
})
  .notRequired()
  .nullable()
