import React, { useCallback } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { FormProvider, useForm } from 'react-hook-form'

import { showConfirmation } from '../../../../utils/confirmations'
import { t } from '../../../../utils/i18n'
import { url } from '../../../../utils/urlGenerator'
import { TextField } from '../../../Forms/HookFields'
import { handleHookFormError } from '../../../Forms/utils/formErrors'
import ModalFormButtons from '../../common/ModalFormButtons'
import { useSchedulesContext } from '../../contexts/SchedulesContext'
import TimeFields from '../TimeFields'
import { validationSchema, scheduleToForm, formToData } from './helpers'

const DayForm = ({ schedule, metadata, closeModal }) => {
  const { refreshSchedule } = useSchedulesContext()

  const formData = scheduleToForm(schedule)

  const formContext = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: formData,
    mode: 'onSubmit'
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    setError,
    getFieldState,
    register
  } = formContext

  const month = metadata.dateIso.slice(0, -3)

  const sendRequest = useCallback(
    async (values, status) => {
      return axios({
        method: 'put',
        url: url('v2/schedules/shifts/bulk_upsert'),
        data: {
          month,
          shift_units_attributes: {
            ...formToData(values),
            status,
            units: [
              {
                date: metadata.dateIso,
                schedule_id: schedule.id
              }
            ]
          }
        }
      })
        .then(response => {
          closeModal()
          const updatedSchedule = response.data.rows[0]
          refreshSchedule(updatedSchedule)
        })
        .catch(error => {
          handleHookFormError({ error, setError })
        })
    },
    [refreshSchedule, setError, month, closeModal, schedule.id, metadata.dateIso]
  )

  const confirmDayDelete = () => {
    showConfirmation({
      isOpen: true,
      closeOnConfirm: true,
      onConfirm: () => sendRequest({}, 'deleted'),
      text: t('confirm_destroy'),
      confirmButton: { title: t('destroy'), type: 'danger' }
    })
  }

  return (
    <FormProvider {...formContext}>
      <form onSubmit={handleSubmit(values => sendRequest(values, 'updated'))} className='react-form shift-form'>
        <TimeFields control={control} setValue={setValue} initialValues={formData} />
        <TextField
          control={control}
          name='comment'
          label={t('v2.shifts.index.form.fields.comment')}
          getFieldState={getFieldState}
          register={register}
          textArea
        />
        <ModalFormButtons isSubmitting={isSubmitting} closeModal={closeModal} onDestroyClick={confirmDayDelete} />
      </form>
    </FormProvider>
  )
}

export default DayForm
