import React from 'react'

import Icon from './Icon'

class Flash extends React.Component {
  state = { flashes: this.props.flashes }

  componentDidMount() {
    this.timeout = setTimeout(this.clearFlashes, 7500)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  clearFlashes = type => {
    const flashes = type ? this.state.flashes.filter(f => f[0] !== type) : []
    this.setState({ flashes })
  }

  renderFlash = ([type, message], index) => {
    const icon = { alert: 'circleCross', notice: 'checkMark' }[type]

    return (
      <div key={index} className={`flash ${type}`}>
        <Icon icon={icon} className='icon' />
        {message}
        <Icon icon='cross' className='close' onClick={() => this.clearFlashes(type)} />
      </div>
    )
  }

  render() {
    return <div className='flash-container'>{this.state.flashes.map(this.renderFlash)}</div>
  }
}

export default Flash
