import React from 'react'

import axios from 'axios'
import classNames from 'classnames'
import Cleave from 'cleave.js/react'
import debounce from 'lodash/debounce'
import { observer } from 'mobx-react'
import Select from 'react-select'
import Turbolinks from 'turbolinks'

import { getApiErrorMessage, showErrorModal } from '../utils/confirmations'
import { t } from '../utils/i18n'
import { url } from '../utils/urlGenerator'
import DateInterval from './DateInterval'
import { ExportButton, ExportRange } from './ExportButton'
import Icon from './Icon'
import ModalForm from './ModalForm'
import RangeDatepicker from './RangeDatepicker'
import { REACT_SELECT_PROPS } from './Select'
import Tooltip from './Tooltip'

const SearchField = observer(({ className, placeholder, store }) => (
  <div className={classNames(className, 'search-field')}>
    <div className='input-group'>
      <div className='input-group-prepend'>
        <span className='input-group-text'>
          <i className='fa fa-search' />
        </span>
      </div>
      <input
        className='form-control search'
        type='text'
        placeholder={placeholder}
        onChange={store.setFilterKeyword}
        value={store.getFilterKeyword()}
      />
    </div>
  </div>
))

const DurationFilterInput = observer(({ store }) => (
  <div className='filter-duration-input'>
    <Cleave
      placeholder={t('filters.select_duration')}
      className='form-control'
      options={{ time: true, timePattern: ['h', 'm'] }}
      onChange={store.setFilterDuration}
      value={store.getFilterDuration()}
    />
  </div>
))

class SimpleSelect extends React.Component {
  constructor(props) {
    super(props)

    const normalizedOptions = this.normalizeOptions(props.options)

    this.state = { normalizedOptions, defaultValue: this.defaultValue(normalizedOptions) }
  }

  normalizeOptions(options) {
    if (!options) return null

    if (Array.isArray(options[0])) {
      return options.map(([label, value]) => ({ label, value }))
    } else {
      return options
    }
  }

  defaultValue(normalizedOptions) {
    const { store, selectable, defaultFilter = {} } = this.props
    let defaultOptions = []
    const filterValue = store.state.filters[selectable] || defaultFilter[selectable]

    if (filterValue) {
      const selectedValues = Array.isArray(filterValue) ? filterValue : [filterValue]

      defaultOptions = normalizedOptions.filter(option => selectedValues.some(selected => selected == option.value))
    }

    return defaultOptions
  }

  setFilterSelection = selection => {
    this.props.store.setFilter(this.props.selectable, selection)
  }

  getContainerProps() {
    const { className, dataContains } = this.props

    return {
      className: classNames('filter-select', className),
      'data-contains': dataContains
    }
  }

  render() {
    const { selectable, options, isClearable = true, ...restProps } = this.props

    if (!options) return null

    return (
      <div {...this.getContainerProps()}>
        <Select
          {...REACT_SELECT_PROPS}
          {...restProps}
          placeholder={t(`filters.select_${selectable}`)}
          onChange={this.setFilterSelection}
          options={this.state.normalizedOptions}
          defaultValue={this.state.defaultValue}
          isClearable={isClearable}
        />
      </div>
    )
  }
}

class TimeZoneSelect extends SimpleSelect {
  setFilterSelection = selection => {
    this.props.store.onTimeZoneChange(selection)
  }
}

const onSuccessfulImport = ({ data }) => {
  const importResult = data && data.import_result

  if (importResult) {
    localStorage.setItem('importResult', JSON.stringify(importResult))
  }
}

const ImportButton = ({ url, permitted }) => {
  if (!permitted) return null

  return (
    <div>
      <ModalForm
        url={url}
        title={t('import')}
        button={onClick => (
          <button className='btn btn-primary' onClick={onClick}>
            {t('import')}
          </button>
        )}
        onSuccessfulSubmit={onSuccessfulImport}
      />
    </div>
  )
}

const DateIntervalFilter = observer(({ store, accessKeys, label }) => (
  <div className='interval-filter-wrapper' aria-label={label}>
    <DateInterval store={store} accessKeys={accessKeys} label={label} />
  </div>
))

const DateRangeFilter = observer(({ className, store, customDateKeys = null }) => (
  <div className={classNames('range-datepicker-wrapper', className)}>
    <RangeDatepicker store={store} customDateKeys={customDateKeys} />
  </div>
))

class FilterContainer extends React.Component {
  state = { showFilterSettings: false, updating: false, showMoreOptions: false }
  store = this.props.store

  mapOptions = (options, exportUrl) =>
    options.map(({ path = exportUrl, params, icon = 'fileXlsx', label, title }, index) => (
      <button onClick={() => this.store.exportData(path, params)} className='option' key={index}>
        <Icon icon={icon} />
        {label ? t(`filters.more_options.${label}`) : title}
      </button>
    ))

  renderClearFiltersButton() {
    if (this.props.filters === false) return

    return (
      <button
        className='btn btn-sm filter-button clear-filters'
        onClick={this.store.clearFilters}
        title={t('filters.clear_filters')}
      >
        <i className='fa fa-times mr-0' />
      </button>
    )
  }

  toggleTableSettings(showFilterSettings) {
    this.setState({ showFilterSettings })
  }

  onColumnOptionClick(column) {
    this.setState({ updating: true })
    this.props.onColumnVisibilityChange(column)
    this.debouncedUpdateColumnPreferences(column)
  }

  async updateColumnPreferences(column) {
    try {
      await axios.put(url(`v2/table_preferences/${this.props.table_name}`, { column }))
    } catch {
      showErrorModal({
        title: getApiErrorMessage(null),
        cancelAction: Turbolinks.visit
      })
    } finally {
      this.setState({ updating: false })
    }
  }

  debouncedUpdateColumnPreferences = debounce(this.updateColumnPreferences, 200)

  renderTableSettingItem = (item, index, oneChecked) => {
    const checked = item.visible
    const disabled = checked && oneChecked

    return (
      <div
        key={index}
        className={classNames('option', { disabled })}
        onClick={() => !disabled && this.onColumnOptionClick(item.accessorKey)}
      >
        <span className={classNames('checkbox', { checked, disabled })}>{checked && <Icon icon='checkMark' />}</span>
        {item.title}
      </div>
    )
  }

  renderTableSettingsTooltipContent() {
    const { columnOptions = [] } = this.props

    const hideableColumnOptions = columnOptions.filter(o => o.hideable)
    const oneChecked = hideableColumnOptions.filter(o => o.visible).length <= 1

    return (
      <div className='table-preferences-container'>
        <div className='title'>{t('filters.columns')}</div>
        {oneChecked && <div className='info-text'>{t('filters.minimal_column_count')}</div>}
        <div className='options-container'>
          {hideableColumnOptions.map((option, index) => this.renderTableSettingItem(option, index, oneChecked))}
        </div>
      </div>
    )
  }

  renderTableSettingsButton() {
    if (!this.props.show_table_preferences_button) return

    const { showFilterSettings, updating } = this.state

    const buttonClassnames = classNames('btn btn-sm filter-button table-preferences', {
      'filter-open': showFilterSettings
    })

    return (
      <>
        <button className={buttonClassnames} data-tip data-for='table-preferences-tooltip' data-updating={updating}>
          <i className='fa fa-eye mr-0' />
        </button>
        <Tooltip
          id='table-preferences-tooltip'
          place='bottom'
          className='white'
          effect='solid'
          globalEventOff='click'
          afterHide={() => this.toggleTableSettings(false)}
          afterShow={() => this.toggleTableSettings(true)}
          event='click'
          clickable
        >
          {this.renderTableSettingsTooltipContent()}
        </Tooltip>
      </>
    )
  }

  renderHiddenFilterToggle() {
    if (!this.props.hiddenFilters || this.props.filters === false) return

    const buttonClassnames = classNames('btn btn-sm filter-button', {
      'filter-open': this.store.getFilterState()
    })

    return (
      <button className={buttonClassnames} onClick={this.store.toggleFilter}>
        <i className='fa fa-sliders' />
        {t('filters.filters')}
      </button>
    )
  }

  renderHiddenFilters() {
    if (!this.props.hiddenFilters || !this.store.getFilterState()) return

    return <div className='filter-row'>{this.props.hiddenFilters()}</div>
  }

  renderMoreOptionsTooltipContent() {
    const { export_url, additional_export_options } = this.props
    if (!additional_export_options) return

    return <div className='export-option-list'>{this.mapOptions(additional_export_options, export_url)}</div>
  }

  exportWithDateRange() {
    const { export_options: options } = this.props

    return (
      <ExportButton optionsContent={this.mapOptions(options)}>
        <ExportRange store={this.store} />
      </ExportButton>
    )
  }

  handleExportClick(props) {
    if (!props.additional_export_options) {
      const hidden_columns = props.columnOptions?.filter(column => !column.visible).map(column => column.accessorKey)
      this.store.exportData(props.export_url, { hidden_columns })
    }
  }

  render() {
    const { export_url, export_options } = this.props

    return (
      <div className='filters'>
        <div className='filter-row'>
          {this.renderTableSettingsButton()}
          {this.renderClearFiltersButton()}
          {this.renderHiddenFilterToggle()}
          {this.props.children}
          {export_url && (
            <ExportButton
              onClick={() => this.handleExportClick(this.props)}
              optionsContent={this.renderMoreOptionsTooltipContent()}
            />
          )}
          {export_options && this.exportWithDateRange()}
        </div>

        {this.renderHiddenFilters()}
      </div>
    )
  }
}

const FilterContainerWrapped = observer(FilterContainer)

export {
  FilterContainerWrapped as FilterContainer,
  SearchField,
  ImportButton,
  SimpleSelect,
  DateRangeFilter,
  DateIntervalFilter,
  TimeZoneSelect,
  DurationFilterInput
}
