import React, { useCallback, useEffect, useRef, useState } from 'react'

import ReactDOM from 'react-dom'
import Draggable from 'react-draggable'

import useHandleOutsideClick from '../../hooks/useHandleOutsideClick'
import useUnsavedChangesConfirmation from '../../hooks/useUnsavedChangesConfirmation'
import { t } from '../../utils/i18n'
import Icon from '../Icon'

const PADDING = 10

export const calculateMiniModalPosition = (ref, maxWidth) => {
  const { x, y } = ref.current.getBoundingClientRect()
  const scrollHeight = document.documentElement.scrollTop || document.body.scrollTop

  const calculatedX = window.innerWidth < x + maxWidth ? window.innerWidth - maxWidth - PADDING : x

  return {
    x: calculatedX,
    y: y - 82 + scrollHeight
  }
}

const MiniModal = ({
  position,
  onClose,
  dirty,
  handleSubmit,
  onDeleteClick,
  children,
  buttons,
  multiple,
  className = ''
}) => {
  const [bounds, setBounds] = useState(null)
  const [initialNodeBox, setInitialNodeBox] = useState(null)
  const miniModalRef = useRef(null)

  const handleClose = useUnsavedChangesConfirmation({ dirty, onClose })

  const handleClickOutside = event => {
    const ctrlPressed = event.getModifierState('Control') || event.getModifierState('Meta') // Ctrl || Cmd

    if (!ctrlPressed || !multiple) {
      handleClose()
    }
  }

  useHandleOutsideClick({ ref: miniModalRef, onOutsideClick: handleClickOutside, onEsc: handleClose })

  const handleEnter = useCallback(
    ({ key }) => {
      if (key === 'Enter') handleSubmit()
    },
    [handleSubmit]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleEnter)
    return () => {
      window.removeEventListener('keydown', handleEnter)
    }
  }, [handleEnter])

  const handleStart = useCallback(
    (_e, data) => {
      if (!data) return

      const boundingBox = document.querySelector('main').getBoundingClientRect()

      if (!bounds) {
        const nodeBox = data.node.getBoundingClientRect()

        setInitialNodeBox(nodeBox)

        setBounds({
          left: boundingBox.left - nodeBox.left,
          right: boundingBox.right - nodeBox.right,
          top: boundingBox.top - nodeBox.top,
          bottom: boundingBox.bottom - nodeBox.bottom
        })
      } else {
        setBounds({
          ...bounds,
          bottom: boundingBox.height - initialNodeBox.bottom
        })
      }
    },
    [bounds, initialNodeBox, setInitialNodeBox, setBounds]
  )

  return ReactDOM.createPortal(
    <Draggable positionOffset={position} bounds={bounds} onStart={handleStart} cancel='.not-draggable'>
      <div className={`shift-mini-modal ${className}`} ref={miniModalRef}>
        <div className='mini-modal-container'>
          <button className='btn not-draggable close' onClick={onClose}>
            <Icon icon='cross' />
          </button>

          <div className='content d-flex'>
            {children}
            <div className='buttons'>
              <button className='btn btn-sm not-draggable delete ml-2' onClick={onDeleteClick} title={t('destroy')}>
                <Icon icon='iconTrash' className='d-flex' />
              </button>
              {buttons}
            </div>
          </div>
          <div className='info-text'>{t('v2.shift_units.form.save_info')}</div>
        </div>
      </div>
    </Draggable>,
    document.querySelector('.modal-container')
  )
}

export default MiniModal
