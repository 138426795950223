import React, { useContext } from 'react'

import classNames from 'classnames'
import dayjs from 'dayjs'
import { AnimatePresence, motion } from 'framer-motion'
import isEqual from 'lodash/isEqual'

import { formatTime } from '../../utils/formatTime'
import { t } from '../../utils/i18n'
import Tooltip from '../Tooltip'
import SelectIndicator from './SelectIndicator'
import { ShiftContext } from './Shifts'

const formatHours = time => dayjs(time).format('HH')

const EditIndicator = ({ tooltipId }) => (
  <>
    <div className='shift-edit-indicator' data-tip data-for={tooltipId} />

    <Tooltip id={tooltipId} className='accented' place='bottom' effect='solid'>
      {t('v2.shifts.index.changes_take_effect')}
    </Tooltip>
  </>
)

const BlockIndication = ({ className, showErrors, halfSizeBlock, invalidRegistrationsCount }) => (
  <div className={className}>
    {showErrors && (
      <>
        {!halfSizeBlock && <i className='fa fa-warning' />}
        <span className='shift-errors-count'>{invalidRegistrationsCount}</span>
      </>
    )}
  </div>
)

const BlockTime = ({ halfSizeBlock, startTime, endTime }) => {
  if (!startTime || !endTime) return null

  const formatShiftTime = halfSizeBlock ? formatHours : formatTime

  return <div className='shift-block-time'>{`${formatShiftTime(startTime)} - ${formatShiftTime(endTime)}`}</div>
}

const Block = ({
  blockInfo,
  dayBlockKey,
  employeeId,
  dayInvalid,
  showUpdatedIndication,
  employeeDayInvalid,
  halfSizeBlock,
  invalidRegistrationsCount,
  shiftBlockClasses,
  shiftTimes,
  color
}) => {
  const { managedShiftInfo, setManagedShiftInfo } = useContext(ShiftContext)

  const blockIndicationClasses = {
    'shift-block-indication': true,
    invalid: employeeDayInvalid,
    updated: showUpdatedIndication
  }

  const animations = {
    initial: { opacity: 0, scale: 0.3 },
    animate: { opacity: 1, scale: 1 },
    transition: { type: 'spring', stiffness: 100 },
    exit: { opacity: 0, scale: 0.3, transition: { duration: 0.2 } },
    onAnimationComplete: () => {
      setManagedShiftInfo(managedShiftInfo.filter(shiftInfo => !isEqual(shiftInfo, blockInfo)))
    }
  }

  const showBlockIndication = dayInvalid || showUpdatedIndication

  return (
    <motion.div key={dayBlockKey} className={classNames(shiftBlockClasses)} {...animations}>
      {showBlockIndication && (
        <BlockIndication
          className={classNames(blockIndicationClasses)}
          showErrors={!employeeId && dayInvalid}
          halfSizeBlock={halfSizeBlock}
          invalidRegistrationsCount={invalidRegistrationsCount}
        />
      )}
      <div className='shift-block-base' style={{ backgroundColor: color }} data-color={color} />
      <div className='hover-overlay' />
      <BlockTime halfSizeBlock={halfSizeBlock} {...shiftTimes} />
    </motion.div>
  )
}

const ShiftDayBlock = ({ shiftId, employeeId, color, dayParams, yesterdayParams, tomorrowParams }) => {
  const {
    day: { today, past, dateIso },
    shiftUnits: { startTime, endTime, singleDayShift, versionWithTimes },
    dayBlockParams: { dayInvalid, employeeDayInvalid, showBlock, invalidRegistrationsCount },
    editParams: { isShiftEdited, dayUpdated }
  } = dayParams

  const { shiftUnits: tomorrowShiftUnits, dayBlockParams: tomorrowBlockParams } = tomorrowParams || {}
  const { shiftUnits: yesterdayShiftUnits, dayBlockParams: yesterdayBlockParams } = yesterdayParams || {}

  const { showBlock: showTomorrow, hasAbsence: hasAbsenceTomorrow } = tomorrowBlockParams || {}
  const { showBlock: showYesterday } = yesterdayBlockParams || {}
  const { singleDayShift: singleDayTomorrow } = tomorrowShiftUnits || {}
  const { singleDayShift: singleDayYesterday } = yesterdayShiftUnits || {}

  const roundLeft = !showYesterday || (singleDayYesterday && !singleDayShift)
  const roundRight = !showTomorrow || (singleDayShift && !singleDayTomorrow)

  const blockId = `${shiftId}-${dateIso}`
  const tooltipId = `shift-edit-indicator-${blockId}`

  const halfSizeBlock =
    (yesterdayParams && !singleDayYesterday && singleDayShift) || (!singleDayShift && hasAbsenceTomorrow)
  const showUpdatedIndication = (!dayInvalid || (employeeId && !employeeDayInvalid)) && dayUpdated && versionWithTimes

  const shiftBlockClasses = {
    'shift-block': true,
    'one-day': !halfSizeBlock && singleDayShift,
    'half-block': halfSizeBlock,
    'rounded-left': roundLeft,
    'rounded-right': roundRight,
    'past-overlay': past || (today && singleDayShift),
    'half-today-overlay': today && !singleDayShift
  }

  return (
    <>
      <AnimatePresence initial={false}>
        {showBlock && (
          <Block
            dayBlockKey={`${blockId}-${employeeId}`}
            blockInfo={{ shiftId, date: dateIso }}
            shiftTimes={{ startTime, endTime }}
            employeeId={employeeId}
            dayInvalid={dayInvalid}
            employeeDayInvalid={employeeDayInvalid}
            showUpdatedIndication={showUpdatedIndication}
            invalidRegistrationsCount={invalidRegistrationsCount}
            shiftBlockClasses={shiftBlockClasses}
            halfSizeBlock={halfSizeBlock}
            color={color}
          />
        )}
      </AnimatePresence>

      {isShiftEdited && <EditIndicator tooltipId={tooltipId} />}

      <SelectIndicator
        shiftId={shiftId}
        employeeId={employeeId}
        dayParams={dayParams}
        yesterdayParams={yesterdayParams}
        tomorrowParams={tomorrowParams}
        halfSizeblock={halfSizeBlock}
      />
    </>
  )
}

export default ShiftDayBlock
