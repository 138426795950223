import React from 'react'

import classNames from 'classnames'
import { useFormContext, useController } from 'react-hook-form'

import Checkbox from '../../Checkbox'
import HookFieldWrapper from './HookFieldWrapper'

const CheckField = ({ name, required, fieldIcon, label, wrapperClassName = '', disabled }) => {
  const { getFieldState, control } = useFormContext()
  const fieldState = getFieldState(name)

  const { field } = useController({
    name,
    control
  })

  const { ref: _ref, ...fieldProps } = field

  return (
    <HookFieldWrapper
      name={name}
      required={required}
      className={classNames(wrapperClassName, 'justify-content-end', 'flex-row-reverse')}
      icon={fieldIcon}
      label={label}
      fieldState={fieldState}
    >
      <Checkbox checked={field.value} {...fieldProps} disabled={disabled} id={name} />
    </HookFieldWrapper>
  )
}

export default CheckField
