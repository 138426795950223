import React, { useContext, useEffect, useState } from 'react'

import axios from 'axios'

import useTranslate from '../../../hooks/useTranslate'
import { formatCurrency, formatDate } from '../../../utils/formatters'
import { url } from '../../../utils/urlGenerator'
import Modal from '../../Modal'
import Table from '../../Table'
import { VedludbContext } from './ConstructionObjectVedludb'

const PaymentHistory = ({ isOpen, payment, onClose }) => {
  const t = useTranslate('ConstructionObjects.Vedludb')
  const vedludbCtx = useContext(VedludbContext)
  const [paymentHistory, setPaymentHistory] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true)
      const fetchParams = { id: vedludbCtx.ligumsId, norekinuMaksajumsId: payment?.norekinuMaksajumsId }

      axios
        .get(url('v2/vedludb/payments/history'), { params: { ...fetchParams } })
        .then(res => setPaymentHistory(res.data.history))
        .finally(() => setIsLoading(false))
    } else {
      setPaymentHistory([])
    }
  }, [isOpen, payment, vedludbCtx.ligumsId])

  const modalProps = {
    closeOnBackdropClick: false,
    title: t('.PaymentHistory.title'),
    className: 'payment-history-modal',
    onClose,
    isOpen
  }

  const formatPaymentStatus = status => {
    return {
      0: t('.PaymentsTable.statuses.waiting'),
      1: t('.PaymentsTable.statuses.approved'),
      2: t('.PaymentsTable.statuses.denied')
    }[status]
  }

  const columns = [
    { Header: t('.PaymentHistory.headers.version'), accessor: 'versija', className: 'text-right' },
    { Header: t('.PaymentHistory.headers.payment_date'), Cell: ({ resource }) => formatDate(resource.maksajumaDatums) },
    {
      Header: t('.PaymentHistory.headers.payment_sum'),
      className: 'text-right',
      Cell: ({ resource }) => (
        <div>
          <i className='fa fa-eur mx-1' aria-hidden='true'></i>
          {formatCurrency(resource.maksajumaSumma)}
        </div>
      )
    },
    { Header: t('.PaymentHistory.headers.status'), Cell: ({ resource }) => formatPaymentStatus(resource.statuss) },
    { Header: t('.PaymentHistory.headers.explanation'), accessor: 'pamatojums' },
    { Header: t('.PaymentHistory.headers.who_changed'), accessor: 'izmainuVeicejs' },
    { Header: t('.PaymentHistory.headers.change_date'), Cell: ({ resource }) => formatDate(resource.izmainuDatums) }
  ]

  return (
    <Modal {...modalProps}>
      <Table columns={columns} data={paymentHistory} isLoading={isLoading} className='payment-history-table' />
    </Modal>
  )
}

export default PaymentHistory
