import React, { useState } from 'react'

import classNames from 'classnames'
import Turbolinks from 'turbolinks'

import { showConfirmation } from '../../utils/confirmations'
import Icon from '../Icon'
import Tooltip from '../Tooltip'

const ActionsColumn = (store, actions, legacy = false) => {
  let options = {
    header: () => {
      const ids = store.checkedRows().map(([k, _v]) => k)
      const tooltipId = 'header-actions'

      if (ids.length === 0) return null

      return (
        <ActionDropdown
          actions={actions.header}
          object={ids}
          tooltipId={tooltipId}
          className='d-flex align-items-center'
        >
          ({ids.length})
        </ActionDropdown>
      )
    },
    sortable: false,
    enableResizing: false,
    minSize: 75,
    size: 75,
    maxSize: 75,
    headerClassName: 'row-actions',
    className: 'row-actions',
    id: 'row_actions',
    cell: ({ row }) => {
      const original = row.original || row._original
      if (actions.row.every(action => !(original.permissions && original.permissions[action.permission]))) {
        return null
      }

      const tooltipId = `row-actions-${row.id}`

      return (
        <ActionDropdown
          actions={actions.row}
          object={original}
          permissions={original.permissions}
          tooltipId={tooltipId}
        />
      )
    }
  }

  if (legacy) {
    options.Header = options.header
    options.Cell = options.cell
    options.disableResizing = true
    options.minWidth = 75
    options.width = 75
    options.maxWidth = 75
  }

  return options
}

const action = ({ permission, text, customText, icon, onClick, confirmationProps, handler }) => {
  handler ||= object => onClick(object).finally(() => Turbolinks.visit(window.location))

  return {
    permission,
    text,
    customText,
    icon,
    onClick: object => {
      if (confirmationProps) {
        showConfirmation({ isOpen: true, onConfirm: () => handler(object), ...confirmationProps })
      } else {
        handler(object)
      }
    }
  }
}

const ActionButton = ({ onClick, icon, text, customText, object }) => {
  const buttonText = !text && customText ? customText(object) : text

  return (
    <button onClick={() => onClick(object)} className='action w-100'>
      <div className='action-content'>
        <i className={`fa fa-fw fa-${icon}`} />
        {buttonText}
      </div>
    </button>
  )
}

const ActionComponent = ({ action, object, buttonProps, ...others }) => {
  const [isOpen, setIsOpen] = useState(false)

  const props = { isOpen, setIsOpen }
  const Component = action(object, props)

  const onClick = () => {
    setIsOpen(true)
  }

  return (
    <>
      <ActionButton {...others} {...buttonProps} object={object} onClick={onClick} />
      {isOpen && <Component.type {...Component.props} {...props} />}
    </>
  )
}

const dropdownItems = (actions, object, permissions) =>
  actions.map(({ permission, action, ...restProps }, index) => {
    if (permissions && !permissions[permission]) {
      return null
    } else if (action) {
      return <ActionComponent key={index} object={object} action={action} {...restProps} />
    } else {
      return <ActionButton {...restProps} key={index} object={object} />
    }
  })

const DropDown = ({ items, dropdownProps }) => (
  <Tooltip className='actions-dropdown white' place='bottom' effect='solid' clickable {...dropdownProps}>
    {items}
  </Tooltip>
)

const ActionDropdown = ({
  tooltipId,
  actions,
  permissions,
  object,
  className,
  clickable,
  children,
  bottomOffset = -8,
  placement = 'bottom'
}) => {
  const [open, setOpen] = useState(false)

  const items = dropdownItems(actions, object, permissions)

  const dropdownProps = clickable
    ? {
        id: tooltipId,
        event: 'click',
        globalEventOff: 'click',
        afterHide: () => setOpen(false),
        afterShow: () => setOpen(true),
        offset: { right: placement === 'left' ? 0 : 76, bottom: -8 },
        place: placement
      }
    : { id: tooltipId, offset: { right: 8, bottom: bottomOffset }, place: placement }

  return (
    <div
      className={classNames(`action-toggler ${className || ''}`, { open })}
      data-tip
      data-for={tooltipId}
      onClick={e => e.stopPropagation()}
    >
      {children}
      <div className='icon-wrapper'>
        <Icon icon='cog' />
      </div>
      <DropDown dropdownProps={dropdownProps} items={items} />
    </div>
  )
}

export { ActionsColumn, ActionButton, action, DropDown, ActionDropdown }
