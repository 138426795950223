import React from 'react'

import qs from 'qs'

export const tenant = () => {
  const $metaTag = document.querySelector('meta[name=tenant]')
  const tenant = $metaTag.content

  if (tenant === 'public') {
    return ''
  } else {
    return tenant
  }
}

export const url = (...args) => {
  const params = args.length > 1 ? args.pop() : null
  const t = tenant()
  const regexp = new RegExp(`^/${t}/+${t}`)

  let url = `/${t}/${args.join('/')}`

  if (params && params.constructor === Object) {
    const separator = url.includes('?') ? '&' : '?'
    url = `${url}${separator}${qs.stringify(params, { arrayFormat: 'brackets' })}`
  } else if (params) {
    url = `${url}/${params}`
  }

  // prevent double tenant prefix if the path comes from Rails's URL helpers, since tenant is already included there
  return url.replace(regexp, `/${t}`)
}

export const linkTo = (path, label) => {
  return <a href={path}>{label}</a>
}
