import React from 'react'

import cx from 'classnames'
import { useFormContext } from 'react-hook-form'

import FieldWrapper from '../FieldWrapper'
import styles from './FieldStyles.module.scss'

const HookFieldWrapper = ({ name, ...others }) => {
  const { getFieldState } = useFormContext()
  const fieldState = getFieldState(name)
  const { error, invalid } = fieldState

  const hasError = invalid && error && typeof error.message === 'string'

  const classes = cx(styles.formField, others.className)

  return <FieldWrapper {...others} className={classes} error={error?.message} hasError={hasError} name={name} />
}

export default HookFieldWrapper
