import React from 'react'

import { observer } from 'mobx-react'

import { t } from '../../utils/i18n'
import { FilterContainer, SearchField, SimpleSelect } from '../FilterComponents'

class CompaniesFilter extends React.Component {
  hiddenFilters = () => {
    const { store } = this.props

    return (
      <React.Fragment>
        <SimpleSelect
          store={store}
          selectable='construction_object_id'
          options={this.props.construction_objects_options}
          isMulti={true}
        />
        <SimpleSelect store={store} selectable='is_foreign' options={this.props.is_foreign_options} />
      </React.Fragment>
    )
  }

  render() {
    const { store, customFilters, ...others } = this.props

    return (
      <FilterContainer store={store} hiddenFilters={customFilters || this.hiddenFilters} {...others}>
        <SearchField store={store} placeholder={t('filters.search_companies')} />
      </FilterContainer>
    )
  }
}

export default observer(CompaniesFilter)
