import React from 'react'

import Store from '../../Store'
import SystemAccessTokensTable from './SystemAccessTokensTable'

class SystemAccessTokensTableContainer extends React.Component {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'name', desc: false }]
  })

  render() {
    return (
      <div className='nested-table'>
        <SystemAccessTokensTable {...this.props} store={this.store} />
      </div>
    )
  }
}

export default SystemAccessTokensTableContainer
