import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Icon from '../Icon'
import { CheckLogLocation, CheckLogCheckedWith, CheckLogDates, CheckLogDuration } from '../NfcCheckLogs/CheckLogsData'
import CheckLogsFilter from '../NfcCheckLogs/CheckLogsFilter'
import NfcCheckLogsTable from '../NfcCheckLogs/NfcCheckLogsTable'
import NfcCheckLogsTableContainer from '../NfcCheckLogsTableContainer'
import Store from '../Store'

class CompanyNfcCheckLogsTableContainer extends NfcCheckLogsTableContainer {
  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'check_log_date_properties', desc: true }],
    // To handle auto expansion when filtered by task/subtask
    onDataLoad: () => {
      const taskIds = this.store.state.filters.construction_object_task_id
      const subtaskIds = this.store.state.filters.construction_object_sub_task_id

      const task_filter_present = taskIds && taskIds.length > 0
      const subtask_filter_present = subtaskIds && subtaskIds.length > 0

      if (task_filter_present || subtask_filter_present) {
        let expanded = {}

        for (let i = 0; i < this.store.state.data.length; i++) {
          expanded[i] = true
          if (subtask_filter_present) {
            for (let u = 0; u < this.store.state.data[i].subrows.length; u++) {
              expanded[`${i}.${u}`] = true
            }
          }
        }

        this.store.updateState({ expanded })
      }
    }
  })

  columns = [
    this.props.business_industry && {
      accessorKey: 'row-expander',
      id: 'row-expander',
      header: '',
      size: 40,
      maxSize: 40,
      sortable: false,
      enableResizing: false,
      minSize: 40,
      cell: ({ row, row: { original } }) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            {this.renderExpander(row, original.is_group, row.getIsExpanded(), original.parent_id_prefix)}
          </div>
        )
      },
      getClassName: this.getExpandableCellClassName,
      hideable: false
    },
    {
      header: t('activerecord.attributes.nfc_check_log.checkable_full_name'),
      accessorKey: 'checkable_full_name',
      cell: ({ getValue, row, row: { original } }) =>
        row.depth === 0 && (
          <>
            <Icon icon='avatar' className='avatar' />
            <a href={url('v2/employees', original.checkable_id)}>{getValue()}</a>
          </>
        )
    },
    {
      header: t('activerecord.attributes.nfc_check_log.construction_object_name'),
      accessorKey: 'construction_object_name',
      cell: ({ getValue, row: { original } }) =>
        !original.subRow && <a href={url('v2/construction_objects', original.construction_object_id)}>{getValue()}</a>
    },
    {
      header: t('activerecord.attributes.nfc_check_log.checked_in'),
      accessorKey: 'checked_in_name',
      size: 200
    },
    {
      header: t('activerecord.attributes.nfc_check_log.registration_time'),
      accessorKey: 'check_log_date_properties',
      cell: ({ getValue, row: { original } }) => <CheckLogDates dateTimes={getValue()} checkLog={original} />
    },
    {
      header: t('activerecord.attributes.nfc_check_log.checked_with'),
      accessorKey: 'check_log_checked_with_properties',
      sortable: false,
      size: 90,
      cell: ({ getValue, row: { original } }) => <CheckLogCheckedWith checkedWith={getValue()} checkLog={original} />
    },
    {
      header: t('activerecord.attributes.nfc_check_log.location'),
      accessorKey: 'check_log_location_properties',
      sortable: false,
      size: 90,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue()
        return value ? <CheckLogLocation locationData={value} checkLog={original} onClick={this.openMapModal} /> : null
      }
    },
    {
      header: t('activerecord.attributes.nfc_check_log.checked_in_duration'),
      accessorKey: 'checked_in_duration',
      size: 120,
      cell: ({ getValue, row: { original } }) => (
        <CheckLogDuration duration={getValue()} invalid={original.work_duration_exceeded} />
      )
    }
  ]

  onExpand(row) {
    this.onNfcExpandedChange(row, 'Company', this.props.company_id)
  }

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <CheckLogsFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <NfcCheckLogsTable
          ref={this.checkLogTableRef}
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
          onExpand={row => this.onNfcExpandedChange(row, 'Company', this.props.company_id)}
          expanded={this.store.expanded}
        />
      </div>
    )
  }
}

export default CompanyNfcCheckLogsTableContainer
