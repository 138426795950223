import React, { useState, useCallback, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import dayjs from 'dayjs'
import { FormProvider, useForm } from 'react-hook-form'

import { getApiErrorMessage, showErrorModal, showConfirmation } from '../../../utils/confirmations'
import { t } from '../../../utils/i18n'
import { url } from '../../../utils/urlGenerator'
import yup from '../../../utils/yup'
import DateField from '../../Forms/HookFields/DateField'
import SelectField from '../../Forms/HookFields/SelectField'
import Icon from '../../Icon'
import MiniModal, { calculateMiniModalPosition } from '../../Scheduler/MiniModal'
import { useAbsenceContext } from '../contexts/AbsenceContext'
import useAbsencePositionStyle from './useAbsencePositionStyle'

const MINI_MODAL_MAX_WIDTH = 410

const isValidDate = yup.string().required().humanDate()

const validationSchema = yup.object({
  date_from: isValidDate,
  date_to: isValidDate
})

const absenceTypesAdminUrl = url('v2/settings/classifiers/absence_types')

const Edit = ({ absence, policies, dayWidth, day, absenceRef }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div
        className='absences__edit'
        onClick={() => {
          setIsOpen(true)
        }}
      />
      {isOpen && (
        <EditForm
          absence={absence}
          setIsOpen={setIsOpen}
          policies={policies}
          absenceRef={absenceRef}
          dayWidth={dayWidth}
          day={day}
        />
      )}
    </>
  )
}

const getInitialValues = absence => {
  const { date_from, date_to, absence_type } = absence
  const { id: value, abbreviation, title } = absence_type

  return {
    date_from: dayjs(date_from).toDate(),
    date_to: dayjs(date_to).toDate(),
    absence_type_id: { value, abbreviation, title, label: `${title} ( ${abbreviation} )` }
  }
}

const EditForm = ({ absence, setIsOpen, policies, dayWidth, day, absenceRef }) => {
  const { updateAbsence, deleteAbsence } = useAbsenceContext()

  const close = () => {
    setIsOpen(false)
  }

  const initialValues = getInitialValues(absence)

  const onSubmit = useCallback(
    data => {
      axios({
        method: 'put',
        url: url('v2/absences', absence.id),
        data: {
          absence: {
            date_from: dayjs(data.date_from).format(),
            date_to: dayjs(data.date_to).format(),
            absence_type_id: data.absence_type_id.value
          }
        }
      })
        .then(({ data }) => {
          updateAbsence(data)
        })
        .catch(_error => {
          showErrorModal({
            title: getApiErrorMessage(null),
            cancelAction: Turbolinks.visit
          })
        })
    },
    [absence.id, updateAbsence]
  )

  const handleDelete = useCallback(() => {
    const requestDelete = () =>
      axios({
        method: 'DELETE',
        url: url('v2/absences', absence.id)
      })
        .then(() => {
          deleteAbsence(absence)
        })
        .catch(_error => {
          showErrorModal({
            title: getApiErrorMessage(null),
            cancelAction: Turbolinks.visit
          })
        })

    showConfirmation({
      isOpen: true,
      closeOnConfirm: true,
      onConfirm: requestDelete,
      text: t('confirm_destroy'),
      confirmButton: { title: t('destroy'), type: 'danger' }
    })
  }, [absence, deleteAbsence])

  const formContext = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onSubmit'
  })

  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty },
    setValue
  } = formContext

  const dateFrom = watch('date_from')

  const currentDateFrom = dayjs(dateFrom)
  const currentDateTo = dayjs(watch('date_to'))

  const currentISODateFrom = currentDateFrom.format()
  const currentISODateTo = currentDateTo.format()

  useEffect(() => {
    if (currentDateFrom.isAfter(currentDateTo)) {
      setValue('date_to', dateFrom)
    }
  }, [currentDateFrom, currentDateTo, dateFrom, setValue])

  const outlineStyle = useAbsencePositionStyle({
    day,
    dayWidth,
    dateFrom: currentISODateFrom,
    dateTo: currentISODateTo
  })

  const position = calculateMiniModalPosition(absenceRef, MINI_MODAL_MAX_WIDTH)

  const popperModifiers = [
    {
      name: 'offset',
      options: {
        // calendar width - input width
        offset: [-215, 0]
      }
    }
  ]

  return (
    <>
      {outlineStyle && <div className='absences__edit-outline' style={outlineStyle} />}
      <MiniModal
        position={position}
        onClose={close}
        dirty={isDirty}
        handleSubmit={handleSubmit(onSubmit)}
        onDeleteClick={handleDelete}
        className='absence-mini-modal'
      >
        <FormProvider {...formContext}>
          <form onSubmit={handleSubmit(onSubmit)} className='react-form'>
            <div className='d-flex align-items-start'>
              <SelectField
                control={control}
                label={t('v2.absences.edit_modal.type')}
                optionsURL={url('v2/absences/absence_types_autocomplete')}
                name='absence_type_id'
                withoutBlank
                renderOptionLabel={({ abbreviation, title }) => (
                  <>
                    <span className='text-truncate'>{title}</span>
                    <span className='text-secondary text-nowrap ml-2'>( {abbreviation} )</span>
                  </>
                )}
                formatSelectedItem={({ abbreviation, title }) => `${title} ( ${abbreviation} )`}
                optionsFooter={
                  policies.absenceTypes.create && (
                    <a className='options-link' href={absenceTypesAdminUrl} target='_blank' rel='noreferrer'>
                      <Icon icon='cog' className='mr-2' />
                      <span>{t('nav.settings')}</span>
                    </a>
                  )
                }
                wrapperClassName='my-0'
                className='not-draggable'
              />
              <DateField
                control={control}
                name='date_from'
                label={t('v2.absences.edit_modal.from')}
                wrapperClassName='mx-2 my-0'
                className='text-center not-draggable'
                popperModifiers={popperModifiers}
              />
              <DateField
                control={control}
                name='date_to'
                label={t('v2.absences.edit_modal.to')}
                wrapperClassName='my-0'
                className='text-center not-draggable'
                dateMin={dateFrom}
                popperModifiers={popperModifiers}
              />
            </div>
          </form>
        </FormProvider>
      </MiniModal>
    </>
  )
}

export default Edit
