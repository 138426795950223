import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import { CheckLogLocation, CheckLogCheckedWith, CheckLogDates, CheckLogDuration } from '../NfcCheckLogs/CheckLogsData'
import CheckLogsFilter from '../NfcCheckLogs/CheckLogsFilter'
import NfcCheckLogsTable from '../NfcCheckLogs/NfcCheckLogsTable'
import Store from '../Store'
import TableContainer from '../TableContainer'
import { BooleanIcon } from '../elements/TableColumnsElements'

class GuestNfcCheckLogsTableContainer extends TableContainer {
  constructor(props) {
    super(props)
    this.checkLogTableRef = React.createRef()
  }

  openMapModal = (mapModalTitle, markerLat, markerLong, polygons, apiKey) => {
    this.checkLogTableRef.current.openMapModal(mapModalTitle, markerLat, markerLong, polygons, apiKey)
  }

  store = new Store({
    url: this.props.url,
    defaultSorted: [{ id: 'check_log_date_properties', desc: true }]
  })

  columns = [
    {
      header: t('activerecord.attributes.nfc_check_log.construction_object_name'),
      accessorKey: 'construction_object_name',
      cell: ({ getValue, row: { original } }) => (
        <span>
          <a href={url('v2/construction_objects', original.construction_object_id)}>{getValue()}</a>
        </span>
      )
    },
    {
      header: t('activerecord.attributes.nfc_check_log.checked_in'),
      accessorKey: 'checked_in',
      minSize: 90,
      size: 90,
      cell: ({ getValue }) => <BooleanIcon checked={getValue()} />
    },
    {
      header: t('activerecord.attributes.nfc_check_log.registration_time'),
      accessorKey: 'check_log_date_properties',
      cell: ({ getValue, row: { original } }) => <CheckLogDates dateTimes={getValue()} checkLog={original} />
    },
    {
      header: t('activerecord.attributes.nfc_check_log.checked_with'),
      accessorKey: 'check_log_checked_with_properties',
      sortable: false,
      cell: ({ getValue, row: { original } }) => <CheckLogCheckedWith checkedWith={getValue()} checkLog={original} />
    },
    {
      header: t('activerecord.attributes.nfc_check_log.location'),
      accessorKey: 'check_log_location_properties',
      sortable: false,
      cell: ({ getValue, row: { original } }) => (
        <CheckLogLocation locationData={getValue()} checkLog={original} onClick={this.openMapModal} />
      )
    },
    {
      header: t('activerecord.attributes.nfc_check_log.checked_in_duration'),
      accessorKey: 'checked_in_duration',
      cell: ({ getValue, row: { original } }) => (
        <CheckLogDuration duration={getValue()} invalid={original.work_duration_exceeded} />
      )
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <CheckLogsFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <NfcCheckLogsTable
          ref={this.checkLogTableRef}
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
        />
      </div>
    )
  }
}

export default GuestNfcCheckLogsTableContainer
