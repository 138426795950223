import React from 'react'

import axios from 'axios'
import classNames from 'classnames'
import Turbolinks from 'turbolinks'

import { showErrorModal, getApiErrorMessage } from '../../../utils/confirmations'
import Checkbox from '../../Checkbox'
import Tooltip from '../../Tooltip'

class SubTasks extends React.Component {
  state = { subTasks: this.props.sub_tasks }

  calculatePosition(_pos, _event, element, tooltip) {
    const { clientWidth: tooltipWidth, clientHeight: tooltipHeight } = tooltip
    const {
      left: offsetLeft,
      top: offsetTop,
      width: checkboxWidth
    } = element.querySelector('.checkbox').getBoundingClientRect()

    const left = offsetLeft - tooltipWidth / 2 + checkboxWidth / 2
    const top = offsetTop - tooltipHeight - 3

    return { left, top }
  }

  toggleSubTask = async (id, completed) => {
    const subTasks = this.state.subTasks.map(subTask => (id === subTask.id ? { ...subTask, completed } : subTask))

    try {
      const { status } = await axios.put(`${this.props.update_path}/${id}`, { sub_task: { completed } })

      if (status === 255) {
        Turbolinks.visit()
      } else {
        this.setState({ subTasks })
      }
    } catch ({ response }) {
      showErrorModal({
        title: getApiErrorMessage(response),
        cancelAction: Turbolinks.visit
      })
    }
  }

  renderSubTasks() {
    const { disabled } = this.props

    return this.state.subTasks.map(({ id, title, completed }) => (
      <div className={classNames('sub-task', { completed })} key={id} data-tip data-for='sub-tasks'>
        <Checkbox disabled={disabled} checked={completed} onChange={() => this.toggleSubTask(id, !completed)} />

        <span className='title'>{title}</span>
      </div>
    ))
  }

  render() {
    const { disabled, disabled_message } = this.props

    return (
      <div className='sub-tasks'>
        {this.renderSubTasks()}

        {disabled && (
          <Tooltip
            id='sub-tasks'
            place='top'
            effect='solid'
            overridePosition={this.calculatePosition}
            className='accented'
          >
            {disabled_message}
          </Tooltip>
        )}
      </div>
    )
  }
}

export default SubTasks
