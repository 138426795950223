import React from 'react'

import classNames from 'classnames'
import { t as translate } from 'i18n-js'

import { SwitchField } from '../../../../Forms/HookFields'
import ForbiddenTimeInput from '../Fields/ForbiddenTimesInput'
import styles from '../styles/ModuleWrapper.module.scss'

const t = name => translate(`activerecord.attributes.tasker/task.${name}`)

const TasksFormSwitch = ({ name }) => <SwitchField name={name} label={t(name)} />

export const TimeTrackingModule = () => {
  const title = translate('v2.tasker.tasks.form.modules.time_tracking')

  return (
    <div className={styles.moduleWrapper}>
      <hr className='mt-4 mb-2' />
      <div className={classNames('mb-2', styles.moduleTitle)}>{title}</div>
      <div className={styles.fields}>
        <ForbiddenTimeInput />
        <TasksFormSwitch name='allow_time_tracking_in_forbidden_periods' />
        <TasksFormSwitch name='resume_time_tracking_after_forbidden_period' />
        <TasksFormSwitch name='concurrent_time_tracking_forbidden' />
      </div>
    </div>
  )
}
