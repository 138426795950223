import variables from '../../../../../assets/stylesheets/shift-colors'
import { formatDuration } from '../../../../utils/schedulerDuration'
import {
  multiselectFormatter,
  selectFormatter,
  stringFormatter,
  timeIntervalFormatter,
  arrayFormatter,
  booleanFormatter
} from '../../../Forms/Formatters'
import Yup from '../../../Forms/common/yup'
import { formattedTotalTime } from '../../utils/handleTotalTime'
import { breakTimeSchema } from '../../utils/yupSchemas'

export const colors = variables.colors.split(',')

const randomColor = () => colors[Math.floor(Math.random() * colors.length)]

export const roundMinsToFive = val => val.replace(/^(\d\d:\d)([1234])$/, '$10').replace(/^(\d\d:\d)([6789])$/, '$15')

export const validationSchema = Yup.object().shape({
  color: Yup.string(),
  name: Yup.string().required(),
  start_time: Yup.string().nullable().bothTimes('end_time'),
  end_time: Yup.string().nullable().bothTimes('start_time'),
  construction_object_id: Yup.object().nullable().required(),
  employee_ids: Yup.array(Yup.object()).nullable(),
  company_ids: Yup.array(Yup.object()).nullable(),
  start_time_tolerance_before: Yup.string().nullable(),
  start_time_tolerance_after: Yup.string().nullable(),
  end_time_tolerance_before: Yup.string().nullable(),
  end_time_tolerance_after: Yup.string().nullable(),
  responsible_ids: Yup.array(Yup.object()).nullable(),
  regularity: Yup.array(Yup.number()).nullable(),
  break_time: breakTimeSchema,
  sync_all_construction_objects: Yup.boolean(),
  sync_new_employees: Yup.boolean(),
  force_update: Yup.boolean()
})

export const dataToForm = (data, formOptions) => {
  const startTime = formatDuration(data.start_time)
  const endTime = formatDuration(data.end_time)
  const breakTime = formatDuration(data.break_time)
  const startTimeToleranceBefore = formatDuration(data.start_time_tolerance_before)
  const startTimeToleranceAfter = formatDuration(data.start_time_tolerance_after)
  const endTimeToleranceBefore = formatDuration(data.end_time_tolerance_before)
  const endTimeToleranceAfter = formatDuration(data.end_time_tolerance_after)

  return {
    color: data.color || randomColor(),
    name: data.name || '',
    start_time: startTime,
    end_time: endTime,
    total_time: formattedTotalTime(startTime, endTime, breakTime),
    construction_object_id:
      formOptions.object_options?.find(({ value }) => data.construction_object_id === value) || null,
    employee_ids: data.employee_options || [],
    company_ids: [],
    start_time_tolerance_before: startTimeToleranceBefore || '',
    start_time_tolerance_after: startTimeToleranceAfter || '',
    end_time_tolerance_before: endTimeToleranceBefore || '',
    end_time_tolerance_after: endTimeToleranceAfter || '',
    regularity: (data.regularity || [1, 2, 3, 4, 5]).map(String),
    responsible_user_ids: data.responsible_options || [],
    break_time: breakTime,
    sync_new_employees: data.sync_new_employees
  }
}

export const valueFormats = {
  color: stringFormatter,
  name: stringFormatter,
  start_time: timeIntervalFormatter,
  end_time: timeIntervalFormatter,
  construction_object_id: selectFormatter,
  employee_ids: multiselectFormatter,
  company_ids: multiselectFormatter,
  start_time_tolerance_before: stringFormatter,
  start_time_tolerance_after: stringFormatter,
  end_time_tolerance_before: stringFormatter,
  end_time_tolerance_after: stringFormatter,
  regularity: arrayFormatter,
  responsible_user_ids: multiselectFormatter,
  break_time: timeIntervalFormatter,
  force_update: booleanFormatter,
  sync_new_employees: booleanFormatter
}
