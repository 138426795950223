import map from 'lodash/map'
import reduce from 'lodash/reduce'
import xor from 'lodash/xor'

const formatMonthDaysByWeeks = days => {
  const result = {}
  days.forEach((value, idx) => {
    result[idx] = value
  })
  return result
}

class DataProcessor {
  modules = { regularity: false, timeTracking: false, subtasks: false, location: false }
  unformattedData = null
  fileKeys = []
  formType = 'new'

  constructor(modules, unformattedData, fileKeys, formType) {
    this.modules = modules
    this.unformattedData = unformattedData
    this.fileKeys = fileKeys
    this.formType = formType
  }

  perform() {
    const resultingData = {
      tasker_task: {
        ...this.unformattedData,
        ...this.#processFiles(),
        ...this.#processSelectFields(),
        ...this.#processTaskTimes(),
        ...this.#processEnabledModules(),
        ...this.#processSubtaskParams(),
        ...this.#processRegularity()
      }
    }

    delete resultingData.tasker_task.attached_files

    return resultingData
  }

  #processFiles() {
    const templateFiles = (data, formType) => {
      if (formType === 'edit') return []
      return xor(map(data.attached_files, 'key'), data.purgeable_file_ids)
    }

    return { files: [...this.fileKeys, ...templateFiles(this.unformattedData, this.formType)] }
  }

  #processSelectFields() {
    const data = this.unformattedData
    const result = {}
    const accessors = [
      'task_group_id',
      'client_label',
      'device_label',
      'responsible_user',
      'responsible_user_group',
      'responsible_users_ids',
      'responsible_user_groups_ids'
    ]

    accessors.forEach(accessor => {
      const formValue = data[accessor]
      const value = Array.isArray(formValue) ? formValue.map(v => v.value) : formValue?.value || null
      let key = accessor

      if ((key === 'responsible_users_ids' && !Array.isArray(value) && !!value) || key === 'responsible_user') {
        key = 'responsible_user_id'
      }
      if (
        (key === 'responsible_user_groups_ids' && !Array.isArray(value) && !!value) ||
        key === 'responsible_user_group'
      ) {
        key = 'responsible_user_group_id'
      }

      result[key] = value
    })

    return result
  }

  #processTaskTimes() {
    if (this.modules.regularity) {
      return { starting_time: null, deadline: null }
    } else {
      const { starting_time, deadline } = this.unformattedData
      return { starting_time, deadline }
    }
  }

  #processEnabledModules() {
    return {
      regularity_enabled: this.modules.regularity,
      time_tracking_enabled: this.modules.timeTracking,
      has_location: this.modules.location
    }
  }

  #processSubtaskParams() {
    const moduleSelected = this.modules.subtasks
    const subtasks = this.unformattedData.sub_tasks_attributes

    const processedSubtasks = subtasks.map((subtask, idx) => {
      let result = { ...subtask, _destroy: subtask._destroy || !moduleSelected, position: idx }
      if (result.autogen) {
        delete result.id
        delete result.autogen
      }
      return result
    })
    return { sub_tasks_attributes: processedSubtasks }
  }

  #processRegularity() {
    const settings = this.unformattedData.regularity
    if (!settings || !this.modules.regularity) return null

    const durationDaysPresent = !!('' + (settings.duration_days?.value ?? (settings.duration_days || '')))

    return {
      regularity: {
        ...settings,
        interval: settings.interval?.value,
        month_specific_days: settings.month_specific_days?.value ?? settings.month_specific_days,
        duration_days: durationDaysPresent ? settings.duration_days?.value ?? settings.duration_days : '',
        duration_time: durationDaysPresent ? (settings.duration_time !== '' ? settings.duration_time : null) : null,
        week_days: reduce(
          settings.week_days,
          (result, value, key) => {
            result[key] = value ? 'true' : 'false'
            return result
          },
          {}
        ),
        month: {
          month_days_by_weeks: formatMonthDaysByWeeks(settings.month?.month_days_by_weeks || []),
          month_dates: settings.month?.month_dates
        }
      }
    }
  }
}

export { DataProcessor }
