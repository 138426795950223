import { init, setTag } from '@sentry/browser'

import { tenant } from '../utils/urlGenerator'

const sentryDSN = document.querySelector('meta[name=sentry_dsn]').content
const environment = document.querySelector('meta[name=env]').content

if (sentryDSN) {
  init({
    environment: environment,
    dsn: sentryDSN
  })
}

export const setSentryContext = () => {
  setTag('tenant', tenant())
}
