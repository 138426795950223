class TaskActivitiesChangelogToggle {
  static selector = '.changelog-toggler'

  constructor(toggleRow) {
    this.bindToggle(toggleRow)
  }

  bindToggle(toggleRow) {
    toggleRow.addEventListener('click', () => {
      const { id } = toggleRow.dataset
      const changelogEl = document.querySelector(`.changelog[data-id='${id}']`)
      changelogEl.classList.toggle('closed')
    })
  }
}

export default TaskActivitiesChangelogToggle
