import React from 'react'

import { useController, useFormContext } from 'react-hook-form'
import Select from 'react-select'

import { t } from '../../../../utils/i18n'
import { REACT_SELECT_PROPS } from '../../../Select'
import MonthDate from './MonthDate'

const MonthByDates = ({ monthSpecificDayOptions, days, specificDays, disabled }) => {
  const { control } = useFormContext()
  const {
    field: { onChange, value }
  } = useController({ control, name: '[regularity][month_specific_days]', defaultValue: specificDays })

  const dates = []
  for (let i = 1; i < 32; i++) {
    dates.push(<MonthDate day={i} key={i} selected={days && days[`date_${i}`] === 'true'} disabled={disabled} />)
  }

  return (
    <React.Fragment>
      <div className='task-regularity-month-dates-predefined-options-container'>
        <Select
          value={value}
          onChange={onChange}
          {...REACT_SELECT_PROPS}
          placeholder={t('v2.tasker.tasks.regularity_month_by_dates.predefined_options_placeholder')}
          options={monthSpecificDayOptions}
          isClearable
          isDisabled={disabled}
        />
      </div>
      <div className='task-regularity-month-dates-container'>{dates}</div>
    </React.Fragment>
  )
}

export default MonthByDates
