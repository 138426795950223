import React, { useContext } from 'react'

import Avatar from '../../../Avatar'
import { SelectContext } from './index'

const MAX_ITEM_COUNT = 3

const SelectedCount = ({ label, selectedItems }) => (
  <div className='selected-count'>
    {selectedItems.length} {label}
  </div>
)

const SelectedItem = ({ itemProps, withImages, optionImageSrc, label }) => (
  <span className='selected-item' {...itemProps}>
    {withImages && <Avatar src={optionImageSrc} />}
    <div className='item-label text-truncate'>{label}</div>
  </span>
)

const SelectedItems = ({ label, getSelectedItemProps }) => {
  const { selectedItems, withImages, isMulti, name } = useContext(SelectContext)

  if (!isMulti || selectedItems.length === 0) {
    return null
  }

  return (
    <div className={`selected-items selected-${name}`}>
      {selectedItems.length <= MAX_ITEM_COUNT ? (
        selectedItems.map((selectedItem, index) => (
          <SelectedItem
            key={index}
            itemProps={{ key: `selected-item-${index}`, ...getSelectedItemProps({ selectedItem, index }) }}
            label={selectedItem.label}
            withImages={withImages}
            optionImageSrc={selectedItem.image_src || selectedItem.imageSrc}
          />
        ))
      ) : (
        <SelectedCount label={label} selectedItems={selectedItems} />
      )}
    </div>
  )
}

export default SelectedItems
