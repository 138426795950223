import { showConfirmation } from '../utils/confirmations'

export default container => {
  const confirmations = container.querySelectorAll('[data-confirmation]')

  confirmations.forEach(element => element.addEventListener('click', handleConfirmation))
}

const handleConfirmation = event => {
  if (event.confirmed) return

  const { confirmation, buttonText } = event.currentTarget.dataset

  showConfirmation({
    event: event,
    isOpen: true,
    text: confirmation,
    confirmButton: { title: buttonText, type: 'danger' }
  })
}
