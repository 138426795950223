import { createContext, useContext } from 'react'

export const AbsenceContext = createContext({
  absences: [],
  loading: false,
  addAbsences: () => {},
  updateAbsence: () => {},
  deleteAbsence: () => {},
  employeeAbsences: {},
  absenceDatesWithEmployeeIds: {}
})

export const useAbsenceContext = () => useContext(AbsenceContext)
