import React from 'react'

import { t } from '../../../utils/i18n'
import Avatar from '../../Avatar'
import Icon from '../../Icon'
import Tooltip from '../../Tooltip'
import SubTasksProgress from './SubTasksProgress'

export const ToolTip = ({ tooltipId, iconTranslation }) => (
  <Tooltip id={tooltipId} className='accented' place='bottom' effect='solid'>
    {t(`v2.tasker.tasks.icons.${iconTranslation}`)}
  </Tooltip>
)

export const TaskInfoIcons = ({ original }) => {
  const {
    id,
    time_tracking_enabled,
    sub_task_count,
    parent_id,
    with_attachment,
    images_required,
    receive_task_completion_notification,
    regularity_enabled,
    notes_count,
    unread_notes_count,
    unread_notes_mention_count
  } = original

  const renderInfoIcon = ({ visible, wrapperClass, icon, tooltipId, iconTranslation }, index) =>
    visible && (
      <span className={wrapperClass} key={index}>
        <Icon icon={icon} data-tip data-for={tooltipId} />
        <ToolTip tooltipId={tooltipId} iconTranslation={iconTranslation} />
      </span>
    )

  const subTaskTooltipId = `subTask-${id}`

  const hasNotes = notes_count > 0
  const hasUnreadNotes = unread_notes_count > 0
  const hasUnreadMentionNotes = unread_notes_mention_count > 0

  const infoIcons = [
    {
      visible: time_tracking_enabled,
      wrapperClass: 'task-icon-timer',
      icon: 'timer',
      tooltipId: `timer-${id}}`,
      iconTranslation: 'time_entry'
    },
    {
      visible: parent_id,
      wrapperClass: 'task-icon-related',
      icon: 'related',
      tooltipId: `related-${id}`,
      iconTranslation: 'related_task'
    },
    {
      visible: regularity_enabled,
      wrapperClass: 'task-icon-regularity',
      icon: 'regularity',
      tooltipId: `regularity-${id}`,
      iconTranslation: 'regularity_task'
    },
    {
      visible: with_attachment,
      wrapperClass: 'task-icon-attachment',
      icon: 'attachment',
      tooltipId: `attachment-${id}`,
      iconTranslation: 'with_attachment'
    },
    {
      visible: images_required,
      wrapperClass: 'task-icon-images-required',
      icon: 'attachmentFilled',
      tooltipId: `images-required-${id}`,
      iconTranslation: 'images_required'
    },
    {
      visible: receive_task_completion_notification,
      wrapperClass: 'task-icon-receive-notification',
      icon: 'receiveNotification',
      tooltipId: `receive-notification-${id}`,
      iconTranslation: 'receive_notification'
    },
    {
      visible: hasNotes && !hasUnreadNotes,
      wrapperClass: 'task-icon-chat',
      icon: 'iconChat',
      tooltipId: `has-notes-${id}`,
      iconTranslation: 'has_notes'
    },
    {
      visible: hasUnreadNotes && !hasUnreadMentionNotes,
      wrapperClass: 'task-icon-chat-unread',
      icon: 'iconChatUnread',
      tooltipId: `has-unread-notes-${id}`,
      iconTranslation: 'has_unread_notes'
    },
    {
      visible: hasUnreadMentionNotes,
      wrapperClass: 'task-icon-chat-mention',
      icon: 'iconChatMention',
      tooltipId: `has-unread-notes-mention-${id}`,
      iconTranslation: 'has_unread_notes_mention'
    }
  ]

  return (
    <div className='task-icon-container'>
      {infoIcons.map(renderInfoIcon)}
      {sub_task_count > 0 && (
        <>
          <span className='task-icon-subtasks task-progress mr-1' data-tip data-for={subTaskTooltipId}>
            <SubTasksProgress {...original} />
          </span>
          <ToolTip tooltipId={subTaskTooltipId} iconTranslation='sub_task_progress' />
        </>
      )}
    </div>
  )
}

export const ResponsibleImages = ({ responsibles, rowIndex }) => {
  if (!responsibles.length) return null
  const avatarWidth = 24 // width - margin left
  const badgeWidth = 43
  const paddingHorizontal = 32

  const responsiblesCount = responsibles.length
  const headerSelector = document.querySelector('.rt-th.responsible_name-header')

  const currentWidth = headerSelector
    ? Number.parseInt(getComputedStyle(headerSelector).getPropertyValue('width'))
    : 200 // default width

  const visibleAvatarCount = Math.floor((currentWidth - paddingHorizontal - badgeWidth - 8) / avatarWidth)

  const hiddenAvatarCount = responsiblesCount > visibleAvatarCount ? responsiblesCount - visibleAvatarCount : 0

  const renderResponsible = (responsible, index) => {
    if (index >= visibleAvatarCount) return null

    return (
      <span key={index} className='responsible-avatar'>
        <span data-tip data-for={`${rowIndex}-responsible-${index}`}>
          <Avatar src={responsible.attachment} />
        </span>
        <Tooltip id={`${rowIndex}-responsible-${index}`} place='bottom' effect='solid'>
          {responsible.name}
        </Tooltip>
      </span>
    )
  }

  const renderBadge = () => (
    <span className='badge-container'>
      {hiddenAvatarCount ? (
        <>
          <span className='badge badge-secondary' data-tip data-for={`${rowIndex}-responsible-names`}>
            +{hiddenAvatarCount}
          </span>
          <Tooltip id={`${rowIndex}-responsible-names`} place='right' effect='solid'>
            <div className='d-flex flex-column'>
              {responsibles.map(
                (responsible, index) => index + 1 > visibleAvatarCount && <span key={index}>{responsible.name}</span>
              )}
            </div>
          </Tooltip>
        </>
      ) : null}
    </span>
  )

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex align-items-center responsible-list'>{responsibles.map(renderResponsible)}</div>
      {renderBadge()}
    </div>
  )
}

export const TaskCountByState = ({ state, row: { state_statistics, state_colors, parent_id }, onClick }) => {
  const count = state_statistics[state]
  const color = state_colors[state].bg

  return (
    <>
      <span
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          onClick(state)
        }}
        data-tip
        data-for={`${state}-count-${parent_id}`}
        className='task-state-statistics'
        style={{ color }}
        data-contains={`task-state-statistics-${state}`}
      >
        {count}
      </span>

      <Tooltip id={`${state}-count-${parent_id}`} className='accented' place='bottom' effect='solid'>
        {t(`activerecord.attributes.task.state/${state}`)}
      </Tooltip>
    </>
  )
}

export const StateCell = ({ original, onStateStatisticsClick }) => {
  const { is_group, state } = original

  if (is_group) {
    return (
      <div className='task-item-state'>
        <TaskCountByState state='draft' row={original} onClick={onStateStatisticsClick} />
        <span className='separator' />
        <TaskCountByState state='in_progress' row={original} onClick={onStateStatisticsClick} />
        <span className='separator' />
        <TaskCountByState state='finished' row={original} onClick={onStateStatisticsClick} />
      </div>
    )
  } else {
    const [colors, text] = original.state_colors
      ? [original.state_colors[state], t(`activerecord.attributes.task.state/${state}`)]
      : [original.colors, state]

    return (
      <div className='task-item-state'>
        <span
          style={{
            color: colors.bg
          }}
        >
          {text}
        </span>
      </div>
    )
  }
}

export const ResponsibleCell = ({ original, rowIndex }) => {
  if (original.is_group) {
    return <ResponsibleImages responsibles={original.responsible_data} rowIndex={rowIndex} />
  } else {
    const [src, text] = original.responsible_data?.length
      ? [original.responsible_data[0].attachment, original.responsible_data[0].name]
      : [original.responsible_attachment_url, original.responsible_name]

    return src && text ? <Avatar src={src} text={text} /> : null
  }
}
