import React from 'react'

import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import Avatar from '../Avatar'
import Icon from '../Icon'
import IconCell from '../IconCell'
import Store from '../Store'
import TableContainer from '../TableContainer'
import EmployeesFilter from './EmployeesFilter'
import ReportEmployeesTable from './ReportEmployeesTable'
import StateCell from './StateCell'

class ReportEmployeesTableContainer extends TableContainer {
  pathPrefix = 'v2/employees'

  constructor(props) {
    super(props)

    this.store = new Store({
      url: this.props.url,
      defaultSorted: [{ id: 'first_name_last_name', desc: false }]
    })

    this.store.state.filters.startDate = props.start_date
    this.store.state.filters.endDate = props.end_date
  }

  columns = [
    {
      header: t('activerecord.attributes.employee.first_name_last_name'),
      accessorKey: 'first_name_last_name',
      cell: ({ getValue, row: { original } }) => (
        <Avatar
          src={original.picture_url}
          text={getValue()}
          resourcePath={original.discarded ? null : url(this.pathPrefix, original.id)}
        />
      )
    },
    {
      header: t('activerecord.attributes.employee.personal_code'),
      accessorKey: 'personal_code',
      cell: ({
        getValue,
        row: {
          original: { id, is_foreigner }
        }
      }) => (
        <IconCell
          id={id}
          showIcon={is_foreigner}
          tooltipText={t('activerecord.attributes.employee.is_foreigner')}
          iconComponent={tooltipId => <Icon icon='globe' className='ml-2 center-icon' data-tip data-for={tooltipId} />}
        >
          {getValue()}
        </IconCell>
      )
    },
    {
      header: t('activerecord.attributes.employee.position'),
      accessorKey: 'position'
    },
    {
      header: t('activerecord.attributes.employee.company'),
      accessorKey: 'company_name',
      cell: ({ getValue, row: { original } }) => <a href={url('v2/companies', original.company_id)}>{getValue()}</a>
    },
    {
      header: t('activerecord.attributes.employee.consumed_time_in_period'),
      accessorKey: 'consumed_time_in_period'
    },
    this.props.show_state_column && {
      header: t('activerecord.attributes.report_version.state'),
      accessorKey: 'state',
      cell: ({ getValue, row: { original } }) => (
        <StateCell state={getValue()} edited={original.edited} id={original.id} type='employee' />
      )
    }
  ]

  render() {
    const { columnOptions } = this.state

    return (
      <div className='nested-table'>
        <EmployeesFilter
          {...this.props}
          store={this.store}
          columnOptions={columnOptions}
          onColumnVisibilityChange={this.onColumnVisibilityChange}
        />
        <ReportEmployeesTable
          {...this.props}
          store={this.store}
          columns={this.columns}
          columnOptions={columnOptions}
          pathPrefix={this.pathPrefix}
        />
      </div>
    )
  }
}

export default ReportEmployeesTableContainer
