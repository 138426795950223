import Axios from 'axios'
import qs from 'qs'
import Turbolinks from 'turbolinks'

import { Notifier } from '../utils/notifier'

const handleNotification = (type, data) => {
  if (data?.data?.notice) {
    Notifier.showFlash(type, data?.data?.notice)
  }
}

const csrfToken = document.querySelector('meta[name=csrf-token]')

Axios.defaults.headers.common = {
  'X-CSRF-Token': csrfToken && csrfToken.getAttribute('content'),
  'Content-Type': 'application/json',
  Accept: 'application/json'
}

Axios.interceptors.request.use(config => {
  config.paramsSerializer = {
    serialize: params => {
      return qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false
      })
    }
  }

  return config
})

Axios.interceptors.response.use(
  response => {
    handleNotification('notice', response)
    return response
  },
  error => {
    if (error.response.status === 401) {
      Turbolinks.visit(window.location)
    }

    handleNotification('alert', error.response)

    return Promise.reject(error)
  }
)
