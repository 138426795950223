import React from 'react'

import TableContainer from './TableContainer'

class NfcCheckLogsTableContainer extends TableContainer {
  constructor(props) {
    super(props)

    this.checkLogTableRef = React.createRef()
  }

  openMapModal = (mapModalTitle, markerLat, markerLong, polygons, apiKey) => {
    this.checkLogTableRef.current.openMapModal(mapModalTitle, markerLat, markerLong, polygons, apiKey)
  }

  onNfcExpandedChange = (row, contextModel, contextObjectId) => {
    if (!this.props.business_industry) return {}

    const [parentType, subRowName] = row?.original.is_child_time_entry
      ? ['TimeEntry', 'thirdLevelRows']
      : ['NfcCheckLog', 'secondLevelRows']

    this.onExpandedChange({
      rowId: row.id,
      parentId: row.original.parent_id,
      parentType,
      path: 'v2/construction_object_time_entries/time_entries',
      subRowName,
      parentIdPrefix: row.original.parent_id_prefix,
      contextModel,
      contextObjectId,
      open: !row.getIsExpanded()
    })
  }
}

export default NfcCheckLogsTableContainer
