import React from 'react'

import { Field, getIn } from 'formik'

import FieldWrapper from '../FieldWrapper'

const FormikFieldWrapper = ({ name, ...others }) => (
  <Field name={name}>
    {({ form }) => {
      const error = getIn(form.errors, name)
      const touched = getIn(form.touched, name)

      const hasError = touched && error && typeof error === 'string'

      return <FieldWrapper {...others} name={name} error={error} hasError={hasError} />
    }}
  </Field>
)

export default FormikFieldWrapper
