import React, { useMemo } from 'react'

import classNames from 'classnames'

import { useSelectedShiftItems } from './SelectedShiftItemsContext'
import { unitSelectIndex } from './shiftDayHelper'

const isUnitSelected = (units, scheduleId, dateIso, employeeId) =>
  unitSelectIndex(units, { schedule_id: scheduleId, date: dateIso, employee_id: employeeId }) > -1

const SelectIndicator = ({
  dateIso,
  scheduleId,
  employeeId,
  visibilityParams: {
    yesterdayDateIso,
    tomorrowDateIso,
    showBlock,
    showTomorrow,
    showYesterday,
    singleDay,
    singleDayTomorrow,
    singleDayYesterday
  }
}) => {
  const { selectedShiftItems } = useSelectedShiftItems()

  const selected = useMemo(
    () => isUnitSelected(selectedShiftItems, scheduleId, dateIso, employeeId),
    [selectedShiftItems, scheduleId, dateIso, employeeId]
  )

  const tomorrowSelected = useMemo(
    () => isUnitSelected(selectedShiftItems, scheduleId, tomorrowDateIso, employeeId),
    [selectedShiftItems, scheduleId, tomorrowDateIso, employeeId]
  )

  const yesterdaySelected = useMemo(
    () => isUnitSelected(selectedShiftItems, scheduleId, yesterdayDateIso, employeeId),
    [selectedShiftItems, scheduleId, yesterdayDateIso, employeeId]
  )

  if (!selected) {
    return null
  }

  const todayShifted = !singleDay && showBlock
  const tomorrowShifted = !singleDayTomorrow && showTomorrow
  const yesterdayShifted = !singleDayYesterday && showYesterday

  const roundRight = !tomorrowSelected || (!todayShifted && tomorrowShifted)
  const roundLeft = !yesterdaySelected || (!yesterdayShifted && todayShifted)

  const selectIndicatorClasses = {
    'rounded-right': roundRight,
    'border-right-0': !roundRight,
    'rounded-left': roundLeft,
    'border-left-0': !roundLeft
  }

  return <div className={classNames('select-indicator', selectIndicatorClasses)} />
}

export default SelectIndicator
