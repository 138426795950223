import React from 'react'

import classNames from 'classnames'
import { useFormContext } from 'react-hook-form'

import Icon from '../../../Icon'
import { ModuleToggle } from './ModuleToggle'
import SettingToggle from './SettingToggle'
import styles from './styles/Sidebar.module.scss'

const TasksFormSidebar = props => {
  const { disableRegularity, locationAllowed } = useFormContext()
  const { onClose } = props

  return (
    <div className={classNames(styles.tasksFormSidebar, 'tasks-form-sidebar')}>
      <div className={classNames('mb-4', styles.closeIcon)}>
        <Icon icon='cross' onClick={onClose} />
      </div>

      <div className={styles.stickyBlock}>
        <div className={styles.moduleTogglesWrapper}>
          <div className={styles.wrapperTitle}>Uzdevuma moduļi:</div>
          <ModuleToggle title='subtasks' icon='subtasks' moduleName='subtasks' />
          <ModuleToggle title='time_tracking' icon='timer' moduleName='timeTracking' />
          {locationAllowed && <ModuleToggle title='location' icon='mapMarker' moduleName='location' />}
          <ModuleToggle title='regularity' icon='regularity' moduleName='regularity' disabled={disableRegularity} />
        </div>

        <div className={styles.moduleTogglesWrapper}>
          <div className={styles.wrapperTitle}>
            <Icon icon='cog' />
            <div>Citi iestatījumi:</div>
          </div>
          <SettingToggle name={'images_required'} />
          <SettingToggle name={'receive_task_completion_notification'} />
          <SettingToggle name={'is_template'} />
        </div>
      </div>
    </div>
  )
}

export default TasksFormSidebar
