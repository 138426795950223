import React from 'react'

import { lv, ru, enUS } from 'date-fns/locale'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import I18n from 'i18n-js'
import { observer } from 'mobx-react'
import DatePicker, { registerLocale } from 'react-datepicker'

import { t } from '../utils/i18n'

registerLocale('lv', lv)
registerLocale('ru', ru)
registerLocale('en', enUS)
dayjs.extend(customParseFormat)

const MonthDatepicker = observer(({ store, ...restProps }) => {
  const { period } = store.state.filters
  const selected = period && dayjs(period, t('date.formats.without_date_human')).toDate()

  return (
    <DatePicker
      {...restProps}
      onChange={store.onMonthDateChange}
      selected={selected}
      dateFormat={t('date.formats.without_date_human_js')}
      showMonthYearPicker
      locale={I18n.locale}
      placeholderText={t('components.date_picker.by_month')}
      popperPlacement='bottom-start'
      isClearable
    />
  )
})
export default MonthDatepicker
