import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { url } from '../../../utils/urlGenerator'

const getContractsFromBis = async ligumsId => {
  return await axios.get(url('v2/vedludb/contracts', { id: ligumsId })).then(res => res.data?.contracts)
}

const getPaymentsFromBis = async ligumsId => {
  return await axios.get(url('v2/vedludb/payments', { id: ligumsId })).then(res => res.data?.payments)
}

const getContractInfoFromBis = async (ligumsId, contract) => {
  return await axios
    .get(url('v2/vedludb/contracts/info', { id: ligumsId, ligumsId: contract?.ligumsId }))
    .then(res => res.data)
}

const getRegistrations = async constructionObjectId => {
  return await axios
    .get(url('v2/vedludb/registrations', { construction_object_id: constructionObjectId }))
    .then(res => res.data?.data)
}

export const useContractsQuery = ligumsId =>
  useQuery({
    queryKey: ['vedludb', 'contracts', ligumsId],
    queryFn: () => getContractsFromBis(ligumsId),
    retry: false
  })

export const usePaymentsQuery = ligumsId =>
  useQuery({
    queryKey: ['vedludb', 'payments', ligumsId],
    queryFn: () => getPaymentsFromBis(ligumsId)
  })

export const useContractQuery = (ligumsId, contract) =>
  useQuery({
    queryKey: ['vedludb', 'contracts', 'info', ligumsId, contract?.ligumsId],
    queryFn: () => getContractInfoFromBis(ligumsId, contract)
  })

export const useRegistrationsQuery = constructionObjectId =>
  useQuery({
    queryKey: ['vedludb', 'registrations'],
    queryFn: () => getRegistrations(constructionObjectId)
  })
