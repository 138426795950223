import React from 'react'

import classNames from 'classnames'

import { formatTime } from '../../../utils/formatTime'
import { formatDuration } from '../../../utils/schedulerDuration'
import Avatar from '../../Avatar'

const ShiftRow = ({ shift, hasTolerances }) => {
  const {
    start_datetime,
    end_datetime,
    work_time_duration,
    planned_duration,
    employee_name,
    picture_url,
    invalid,
    outside_shift,
    employee_id
  } = shift
  return (
    <div
      className={classNames('registration-row registration', {
        invalid: invalid || (outside_shift && hasTolerances),
        'text-muted': outside_shift,
        'outside-shift': outside_shift
      })}
      data-employee-id={employee_id}
    >
      <div className='registration-cell employee'>
        <Avatar src={picture_url} />
        <div>{employee_name}</div>
      </div>
      <div className='registration-cell' data-contains='work_time_hours'>
        {formatTime(start_datetime)} - {formatTime(end_datetime)}
      </div>
      <div className='registration-cell' data-contains='planned_duration'>
        {formatDuration(planned_duration)}
      </div>
      <div className='registration-cell justify-content-end' data-contains='work_time_duration'>
        {formatDuration(work_time_duration)}
      </div>
    </div>
  )
}

export default ShiftRow
