import React, { useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import dayjs from 'dayjs'
import { FormProvider, useForm } from 'react-hook-form'
import yup from 'utils/yup'

import useTranslate from '../../../hooks/useTranslate'
import { url } from '../../../utils/urlGenerator'
import FormModal from '../../FormModal'
import { dateFormatter, selectFormatter } from '../../Forms/Formatters'
import { formatValuesForSubmit } from '../../Forms/Formatters/submit'
import { DateField, SelectField } from '../../Forms/HookFields'
import StatusHistory from './StatusHistory'

const initialFormData = {
  status: '',
  date: dayjs().toDate()
}

const validationSchema = yup.object({
  status: yup.object().nullable().required(),
  date: yup.string().nullable().required()
})

const valueFormats = {
  status: selectFormatter,
  date: dateFormatter
}

const StatusForm = ({ data, onClose, ligumsId }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.RegistrationStatus')
  const queryClient = useQueryClient()

  const { mutate, isLoading: isSubmitting } = useMutation({
    mutationFn: formData => {
      return axios.put(url('v2/vedludb/registrations', ligumsId, 'update_status'), {
        registration: formatValuesForSubmit(formData, valueFormats)
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vedludb', 'registration', ligumsId] })
      onClose()
    }
  })

  const formContext = useForm({
    values: initialFormData,
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema)
  })

  const {
    handleSubmit,
    formState: { errors: _errors }
  } = formContext

  const statusOptions = [0, 1, 2, 3]
    .filter(status => status !== data.statuss)
    .map(status => ({ value: status, label: t(`.status.${status}`) }))

  return (
    <FormProvider {...formContext}>
      <FormModal title={t('.title')} onClose={onClose}>
        <form onSubmit={handleSubmit(mutate)} className='react-form'>
          <SelectField name='status' label={t('.new_status')} options={statusOptions} />
          <DateField name='date' label={t('.date')} />
          <div className='mt-4 d-flex gap-3'>
            <button className='btn btn-primary' type='submit' disabled={isSubmitting}>
              {t('save')}
            </button>
            <button className='btn btn-secondary' onClick={onClose}>
              {t('cancel')}
            </button>
          </div>
        </form>
      </FormModal>
    </FormProvider>
  )
}

const RegistrationStatus = ({ data, ligumsId }) => {
  const t = useTranslate('ConstructionObjects.Vedludb.RegistrationStatus')
  const [modalOpen, setModalOpen] = useState(false)
  const [historyIsOpen, setHistoryIsOpen] = useState(false)

  return (
    <div className='mt-5 mb-5'>
      <h3>
        {t('.title')}: <span data-contains='status'>{t(`.status.${data.statuss}`)}</span>
      </h3>
      <div className='d-flex gap-2'>
        <button type='button' className='btn btn-secondary' onClick={() => setModalOpen(true)}>
          {t('.change_status')}
        </button>
        <button className='btn btn-secondary' onClick={() => setHistoryIsOpen(true)}>
          {t('history')}
        </button>
      </div>
      {modalOpen && <StatusForm onClose={() => setModalOpen(false)} ligumsId={ligumsId} data={data} />}
      {historyIsOpen && <StatusHistory ligumsId={ligumsId} onClose={() => setHistoryIsOpen(false)} />}
    </div>
  )
}

export default RegistrationStatus
