import React from 'react'

import { t } from 'i18n-js'
import { useFormContext } from 'react-hook-form'

import SelectField from '../../../../../Forms/HookFields/SelectField'
import SectionTitle from '../../SectionTitle'

const RegularityDynamicSelect = ({ intervalKind, name, className = '' }) => {
  const { selectOptions, submitting, disableRegularity } = useFormContext()
  const options = selectOptions.regularity[`${intervalKind}_interval_options`]

  const selectProps = {
    className: `${className} select-container tasks-form-select-field dynamic-select`,
    classNamePrefix: 'rs',
    noOptionsMessage: () => t('components.select.no_options'),
    loadingMessage: () => t('components.select.loading_message'),
    formatCreateLabel: value => `${t('components.select.create', { value })}`,
    disabled: submitting || disableRegularity,
    name,
    options
  }

  return (
    <div>
      <SectionTitle>{t('v2.tasker.tasks.regularity_common_fields.regularity_repetition')}</SectionTitle>
      <SelectField {...selectProps} />
    </div>
  )
}

export default RegularityDynamicSelect
