import React from 'react'

import { lv, ru, enUS } from 'date-fns/locale'
import dayjs from 'dayjs'
import I18n from 'i18n-js'
import { observer } from 'mobx-react'
import DatePicker, { registerLocale } from 'react-datepicker'

import Icon from './Icon'

registerLocale('lv', lv)
registerLocale('ru', ru)
registerLocale('en', enUS)

class RangeDatepicker extends React.Component {
  constructor(props) {
    super(props)

    this.startCalendarRef = React.createRef()
    this.endCalendarRef = React.createRef()
    this.startKey = props.customDateKeys?.start ?? 'startDate'
    this.endKey = props.customDateKeys?.end ?? 'endDate'
  }

  onRefElemClick(event, type) {
    if (event.type === 'click') {
      this[type].current.setOpen(true)
      this[type].current.input.focus()
    }
  }

  onStartDateChange(date, event) {
    const { store, onStartDateChange } = this.props

    if (onStartDateChange) {
      onStartDateChange(date)
    } else {
      store.onRangeDateChange(date, this.startKey)
    }

    this.onRefElemClick(event, 'endCalendarRef')
  }

  onEndDateChange(date) {
    const { store, onEndDateChange } = this.props

    if (onEndDateChange) {
      onEndDateChange(date)
    } else {
      store.onRangeDateChange(date, this.endKey)
    }
  }

  render() {
    const { store, withIcon = true } = this.props
    const startDate = store?.state.filters[this.startKey] || this.props[this.startKey]
    const endDate = store?.state.filters[this.endKey] || this.props[this.endKey]

    const parsedStartDate = startDate ? dayjs(startDate).toDate() : null
    const parsedEndDate = endDate ? dayjs(endDate).toDate() : null

    const format = I18n.t('date.formats.date_human_js')

    return (
      <div className='range-datepicker'>
        <DatePicker
          ref={this.startCalendarRef}
          locale={I18n.locale}
          selected={parsedStartDate}
          onChange={(date, event) => this.onStartDateChange(date, event)}
          selectsStart
          startDate={parsedStartDate}
          endDate={parsedEndDate}
          dateFormat={format}
          highlightDates={[new Date()]}
          popperPlacement='bottom-start'
          showPopperArrow={false}
          autoComplete='off'
        />
        <span className='range-separator'>-</span>
        <DatePicker
          ref={this.endCalendarRef}
          locale={I18n.locale}
          selected={parsedEndDate}
          onChange={date => this.onEndDateChange(date)}
          selectsEnd
          startDate={parsedStartDate}
          endDate={parsedEndDate}
          minDate={parsedStartDate}
          dateFormat={format}
          highlightDates={[new Date()]}
          popperPlacement='bottom-start'
          showPopperArrow={false}
          autoComplete='off'
        />
        {withIcon && (
          <Icon
            icon='calendar'
            className='calendar-icon'
            onClick={event => this.onRefElemClick(event, 'startCalendarRef')}
          />
        )}
      </div>
    )
  }
}

export default observer(RangeDatepicker)
