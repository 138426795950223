import React from 'react'

import classNames from 'classnames'

import paginationArrowNext from '../../images/pagination-arrow-next.png'
import paginationArrowPrevious from '../../images/pagination-arrow-previous.png'
import { t } from '../../utils/i18n'
import { url } from '../../utils/urlGenerator'
import ModalForm from '../ModalForm'
import ModalFormikForm from '../ModalFormikForm'

const parseNumber = number => {
  if (number === 0 || number === '0') return 0
  return Number(number) || '' // In case of Number(0) or Number('0') it returns false and '', so we do a check beforehand
}

class TableFooter extends React.Component {
  store = this.props.store

  onPageChange(page) {
    if (this.props.page !== page) {
      this.store.setState({ page: parseNumber(page) })
    }
  }

  onPageSizeInputBlur = e => {
    let pageSize
    pageSize = Math.min(parseNumber(e.target.value), 100)
    pageSize = Math.max(pageSize, 1)

    this.store.setState({ pageSize })
    this.props.setPageSize(pageSize)
  }

  onPageSizeInputChange = e => {
    const pageSize = parseNumber(e.target.value)
    this.store.setState({ pageSize })
  }

  renderNextPageSwitch(nextPage) {
    const disabled = !this.props.canNextPage
    return (
      <img
        className={classNames('page-switch', { disabled })}
        onClick={() => {
          if (disabled) return null

          this.props.nextPage()
          this.onPageChange(nextPage)
        }}
        src={paginationArrowNext}
      />
    )
  }

  renderPrevPageSwitch(prevPage) {
    const disabled = !this.props.canPreviousPage
    return (
      <img
        className={classNames('page-switch', { disabled })}
        onClick={() => {
          if (disabled) return null

          this.props.previousPage()
          this.onPageChange(prevPage)
        }}
        src={paginationArrowPrevious}
      />
    )
  }

  renderPage(page, index, renderablePages) {
    const currentPage = this.props.page

    return (
      <React.Fragment key={page}>
        <span
          className={classNames('page', { selected: currentPage === page })}
          onClick={() => {
            this.props.gotoPage(page)
            this.onPageChange(page)
          }}
        >
          {page + 1}
        </span>

        {renderablePages[index + 1] && renderablePages[index + 1] != page + 1 && '…'}
      </React.Fragment>
    )
  }

  renderPages() {
    const { page: currentPage, pageCount } = this.props

    if (pageCount <= 0) return

    const renderablePages = [...Array(pageCount).keys()].filter(page => {
      return page === 0 || page + 1 === pageCount || (page + 2 > currentPage && page - 2 < currentPage)
    })

    return (
      <div className='pages'>
        {this.renderPrevPageSwitch(currentPage - 1)}
        {renderablePages.map(this.renderPage.bind(this))}
        {this.renderNextPageSwitch(currentPage + 1)}
      </div>
    )
  }

  renderObjectCount() {
    const { data, dataSize, page } = this.props
    const { pageSize } = this.store.state

    const start = page * pageSize
    const end = Math.min(start + data.length, dataSize)

    return (
      <span>
        {start + 1} {t('components.active_table.up_to_text')} {end} {t('components.active_table.of_text')} {dataSize}
      </span>
    )
  }

  renderPageSizeInput() {
    return (
      <span className='page-size-options'>
        {t('components.active_table.rows_selector_text')}
        <input
          value={this.store.state.pageSize}
          type='number'
          onKeyPress={e => e.key === 'Enter' && e.target.blur()}
          onChange={this.onPageSizeInputChange}
          onBlur={this.onPageSizeInputBlur}
        />
      </span>
    )
  }

  renderNewResourceButton() {
    const {
      creatable,
      pathPrefix,
      translationPath,
      reloadPageOnSuccess,
      formikForm,
      customModal = null,
      formParams = {}
    } = this.props

    if (creatable) {
      const translation = Array.isArray(translationPath) ? translationPath.join('.') : translationPath
      const formUrl = url(pathPrefix, 'new', formParams)
      const title = t(`v2.${translation}.new.title`)
      const buttonText = t(`v2.${translation}.index.add_subject`)

      const modalProps = {
        action: 'new',
        url: formUrl,
        title,
        reloadPageOnSuccess,
        buttonText,
        toggleModal: toggleFormModal => (this.toggleFormModal = toggleFormModal)
      }

      if (formikForm) {
        return <ModalFormikForm {...modalProps} formikForm={formikForm} reloadPageOnSuccess />
      } else if (customModal) {
        return customModal(modalProps)
      } else {
        return <ModalForm {...modalProps} />
      }
    } else {
      return <div className='new-resource-button' />
    }
  }

  render() {
    if (this.props.dataSize === 0) {
      return <div className='rt-pagination'>{this.renderNewResourceButton()}</div>
    } else {
      return (
        <div className='rt-pagination'>
          {this.renderNewResourceButton()}
          {this.renderPages()}

          <div className='pagination-info'>
            {this.renderObjectCount()}
            {this.renderPageSizeInput()}
          </div>
        </div>
      )
    }
  }
}

export default TableFooter
