import { makeObservable, observable, action } from 'mobx'

import { generateId } from './generateId'

export const Notifier = {
  flashes: [],

  showFlash(type, message) {
    const id = generateId()
    this.flashes.push({ id, type, message })

    setTimeout(id => this.closeFlash(id), 7500, id)
  },

  closeFlash(id) {
    this.flashes = this.flashes.filter(flash => flash.id !== id)
  }
}

makeObservable(Notifier, {
  flashes: observable,
  showFlash: action,
  closeFlash: action
})
