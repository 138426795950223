class ConstructionObjectForm {
  static selector = '.edit_construction_object, .new_construction_object'

  constructor(form) {
    this.form = form

    this.bindElements()
    this.bindEvents()
  }

  bindElements() {
    this.selectors = {
      sendtoSrsInput: this.form.querySelector('#construction_object_send_to_srs'),
      vedludbTokenInput: this.form.querySelector('#construction_object_vedludb_token'),
      vedludbConstructionSiteInput: this.form.querySelector('#construction_object_vedludb_construction_site'),
      vedludbTokenAttributeContainer: this.form.querySelector('#vedludb-token-container')
    }
  }

  bindEvents() {
    if (this.selectors.sendtoSrsInput) {
      this.selectors.sendtoSrsInput.addEventListener('change', this.handleVedludbToken)
    }

    if (this.selectors.vedludbTokenInput) {
      this.selectors.vedludbTokenInput.addEventListener('change', this.handleVedludbTokenChange)
    }
  }

  handleVedludbToken = event => {
    this.selectors.vedludbTokenAttributeContainer.classList[event.target.checked ? 'remove' : 'add']('d-none')
  }

  handleVedludbTokenChange = event => {
    this.selectors.vedludbConstructionSiteInput.value = event.target.innerText
  }
}

export default ConstructionObjectForm
