import React, { useMemo } from 'react'

import axios from 'axios'

import { t } from '../../../utils/i18n'
import { url } from '../../../utils/urlGenerator'
import NfcCheckLogForm, { useNfcCheckLogFormik } from '../../Forms/NfcCheckLogForm'
import ModalFormikForm from '../../ModalFormikForm'
import { discard, checkOut } from '../../elements/actionDefinitions'
import RegistrationRow from './RegistrationRow'

const formikForm = { Form: NfcCheckLogForm, useForm: useNfcCheckLogFormik }

const Registrations = ({
  registrations = [],
  loading,
  dayData: { has_tolerances, start_time_violation, end_time_violation },
  setRegistrations,
  loadRegistrations,
  setOverModalData
}) => {
  const registrationActions = useMemo(
    () => [
      {
        permission: 'edit',
        text: t('edit'),
        icon: 'pencil',
        onClick: object => {
          setOverModalData({
            props: {
              url: url('v2/nfc_check_logs', object.id, 'edit'),
              reloadPageOnSuccess: false,
              onSuccessfulSubmit: () => {
                setOverModalData(null)
                loadRegistrations()
              },
              title: t('v2.nfc_check_logs.edit.title'),
              skipToggleButton: true,
              formikForm: formikForm,
              isOpen: true,
              onClose: () => setOverModalData(null)
            },
            Component: ModalFormikForm
          })
        }
      },
      checkOut('id', {
        confirmationProps: {
          text: t('confirm_check_out'),
          confirmButton: { title: t('check_out'), type: 'warning' },
          closeOnConfirm: true
        },
        handler: ({ id }) => {
          axios.put(url('v2/shift_registrations', id, 'manual_check_out')).then(() => {
            loadRegistrations()
          })
        }
      }),
      discard('v2/nfc_check_logs', {
        confirmationProps: {
          text: t('confirm_discard'),
          confirmButton: { title: t('discard'), type: 'danger' },
          closeOnConfirm: true
        },
        handler: ({ id }) => {
          axios.put(url('v2/nfc_check_logs', id, 'discard')).then(() => {
            setRegistrations(registrations.filter(reg => reg.id !== id))
          })
        }
      })
    ],
    [loadRegistrations, registrations, setOverModalData, setRegistrations]
  )

  if (loading) {
    return <div className='mt-3 text-center font-weight-bold'>{t('components.active_table.loading_text')}</div>
  } else if (!registrations.length) {
    return null
  }

  return (
    <div className='registrations'>
      <div className='registration-row header'>
        <div className='registration-cell'>{t('activerecord.attributes.shift.registrations.start')}</div>
        <div className='registration-cell justify-content-center'>
          {t('activerecord.attributes.shift.registrations.end')}
        </div>
        <div className='registration-cell justify-content-end'>
          {t('activerecord.attributes.shift.registrations.hours')}
        </div>
        <div className='registration-cell'></div>
      </div>
      {registrations.map((registration, i) => (
        <RegistrationRow
          registration={registration}
          key={i}
          registrationActions={registrationActions}
          hasTolerances={has_tolerances}
          violations={{
            start: i === 0 && start_time_violation,
            end: i === registrations.length - 1 && end_time_violation
          }}
        />
      ))}
    </div>
  )
}

export default Registrations
