import axios from 'axios'

import { url } from './urlGenerator'

const UPLOAD_URL = url('/upload')
const HEADERS = { headers: { 'Content-Type': 'multipart/form-data', processData: 'false' } }

class FileUploader {
  files = []

  constructor(files = []) {
    this.files = files
  }

  async perform() {
    const filekeys = []

    for (const fileData of this.files) {
      const { data } = await axios.post(UPLOAD_URL, { file: fileData.raw }, HEADERS)
      if (data) filekeys.push(data.key)
    }

    return filekeys
  }
}

export default FileUploader
