import * as Yup from 'yup'

import { formatTime } from '../../../../utils/formatTime'
import { t } from '../../../../utils/i18n'
import { formatDuration } from '../../../../utils/schedulerDuration'
import { selectFormatter, stringFormatter, timeIntervalFormatter } from '../../../Forms/Formatters'

export const valueFormats = {
  date: stringFormatter,
  start_time: timeIntervalFormatter,
  end_time: timeIntervalFormatter,
  break_time: timeIntervalFormatter,
  switchable_schedule_id: selectFormatter,
  comment: stringFormatter
}

export const validationSchema = Yup.object().shape({
  start_time: Yup.string().required(t('v2.shifts.index.form.errors.required')),
  end_time: Yup.string().required(t('v2.shifts.index.form.errors.required')),
  switchable_schedule_id: Yup.object().nullable(),
  break_time: Yup.string()
})

export const dataToForm = ({ shift, schedule, date }) => ({
  date,
  start_time: shift ? formatTime(shift.start_datetime) : formatDuration(schedule.start_time),
  end_time: shift ? formatTime(shift.end_datetime) : formatDuration(schedule.end_time),
  break_time: shift ? formatDuration(shift?.break_time) : formatDuration(schedule.break_time),
  switchable_schedule_id: null,
  comment: ''
})
