import React from 'react'

import classNames from 'classnames'

import Icon from './Icon'

const Avatar = ({ src, text, resourcePath, className = '' }) => (
  <div className={classNames('d-flex align-items-center', className)}>
    {src ? <img src={src} className='avatar' /> : <Icon icon='avatar' className='avatar' />}
    {text ? (
      resourcePath ? (
        <a href={resourcePath} className='text-truncate'>
          {text}
        </a>
      ) : (
        <span className='text-truncate'>{text}</span>
      )
    ) : null}
  </div>
)

export default Avatar
