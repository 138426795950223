import React, { useRef } from 'react'

import cx from 'classnames'
import reject from 'lodash/reject'
import { useController, useFormContext } from 'react-hook-form'

import Icon from '../../Icon'
import styles from './FieldStyles.module.scss'

const FILE_INPUT_ACCEPT_TYPES =
  'image/jpg,image/jpeg,image/png,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation'

const NewFileButton = ({ field, children }) => {
  const { onChange } = field
  const inputRef = useRef(null)

  const handleClick = () => {
    inputRef.current.click()
  }

  const handleFileChange = () => {
    const newFiles = Array.from(inputRef.current.files).map(fileData => ({
      filename: fileData.name,
      content_type: fileData.type,
      raw: fileData
    }))
    onChange(newFiles)
  }

  return (
    <>
      <div className={styles.newFileButton} onClick={handleClick}>
        <Icon icon='attachment' />
        <div>{children}</div>
      </div>
      <input
        ref={inputRef}
        className={styles.hiddenFileInput}
        type='file'
        onChange={handleFileChange}
        multiple={true}
        accept={FILE_INPUT_ACCEPT_TYPES}
      />
    </>
  )
}

const FileTag = ({ file, pos = null, ...props }) => {
  const {
    field: { onChange, value }
  } = props

  const handleRemove = () => {
    if (pos !== null) {
      const copyOfOriginal = [...value]
      copyOfOriginal.splice(pos, 1)
      onChange(copyOfOriginal)
    } else {
      const { handleRemove } = props
      handleRemove(file.id)
    }
  }

  return (
    <div className={cx(styles.tag, 'tag')}>
      <i data-type={file.content_type} className='fa fa-lg' />
      <span className='mr-2'>{file.filename}</span>
      <Icon icon='cross' onClick={handleRemove} className={styles.removeIcon} />
    </div>
  )
}

const FilesField = ({ buttonText }) => {
  const { control } = useFormContext()
  const filesControls = useController({ control, name: 'attached_files' })
  const newFilesControls = useController({ control, name: 'files', defaultValue: [] })
  const {
    field: { onChange, value }
  } = useController({ control, name: 'purgeable_file_ids', defaultValue: [] })

  const handleExistingFileRemove = id => {
    const existingFiles = filesControls.field.value
    onChange([...value, id])
    filesControls.field.onChange(reject(existingFiles, ['id', id]))
  }

  return (
    <div className={cx(styles.attachmentInput, 'attached-files')}>
      <NewFileButton {...newFilesControls}>{buttonText}</NewFileButton>
      {filesControls.field.value.map((file, key) => (
        <FileTag key={key} file={file} {...filesControls} handleRemove={handleExistingFileRemove} />
      ))}
      {newFilesControls.field.value.map((file, i) => (
        <FileTag file={file} key={i} pos={i} {...newFilesControls} />
      ))}
    </div>
  )
}

export default FilesField
