import React from 'react'

import ReactDOM from 'react-dom'

import Confirmation from '../components/Confirmation'
import { t } from './i18n'

const showConfirmation = props => {
  ReactDOM.render(<Confirmation {...props} />, document.querySelector('.confirmation-container'))
}

const getApiErrorMessage = response => {
  if (!response) return t('generic_error_message')

  const error = response.data.base || response.data[Object.keys(response.data)[0]]

  if (error) {
    return error[0].full_message
  } else {
    return t('generic_error_message')
  }
}

const showErrorModal = ({ title, errors, cancelAction }) => {
  const text = (
    <div className='error-modal-container d-flex flex-column'>
      <div className='mb-4 font-weight-bold d-flex flex-row justify-content-center align-items-center title-container'>
        {title}
      </div>
      {errors && <div className='error-list text-left align-self-center d-flex flex-column'>{errors}</div>}
    </div>
  )

  showConfirmation({
    isOpen: true,
    closeOnConfirm: true,
    cancelText: t('close'),
    cancelAction,
    text
  })
}

// TODO: use this where appropriate
const showDefaultErrorModal = () =>
  showErrorModal({
    title: getApiErrorMessage(null),
    cancelAction: Turbolinks.visit
  })

export { showConfirmation, showErrorModal, showDefaultErrorModal, getApiErrorMessage }
