class PasswordForm {
  static selector = '#user_settings_form, #admin_user_settings_form'

  constructor(form) {
    this.form = form

    this.bindElements()
    this.bindEvents()
    this.togglePasswordFields()
  }

  bindElements() {
    this.selectors = {
      passwordSwitch: this.form.querySelector('#validate_password'),
      fieldsContainer: this.form.querySelector('.password-field-container')
    }
  }

  bindEvents() {
    this.selectors.passwordSwitch.addEventListener('change', this.togglePasswordFields)
  }

  togglePasswordFields = () => {
    const { passwordSwitch, fieldsContainer } = this.selectors

    if (passwordSwitch.checked) {
      passwordSwitch.value = true
      fieldsContainer.classList.add('animating-in')
      fieldsContainer.classList.remove('hidden')
      fieldsContainer.classList.remove('animating-out')
    } else {
      passwordSwitch.value = false
      fieldsContainer.classList.add('animating-out')
      fieldsContainer.classList.add('hidden')
      fieldsContainer.classList.remove('animating-in')
    }
  }
}

export default PasswordForm
