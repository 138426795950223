import React from 'react'

import ActiveTable from '../ActiveTable'
import { edit, discard, discardSelected } from '../elements/actionDefinitions'

class CompaniesTable extends React.Component {
  store = this.props.store

  actions = {
    row: [edit(this.props.pathPrefix, 'v2.companies.edit.title'), discard(this.props.pathPrefix)],
    header: [discardSelected(this.props.pathPrefix)]
  }

  render() {
    return (
      <ActiveTable
        {...this.props}
        actions={this.actions}
        highlightedBy='checked_in_employees_count'
        translationPath='companies'
        onRowClick='navigateToResource'
      />
    )
  }
}

export default CompaniesTable
