import { cloneDeep } from 'lodash'

const applyFormatter = (value, formatter) => {
  if (!formatter) {
    return value
  } else if (Array.isArray(formatter)) {
    return formatter.reduce((acc, currentFormatter) => applyFormatter(acc, currentFormatter), value)
  } else if (typeof formatter === 'object') {
    const formatterFunction = formatter.function
    return formatterFunction(value, formatter.valueFormatter)
  } else {
    return formatter(value)
  }
}

export const formatValuesForSubmit = (values, valueFormats) => {
  const formattedValues = cloneDeep(valueFormats)

  Object.keys(formattedValues).map(fieldName => {
    const formatter = formattedValues[fieldName]

    formattedValues[fieldName] = applyFormatter(values[fieldName], formatter)
  })

  return formattedValues
}
