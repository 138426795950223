import React, { useMemo } from 'react'

import classNames from 'classnames'
import { uniqBy, differenceBy } from 'lodash'
import { FixedSizeList as List } from 'react-window'

import { t } from '../utils/i18n'
import Avatar from './Avatar'
import Icon from './Icon'

const OPTION_HEIGHT = 34

export const OptionColorAvatar = ({ color }) => <div className='color-avatar' style={{ background: color }} />

export const addOrRemoveSelectedItem = ({ selectedItem, selectedItems, onChange }) => {
  if (!selectedItem) {
    return
  }

  const index = selectedItems.findIndex(item => item.value === selectedItem.value)

  let newSelectedItems

  if (index > 0) {
    newSelectedItems = [...selectedItems.slice(0, index), ...selectedItems.slice(index + 1)]
  } else if (index === 0) {
    newSelectedItems = [...selectedItems.slice(1)]
  } else {
    newSelectedItems = [...selectedItems, selectedItem]
  }

  onChange(newSelectedItems)
}

export const SelectAllOption = ({ selectedAll, onSelectAll }) => (
  <div className='option-row'>
    <div style={{ height: 40 }} onClick={onSelectAll} className={classNames('option', { selected: selectedAll })}>
      <div className='option-content'>
        <span className={classNames('checkbox', { checked: selectedAll })}>
          {selectedAll && <Icon icon='checkMark' />}
        </span>
        {t('all')}
      </div>
    </div>
  </div>
)

const optionLabel = (item, renderOptionLabel) => {
  if (item.creatable) {
    return t('components.select.create', { value: item.label })
  } else if (renderOptionLabel) {
    return renderOptionLabel(item)
  } else {
    return item.label
  }
}

export const Option = ({
  data,
  item,
  index,
  style,
  selectedItems,
  className,
  renderOptionLabel,
  isMulti,
  containerProps,
  withImages,
  onClick
}) => {
  item ||= data.items[index]

  if (!item) return null

  const selected = isMulti
    ? !!selectedItems.filter(selectedItem => selectedItem.value === item.value).length
    : selectedItems?.value === item.value

  return (
    <div
      style={style}
      className={classNames(`option ${className}`, { selected })}
      onClick={() => onClick(item)}
      {...containerProps}
    >
      {isMulti && (
        <span className={classNames('checkbox', { checked: selected })}>{selected && <Icon icon='checkMark' />}</span>
      )}
      {withImages &&
        (item.color ? <OptionColorAvatar color={item.color} /> : <Avatar src={item.image_src || item.imageSrc} />)}
      {optionLabel(item, renderOptionLabel)}
    </div>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
export const OptionList = ({
  options,
  renderLabel,
  selectedItems,
  onSelect,
  onSelectAll,
  isMulti,
  optionsFooter,
  onMouseLeave,
  OptionComponent
}) => {
  const renderOption = useMemo(
    () => props => {
      const Component = OptionComponent || Option

      return (
        <Component
          {...props}
          selectedItems={selectedItems}
          onClick={onSelect}
          renderOptionLabel={renderLabel}
          isMulti={isMulti}
        />
      )
    },
    [selectedItems, onSelect, renderLabel, isMulti]
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  const optionValues = useMemo(() => {
    if (isMulti) return options.map(item => item.value)
  }, [options, isMulti])

  const selectedItemValues = useMemo(() => {
    if (isMulti) return selectedItems.map(item => item.value)
  }, [selectedItems, isMulti])

  const selectedAll = useMemo(() => {
    if (isMulti) return optionValues.every(id => selectedItemValues.includes(id))
  }, [optionValues, selectedItemValues, isMulti])

  const onSelectAllItems = () => {
    const selectFunction = onSelectAll || onSelect

    if (selectedAll) {
      selectFunction(differenceBy(selectedItems, options, 'value'))
    } else {
      selectFunction(uniqBy([...selectedItems, ...options], 'value'))
    }
  }

  return (
    <div className='option-list' onClick={e => e.nativeEvent.stopImmediatePropagation()}>
      {options.length ? (
        <div onMouseLeave={onMouseLeave}>
          {isMulti && <SelectAllOption selectedAll={selectedAll} onSelectAll={onSelectAllItems} />}
          <List
            width='100%'
            height={options.length < 6 ? options.length * OPTION_HEIGHT : 5.5 * OPTION_HEIGHT}
            itemCount={options.length}
            itemSize={OPTION_HEIGHT}
            itemData={{ items: options }}
          >
            {renderOption}
          </List>
        </div>
      ) : (
        <div className='no-data'>{t('no_data')}</div>
      )}
      {optionsFooter && (
        <>
          <hr className='my-0' />
          {optionsFooter}
        </>
      )}
    </div>
  )
}
